import React, {useEffect, useState} from "react";
import profilePic from "../../assets/svg/user_profile.svg";
import userTwo from "../../assets/svg/user_two.svg";
import activeIcon from "../../assets/svg/activeIcon.svg";
import pendingIcon from "../../assets/svg/pendingicon.svg";
import search_icon from "../../assets/svg/search_icon.svg";
import "./BlockChainSideBar.scss";

const BlockChainSideBar = ({setSelectedUser, setActiveTab}) => {
  const [seletedIndex, setSelectedIndex] = useState(0);
  const users = [
    {
      name: "Adriana Boyd",
      userType: "Investor",
      status: "Active",
      match: "80%",
      deals: "40",
      borrowed: "$310,000",
      paid: "$124,000",
      paidPercentage: "62%",
      earned: "$166,000",
      earnedPercentage: "38%",
      loanTerm: "12",
      profileImg: userTwo,
    },
    {
      name: "John Doe",
      userType: "Investor",
      status: "Pending",
      match: "80%",
      deals: "50",
      borrowed: "$390,000",
      paid: "$184,000",
      paidPercentage: "62%",
      earned: "$126,000",
      earnedPercentage: "38%",
      loanTerm: "09",
      profileImg: profilePic,
    },
    {
      name: "Michel Starch",
      userType: "Investor",
      status: "Pending",
      match: "60%",
      deals: "90",
      borrowed: "$358,000",
      paid: "$114,000",
      paidPercentage: "62%",
      earned: "$116,000",
      earnedPercentage: "38%",
      loanTerm: "11",
      profileImg: userTwo,
    },
    {
      name: "Adriana Boyd",
      userType: "Investor",
      status: "Active",
      match: "30%",
      deals: "30",
      borrowed: "$340,000",
      paid: "$160,000",
      paidPercentage: "62%",
      earned: "$116,000",
      earnedPercentage: "38%",
      loanTerm: "12",
      profileImg: profilePic,
    },
    {
      name: "Adriana Boyd",
      userType: "Investor",
      status: "Pending",
      match: "80%",
      deals: "20",
      borrowed: "$350,000",
      paid: "$184,000",
      paidPercentage: "62%",
      earned: "$166,000",
      earnedPercentage: "38%",
      loanTerm: "12",
      profileImg: userTwo,
    },
    {
      name: "Adriana Boyd",
      userType: "Investor",
      status: "Active",
      match: "80%",
      deals: "23",
      borrowed: "$350,000",
      paid: "$184,000",
      paidPercentage: "62%",
      earned: "$116,000",
      earnedPercentage: "38%",
      loanTerm: "12",
      profileImg: profilePic,
    },
    {
      name: "Adriana Boyd",
      userType: "Investor",
      status: "Pending",
      match: "70%",
      deals: "90",
      borrowed: "$350,000",
      paid: "$114,000",
      paidPercentage: "62%",
      earned: "$166,000",
      earnedPercentage: "38%",
      loanTerm: "12",
      profileImg: userTwo,
    },
    {
      name: "Adriana Boyd",
      userType: "Investor",
      status: "Active",
      match: "80%",
      deals: "40",
      borrowed: "$350,000",
      paid: "$184,000",
      paidPercentage: "62%",
      earned: "$166,000",
      earnedPercentage: "38%",
      loanTerm: "12",
      profileImg: profilePic,
    },
    {
      name: "Adriana Boyd",
      userType: "Investor",
      status: "Pending",
      match: "80%",
      deals: "40",
      borrowed: "$350,000",
      paid: "$184,000",
      paidPercentage: "62%",
      earned: "$166,000",
      earnedPercentage: "38%",
      loanTerm: "12",
      profileImg: profilePic,
    },
  ];
  const [usersData, setUsersData] = useState(users);

  useEffect(() => {
    setSelectedUser(users[seletedIndex]);
  }, []);

  const handleChange = (e) => {
    setUsersData(users.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase())));
  };

  return (
    <div className="blockchain_sidebar">
      <div className="search_header">
        <div className="search">
          <input
            type="text"
            placeholder="Search Collaborators"
            onChange={handleChange}
          />
          <img
            src={search_icon}
            alt=""
          />
        </div>
      </div>
      <div className="userList">
        {usersData.length !== 0 ? (
          usersData.map((item, index) => {
            return (
              <div
                className={index === seletedIndex ? "singleUser selectedUser" : "singleUser"}
                key={index}
                onClick={() => {
                  setSelectedIndex(index);
                  setSelectedUser(item);
                  setActiveTab(0);
                  window.scrollTo(0, 0);
                }}
              >
                <img
                  src={item.profileImg}
                  alt=""
                  className="userImg"
                />
                <div>
                  <h2>{item.name}</h2>
                  <p>
                    {" "}
                    <img
                      className="userStatusImg"
                      src={item.status === "Active" ? activeIcon : pendingIcon}
                      alt=""
                    />{" "}
                    {item.status}
                  </p>
                </div>
                {/* <img src={activeIcon} alt /> */}
              </div>
            );
          })
        ) : (
          <h2 style={{textAlign: "center", fontFamily: "Roboto", marginTop: "20px", fontWeight: "500", fontSize: "20px"}}>No User Found 😰</h2>
        )}
      </div>
    </div>
  );
};

export default BlockChainSideBar;
