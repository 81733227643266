import React from "react";
import "./BorrowerProfile.scss";
import msgIcon from "../../../assets/svg/msgIcon.svg";
import locationIcon from "../../../assets/svg/locationIocn.svg";
import phoneIcon from "../../../assets/svg/phoneIcon.svg";
import see_more from '../../../assets/svg/see_more_icon.svg';

const BorrowerProfile = () => {
  return (
    <div className="borrower_profile_container">
      <div className="basic_details">
        <h1>Basic Details</h1>
        <div className="info">
          <div>
            <img
              src={msgIcon}
              alt=""
            />
            <p>me@angenewton.co</p>
          </div>
          <div>
            <img
              src={phoneIcon}
              alt=""
            />
            <p>+1 (200) 7887 665 </p>
          </div>
          <div>
            <img
              src={locationIcon}
              alt=""
            />
            <p>United States</p>
          </div>
        </div>
      </div>
      <div className="bio_details">
        <h1>bio</h1>
        <div className="info">
          <p>
            Working in automotive quality management for over 3 years. A passionate traveller, loves to spend time with her dog. Wine taster. Biggest dream is to travel the world with all the money I
            make from renting building to my dearest customers who pay well.{" "}
          </p>
        </div>
      </div>
      <div className="tags_details">
        <h1>Tags</h1>
        <div className="info">
          <div
            className="tagName"
            style={{background: "#34AA44"}}
          >
            Office Management
          </div>
          <div
            className="tagName"
            style={{background: "#F6AB2F"}}
          >
            Commercial
          </div>
          <div
            className="tagName"
            style={{background: "#1665D8"}}
          >
            Development
          </div>
        </div>
      </div>
      <div className="lent_details">
        <div className="info">
          <div>
            <p>lent</p>
            <h1>$5,080,000</h1>
            <span>52% more than average</span>
          </div>
          <div>
            <p>Average loan</p>
            <h1>$74,705</h1>
            <span>52% more than average</span>
          </div>
          <div>
            <p>MAX loan</p>
            <h1>$1,000,000</h1>
            <span>52% more than average</span>
          </div>
        </div>
      </div>
      <div className="conditions_details">
        <div className="heading">
          <h1>Conditions</h1>
          <p>See More <img src={see_more} alt="" /></p>
        </div>
        <div className="info">
          <p>
            Working in automotive quality management for over 3 years. A passionate traveller, loves to spend time with her dog. Wine taster. Biggest dream is to travel the world with all the money I
            make from renting building to my dearest customers who pay well.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BorrowerProfile;
