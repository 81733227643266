import React from 'react'
import './KeyDeal.scss'
import AssestCard from './AssetCard'
import photo from "../../assets/acqusition/svg/photo.svg";
import location from "../../assets/acqusition/svg/location.svg";
import street from "../../assets/acqusition/svg/street.svg";
import bookmark from "../../assets/acqusition/svg/bookmark.svg";
import share from "../../assets/acqusition/svg/share.svg";
import compare from "../../assets/acqusition/svg/compare.svg";
import cross from '../../assets/svg/cross-circle.svg'
import CrowdfundingHeader from './CrowdFundingHeader';

function KeyDeal() {

    const keydealdata = [
        {
          id: 1,
          heading: 'Focus on Driving Returns with Steeply Discounted Assets',
          text: 'McFarlin Group seeks to generate superior returns by acquiring distressed senior living assets including poorly positioned, over leveraged or under-capitalized assets that provide an opportunity for acquisition at a steep discount to replacement cost. COVID-19 has created a unique opportunity to acquire hard-hit senior living assets at considerable discounts which the fund will seek to capitalize upon.',
        },
        {
            id: 2,
            heading: 'Focus on Driving Returns with Steeply Discounted Assets',
            text: 'McFarlin Group seeks to generate superior returns by acquiring distressed senior living assets including poorly positioned, over leveraged or under-capitalized assets that provide an opportunity for acquisition at a steep discount to replacement cost. COVID-19 has created a unique opportunity to acquire hard-hit senior living assets at considerable discounts which the fund will seek to capitalize upon.',
          },
          {
            id: 3,
            heading: 'Focus on Driving Returns with Steeply Discounted Assets',
            text: 'McFarlin Group seeks to generate superior returns by acquiring distressed senior living assets including poorly positioned, over leveraged or under-capitalized assets that provide an opportunity for acquisition at a steep discount to replacement cost. COVID-19 has created a unique opportunity to acquire hard-hit senior living assets at considerable discounts which the fund will seek to capitalize upon.',
          },
          {
            id: 4,
            heading: 'Focus on Driving Returns with Steeply Discounted Assets',
            text: 'McFarlin Group seeks to generate superior returns by acquiring distressed senior living assets including poorly positioned, over leveraged or under-capitalized assets that provide an opportunity for acquisition at a steep discount to replacement cost. COVID-19 has created a unique opportunity to acquire hard-hit senior living assets at considerable discounts which the fund will seek to capitalize upon.',
          },
      ];
      
  return (
    <div className='keydeal'>
    <CrowdfundingHeader title={'Key Deal Points'} />
    <div className='keydeal_container'>
      <div className='keydeal_center'>
      {keydealdata.map((deal) => (
            <KeyDealBox key={deal.id} {...deal} />
          ))}    </div>
    <div className='keydeal_right'>
        <AssestCard />
    </div>
    </div> </div>
  )
}

export default KeyDeal


function KeyDealBox({ id, heading, text }) {
    return (
      <div className='keydeal_box'>
        <div className='keydeal_box_left'>{id}</div>
        <div className='keydeal_box_right'>
          <h2>{heading}</h2>
          <p>{text}</p>
        </div>
      </div>
    );
  }
  