import React from "react";
import Chart from "react-apexcharts";
import "./style.scss"; // Make sure to create and style this file as needed

const WeeklyPerformanceChart = () => {
  const data = {
    series: [
      {
        name: "Interactions",
        data: [200, 300, 150, 250, 350, 200, 200], // Example data for interactions
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ["M", "T", "W", "T", "F", "S", "S"],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
    },
  };

  return (
    <div className="weekly-performance">
      <div className="header">
        <p>WEEKLY PERFORMANCE</p>
        <p>Last 7 Days</p>
      </div>
      <div className="chart">
        <Chart
          options={data.options}
          series={data.series}
          type="bar"
          height={200}
          width={460}
        />
      </div>
      <div className="metrics">
        <div className="metric">
          <div>
            <h3>Profile Visits</h3>
            <p className="comparison">+263 vs. 21 May - 27 May</p>
          </div>

          <h2>1,369</h2>
        </div>
        <div className="metric">
          <div>
            <h3>Website Clicks</h3>
            <p className="comparison">+48 vs. 21 May - 27 May</p>
          </div>
          <h2>75</h2>
        </div>
        <div className="metric">
          <div>
            <h3>Emails</h3>
            <p className="comparison">+1 vs. 21 May - 27 May</p>
          </div>
          <h2>2</h2>
        </div>
      </div>
    </div>
  );
};

export default WeeklyPerformanceChart;
