import React, {useEffect, useRef, useState} from "react";
import "./style.scss";
import PageStatisticsChart from "../Charts/PastInvestmentsChart";
import ActiveInvestmentsChart from "../Charts/ActiveInvestmentsChart";
import calander from "../../../assets/svg/calender.svg";
import upIcon from "../../../assets/svg/upIcon.svg";
import downIcon from "../../../assets/svg/downIcon.svg";
import search from "../../../assets/svg/search.svg";
import northGroup from "../../../assets/Image/Investment_Pipeline/northGroup.png";
import buildingOne from "../../../assets/svg/buildingOne.svg";
import buildingTwo from "../../../assets/svg/buildingTwo.svg";
import filter from "../../../assets/svg/filter_icon.svg";
import info from "../../../assets/svg/info.svg";
import AuroraAssests from "../../../assets/Image/Investment_Pipeline/AuroraAssets.png";
import DollarSilver from "../../../assets/svg/dollarSilver.svg";
import ArrowUp from "../../../assets/svg/ArrowUp.svg";
import ArrowDown from "../../../assets/svg/ArrowDown.svg";
import PastInvestmentsChart from "../Charts/PastInvestmentsChart";
import SyndicatesPerformanceChart from "../Charts/SyndicatesPerformanceChart";
import syndicateImg from "../../../assets/Image/Investment_Pipeline/Pic.png";
import syndicateTableProfileImg from "../../../assets/Image/Investment_Pipeline/syndicateTableProfileImg.png";
import ReactTable from "../../../pages/InvestmentPipeline/reusuableComponents/ReactTable/ReactTable";

const SyndicateOwnerAnalytics = () => {
  const data = React.useMemo(
    () => [
      {
        syndicateName: "Aspen Hills Apartments",
        comment: "What's a Good CAP Rate to Buy Real Estate?",
        created: "08 Nov 2018",
        postedBy: "Hester Moreno",
        role: "Researchers",
        reactions: 248,
        replies: 1241,
        image: syndicateImg,
        userImage: syndicateTableProfileImg,
      },
      {
        syndicateName: "Waltham Woods Apartments",
        comment: "A company’s portfolio of real estate holdings sho...",
        created: "09 Sep 2018",
        postedBy: "Tommy Price",
        role: "UK Team",
        reactions: 156,
        replies: 832,
        image: syndicateImg,
        userImage: syndicateTableProfileImg,
      },
      {
        syndicateName: "Aspen Hills Apartments",
        comment: "Executives need a “snapshot” of the compa...",
        created: "13 Oct 2018",
        postedBy: "Phoebe Watkins",
        role: "External Expert",
        reactions: 36,
        replies: 263,
        image: syndicateImg,
        userImage: syndicateTableProfileImg,
      },
      {
        syndicateName: "Aspen Hills Apartments",
        comment: "What's a Good CAP Rate to Buy Real Estate?",
        created: "30 May 2018",
        postedBy: "Jayden Day",
        role: "SF Dev Team",
        reactions: 248,
        replies: 632,
        image: syndicateImg,
        userImage: syndicateTableProfileImg,
      },
      {
        syndicateName: "Waltham Woods Apartments",
        comment: "A company’s portfolio of real estate holdings sho...",
        created: "20 Oct 2018",
        postedBy: "Jesus Garner",
        role: "UK Office",
        reactions: 154,
        replies: 62,
        image: syndicateImg,
        userImage: syndicateTableProfileImg,
      },
    ],
    [],
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "SYNDICATE NAME",
        accessor: "syndicateName",
        Cell: ({cell: {value}, row: {original}}) => (
          <div className="syndicate-cell">
            <img
              src={original.image}
              alt={value}
              className="syndicate-image"
            />
            <p> {value}</p>
          </div>
        ),
      },
      {
        Header: "COMMENT",
        accessor: "comment",
      },
      {
        Header: "CREATED",
        accessor: "created",
      },
      {
        Header: "POSTED BY",
        accessor: "postedBy",
        Cell: ({cell: {value}, row: {original}}) => (
          <div className="posted-by-cell">
            <img
              src={original.userImage}
              alt={value}
              className="user-image"
            />
            <div>
              <p>{value}</p>
              <p className="role">{original.role}</p>
            </div>
          </div>
        ),
      },
      {
        Header: "REACTIONS",
        accessor: "reactions",
      },
      {
        Header: "REPLIES",
        accessor: "replies",
      },
    ],
    [],
  );
  return (
    <div className="SyndicateOwnerAnalytics_main">
      <div className="SyndicateOwnerAnalytics_header">
        <h3>Portfolio Analytics</h3>
      </div>

      <div className="syndicates_highilites">
        <div className="syndicates_highilites_header">
          <div>
            <h1>Syndicates Highilites</h1>
          </div>
          <div className="syndicates_highilites_calander_and_button">
            <div className="syndicates_highilites_calander">
              <div>
                <img
                  src={calander}
                  alt=""
                />
                <p>8 Apr 20</p>
              </div>
              {"-"}
              <div>
                <img
                  src={calander}
                  alt=""
                />
                <p>8 Apr 20</p>
              </div>
            </div>
            <div className="all_syndicates">All Syndicates</div>
          </div>
        </div>

        <div className="syndicates_highilites_data">
          <div className="syndicates_highilites_data_one">
            <p>Unique Users</p>
            <h4>18,325</h4>
            <div className="syndicates_highilites_data_one_percentage">
              <img
                src={upIcon}
                alt="upIcon"
              />
              <p>9.8%</p>
            </div>
            <p>Compare to average</p>
          </div>
          <div className="syndicates_highilites_data_one">
            <p>Unique Users</p>
            <h4>18,325</h4>
            <div className="syndicates_highilites_data_one_percentage">
              <img
                src={downIcon}
                alt="downIcon"
              />
              <p className="negative">9.8%</p>
            </div>
            <p>Compare to average</p>
          </div>
          <div className="syndicates_highilites_data_one">
            <p>Unique Users</p>
            <h4>18,325</h4>
            <div className="syndicates_highilites_data_one_percentage">
              <img
                src={upIcon}
                alt="upIcon"
              />
              <p>9.8%</p>
            </div>
            <p>Compare to average</p>
          </div>
          <div className="syndicates_highilites_data_one">
            <p>Unique Users</p>
            <h4>18,325</h4>
            <div className="syndicates_highilites_data_one_percentage">
              <img
                src={upIcon}
                alt="upIcon"
              />
              <p>9.8%</p>
            </div>
            <p>Compare to average</p>
          </div>
          <div className="syndicates_highilites_data_one">
            <p>Unique Users</p>
            <h4>18,325</h4>
            <div className="syndicates_highilites_data_one_percentage">
              <img
                src={upIcon}
                alt="upIcon"
              />
              <p>9.8%</p>
            </div>
            <p>Compare to average</p>
          </div>
        </div>
      </div>

      <div className="syndicates_highilites_Chart">
        <div className="syndicates_highilites_header">
          <div>
            <h1>Syndicates Performence</h1>
          </div>
          <div className="syndicates_highilites_calander_and_button">
            <div className="syndicates_highilites_calander">
              <div>
                <img
                  src={calander}
                  alt=""
                />
                <p>8 Apr 20</p>
              </div>
              {"-"}
              <div>
                <img
                  src={calander}
                  alt=""
                />
                <p>8 Apr 20</p>
              </div>
            </div>
            <div className="all_syndicates">All Syndicates</div>
          </div>
        </div>
        <SyndicatesPerformanceChart />
      </div>

      <div className="porfolio_performence_tableOne">
        <h3>My Cash-flowing Investments</h3>
        <ReactTable
          columns={columns}
          data={data}
        />
      </div>
    </div>
  );
};

export default SyndicateOwnerAnalytics;
