import {useState} from "react";
import React from "react";
import "./AValuations.scss";
import Convertibles from "../../../assets/Manage-syndicate/image/Convertibles.png";
import {PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import time from "../../../assets/Manage-syndicate/Svg/time.svg";

const AValuations = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "How is my valuation conducted, and how do I make sue I get the best 409A",
      // answer: "ukyfgyiuhbuiogiugug",
    },
    {
      question: "How do you request a 409A from Carta and start the process",
      // answer: "ukyfgyiuhbuiogiugug",
    },
    {
      question: "What information does the questionnaire require?",
      // answer: "ukyfgyiuhbuiogiugug",
    },
    {
      question: "How much does it cost to upgrade and how do I upgrade",
      // answer: "ukyfgyiuhbuiogiugug",
    },
    {
      question: "How do I get help?",
      // answer: "ukyfgyiuhbuiogiugug",
    },
    {
      question: "What is a 409A and why do I need it?",
      // answer: "ukyfgyiuhbuiogiugug",
    },
  ];
  return (
    <div>
      <div style={{marginTop: "10px", marginBottom: "10px"}}>
        <h1>Convertibles</h1>
      </div>
      <div className="AValuations-Conatiner-wrapper">
        <div>
          <div className="AValuations-Conatiner">
            <div className="AValuations-Conatiner-data">
              <div className="AValuations-Conatiner-data-conatiner">
                <h5>Add fair market value (FMV) into Carta</h5>
                <p>If you are not due for a new 409A, you can add your last fair market value (FMV) into Carta.</p>
              </div>
              <div>
                <PrimaryButtonSvgFirst
                  name="Add FMV"
                  classNames="CabTable-Filter"
                  padding="10px"
                  svgHeight="20px"
                  svgWidth="20px"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="AValuations-container-image">
            <div className="AValuations-container-image-data">
              <div className="Learn-more">
                <img
                  src={Convertibles}
                  alt="image"
                />
                <h1>Learn more about 409As</h1>
                <hr />
                <p>Still have questions about 409s? Chat with our soeccialist and learn more.</p>
                <PrimaryButtonSvgFirst
                  name="Schedule a call"
                  classNames="CabTable-Filter"
                  padding="10px"
                  svgHeight="20px"
                  svgWidth="20px"
                />
              </div>
              <div className="Fast-fact">
                <div className="Fast-fact-data">
                  <div>
                    <h1>Fast fact about Carta 409A</h1>
                    <hr />
                  </div>
                  <img
                    src={time}
                    alt="image"
                  />
                </div>
                <p>Carat deliver ove 14,000,409A valuations every year. We’re audit-defensible, fast, and accurate</p>
                <p>
                  Our team of analysts comes from the world’s most respected according firms- including Deloitte, Ernst & Young, KPMG, PricewaterhouseCoopers, Andersen Tax, Grant Thornton, Mass Adams,
                  and more
                </p>
                <p>Combing industry -leading data with software allows our deliver accurate, audit- ready 409A valuations in the fastest turnaround possible.</p>
                <p>
                  With nearly halp of all the venture- backed companies in America using Carta, our team has access to more market insights than any other 409A provider- especially for niche
                  industries ike biotech and crypto.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="wrapper faqBox">
            <h1>Frequently Asked Questions</h1>
            {faqData.map((faq, index) => (
              <div
                className="faq"
                key={index}
              >
                <button
                  className={`accordion ${activeIndex === index ? "active" : ""}`}
                  onClick={() => toggleAccordion(index)}
                >
                  {faq.question}
                  <i className="fa-solid fa-chevron-down"></i>
                </button>
                <div className={`pannel ${activeIndex === index ? "active" : ""}`}>
                  <p>{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AValuations;
