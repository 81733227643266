import React, {useEffect, useRef} from "react";
import "./GeneralPartner.scss";
import AssestCard from "./AssetCard";
import photo from "../../assets/acqusition/svg/photo.svg";
import location from "../../assets/acqusition/svg/location.svg";
import street from "../../assets/acqusition/svg/street.svg";
import bookmark from "../../assets/acqusition/svg/bookmark.svg";
import share from "../../assets/acqusition/svg/share.svg";
import compare from "../../assets/acqusition/svg/compare.svg";
import bg from "../../assets/Image/assetDiscovery/general-bg.png";
import {useState} from "react";
import Summary from "./Summary";
import GeneralInvestments from "./GeneralInvestments";
import More from "./More";
import ArrowDown from "../../assets/Image/assetDiscovery/arrow_down.svg";
import Woman from "../../assets/Image/assetDiscovery/woman.png";
import follow from "../../assets/Image/assetDiscovery/follow.svg";
import comment from "../../assets/notes/comment.svg";
import CrowdfundingHeader from "./CrowdFundingHeader";

function GeneralPartner() {
  const [navActive, setNavActive] = useState(1);
  const [navDropdownActive, setNavDropdownActive] = useState(1);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  // useEffect(() => {
  //     const handleClickOutside = (event) => {
  //         if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //             setDropdownVisible(false);
  //         }
  //     };

  //     document?.addEventListener('mousedown', handleClickOutside);

  //     return () => {
  //         document?.removeEventListener('mousedown', handleClickOutside);
  //     };
  // }, [dropdownRef]);

  return (
    <div className="general">
      <CrowdfundingHeader title={"General Partner"} />
      <div className="general_container">
        <div className="general_center">
          <div className="general_upper">
            <div
              className="bg"
              style={{background: `url(${bg})`}}
            >
              <img
                className="img_left"
                src={Woman}
                alt=""
              />
              <div className="bg_right">
                <p>Followed by 55 of your Contacts</p>
                <div className="imgs">
                  <img
                    src={Woman}
                    alt=""
                  />
                  <img
                    src={Woman}
                    alt=""
                  />
                  <img
                    src={Woman}
                    alt=""
                  />
                  <img
                    src={Woman}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="info">
              <div className="general_nav">
                <div className=" deals-room-nav asset-nav ass-nav">
                  <span
                    style={{display: "flex", gap: "10px", alignItems: "center"}}
                    className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
                    onClick={() => {
                      setNavActive(1);
                    }}
                  >
                    Summary{" "}
                  </span>
                  <span
                    style={{display: "flex", gap: "10px", alignItems: "center"}}
                    className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
                    onClick={() => {
                      setNavActive(2);
                    }}
                  >
                    Investments
                  </span>
                  <span
                    style={{display: "flex", gap: "10px", alignItems: "center", position: "relative"}}
                    className={navActive === 3 && "deals-room-nav-active ut-nav-active more"}
                    onClick={() => {
                      setNavActive(3);
                      setDropdownVisible(!dropdownVisible);
                    }}
                  >
                    More{" "}
                    <img
                      src={ArrowDown}
                      alt=""
                    />
                    {dropdownVisible && (
                      <div
                        className="more_dropdown"
                        ref={dropdownRef}
                      >
                        <p
                          onClick={() => {
                            setNavDropdownActive(1);
                          }}
                        >
                          syndicates
                        </p>
                        <p
                          onClick={() => {
                            setNavDropdownActive(2);
                          }}
                        >
                          Funds
                        </p>
                        <p
                          onClick={() => {
                            setNavDropdownActive(3);
                          }}
                        >
                          Posts
                        </p>
                        <p
                          onClick={() => {
                            setNavDropdownActive(4);
                          }}
                        >
                          connections
                        </p>
                        <p
                          onClick={() => {
                            setNavDropdownActive(5);
                          }}
                        >
                          reviews
                        </p>
                      </div>
                    )}
                  </span>
                </div>{" "}
              </div>

              <div className="btns">
                <button className="btn_left">
                  <img
                    src={comment}
                    alt=""
                  />
                </button>
                <button className="btn_right">
                  <img
                    src={follow}
                    alt=""
                  />
                  Follow
                </button>
              </div>
            </div>
          </div>

          <div className="general_lower">
            {navActive === 1 && <Summary />}
            {navActive === 2 && <GeneralInvestments />}
            {navActive === 3 && <More navDropdownActive={navDropdownActive} />}
          </div>
        </div>
        <div className="general_right">
          <AssestCard />
        </div>
      </div>{" "}
    </div>
  );
}

export default GeneralPartner;
