import React, {useRef, useState, useEffect} from "react";
import "./style.scss";

import search from "../../../../assets/svg/search.svg";
import filter from "../../../../assets/svg/filter_icon.svg";
import info from "../../../../assets/svg/info.svg";
import star from "../../../../assets/svg/star.svg";
import {useNavigate} from "react-router-dom";

const ActiveInvestment = ({investments, loading}) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const filterRef = useRef(null);

  const navigate = useNavigate();

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (filterRef.current && !filterRef.current.contains(event.target)) {
  //       setIsFilterModalOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [filterRef]);

  useEffect(() => {
    if (loading) {
      setIsFilterModalOpen(false);
    }
  }, [loading]);

  return (
    <div className="active_investment">
      <div className="active_investment_header">
        <h3>Active Investments</h3>

        <div className="active_investment_one">
          <img src={search} alt="search" />
          <button
            className="active_investment_filter_button"
            onClick={() => setIsFilterModalOpen(!isFilterModalOpen)}
            disabled={loading}
          >
            <img src={filter} alt="filter" />
            <p>Filter</p>
          </button>
        </div>

        {isFilterModalOpen && (
          <div className="filter_modal_overlay">
            <div className="filter_modal" ref={filterRef}>
              <div className="filter_modal_head">
                <h2>Filters</h2>
              </div>
              <div className="filter_modal_one">
                <p>Asset Type</p>
                <button>Commercial</button>
              </div>
              <div className="filter_modal_two">
                <p>Asset Type</p>
                <button>NorthInvest Group</button>
              </div>
              <div className="filter_modal_button">
                <button className="apply">Apply</button>
                <button
                  className="cancel"
                  onClick={() => setIsFilterModalOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="active_investment_genralPartner">
        <div>
          <h3>Investments</h3>
        </div>
        <div>
          <h3>Multifamily</h3>
        </div>
        <div>
          <h3>Commercial</h3>
        </div>
        <div>
          <h3>All</h3>
        </div>
      </div>

      <div className="active_investment_middle">
        <p>Name</p>
        <p style={{marginLeft: "120px"}}>Investing Entity</p>
        <div className="genralPartner">
          <p>Performance</p>
        </div>
        <div style={{display: "flex", gap: "10px"}}>
          <p>Performance</p>
          <img src={info} alt="info" />
        </div>
      </div>

      <div className="active_investment_lower">
        {loading ? (
          <div className="shimmer-container">
            {[...Array(4)].map((_, index) => (
              <div key={index} className="shimmer-wrapper">
                <div className="shimmer-image shimmer"></div>
                <div className="shimmer-text shimmer"></div>
              </div>
            ))}
          </div>
        ) : (
          investments?.map((item, index) => (
            <div
              key={index}
              className="active_investment_inner"
            >
              <div
                className="active_investment_one"
                onClick={() => navigate("/investment/apartment-details")}
              >
                <div>
                  <img
                    className="active_investment_image"
                    src={item.asset.assetImages[0]}
                    alt="Investment Asset"
                  />
                </div>
                <div className="active_investment_assetdetailscontainer">
                  <h3>{item.asset.assetName}</h3>
                  <p>{item.asset.assetAddress}</p>
                </div>
              </div>
              <div className="active_investment_two">
                <h3>{item?.investmentEntity}</h3>
              </div>
              <div className="active_investment_three genralPartner">
                <img src={star} alt="star" />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ActiveInvestment;
