import React from 'react'
import './SavedHeader.scss'
import DropDown from '../../../components/reusableComponents/Dropdown/Dropdown'
import { useState } from 'react';
import calendar from '../../../assets/svg/calender.svg'
import sort from '../../../assets/svg/sort.svg'

function SavedHeader({title , dd1 , ld}) {

    const [timeList, setTimeList] = useState(["Hourly", "Daily"]);
    const [selectedTimeList, setSelectedTimeList] = useState("Hourly");
    const [timeListDa, setTimeListDa] = useState(false);

    return (
        <div className='savedHeader'>
            <h2>{title}</h2>
            <div style={{ display: 'flex', gap: '10px' }}>
                {!dd1 ? '' :
                <DropDown
                    label=""
                    selectedData={'Commercial'}
          
                /> }
                {ld && 
                 <DropDown
                 label=""
                 label2={'Labels:'}
                 selectedData={'All Labels'}
       
             /> }
                
                <DropDown
                    label=""
                    label2={''}
                    Img={calendar}
                    selectedData={'This Year'}
            
                />
                <DropDown
                    label=""
                    label2={'Sort:'}
                    Img2={sort}
                    paddingLeft={'28px'}
                    selectedData={'Date Added'}
                 
                />
            </div>
        </div>
    )
}

export default SavedHeader 