import React from "react";
import {PrimaryButton} from "../../../components/reusableComponents/Buttons/Buttons";
import "./Communications.scss";
import SearchInput from "../../../components/reusableComponents/Search/Search";
import message from "../../../assets/Manage-syndicate/Svg/no-spam.svg";

const Communications = () => {
  return (
    <div>
      <div style={{marginTop: "10px", marginBottom: "10px"}}>
        <h1>Communication Center</h1>
      </div>
      <div className="Communications-flex-data">
        <div className="Communications-header">
          <SearchInput />
          <PrimaryButton
            name="New share draft set"
            classNames="Communications-New-share"
            padding="10px"
            svgHeight="20px"
            svgWidth="20px"
          />
        </div>
        <div className="Communications-header-data">
          <div className="Communications-header-span">
            <span>DRAFT NAME</span>
            <span>SUBJECT</span>
            <span>STATUS</span>
            <span>OPEN RATE </span>
            <span>RECIPIENTS</span>
            <span>AUTHER</span>
          </div>
        </div>
      </div>
      <div>
        <div className="Communications-data">
          <div className="Communications-data-container-maindiv">
            <div className="Communications-Error-Container">
              <div className="Communications-data-container-image">
                <img
                  src={message}
                  alt="image"
                />
              </div>
              <p>No message have been sent yet.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Communications;
