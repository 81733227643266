import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import Endpoint from "../../utils/Endpoint";

export const fetchFinancialData = createAsyncThunk("financial/fetchFinancialData", async (property_code, {rejectWithValue, getState}) => {
  try {
    const {auth} = getState();
    const idToken = auth.idToken;
    const response = await axios.get(Endpoint.KPI, {
      headers: {
        "auth-token": idToken,
      },
      params: {
        property_code,
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const financialSlice = createSlice({
  name: "financial",
  initialState: {
    financialData: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFinancialData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFinancialData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.financialData = action.payload;
      })
      .addCase(fetchFinancialData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default financialSlice.reducer;
