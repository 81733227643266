import React from "react";
import hero_img from "../../assets/Image/opportunity_hero.png";
import user_coverImg_1 from "../../assets/Image/Rectangle 1150.png";
import user_profile from "../../assets/Image/user_profile.png";
import user_p_2 from "../../assets/Image/user_with_smile.png";
import user_p_3 from "../../assets/Image/normal_user.png";
import bulding_icon from "../../assets/svg/bulingtt.svg";
import devider from "../../assets/svg/Divider.svg";
import circle from "../../assets/svg/Green.svg";
import user_icon from "../../assets/svg/32.svg";
import grpImg from "../../assets/svg/groups_svg.svg";
import grpTwoImg from "../../assets/svg/GroupTwo.svg";
import follow from "../../assets/svg/Page 1.svg";
import view from "../../assets/svg/eye.svg";
import cmt from "../../assets/svg/cmt.svg";
import chart_icon from "../../assets/svg/greenGraph.svg";
import hrLine from "../../assets/svg/hr_line.svg";
import rotate from "../../assets/svg/rotate.svg";
import badge from "../../assets/svg/Badge.svg";
import companies_group from "../../assets/svg/company_group.svg";
import c_grp from "../../assets/svg/companies_grops_svg.svg";
import trust_grp from "../../assets/svg/trust_grp_svg.svg";
import real_estate_pic from "../../assets/svg/real_estate_svg.svg";
import commercial_img from "../../assets/Image/Pic.png";
import left_slider from "../../assets/svg/slider_left_icon.svg";
import right_slider from "../../assets/svg/slider_right_icon.svg";
import rangeTrack from "../../assets/svg/Range + Track.svg";
import "./Opportunity.scss";
import PropertyCard from "./PropertyCard/PropertyCard";
import last_grp from "../../assets/svg/last_grp.svg";
import eye_iconT from "../../assets/svg/eye_icon_svg.svg";
import location_svg from "../../assets/svg/location_icon_svg.svg";
import imgOne from "../../assets/Image/city_jpg.jpg";
import {useNavigate} from "react-router-dom";

const Opportunities = () => {
  const navigate = useNavigate();
  return (
    <div className="opportunity_main">
      <div className="opportunity_hero_img">
        <img
          src={hero_img}
          alt=""
        />
      </div>
      <div className="opportunity_search">
        <h1> Search for Professionals you need</h1>
        <div className="opportunity_search_div">
          <div className="opportunity_search_child">
            <div className="opportunity_search_input">
              <input
                type="text"
                placeholder="Search by Title, Intrests, Company"
              />
            </div>
            <select
              name=""
              id=""
            >
              <option>All Categories</option>
            </select>
          </div>
          <div className="opportunity_state_city"></div>
          <div className="opportunity_state_city_input">
            <input
              type="text"
              placeholder="State, City or Zip Code"
            />
          </div>
          <button onClick={() => navigate("/opportunities-search")}> Search</button>
        </div>
        <div className="opportunity_popular">
          <span >Popular</span>
          <span>North</span>
          <span>Commercial</span>
          <span>Multifamily</span>
          <span>Baltimore , MD</span>
          <span>Offices</span>
        </div>
      </div>

      <div className="opportunity_details">
        <div className="individuals_to_follow">
          <div className="recommended_users_heading">
            <div className="user-icon">
              <img
                src={user_icon}
                alt="user-icon"
              />
            </div>
            <div>
              <h1>Recommended Individuals to Follow</h1>
              <p>Based on your intrest, invesments, and search history</p>
            </div>
          </div>
          <div className="users">
            <div className="user_list">
              <div className="single_user">
                <div className="cover_img">
                  <img
                    src={user_coverImg_1}
                    alt="user_cover_img"
                    width="100%"
                  />
                </div>
                <div className="user_pic">
                  <img
                    src={user_profile}
                    alt="user_img"
                    style={{borderRadius: "50%", border: "2px solid #ffffff"}}
                  />
                </div>
                <h2 style={{display: "flex", alignItems: "center", gap: "5px"}}>
                  Emily Byrd{" "}
                  <img
                    src={badge}
                    alt=""
                  />
                </h2>
                <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                  <div className="user_type">1st</div>
                  <img
                    src={circle}
                    alt="circle"
                    style={{borderRadius: "50%"}}
                  />
                  <span>Investor</span>
                </div>
                <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                  <div className="bulding_circle">
                    <img
                      src={bulding_icon}
                      alt=""
                    />
                  </div>
                  <p>NorthGroup Invest</p>
                </div>
                <div
                  style={{display: "flex", flexDirection: "column"}}
                  className="total_property"
                >
                  <span>Owns 12 properties with</span>
                  <span>assessed value $14,349,409</span>
                </div>

                <div className="matching">
                  <p>Matching</p>
                  <span style={{color: "#3E3F42", fontSize: "14px", fontWeight: "500", lineHeight: "22px", fontStyle: "normal", fontWeight: "400"}}>
                    <img
                      src={chart_icon}
                      alt=""
                      height="12px"
                      width="12px"
                    />{" "}
                    85%
                  </span>
                </div>
                <div className="mutual">
                  <div>
                    <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                      <img
                        src={grpImg}
                        alt=""
                      />
                      <p>102</p>
                    </div>
                    <span>Mutual Connection</span>
                  </div>
                  <div>
                    <img
                      src={devider}
                      alt="devider"
                    />
                  </div>
                  <div>
                    <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                      <img
                        src={grpTwoImg}
                        alt=""
                      />
                      <p>89</p>
                    </div>
                    <span> Mutual Investments</span>
                  </div>
                </div>
                <div className="follow_see_cmt">
                  <button className="follow_btn">
                    <img
                      src={follow}
                      alt=""
                    />{" "}
                    Follow
                  </button>
                  <button className="eye_btn">
                    <img
                      src={view}
                      alt=""
                    />
                  </button>
                  <button className="cmt_btn">
                    <img
                      src={cmt}
                      alt=""
                    />{" "}
                  </button>
                </div>
              </div>
              <div className="single_user">
                <div className="cover_img">
                  <img
                    src={user_coverImg_1}
                    alt="user_cover_img"
                    width="100%"
                  />
                </div>
                <div className="user_pic">
                  <img
                    src={user_p_2}
                    alt="user_img"
                    style={{borderRadius: "50%", border: "2px solid #ffffff"}}
                  />
                </div>
                <h2 style={{display: "flex", alignItems: "center", gap: "5px"}}>
                  Harvey Rivera{" "}
                  <img
                    src={badge}
                    alt=""
                  />
                </h2>

                <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                  <div className="user_type">1st</div>
                  <img
                    src={circle}
                    alt="circle"
                    style={{borderRadius: "50%"}}
                  />
                  <span>Investor</span>
                </div>
                <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                  <div className="bulding_circle">
                    <img
                      src={bulding_icon}
                      alt=""
                    />
                  </div>
                  <p>NorthGroup Invest</p>
                </div>
                <div
                  style={{display: "flex", flexDirection: "column"}}
                  className="total_property"
                >
                  <span>Owns 12 properties with</span>
                  <span>assessed value $14,349,409</span>
                </div>

                <div className="matching">
                  <p>Matching</p>
                  <span style={{color: "#3E3F42", fontSize: "14px", fontWeight: "500", lineHeight: "22px", fontStyle: "normal", fontWeight: "400"}}>
                    <img
                      src={chart_icon}
                      alt=""
                      height="12px"
                      width="12px"
                    />{" "}
                    85%
                  </span>
                </div>
                <div className="mutual">
                  <div>
                    <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                      <img
                        src={grpImg}
                        alt=""
                      />
                      <p>102</p>
                    </div>
                    <span>Mutual Connection</span>
                  </div>
                  <div>
                    <img
                      src={devider}
                      alt="devider"
                    />
                  </div>
                  <div>
                    <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                      <img
                        src={grpTwoImg}
                        alt=""
                      />
                      <p>89</p>
                    </div>
                    <span> Mutual Investments</span>
                  </div>
                </div>
                <div className="follow_see_cmt">
                  <button className="follow_btn">
                    <img
                      src={follow}
                      alt=""
                    />{" "}
                    Follow
                  </button>
                  <button className="eye_btn">
                    <img
                      src={view}
                      alt=""
                    />
                  </button>
                  <button className="cmt_btn">
                    <img
                      src={cmt}
                      alt=""
                    />{" "}
                  </button>
                </div>
              </div>
              <div className="single_user">
                <div className="cover_img">
                  <img
                    src={user_coverImg_1}
                    alt="user_cover_img"
                    width="100%"
                  />
                </div>
                <div className="user_pic">
                  <img
                    src={user_p_3}
                    alt="user_img"
                    style={{borderRadius: "50%", border: "2px solid #ffffff"}}
                  />
                </div>
                <h2 style={{display: "flex", alignItems: "center", gap: "5px"}}>
                  Emily Byrd{" "}
                  <img
                    src={badge}
                    alt=""
                  />
                </h2>

                <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                  <div className="user_type">1st</div>
                  <img
                    src={circle}
                    alt="circle"
                    style={{borderRadius: "50%"}}
                  />
                  <span>Investor</span>
                </div>
                <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                  <div className="bulding_circle">
                    <img
                      src={bulding_icon}
                      alt=""
                    />
                  </div>
                  <p>NorthGroup Invest</p>
                </div>
                <div
                  style={{display: "flex", flexDirection: "column"}}
                  className="total_property"
                >
                  <span>Owns 12 properties with</span>
                  <span>assessed value $14,349,409</span>
                </div>

                <div className="matching">
                  <p>Matching</p>
                  <span style={{color: "#3E3F42", fontSize: "14px", fontWeight: "500", lineHeight: "22px", fontStyle: "normal", fontWeight: "400"}}>
                    <img
                      src={chart_icon}
                      alt=""
                      height="12px"
                      width="12px"
                    />{" "}
                    85%
                  </span>
                </div>
                <div className="mutual">
                  <div>
                    <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                      <img
                        src={grpImg}
                        alt=""
                      />
                      <p>102</p>
                    </div>
                    <span>Mutual Connection</span>
                  </div>
                  <div>
                    <img
                      src={devider}
                      alt="devider"
                    />
                  </div>
                  <div>
                    <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                      <img
                        src={grpTwoImg}
                        alt=""
                      />
                      <p>89</p>
                    </div>
                    <span> Mutual Investments</span>
                  </div>
                </div>
                <div className="follow_see_cmt">
                  <button className="follow_btn">
                    <img
                      src={follow}
                      alt=""
                    />{" "}
                    Follow
                  </button>
                  <button className="eye_btn">
                    <img
                      src={view}
                      alt=""
                    />
                  </button>
                  <button className="cmt_btn">
                    <img
                      src={cmt}
                      alt=""
                    />{" "}
                  </button>
                </div>
              </div>
            </div>
            <button className="show_more_btn">
              <img
                src={rotate}
                alt="icon"
              />
              Show More Requests
            </button>
          </div>
        </div>

        <div className="companies_to_follow">
          <div className="recommended_users_heading">
            <div className="user-icon">
              <img
                src={c_grp}
                alt="user-icon"
              />
            </div>
            <div>
              <h1>Recommended Companies to Follow</h1>
              <p>Based on your interests, investments, and search history</p>
            </div>
          </div>
          <div className="users">
            <div className="user_list">
              <div className="single_user">
                <div className="cover_img">
                  <img
                    src={user_coverImg_1}
                    alt="user_cover_img"
                    width="100%"
                  />
                </div>
                <div
                  className="comapnies_grp_pic"
                  style={{borderRadius: "50%", border: "2px solid #ffffff"}}
                >
                  <img
                    src={companies_group}
                    alt="user_img"
                  />
                </div>
                <h2 style={{display: "flex", alignItems: "center", gap: "5px"}}>Rivera Group </h2>

                <div
                  style={{display: "flex", flexDirection: "column"}}
                  className="total_property"
                >
                  <span>Owns 12 properties with</span>
                  <span>assessed value $14,349,409</span>
                </div>

                <div className="matching">
                  <p>Matching</p>
                  <span style={{color: "#3E3F42", fontSize: "14px", fontWeight: "500", lineHeight: "22px", fontStyle: "normal", fontWeight: "400"}}>
                    <img
                      src={chart_icon}
                      alt=""
                      height="12px"
                      width="12px"
                    />{" "}
                    85%
                  </span>
                </div>
                <div className="mutual">
                  <div>
                    <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                      <img
                        src={grpImg}
                        alt=""
                      />
                      <p>102</p>
                    </div>
                    <span>Mutual Connection</span>
                  </div>
                  <div>
                    <img
                      src={devider}
                      alt="devider"
                    />
                  </div>
                  <div>
                    <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                      <img
                        src={grpTwoImg}
                        alt=""
                      />
                      <p>89</p>
                    </div>
                    <span> Mutual Investments</span>
                  </div>
                </div>
                <div className="follow_see_cmt">
                  <button className="follow_btn">
                    <img
                      src={follow}
                      alt=""
                    />{" "}
                    Follow
                  </button>
                  <button className="eye_btn">
                    <img
                      src={view}
                      alt=""
                    />
                  </button>
                  <button className="cmt_btn">
                    <img
                      src={cmt}
                      alt=""
                    />{" "}
                  </button>
                </div>
                <div className="for_you">
                  <p>6 Syndicates, </p>
                  <p>1 Fund</p>
                  <b></b>
                  <span>for you</span>
                </div>
              </div>
              <div className="single_user">
                <div className="cover_img">
                  <img
                    src={user_coverImg_1}
                    alt="user_cover_img"
                    width="100%"
                  />
                </div>
                <div
                  className="user_pic"
                  style={{borderRadius: "50%", border: "2px solid #ffffff", height: "50px", width: "50px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#617d92"}}
                >
                  <img
                    src={trust_grp}
                    alt="user_img"
                  />
                </div>
                <h2 style={{display: "flex", alignItems: "center", gap: "5px"}}>
                  East Trust{" "}
                  <img
                    src={trust_grp}
                    alt=""
                  />
                </h2>

                <div
                  style={{display: "flex", flexDirection: "column"}}
                  className="total_property"
                >
                  <span>Owns 12 properties with</span>
                  <span>assessed value $14,349,409</span>
                </div>

                <div className="matching">
                  <p>Matching</p>
                  <span style={{color: "#3E3F42", fontSize: "14px", fontWeight: "500", lineHeight: "22px", fontStyle: "normal", fontWeight: "400"}}>
                    <img
                      src={chart_icon}
                      alt=""
                      height="12px"
                      width="12px"
                    />{" "}
                    85%
                  </span>
                </div>
                <div className="mutual">
                  <div>
                    <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                      <img
                        src={grpImg}
                        alt=""
                      />
                      <p>102</p>
                    </div>
                    <span>Mutual Connection</span>
                  </div>
                  <div>
                    <img
                      src={devider}
                      alt="devider"
                    />
                  </div>
                  <div>
                    <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                      <img
                        src={grpTwoImg}
                        alt=""
                      />
                      <p>89</p>
                    </div>
                    <span> Mutual Investments</span>
                  </div>
                </div>
                <div className="follow_see_cmt">
                  <button className="follow_btn">
                    <img
                      src={follow}
                      alt=""
                    />{" "}
                    Follow
                  </button>
                  <button className="eye_btn">
                    <img
                      src={view}
                      alt=""
                    />
                  </button>
                  <button className="cmt_btn">
                    <img
                      src={cmt}
                      alt=""
                    />{" "}
                  </button>
                </div>
                <div className="for_you">
                  <p>2 Syndicates, </p>

                  <b></b>
                  <span>for you</span>
                </div>
              </div>
              <div className="single_user">
                <div className="cover_img">
                  <img
                    src={user_coverImg_1}
                    alt="user_cover_img"
                    width="100%"
                  />
                </div>
                <div
                  className="user_pic"
                  style={{borderRadius: "50%", border: "2px solid #ffffff", height: "50px", width: "50px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#E25A9E"}}
                >
                  <img
                    src={real_estate_pic}
                    alt="user_img"
                  />
                </div>
                <h2 style={{display: "flex", alignItems: "center", gap: "5px"}}>Emily Byrd </h2>
                <div
                  style={{display: "flex", flexDirection: "column"}}
                  className="total_property"
                >
                  <span>Owns 12 properties with</span>
                  <span>assessed value $14,349,409</span>
                </div>

                <div className="matching">
                  <p>Matching</p>
                  <span style={{color: "#3E3F42", fontSize: "14px", fontWeight: "500", lineHeight: "22px", fontStyle: "normal", fontWeight: "400"}}>
                    <img
                      src={chart_icon}
                      alt=""
                      height="12px"
                      width="12px"
                    />{" "}
                    85%
                  </span>
                </div>
                <div className="mutual">
                  <div>
                    <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                      <img
                        src={grpImg}
                        alt=""
                      />
                      <p>102</p>
                    </div>
                    <span>Mutual Connection</span>
                  </div>
                  <div>
                    <img
                      src={devider}
                      alt="devider"
                    />
                  </div>
                  <div>
                    <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                      <img
                        src={grpTwoImg}
                        alt=""
                      />
                      <p>89</p>
                    </div>
                    <span> Mutual Investments</span>
                  </div>
                </div>
                <div className="follow_see_cmt">
                  <button className="follow_btn">
                    <img
                      src={follow}
                      alt=""
                    />{" "}
                    Follow
                  </button>
                  <button className="eye_btn">
                    <img
                      src={view}
                      alt=""
                    />
                  </button>
                  <button className="cmt_btn">
                    <img
                      src={cmt}
                      alt=""
                    />{" "}
                  </button>
                </div>
                <div className="for_you">
                  {/* <p>6 Syndicates, </p> */}
                  <p>1 Fund</p>
                  <b></b>
                  <span>for you</span>
                </div>
              </div>
            </div>
            <button className="show_more_btn">
              <img
                src={rotate}
                alt="icon"
              />
              Show More Requests
            </button>
          </div>
        </div>

        <div className=" Syndicates_apply">
          <div className="commercials">
            <div className="recommended_users_heading">
              <div className="user-icon">
                <img
                  src={last_grp}
                  alt="user-icon"
                />
              </div>
              <div>
                <h1>Recommended Individuals to Follow</h1>
                <p>Based on your intrest, invesments, and search history</p>
              </div>
            </div>
            <div className="commercial_list">
              <div className="single_commercial">
                <div
                  className="commercial_slider"
                  style={{position: "relative", overflow: "hidden"}}
                >
                  <img
                    src={commercial_img}
                    alt=""
                    width="100%"
                  />
                  <span>Commercial</span>
                  <div className="commercial_slider_btns">
                    <p>1/14 photos</p>
                    <div>
                      <img
                        src={left_slider}
                        alt=""
                      />
                      <img
                        src={right_slider}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="invest_group"
                  style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px"}}
                >
                  <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                    <p style={{height: "25px", width: "25px", backgroundColor: "#617D92", borderRadius: "50%"}}></p>
                    <p>NorthGroup Invest</p>
                  </div>
                  <p>Debt</p>
                </div>
                <div className="heading_location">
                  <h2>Waltham Woods Apartments</h2>
                  <p>164 S. Carson Court Newport News,....</p>
                </div>
                <p className="offer_text">Offer Available for:</p>
                <div className="time_period">
                  <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <p>02</p>
                    <p>Days</p>
                  </div>
                  <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <p>02</p>
                    <p>Days</p>
                  </div>
                  <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <p>02</p>
                    <p>Days</p>
                  </div>
                  <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <p>02</p>
                    <p>Days</p>
                  </div>
                </div>

                <div className="LTV">
                  <div>
                    <p>Yield</p>
                    <h2>10%</h2>
                  </div>
                  <div>
                    <p>LTV</p>
                    <h2>75%</h2>
                  </div>
                  <div>
                    <p>Hold Period</p>
                    <h2>18 mo</h2>
                  </div>
                </div>

                <div className="investments">
                  <div>
                    <p>Minimum Investment</p>
                    <h2>$30,000</h2>
                  </div>
                  <div>
                    <p>Redemption period</p>
                    <h2>12 Month</h2>
                  </div>
                  <div>
                    <p>Strategy</p>
                    <h2>Core-Plus</h2>
                  </div>
                </div>
                <div className="raised">
                  <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                    <p>Raised</p>
                    <p style={{color: "purple"}}>50%</p>
                  </div>
                  <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                    <p style={{height: "7px", width: "7px", background: "#34AA44", borderRadius: "50%"}}></p>
                    <p>Active</p>
                  </div>
                </div>
                <img
                  src={rangeTrack}
                  alt=""
                  style={{marginLeft: "6px"}}
                />
                <button className="view_details">
                  <img
                    src={eye_iconT}
                    alt=""
                  />{" "}
                  View details
                </button>
              </div>

              <div className="single_commercial">
                <div
                  className="commercial_slider"
                  style={{position: "relative", overflow: "hidden"}}
                >
                  <img
                    src={commercial_img}
                    alt=""
                    width="100%"
                  />
                  <span>Commercial</span>
                  <div className="commercial_slider_btns">
                    <p>1/14 photos</p>
                    <div>
                      <img
                        src={left_slider}
                        alt=""
                      />
                      <img
                        src={right_slider}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="invest_group"
                  style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px"}}
                >
                  <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                    <p style={{height: "25px", width: "25px", backgroundColor: "#617D92", borderRadius: "50%"}}></p>
                    <p>NorthGroup Invest</p>
                  </div>
                  <p>Debt</p>
                </div>
                <div className="heading_location">
                  <h2>Waltham Woods Apartments</h2>
                  <p>164 S. Carson Court Newport News,....</p>
                </div>
                <p className="offer_text">Offer Available for:</p>
                <div className="time_period">
                  <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <p>02</p>
                    <p>Days</p>
                  </div>
                  <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <p>02</p>
                    <p>Days</p>
                  </div>
                  <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <p>02</p>
                    <p>Days</p>
                  </div>
                  <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <p>02</p>
                    <p>Days</p>
                  </div>
                </div>

                <div className="LTV">
                  <div>
                    <p>Yield</p>
                    <h2>10%</h2>
                  </div>
                  <div>
                    <p>LTV</p>
                    <h2>75%</h2>
                  </div>
                  <div>
                    <p>Hold Period</p>
                    <h2>18 mo</h2>
                  </div>
                </div>

                <div className="investments">
                  <div>
                    <p>Minimum Investment</p>
                    <h2>$30,000</h2>
                  </div>
                  <div>
                    <p>Redemption period</p>
                    <h2>12 Month</h2>
                  </div>
                  <div>
                    <p>Strategy</p>
                    <h2>Core-Plus</h2>
                  </div>
                </div>
                <div className="raised">
                  <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                    <p>Raised</p>
                    <p style={{color: "purple"}}>50%</p>
                  </div>
                  <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                    <p style={{height: "7px", width: "7px", background: "#34AA44", borderRadius: "50%"}}></p>
                    <p>Active</p>
                  </div>
                </div>
                <img
                  src={rangeTrack}
                  alt=""
                  style={{marginLeft: "6px"}}
                />
                <button className="view_details">
                  <img
                    src={eye_iconT}
                    alt=""
                  />{" "}
                  View details
                </button>
              </div>

              <div className="single_commercial">
                <div
                  className="commercial_slider"
                  style={{position: "relative", overflow: "hidden"}}
                >
                  <img
                    src={commercial_img}
                    alt=""
                    width="100%"
                  />
                  <span>Commercial</span>
                  <div className="commercial_slider_btns">
                    <p>1/14 photos</p>
                    <div>
                      <img
                        src={left_slider}
                        alt=""
                      />
                      <img
                        src={right_slider}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="invest_group"
                  style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px"}}
                >
                  <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                    <p style={{height: "25px", width: "25px", backgroundColor: "#617D92", borderRadius: "50%"}}></p>
                    <p>NorthGroup Invest</p>
                  </div>
                  <p>Debt</p>
                </div>
                <div className="heading_location">
                  <h2>Waltham Woods Apartments</h2>
                  <p>164 S. Carson Court Newport News,....</p>
                </div>
                <p className="offer_text">Offer Available for:</p>
                <div className="time_period">
                  <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <p>02</p>
                    <p>Days</p>
                  </div>
                  <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <p>02</p>
                    <p>Days</p>
                  </div>
                  <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <p>02</p>
                    <p>Days</p>
                  </div>
                  <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <p>02</p>
                    <p>Days</p>
                  </div>
                </div>

                <div className="LTV">
                  <div>
                    <p>Yield</p>
                    <h2>10%</h2>
                  </div>
                  <div>
                    <p>LTV</p>
                    <h2>75%</h2>
                  </div>
                  <div>
                    <p>Hold Period</p>
                    <h2>18 mo</h2>
                  </div>
                </div>

                <div className="investments">
                  <div>
                    <p>Minimum Investment</p>
                    <h2>$30,000</h2>
                  </div>
                  <div>
                    <p>Redemption period</p>
                    <h2>12 Month</h2>
                  </div>
                  <div>
                    <p>Strategy</p>
                    <h2>Core-Plus</h2>
                  </div>
                </div>
                <div className="raised">
                  <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                    <p>Raised</p>
                    <p style={{color: "purple"}}>50%</p>
                  </div>
                  <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                    <p style={{height: "7px", width: "7px", background: "#34AA44", borderRadius: "50%"}}></p>
                    <p>Active</p>
                  </div>
                </div>
                <img
                  src={rangeTrack}
                  alt=""
                  style={{marginLeft: "6px"}}
                />
                <button className="view_details">
                  <img
                    src={eye_iconT}
                    alt=""
                  />{" "}
                  View details
                </button>
              </div>
            </div>
            <button className="show_btn">
              <img
                src={rotate}
                alt="icon"
              />
              Show More Requests
            </button>
          </div>
        </div>

        <div className="browse_city">
          <div className="recommended_users_heading">
            <div className="user-icon">
              <img
                src={location_svg}
                alt="user-icon"
              />
            </div>
            <div>
              <h1>Browse by the City</h1>
            </div>
          </div>
          <div className="cities">
            <div>
              <img
                src={imgOne}
                alt=""
              />
              <p>New York</p>
            </div>
            <div>
              <img
                src={imgOne}
                alt=""
              />
              <p>New York</p>
            </div>
            <div>
              <img
                src={imgOne}
                alt=""
              />
              <p>New York</p>
            </div>
            <div>
              <img
                src={imgOne}
                alt=""
              />
              <p>New York</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Opportunities;
