import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import backButtonGray from "../../../../../assets/investor-pipeline/icons/back_icon_gray.svg";
import buildingPic1 from "../../../../../assets/investor-pipeline/icons/apartment_building1.svg";
import filterBtnGray from "../../../../../assets/investor-pipeline/icons/action_icon.svg";
import InvestmentDashboard from "./SubContents/InvestmentDashboard/InvestmentDashboard";
import SvgColorChanger from "../../../../DealsRoom/SvgColorChanger";
import "./InvestmentSubPage.scss";
import DealRoom from "./SubContents/DealRoom/DealRoom";
import Financial from "./SubContents/Financial/Financial";
import Transaction from "./SubContents/Transaction/Transaction";
import Messaging from "../../../../../pages/InvestmentPipeline/Messaging";
import Document from "./SubContents/Document/Document";
import GeneralPartner from "./SubContents/GeneralPartner/GeneralPartner";
const InvestmentSubPages = (props) => {
  const [isClicked, setIsClicked] = useState(1);
  const [activeTab, setActiveTab] = useState(1);
  const handleMenuClicked = (data) => {
    setIsClicked(data);
  };
  const handleTabClicked = (tabIndex) => {
    setActiveTab(tabIndex);
  };



  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="deals-room">
      <div className="deals-room-left">
        <Link to={"/investment-pipeline"}>
          <div className="acqu">
            <img
              src={backButtonGray}
              alt=""
            />
            <span>North Group Trust</span>
          </div>
        </Link>
        <div className="deal-title">
          <div className="deal-left-img">
            <img
              src={buildingPic1}
              alt="Rectangle deal img"
            />
          </div>
          <div className="deal-left-img-text">
            <p>Waltamwoods Apart...</p>
            <span>North Group Trust</span>
          </div>
          <div className="filter_icon">
            <img
              src={filterBtnGray}
              alt=""
            />
          </div>
        </div>
        <div
          style={{margin: "20px 0"}}
          className="deal-room-left-menu"
        >
          <span> Menu</span>
        </div>

        <LeftMenu
          icon={""}
          name={"Investment Dashboard"}
          id={1}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenu
          icon={""}
          name={"Deal Room"}
          id={2}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenu
          icon={""}
          name={"Financial"}
          id={3}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenu
          icon={""}
          name={"Transactions"}
          id={4}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenu
          icon={""}
          name={"Messaging"}
          id={5}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenu
          icon={""}
          name={"Documents"}
          id={6}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenu
          icon={""}
          name={"General Partner"}
          id={7}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
      </div>
      <div className="investmentSubPages_center_wrapper center-wrapper">
        {isClicked === 1 && <InvestmentDashboard handleMenuClicked={handleMenuClicked} />}
        {isClicked === 2 && <DealRoom />}
        {isClicked === 3 && <Financial />}
        {isClicked === 4 && <Transaction />}
        {isClicked === 5 && <Messaging />}
        {isClicked === 6 && <Document />}
        {isClicked === 7 && <GeneralPartner />}
      </div>
    </div>
  );
};

export default InvestmentSubPages;

const LeftMenu = ({icon, name, id, state, handleMenuClicked}) => {
  return (
    <div
      className={`deals-left-menu ${id === state && "deals-menu-active"}`}
      onClick={() => {
        handleMenuClicked(id);
      }}
    >
      {" "}
      <SvgColorChanger svgContent={icon} />
      <span className="left-menu-title">{name}</span>
    </div>
  );
};
