import React, {useState, useEffect} from "react";
import "./Financial.scss";
import BarChart from "./BarChart";
import DonutChart from "./DonutChart";
import WaterfallChart from "./WaterfallChart";
import bath from "../../../../../../../assets/Valuation/bath.svg";
import bed from "../../../../../../../assets/Valuation/bed.svg";
import blueprint from "../../../../../../../assets/Valuation/blueprint.svg";
import pricetag from "../../../../../../../assets/Valuation/pricetag.svg";
import filter from "../../../../../../../assets/dealsroom/svg/filter.svg";
import arrow from "../../../../../../../assets/Valuation/arrow.svg";
import arrowred from "../../../../../../../assets/Valuation/arrowred.svg";
import savebtn from "../../../../../../../assets/dealsroom/svg/save.svg";
import expense from "../../../../../../../assets/Valuation/expense.svg";
import noi from "../../../../../../../assets/Valuation/noi.svg";
import other from "../../../../../../../assets/Valuation/other.svg";
import otherexp from "../../../../../../../assets/Valuation/otherexp.svg";
import graphlegent from "../../../../../../../assets/Valuation/graphlegent.svg";
import incomechart from "../../../../../../../assets/Valuation/incomechart.svg";

import barcharttwo from "../../../../../../../assets/Valuation/barcharttwo.svg";
import equity from "../../../../../../../assets/Valuation/equity.svg";

import equitydot from "../../../../../../../assets/Valuation/equitydot.svg";
import what from "../../../../../../../assets/syndicate/svg/what.svg";
import arrowdown from "../../../../../../../assets/Valuation/arrowdown.svg";
import user from "../../../../../../../assets/Valuation/user.svg";

import gpgraph from "../../../../../../../assets/Valuation/gpgraph.png";

import NewBarChart from "../../../../../../../pages/Valuation/NewBarChart";
import EquityPieChart from "../../../../../../../pages/Valuation/EquityPieChart";
import TimeChart from "../../../../../../../pages/Valuation/TimeChart";
import RedChart from "../../../../../../../pages/Valuation/RedChart";
import BluePieChart from "../../../../../../../pages/Valuation/BluePieChart";
import CashFlowchart from "../../../../../../../pages/Valuation/CashFlowChart";
import StackBarChart from "../../../../../../../pages/Valuation/StackBarChart";
import NormalBarChart from "../../../../../../../pages/Valuation/NormalBarChart";
import {FetchValuationInfo} from "../../../../../../../utils/Axios";
import {useSelector} from "react-redux";
import RangeSelector from "./RangeSelector";

// import  from "../../../../../../../assets/svg/financial.svg";
import {ReactComponent as FinancialIcon} from "../../../../../../../assets/svg/financial.svg";
import dealeconomic from "../../../../../../../assets/svg/deal-economic.svg";

const Financial = () => {
  const [navActive, setNavActive] = useState(1);
  return (
    <div className="financial_page_wrapper">
      <div className="topHeader_nav">
        <div className="deals-room-nav gp_nav asset-nav ass-nav">
          <span
            className={navActive === 1 ? "deals-room-nav-active ut-nav-active" : ""}
            onClick={() => {
              setNavActive(1);
            }}
            // style={{display: "flex", alignItems: "center", gap: "10px", paddingBottom: "10px"}}
          >
            {/* <FinancialIcon /> */}
            Financial
          </span>
          <span
            className={navActive === 2 ? "deals-room-nav-active ut-nav-active" : ""}
            onClick={() => {
              setNavActive(2);
            }}
            // style={{display: "flex", alignItems: "center", gap: "10px", paddingBottom: "10px"}}
          >
            {/* <img
              src={dealeconomic}
              alt="financial"
            /> */}
            Deal economics Highlights
          </span>
        </div>
      </div>
      <div className="main_content_container">
        {navActive === 1 ? (
          <div className="financial_page_container">
            <FinancialContent />
          </div>
        ) : (
          <DealEcoContent />
        )}
      </div>
    </div>
  );
};

export default Financial;

const DealEcoContent = () => {
  const [navActive, setNavActive] = useState(0);
  const [navActiveone, setNavActiveone] = useState(0);
  const timePeriods = ["3 m", "6 m", "1 year", "2 years", "5 years", "10 years", "All period"];

  const incomeType = [
    {img: noi, value: "NOI"},
    // { img: gpr, value: "GPR" },
    {img: other, value: "Other Income"},
    {img: expense, value: "Interest Payment"},
    {img: expense, value: "Management Fee"},
    {img: otherexp, value: "Other expenses"},
  ];
  const incomePlan = [
    {img: noi, value: "Levered Net Cash Flow"},
    {img: other, value: "Unlevered Net Cash Flow"},
    {img: otherexp, value: "Principal Payment"},
    {img: expense, value: "Interest Payment"},
    {img: otherexp, value: "Ending Loan Balance"},
  ];

  const formatPrice = (price) => {
    if (price) {
      let priceString = price.toString();

      let parts = priceString.split(".");

      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return parts.join(".");
    }
  };

  // Example usage:
  const formattedPrice = formatPrice(10485985);

  const [data, setData] = useState();
  const {idToken} = useSelector((state) => state.auth);
  const {userInformation} = useSelector((state) => state.userInfo);

  useEffect(() => {
    (async () => {
      FetchValuationInfo(idToken).then((res) => setData(res && res?.data?.[0]));
    })();
  }, []);

  const CashFlowMetrics = [
    {title: "GP IRR", value: data?.summary?.cash_flow?.gp_irr},
    {title: "GP Equity Multiple", value: data?.summary?.cash_flow?.lp_equity_multiple},
    {title: "LP IIR", value: data?.summary?.cash_flow?.lp_iir},
    {title: "LP Equity Multiple", value: data?.summary?.cash_flow?.lp_equity_multiple},
  ];

  const expenseMetrics = [
    {label: "Operating Expenses", value: data?.summary?.expense_metrics?.operating_expenses},
    {label: "Property Taxes", value: data?.summary?.expense_metrics?.property_taxes},
    {label: "Property Management", value: data?.summary?.expense_metrics?.property_management},
    {label: "Utilities and Perodic Exp.", value: data?.summary?.expense_metrics?.utilities_and_periodic_expenses},
  ];

  const {dates, gpr, interest_payment, management_fee, other_expenses, other_income} = data?.summary?.net_operating_income ?? {}; // Use optional chaining and nullish coalescing operator

  const [filteredData, setFilteredData] = useState(data?.summary?.net_operating_income);
  const [selectedPeriod, setSelectedPeriod] = useState("All period"); // state for Net Operating Income
  const [cashFlowPeriod, setCashFlowPeriod] = useState("All period");

  const handleTimePeriodChange = (period) => {
    setSelectedPeriod(period);

    // Filter data based on selected period
    if (period !== "All period") {
      const index = timePeriods.indexOf(period);
      const filteredNetOperatingIncome = {
        ...data.summary.net_operating_income,
        dates: data.summary.net_operating_income.dates.slice(-index),
        noi: data.summary.net_operating_income.noi.slice(-index),
        gpr: data.summary.net_operating_income.gpr.slice(-index),
        other_income: data.summary.net_operating_income.other_income.slice(-index),
        interest_payment: data.summary.net_operating_income.interest_payment.slice(-index),
        management_fee: data.summary.net_operating_income.management_fee.slice(-index),
        other_expenses: data.summary.net_operating_income.other_expenses.slice(-index),
      };
      setFilteredData(filteredNetOperatingIncome);
    } else {
      // If all period selected, set data to original
      setFilteredData(data?.summary?.net_operating_income);
    }
  };
  useEffect(() => {
    handleTimePeriodChange("All period");
  }, [data?.summary?.net_operating_income]);



  return (
    <div className="summary-main-container">
      <div className="summary-body-A">
        <div className="price-summary">
          <span>LilyPads Estimate</span>
          <span>${formatPrice(data?.summary?.lily_pads_estimate) || 0}</span>
          <div className="div-img">
            <img
              src={arrow}
              alt=""
            />
            <span>5.9%</span>
          </div>
          <span>Compare to listed $12.4M</span>
        </div>
        <div className="price-summary">
          <span>Appreciation Estimate</span>
          <span>{data?.summary?.appreciation_estimate || 0}%</span>
          <div className="div-img img-red">
            <img
              src={arrowred}
              alt=""
            />
            <span>9.1%</span>
          </div>
          <span>Compare to listed $12.4M</span>
        </div>
        <div className="price-summary">
          <span>Optimal Hold Time</span>
          <span>{data?.summary?.optimal_hold_time || 0} years</span>
        </div>
        <div className="price-summary">
          <span>Annual Income Growth</span>
          <span>{data?.summary?.annual_income_growth || 0}%</span>
        </div>
        <div className="price-summary">
          <span>CAP RATE</span>
          <span>{data?.summary?.cap_rate || 0}%</span>
          <div className="div-img">
            <img
              src={arrow}
              alt=""
            />
            <p>5.9% &nbsp;</p>
            <span>vs. Goal</span>
          </div>
        </div>
        <div className="price-summary">
          <span>IRR</span>
          <span>{data?.summary?.irr || 0}%</span>
          <div className="div-img">
            <img
              src={arrow}
              alt=""
            />
            <p>5.9% &nbsp;</p>
            <span>vs. Goal</span>
          </div>
        </div>
        <div className="price-summary">
          <span>NOI</span>
          <span>${formatPrice(data?.summary?.noi) || 0}</span>
          <div className="div-img">
            <img
              src={arrow}
              alt=""
            />
            <p>5.9% &nbsp;</p>
            <span>vs. Goal</span>
          </div>
        </div>
      </div>

      <div className="summary-body-B"></div>
      <div className="income-container">
        <div className="income-left">
          <h1>Income Metrics</h1>
          <div className="income-flex">
            <span>Effective Gross Income</span>
            <span>${formatPrice(data?.summary?.income_metrics?.effective_gross_income) || 0}</span>
          </div>
          <div className="income-flex">
            <span>Cash-on-Cash Return</span>
            <div>
              <div>
                <p>{data?.summary?.income_metrics?.cash_on_cash_return?.year || 0}%</p>
                <span>Year 1</span>
              </div>
              <div>
                <p>{data?.summary?.income_metrics?.cash_on_cash_return?.avg || 0}%</p>
                <span>Avg</span>
              </div>
            </div>
          </div>
          <div className="income-flex">
            <span>Equity Multiple</span>
            <span>{data?.summary?.income_metrics?.equity_multiple || 0}x</span>
          </div>
        </div>
        <NormalBarChart />
        {/* <img
          src={incomegraph}
          alt=""
        /> */}
        <div className="income-right">
          <h1>Expense Metrics</h1>
          {expenseMetrics.map((metric, index) => (
            <div key={index}>
              <p>{metric.label}</p>
              <span>${metric.value || 0} </span>
            </div>
          ))}
        </div>
      </div>
      <div className="net-income-cont">
        <div className="net-income-head">
          <div className="income-head-left">
            <div>
              <img
                src={user}
                alt=""
              />
            </div>
            <h1>Waterfal Structure</h1>
          </div>
          <div>
            <img
              src={savebtn}
              alt=""
            />
          </div>
        </div>
        <div className="waterfall-body">
          {/* <img
            src={waterfallone}
            alt=""
          /> */}
          <BluePieChart />
          {/* <img
            src={waterfalltwo}
            alt=""
          /> */}
          <TimeChart />
        </div>
      </div>
      <div className="unit-container">
        <div className="unit-head">
          <span>Unit Mix</span>
          <img
            src={savebtn}
            alt=""
          />
        </div>
        <div className="unit-card-body">
          <>
            {/* <ReusableUnitCard
            title="Studio"
            units={20}
            unitNetIncome="$20,000"
            area={1000}
            rent="$1,250"
            blueprint={blueprint}
            pricetag={pricetag}
          /> */}
            {/* <ReusableUnitCardB
            contentArray={[
              { title: "1 BR", icon: bed, image: blueprint, percentage: "26%" },
              { title: "1 BA", icon: bath, image: pricetag, percentage: "25%" },
            ]}
            units={20}
            unitNetIncome="$20,000"
            area={1000}
            rent="$1,250"
          />
          <ReusableUnitCardB
            contentArray={[
              { title: "2 BR", icon: bed, image: blueprint, percentage: "26%" },
              { title: "2 BA", icon: bath, image: pricetag, percentage: "34%" },
            ]}
            units={20}
            unitNetIncome="$65,000"
            area={1000}
            rent="$1,250"
          />
          <ReusableUnitCardB
            contentArray={[
              { title: "3 BR", icon: bed, image: blueprint, percentage: "26%" },
              { title: "2 BA", icon: bath, image: pricetag, percentage: "28%" },
            ]}
            units={20}
            unitNetIncome="$50,000"
            area={1000}
            rent="$1,250"
          /> */}
          </>

          {data?.summary?.unit_mix?.map((item, index) => {
            return (
              <ReusableUnitCard
                data={item}
                blueprint={blueprint}
                pricetag={pricetag}
                bed={bed}
                bath={bath}
              />
            );
          })}
        </div>
      </div>

      <div className="net-income-cont">
        <div className="net-income-head">
          <div className="income-head-left">
            <div className="head-left-upper">
              <div></div>
              <div>
                <p>
                  GP <span>VS.</span>
                </p>
              </div>
              <div></div>
              <div>
                <p>
                  LP <span>/</span> Cash Flow
                </p>
              </div>
            </div>
          </div>
          <div>
            <img
              src={savebtn}
              alt=""
            />
          </div>
        </div>
        <div className="cash-flow-body">
          <div className="cash-flow-body-upper">
            {CashFlowMetrics?.map((metric, index) => (
              <div key={index}>
                <h1>{metric.title}</h1>
                <span>{metric.value}</span>
              </div>
            ))}
          </div>
          <CashFlowchart data={data?.summary?.cash_flow} />
          {/* <img
            src={gpgraph}
            alt=""
          /> */}
        </div>
      </div>

      <div className="net-income-cont">
        <div className="net-income-head">
          <div>
            <h1>Net Operating Income</h1>
          </div>
          <div className="income-head-right">
            <img
              src={savebtn}
              alt=""
            />
            <div>
              <img
                src={filter}
                alt=""
              />
              <span>Filter</span>
            </div>
          </div>
        </div>
        <div>
          {/* Filter bar */}
          <div className="income-time">
            {timePeriods.map((period, index) => (
              <div
                key={index}
                onClick={() => handleTimePeriodChange(period)}
                className={`${selectedPeriod === period && "timeactive"}`}
              >
                <span>{period}</span>
              </div>
            ))}
          </div>
          {/* Chart component */}
          <div className="income-graphs"></div>
          <div className="income-graphs">
            <NewBarChart data={filteredData} />
            {/* <img
            src={incomegraphtwo}
            alt=""
          /> */}
            <img
              src={graphlegent}
              alt=""
            />
            <img
              src={incomechart}
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="net-income-cont">
        <div className="net-income-head">
          <div>
            <h1>Cash Flow Plan</h1>
          </div>
          <div className="income-head-right">
            <img
              src={savebtn}
              alt=""
            />
            <div>
              <img
                src={filter}
                alt=""
              />
              <span>Filter</span>
            </div>
          </div>
        </div>
        <div className="net-income-body">
          <div className="income-time">
            {timePeriods.map((period, index) => (
              <div
                key={index}
                onClick={() => {
                  setNavActiveone(index);
                  setCashFlowPeriod(period);
                }}
                className={`${navActiveone === index && "timeactive"}`}
              >
                <span>{period}</span>
              </div>
            ))}
          </div>
          <div className="loan-ammount">
            <div>
              <span>Loan Proceeds</span>
              <span>${data?.summary?.cash_flow_plan?.loan_proceeds}</span>
            </div>
            <div>
              <span>Loan Origination Fees</span>
              <span>${data?.summary?.cash_flow_plan?.loan_origination_fees}</span>
            </div>
          </div>
          {/* <div className="income-type">
            {incomePlan.map((type, index) => (
              <div key={index}>
                <img
                  src={type.img}
                  alt=""
                />
                <span>{type.value}</span>
              </div>
            ))}
          </div> */}
        </div>
        <div className="income-graphs">
          <RedChart
            data={data?.summary?.cash_flow_plan?.cash_flow}
            period={cashFlowPeriod}
          />
          {/* <img
            src={incomegraphthree}
            alt=""
          /> */}
          {/* <img
            src={graphlegent}
            alt=""
          /> */}
          <img
            src={barcharttwo}
            alt=""
          />
        </div>
      </div>
      <div className="equity-loan-container">
        <h1>Capital Stack</h1>
        <div className="net-income-cont">
          <div className="net-income-head">
            <div className="income-head-left">
              <div>
                <img
                  src={equity}
                  alt=""
                />
              </div>
              <h1>Equity to Loan</h1>
            </div>
            <div>
              <img
                src={savebtn}
                alt=""
              />
            </div>
          </div>
          <div className="equity-body">
            <div className="equity-body-top">
              {/* <img
                src={equityellispse}
                alt=""
              /> */}
              <EquityPieChart data={data?.summary?.equity_to_loan} />
              <div>
                <p>$451,500</p>
                <div>
                  <img
                    src={equitydot}
                    alt=""
                  />
                  <span>EQUITY</span>
                </div>
              </div>
              <div>
                <p>$838,500</p>
                <div>
                  <img
                    src={expense}
                    alt=""
                  />
                  <span>MAX LOAN</span>
                </div>
              </div>
              <div>
                <p>$0</p>
                <span>Refinance Loan Amount</span>
              </div>
            </div>
            <div className="stack_and_range_div">
              {/* Stack Chart */}
              <div className="equity-body-bottom">
                <div style={{width: "400px"}}>
                  <StackBarChart />
                </div>
                {/* <img
                src={equityone}
                alt=""
              /> */}
                <div className="equity-bottom-left">
                  <div className="bottom-left-A bottom-left-flex">
                    <p>20%</p>
                    <div>
                      <span>Common Equity</span>
                      <img
                        src={what}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="bottom-left-B bottom-left-flex">
                    <p>15%</p>
                    <div>
                      <span>Preferred Equity</span>
                      <img
                        src={what}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="bottom-left-C bottom-left-flex">
                    <p>65%</p>
                    <div>
                      <img
                        src={arrowdown}
                        alt=""
                      />
                      <span>Debt</span>
                      <img
                        src={what}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="bottom-left-D">
                    <div>
                      <div>
                        <p>40%</p>
                        <img
                          src={what}
                          alt=""
                        />
                      </div>
                      <span>Senior Debt</span>
                    </div>
                    <div>
                      <div>
                        <p>25%</p>
                        <img
                          src={what}
                          alt=""
                        />
                      </div>
                      <span>Mezzanine Financing</span>
                    </div>
                  </div>
                </div>
                <div className="equity-bottom-right"></div>
              </div>
              {/* Range selector */}
              <div>
                <RangeSelector
                  min={0}
                  max={100}
                  step={1}
                  initialValues={[10, 20]}
                  color="#6b52ae"
                />
                <RangeSelector
                  min={0}
                  max={100}
                  step={1}
                  initialValues={[6, 12]}
                  color="#d9534f"
                />
                <RangeSelector
                  min={0}
                  max={100}
                  step={1}
                  initialValues={[4, 8]}
                  color="#6c757d"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FinancialContent = () => {
  return (
    <div className="financial_page_wrapper">
      <div className="topSection">
        <div className="status_box">
          <div className="status_box_header">
            <h2>Status</h2>
          </div>
          <div className="status_box_content">
            <div className="project_stats_wrapper">
              <div className="project_stats_header">
                <h3>Project stats</h3>
              </div>
              <div className="contents_box">
                <span>Strategy</span>
                <p>Opportunistic</p>
              </div>
              <div className="contents_box">
                <span>First distribution</span>
                <p>Apr 10, 2015</p>
              </div>
            </div>
            <div className="leasing_stats_wrapper">
              <div className="leasing_stats_header">
                <h3>Leasing stats</h3>
              </div>
              <div className="contents_box">
                <span>Percent leased</span>
                <div className="data_box">
                  <p>100 %</p>
                  <span>May 1, 2018</span>
                </div>
                <div className="data_box">
                  <p>88 %</p>
                  <span>Jan 11, 2015</span>
                </div>
              </div>
              <div className="contents_box">
                <span>Five year lease rollover</span>
                <div className="data_box">
                  <p>-</p>
                  <span>May 1, 2018</span>
                </div>
                <div className="data_box">
                  <p>6.7 %</p>
                  <span>Jan 11, 2015</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottomSection">
        <div className="debt_box">
          <div className="debt_box_header">
            <h2>Debt</h2>
          </div>
          <p className="debt_box_caption">Loan with a balance of $16,000,000 maturing on Jan 31, 2020</p>
          <div className="status_box_content">
            <div className="groupA">
              <div className="static_loan_wrapper">
                <div className="static_loan_header">
                  <h3>Static loan details</h3>
                </div>
                <div className="contents_box">
                  <span>Lender name</span>
                  <p>JP Morgan</p>
                </div>
                <div className="contents_box">
                  <span>Lender type</span>
                  <p>Balance sheet</p>
                </div>
                <div className="contents_box">
                  <span>Maturity date</span>
                  <p>Apr 10, 2015</p>
                </div>
                <div className="contents_box">
                  <span>Local debt currency</span>
                  <p>U.S. Dollar (USD)</p>
                </div>
                <div className="contents_box">
                  <span>Initial debt balance</span>
                  <p>$16,000,000</p>
                </div>
              </div>
              <div className="leasing_stats_wrapper">
                <div className="leasing_stats_header">
                  <h3>Leasing stats</h3>
                </div>
                <div className="contents_box">
                  <span>Loan index</span>
                  <p>Prime</p>
                </div>
                <div className="contents_box">
                  <span>Interest rate description</span>
                  <p>Fixed</p>
                </div>
                <div className="contents_box">
                  <span>Payment type</span>
                  <p>Interest only</p>
                </div>
                <div className="contents_box">
                  <span>Collateral</span>
                  <p>Pacific Vista Commerce Center</p>
                </div>
              </div>
            </div>
            <div className="groupB">
              <div className="current_date_stats">
                <div className="c_d_header">
                  <h3>As of Apr 24, 2018</h3>
                  <p>As of Apr 24, 2018</p>
                </div>
                <div className="content_wrapper">
                  <div className="contents_box">
                    <span>Total interest rate</span>
                    <p>$14,000,000</p>
                  </div>
                  <div className="contents_box">
                    <span>
                      Current equity balance <br /> (at 100%)
                    </span>
                    <p>$18,000,000</p>
                  </div>
                  <div className="contents_box">
                    <span>
                      Current equity balance <br />
                      (at 100%)
                    </span>
                    <p>$18,000,000</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ReusableUnitCard = ({data, blueprint, pricetag, bed, bath}) => (
  <div className="unit-card">
    <div className="unit-card-inner">
      <div>
        <h1 style={{display: "flex", alignItems: "center", gap: "10px"}}>
          {data?.Studio ? (
            "Studio"
          ) : (
            <>
              BR {data?.br}{" "}
              <img
                src={bed}
                alt=""
              />
              &nbsp; &nbsp;BA {data?.ba}{" "}
              <img
                src={bath}
                alt=""
              />
            </>
          )}
        </h1>
      </div>

      <div className="unit-card-price">
        <div>
          <span>Units</span>
          <span>{data?.units}</span>
          <span>20% of total</span>
        </div>
        <div></div>
        <div>
          <span>Unit Net Income</span>
          <span>{data?.unit_net_income}</span>
          <span>11% of total</span>
        </div>
      </div>
      <div className="unit-card-bottom">
        <div>
          <img
            src={blueprint}
            alt=""
          />
          <div>
            <p>Area, SF</p>
            <span>{data?.area_sf}</span>
          </div>
        </div>
        <div>
          <img
            src={pricetag}
            alt=""
          />
          <div>
            <p>Rent</p>
            <span>{data?.rent}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);
