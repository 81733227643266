import React from "react";

function Checkmark({}) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_di_10347_4930)">
        <rect
          x="1"
          width="16"
          height="16"
          rx="3"
          fill="url(#paint0_linear_10347_4930)"
        />
        <rect
          x="1.5"
          y="0.5"
          width="15"
          height="15"
          rx="2.5"
          stroke="#D8DCE6"
        />
      </g>
      <defs>
        <filter
          id="filter0_di_10347_4930"
          x="0"
          y="0"
          width="18"
          height="18"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0863 0 0 0 0 0.1137 0 0 0 0 0.1451 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_10347_4930"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_10347_4930"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_10347_4930"
          />
        </filter>
        <linearGradient
          id="paint0_linear_10347_4930"
          x1="17"
          y1="16"
          x2="17"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F6F7F9" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}

function CheckmarkTICK({}) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_di_7_4)">
        <rect width="16" height="16" rx="4" fill="#7D55C7" />
        <rect width="16" height="16" rx="4" fill="url(#paint0_linear_7_4)" />
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="3.5"
          stroke="#7D55C7"
          stroke-opacity="0.71"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.65183 10.8942L4.10636 8.33133C3.96455 8.19032 3.96455 7.96081 4.10636 7.8183L4.62055 7.30602C4.76237 7.16501 4.99291 7.16501 5.13473 7.30602L6.90929 9.10013L10.8649 5.10613C11.0068 4.96512 11.2373 4.96512 11.3791 5.10613L11.8933 5.61916C12.0351 5.76017 12.0351 5.99044 11.8933 6.1307L7.16602 10.8942C7.0242 11.0353 6.79365 11.0353 6.65183 10.8942Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_di_7_4"
          x="-1"
          y="0"
          width="18"
          height="18"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0745098 0 0 0 0 0.121569 0 0 0 0 0.0823529 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7_4"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7_4"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.06 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_7_4" />
        </filter>
        <linearGradient
          id="paint0_linear_7_4"
          x1="16"
          y1="16"
          x2="16"
          y2="-1.24444"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7D55C7" />
          <stop offset="1" stop-color="#8865C8" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export { Checkmark, CheckmarkTICK };
