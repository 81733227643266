import React, { useState } from 'react'
import './BudgetTable.scss'
import DropDown from '../../../components/reusableComponents/Dropdown/Dropdown'
import calendar from '../../../assets/svg/calender.svg'
import print from '../../../assets/svg/print-fill.svg'
import download from '../../../assets/svg/download-fill.svg'
import arrowdown from '../../../assets/dealsroom/svg/arrowdown.svg'
import { InputBox } from '../../../components/reusableComponents/FormFields/FormFields'
import tick from '../../../assets/svg/tick-circle.svg'
import cross from '../../../assets/svg/cross-red.svg'
import repeat from '../../../assets/svg/arrow_repeat.svg'
import documenticon from '../../../assets/svg/document.svg'
import excelicon from '../../../assets/svg/excel.svg'
import pdficon from '../../../assets/svg/pdf.svg'

function BudgetTable({handleBudgetTable}) {


    const [downloadBox , setDownloadBox] = useState(false)
    const handleDownloadBox = () => {
        setDownloadBox(!downloadBox)
    }

    const data = [
        {
            id: 48876,
            category: 'Gross Potential Rent',
            january: '432.23',
            february: '432.23',
            march: '432.23',
            april: '432.23',
            may: '432.23',
            expandedRowsData: [
                {
                    id: 46466,
                    category: 'Gross Potential Rent',
                    january: '432.23',
                    february: '432.23',
                    march: '432.23',
                    april: '432.23',
                    may: '432.23',
                },
                {
                    id: 48789,
                    category: 'Gross Potential Rent',
                    january: '432.23',
                    february: '432.23',
                    march: '432.23',
                    april: '432.23',
                    may: '432.23',
                },
                {
                    id: 47787,
                    category: 'Gross Potential Rent',
                    january: '432.23',
                    february: '432.23',
                    march: '432.23',
                    april: '432.23',
                    may: '432.23',
                },
                {
                    id: 42877,
                    category: 'Gross Potential Rent',
                    january: '432.23',
                    february: '432.23',
                    march: '432.23',
                    april: '432.23',
                    may: '432.23',
                },
                {
                    id: 46767,
                    category: 'Net Rent',
                    january: '432.23',
                    february: '432.23',
                    march: '432.23',
                    april: '432.23',
                    may: '432.23',
                },
            ],
        },
        {
            id: 48880,
            category: 'Gross Potential Rent',
            january: '432.23',
            february: '432.23',
            march: '432.23',
            april: '432.23',
            may: '432.23',
            expandedRowsData: [
                {
                    id: 48799,
                    category: 'Gross Potential Rent',
                    january: '432.23',
                    february: '432.23',
                    march: '432.23',
                    april: '432.23',
                    may: '432.23',
                },
                {
                    id: 48756,
                    category: 'Gross Potential Rent',
                    january: '432.23',
                    february: '432.23',
                    march: '432.23',
                    april: '432.23',
                    may: '432.23',
                },
                {
                    id: 48769,
                    category: 'Gross Potential Rent',
                    january: '432.23',
                    february: '432.23',
                    march: '432.23',
                    april: '432.23',
                    may: '432.23',
                },
                {
                    id: 48596,
                    category: 'Gross Potential Rent',
                    january: '432.23',
                    february: '432.23',
                    march: '432.23',
                    april: '432.23',
                    may: '432.23',
                },
                {
                    id: 48965,
                    category: 'Net Rent',
                    january: '432.23',
                    february: '432.23',
                    march: '432.23',
                    april: '432.23',
                    may: '432.23',
                },
            ],
        },
    ];




    return (
        <div className='budgettable'>
            <div className='bt_header'>
                <div className='bt_header_left'>
                    <h4>Shown for period</h4>
                    <DropDown width={'198px'} Img={calendar} selectedData={'Jan 2020-Dec 2020'} />
                    <button>
                        <img src={repeat} alt='' />
                        Regenerate Period
                    </button>
                    <button>
                        Change Answers
                    </button>
                    <button>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.833 2.5C6.69134 2.5 3.33301 5.85833 3.33301 10H0.833008L4.07467 13.2417L4.13301 13.3583L7.49967 10H4.99967C4.99967 6.775 7.60801 4.16667 10.833 4.16667C14.058 4.16667 16.6663 6.775 16.6663 10C16.6663 13.225 14.058 15.8333 10.833 15.8333C9.22467 15.8333 7.76634 15.175 6.71634 14.1167L5.53301 15.3C6.89134 16.6583 8.75801 17.5 10.833 17.5C14.9747 17.5 18.333 14.1417 18.333 10C18.333 5.85833 14.9747 2.5 10.833 2.5ZM9.99967 6.66667V10.8333L13.5663 12.95L14.1663 11.9417L11.2497 10.2083V6.66667H9.99967Z" fill="#9EA0A5" />
                        </svg>
                        Restore defaults
                    </button>
                </div>

                <div className='bt_header_right'>
                    <img src={print} alt='' />
                    <img onClick={handleDownloadBox} src={download} alt='' />
                    <button className='btn_right' onClick={handleBudgetTable}>Save and Exit</button>
                </div>
            </div>
            
            
                 {downloadBox && <DownloadBox />}
            <div className='bt_wrapper' >

                <div className='table_wrapper'>

                <ExpandableTable data={data} />
                </div>
                <div className='bt_sidebar'>
                    <InputBox label={'name'} width={'216px'} />

                    <span>Issues (23)</span>

                    <TextBox title={'Unit 33304'} text={'Contractual Rent is more	than 30% below the Market	Rent.'} />

                    <div className='box'>

                        <TextBox title={'Unit 33304'} text={'Last year it was 30% higher.'} />
                        <div>
                            <p className='input_label'>Suggested Value:</p>
                            <div style={{ background: '#fff', width: '225px' }} className='dollar_input'>
                                <p>$</p>
                                <input type='text' />
                            </div>
                        </div>

                        <div className='box_btns'>
                            <button>
                                <img src={tick} alt='' />
                                Accpet</button>
                            <button>
                                <img src={cross} alt='' />
                                Dismiss</button>
                        </div>
                    </div>

                    <TextBox title={'Unit 33304'} text={'Contractual Rent is more	than 30% below the Market	Rent.'} />
                    <TextBox title={'Unit 33304'} text={'Contractual Rent is more	than 30% below the Market	Rent.'} />
                    <TextBox title={'Unit 33304'} text={'Contractual Rent is more	than 30% below the Market	Rent.'} bottom={true} />
                    <TextBox title={'Unit 33304'} text={'Contractual Rent is more	than 30% below the Market	Rent.'} />
                    <TextBox title={'Unit 33304'} text={'Contractual Rent is more	than 30% below the Market	Rent.'} />

                </div>
            </div>
        </div>
    )
}

export default BudgetTable


const DownloadBox = () => {
    return (
        <div className='downloadbox'>
            <h5>Download section</h5>

            <div>
                <span>
                    <img src={excelicon} alt='' />
                As Excell
                </span>
                <span>
                <img src={pdficon} alt='' />

                As Pdf
                </span>
                <span>

                <img src={documenticon} alt='' />

                As T12
                </span>
            </div>
        </div>
    )
    }

const TextBox = ({ title, text, bottom }) => {
    return (
        <div className='textbox'>
            <h2>{title}</h2>
            <p>{text}</p>
            {bottom &&
                <div className='textbox_bottom'>
                    <p>Modified</p>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 2.25C6.0225 2.25 3 5.2725 3 9H0.75L3.6675 11.9175L3.72 12.0225L6.75 9H4.5C4.5 6.0975 6.8475 3.75 9.75 3.75C12.6525 3.75 15 6.0975 15 9C15 11.9025 12.6525 14.25 9.75 14.25C8.3025 14.25 6.99 13.6575 6.045 12.705L4.98 13.77C6.2025 14.9925 7.8825 15.75 9.75 15.75C13.4775 15.75 16.5 12.7275 16.5 9C16.5 5.2725 13.4775 2.25 9.75 2.25ZM9.00004 6.00004V9.75004L12.21 11.655L12.75 10.7475L10.125 9.18754V6.00004H9.00004Z" fill="#00629B" />
                        </svg>
                        Restore</span>
                </div>
            }
        </div>
    )
}




const ExpandableTable = ({ data }) => {
    const [expandedRows, setExpandedRows] = useState(data.map((row) => row.id));
    const [selectedIds, setSelectedIds] = useState([]);

    const handleRowClick = (id) => {
        setExpandedRows((prevExpandedRows) =>
            prevExpandedRows.includes(id)
                ? prevExpandedRows.filter((rowId) => rowId !== id)
                : [...prevExpandedRows, id]
        );
    };

    const handleCheckboxChange = (id) => {
        setSelectedIds((prevSelectedIds) =>
            prevSelectedIds.includes(id)
                ? prevSelectedIds.filter((selectedId) => selectedId !== id)
                : [...prevSelectedIds, id]
        );
    };

    const handleDropdownClick = (id) => {
        setExpandedRows((prevExpandedRows) =>
            prevExpandedRows.includes(id)
                ? prevExpandedRows.filter((rowId) => rowId !== id)
                : [...prevExpandedRows, id]
        );
    };

    return (
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Category</th>
                    <th>Jan 2020</th>
                    <th>Feb 2020</th>
                    <th>Mar 2020</th>
                    <th>Apr 2020</th>
                    <th>May 2020</th>
                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => (
                    <React.Fragment key={row.id}>
                        <tr className={`main_row ${index === 1 && 'second_row'}`} onClick={() => handleRowClick(row.id)}>
                            <td>
                                <span
                                    role="img"
                                    aria-label="dropdown-icon"
                                    className={`dropdown-icon ${expandedRows.includes(row.id) ? 'expanded' : ''
                                        }`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDropdownClick(row.id);
                                    }}
                                >
                                    <img src={arrowdown} alt='' />
                                </span>

                                {row.id}
                            </td>
                            <td>{row.category}</td>

                        </tr>
                        {expandedRows.includes(row.id) &&
                            row.expandedRowsData.map((expandedRow, index) => (
                                <tr className={` ${index === 4 ? 'expanded_last' : 'expanded'}`} key={expandedRow.id}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedIds.includes(expandedRow.id)}
                                            onChange={() => handleCheckboxChange(expandedRow.id)}
                                        />
                                        {expandedRow.id}
                                    </td>
                                    <td>{expandedRow.category}</td>
                                    <td>{expandedRow.january}</td>
                                    <td>{expandedRow.february}</td>
                                    <td>{expandedRow.march}</td>
                                    <td>{row.april}</td>
                                    <td>{row.may}</td>
                                </tr>
                            ))}
                    </React.Fragment>
                ))}
            </tbody>
        </table>
    );
};