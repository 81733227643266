import React, {useEffect} from "react";
import "./AssetInformation.scss";
import photo from "../../assets/acqusition/svg/photo.svg";
import location from "../../assets/acqusition/svg/location.svg";
import street from "../../assets/acqusition/svg/street.svg";
import bookmark from "../../assets/acqusition/svg/bookmark.svg";
import share from "../../assets/acqusition/svg/share.svg";
import compare from "../../assets/acqusition/svg/compare.svg";
// import assettype from "../../assets/acqusition/svg/assettype.svg";
import assetype from "../../assets/acqusition/svg/assettype.svg";
import utilities from "../../assets/acqusition/img/utilities.png";
import calender from "../../assets/acqusition/svg/calender.svg";
import building_area from "../../assets/acqusition/svg/building_area.svg";
import total_unit from "../../assets/acqusition/svg/total_unit.svg";
import zoning from "../../assets/acqusition/svg/zoning.svg";
import metro from "../../assets/acqusition/svg/metro.svg";
import market from "../../assets/acqusition/svg/market.svg";
import sub_market from "../../assets/acqusition/svg/sub-market.svg";
import parking from "../../assets/acqusition/svg/parking.svg";
import price from "../../assets/acqusition/svg/price_squart.svg";
import map from "../../assets/acqusition/svg/map.svg";
import redirect from "../../assets/acqusition/svg/redirect.svg";
import dropdown from "../../assets/acqusition/svg/dropdown.svg";
import mortgage from "../../assets/acqusition/svg/mortgage.svg";
import mappin from "../../assets/acqusition/svg/mappin.svg";
import cross from "../../assets/acqusition/svg/cross.svg";
import walk from "../../assets/acqusition/svg/walk.svg";
import car from "../../assets/acqusition/svg/car.svg";
import train from "../../assets/acqusition/svg/train.svg";
import cycle from "../../assets/acqusition/svg/cycle.svg";
import refresh from "../../assets/acqusition/svg/refresh.svg";
import next from "../../assets/acqusition/svg/next.svg";
import profit from "../../assets/acqusition/svg/profit.svg";
import increase from "../../assets/acqusition/svg/increase.svg";
import decrease from "../../assets/acqusition/svg/decrease.svg";
import star from "../../assets/acqusition/svg/star.svg";
import prev from "../../assets/acqusition/svg/prev.svg";
import mapround from "../../assets/acqusition/svg/mapround.svg";
import mall from "../../assets/acqusition/svg/mall.svg";
import show from "../../assets/acqusition/svg/show.svg";
import n1 from "../../assets/acqusition/img/n1.png";
import dropdownbg from "../../assets/acqusition/svg/dropdownbg.svg";
import verified from "../../assets/acqusition/svg/verified.svg";
import twitter from "../../assets/acqusition/svg/twitter.svg";
import love from "../../assets/acqusition/svg/love.svg";
import info from "../../assets/acqusition/svg/info.svg";
import comment from "../../assets/acqusition/svg/comment.svg";
import fillstar from "../../assets/acqusition/svg/fillstar.svg";
import emptystar from "../../assets/acqusition/svg/empty.svg";
import rightarrow from "../../assets/acqusition/svg/rightarrow.svg";
import prevnav from "../../assets/dealsroom/svg/prevnav.svg";
import fwdnav from "../../assets/dealsroom/svg/fwdnav.svg";
import person from "../../assets/acqusition/img/man.png";
import {useState} from "react";
import OffMarkets from "../DealsRoom/OffMarketes";
import AssetCard from "../DealsRoom/AssetCard";
import MaketSurvey from "../DealsRoom/MaketSurvey";
import {InputBox, SelectBox} from "../../components/reusableComponents/FormFields/FormFields";
import {Checkmark, CheckmarkTICK} from "../../assets/svg/checkmark";
import DropDown from "../../components/reusableComponents/Dropdown/Dropdown";
import {Checkbox, FormControlLabel, FormGroup, Slider} from "@mui/material";
import {ThumbsSlider} from "../../components/reusableComponents/ThumbsSlider/ThumbsSlider";
import DataPointChart from "./dist/DataPointChart";
import HexaChart from "./dist/HexaChart";
import BarChart from "./dist/BarChart";
import {useSelector} from "react-redux";
import LineChartNew from "./dist/LineChartNew";
import {PropertyDetails, PropertyFeatures, LandDoc, AdvancedDoc, FinancingDoc} from "./AssetInformationData/AssetInformationData";
import {useParams, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {fetchPortfolio} from "../../store/reducers/portfolioSlice";
import {Collapse} from "antd";
// import {AccessPopup} from "../portfolio/portfolioData/portfolioDatanew";
import {Link} from "react-router-dom";
import plusIcon from "../../assets/portfolio/svgs/plus.svg";
import {AssetHeader, InviteBox, OverviewHeader, PortfolioDashboard, AccessPopup} from "../portfolio/portfolioData/portfolioDatanew";
import {CaretRightOutlined} from "@ant-design/icons";
import defaultimage from "../../assets/floor_plan.png";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress,Typography} from "@mui/material";
import axios from "axios";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PDF_Icon from "../../assets/svg/pdf_4726010.svg";
import {styled} from "@mui/material/styles";
import {SimilarProperties_list} from "../../utils/Axios";
import Upload_Image from "../../assets/svg/Upload_PDF.svg";
import Endpoint from "../../utils/Endpoint";

const AssetInformation = ({isClicked, selectedData}) => {
  const [navActive, setNavActive] = useState(1);
  const [utiNavActive, setUtiNavActive] = useState(1);
  const [proNavActive, setProNavActive] = useState(1);
  const [expNavActive, setExpNavActive] = useState(1);
  const [mktNavActive, setMktNavActive] = useState(1);
  const [check, setCheck] = useState(false);
  const [builtGraph, setBuiltGraph] = useState(1);
  const [countryList, setCountrylist] = useState(["Kindergarden", "Elementarye", "Middle", "High"]);
  const [countryOfCitizenship, setCountryOfCitizenship] = useState("Kindergarden");
  const [countryOfCitizenshipDA, setCountryOfCitizenshipDA] = useState(false);
  const [pricethree, setPricethree] = useState({from: 0, to: 10, any: false});
  const [typeList, setTypeList] = useState(["All types", "Road", "New"]);
  const [typeListSel, setTypeListSel] = useState("All Types");
  const [typeListDa, setTypeListDa] = useState(false);
  const assets = useSelector((state) => state.AdminGivenAccess.assets) || [];
  const [open, setOpen] = useState(false);
  const [selectedPDF, setSelectedPDF] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadedPDFs, setUploadedPDFs] = useState([]);
  const [openpdf, setOpenpdf] = useState(false);
  const [fileName, setFileName] = useState("");
  const {id} = useParams();
  const dispatch = useDispatch();
  const onChange = (key) => {};
  const {userInformation, idToken} = useSelector((state) => state.auth);
  const [openInvite, setOpenInvite] = useState(false);
  const [inviteBoxKey, setInviteBoxKey] = useState(0);
  const [selectedImages, setSelectedImages] = useState([]);
  const {userPermissions} = useSelector((state) => state.userPermissions || {});
  const [selectedFile, setSelectedFile] = useState(null);

  const scrollToSection = (targetId) => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const yOffset = targetElement.getBoundingClientRect().top - 100;
      window.scrollTo({
        top: yOffset,
        behavior: "smooth",
      });
    }
  };

  const onDropFile = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      if (file.type === "application/pdf") {
        onFileChange({target: {files: [file]}});
      } else {
        alert("Please upload a PDF file.");
      }
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleInvite = () => {
    setOpenInvite(!openInvite);
    if (!openInvite) {
      setInviteBoxKey((prevKey) => prevKey + 1);
    }
  };

  // Floor Image Upload

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
    setLoading(false);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const filePreviews = files.map((file) => URL.createObjectURL(file));
    setSelectedImages(filePreviews);
  };

  const handleUpload = () => {
    if (selectedFile) {
      setLoading(true);
    }
  };

  // Pdf Documents

  const onFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file ? file.name : "");
    handleFileSelect(event);
  };

  useEffect(() => {
    dispatch(fetchPortfolio(id));
  }, [dispatch, id]);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type !== "application/pdf") {
        alert("Please select a PDF file.");
        event.target.value = "";
        return;
      }
      setSelectedPDF(file);
    }
  };

  const fetchUploadedPDFs = async () => {
    try {
      const response = await axios.get(`${Endpoint.uploaded_pdfs}?assetId=${id}`, {
        headers: {
          "auth-token": idToken,
        },
      });
      setUploadedPDFs(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchUploadedPDFs();
  }, []);

  const handleUploadPDF = async () => {
    if (!selectedPDF) return;

    setLoading(true);
    const formData = new FormData();
    formData.append("files", selectedPDF);
    let config = {
      headers: {
        "auth-token": idToken,
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const response = await axios.post(`${Endpoint.uploadPdf}?assetId=${id}`, formData, config);

      if (response.status === 200) {
        setSelectedPDF(null);
        fetchUploadedPDFs();
      }
    } catch (error) {
    } finally {
      setLoading(false);
      handleClosePDF();
    }
  };

  const handleClickOpenPDF = () => {
    setOpenpdf(true);
  };

  const handleClosePDF = () => {
    setOpenpdf(false);
    setSelectedPDF(null);
  };

  const [similarProperties, setSimilarProperties] = useState([]);
  useEffect(() => {
    const fetchSimilarProperties = async () => {
      let assetId = selectedData?._id;
      const result = await SimilarProperties_list(idToken, assetId);
      setSimilarProperties(result.data);
    };

    fetchSimilarProperties();
  }, []);

  const items = [
    {
      key: "1",
      label: "Land documents",
      children: (
        <div className="PDF_Management_Upload">
          <h3>Land documents (2)</h3>

          <Dialog
            open={openpdf}
            onClose={handleClosePDF}
            aria-labelledby="upload-pdf-dialog-title"
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle
              id="upload-pdf-dialog-title"
              style={{
                backgroundColor: "#f5f5f5",
                borderBottom: "1px solid #ddd",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Upload PDF
            </DialogTitle>
            <DialogContent style={{padding: "20px"}}>
              {fileName && selectedPDF && (
                <div
                  className="pdf-preview"
                  style={{
                    textAlign: "center",
                    padding: "15px",
                    backgroundColor: "#f9f9f9",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                    marginBottom: "20px",
                  }}
                >
                  <iframe
                    src={URL.createObjectURL(selectedPDF)}
                    title="PDF Preview"
                    className="PDF_Icon_previews"
                    style={{
                      width: "100%",
                      height: "180px",
                      border: "none",
                      borderRadius: "5px",
                    }}
                  />
                  <div
                    style={{
                      marginTop: "10px",
                      fontWeight: "500",
                      color: "#333",
                    }}
                  >
                    {fileName}
                  </div>
                </div>
              )}
              {loading && (
                <CircularProgress
                  size={24}
                  style={{
                    display: "block",
                    margin: "20px auto",
                    color: "#3f51b5",
                  }}
                />
              )}
              <div
                className="drop-area"
                style={{
                  textAlign: "center",
                  padding: "20px",
                  border: "2px dashed #ddd",
                  borderRadius: "10px",
                  backgroundColor: "#fafafa",
                  cursor: "pointer",
                  marginBottom: "20px",
                  width: "100%",
                }}
                onDrop={onDropFile}
                onDragOver={onDragOver}
              >
                <img
                  src={Upload_Image}
                  alt="Drop icon"
                  className="drop-icon"
                  style={{
                    width: "50px",
                    height: "50px",
                    marginBottom: "10px",
                  }}
                />
                <div
                  className="drop-text"
                  style={{fontSize: "16px", color: "#555"}}
                >
                  Drop files here
                </div>
                <div
                  className="file-types"
                  style={{fontSize: "12px", color: "#999"}}
                >
                  You can upload Only pdf
                </div>
              </div>
              <label
                htmlFor="file-upload"
                className="file-upload-label"
                style={{
                  display: "block",
                  textAlign: "center",
                  marginBottom: "10px",
                }}
              >
                <span
                  className="file-upload-button"
                  style={{
                    padding: "10px 20px",
                    // backgroundColor: "#3f51b5",
                    color: "#fff",
                    borderRadius: "5px",
                    cursor: "pointer",
                    display: "inline-block",
                  }}
                >
                  Choose PDF
                </span>
                <input
                  id="file-upload"
                  accept="application/pdf"
                  type="file"
                  onChange={onFileChange}
                  className="file-upload-input"
                  style={{display: "none"}}
                />
              </label>
            </DialogContent>
            <DialogActions style={{padding: "10px 20px", borderTop: "1px solid #ddd"}}>
              <Button
                onClick={handleClosePDF}
                color="primary"
                disabled={loading}
                style={{
                  fontWeight: "500",
                  color: loading ? "#999" : "#3f51b5",
                }}
              >
                Close
              </Button>
              <Button
                onClick={handleUploadPDF}
                color="primary"
                disabled={!selectedPDF || loading}
                style={{
                  fontWeight: "500",
                  backgroundColor: selectedPDF && !loading ? "#3f51b5" : "#ddd",
                  color: "#fff",
                  padding: "8px 20px",
                  borderRadius: "5px",
                }}
              >
                Upload
              </Button>
            </DialogActions>
          </Dialog>

          <div className="Land_documents_PDF_flex">
            {uploadedPDFs?.length > 0 ? (
              uploadedPDFs?.map((pdf, index) => (
                <div
                  key={index}
                  className="pdf-card"
                >
                  <iframe
                    src={pdf.url}
                    title={`PDF Preview ${index}`}
                    className="pdf-thumbnail"
                    style={{
                      width: "100%",
                      height: "350px",
                      border: "none",
                      objectFit: "cover",
                    }}
                  />
                  <div className="pdf-details">
                    <span className="pdf-title">{pdf.name}</span>
                  </div>
                </div>
              ))
            ) : (
              <p>No documents uploaded yet.</p>
            )}
          </div>

          {userInformation?.roleTypes === 2 && (
            <Button
              sx={{
                backgroundColor: "#6E50BA",
                color: "#textColor",
                "&:hover": {
                  backgroundColor: "#yourHoverColor",
                },
              }}
              variant="contained"
              onClick={handleClickOpenPDF}
            >
              Upload PDF
            </Button>
          )}
        </div>
      ),
    },
  ];

  // google Map
  const openGoogleMap = () => {
    const latAndLong = selectedData?.lat_and_long;
    if (latAndLong && latAndLong?.length === 1 && latAndLong[0]?.length === 2) {
      const latitude = latAndLong[0][0];
      const longitude = latAndLong[0][1];
      const googleMapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
      window.open(googleMapUrl, "_blank");
    }
  };

  // For Street View
  const openStreetView = () => {
    const latAndLong = selectedData?.lat_and_long;
    if (latAndLong && latAndLong?.length === 1 && latAndLong[0]?.length === 2) {
      const latitude = latAndLong[0][0];
      const longitude = latAndLong[0][1];
      const streetViewUrl = `https://www.google.com/maps?q=&layer=c&cbll=${latitude},${longitude}`;
      window.open(streetViewUrl, "_blank");
    }
  };

  return (
    <div className="AssetInformation-deals-room-center asset-center-con">
      <div className="deals-room-center-header">
        <div className="acq-head-left">
          <div>
            <div className="acq-head_menu_items">
              <img
                src={photo}
                alt="image"
              />
              <span>Photos</span>
            </div>
            <div className="acq-head_menu_items">
              <img
                src={location}
                alt="image"
              />
              <span onClick={openGoogleMap}>Location</span>
            </div>
            <div className="acq-head_menu_items">
              <img
                src={street}
                alt="image"
              />
              <span onClick={openStreetView}>Street View</span>
            </div>
          </div>
          {/* <div>
            {userInformation?.roleTypes === 2 && userInformation?.email && <h6 className="contributors_text">{assets?.length} Total Collaborators</h6>}
            {userInformation?.roleTypes === 2 && userInformation?.email && (
              <button
                onClick={handleInvite}
                className="invite-button"
              >
                <img
                  src={plusIcon}
                  alt="Invite icon"
                />
                <h4>Invite</h4>
              </button>
            )}
          </div> */}
        </div>
        <div className="acq-head-right">
          <div>
            <img
              src={bookmark}
              alt="image"
            />
          </div>
          <div>
            <img
              src={share}
              alt="image"
            />
          </div>
          <div>
            <img
              src={compare}
              alt="image"
            />
          </div>
        </div>
      </div>
      {openInvite && userPermissions && (
        <InviteBox
          key={inviteBoxKey}
          handleInvite={handleInvite}
          assetsData={assets}
          selectedData={selectedData}
        />
      )}
      <div className="asset-main-container AssetInformation-main-Container">
        <div className="asset-main-center">
          <div className="asset-img-hero">
            <ThumbsSlider selectedData={selectedData} />
          </div>
          <div className="AssetInformation-data">
            <span
              className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
              onClick={() => scrollToSection("section1")}
            >
              Property Details
            </span>
            <span
              className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
              onClick={() => scrollToSection("section2")}
            >
              Utilities & Amenities
            </span>
            <span
              className={navActive === 3 && "deals-room-nav-active ut-nav-active"}
              onClick={() => scrollToSection("section3")}
            >
              Property History
            </span>
            <span
              className={navActive === 4 && "deals-room-nav-active ut-nav-active"}
              onClick={() => scrollToSection("explore-section")}
            >
              Comps
            </span>

            <span
              className={navActive === 5 && "deals-room-nav-active ut-nav-active"}
              onClick={() => scrollToSection("explore-section")}
            >
              Explore
            </span>

            <span
              className={navActive === 6 && "deals-room-nav-active ut-nav-active"}
              onClick={() => scrollToSection("test1")}
            >
              Market Survey
            </span>

            <span
              className={navActive === 7 && "deals-room-nav-active ut-nav-active"}
              onClick={() => scrollToSection("section7")}
            >
              Market overview
            </span>
            <span
              className={navActive === 8 && "deals-room-nav-active ut-nav-active"}
              onClick={() => scrollToSection("section8")}
            >
              Similar
            </span>
          </div>

          <div
            className="property-d"
            id="section1"
            style={{paddingTop: "30px"}}
          >
            <span>{selectedData?.assetName}</span>
          </div>
          <div className="property-wrapper">
            <div className="property-wrapper-left">
              <span>Description</span>
              <span className="property-description">{selectedData?.assetDescription}</span>
              <Collapse
                items={items}
                defaultActiveKey={["1"]}
                onChange={onChange}
                accordion
                expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              />
              <div className="divider"></div>
            </div>
            <div className="property-wrapper-right">
              <span>FLOOR PLAN</span>
              <div className="property-img-wrapper">
                <img
                  src={selectedData?.advanceAssets?.asset_info?.property_details?.floor_plan[1] || defaultimage}
                  alt="image"
                />
                <img
                  src={selectedData?.advanceAssets?.asset_info?.property_details?.floor_plan[1] || defaultimage}
                  alt="image"
                />
                <img
                  src={selectedData?.advanceAssets?.asset_info?.property_details?.floor_plan[2] || defaultimage}
                  alt="image"
                />
                <img
                  src={selectedData?.advanceAssets?.asset_info?.property_details?.floor_plan[3] || defaultimage}
                  alt="image"
                />
              </div>
              {userInformation?.roleTypes === 2 && (
                <Button
                  variant="contained"
                  // color="primary"
                  className="floorPlan_btn"
                  onClick={handleClickOpen}
                  sx={{
                    backgroundColor: "#6E50BA",
                    color: "#textColor",
                    "&:hover": {
                      backgroundColor: "#yourHoverColor",
                    },
                  }}
                >
                  Update
                </Button>
              )}

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
              >
                <DialogTitle id="alert-dialog-title">{"Update Information"}</DialogTitle>
                <DialogContent>
                  <div style={{textAlign: "center", marginBottom: "20px"}}>
                    <Typography
                      variant="body1"
                      style={{marginBottom: "10px"}}
                    >
                      Select Images
                    </Typography>
                    <Button
                      component="label"
                      accept="image/*"
                      role={undefined}
                      variant="contained"
                      color="primary"
                      tabIndex={-1}
                      onChange={handleFileChange}
                      startIcon={<CloudUploadIcon />}
                      style={{
                        marginBottom: "16px",
                        marginTop: "10px",
                        padding: "10px 20px",
                        fontSize: "16px",
                      }}
                    >
                      Select
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileChange}
                        multiple
                      />
                    </Button>
                  </div>

                  {/* Display selected images as previews */}
                  {selectedImages.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                        marginTop: "20px",
                        justifyContent: "center",
                      }}
                    >
                      {selectedImages.map((image, index) => (
                        <div
                          key={index}
                          style={{position: "relative"}}
                        >
                          <img
                            src={image}
                            alt={`preview-${index}`}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              borderRadius: "8px",
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  )}

                  {loading && (
                    <CircularProgress
                      size={24}
                      style={{display: "block", margin: "20px auto"}}
                    />
                  )}
                </DialogContent>
                <DialogActions style={{marginTop: "16px"}}>
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    disabled={loading}
                    style={{marginRight: "8px"}}
                  >
                    Close
                  </Button>
                  <Button
                    onClick={handleUpload}
                    color="primary"
                    disabled={selectedImages.length === 0 || loading}
                    variant="contained"
                    style={{padding: "6px 16px"}}
                  >
                    Upload
                  </Button>
                </DialogActions>
              </Dialog>

              <div className="property-f-wrapper">
                <span className="property-f-title">Property Features</span>
                <PropertyFeatures
                  svgPath={assetype}
                  name={"Asset Type"}
                  details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.asset_type}
                />
                <PropertyFeatures
                  svgPath={calender}
                  name={"Year Built"}
                  details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.year_built}
                />
                <div>
                  <PropertyFeatures
                    svgPath={building_area}
                    name={"Building Area"}
                    details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.building_area}
                  />
                  <PropertyFeatures
                    svgPath={total_unit}
                    name={"Total Units"}
                    details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.total_units}
                  />
                  <PropertyFeatures
                    svgPath={zoning}
                    name={"Zoning"}
                    details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.zoning}
                  />
                </div>
                <PropertyFeatures
                  svgPath={metro}
                  name={"Metro"}
                  details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.metro}
                />
                <PropertyFeatures
                  svgPath={market}
                  name={"Market"}
                  details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.market}
                />
                <PropertyFeatures
                  svgPath={sub_market}
                  name={"Sub-market"}
                  details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.sub_market}
                />
                <PropertyFeatures
                  svgPath={parking}
                  name={"Parking"}
                  details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.parking}
                />
                <PropertyFeatures
                  svgPath={price}
                  name={"Price/sqft"}
                  details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.price_sqft}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="utilities">
              <div className="wrapper-heading">Utilities & Amenities</div>
              <div className="deals-room-nav asset-nav ut-nav">
                <span
                  className={utiNavActive === 1 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setUtiNavActive(1);
                  }}
                >
                  Utilities Arrangement
                </span>
                <span
                  className={utiNavActive === 2 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setUtiNavActive(2);
                  }}
                >
                  Amenities
                </span>
                <span
                  className={utiNavActive === 3 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setUtiNavActive(3);
                  }}
                >
                  Jurisdiction
                </span>
                <span
                  className={utiNavActive === 4 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setUtiNavActive(4);
                  }}
                >
                  Valuation & Taxes
                </span>
              </div>
              <div className={`utilities-main-wrapper ${(utiNavActive == 1 || utiNavActive == 4) && " utilities-w-p"} `}>
                {utiNavActive === 1 && (
                  <div>
                    <div className="table-wrapper">
                      <div className="table-h">
                        <span>UTILITY</span>
                        <span>SERVICE</span>
                        <span>PAID BY</span>
                        <span>METER TYPE</span>
                      </div>
                      <div>
                        <span>Water/Sewer</span>
                        <span>Pepco</span>
                        <span>Resident</span>
                        <span>Individual</span>
                      </div>
                      <div>
                        <span>Water/Sewer</span>
                        <span>Pepco</span>
                        <span>Resident</span>
                        <span>Individual</span>
                      </div>
                      <div>
                        <span>Water/Sewer</span>
                        <span>Pepco</span>
                        <span>Resident</span>
                        <span>Individual</span>
                      </div>
                    </div>
                    <img
                      src={utilities}
                      alt=""
                    />
                  </div>
                )}

                {utiNavActive === 2 && (
                  <div>
                    <div className="amenities-wrapper">
                      <span>Types of Locations</span>
                      <div className="amenities-w-main">
                        <div>
                          <span>View of Coastal, City or Hills</span>
                        </div>
                      </div>
                      <span>Other</span>
                      <div className="amenities-w-main">
                        <div>
                          <span>Portable water available</span>
                        </div>
                        <div>
                          <span>Bins available</span>
                        </div>
                        <div>
                          <span>Washing machine available</span>
                        </div>
                        <div>
                          <span>Free Wi-Fi</span>
                        </div>
                        <div>
                          <span>Showers available</span>
                        </div>
                      </div>
                    </div>
                    <div className="amenities-wrapper">
                      <span>Types of Locations</span>
                      <div className="amenities-w-main">
                        <div>
                          <span>View of Coastal, City or Hills</span>
                        </div>
                      </div>
                      <span>Other</span>
                      <div className="amenities-w-main">
                        <div>
                          <span>Portable water available</span>
                        </div>
                        <div>
                          <span>Bins available</span>
                        </div>
                        <div>
                          <span>Washing machine available</span>
                        </div>
                        <div>
                          <span>Free Wi-Fi</span>
                        </div>
                        <div>
                          <span>Showers available</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {utiNavActive === 3 && (
                  <div className="jurisdiction-wrapper">
                    <div>
                      <span>Jurisdiction:</span>
                      <span>Texas</span>
                    </div>
                    <div>
                      <span>Tax rate:</span>
                      <span>$0.85 per $100 of assessed value</span>
                    </div>
                    <div>
                      <span>Assessment:</span>
                      <span>2017 - $9,598,460</span>
                    </div>
                    <div>
                      <span>Parcel number:</span>
                      <span>TXC WASA</span>
                    </div>
                  </div>
                )}

                {utiNavActive === 4 && (
                  <div className="value-taxes-main">
                    <div className="valuation-taxes-wrapper">
                      <span>Valuation</span>

                      <div className="valuation-taxes-wrapper-main">
                        <div>
                          <div>
                            <img
                              src={calender}
                              alt=""
                            />
                            <span>Assessed year</span>
                          </div>
                          <span>2017</span>
                        </div>
                        <div className="divider"></div>
                        <div>
                          <div>
                            <img
                              src={mortgage}
                              alt=""
                            />
                            <span>Assessed amount</span>
                          </div>
                          <span>$2,545</span>
                        </div>
                      </div>
                    </div>
                    <div className="valuation-taxes-wrapper">
                      <span>Valuation</span>

                      <div className="valuation-taxes-wrapper-main">
                        <div>
                          <div>
                            <img
                              src={calender}
                              alt=""
                            />
                            <span>Assessed year</span>
                          </div>
                          <span>2017</span>
                        </div>
                        <div className="divider"></div>
                        <div>
                          <div>
                            <img
                              src={profit}
                              alt=""
                            />
                            <span>Assessed amount</span>
                          </div>
                          <span>$2,545</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className="property-history-wrapper"
              id="section3"
              style={{paddingTop: "50px"}}
            >
              <div className="wrapper-heading">Property History</div>
              <div className="deals-room-nav asset-nav table-nav ut-nav">
                <span
                  className={proNavActive === 1 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setProNavActive(1);
                  }}
                >
                  Sales History
                </span>
                <span
                  className={proNavActive === 2 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setProNavActive(2);
                  }}
                >
                  Price History
                </span>
              </div>

              {proNavActive === 1 && (
                <div className="p-table-wrapper">
                  <table className="p-table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Event</th>
                        <th>PRICE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedData?.advanceAssets?.asset_info?.property_history?.sales_history.map((row, index) => (
                        <tr key={index}>
                          <td>{row.date}</td>
                          <td>{row.event}</td>
                          <td>{row.price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {proNavActive === 2 && (
                <div className="property-wrapper-main">
                  <div className="price-history-upper">
                    <div className="price-h-card">
                      <div>
                        <span>This Asset</span>
                        <span>Deviation</span>
                      </div>
                      <div>
                        <span>{selectedData?.advanceAssets?.asset_info?.property_history?.price_history?.asset_price}</span>
                        <div>
                          <span>{selectedData?.advanceAssets?.asset_info?.property_history?.price_history?.asset_deviation}</span>
                          <img
                            src={increase}
                            alt=""
                          />
                        </div>
                      </div>
                      <div>
                        <span>Aug. 14, 2018</span>
                        <span>Today</span>
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="price-h-card">
                      <div>
                        <span>Average Market</span>
                        <span>Deviation</span>
                      </div>
                      <div>
                        <span>{selectedData?.advanceAssets?.asset_info?.property_history?.price_history?.market_price}</span>
                        <div>
                          <span>{selectedData?.advanceAssets?.asset_info?.property_history?.price_history?.market_deviation}</span>
                          <img
                            src={decrease}
                            alt=""
                          />
                        </div>
                      </div>
                      <div>
                        <span>Aug. 14, 2018</span>
                        <span>Today</span>
                      </div>
                    </div>

                    <InputBox
                      type={"date"}
                      placeholder={"7 years"}
                      svgPath={dropdown}
                    />
                  </div>
                  <div>
                    {/* <img
                      src={pricehistory}
                      alt=""
                    /> */}
                    <LineChartNew data={selectedData?.advanceAssets?.asset_info?.property_history?.price_history?.price_history_chart} />
                  </div>
                </div>
              )}
            </div>
            <div
              className="explore-wrapper"
              id="explore-section"
              style={{paddingTop: "50px"}}
            >
              <div className="wrapper-heading">Explore</div>
              <div className="deals-room-nav asset-nav table-nav ut-nav">
                <span
                  className={expNavActive === 1 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setExpNavActive(1);
                  }}
                >
                  Commute time
                </span>
                <span
                  className={expNavActive === 2 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setExpNavActive(2);
                  }}
                >
                  Educational
                </span>
                <span
                  className={expNavActive === 3 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setExpNavActive(3);
                  }}
                >
                  Lifestyle
                </span>
                <span
                  className={expNavActive === 4 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setExpNavActive(4);
                  }}
                >
                  Potential Pros & Cons
                </span>
              </div>

              {expNavActive == 1 && (
                <div className="explore-body-wrapper">
                  <div className="explore-body-right">
                    <img
                      src={map}
                      alt=""
                    />
                  </div>
                  <div className="explore-body-left">
                    <h1>Commute Time</h1>
                    <div className="inp">
                      <InputBox
                        conCLass={"explore-input"}
                        label={"Address"}
                        svgPath={cross}
                        name={"Address"}
                        placeholder={"600 N Michigan Ave, Atlantic Cit"}
                      />
                      <img
                        src={mappin}
                        alt=""
                      />
                    </div>
                    <div
                      className="explore-checkbox"
                      onClick={() => {
                        setCheck(!check);
                      }}
                    >
                      {check ? <CheckmarkTICK /> : <Checkmark />}
                      <h2>Include Traffic</h2>
                    </div>
                    <div className="explore-ways">
                      <span>Way of getting there</span>
                      <div>
                        <img
                          src={walk}
                          alt=""
                        />
                        <img
                          src={car}
                          alt=""
                        />
                        <img
                          src={train}
                          alt=""
                        />
                        <img
                          src={cycle}
                          alt=""
                        />
                      </div>
                    </div>
                    <span className="explore-para">
                      {" "}
                      <span>45 min</span> by walk to 600 N Michigan Ave,Atlantic City, NJ 08401
                    </span>

                    <div className="explore-refresh">
                      <img
                        src={refresh}
                        alt=""
                      />
                      <span> Recalculate</span>
                    </div>
                  </div>
                </div>
              )}

              {expNavActive == 2 && (
                <div className="explore-body-wrapper">
                  <div className="explore-body-right">
                    <img
                      src={mapround}
                      alt=""
                    />
                  </div>
                  <div className="educational-left">
                    <div>
                      <DropDown
                        label={"Type"}
                        classDataName={"dropdown-height "}
                        data={countryList}
                        selectedData={countryOfCitizenship}
                        setSelectedData={setCountryOfCitizenship}
                        dropdownState={countryOfCitizenshipDA}
                        dropdownSetter={setCountryOfCitizenshipDA}
                      />

                      <div>
                        <div className="slider-label">
                          <span>Radius</span>
                        </div>
                        <div className="slider_container">
                          <div className="top_div inputFlexLine">
                            <p></p>
                            <span>{pricethree.from}&nbsp;mi</span>
                          </div>
                          <Slider
                            defaultValue={pricethree.from}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            step={10}
                            min={0}
                            max={100}
                            onChange={(event) => {
                              setPricethree((prev) => ({
                                ...prev,
                                from: event.target.value.toLocaleString(),
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="educational-card">
                        <div>
                          <span>Northwood School</span>
                          <span>1.5 mi</span>
                        </div>
                        <div>
                          <img
                            src={star}
                            alt=""
                          />
                          <span>4,7</span>
                        </div>
                      </div>
                      <div className="educational-card">
                        <div>
                          <span>Northwood School</span>
                          <span>1.5 mi</span>
                        </div>
                        <div>
                          <img
                            src={star}
                            alt=""
                          />
                          <span>4,7</span>
                        </div>
                      </div>
                      <div className="educational-card">
                        <div>
                          <span>Northwood School</span>
                          <span>1.5 mi</span>
                        </div>
                        <div>
                          <img
                            src={star}
                            alt=""
                          />
                          <span>4,7</span>
                        </div>
                      </div>
                    </div>
                    <div className="page-nxt-slider">
                      <div className="page-nxt-slider-inner">
                        <div>
                          <img
                            src={prev}
                            alt=""
                          />
                        </div>
                        <div>1</div>
                        <div>2</div>
                        <div>3</div>
                        <div>4</div>
                        <div>5</div>
                        <div>
                          <img
                            src={next}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {expNavActive == 3 && (
                <div className="explore-body-wrapper">
                  <div className="explore-body-right">
                    <img
                      src={mapround}
                      alt=""
                    />
                  </div>
                  <div className="educational-left">
                    <div>
                      <DropDown
                        label={"Type"}
                        classDataName={"dropdown-height "}
                        data={countryList}
                        selectedData={countryOfCitizenship}
                        setSelectedData={setCountryOfCitizenship}
                        dropdownState={countryOfCitizenshipDA}
                        dropdownSetter={setCountryOfCitizenshipDA}
                      />

                      <div>
                        <div className="slider-label">
                          <span>Radius</span>
                        </div>
                        <div className="slider_container">
                          <div className="top_div inputFlexLine">
                            <p></p>
                            <span>{pricethree.from}&nbsp;mi</span>
                          </div>
                          <Slider
                            defaultValue={pricethree.from}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            step={10}
                            min={0}
                            max={100}
                            onChange={(event) => {
                              setPricethree((prev) => ({
                                ...prev,
                                from: event.target.value.toLocaleString(),
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="educational-card">
                        <div>
                          <span>Northwood School</span>
                          <span>1.5 mi</span>
                        </div>
                        <div>
                          <img
                            src={star}
                            alt=""
                          />
                          <span>4,7</span>
                        </div>
                      </div>
                      <div className="educational-card">
                        <div>
                          <span>Northwood School</span>
                          <span>1.5 mi</span>
                        </div>
                        <div>
                          <img
                            src={star}
                            alt=""
                          />
                          <span>4,7</span>
                        </div>
                      </div>
                      <div className="educational-card">
                        <div>
                          <span>Northwood School</span>
                          <span>1.5 mi</span>
                        </div>
                        <div>
                          <img
                            src={star}
                            alt=""
                          />
                          <span>4,7</span>
                        </div>
                      </div>
                    </div>
                    <div className="page-nxt-slider">
                      <div className="page-nxt-slider-inner">
                        <div>
                          <img
                            src={prev}
                            alt=""
                          />
                        </div>
                        <div>1</div>
                        <div>2</div>
                        <div>3</div>
                        <div>4</div>
                        <div>5</div>
                        <div>
                          <img
                            src={next}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {expNavActive == 4 && (
                <div className="explore-body-wrapper">
                  <div className="explore-body-right">
                    <img
                      src={mapround}
                      alt=""
                    />
                  </div>
                  <div className="educational-left">
                    <div>
                      <DropDown
                        label={"Type"}
                        classDataName={"dropdown-height "}
                        data={typeList}
                        selectedData={typeListSel}
                        setSelectedData={setTypeListSel}
                        dropdownState={typeListDa}
                        dropdownSetter={setTypeListDa}
                      />

                      <div>
                        <div className="slider-label">
                          <span>Radius</span>
                        </div>
                        <div className="slider_container">
                          <div className="top_div inputFlexLine">
                            <p></p>
                            <span>{pricethree.from}&nbsp;mi</span>
                          </div>
                          <Slider
                            defaultValue={pricethree.from}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            step={10}
                            min={0}
                            max={100}
                            onChange={(event) => {
                              setPricethree((prev) => ({
                                ...prev,
                                from: event.target.value.toLocaleString(),
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="potential">
                      <div className="edu-l">
                        <div className="mall">
                          <img
                            className="ml"
                            src={mall}
                            alt=""
                          />
                        </div>
                        <img
                          src={show}
                          alt=""
                        />
                      </div>
                      <div className="edu-r">
                        <div>
                          <span>A new shopping mall is under construction. </span>
                          <img
                            src={dropdownbg}
                            alt=""
                          />
                        </div>
                        <div>
                          <span>1.5 miles</span>
                          <span>That may bring inconvenience for 1-2 years in this area. However after that it’ll be a perfect location with a brand new crossroad.</span>
                        </div>
                      </div>
                    </div>

                    <div className="deals-room-doc-page-nav center">
                      <div className="page-nav-cont">
                        <img
                          src={prevnav}
                          alt=""
                        />
                        <span>2 of 10</span>
                        <img
                          src={fwdnav}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* <div
              className="market-survey-wrapper"
              name="test1"
              id="test1"
              style={{paddingTop: "50px"}}
            >
              <div className="market-sur-heading">
                <span>Market Survey</span>
              </div>

              <div className="deals-room-nav asset-nav ut-nav">
                <span
                  className={mktNavActive === 1 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setMktNavActive(1);
                  }}
                >
                  Property Details
                </span>
                <span
                  className={mktNavActive === 2 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setMktNavActive(2);
                  }}
                >
                  Utilities & Amenities
                </span>
                <span
                  className={mktNavActive === 3 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setMktNavActive(3);
                  }}
                >
                  Property History
                </span>
              </div>

              {mktNavActive == 1 && (
                <div className="news-s-wrapper">
                  {" "}
                  <MaketSurvey />
                  <MaketSurvey />
                </div>
              )}

              {mktNavActive === 2 && (
                <div className="social-net-wrapper">
                  <div className="social-n-l">
                    <div className="social-heading">2,365 Posts</div>

                    <div className="social-n-card">
                      <div className="s-n-c-main">
                        <div className="n-card-upper">
                          <div className="n-c-u-l">
                            <img
                              className="ver"
                              src={person}
                              alt=""
                            />
                            <div>
                              <span>Gregory Ray</span>
                              <span>@Gregory Ray</span>
                            </div>
                            <img
                              className=""
                              src={verified}
                              alt=""
                            />
                          </div>
                          <div className="n-c-u-r">
                            {" "}
                            <img
                              src={twitter}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="n-card-center">
                          {" "}
                          <span>
                            I can’t figure out the how to do the mask on the circle and the speaker holes for the life of me. I select them all and then select the circle layer and choose layer mask.
                            I’ve tried all the options under layer mask and it’s not turning out…
                          </span>{" "}
                        </div>
                        <div className="n-card-down">
                          <div>
                            <div>
                              <img
                                src={love}
                                alt=""
                              />{" "}
                              <span>249</span>
                            </div>
                            <div>
                              {" "}
                              <span>7:48 PM - May 8, 2019</span>
                            </div>
                          </div>
                          <div>
                            {" "}
                            <img
                              src={info}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="divider d"></div>
                      <div className="s-n-c-main-down">
                        <div>
                          <img
                            src={comment}
                            alt=""
                          />
                          <span>110 people are talking about this</span>
                        </div>
                        <div>
                          {" "}
                          <img
                            src={rightarrow}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="social-n-card">
                      <div className="s-n-c-main">
                        <div className="n-card-upper">
                          <div className="n-c-u-l">
                            <img
                              className="ver"
                              src={person}
                              alt=""
                            />
                            <div>
                              <span>Gregory Ray</span>
                              <span>@Gregory Ray</span>
                            </div>
                            <img
                              className=""
                              src={verified}
                              alt=""
                            />
                          </div>
                          <div className="n-c-u-r">
                            {" "}
                            <img
                              src={twitter}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="n-card-center">
                          {" "}
                          <span>
                            I can’t figure out the how to do the mask on the circle and the speaker holes for the life of me. I select them all and then select the circle layer and choose layer mask.
                            I’ve tried all the options under layer mask and it’s not turning out…
                          </span>{" "}
                        </div>
                        <div className="n-card-down">
                          <div>
                            <div>
                              <img
                                src={love}
                                alt=""
                              />{" "}
                              <span>249</span>
                            </div>
                            <div>
                              {" "}
                              <span>7:48 PM - May 8, 2019</span>
                            </div>
                          </div>
                          <div>
                            {" "}
                            <img
                              src={info}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="divider d"></div>
                      <div className="s-n-c-main-down">
                        <div>
                          <img
                            src={comment}
                            alt=""
                          />
                          <span>110 people are talking about this</span>
                        </div>
                        <div>
                          {" "}
                          <img
                            src={rightarrow}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="social-n-r">
                    <div className="social-filter-heading">Filter Results</div>
                    <div className="show-filter">
                      <span>Show</span>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Facebook"
                          />
                        </FormGroup>
                        <span>3,550 posts</span>
                      </div>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Twitter"
                          />
                        </FormGroup>
                        <span>785 posts</span>
                      </div>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Instagram"
                          />
                        </FormGroup>
                        <span>785 posts</span>
                      </div>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Linkedin"
                          />
                        </FormGroup>
                        <span>3,550 posts</span>
                      </div>
                    </div>

                    <div className="show-filter">
                      <span>Date added</span>
                      <InputBox type={"date"} />
                    </div>
                    <div className="show-filter">
                      <span>Sort by</span>
                      <InputBox
                        type={"date"}
                        placeholder={"Last 30 Days"}
                      />
                    </div>
                  </div>
                </div>
              )}
              {mktNavActive === 3 && (
                <div className="social-net-wrapper">
                  <div className="social-n-l asset-review">
                    <div className="social-heading">165 Ratings</div>
                    <div className="divider d"></div>

                    <div className="rating-wrapper">
                      <div className="rating-container">
                        <span>Location</span>
                        <div>
                          <div>
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={emptystar}
                              alt=""
                            />
                          </div>
                          <span>4.2</span>
                        </div>
                      </div>
                      <div className="rating-container">
                        <span>Accuracy</span>
                        <div>
                          <div>
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={emptystar}
                              alt=""
                            />
                          </div>
                          <span>4.2</span>
                        </div>
                      </div>
                      <div className="rating-container">
                        <span>Noise</span>
                        <div>
                          <div>
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={emptystar}
                              alt=""
                            />
                          </div>
                          <span>4.2</span>
                        </div>
                      </div>
                      <div className="rating-container">
                        <span>Activity</span>
                        <div>
                          <div>
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={emptystar}
                              alt=""
                            />
                            <img
                              src={emptystar}
                              alt=""
                            />
                          </div>
                          <span>3.2</span>
                        </div>
                      </div>
                      <div className="rating-container">
                        <span>Cleanliness</span>
                        <div>
                          <div>
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={emptystar}
                              alt=""
                            />
                          </div>
                          <span>4.2</span>
                        </div>
                      </div>
                    </div>
                    <div className="divider d"></div>
                    <div className="person-rating-wrapper">
                      <div className="person-r-upper">
                        <div className="prof">
                          <img
                            src={person}
                            alt=""
                          />
                          <div>
                            <span>Susie Chapman</span>
                            <span>May 2017</span>
                          </div>
                        </div>
                        <div>
                          <span>Yelp</span>
                          <div>
                            <div>
                              <img
                                src={fillstar}
                                alt=""
                              />
                              <img
                                src={fillstar}
                                alt=""
                              />
                              <img
                                src={fillstar}
                                alt=""
                              />
                              <img
                                src={fillstar}
                                alt=""
                              />
                              <img
                                src={emptystar}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="person-r-center">
                        <span>
                          You are empowered to make decisions and choose your own goals/tasks. Every day you meet so many amazing entrepreneurs as well as continuously build these relationships.
                          Inspiring and fun meetings, all-hands & trips (Summer Camp & Summit). Support system! Everyone cares for your well-being and become family.
                        </span>
                      </div>
                    </div>
                    <div className="divider d net"></div>
                    <div className="person-rating-wrapper">
                      <div className="person-r-upper">
                        <div className="prof">
                          <img
                            src={person}
                            alt=""
                          />
                          <div>
                            <span>Shon McWu </span>
                            <span>May 2017</span>
                          </div>
                        </div>
                        <div>
                          <span>Yelp</span>
                          <div>
                            <div>
                              <img
                                src={fillstar}
                                alt=""
                              />
                              <img
                                src={fillstar}
                                alt=""
                              />
                              <img
                                src={fillstar}
                                alt=""
                              />
                              <img
                                src={fillstar}
                                alt=""
                              />
                              <img
                                src={emptystar}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="person-r-center">
                        <span>You are empowered to make decisions and choose your own goals/tasks. Every day you meet so many amazing entrepreneurs as well as continuously.</span>
                      </div>
                    </div>
                  </div>
                  <div className="social-n-r">
                    <div className="social-filter-heading">Filter Results</div>
                    <div className="show-filter">
                      <span>Show</span>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Facebook"
                          />
                        </FormGroup>
                        <span>3,550 posts</span>
                      </div>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Twitter"
                          />
                        </FormGroup>
                        <span>785 posts</span>
                      </div>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Instagram"
                          />
                        </FormGroup>
                        <span>785 posts</span>
                      </div>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Linkedin"
                          />
                        </FormGroup>
                        <span>3,550 posts</span>
                      </div>
                    </div>

                    <div className="show-filter">
                      <span>Date added</span>
                      <InputBox type={"date"} />
                    </div>
                    <div className="show-filter">
                      <span>Sort by</span>
                      <InputBox
                        type={"date"}
                        placeholder={"Last 30 Days"}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="page-nxt-slider">
                <div className="page-nxt-slider-inner">
                  <div>
                    <img
                      src={prev}
                      alt=""
                    />
                  </div>
                  <div>1</div>
                  <div>2</div>
                  <div>3</div>
                  <div>4</div>
                  <div>5</div>
                  <div>
                    <img
                      src={next}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div
              className="building-wrapper"
              id="section7"
              style={{paddingTop: "50px"}}
            >
              <div className="building-wrapper-left">
                <HexaChart />
                {/* <img
                  src={propertyGraph}
                  alt=""
                /> */}
              </div>
              <div className="building-wrapper-right">
                <div className="b-w-r-h">
                  <span>How does {selectedData?.assetName} compare to the average similar nearby?</span>
                </div>

                <div className="built-card">
                  <PropertyDetails
                    svgPath={calender}
                    name={"Year Built"}
                    name_={"2 years older."}
                    details={"1966"}
                    details_={"1978 average"}
                  />
                  <div className="built-card-middle">
                    <PropertyDetails
                      svgPath={building_area}
                      name={"Building Area"}
                      name_={"2 years older."}
                      details={"7,670 sqft"}
                      details_={"6,658 sqft average"}
                    />
                    <PropertyDetails
                      svgPath={total_unit}
                      name={"Total Units"}
                      name_={"There are more units for  the same price."}
                      details={"312"}
                      details_={"410 average"}
                    />
                    <PropertyDetails
                      svgPath={zoning}
                      name={"Zoning"}
                      name_={"There's no clear advantage."}
                      details={"C-2"}
                    />
                  </div>
                  <div className="built-card-bottom">
                    <PropertyDetails
                      svgPath={metro}
                      name={"Metro"}
                      details={"Baltimor"}
                    />
                    <PropertyDetails
                      svgPath={market}
                      name={"Market"}
                      details={"Value"}
                    />
                    <PropertyDetails
                      svgPath={sub_market}
                      name={"Sub-market"}
                      details={"Value"}
                    />
                    <PropertyDetails
                      svgPath={parking}
                      name={"Parking"}
                      details={"3 places"}
                    />
                    <PropertyDetails
                      svgPath={price}
                      name={"Price/sqft"}
                      details={"$524"}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="built-graph-wrapper"
              id="section8"
              style={{paddingTop: "50px"}}
            >
              <div className="built-graph-nav">
                <div
                  className={builtGraph == 1 && "active"}
                  onClick={() => {
                    setBuiltGraph(1);
                  }}
                >
                  <img
                    src={calender}
                    alt=""
                  />
                  <span>Year Built</span>
                </div>
                <div
                  className={builtGraph == 2 && "active"}
                  onClick={() => {
                    setBuiltGraph(2);
                  }}
                >
                  <img
                    src={calender}
                    alt=""
                  />
                  <span>Building Area</span>
                </div>
                <div
                  className={builtGraph == 3 && "active"}
                  onClick={() => {
                    setBuiltGraph(3);
                  }}
                >
                  <img
                    src={calender}
                    alt=""
                  />
                  <span>Total Units</span>
                </div>
                <div
                  className={builtGraph == 4 && "active"}
                  onClick={() => {
                    setBuiltGraph(4);
                  }}
                >
                  <img
                    src={calender}
                    alt=""
                  />
                  <span>Price</span>
                </div>
                <div
                  className={builtGraph == 5 && "active"}
                  onClick={() => {
                    setBuiltGraph(5);
                  }}
                >
                  <img
                    src={calender}
                    alt=""
                  />
                  <span>Parking</span>
                </div>
              </div>
              <div className="b-g-g-s">
                {/* <span>7,568 Assets have it</span> */}
                {/* <img
                  src={builtgraph}
                  alt=""
                /> */}
                <BarChart />
              </div>
            </div>

            <div className="nearby-wrapper">
              <span>Similar In this range nearby</span>

              {/* Similar Properties */}

              <div className="nearby-card-wrapper">
                {similarProperties?.map((property, index) => (
                  <div
                    className="nearby-card"
                    key={index}
                  >
                    <div>
                      <div className="n-c-l"></div>
                      <img
                        src={property?.assetsImages[0]}
                        alt="property"
                        className="property_images"
                      />
                    </div>
                    <div className="n-c-b">
                      <span className="n-c-b-h">{property?.assetName}</span>
                      <div>
                        <span>${property?.assetValue.toLocaleString()}</span>
                        {/* <img
                          src={redirect}
                          alt="redirect"
                        /> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <OffMarkets />
          </div>
        </div>
        <div className="asset-main-right">
          <AssetCard selectedData={selectedData} />
        </div>
      </div>
    </div>
  );
};

export default AssetInformation;
