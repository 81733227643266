import React, {useState} from "react";
import {PrimaryButton, PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import "./CompanySetting.scss";
import SearchInput from "../../../components/reusableComponents/Search/Search";
import image1 from "../../../assets/Manage-syndicate/image/Person (Circle).png";
import image2 from "../../../assets/Manage-syndicate/image/Person (Circle1).png";
import image3 from "../../../assets/Manage-syndicate/image/Person (Circle10).png";
import image4 from "../../../assets/Manage-syndicate/image/Person (Circle11).png";
import image5 from "../../../assets/Manage-syndicate/image/Person (Circle3).png";
import image6 from "../../../assets/Manage-syndicate/image/Person (Circle4).png";
import image7 from "../../../assets/Manage-syndicate/image/Person (Circle5).png";
import image8 from "../../../assets/Manage-syndicate/image/Person (Circle6).png";

const CompanySetting = () => {
  const [selectedData, setSelectedData] = useState(null);

  const [activeButton, setActiveButton] = useState("");

  const handleButtonClick = (name) => {
    setActiveButton(name);
  };

  const buttonData = [
    "General",
    "USER AND PERMISSIONS",
    "PROFILE",
    "PAYMENTS INFO",
    "SUBSCRIPTION DETAILS",
    "FINANCIAL & HR",
    "INTEGRATION"
  ];
  
  return (
    <div>
      <div>
        <h1>Company Settings</h1>
      </div>
      <div>
        <div className="Company-Settings-container">
          {buttonData.map((name, index) => (
            <button
              key={index}
              className={`PrimaryButton ${activeButton === name ? "active" : ""}`}
              onClick={() => handleButtonClick(name)}
            >
              {name}
            </button>
          ))}
        </div>
      </div>
      <div></div>
      <div>
        <h1>Outsource Finance Firm</h1>
        <div className="CompanySetting-DropDown-container">
          <div className="CompanySetting-DropDown">
            <SearchInput />
            <select name="Saved views">
              <option
                value=""
                disabled
                selected
              >
                Add User
              </option>
              <option value="view1">View 1</option>
              <option value="view2">View 2</option>
            </select>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <div class="CompanySetting-dropdown-data">
              <select name="Saved views">
                <option
                  value=""
                  selected
                >
                  Company User
                </option>
                <option value="view1">View 1</option>
                <option value="view2">View 2</option>
              </select>
              <select name="columns">
                <option
                  value=""
                  disabled
                  selected
                >
                  Board User
                </option>
                <option value="column1">Column 1</option>
                <option value="column2">Column 2</option>
              </select>
              <select name="RELATIONSHIP">
                <option
                  value=""
                  disabled
                  selected
                >
                  Signatories
                </option>
                <option value="relationship1">Relationship 1</option>
                <option value="relationship2">Relationship 2</option>
              </select>
              <select name="COST CENTER">
                <option
                  value=""
                  disabled
                  selected
                >
                  Comapanies cotacts
                </option>
                <option value="center1">Cost Center 1</option>
                <option value="center2">Cost Center 2</option>
              </select>
              <select name="HOLDING(1)">
                <option
                  value=""
                  disabled
                  selected
                >
                  Law
                </option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
              </select>
            </div>
          </div>
          <hr className="hrtag" />
        </div>
        {/* Stack Holder */}
        <div>
          <div className="CompanySetting-userdata">
            <p>Name</p>
            <p>Emaiil</p>
            <p>Permissions</p>
          </div>
        </div>
        <hr className="hrtag" />
        <div>
          <div className="CompanySetting-details">
            <div className="CompanySetting-details-profile">
              <input
                type="checkbox"
                name=""
                id=""
              />
              <img
                src={image1}
                alt="image"
              />
              <p>name</p>
            </div>
            <p>georgia.young@example.com</p>
            <span>Full Access</span>
          </div>
          <div className="CompanySetting-details">
            <div className="CompanySetting-details-profile">
              <input
                type="checkbox"
                name=""
                id=""
              />
              <img
                src={image2}
                alt="image"
              />
              <p>name</p>
            </div>
            <p>georgia.young@example.com</p>

            <span>Full Access</span>
          </div>
          <div className="CompanySetting-details">
            <div className="CompanySetting-details-profile">
              <input
                type="checkbox"
                name=""
                id=""
              />
              <img
                src={image3}
                alt="image"
              />
              <p>name</p>
            </div>
            <p>georgia.young@example.com</p>

            <span>Full Access</span>
          </div>
          <div className="CompanySetting-details">
            <div className="CompanySetting-details-profile">
              <input
                type="checkbox"
                name=""
                id=""
              />
              <img
                src={image4}
                alt="image"
              />
              <p>name</p>
            </div>
            <p>georgia.young@example.com</p>

            <span>Full Access</span>
          </div>
          <div className="CompanySetting-details">
            <div className="CompanySetting-details-profile">
              <input
                type="checkbox"
                name=""
                id=""
              />
              <img
                src={image5}
                alt="image"
              />
              <p>name</p>
            </div>
            <p>georgia.young@example.com</p>

            <span>Full Access</span>
          </div>
          <div className="CompanySetting-details">
            <div className="CompanySetting-details-profile">
              <input
                type="checkbox"
                name=""
                id=""
              />
              <img
                src={image6}
                alt="image"
              />
              <p>name</p>
            </div>
            <p>georgia.young@example.com</p>

            <span>Full Access</span>
          </div>
          <div className="CompanySetting-details">
            <div className="CompanySetting-details-profile">
              <input
                type="checkbox"
                name=""
                id=""
              />
              <img
                src={image7}
                alt="image"
              />
              <p>name</p>
            </div>
            <p>georgia.young@example.com</p>

            <span>Full Access</span>
          </div>
          <div className="CompanySetting-details">
            <div className="CompanySetting-details-profile">
              <input
                type="checkbox"
                name=""
                id=""
              />
              <img
                src={image8}
                alt="image"
              />
              <p>name</p>
            </div>
            <p>georgia.young@example.com</p>
            <span>Full Access</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanySetting;
