import React, { useState } from 'react'
import './GeneralInvestments.scss'
import profileIcon from "../../assets/Image/assetDiscovery/profile.png"
import prevnav from "../../assets/dealsroom/svg/prevnav.svg";
import fwdnav from "../../assets/dealsroom/svg/fwdnav.svg";

function GeneralInvestments() {

    const [navActive, setNavActive] = useState(1);

    return (
        <div className='general_investments'>
            <div className='upper'>
                <h2>Investments</h2>
                <div>
                    <div className='general_investment_nav'>
                        <div className=" deals-room-nav asset-nav ass-nav">
                            <span
                                className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
                                onClick={() => {
                                    setNavActive(1);
                                }}
                            >
                                All Investments            </span>
                            <span
                                className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
                                onClick={() => {
                                    setNavActive(2);
                                }}
                            >
                                Commercial
                            </span>
                            <span
                                className={navActive === 3 && "deals-room-nav-active ut-nav-active"}
                                onClick={() => {
                                    setNavActive(3);
                                }}
                            >
                                Multifamily
                            </span>



                        </div> </div>

                    <div className="search_bar">
                        <input
                            type="text"
                            placeholder="Search"
                        />
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M20 18.586L18.586 20L12.929 14.343L14.343 12.929L20 18.586ZM7 12C4.243 12 2 9.757 2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12ZM7 0C3.134 0 0 3.134 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0Z"
                                fill="white"
                                fill-opacity="0.01"
                            />
                            <mask
                                id="mask0_9862_58034"
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="20"
                                height="20"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M20 18.586L18.586 20L12.929 14.343L14.343 12.929L20 18.586ZM7 12C4.243 12 2 9.757 2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12ZM7 0C3.134 0 0 3.134 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0Z"
                                    fill="white"
                                />
                            </mask>
                            <g mask="url(#mask0_9862_58034)">
                                <rect
                                    opacity="0.5"
                                    x="-1"
                                    y="-1"
                                    width="22"
                                    height="22"
                                    fill="#9EA0A5"
                                />
                            </g>
                        </svg>
                    </div>
                </div>
            </div>

            <div className='lower'>
                <Table />
            </div>
        </div>
    )
}

export default GeneralInvestments


const Table = () => {
    return (
        <div>
        <div className="documents_box-B table">
            <div className="uploaded_docs">
                <div className="header">
                    <div className="left">
                        <p>Name</p>
                        <p>date added</p>
                        <p>type</p>
                        <p>Potential Growth</p>
                    </div>

                </div>
                <Row2 />
                <Row2 />
                <Row2 />
                <Row2 />

            </div>
        </div>

        <div className='table_lower'>
        <div className="deals-room-doc-page-nav">
          <div className="page-nav-cont">
            <img
              src={prevnav}
              alt=""
            />
            <span>2 of 10</span>
            <img
              src={fwdnav}
              alt=""
            />
          </div>
        </div>
        <p>Load More Items</p>
        </div>
         </div>
    )
}


const Row2 = () => {
    return (
        <div className="table_row table_row2">
            <div className="left">
                <div className="item first">
                    <img
                        src={profileIcon}
                        alt=""
                    />

                    <div className="text_wrapper">
                        <p>Aurora</p>
                    </div>
                </div>
           
                <div className="item">
                    <div className="text_wrapper">
                        <p>08 Nov 2018</p>
                    </div>
                </div>
                <div className="item">
                    <div className="text_wrapper">
                        <p>Commercial</p>
                    </div>
                </div>
                <div className="item">
                    <div className="text_wrapper">
                        <p>$500,900.00</p>
                    </div>
                </div>
            </div>
         
        </div>
    );
};