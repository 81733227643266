





export const Button = ({icon, value, setNavActive, navActive}) => {
    return (
      <div
        className="header-button"
        onClick={() => (value === "Back" ? setNavActive(navActive === 1 ? 1 : navActive - 1) : null)}
      >
        <img
          src={icon}
          alt="icon"
        />
        <p>{value}</p>
      </div>
    );
  };
  
  export const Yield = ({name, value}) => {
    return (
      <div className="yield">
        <p className="first-child">{name}</p>
        <p className="last-child">{value}</p>
      </div>
    );
  };
  
  export const Yieldd = ({name, value}) => {
    return (
      <div className="yieldd">
        <p className="firstt-child">{name}</p>
        <p className="lastt-child">{value}</p>
      </div>
    );
  };
  
  export const Yielddd = ({name, value, valueSecond}) => {
    return (
      <div className="yielddd">
        <p className="firsttt-child">{name}</p>
        <p className="lasttt-child">{value}</p>
        <p className="lasttt-child-child">{valueSecond}</p>
      </div>
    );
  };
  
  export const List = ({value}) => {
    return <li className="list">{value}</li>;
  };