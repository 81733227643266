import React, {useState, useRef, useEffect} from "react";
import {Dialog, DialogTitle, DialogContent, Button} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as pdfjsLib from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import CircularProgress from "@mui/material/CircularProgress";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PDF_Viewer = ({open, onClose, pdfUrl}) => {
  const [pdfDocument, setPdfDocument] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const canvasRefs = useRef([]);

  const A4_WIDTH = 595;
  const A4_HEIGHT = 842;
  const zoomScale = 1.5;

  const renderPdfPage = async (pdf, pageNum) => {
    const page = await pdf.getPage(pageNum);
    const canvas = canvasRefs.current[pageNum - 1];
    if (!canvas) return;
    const context = canvas.getContext("2d");
    const viewport = page.getViewport({scale: zoomScale});
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };

    await page.render(renderContext);
  };

  const renderAllPages = async (pdf) => {
    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
      await renderPdfPage(pdf, pageNum);
    }
  };

  useEffect(() => {
    if (pdfUrl) {
      loadPdf(pdfUrl);
    }
  }, [pdfUrl]);

  const loadPdf = async (pdfUrl) => {
    try {
      setLoading(true);
      const loadingTask = pdfjsLib.getDocument(pdfUrl);
      const pdf = await loadingTask.promise;
      setNumPages(pdf.numPages);
      setPdfDocument(pdf);
      await renderAllPages(pdf);
    } catch (error) {

    } finally {
      setLoading(false);
    }
  };



  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      PaperProps={{
        style: {
          borderRadius: "0",
          boxShadow: "none",
          padding: "0",
          height: "90vh",
        },
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
          backgroundColor: "#f5f5f5",
          borderBottom: "1px solid #ddd",
        }}
      >
        <span style={{fontWeight: "bold", color: "#333"}}>PDF Preview</span>
        <Button
          onClick={onClose}
          style={{color: "#333"}}
        >
          <CloseIcon />
        </Button>
      </DialogTitle>

      <DialogContent
        style={{
          position: "relative",
          margin: "0",
          padding: "0",
          height: "calc(100% - 64px)",
          overflowY: "auto",
        }}
      >
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        )}

        {!loading &&
          Array.from({length: numPages}, (_, index) => (
            <canvas
              key={index}
              ref={(el) => (canvasRefs.current[index] = el)}
              style={{
                display: "block",
                margin: "0 auto 20px auto",
                width: "100%",
                maxWidth: `${A4_WIDTH * zoomScale}px`,
                height: `${A4_HEIGHT * zoomScale}px`,
                border: "1px solid #000",
              }}
            />
          ))}
      </DialogContent>
    </Dialog>
  );
};

export default PDF_Viewer;
