import React, {useState} from "react";
import "./TotalCompensation.scss";
import Convertibles from "../../../assets/Manage-syndicate/image/Convertibles.png";
import Checkmark from "../../../assets/Manage-syndicate/Svg/Checkmark.svg";
import TotalCompensationimage from "../../../assets/Manage-syndicate/image/Total Compensation.png";

const TotalCompensation = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is  Lilypads Total Compensation?",
      answer:
        "Compare that have raised at last $10M in funding OR have least 500 stakeholders on their cap table are required to disclose their stock based compensation expenses on their income statement",
    },
    {
      question: "How are Lilypads Total Compensation benchmark created?",
      answer:
        "Compare that have raised at last $10M in funding OR have least 500 stakeholders on their cap table are required to disclose their stock based compensation expenses on their income statement",
    },
    {
      question: "What can ",
      answer:
        "Compare that have raised at last $10M in funding OR have least 500 stakeholders on their cap table are required to disclose their stock based compensation expenses on their income statement",
    },
    {
      question: "What should I do I’m being audited?",
      answer:
        "Compare that have raised at last $10M in funding OR have least 500 stakeholders on their cap table are required to disclose their stock based compensation expenses on their income statement",
    },
    {
      question: "What can  Lilypads  Total Compensation help me do ?",
      answer:
        "Compare that have raised at last $10M in funding OR have least 500 stakeholders on their cap table are required to disclose their stock based compensation expenses on their income statement",
    },
    {
      question: "How is  Lilypads  Total Compensation  different from other solution?",
      answer:
        "Compare that have raised at last $10M in funding OR have least 500 stakeholders on their cap table are required to disclose their stock based compensation expenses on their income statement",
    },
  ];
  return (
    <div>
      <div>
        <div className="TotalCompensation-container-image">
          <div className="TotalCompensation-container-image-data">
            <div className="TotalCompensation-Data">
              <img
                src={TotalCompensationimage}
                alt="image"
              />
              <h1>Pay every employee correctly with transparent employee</h1>
              <hr className="linetag" />
              <div className="TotalCompensation-flex">
                <img
                  src={Checkmark}
                  alt="image"
                />
                <p>Relevant salary and equity data</p>
              </div>
              <div className="TotalCompensation-flex">
                <img
                  src={Checkmark}
                  alt="image"
                />
                <p>Personalized data insightas</p>
              </div>
              <div className="TotalCompensation-flex">
                <img
                  src={Checkmark}
                  alt="image"
                />
                <p>Employee communicatiion tools</p>
              </div>
              <button>Schedule a call</button>
            </div>
            <div className="TotalCompensation-wrapper">
              <h1>Frequently Asked Questions</h1>
              {faqData.map((faq, index) => (
                <div
                  className="TotalCompensation"
                  key={index}
                >
                  <button
                    className={`TotalCompensation-accordion ${activeIndex === index ? "active" : ""}`}
                    onClick={() => toggleAccordion(index)}
                  >
                    {faq.question}
                    <i className="fa-solid fa-chevron-down"></i>
                  </button>
                  <div className={`TotalCompensation-pannel ${activeIndex === index ? "active" : ""}`}>
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalCompensation;
