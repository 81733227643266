import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import stonksIcon from "../../../../assets/portfolio/svgs/stonks.svg";
import lossIcon from "../../../../assets/portfolio/svgs/loss.svg";
import {ReactComponent as InfoIcon} from "../../../../assets/portfolio/svgs/info-icon.svg";
import {useSelector} from "react-redux";
import ApexCharts from "react-apexcharts";
import graphRentalHigh from "../../../../assets/portfolio/images/graph-group-1.png";
import {fetchFinancialData} from "../../../../store/reducers/financialSlice";
import {useDispatch} from "react-redux";

const FinancialKPI = ({selectedItem}) => {
  let financedData = useSelector((state) => state.financialSlice);
  const dispatch = useDispatch();
  const {selectedProperty} = useSelector((state) => state.portfolio);

  useEffect(() => {
    if (selectedProperty?.Property_Code) {
      dispatch(fetchFinancialData(selectedProperty?.Property_Code));
    }
  }, [dispatch, selectedProperty?.Property_Code, selectedProperty?.Property_Code]);

  // RENTAL INCOME ----------
  const transformTopPaymentsForChart = (data) => {
    if (!data || !data.financialData?.RENTALINCOME?.topPayments) return [];
    return data.financialData.RENTALINCOME.topPayments.map((amount, index) => ({
      x: index,
      y: parseFloat(amount.replace(/[^0-9.-]+/g, "")),
    }));
  };

  const chartOptions = {
    chart: {
      id: "financial-chart",
      type: "area",
      height: "100%",
      background: "transparent",
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: false,
        tools: {
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        offsetX: 0,
        offsetY: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      type: "datetime",
      labels: {
        show: false,
      },
      axisBorder: {
        show: true,
        color: "#DDDDDD",
      },
      axisTicks: {
        show: true,
        color: "#DDDDDD",
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: true,
        color: "#DDDDDD",
      },
      axisTicks: {
        show: true,
        color: "#DDDDDD",
      },
      tooltip: {
        enabled: false,
      },
    },
    tooltip: {
      x: {
        format: "yyyy-MM-dd",
      },
      y: {
        formatter: (value) => `$${value}`,
      },
    },
    grid: {
      show: false,
    },
    colors: ["#28a745", "#6E47BD"],
  };

  const chartSeriesfinance = [
    {
      name: "Rental Income",
      data: transformTopPaymentsForChart(financedData),
    },
  ];

  // PAYROLL ----------

  const transformPayrollForChart = (data) => {
    if (!data || !data.financialData?.PAYROLL?.records) return [];
    return data.financialData.PAYROLL.records.map((record) => ({
      x: new Date(record.date + "-01").toISOString(),
      y: parseFloat(record.amount.replace(/[^0-9.-]+/g, "")),
    }));
  };

  const payrollChartOptions = {
    ...chartOptions,
  };

  const chartSeriesPayroll = [
    {
      name: "Payroll Amounts",
      data: transformPayrollForChart(financedData),
    },
  ];

  // REPAIR_MAINTENANCE ----------

  const transformRepairDataForChart = (data) => {
  
    if (!data || !data.financialData?.REPAIR_MAINTENANCE?.topPayments) return [];
  
    const startDate = new Date("2024-09-01"); 
  
    return data.financialData.REPAIR_MAINTENANCE.topPayments.map((payment, index) => ({
      x: new Date(startDate.getFullYear(), startDate.getMonth() + index, 1).toISOString(),
      y: parseFloat(payment.replace(/[^0-9.-]+/g, "")),
    }));
  };
  

  const repairChartOptions = {
    ...chartOptions,
  };

  const chartSeriesRepair = [
    {
      name: "Repair & Maintenance Charges",
      data: transformRepairDataForChart(financedData),
    },
  ];

  // UTILITIES ----------

  const transformUtilitiesForChart = (data) => {
  
    if (!data || !data.financialData?.UTILITIES?.topPayments) return [];
  
    const startDate = new Date("2024-09-01"); 
  
    return data.financialData.UTILITIES.topPayments.map((payment, index) => ({
      x: new Date(startDate.getFullYear(), startDate.getMonth() + index, 1).toISOString(),
      y: parseFloat(payment.replace(/[^0-9.-]+/g, "")),
    }));
  };

  const UTILITIESOption = {
    ...chartOptions,
  };

  const UTILITIESData = [
    {
      name: "Utility Charges",
      data: transformUtilitiesForChart(financedData),
    },
  ];

  // ADMINISTRATIVE

  const transformAdministrativeForChart = (data) => {
  
    if (!data || !data.financialData?.ADMINISTRATIVE?.topPayments) return [];
  
    return data.financialData.ADMINISTRATIVE.topPayments.map((payment, index) => ({
      // Using index as the x value (you can adjust this as needed)
      x: `Payment ${index + 1}`, // This could be a label like "Payment 1", "Payment 2", etc.
      y: parseFloat(payment.replace(/[^0-9.-]+/g, "")),
    }));
  };
  
  const administrativeChartOptions = {
    ...chartOptions,
  };
  
  const chartSeriesAdministrative = [
    {
      name: "Administrative Charges",
      data: transformAdministrativeForChart(financedData), // Apply the transformation function here
    },
  ];
  

  return (
    <div className="financial-kpi-cards">
      {/* <h1>FinancialKPI</h1> */}
      <div className="financial-grid-1 financial-kpi-card">
        <h2>RENTAL INCOME</h2>
        {/* <p>May ‘19 - Jul ‘19 avg/mo</p> */}
        <p>
          {financedData?.financialData?.RENTALINCOME?.dateRange?.maxDate} - {financedData?.financialData?.RENTALINCOME?.dateRange?.minDate || "0"}/mo
        </p>
        <h1>
          {financedData?.financialData?.RENTALINCOME?.totalPayments
            ? financedData.financialData.RENTALINCOME.totalPayments.length > 15
              ? financedData.financialData.RENTALINCOME.totalPayments.slice(0, 15) + "..."
              : financedData.financialData.RENTALINCOME.totalPayments
            : "0"}
        </h1>
        {/* <div className="benchmark">
          <img
            src={stonksIcon}
            alt="info"
            className="info-icon"
          />
          <p>
            <span style={{fontWeight: "500"}}>{selectedItem?.currency}43</span> Benchmark
          </p>
        </div> */}
        <div style={{textAlign: "center", paddingTop: "10px"}}>
          <h1 style={{fontSize: "14px", lineHeight: "14px"}}>
            {financedData?.financialData?.RENTALINCOME?.averagePaymentPerMonth.length > 15
              ? financedData?.financialData?.RENTALINCOME?.averagePaymentPerMonth.slice(0, 15) + "..."
              : financedData?.financialData?.RENTALINCOME?.averagePaymentPerMonth
              ? `${financedData?.financialData?.RENTALINCOME?.averagePaymentPerMonth || "0"}/mo`
              : "0/mo"}{" "}
            {/* <span>
              <InfoIcon style={{marginBottom: "-4px"}} />
            </span> */}
          </h1>

          {/* <h1 style={{fontSize: "14px"}}>Loss vs. Benchmark</h1> */}
        </div>

        <ApexCharts
          options={chartOptions}
          series={chartSeriesfinance}
          type="area"
          height={250}
        />

        <div className="kpi-owner-details-wrapper">
          <div className="kpi-owner-details">
            <div className="owner-status-wrapper">
              <p style={{backgroundColor: "#7D55C7"}}>&nbsp;</p>
              <h4>You</h4>
            </div>
            <div className="owner-status-wrapper">
              {/* <p style={{backgroundColor: "#34AA44"}}>&nbsp;</p> */}
              {/* <h4>Benchmark</h4> */}
            </div>
          </div>

          <Link to="/portfolio">
            {/* <BulbIcon
              className={`owner-info-icon ${isBulbActive ? "active" : ""}`}
              onClick={() => setIsBulbActive(!isBulbActive)}
            /> */}
            {/* <img
              src={bulbIcon}
              alt="bulb"
              className={`owner-info-icon ${isBulbActive ? "active" : ""}`}
              onClick={() => setIsBulbActive(!isBulbActive)}
            /> */}
          </Link>
        </div>
        <InfoIcon className="finance-card-info" />
      </div>
      <div className="financial-grid-2 financial-kpi-card">
        <h2>PAYROLL RENT</h2>
        <p>
          {financedData?.financialData?.RENTALINCOME?.dateRange?.maxDate} - {financedData?.financialData?.RENTALINCOME?.dateRange?.minDate}/mo
        </p>
        <h1>
          {financedData?.financialData?.PAYROLL?.totalRent
            ? financedData.financialData.PAYROLL.totalRent.length > 15
              ? financedData.financialData.PAYROLL.totalRent.slice(0, 15) + "..."
              : financedData.financialData.PAYROLL.totalRent
            : ""}
        </h1>

        <div className="benchmark">
          {/* <img
            src={lossIcon}
            alt="info"
            className="info-icon"
          />
          <p style={{color: "#E6492D"}}>
            <span style={{fontWeight: "500"}}>{selectedItem?.currency}113</span> Benchmark
          </p> */}
        </div>
        <div style={{textAlign: "center", paddingTop: "10px"}}>
          <h1 style={{fontSize: "14px", lineHeight: "14px"}}>
            {financedData?.financialData?.PAYROLL?.averageRent
              ? financedData?.financialData?.PAYROLL?.averageRent.length > 15
                ? financedData?.financialData?.PAYROLL?.averageRent.slice(0, 15) + "..."
                : financedData?.financialData?.PAYROLL?.averageRent
              : ""}
            /mo{" "}
            {/* <span>
              <InfoIcon style={{marginBottom: "-4px"}} />
            </span> */}
          </h1>
          {/* <h1 style={{fontSize: "14px"}}>Loss vs. Benchmark</h1> */}
        </div>
        <ApexCharts
          options={payrollChartOptions}
          series={chartSeriesPayroll}
          type="area"
          height={250}
        />
        <div className="kpi-owner-details-wrapper">
          <div className="kpi-owner-details">
            <div className="owner-status-wrapper">
              <p style={{backgroundColor: "#7D55C7"}}>&nbsp;</p>
              <h4>You</h4>
            </div>
            <div className="owner-status-wrapper">{/* <p style={{backgroundColor: "#34AA44"}}>&nbsp;</p> */}</div>
          </div>

          <Link to="/portfolio">
            {/* <BulbIcon
              className={`owner-info-icon ${isBulbActive ? "active" : ""}`}
              onClick={() => setIsBulbActive(!isBulbActive)}
            /> */}
            {/* <img
              src={bulbIcon}
              alt="bulb"
              className={`owner-info-icon ${isBulbActive ? "active" : ""}`}
              onClick={() => setIsBulbActive(!isBulbActive)}
            /> */}
          </Link>
        </div>
        <InfoIcon className="finance-card-info" />
      </div>

      <div className="financial-grid-3 financial-kpi-card">
        <h2>REPAIRS & MAINTENANCE</h2>
        <p>
          {financedData?.financialData?.REPAIR_MAINTENANCE?.dateRange?.maxDate} - {financedData?.financialData?.REPAIR_MAINTENANCE?.dateRange?.minDate}/mo
        </p>
        <h1>
          {financedData?.financialData?.REPAIR_MAINTENANCE?.totalPayments
            ? financedData?.financialData?.REPAIR_MAINTENANCE?.totalPayments.length > 15
              ? financedData?.financialData?.REPAIR_MAINTENANCE?.totalPayments.slice(0, 15) + "..."
              : financedData?.financialData?.REPAIR_MAINTENANCE?.totalPayments
            : ""}
        </h1>
        <div className="benchmark">
          {/* <img
            src={lossIcon}
            alt="info"
            className="info-icon"
          />
          <p style={{color: "#E6492D"}}>
            <span style={{fontWeight: "500"}}>{selectedItem?.currency}113</span> Benchmark
          </p> */}
        </div>
        <div style={{textAlign: "center", paddingTop: "10px"}}>
          <h1 style={{fontSize: "14px", lineHeight: "14px"}}>
            {financedData?.financialData?.REPAIR_MAINTENANCE?.averagePaymentPerMonth
              ? financedData?.financialData?.REPAIR_MAINTENANCE?.averagePaymentPerMonth.length > 15
                ? financedData?.financialData?.REPAIR_MAINTENANCE?.averagePaymentPerMonth.slice(0, 15) + "..."
                : financedData?.financialData?.REPAIR_MAINTENANCE?.averagePaymentPerMonth
              : ""}
            /mo{" "}
            {/* <span>
              <InfoIcon style={{marginBottom: "-4px"}} />
            </span> */}
          </h1>
          {/* <h1 style={{fontSize: "14px"}}>Loss vs. Benchmark</h1> */}
        </div>

        <ApexCharts
          options={repairChartOptions}
          series={chartSeriesRepair}
          type="area"
          height={250}
        />
        <div className="kpi-owner-details-wrapper">
          <div className="kpi-owner-details">
            <div className="owner-status-wrapper">
              <p style={{backgroundColor: "#7D55C7"}}>&nbsp;</p>
              <h4>You</h4>
            </div>
            <div className="owner-status-wrapper">
              {/* <p style={{backgroundColor: "#34AA44"}}>&nbsp;</p>
              <h4>Benchmark</h4> */}
            </div>
          </div>

          <Link to="/portfolio">
            {/* <BulbIcon
              className={`owner-info-icon ${isBulbActive ? "active" : ""}`}
              onClick={() => setIsBulbActive(!isBulbActive)}
            /> */}
            {/* <img
              src={bulbIcon}
              alt="bulb"
              className={`owner-info-icon ${isBulbActive ? "active" : ""}`}
              onClick={() => setIsBulbActive(!isBulbActive)}
            /> */}
          </Link>
        </div>
        <InfoIcon className="finance-card-info" />
      </div>

      <div className="financial-grid-4 financial-kpi-card">
        <h2>UTILITIES</h2>
        <p>
          {financedData?.financialData?.UTILITIES?.dateRange?.maxDate} - {financedData?.financialData?.UTILITIES?.dateRange?.minDate}/mo
        </p>
        <h1>
          {financedData?.financialData?.UTILITIES?.totalPayments
            ? financedData?.financialData?.UTILITIES?.totalPayments.length > 15
              ? financedData?.financialData?.UTILITIES?.totalPayments.slice(0, 15) + "..."
              : financedData?.financialData?.UTILITIES?.totalPayments
            : ""}
        </h1>
        <div className="benchmark">
          {/* <img
            src={lossIcon}
            alt="info"
            className="info-icon"
          />
          <p style={{color: "#E6492D"}}>
            <span style={{fontWeight: "500"}}>{selectedItem?.currency}113</span> Benchmark
          </p> */}
        </div>
        <div style={{textAlign: "center", paddingTop: "10px"}}>
          <h1 style={{fontSize: "14px", lineHeight: "14px"}}>
            {financedData?.financialData?.UTILITIES?.averagePaymentPerMonth
              ? financedData?.financialData?.UTILITIES?.averagePaymentPerMonth.length > 15
                ? financedData?.financialData?.UTILITIES?.averagePaymentPerMonth.slice(0, 15) + "..."
                : financedData?.financialData?.UTILITIES?.averagePaymentPerMonth
              : ""}
            /mo{" "}
            {/* <span>
              <InfoIcon style={{marginBottom: "-4px"}} />
            </span> */}
          </h1>
          {/* <h1 style={{fontSize: "14px"}}>Loss vs. Benchmark</h1> */}
        </div>

        <ApexCharts
          options={UTILITIESOption}
          series={UTILITIESData}
          type="area"
          height={250}
        />
        <div className="kpi-owner-details-wrapper">
          <div className="kpi-owner-details">
            <div className="owner-status-wrapper">
              <p style={{backgroundColor: "#7D55C7"}}>&nbsp;</p>
              <h4>You</h4>
            </div>
            <div className="owner-status-wrapper">
              {/* <p style={{backgroundColor: "#34AA44"}}>&nbsp;</p>
              <h4>Benchmark</h4> */}
            </div>
          </div>

          <Link to="/portfolio">
            {/* <BulbIcon
              className={`owner-info-icon ${isBulbActive ? "active" : ""}`}
              onClick={() => setIsBulbActive(!isBulbActive)}
            /> */}
            {/* <img
              src={bulbIcon}
              alt="bulb"
              className={`owner-info-icon ${isBulbActive ? "active" : ""}`}
              onClick={() => setIsBulbActive(!isBulbActive)}
            /> */}
          </Link>
        </div>
        <InfoIcon className="finance-card-info" />
      </div>

      <div className="financial-grid-5 financial-kpi-card">
        <h2>ADMINISTRATIVE</h2>
        <p>
          {financedData?.financialData?.ADMINISTRATIVE?.dateRange?.maxDate} - {financedData?.financialData?.ADMINISTRATIVE?.dateRange?.minDate}/mo
        </p>
        <h1>
          {financedData?.financialData?.ADMINISTRATIVE?.totalPayments
            ? financedData?.financialData?.ADMINISTRATIVE?.totalPayments.length > 15
              ? financedData?.financialData?.ADMINISTRATIVE?.totalPayments.slice(0, 15) + "..."
              : financedData?.financialData?.ADMINISTRATIVE?.totalPayments
            : ""}

        </h1>
        <div className="benchmark">
          {/* <img
            src={lossIcon}
            alt="info"
            className="info-icon"
          />
          <p style={{color: "#E6492D"}}>
            <span style={{fontWeight: "500"}}>{selectedItem?.currency}113</span> Benchmark
          </p> */}
        </div>
        <div style={{textAlign: "center", paddingTop: "10px"}}>
          <h1 style={{fontSize: "14px", lineHeight: "14px"}}>
            {financedData?.financialData?.ADMINISTRATIVE?.averagePaymentPerMonth
              ? financedData?.financialData?.ADMINISTRATIVE?.averagePaymentPerMonth.length > 15
                ? financedData?.financialData?.ADMINISTRATIVE?.averagePaymentPerMonth.slice(0, 15) + "..."
                : financedData?.financialData?.ADMINISTRATIVE?.averagePaymentPerMonth
              : ""}
            /mo{" "}
            {/* <span>
              <InfoIcon style={{marginBottom: "-4px"}} />
            </span> */}
          </h1>
          {/* <h1 style={{fontSize: "14px"}}>Loss vs. Benchmark</h1> */}
        </div>

        <ApexCharts
          options={administrativeChartOptions}
          series={chartSeriesAdministrative}
          type="area"
          height={250}
        />
        <div className="kpi-owner-details-wrapper">
          <div className="kpi-owner-details">
            <div className="owner-status-wrapper">
              <p style={{backgroundColor: "#7D55C7"}}>&nbsp;</p>
              <h4>You</h4>
            </div>
            <div className="owner-status-wrapper">
              {/* <p style={{backgroundColor: "#34AA44"}}>&nbsp;</p>
              <h4>Benchmark</h4> */}
            </div>
          </div>

          <Link to="/portfolio">
            {/* <BulbIcon
              className={`owner-info-icon ${isBulbActive ? "active" : ""}`}
              onClick={() => setIsBulbActive(!isBulbActive)}
            /> */}
            {/* <img
              src={bulbIcon}
              alt="bulb"
              className={`owner-info-icon ${isBulbActive ? "active" : ""}`}
              onClick={() => setIsBulbActive(!isBulbActive)}
            /> */}
          </Link>
        </div>
        <InfoIcon className="finance-card-info" />
      </div>
    </div>
  );
};

// const EditPopup = ({isOpen, onClose, title, data, onSave}) => {
//   const [editedData, setEditedData] = useState({...data});
//   const [newKey, setNewKey] = useState("");
//   const [newValue, setNewValue] = useState("");

//   const handleChange = (key, value) => {
//     setEditedData((prevData) => ({...prevData, [key]: value}));
//   };

//   const handleSave = () => {
//     onSave(editedData);
//   };

//   const handleAddNewEntry = () => {
//     if (newKey && newValue) {
//       setEditedData((prevData) => ({...prevData, [newKey]: newValue}));
//       setNewKey("");
//       setNewValue("");
//     }
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="edit_popup_KPI">
//       <div className="popup_content_KPI">
//         <h2>{title}</h2>
//         {Object.keys(editedData).map((key) => (
//           <div key={key}>
//             <label>{key}</label>
//             <input
//               type="text"
//               value={editedData[key]}
//               onChange={(e) => handleChange(key, e.target.value)}
//             />
//           </div>
//         ))}
//         <div className="new-entry">
//           <label>New Key</label>
//           <input
//             type="text"
//             value={newKey}
//             onChange={(e) => setNewKey(e.target.value)}
//           />
//           <label>New Value</label>
//           <input
//             type="text"
//             value={newValue}
//             onChange={(e) => setNewValue(e.target.value)}
//           />
//           <button
//             className="add-button"
//             onClick={handleAddNewEntry}
//           >
//             Add
//           </button>
//         </div>
//         <div className="button-container">
//           <button
//             className="save-button"
//             onClick={handleSave}
//           >
//             Save
//           </button>
//           <button
//             className="close-button"
//             onClick={onClose}
//           >
//             Close
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

export default FinancialKPI;
