import React, {useState} from "react";
import "./FormFields.scss";
import CalenderIcon from "../../../assets/svg/calender.svg";
import passwordEye from "../../../assets/svg/passwordeye.svg";
import passwordEyeClose from "../../../assets/svg/passwordeyeClose.svg";
import {Checkmark, CheckmarkTICK} from "../../../assets/svg/checkmark";
import uploadIcon from "../../../assets/svg/uploadIcon.svg";
import Switch, {SwitchProps} from "@mui/material/Switch";
import {styled} from "@mui/material/styles";
import {DateRangePicker} from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

function InputBox({className, label, value, name, onChange = () => {}, noeye, labelBtm, type, conCLass, placeholder, width, svgPath, padding, svgHeight, svgWidth, RangeInput}) {
  const [inputType, setInputType] = useState(type);
  const [showCalendar, setShowCalendar] = useState(false);
  const handleCalendarToggle = () => {
    setShowCalendar(!showCalendar);
  };

  const handleCloseCalendar = () => {
    setShowCalendar(false);
  };

  return (
    <div
      className={"inputBoxMain " + conCLass}
      style={{padding: `${padding ? padding : ""}`, width: width}}
    >
      <p>{label}</p>
      {type === "file" ? (
        <div className="upload-btn-wrapper">
          <button className="btn">
            <img
              src={uploadIcon}
              alt=""
            />
            Drag and drop files or click
          </button>
          <input
            type="file"
            name="myfile"
          />
        </div>
      ) : type === "date-range" ? (
        <div className="date-range-picker">
          {showCalendar && (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              <DateRangePicker
                // ranges={range}
                // onChange={handleSelect}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                direction="horizontal"
                rangeColors="#7d55c7b5"
                color="#7d55c7b5"
              />
              <button onClick={handleCloseCalendar}>Close</button>
            </div>
          )}
          <img
            src={CalenderIcon}
            alt="calendar"
            className="calendar-icon"
            onClick={handleCalendarToggle}
            style={{cursor: "pointer", height: `${svgHeight ? svgHeight : ""}`}}
          />
          {/* {range && (
            <p>
              {formatDate(range[0].startDate)} - {formatDate(range[0].endDate)}
            </p>
          )} */}
        </div>
      ) : (
        <div
          style={{display: "flex"}}
          className="basic_profile_input"
        >
          <input
            type={inputType}
            name={name || label}
            value={value}
            placeholder={placeholder || ""}
            onChange={onChange ? onChange : (e) => onChange(e.target.value)}
          ></input>
          {svgPath && (
            <img
              src={svgPath}
              alt={name + "-svg"}
              className="svg-icon"
              style={{height: `${svgHeight ? svgHeight : ""}`, width: `${svgWidth ? svgWidth : ""}`, marginLeft: "-35px"}}
            />
          )}
        </div>
      )}
      {type === "password" ? (
        <div className="password_eye">
          {!noeye && (
            <div
              onClick={() => setInputType(inputType === "text" ? "password" : "text")}
              style={{width: "20px !important"}}
            >
              {inputType === "password" ? (
                <img
                  width="20px"
                  className="sigin-Pointer"
                  src={passwordEye}
                  alt="passwordEye"
                />
              ) : (
                <img
                  width="20px"
                  src={passwordEyeClose}
                  alt="passwordEyeClose"
                />
              )}
            </div>
          )}
        </div>
      ) : (
        ""
      )}
      {type === "date" ? (
        <div className="calenderIcon">
          <img
            src={CalenderIcon}
            alt=""
          />
        </div>
      ) : (
        ""
      )}
      <p className="labelBtm">{labelBtm}</p>
    </div>
  );
}

function InfoBox({icon, message}) {
  return (
    <div className="infoBox">
      <div className="container inputFlexLine">
        <div className="icon">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.16797 14.166H10.8346V9.16602H9.16797V14.166V14.166ZM10.0013 1.66602C5.4013 1.66602 1.66797 5.39935 1.66797 9.99935C1.66797 14.5993 5.4013 18.3327 10.0013 18.3327C14.6013 18.3327 18.3346 14.5993 18.3346 9.99935C18.3346 5.39935 14.6013 1.66602 10.0013 1.66602V1.66602ZM10.0013 16.666C6.3263 16.666 3.33464 13.6743 3.33464 9.99935C3.33464 6.32435 6.3263 3.33268 10.0013 3.33268C13.6763 3.33268 16.668 6.32435 16.668 9.99935C16.668 13.6743 13.6763 16.666 10.0013 16.666V16.666ZM9.16797 7.49935H10.8346V5.83268H9.16797V7.49935V7.49935Z"
              fill="#6B6C6F"
            />
          </svg>
        </div>
        <div className="text">
          <p>{message}</p>
        </div>
        <div className="close">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.4142 9.00091L13.9497 5.46537L12.5355 4.05116L9 7.58669L5.46447 4.05116L4.05025 5.46537L7.58579 9.00091L4.05025 12.5364L5.46447 13.9507L9 10.4151L12.5355 13.9507L13.9497 12.5364L10.4142 9.00091Z"
              fill="#9EA0A5"
            />
            <mask
              id="mask0_10633_40807"
              maskUnits="userSpaceOnUse"
              x="4"
              y="4"
              width="10"
              height="10"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.4142 9.00091L13.9497 5.46537L12.5355 4.05116L9 7.58669L5.46447 4.05116L4.05025 5.46537L7.58579 9.00091L4.05025 12.5364L5.46447 13.9507L9 10.4151L12.5355 13.9507L13.9497 12.5364L10.4142 9.00091Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_10633_40807)">
              <rect
                width="18"
                height="18"
                fill="#9EA0A5"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
}

function SelectBox({icon, title, id, activeId, greenswitch}) {
  return (
    <div className={"selectBox " + (id === activeId ? "activeBox" : "")}>
      <div className="topIconWrapper">
        <div className="icon_wrapper">
          <img
            src={icon}
            alt=""
          />
          <p>{title}</p>
        </div>
        {greenswitch && (
          <div className="greenswitch">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.785 11.2C9.1754 11.2 7.4682 11.1554 6.0394 11.0922L8.897 8.23516L11.217 9.2207C11.4954 9.3383 11.8178 9.2761 12.0314 9.0625C12.4034 8.6905 12.281 8.06068 11.797 7.85508L10.0282 7.10312L12.253 4.87852C12.5658 4.56572 12.5658 4.05927 12.253 3.74727C11.941 3.43447 11.4346 3.43447 11.1218 3.74727L10.0626 4.80625L9.445 3.74883C9.1898 3.31123 8.589 3.23379 8.2298 3.59219C7.9818 3.84099 7.9314 4.22569 8.109 4.52969L8.9314 5.9375L4.9098 9.95937C4.845 8.52657 4.8002 6.82403 4.8002 6.21523C4.8002 3.36643 5.8402 1.6 11.0154 1.6C11.6938 1.6 13.229 1.66334 14.277 1.74414C14.3474 2.55454 14.4002 3.61037 14.4002 4.18477C14.4002 11.2 11.0474 11.2 9.785 11.2ZM15.6874 0.312891C14.9626 0.114491 11.8034 0 11.0154 0C6.2578 0 3.2002 1.45763 3.2002 6.21523C3.2002 6.82643 3.2698 9.82257 3.393 11.4762L0.2346 14.6344C-0.0782 14.9464 -0.0782 15.4536 0.2346 15.7656C0.5474 16.0784 1.0538 16.0784 1.3658 15.7656L4.5242 12.607C6.1778 12.7302 9.1738 12.8 9.785 12.8C14.5434 12.8 16.0002 8.94236 16.0002 4.18477C16.0002 3.39677 15.885 1.03769 15.6874 0.312891Z"
                fill="#7EC331"
              />
            </svg>
            <IOSSwitch
              defaultChecked
              size="small"
            />
          </div>
        )}
      </div>
      <div className="checkbox_container">{id === activeId ? <CheckmarkTICK /> : <Checkmark />}</div>
    </div>
  );
}

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({theme}) => ({
  width: 50,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#7d55c7",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export {InputBox, InfoBox, SelectBox, IOSSwitch};
