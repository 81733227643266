import React, {useState} from "react";
import DropDown from "../../components/reusableComponents/Dropdown/Dropdown";
import {InputWithIcon, InputWithAddFieldFunc, UploadFile, TextInputWithLabel, DropdownWithCheckbox} from "../portfolio/Inputs";

export const PropertyInfo = ({ onChange, propertyDetails }) => {
  return (
    <div className="property-info-container">
      <div className="info-row">
        <DropDown
          label="Lot Area"
          // data={filterOptions}
          selectedData={propertyDetails.lotArea}
          classDataName="dropdown"
          width="200px"
          margin="10px 0px"
          onChange={(value) => onChange('lotArea', value)} 
        />
        <TextInputWithLabel
          label={"LOT AREA"}
          placeholder={"Value"}
          optionText={"SF"}
          value={propertyDetails.lotArea}
          onChange={(e) => onChange('lotArea', e.target.value)} 
        />
        <TextInputWithLabel
          label={"ZONING"}
          placeholder={"Value"}
          value={propertyDetails.zoning}
          onChange={(e) => onChange('zoning', e.target.value)} 
        />
      </div>
      <div className="line">&nbsp;</div>
      <div className="info-row">
        <TextInputWithLabel
          label={"TOTAL UNITS"}
          placeholder={"Value"}
          value={propertyDetails.totalUnits}
          onChange={(e) => onChange('totalUnits', e.target.value)} 
        />
        <TextInputWithLabel
          label={"BUILDING AREA"}
          placeholder={"Value"}
          optionText={"SF"}
          value={propertyDetails.buildingArea}
          onChange={(e) => onChange('buildingArea', e.target.value)} 
        />
        <TextInputWithLabel
          label={"YEAR BUILT"}
          placeholder={"Value"}
          value={propertyDetails.yearBuilt}
          onChange={(e) => onChange('yearBuilt', e.target.value)}
        />
      </div>
    </div>
  );
};

