import React, {useState, useEffect} from "react";
import {Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import {useSelector, useDispatch} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import {returnMessages} from "../../../store/reducers/message";
import {returnErrors} from "../../../store/reducers/error";
import Endpoint from "../../../utils/Endpoint";

const Send_Contract = ({open, onClose, selectedData, getpdfurldata, pdfUrl}) => {
  const [pdfFile, setPdfFile] = useState(null);
  const [preview, setPreview] = useState("");
  const [uploadPdf, setUploadPdf] = useState(null);
  const [error, setError] = useState(null);
  const {idToken} = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setPdfFile(file);
      setPreview(URL.createObjectURL(file));
      setError(null);
    } else {
      setError("Please upload a PDF file.");
    }
  };

  const handleUploadClick = async () => {
    setLoading(true);
    if (!pdfFile) {
      setError("No file selected");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("files", pdfFile);

    const config = {
      headers: {
        "auth-token": idToken,
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const response = await axios.post(`${Endpoint.Contract_pdfUpload}?assetId=${selectedData?._id}`, formData, config);
      setUploadPdf(response?.data);
      setError(null);
      setLoading(false);
      dispatch(returnMessages(response.data.message || "Contract uploaded successfully.", "success"));
      onClose();
    } catch (error) {
      setError("Please Login Again Tokken Expired");
      setLoading(false);
      dispatch(returnErrors("Failed to upload contract.", "error"));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: "12px",
          boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)",
          padding: "20px",
        },
      }}
    >
      <DialogTitle style={{textAlign: "center", fontWeight: "bold", color: "#333"}}>{pdfUrl ? "Update Contract" : "Upload Contract"}</DialogTitle>

      <DialogContent style={{marginTop: "20px", textAlign: "center"}}>
        <Typography
          variant="body1"
          style={{marginBottom: "10px", color: "#555"}}
        >
          Select a PDF file to {getpdfurldata ? "update" : "upload"}:
        </Typography>

        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          style={{
            backgroundColor: "#1976d2",
            color: "#fff",
            borderRadius: "8px",
            padding: "10px 20px",
            marginBottom: "20px",
            textTransform: "none",
          }}
        >
          {pdfUrl ? "Update PDF" : "Upload PDF"}
          <input
            type="file"
            accept="application/pdf"
            hidden
            onChange={handleFileChange}
          />
        </Button>

        {error && (
          <Typography
            color="error"
            variant="body2"
            style={{marginBottom: "10px"}}
          >
            {error}
          </Typography>
        )}

        {preview && (
          <embed
            src={preview}
            type="application/pdf"
            style={{
              width: "100%",
              height: "500px",
              borderRadius: "10px",
              boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.15)",
            }}
          />
        )}
      </DialogContent>

      <DialogActions style={{justifyContent: "space-between", padding: "15px"}}>
        <Button
          onClick={onClose}
          color="secondary"
          style={{
            backgroundColor: "#f44336",
            color: "#fff",
            padding: "10px 20px",
            borderRadius: "8px",
            fontWeight: "bold",
            textTransform: "none",
          }}
        >
          Cancel
        </Button>

        <Button
          onClick={handleUploadClick}
          disabled={loading}
          style={{
            backgroundColor: loading ? "#b0bec5" : "#4caf50",
            color: "#fff",
            padding: "10px 20px",
            borderRadius: "8px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            textTransform: "none",
          }}
        >
          {loading ? (
            <CircularProgress
              size={24}
              color="inherit"
            />
          ) : pdfUrl ? (
            "Update Contract"
          ) : (
            "Send Contract"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Send_Contract;
