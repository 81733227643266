import React from "react";
import Chart from "react-apexcharts";

const TopInvestors = () => {
  const data = {
    series: [
      {
        data: [24, 14, 13, 11, 9, 8, 6], // Example data for investors' percentages
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 250,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "50%",
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val) => `${val}%`,
        offsetX: 0,
        style: {
          fontSize: "14px",
          colors: ["#ffffff"],
        },
      },
      xaxis: {
        categories: ["JP Morgan", "Argentina", "Zimbabwe", "Lula Berry", "Gregory Ray", "Mellanie Porter", "Ireland"],
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            fontSize: "14px",
            colors: ["#000"],
          },
        },
      },
      grid: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      theme: {
        monochrome: {
          enabled: true,
          color: "#2fa0fc",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      },
    },
  };

  return (
    <div className="top-investors">
      <div className="header">
        <p className="title">Top Investors</p>
        <a
          href="#see-more"
          className="see-more"
        >
          See More →
        </a>
      </div>
      <div className="chart">
        <Chart
          options={data.options}
          series={data.series}
          type="bar"
          height={250}
        />
      </div>
    </div>
  );
};

export default TopInvestors;
