import exportIcon from "../../../assets/svg/export.svg";

const FilterBox = ({handleFilter}) => {
  return (
    <div className="filterbox_wrapper">
      <div className="filterbox">
        <div className="filterbox_header">
          <h2>Filter Settings</h2>
        </div>
        <div className="filterbox_lower">
          <div>
            <p>Show completed tasks</p>
            <img
              src={exportIcon}
              alt=""
            />
          </div>
          <div>
            <p>Show completed tasks</p>
            <img
              src={exportIcon}
              alt=""
            />
          </div>
          <div>
            <p>Show completed tasks</p>
            <img
              src={exportIcon}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};


export default FilterBox