import React from 'react'
import './Funds.scss'
import funds from '../../../assets/Image/assetDiscovery/funds.svg'

function Funds() {
  return (
    <div className='funds'>
        <h3>
        Julie doesn’t have any Fund yet.
        <img src={funds} alt='' />
        </h3>
    </div>
  )
}

export default Funds