import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "./EditAssetInfo.scss";
import { Sidecard, Card } from "./EditAssetInfo/EditAssetInfoData";

const EditAssetInfo = () => {
  return (
    <div className="edit-asset-container">
      <div className="asset-header">
        <h1>Edit Asset Information</h1>
        <div className="header-buttons">
          <Link
            to="/portfolio"
            style={{ backgroundImage: "linear-gradient(to top, #f7f8fa, #fefefe)" }}
          >
            Discard changes and Exit
          </Link>
          <Link
            style={{
              backgroundImage: "linear-gradient(to top, #7858c0, #8164c1)",
              marginLeft: "10px",
              color: "white"
            }}
          >
            Save edits
          </Link>
        </div>
      </div>
      <div className="asset-container">
        <div className="main-container">
          <Card type={"AssetName"} />
          <Card type={"Description"} />
          <Card type={"Price"} />
          <Card type={"Contact"} />
          <Card type={"Website"} />
          <Card type={"Photos"} />
          <Card type={"Google"} />
          <Card type={"Marketing"} />
          <Card type={"PropertyDetails"} />
          <Card type={"Utilities"} />
          <Card type={"Manager"} />
          <Card type={"Tags"} />
        </div>
        <div className="side-container">
          <Sidecard />
        </div>
      </div>
    </div>
  );
};

export default EditAssetInfo;
