import React, { useState } from "react";
import Convertibles from "../../../assets/Manage-syndicate/image/Convertibles.png";
import Checkmark from "../../../assets/Manage-syndicate/Svg/Checkmark.svg";
import TotalCompensationimage from "../../../assets/Manage-syndicate/image/Total Compensation.png";
import business from "../../../assets/Manage-syndicate/Svg/business.svg";
import "./OSBSTaxBrack.scss";
import {
  PrimaryButton,
  PrimaryButtonSvgFirst
} from "../../../components/reusableComponents/Buttons/Buttons";

const OSBSTaxBrack = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What are company requirements to be a Qualified Small Business?",
      answer:
        "To be a Qualified Small Business, the company must meet the following requirements. Incorporated as a U.S C-corporation. Gross asset under $50 million. Not on the list of excluded business types. At least 80% of the company’s assets are used in the “active conduct of one or more trade or business. Carta automatically checks your QSBS eligibility when you conduct a 409A valuation. ",
    },
    {
      question: "What are stakeholders requirements to be eligible for the QSBS tax break?",
      answer:
        "Companies that have raised at least $10M in funding OR have at least 500 stakeholders on their cap table are required to disclose their stock-based compensation expenses on their income statement",
    },
    {
      question: "Why should I share my QSBA attestation letter with my investors?",
      answer:
        "Companies that have raised at least $10M in funding OR have at least 500 stakeholders on their cap table are required to disclose their stock-based compensation expenses on their income statement",
    },
    {
      question: "How does a stakeholder claim their QSBA tax benefits?",
      answer:
        "Companies that have raised at least $10M in funding OR have at least 500 stakeholders on their cap table are required to disclose their stock-based compensation expenses on their income statement",
    },
  ];

  return (
    <div className="OSBSTaxBrack-container">
      <div className="OSBSTaxBrack-container-image">
        <div className="OSBSTaxBrack-Data">
          <div className="OSBSTaxBrack-divconatiner">
            <div className="OSBSTaxBrack-image">
              <img src={business} alt="img" />
            </div>
          </div>
          <PrimaryButton
            name="QSBA Attestation"
            classNames="OSBSTaxBrack-QSBA"
          />
          <div>
            <PrimaryButton name="Add-On" classNames="OSBSTaxBrack-Add" />
          </div>
          <h1>Pay every employee correctly with transparent employee</h1>
          <hr className="linetag" />
          <div className="OSBSTaxBrack-flex">
            <img src={Checkmark} alt="image" />
            <p>Review tax eligibility</p>
          </div>
          <div className="OSBSTaxBrack-flex">
            <img src={Checkmark} alt="image" />
            <p>Attestation letters for eligibility</p>
          </div>
          <div className="OSBSTaxBrack-flex">
            <img src={Checkmark} alt="image" />
            <p>Guidance for tax saving strategies</p>
          </div>
          <PrimaryButtonSvgFirst
            name="Schedule a call"
            classNames="CaptableStake-Filter"
          />
        </div>
        <div className="OSBSTaxBrack-wrapper">
          <h1>Frequently Asked Questions</h1>
          {faqData.map((faq, index) => (
            <div className="OSBSTaxBrack" key={index}>
              <button
                className={`OSBSTaxBrack-accordion ${
                  activeIndex === index ? "active" : ""
                }`}
                onClick={() => toggleAccordion(index)}
              >
                {faq.question}
                <i className="fa-solid fa-chevron-down"></i>
              </button>
              <div
                className={`OSBSTaxBrack-pannel ${
                  activeIndex === index ? "active" : ""
                }`}
              >
                <p>{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OSBSTaxBrack;
