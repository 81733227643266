import React, { useState } from 'react'
import './invoicing.scss'
import DropDown from '../../../components/reusableComponents/Dropdown/Dropdown'
import calendar from '../../../assets/svg/calender.svg'
import chart from '../../../assets/Image/invoice-graph.png'
import { InputBox } from '../../../components/reusableComponents/FormFields/FormFields'
import { PrimaryButtonSvgFirst } from "../../../components/reusableComponents/Buttons/Buttons";
import { Checkbox, FormControlLabel } from "@mui/material";
import profileIcon from "../../../assets/svg/commentProfile.png";
import invoice from '../../../assets/svg/download.svg'
import dot from '../../../assets/svg/dot-gray.svg'

function Invoicing() {

  const [navActive, setNavActive] = useState(1);


  return (
    <div className='invoicing'>
      <div className='invoicing_left'>
        <div className='invoicing_upper'>
          <div className='invoicing_header'>
            <h2>Payment statistics</h2>
            <div className='invoicing_nav'>
              <div className=" deals-room-nav asset-nav ass-nav">
                <span
                  className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setNavActive(1);
                  }}
                >
                  Monthly           </span>
                <span
                  className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setNavActive(2);
                  }}
                >
                  Daily
                </span>


              </div> </div>

            <div>
              <DropDown
                label=""
                label2={''}
                selectedData={'November'}

              />
            </div>

            <div>
              <DropDown
                label=""
                label2={''}
                selectedData={'2019'}

              />
            </div>
          </div>
          <img src={chart} alt='' />
        </div>

        <div className='invoicing_lower'>
          <InvoiceTable />
        </div>
      </div>

      <div className='invoicing_right'>
        <div className='invoicing_right_box'>
          <div className='invoicing_header'>
            <h2>Payment Method</h2>

          </div>
          <div className='inputs'>
            <CardSvg className='cardsvg' />
            <InputBox label="Card Number" type="text" />
            <InputBox label="CardHolder Name" type="text" />
            <div style={{ display: 'flex', gap: '12px' , alignItems:'flex-end' }}>
              <div>
                <DropDown
                  label="Expiry Date"
                  label2={''}
                  selectedData={'10'}
                  width={'95px'}

                />
              </div>
              <div style={{ marginTop: '25px' }}>
                <DropDown
                  width={'95px'}
                  label=""
                  label2={''}
                  selectedData={'2019'}

                />
              </div>
              <div style={{ width: '80px' , marginBottom:'-7px' }}>
                <InputBox noeye={true} label="Cvv" type="password" />

              </div>
            </div>
          </div>
          <div className='btns'>
            <button className='btn_one'>
              Update Card
            </button>
            <button className='btn_two'>
              Unlink Card
            </button>
          </div>
        </div>
        <p className='text'>Payment secured by <span>securio</span>.</p>

      </div>
    </div>
  )
}

export default Invoicing



const CardSvg = ({className}) => {
  return (
    <svg className={className} width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1835_184)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1052 16C21.4654 16 25 12.4183 25 8C25 3.58172 21.4654 0 17.1052 0C12.7451 0 9.21051 3.58172 9.21051 8C9.21051 12.4183 12.7451 16 17.1052 16Z" fill="url(#paint0_linear_1835_184)" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.89474 16C12.2549 16 15.7895 12.4183 15.7895 8C15.7895 3.58172 12.2549 0 7.89474 0C3.53459 0 0 3.58172 0 8C0 12.4183 3.53459 16 7.89474 16Z" fill="url(#paint1_linear_1835_184)" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 1.50195C10.5078 2.95419 9.21051 5.32386 9.21051 8.00056C9.21051 10.6773 10.5078 13.0469 12.5 14.4992C14.4922 13.0469 15.7895 10.6773 15.7895 8.00056C15.7895 5.32386 14.4922 2.95419 12.5 1.50195Z" fill="url(#paint2_linear_1835_184)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_1835_184" x1="9.21051" y1="0" x2="9.21051" y2="16" gradientUnits="userSpaceOnUse">
          <stop stop-color="#F79F1A" />
          <stop offset="1" stop-color="#FFC874" />
        </linearGradient>
        <linearGradient id="paint1_linear_1835_184" x1="0" y1="0" x2="0" y2="16" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EA001B" />
          <stop offset="1" stop-color="#FF4848" />
        </linearGradient>
        <linearGradient id="paint2_linear_1835_184" x1="9.21051" y1="1.50195" x2="9.21051" y2="14.4992" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF5F01" />
          <stop offset="1" stop-color="#FF7727" />
        </linearGradient>
        <clipPath id="clip0_1835_184">
          <rect width="25" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}




const InvoiceTable = () => {
  return (
    <div className="lender_table">
      <div className="documents_box">
        <div className="documents_box-T">
          <div className="left">
            <h2>Invoice history</h2>
          </div>

        </div>
        <div className="documents_box-B table">
          <div className="uploaded_docs">
            <div className="header">
              <div className="left">
                <p>Date</p>
                <p className='second'>Subject</p>
                <p>Price</p>
                <p>Payment Method</p>
                <p>Status</p>
                <p><img src={invoice} alt='' /></p>
             
              </div>

            </div>
            <Row2 />
            <Row2 fail={true} />
            <Row2 />
            <Row2 />
            <Row2 />
            <Row2 />
          </div>
        </div>
      </div>
    </div>
  );
};
const Row2 = ({fail}) => {
  return (
    <div className="table_row table_row2">
      <div className="left">
        <div className="item first">

          <div className="text_wrapper">
            <p>Nov 11, 2018</p>
            <span>ID: 233262573457</span>
          </div>
        </div>
        <div className="item second">
          <div className="text_wrapper">
            <p>2  Assets</p>
            <span>Hicks Rd Hamilton</span>
            <span>Hamilton</span>

          </div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p>$778.00</p>
            <span>$333.00</span>

          </div>
        </div>
        <div className="item">
           <button className='card_btn'>
            <CardSvg />
            ...7771
           </button>
        </div>
        <div className="item">
          <div className="text_wrapper">
            {fail ? <button className='btn_red'>Failed</button>
 :
            <button className='btn_green'>Paid</button>
               }
          </div>
        </div>
        <div className="item">
        <img style={{rotate:'90deg'}} src={dot} alt='' />
        </div>
      </div>
      
    
    </div>
  );
};