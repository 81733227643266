import React from "react";
import "./Stakeholderscharrings.scss";
import view from "../../../assets/Manage-syndicate/Svg/view.svg";

const Stakeholderscharrings = () => {
  return (
    <div>
      <div className="Stakeholder-Conatiner">
        <div className="Stakeholder-Conatiner-data">
          <div className="Stakeholderscharrings-conatiner">
            <img
              src={view}
              alt="image"
            />
          </div>
          <div className="Stakeholderscharrings-button">
            <p>Corporation is read only</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stakeholderscharrings;
