import React from "react";
// import loaderGif from "./assets/loader.gif";
import loaderGif from "../../../assets/portfolio/Loader/Animation - 1730011181820.gif"; 
import "./index.scss";

function LoaderOverlay({ desc }) {
  return (
    <div className="loader_overlay">
      <div className="wrapper">
        <img src={loaderGif} alt="Loading..." className="loader-gif" />
        {desc ? <span>{desc}</span> : <span>Loading...</span>}
      </div>
    </div>
  );
}

export default LoaderOverlay;