import React, { useState } from 'react'
import './ProjectList.scss'
import Checkbox from '../profile/settings/Checkbox';
import arrowdown from '../../assets/Image/assetDiscovery/arrow_down.svg'
import chat from '../../assets/svg/chat.svg'
import attachment from '../../assets/svg/attachment.svg';
import person from '../../assets/svg/profileAvatar.png';
import edit from '../../assets/svg/edit.svg'
import setting from '../../assets/svg/settings.svg'
import add from '../../assets/svg/add-gray.svg'
import Delete from '../../assets/svg/delete.svg';

function ProjectList({ columns, openTaskModal, selectedColumn, setSelectedColumn, setColumns, handleRenameColumn, handleDeleteColumn }) {
  return (
    <div className='project_list'>
      {columns.map((column) => (
        <TodoBlock
          key={column.name}
          title={column.name}
          projects={column.projects}
          openTaskModal={openTaskModal}
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          setColumns={setColumns}
          handleRenameColumn={(newName) => handleRenameColumn(column.name, newName)}
          handleDeleteColumn={() => handleDeleteColumn(column.name)}
        />
      ))}
    </div>
  );
}


export default ProjectList




const TodoBlock = ({ title, projects, openTaskModal, setSelectedColumn, setColumns, handleRenameColumn, handleDeleteColumn }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleTodoBlock = () => {
    setIsOpen(!isOpen);
  };

  const handleRename = () => {
    const newName = prompt('Enter a new name for the column:', title);
    if (newName && newName !== title) {
      handleRenameColumn(newName);
    }
  };

  const handleDelete = () => {
    const isConfirmed = window.confirm(`Are you sure you want to delete the column: ${title}?`);
    if (isConfirmed) {
      handleDeleteColumn();
    }
  };

  
  const [settings , setSettings] = useState(false)
  const toggleSettings = () => {
    setSettings(!settings);
  };

  return (
    <div className="todo_block">
      <div className="todo_block-T">
        <div className="L">
          {isOpen ?
            <img src={arrowdown} alt='' onClick={toggleTodoBlock} />
            : 
            <img style={{rotate:'270deg'}} src={arrowdown} alt='' onClick={toggleTodoBlock} />

          }
          <p>{title}</p>
          <span className="length">{projects.length}</span>
        </div>
        <div className="R">
          <button>
            <img src={add} alt=''  onClick={() => openTaskModal(title)} />
            Add Task
          </button>

          <img src={setting} alt='' onClick={toggleSettings} />
        </div>
      </div>
      {settings && <SettingsPopup onDeleteColumn={handleDeleteColumn} onRenameColumn={handleRenameColumn} />}

      {isOpen &&
        <div className="todo_block-B">
          <div className="header">
            <div className="left">
              <span>Task</span>
            </div>
            <div className="right">
              <span>Updated</span>
              <span>Due Date</span>
              <span>Assigned to</span>
            </div>
          </div>
          {projects.map((project) => (
            <Row key={project.id} project={project} />
          ))}
        </div>
      }
    </div>
  );
};
const Row = ({ project }) => {
  return <div className="row">
    <div className="row-L">
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="24" viewBox="0 0 10 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5ZM10 1.5C10 2.32843 9.32843 3 8.5 3C7.67157 3 7 2.32843 7 1.5C7 0.671573 7.67157 0 8.5 0C9.32843 0 10 0.671573 10 1.5ZM1.5 10C2.32843 10 3 9.32843 3 8.5C3 7.67157 2.32843 7 1.5 7C0.671573 7 0 7.67157 0 8.5C0 9.32843 0.671573 10 1.5 10ZM10 8.5C10 9.32843 9.32843 10 8.5 10C7.67157 10 7 9.32843 7 8.5C7 7.67157 7.67157 7 8.5 7C9.32843 7 10 7.67157 10 8.5ZM1.5 17C2.32843 17 3 16.3284 3 15.5C3 14.6716 2.32843 14 1.5 14C0.671573 14 0 14.6716 0 15.5C0 16.3284 0.671573 17 1.5 17ZM10 15.5C10 16.3284 9.32843 17 8.5 17C7.67157 17 7 16.3284 7 15.5C7 14.6716 7.67157 14 8.5 14C9.32843 14 10 14.6716 10 15.5ZM1.5 24C2.32843 24 3 23.3284 3 22.5C3 21.6716 2.32843 21 1.5 21C0.671573 21 0 21.6716 0 22.5C0 23.3284 0.671573 24 1.5 24ZM10 22.5C10 23.3284 9.32843 24 8.5 24C7.67157 24 7 23.3284 7 22.5C7 21.6716 7.67157 21 8.5 21C9.32843 21 10 21.6716 10 22.5Z" fill="#D5DCE6" />
      </svg>
      <Checkbox />
      <p className="title">{project.title}</p>
      <img src={chat} alt='' />
      <span>2</span>
      <img src={attachment} alt='' />
      <span>1</span>

    </div>
    <div className="row-R">
      <span>27 May</span>
      <p>10 Aug</p>

      <div className="assigned_wrapper">
        <img src={person} alt="" />
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
          <g clip-path="url(#clip0_562_622)">
            <path d="M27.5 14C27.5 21.4558 21.4558 27.5 14 27.5C6.54416 27.5 0.5 21.4558 0.5 14C0.5 6.54416 6.54416 0.5 14 0.5C21.4558 0.5 27.5 6.54416 27.5 14Z" fill="white" fill-opacity="0.01" stroke="#EAEDF3" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 13H18C18.5523 13 19 13.4477 19 14C19 14.5523 18.5523 15 18 15H15V18C15 18.5523 14.5523 19 14 19C13.4477 19 13 18.5523 13 18V15H10C9.44772 15 9 14.5523 9 14C9 13.4477 9.44772 13 10 13H13V10C13 9.44772 13.4477 9 14 9C14.5523 9 15 9.44772 15 10V13Z" fill="#9EA0A5" />
          </g>
          <defs>
            <clipPath id="clip0_562_622">
              <rect width="28" height="28" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <img src={edit} alt="" />

    </div>
  </div>
}


const SettingsPopup = ({ onDeleteColumn, onRenameColumn }) => {
  const handleRename = () => {
    const newName = prompt('Enter a new name for the column:');
    if (newName) {

      onRenameColumn(newName);
    }
  };

  const handleDelete = () => {
    const confirmDelete = window.confirm('Are you sure you want to delete this column?');
    if (confirmDelete) {

      onDeleteColumn();
    }
  };

  return (
    <div className='settings_popup' style={{marginLeft:'0px' , right:'33px'}}>
      <p onClick={handleRename}>
        <img src={Delete} alt='' />
        Rename List
      </p>
      <p onClick={handleDelete}>
        <img src={Delete} alt='' />
        Remove List
      </p>
    </div>
  );
};
