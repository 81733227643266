import React from "react";
import "./Summary.scss";

function Summary() {
  return (
    <div className="summary">
      <div className="summary_upper">
        <div className="upper_header">About</div>
        <div className="upper_info">
          <span style={{marginTop: "20px"}}>Bio</span>
          <p>
            Working in automotive quality management for over 3 years. A passionate traveller, loves to spend time with her dog. Wine taster. Biggest dream is to travel the world with all the money I
            make from renting building to my dearest customers who pay well.{" "}
          </p>

          <p
            className="tag"
            style={{marginTop: "10px"}}
          >
            Tags
          </p>
          <div className="tags_wrapper">
            <div className="tags green">long term</div>
            <div className="tags blue">offices</div>
            <div className="tags orange">Development</div>
          </div>

          <hr></hr>

          <span>Contacts</span>
          <div className="contacts">
            <div className="left">
              <p>
                <img
                  src=""
                  alt=""
                />
                +1 7887 665 221
              </p>
              <p>
                <img
                  src=""
                  alt=""
                />
                me@angenewton.co
              </p>
              <p>
                <img
                  src=""
                  alt=""
                />
                www.heritageattampa.com
              </p>
              <p>
                <img
                  src=""
                  alt=""
                />
                Marketing Brochure
              </p>
            </div>
            <div className="right">
              <p>
                <img
                  src=""
                  alt=""
                />
                <span>facebook.com</span>/NorthGroupTrust
              </p>
              <p>
                <img
                  src=""
                  alt=""
                />
                <span>facebook.com</span>/NorthGroupTrust
              </p>
              <p>
                <img
                  src=""
                  alt=""
                />
                <span>facebook.com</span>/NorthGroupTrust
              </p>
              <p>
                <img
                  src=""
                  alt=""
                />
                <span>facebook.com</span>/NorthGroupTrust
              </p>
            </div>
          </div>
          <hr></hr>

          <div className="upper_info_box">
            <div>
              <h3>$376,722,967</h3>
              <span>Total Portfolio Activity</span>
            </div>
            <div>
              <h3>$376,722,967</h3>
              <span>Total Portfolio Activity</span>
            </div>
            <div>
              <h3>$376,722,967</h3>
              <span>Total Portfolio Activity</span>
            </div>
          </div>
        </div>
      </div>

      <div className="summary_lower">
        <div className="lower_header">
          <h2>Investment interests</h2>
          <p>Based on Portfolio</p>
        </div>

        <div className="lower_info">
          <div className="left">
            <div className="left_info">
              <h3>Property types</h3>
              <hr></hr>
              <div className="info_wrapper">
                <div>
                  <p>Built-to-Rent</p>
                  <p>24</p>
                </div>
                <div>
                  <p>Built-to-Rent</p>
                  <p>24</p>
                </div>
                <div>
                  <p>Built-to-Rent</p>
                  <p>24</p>
                </div>
              </div>
            </div>

            <div className="left_info">
              <h3>Market</h3>
              <hr></hr>
              <div className="info_wrapper">
                <div>
                  <p>Built-to-Rent</p>
                  <p>24</p>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="right_info">
              <h3>Hold Period</h3>
              <hr></hr>
              <div>
                <p>Built-to-Rent</p>
                <p>24</p>
              </div>
            </div>
            <div className="right_info">
              <h3>Regions</h3>
              <hr></hr>
              <div>
                <p>Built-to-Rent</p>
                <p>24</p>
              </div>
              <div>
                <p>Built-to-Rent</p>
                <p>24</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
