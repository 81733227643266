import React, {useState} from "react";
import {Link} from "react-router-dom";
import "./Inputs.scss";
import {FormControlLabel} from "@mui/material";
import searchSvg from "../../assets/portfolio/svgs/search-input-icon.svg";
import DropDownSvg from "../../assets/portfolio/svgs/dropdown-icon.svg";
import DropDownArrows from "../../assets/portfolio/svgs/dropdownArrows.png";
import AddIcon from "../../assets/portfolio/svgs/plus.svg";
import uploadIcon from "../../assets/portfolio/svgs/upload-icon.svg";
import Checkbox from "../profile/settings/Checkbox";

export function SearchInput({width, height, padding}) {
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div
      className="search-container"
      style={{width: width, height: height, padding: padding}}
    >
      <input
        type="text"
        placeholder="Find Asset"
        value={searchValue}
        onChange={handleChange}
        className="search-input"
      />
      <img
        src={searchSvg}
        alt="Search"
        className="search-icon"
      />
    </div>
  );
}

export function SortDropdown(width, height, padding) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Price");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div
      className="custom-dropdown"
      style={{width: width, height: height, padding: padding}}
    >
      <div
        className="dropdown-header"
        // onClick={toggleDropdown}
      >
        <span className="sort-placeholder">Sort:</span>
        <span className="image-icon">
          <img
            src={DropDownSvg}
            alt=""
          />
        </span>
        <span className="sort-label">{selectedOption}</span>
        <span className="dropdown-arrow">
          <img
            src={DropDownArrows}
            alt=""
          />
        </span>
      </div>
      {isOpen && (
        <div className="dropdown-options">
          <div
            className="option"
            onClick={() => handleOptionClick("Price")}
          >
            Price
          </div>
          <div
            className="option"
            onClick={() => handleOptionClick("Date")}
          >
            Date
          </div>
        </div>
      )}
    </div>
  );
}

export function InputWithIcon({label, placeholder, imageSrc, text, bottomText, value}) {
  return (
    <div className="input-with-image">
      <label htmlFor="inputField">{label}</label>
      <div className="input-field">
        <input
          id="inputField"
          type="text"
          placeholder={placeholder}
          value={value}
        />
        <div className="image-container">
          <img
            src={imageSrc}
            alt="Input Icon"
          />
          <span>{text}</span>
        </div>
      </div>
      {bottomText && <p>{bottomText}</p>}
    </div>
  );
}

export function InputWithAddFieldFunc({label, placeholder, imageSrc, text}) {
  const [inputs, setInputs] = useState([{id: 1, value: ""}]);

  const handleInputChange = (index, event) => {
    const values = [...inputs];
    values[index].value = event.target.value;
    setInputs(values);
  };

  const handleAddInput = () => {
    const newInput = {
      id: inputs.length + 1,
      value: "",
    };
    setInputs([...inputs, newInput]);
  };

  return (
    <div className="input-with-add">
      <label htmlFor="inputField">{label}</label>
      {inputs.map((input, index) => (
        <div
          key={input.id}
          className="input-field"
        >
          <div className="input-container">
            <input
              type="text"
              placeholder={placeholder}
              value={input.value}
              onChange={(event) => handleInputChange(index, event)}
            />
            <div className="image-container">
              <img
                src={imageSrc}
                alt="Input Icon"
              />
              <span>{text}</span>
            </div>
          </div>
          {index === inputs.length - 1 && (
            <button
              className="add-input-btn"
              onClick={handleAddInput}
            >
              <img
                src={AddIcon}
                alt="Add Input"
              />
            </button>
          )}
        </div>
      ))}
    </div>
  );
}

export function UploadFile() {
  const [dragging, setDragging] = useState(false);
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const files = Array.from(e.dataTransfer.files);
    // Process dropped files here

  };

  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <div
      className={`file-upload-container ${dragging ? "dragging" : ""}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <button
        className="upload-content"
        onClick={handleButtonClick}
      >
        <img
          src={uploadIcon}
          alt=""
        />
        <div className="upload-text">
          <p>
            <span style={{borderBottom: "1px solid #E2E5ED"}}>Select documents</span> or Drop a file here.
          </p>
          <p className="file-format">Supported file formats: PDF. Max file size 10 MB</p>
        </div>
      </button>
      <input
        id="fileInput"
        type="file"
        style={{display: "none"}}
      />
    </div>
  );
}

export function TextInputWithLabel({label, value, onChange, optionText, placeholder}) {
  return (
    <div className="text-input-container">
      <label className="input-label">{label}</label>
      <div className="input-group">
        <input
          type="text"
          className="text-input"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          data-option-text={optionText}
        />
        {optionText && <span className="option-text">{optionText}</span>}
      </div>
    </div>
  );
}

export function DropdownWithCheckbox({options, onSelect}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    // onSelect(option);
    setIsOpen(false);
  };

  return (
    <div className="dropdown">
      <button
        onClick={toggleDropdown}
        className="dropdown-btn"
      >
        <p>
          Permissions: <span style={{color: "#6B6C6F", fontStyle: "italic"}}>Select</span>
        </p>
        <img
          src={DropDownArrows}
          alt=""
        />
      </button>
      {isOpen && (
        <div className="dropdown-content">
          {options.map((option, index) => (
            <Checkbox label={options[index]} />
          ))}
        </div>
      )}
    </div>
  );
}
