import React from "react";
import ReactECharts from "echarts-for-react";

const HorizontalBarChart = () => {
  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {},
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      show: false,
    },
    yAxis: {
      type: "category",
      show: false,
    },
    series: [
      {
        name: "GPR",
        type: "bar",
        stack: "total",
        label: {
          show: true,
        },
        emphasis: {
          focus: "series",
        },
        data: [320],
        barWidth: 50,
        itemStyle: {
          borderRadius: [5, 0, 0, 5],
          color: "#1566d8",
        },
      },
      {
        name: "Other Income",
        type: "bar",
        stack: "total",
        label: {
          show: true,
        },
        emphasis: {
          focus: "series",
        },
        data: [120],
        barWidth: 50,
        itemStyle: {
          borderRadius: [0, 0, 0, 0],
          color: "#6fcf97",
        },
      },
      {
        name: "Other",
        type: "bar",
        stack: "total",
        label: {
          show: true,
        },
        emphasis: {
          focus: "series",
        },
        data: [220],
        barWidth: 50,
        itemStyle: {
          borderRadius: [0, 0, 0, 0],
          color: "#f2994a",
        },
      },
      {
        name: "Video Ad",
        type: "bar",
        stack: "total",
        label: {
          show: true,
        },
        emphasis: {
          focus: "series",
        },
        data: [150],
        barWidth: 50,
        itemStyle: {
          borderRadius: [0, 0, 0, 0],
          color: "#ffc403",
        },
      },
      {
        name: "Video Ad",
        type: "bar",
        stack: "total",
        label: {
          show: true,
        },
        emphasis: {
          focus: "series",
        },
        data: [150],
        barWidth: 50,
        itemStyle: {
          borderRadius: [0, 0, 0, 0],
          color: "#85b8e8",
        },
      },
      {
        name: "Search Engine",
        type: "bar",
        stack: "total",
        label: {
          show: true,
        },
        emphasis: {
          focus: "series",
        },
        data: [820],
        barWidth: 50,
        itemStyle: {
          borderRadius: [0, 5, 5, 0],
          color: "#9ea0a6",
        },
      },
    ],
  };

  return <ReactECharts option={option} />;
};

export default HorizontalBarChart;
