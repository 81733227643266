import React from "react";
import "./Document.scss";
import pdfIcon from "../../assets/investment/pdf.svg";
import search from "../../assets/svg/search.svg";
import filterIcon from "../../assets/investment/filter.svg";
import arrowIcon from "../../assets/investment/arrow-right.svg";
import Checkbox from "../profile/settings/Checkbox";
import prevnav from "../../assets/dealsroom/svg/prevnav.svg";
import fwdnav from "../../assets/dealsroom/svg/fwdnav.svg";
import downloadIcon from "../../assets/investment/download.svg";
import capitalSvg from "../../assets/InvestmentDashboard/DocumentSvgs/capitalSvg.svg";
import digilence from "../../assets/InvestmentDashboard/DocumentSvgs/legal.svg";
import distribution from "../../assets/InvestmentDashboard/DocumentSvgs/distribution.svg";
import legal from "../../assets/InvestmentDashboard/DocumentSvgs/legal.svg";
import taxForms from "../../assets/InvestmentDashboard/DocumentSvgs/taxForms.svg";
import reports from "../../assets/InvestmentDashboard/DocumentSvgs/reports.svg";

import {InputBox} from "../../components/reusableComponents/FormFields/FormFields";
import DropDown from "../../components/reusableComponents/Dropdown/Dropdown";

function Document() {
  return (
    <div className="document_wrapper">
      <div className="document_left">
        <div className="document_table general_investments">
          <div className="upper">
            <span className="upper_heading">
              <h2>All Documents</h2>
            </span>
            <div className="upper_right">
              <button>
                <img
                  src={downloadIcon}
                  alt=""
                />
                Download as a Zip
              </button>
            </div>
          </div>

          <div className="lower">
            <Table />
          </div>
        </div>
      </div>

      <div className="document_right">
        <div className="right_header">
          <h2>Search and Filters</h2>
        </div>

        <div className="search_wrapper">
          <InputBox
            label={"Search"}
            placeholder={"Document name or any info"}
            svgPath={search}
          />
        </div>

        <div className="heading_wrapper">
          <p>Category</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.59 8.58997L12 13.17L7.41 8.58997L6 9.99997L12 16L18 9.99997L16.59 8.58997Z"
              fill="#959595"
            />
          </svg>
        </div>

        <div className="category_wrapper">
          <Checkbox
            variant={2}
            img={capitalSvg}
            label1={"Capital Call Notices"}
          />
          <Checkbox
            variant={2}
            img={digilence}
            label1={"Diligence"}
          />
          <Checkbox
            variant={2}
            img={distribution}
            label1={"Distribution Notices"}
          />
          <Checkbox
            variant={2}
            img={legal}
            label1={"Legal"}
          />
          <Checkbox
            variant={2}
            img={legal}
            label1={"Reports"}
          />
          <Checkbox
            variant={2}
            img={legal}
            label1={"Marketing"}
          />
          <Checkbox
            variant={2}
            img={legal}
            label1={"Offering Materials"}
          />
          <Checkbox
            variant={2}
            img={reports}
            label1={"Reports"}
          />
          <Checkbox
            variant={2}
            img={taxForms}
            label1={"Tax Forms"}
          />
        </div>

        <div className="heading_wrapper">
          <p>Investment</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.59 8.58997L12 13.17L7.41 8.58997L6 9.99997L12 16L18 9.99997L16.59 8.58997Z"
              fill="#959595"
            />
          </svg>
        </div>

        <div className="date_wrapper">
          <DropDown
            label={"Date"}
            placeholder={"Date"}
            type={"date"}
          />
        </div>
      </div>
    </div>
  );
}

export default Document;

const Table = () => {
  return (
    <div>
      <div className="documents_box-B table">
        <div className="uploaded_docs">
          <div className="header">
            <div className="left">
              <p className="col_first">
                <Checkbox label={"name"} />{" "}
              </p>
              <p>Type</p>
              <p>Date</p>
            </div>
          </div>
          <Row2 />
          <Row2 />
          <Row2 />
          <Row2 />
        </div>
      </div>
      <div className="table_lower">
        <div className="deals-room-doc-page-nav">
          <div className="page-nav-cont">
            <img
              src={prevnav}
              alt=""
            />
            <span>2 of 10</span>
            <img
              src={fwdnav}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Row2 = () => {
  return (
    <div className="table_row table_row2">
      <div className="left">
        <div className="item first">
          <div className="text_wrapper">
            <Checkbox
              variant={2}
              img={pdfIcon}
              label1={"Capital Call #2 notice - Hobart Family Capital, LP"}
            />
          </div>
        </div>

        <div className="item">
          <div className="text_wrapper">
            <p style={{color: "#9EA0A5"}}>Commercial</p>
          </div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p style={{color: "#9EA0A5"}}>
              Sep 25, 2018,<br></br> 10:23am
            </p>
          </div>
        </div>

        <div className="item">
          <div className="text_wrapper">
            <img
              src={downloadIcon}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};
