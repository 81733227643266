import React, { useState, useEffect, useRef } from "react";
import "./Tripledot.scss";
import PropTypes from "prop-types";

const DotMenu = ({ menuItems }) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (menuRef.current && !menuRef.current.contains(event.target)) {
  //       setShowMenu(false);
  //     }
  //   };

  //   document?.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document?.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleMenuItemClick = (action) => {
    action();
    setShowMenu(false);
  };

  return (
    <div className="dot-menu" ref={menuRef}>
      <div className="dot-menu-icon" onClick={toggleMenu}>
        <div className="dot1"></div>
        <div className="dot2"></div>
        <div className="dot3"></div>
      </div>
      {showMenu && (
        <div className="menu">
          {menuItems.map((item, index) => (
            <div
              key={index}
              className="menu-item"
              onClick={() => handleMenuItemClick(item.action)}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

DotMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

export default DotMenu;
