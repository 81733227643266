import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {GetSyndicates} from "../../utils/Axios";
import {useSelector} from "react-redux";
import noCoverImage from "../../assets/Image/no cover image.jpg";
import noDataFound from "../../assets/Image/9170823.jpg";
import goLink from "../../assets/svg/go_link.svg";
import "./ManageSyndicate.scss";
import LoaderOverlay from "../../components/reusableComponents/Loader";

const ManageSyndicates = ({handleMenuClicked}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [syndicateData, setSyndicateData] = useState([]);
  const [tokenExpired, setTokenExpired] = useState(false);
  const userAssetData = useSelector((state) => state?.auth.userInformation?._id);
  const {idToken} = useSelector((state) => state.auth);

  useEffect(() => {
    setLoading(true);
    GetSyndicates(idToken)
      .then((res) => {
        setLoading(false);
        const {success, data} = res;
        if (!success) {
          if (data && data.message === "Token expired") {
            setTokenExpired(true);
          }
        } else {
          setSyndicateData(data || []);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [idToken]);

  const handleSyndicateClick = (syndicate) => {
    if (syndicate && syndicate._id) {
      navigate(`/manage-syndicate/${userAssetData}/${syndicate._id}`, {replace: true});
    }
  };

  if (loading) {
    return (
      <div className="loaderOverlayContainer">
        <LoaderOverlay desc="Please Wait.." />
      </div>
    );
  }

  if (tokenExpired) {
    return (
      <div>
        <p>Your session has expired. Please login again.</p>
      </div>
    );
  }

  if (syndicateData.length === 0) {
    return (
      <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
        <img
          src={noDataFound}
          alt=""
          width="50%"
          style={{margin: "auto"}}
        />
        <span
          style={{cursor: "pointer", color: "purple"}}
          onClick={() => handleMenuClicked(14)}
        >
          Click here to add
        </span>
      </div>
    );
  }

  return (
    <div className="syndicates">
      <div className="syndicate_list">
        {syndicateData.map((syndicate) => (
          <div
            className="single_syndicate"
            key={syndicate.asset_id}
            onClick={() => handleSyndicateClick(syndicate)}
          >
            <img
              src={syndicate.imageUrl || noCoverImage}
              alt="image"
              className="coverImage"
            />
            <div className="syndicate_info">
              <h2>{syndicate.general.Name || "N/A"}</h2>
            </div>
            <button
              className="view_syndicate_btn"
              onClick={() => handleSyndicateClick(syndicate)}
            >
              View Syndicate{" "}
              <img
                src={goLink}
                alt=""
              />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManageSyndicates;
