import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// import "./AddDoc.scss";
import "../../pages/addDoc/AddDoc.scss";
import "../../pages/StartSyndicate/NewSyndicate.scss";
import { Button, Contacts, Disclaimer } from "../DealsRoom/SetupLoanReq";
import DropDown from "../../components/reusableComponents/Dropdown/Dropdown";
import { InputBox, InfoBox } from "../../components/reusableComponents/FormFields/FormFields";
import { PrimaryButtonSvgFirst, PrimaryButton } from "../../components/reusableComponents/Buttons/Buttons";

import buildingtwo from "../../assets/syndicate/svg/buildingtwo.svg";
import download from "../../assets/syndicate/svg/download.svg";
import removenew from "../../assets/syndicate/svg/removenew.svg";
import savetick from "../../assets/syndicate/svg/savetick.svg";
import upload from "../../assets/syndicate/svg/upload.svg";
import eye from "../../assets/dealsroom/svg/eye.svg";
import cross from "../../assets/syndicate/svg/cross.svg";
import save from "../../assets/syndicate/svg/save.svg";
import left from "../../assets/syndicate/svg/left.svg";
import right from "../../assets/syndicate/svg/right.svg";
import plusbold from "../../assets/loanrequest/svg/plus.svg";
import videoimg from "../../assets/syndicate/image/Video Image.png";
import vidoslider from "../../assets/syndicate/svg/videoslider.svg";
import videoplay from "../../assets/syndicate/svg/videoplayer.svg";
import building from "../../assets/syndicate/svg/building.svg";
import businessone from "../../assets/syndicate/svg/businessone.svg";
import pen2 from "../../assets/dealsroom/svg/pen2.svg";
import cancle from "../../assets/dealsroom/svg/cancle.svg";
import minus from "../../assets/dealsroom/svg/minus.svg";
import next from "../../assets/dealsroom/svg/next.svg";
import newdoc from "../../assets/dealsroom/svg/newdoc.svg";
import plus from "../../assets/dealsroom/svg/plus.svg";
import filedetail from "../../assets/dealsroom/svg/filedetail.svg";
import building2 from "../../assets/dealsroom/images/finish.png";
import buildingB from "../../assets/dealsroom/svg/building.svg";
import video from "../../assets/syndicate/svg/video.svg";
import grid from "../../assets/syndicate/svg/grid.svg";
import text from "../../assets/syndicate/svg/text.svg";
import image from "../../assets/syndicate/svg/image.svg";
import addsec from "../../assets/syndicate/svg/addsec.svg";
import navclose from "../../assets/loanrequest/svg/navclose.svg";
import pdf from "../../assets/dealsroom/svg/pdf.svg";

import { BussinessSyndicate, DealsRoomRight, GeneralSyndicate, InvestmentSyndicate, SmartContractSyndicate, SponsorSyndicate, SummarySyndicate, TeamSyndicate } from "../DealsRoom/Syndicate/LenderCard";

const NewSyndicate = () => {
  const [navActive, setNavActive] = useState(6);

  return (
    <div className="add-doc-main-wrapper">
      <DocHeader />
      <div className="second-container">
        <div className="details_and_progress_bar" style={{display:'flex',gap:'20px'}}>
          <div className="upload-doc-section-left">
            {navActive === 1 && <GeneralSynd />}
            {navActive === 2 && <LocationSynd />}
            {navActive === 3 && <AsstInfoSynd />}
            {navActive === 4 && <InvestmentSynd />}
            {navActive === 5 && <SponsorSynd />}
            {navActive === 6 && <BusinessSynd setNavActive={setNavActive} />}
            {navActive === 7 && <KeyDealPointSynd />}
            {navActive === 8 && <SmartContractSynd />}
            {navActive === 9 && <TeamSynd />}
            {navActive === 10 && <ContactSynd />}
            {navActive === 11 && <DisclaimerSynd />}
            {navActive === 12 && <SummarySynd />}
            {navActive === 13 && <BusinessTwo setNavActive={setNavActive} />}
            {navActive === 14 && <BusinessThree />}
          </div>
          <div className="doc_navigator_extra">
            <DocNavigator
              navActive={navActive}
              setNavActive={setNavActive}
            />
          </div>
        </div>

        <div className="upload-doc-section-right syndicate-doc-right">
          <div>
            <p id="pre-head">Preview</p>
            <DealsRoomRight />
          </div>
          <div className="doc_navigator">
            <DocNavigator
              navActive={navActive}
              setNavActive={setNavActive}
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export default NewSyndicate;

const DocHeader = ({ navdata = 1 }) => {
  const [navActive, setNavActive] = useState(6);
  const navigate = useNavigate();
  useEffect(() => {
    setNavActive(navdata);
  }, []);
  const buttonData = [
    { icon: `${save}`, value: "Save Draft" },
    { icon: `${eye}`, value: "Lender’s Preview" },
    { icon: `${left}`, value: "Back" },
  ];

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="deals-room-center-header">
      <p>Start a Syndicate</p>
      <div className="deals-room-header-button">
        <div
          className="header-button-first"
          onClick={goBack}
        >
          <img
            src={cross}
            alt="icon"
          />
          <p>Cancel</p>
        </div>

        {buttonData.map(({ icon, value }) => {
          return (
            <Button
              icon={icon}
              value={value}
            />
          );
        })}
        <div className="header-button-last">
          <p>Next</p>
          <img
            src={right}
            alt="icon"
          />
        </div>
      </div>
    </div>
  );
};

const DocNavigator = ({ navActive, setNavActive }) => {
  return (
    <div className="doc-navigator newloan-nav">
      <div className="steps">
        {renderStep(1, "General", navActive, setNavActive)}
        {renderStep(2, "Location", navActive, setNavActive)}
        {renderStep(3, "Asset Info", navActive, setNavActive)}
        {renderStep(4, "Investment", navActive, setNavActive)}
        {renderStep(5, "Sponsor", navActive, setNavActive)}
        {renderStep(6, "Business Plan", navActive, setNavActive)}
        {renderStep(7, "Key Deal Points", navActive, setNavActive)}
        {renderStep(8, "Smart Cotract", navActive, setNavActive)}
        {renderStep(9, "Team", navActive, setNavActive)}
        {renderStep(10, "Contacts", navActive, setNavActive)}
        {renderStep(11, "Disclaimer", navActive, setNavActive)}
        {renderStep(12, "Summary", navActive, setNavActive)}
        <div className="progress-bar loan-progress">
          <span className="indicator"></span>
        </div>
      </div>
    </div>
  );
};

const renderStep = (stepNumber, stepText, navActive, setNavActive) => {
  const isActive = navActive === stepNumber;

  return (
    <div
      key={stepNumber}
      className={`step-cnt ${isActive ? "active" : " "} `}
      onClick={() => setNavActive(stepNumber)}
    >
      <span>{stepText}</span>
      <span className="circle"></span>
    </div>
  );
};

const GeneralSynd = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container syndicate-general">
        <div className="legendimg">
          <img
            src={buildingtwo}
            alt=""
          />
        </div>
        <h2>General</h2>
        <div>
          <GeneralSyndicate />
        </div>
      </div>
    </div>
  );
};

const LocationSynd = () => {
  return (
    <div>
      <div className="mainContainer">
        <div className="docname-container syndicate-general" >
          <div className="legendimg">
            <img
              src={buildingtwo}
              alt=""
            />
          </div>
          <h2>Location</h2>
          <div className="location-container" style={{margin:'0px !important'}}>
            <div className="first-inner-box">
              <img
                src={download}
                alt="download"
              />
              <p>Address</p>
            </div>
            <div className="location-body">
              <div className="addr">
                <InputBox label="ADDRESS" />
              </div>
              <div className="location-body-bottom">
                <div className="locatoin-body-right">
                  <div className="addr-body">
                    <InputBox label="COUNTRY" />
                  </div>
               <div className="city_dropdown">
               <DropDown
                    selectedData={["commercial"]}
                    margin={"16px 0px 0px 0px"}
                    // width={"320px"}
                    label="City"
                    type="text"
                    classDataName={"city_dropdown"}
                    
                  />
               </div>
                  <div className="addr-body">
                    <InputBox label="MARKET" />
                  </div>
                </div>
                <div className="locatoin-body-left">
                 <div className="state_dropdown">
                 <DropDown
                    selectedData={["commercial"]}
                    margin={"16px 0px 0px 0px"}
                    // width={"320px"}
                    label="State"
                    type="text"
                    classDataName={"state_dropdown"}
                    
                  />
                 </div>
                  <div className="addr-body">
                    <InputBox label="ZIP" />
                  </div>
                  <div className="addr-body">
                    <InputBox label="SUBMARKET" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="addressButton">
            <PrimaryButton name={"Check Address"} />
          </div>
        </div>
      </div>
    </div>
  );
};

const AsstInfoSynd = () => {
  const [isClicked, setIsClicked] = useState(1);
  return (
    <div className="mainContainer">
      <div className="docname-container syndicate-general">
        <div className="legendimg">
          <img
            src={buildingtwo}
            alt=""
          />
        </div>
        <h2>Asset Info</h2>
        <div className="switch-btn ">
          <span
            onClick={() => {
              setIsClicked(1);
            }}
            className={isClicked === 1 && "back"}
          >
            Upload a file
          </span>
          <span
            onClick={() => {
              setIsClicked(2);
            }}
            className={isClicked === 2 && "back"}
          >
            Enter manually
          </span>
        </div>
        <div className="Asset-container">
          <div className="first-inner-box">
            <img
              src={download}
              alt="download"
            />
            <p>Brocker Package</p>
          </div>
          <div className="big-box">
            <div className="inner-big-box">
              <img
                src={upload}
                alt=""
              />
              <p className="first-para">
                <InputBox type={"file"} />
              </p>
              <p className="second-para">Supported file formats: PDF. Max file size 250 MB</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InvestmentSynd = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container syndicate-general">
        <div className="legendimg">
          <img
            src={buildingtwo}
            alt=""
          />
        </div>
        <h2>Investment</h2>
        <div>
          <InvestmentSyndicate />
        </div>
      </div>
    </div>
  );
};

const SponsorSynd = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container syndicate-general">
        <div className="legendimg">
          <img
            src={buildingtwo}
            alt=""
          />
        </div>
        <h2>Sponsor Setup</h2>
        <div>
          <SponsorSyndicate />
        </div>
      </div>
    </div>
  );
};

const BusinessSynd = ({ setNavActive }) => {
  return (
    <div className="mainContainer">
      <div className="docname-container syndicate-general">
        <div className="legendimg">
          <img
            src={buildingtwo}
            alt=""
          />
        </div>
        <h2>Business Plan</h2>
        <div>
          <BussinessSyndicate setNavActive={setNavActive} />
        </div>
      </div>
    </div>
  );
};

const KeyDealPointSynd = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container syndicate-general">
        <div className="legendimg">
          <img
            src={buildingtwo}
            alt=""
          />
        </div>
        <h2>Key Deal Points</h2>
        <div className="key_deal-container">
          <div className="first-inner-box">
            <img
              src={download}
              alt="download"
            />
            <p>Deals Points List</p>
          </div>
          <div className="location-body">
            <div className="keydeal-main-container">
              <div className="keydeal-body-left">
                <span>1</span>
              </div>
              <div className="keydeal-body-right">
                <InputBox
                  conCLass={"keydeal-input-B"}
                  label={"Heading"}
                  type="text"
                  placeholder={"Heading Name"}
                />
                <InputBox
                  conCLass={"keydeal-input-B"}
                  label={"Description"}
                  type="text"
                  placeholder={"What does this point mean? Tell more about the details."}
                />
                <div className="keydeal-btn">
                  <div className="save-btn">
                    <img
                      src={savetick}
                      alt=""
                    />
                    <span>Save</span>
                  </div>
                  <div className="delete-btn">
                    <img
                      src={removenew}
                      alt=""
                    />
                    <span>Remove Point</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="keydeal-bottom">
            <img
              src={plusbold}
              alt=""
            />
            <span>Add a new point</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const SmartContractSynd = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container syndicate-general">
        <div className="legendimg">
          <img
            src={buildingtwo}
            alt=""
          />
        </div>
        <h2>Smart Contract Agreement</h2>
        <div>
          <SmartContractSyndicate />
        </div>
      </div>
    </div>
  );
};

const TeamSynd = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container syndicate-general">
        <div className="legendimg">
          <img
            src={buildingtwo}
            alt=""
          />
        </div>
        <h2>Team</h2>
        <div>
          <TeamSyndicate />
        </div>
      </div>
    </div>
  );
};

const ContactSynd = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container syndicate-general">
        <div className="legendimg">
          <img
            src={buildingtwo}
            alt=""
          />
        </div>
        <h2>Contacts</h2>
        <div>
          <Contacts />
        </div>
      </div>
    </div>
  );
};

const DisclaimerSynd = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container syndicate-general">
        <div className="legendimg">
          <img
            src={buildingtwo}
            alt=""
          />
        </div>
        <h2>Disclaimer</h2>
        <div>
          <Disclaimer />
        </div>
      </div>
    </div>
  );
};

const SummarySynd = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container syndicate-general">
        <div className="legendimg">
          <img
            src={buildingtwo}
            alt=""
          />
        </div>
        <h2>Summary</h2>
        <div>
          <SummarySyndicate />
        </div>
      </div>
    </div>
  );
};

const BusinessTwo = ({ setNavActive }) => {
  const [isClicked, setIsClicked] = useState(1);

  const handleLilypd = () => {
    setNavActive(14);
  };
  return (
    <div>
      <div className="mainContainer">
        <div className="docname-container syndicate-general">
          <div className="legendimg">
            <img
              src={buildingtwo}
              alt=""
            />
          </div>
          <h2>Business Plan</h2>
          <div className="business-A-main">
            <div className="financial-container">
              <div className="message-container finish-head">
                <div className="form-heading">
                  <img
                    src={videoplay}
                    alt=""
                  />
                  <span>Webinar Registration</span>
                </div>
                <div>
                  <img
                    src={pen2}
                    alt=""
                  />
                </div>
              </div>
              <div className="business-body">
                <img
                  src={videoimg}
                  alt=""
                />
                <img
                  id="videoslider"
                  src={vidoslider}
                  alt=""
                />
              </div>
              <div className="business-body-bottom">
                <div className="bottom-head-B">
                  <div className="bottom-head-left">
                    <div id="buildimg">
                      <img
                        src={building}
                        alt=""
                      />
                    </div>
                    <div>
                      <h3>NorthGroup Invest Webinar</h3>
                      <span>by </span> <p> Mark Barker • Recorded October 7, 2020</p>
                    </div>
                  </div>
                  <div>
                    <span>26:59</span>
                  </div>
                </div>
                <p>
                  With approximately 1.8 million SF of competitive industrial projects currently under construction, the Callis Road industrial development sets itself apart as one of the only
                  developments in Eastern Nashville catering to mid-size users.
                </p>
              </div>
            </div>
            <div className="switch-btn switch-syndicate">
              <span
                onClick={() => {
                  setIsClicked(1);
                }}
                className={isClicked === 1 && "back"}
              >
                Create document
              </span>
              <span
                onClick={() => {
                  setIsClicked(2);
                }}
                className={isClicked === 2 && "back"}
              >
                Upload document
              </span>
            </div>

            <div className="finish-box-container">
              <div className="message-container finish-head">
                <div className="form-heading">
                  <img
                    src={newdoc}
                    alt=""
                  />
                  <span>Brocker Package</span>
                </div>
                <div
                  onClick={() => {
                    setNavActive(1);
                  }}
                >
                  <img
                    src={pen2}
                    alt=""
                  />
                </div>
              </div>
              <div className="box-content">
                <div className="box-content-left">
                  <div className="building-img">
                    <img
                      src={building2}
                      alt=""
                    />
                    <img
                      id="building"
                      src={buildingB}
                      alt=""
                    />
                    <div className="zoom-level">
                      <img
                        src={minus}
                        alt=""
                      />
                      <span>36%</span>
                      <img
                        src={plus}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="building-bottom">
                    <span>Waltham Wood Apartments</span>
                    <span>8951 Waltham Woods Road, Parkville, MD 21234.</span>
                    <div id="copyright">
                      <div>
                        <p>Broker Package</p>
                        <p>by Real Estate, LLC</p>
                      </div>
                      <div>
                        <span>&copy; 2022</span>
                      </div>
                    </div>
                    <div id="navigator">
                      <img
                        src={next}
                        alt=""
                      />
                      <span>1 of 120</span>
                      <img
                        src={next}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="box-content-right">
                  <span>File Name</span>
                  <p>Waltham Wood Apartments - RE-2020.pdf</p>
                  <span>File Information</span>
                  <img
                    src={filedetail}
                    alt=""
                  />
                  <PrimaryButton
                    name={"Convert to Lilypads structure"}
                    onclick={handleLilypd}
                  />
                </div>
              </div>
            </div>
            <h1>Obtained Asset Information</h1>
            <div className="business-bottom-A">
              <img
                src={businessone}
                alt=""
              />
              <p>Once a ‘Brocker Package’ file is converted, the details will appear here.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BusinessThree = () => {
  const [navActive, setNavActive] = useState(1);
  const [isClicked, setIsClicked] = useState(1);

  return (
    <div>
      <div className="mainContainer">
        <div className="docname-container syndicate-general">
          <div className="legendimg">
            <img
              src={buildingtwo}
              alt=""
            />
          </div>
          <h2>Business Plan</h2>
          <div className="business-A-main">
            <div className="financial-container">
              <div className="message-container finish-head">
                <div className="form-heading">
                  <img
                    src={videoplay}
                    alt=""
                  />
                  <span>Webinar Registration</span>
                </div>
                <div>
                  <img
                    src={pen2}
                    alt=""
                  />
                </div>
              </div>
              <div className="business-body">
                <img
                  src={videoimg}
                  alt=""
                />
                <img
                  id="videoslider"
                  src={vidoslider}
                  alt=""
                />
              </div>
              <div className="business-body-bottom">
                <div className="bottom-head-B">
                  <div className="bottom-head-left">
                    <div id="buildimg">
                      <img
                        src={building}
                        alt=""
                      />
                    </div>
                    <div>
                      <h3>NorthGroup Invest Webinar</h3>
                      <span>by </span> <p> Mark Barker • Recorded October 7, 2020</p>
                    </div>
                  </div>
                  <div>
                    <span>26:59</span>
                  </div>
                </div>
                <p>
                  With approximately 1.8 million SF of competitive industrial projects currently under construction, the Callis Road industrial development sets itself apart as one of the only
                  developments in Eastern Nashville catering to mid-size users.
                </p>
              </div>
            </div>
            <div className="switch-btn switch-business">
              <span
                onClick={() => {
                  setIsClicked(1);
                }}
                className={isClicked === 1 && "back"}
              >
                Create document
              </span>
              <span
                onClick={() => {
                  setIsClicked(2);
                }}
                className={isClicked === 2 && "back"}
              >
                Upload document
              </span>
            </div>

            <div className="finish-box-container">
              <div className="message-container finish-head">
                <div className="form-heading">
                  <img
                    src={newdoc}
                    alt=""
                  />
                  <span>Brocker Package</span>
                </div>
                <div>
                  <img
                    src={pen2}
                    alt=""
                  />
                </div>
              </div>
              <div className="box-content">
                <div className="box-content-left">
                  <div className="building-img">
                    <img
                      src={building2}
                      alt=""
                    />
                    <img
                      id="building"
                      src={buildingB}
                      alt=""
                    />
                    <div className="zoom-level">
                      <img
                        src={minus}
                        alt=""
                      />
                      <span>36%</span>
                      <img
                        src={plus}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="building-bottom">
                    <span>Waltham Wood Apartments</span>
                    <span>8951 Waltham Woods Road, Parkville, MD 21234.</span>
                    <div id="copyright">
                      <div>
                        <p>Broker Package</p>
                        <p>by Real Estate, LLC</p>
                      </div>
                      <div>
                        <span>&copy; 2022</span>
                      </div>
                    </div>
                    <div id="navigator">
                      <img
                        src={next}
                        alt=""
                      />
                      <span>1 of 120</span>
                      <img
                        src={next}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="box-content-right">
                  <span>File Name</span>
                  <p>Waltham Wood Apartments - RE-2020.pdf</p>
                  <span>File Information</span>
                  <img
                    src={filedetail}
                    alt=""
                  />
                  <div className="refine-btn big-btn">
                    <img
                      src={pen2}
                      alt=""
                    />
                    <span>Refine Converted details</span>
                  </div>
                  <div className="discard-btn big-btn">
                    <img
                      src={cancle}
                      alt=""
                    />
                    <span>Discard Convertation</span>
                  </div>
                </div>
              </div>
            </div>
            <h1>Obtained Asset Information</h1>
            <div className="deals-room-nav">
              <span
                className={navActive === 1 && "deals-room-nav-active"}
                onClick={() => {
                  setNavActive(1);
                }}
              >
                General Information
              </span>
              <span
                className={navActive === 2 && "deals-room-nav-active"}
                onClick={() => {
                  setNavActive(2);
                }}
              >
                Financial Information
              </span>
            </div>
            <div>{navActive === 1 && <GeneralInformation />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const GeneralInformation = () => {
  const [navActive, setNavActive] = useState(1);
  const columns = ["Tier", "Hurdle Rate (Up to)", "Type", "Investors", "Promote"];

  const data = [
    [1, "8.0%", "Preferred Return", "100.0%", "0.0%"],
    [2, "Unreturned Capital Contributions", "-", "-", "-"],
    [3, "12.0%", "IRR", "90.0%", "5.0%"],
    [4, "15.0%", "IRR", "95.0%", "10.0%"],
    [5, "Thereafter", "-", "80.0%", "20.0%"],
  ];
  return (
    <div>
      <div className="general-business-container">
        <div className="general-detail">
          <p>
            NEMI Fund III, LLC (dba, Aurora Offices FUND III) is a Texas limited liability company, (the “Company”). The Company will make investments in existing or to-be developed medical office
            buildings (MOB’s), behavioral health facilities, in-patient physical rehabilitation hospitals (IRF’s), medical specialty facilities, ambulatory surgery centers and other healthcare related
            investments. The Company will generally make its investments in the form of preferred equity (meaning common equity with preferred return hurdles) and mezzanine debt into a single purpose
            entity for each Property (collectively, “Investments”). These Investments will generally be subordinate to first-lien construction and mortgage debt in the 50-75% leverage range. The
            business investment strategy will focus on projects in the general geographic areas of Texas and other southern and midwestern states. The Company intends to make investments in ground-up
            developments, strategic value-add, and bolt-on acquisitions and repositions. The Company anticipates a target holding period of 3-5 years per Property.
          </p>
          <div className="deals-room-nav">
            <span
              className={navActive === 1 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(1);
              }}
            >
              Distribution Strategy
            </span>
            <span
              className={navActive === 2 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(2);
              }}
            >
              Capital Calls
            </span>
            <span
              className={navActive === 3 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(3);
              }}
            >
              Organizational Chart
            </span>
            <span
              className={navActive === 4 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(4);
              }}
            >
              Fees
            </span>
            <span
              className={navActive === 5 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(5);
              }}
            >
              Sources & Uses
            </span>
          </div>
        </div>
        <div className="business-table">
          <p>Distributions from Net Cash from Operations</p>
          <CustomTable
            data={data}
            columns={columns}
          />
        </div>
        <div className="business-table">
          <p>Distributions from Net Cash from Operations</p>
          <CustomTable
            data={data}
            columns={columns}
          />
          <p>Distributions are made at the sole discretion of the Sponsor. Please see offering documents for additional detail.</p>
          <p>Distributions are made at the sole discretion of the Sponsor. Please see offering documents for additional detail.</p>
        </div>
      </div>
      <h1>Add Custom Information</h1>
      <div className="financial-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>General Details</p>
        </div>
        <div className="financial-body">
          <div className="custom-detail-container">
            <div className="add-info-man custom-divtwo ">
              <div className="add-info-img">
                <img
                  src={text}
                  alt=""
                />
              </div>
              <span>Add Text</span>
            </div>
            <div className="add-info-man custom-divtwo">
              <div className="add-info-img">
                <img
                  src={image}
                  alt=""
                />
              </div>
              <span>Add Image</span>
            </div>
            <div className="add-info-man custom-divtwo">
              <div className="add-info-img">
                <img
                  src={video}
                  alt=""
                />
              </div>
              <span>Add Video</span>
            </div>
            <div className="add-info-man custom-divtwo">
              <div className="add-info-img">
                <img
                  src={grid}
                  alt=""
                />
              </div>
              <span>Add Table</span>
            </div>
            <div className="add-info-man custom-divtwo">
              <div className="add-info-img">
                <img
                  src={addsec}
                  alt=""
                />
              </div>
              <span>Add Section</span>
            </div>
          </div>
        </div>
      </div>
      <h1>Additional Documents</h1>
      <div className="financial-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Documents</p>
        </div>
        <div className="upload-doc-body">
          <div className="select-doc">
            <img
              src={upload}
              alt=""
            />
            <InputBox type={"file"} />

            <p>Supported file formats: PDF. Max file size 250 MB</p>
          </div>
          <div className="progress-cont">
            <div className="uploading-file">
              <div className="file-name">
                <div>
                  <img
                    src={pdf}
                    alt=""
                  />
                  <span>Brochure2.pdf</span>
                </div>
                <span>1,5MB of 3,6MB</span>
              </div>
              <div className="progress-bar">
                <span className="progress"></span>
              </div>
            </div>
            <img
              src={navclose}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// export const CustomTable = ({data, columns, classNames}) => {
//   const defaultClass = "custom-table";
//   const combinedClassNames = classNames ? `${defaultClass} ${classNames}` : defaultClass;

//   return (
//     <TableContainer component={Paper}>
//       <Table
//         className={combinedClassNames}
//         aria-label="custom table"
//       >
//         <TableHead>
//           <TableRow>
//             {columns.map((column, index) => (
//               <TableCell
//                 key={index}
//                 className={`custom-head-cell ${classNames ? `${classNames}-head-cell` : ""}`}
//               >
//                 {column}
//               </TableCell>
//             ))}
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {data.map((row, rowIndex) => (
//             <TableRow
//               key={rowIndex}
//               sx={{"&:last-child td, &:last-child th": {border: 0}}}
//             >
//               {row.map((cell, cellIndex) => (
//                 <TableCell
//                   key={cellIndex}
//                   align="left"
//                   className={`custom-body-cell ${classNames ? `${classNames}-body-cell` : ""}`}
//                 >
//                   {cell}
//                 </TableCell>
//               ))}
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// };
export const CustomTable = ({ data, columns, classNames, setIsClicked }) => {
  const defaultClass = "custom-table";
  const combinedClassNames = classNames ? `${defaultClass} ${classNames}` : defaultClass;

  return (
    <TableContainer component={Paper}>
      <Table
        className={combinedClassNames}
        aria-label="custom table"
      >
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                className={`custom-head-cell ${classNames ? `${classNames}-head-cell` : ""}`}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {row.map((cell, cellIndex) => (
                <TableCell
                  key={cellIndex}
                  align="left"
                  className={`custom-body-cell ${classNames ? `${classNames}-body-cell` : ""}`}
                >
                  {/* Check the type of the cell content and render accordingly */}
                  {typeof cell === 'function' ? cell() : (
                    typeof cell === 'string' && cell.endsWith('.svg') ? (
                      <img src={cell} alt={`Image ${rowIndex}-${cellIndex}`} />
                    ) : (
                      cell
                    )
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};