import React from "react";
import TreasurySidebar from "./components/TreasurySidebar";
import FilterForHeader from "./components/FilterForHeader";

const LilypadTreasury = () => {
  return (
    <div>
      <div style={{position: "absolute", top: "90px", left: "300px"}}>
        <FilterForHeader />
      </div>
      <TreasurySidebar />
    </div>
  );
};

export default LilypadTreasury;