import {useState} from "react";
import signal from "../../assets/dealsroom/svg/signal.svg";
import messages from "../../assets/dealsroom/svg/messages.svg";
import phone from "../../assets/dealsroom/svg/phone.svg";
import location from "../../assets/dealsroom/svg/location.svg";
import profileicon from "../../assets/dealsroom/svg/profileicon.svg";
import profilearrow from "../../assets/dealsroom/svg/profilearrow.svg";
import greendot from "../../assets/dealsroom/svg/greendot.svg";
import loanresponse from "../../assets/dealsroom/svg/loanresponse.svg";
import seemore from "../../assets/dealsroom/svg/seemore.svg";
import filter from "../../assets/dealsroom/svg/filter.svg";
import plusbtn from "../../assets/dealsroom/svg/plusbtn.svg";
import pdf from "../../assets/dealsroom/svg/pdf.svg";
import sign from "../../assets/dealsroom/svg/sign.svg";
import notsign from "../../assets/dealsroom/svg/notsign.svg";
import importnat from "../../assets/dealsroom/svg/important.svg";
import clock from "../../assets/dealsroom/svg/clock.svg";
import action from "../../assets/dealsroom/svg/action.svg";
import eye from "../../assets/dealsroom/svg/eye.svg";
import linktoken from "../../assets/dealsroom/svg/linktoken.svg";
import save from "../../assets/dealsroom/svg/save.svg";
import print from "../../assets/dealsroom/svg/print.svg";
import copy from "../../assets/dealsroom/svg/copy.svg";
import prevnav from "../../assets/dealsroom/svg/prevnav.svg";
import fwdnav from "../../assets/dealsroom/svg/fwdnav.svg";
import tokenimg from "../../assets/dealsroom/svg/tokenimg.svg";
import navarrow from "../../assets/dealsroom/svg/navarrow.svg";
import medal from "../../assets/dealsroom/svg/medal.svg";
import arrowright from "../../assets/dealsroom/svg/arrowright.svg";
import imgupload from "../../assets/dealsroom/svg/imgupload.svg";
import attachment from "../../assets/dealsroom/svg/attachment.svg";
import emoji from "../../assets/dealsroom/svg/emoji.svg";
import women from "../../assets/dealsroom/images/women.png";
import women1 from "../../assets/dealsroom/images/women1.png";
import chatimg from "../../assets/dealsroom/images/chatimg.png";
import {Link} from "react-router-dom";
import profilePic from "../../assets/svg/commentProfile.png";
import profilePic2 from "../../assets/svg/profilebig.png";
import lenderHeader from "../../assets/svg/Frame 1196.png";
const LenderResponsesDealRoom = (setLenderResponseTab) => {
  const [navActive, setNavActive] = useState(1);
  const [activeContact, setActiveContact] = useState(0);
  return (
    <div className="deals-room-cente asset-center-con">
      <div className="deals-room-center-header">
        <div className="left">Deal Room</div>
        <div className="right">
          <img
            src={lenderHeader}
            alt=""
          />
        </div>
      </div>

      <div className="deals-room-center-del lender_responses">
        <div className="left">
          <div className="search_section">
            <div className="search_bar">
              <input
                type="text"
                placeholder="Search Lenders"
              />
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 18.586L18.586 20L12.929 14.343L14.343 12.929L20 18.586ZM7 12C4.243 12 2 9.757 2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12ZM7 0C3.134 0 0 3.134 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_13820_230713"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20 18.586L18.586 20L12.929 14.343L14.343 12.929L20 18.586ZM7 12C4.243 12 2 9.757 2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12ZM7 0C3.134 0 0 3.134 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_13820_230713)">
                  <rect
                    x="-1"
                    y="-1"
                    width="22"
                    height="22"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
            </div>
            <div className="letter">
              <p>A</p>
            </div>
            <div
              className={"contact " + `${activeContact === 0 ? "active" : ""} `}
              onClick={() => setActiveContact(0)}
            >
              <img
                src={profilePic}
                alt=""
              />
              <div className="text_wrapper">
                <p>Adriana Boyd</p>
                <span>
                  <img
                    src={greendot}
                    alt=""
                  />
                  Active
                </span>
              </div>
            </div>
            <div
              className={"contact " + `${activeContact === 1 ? "active" : ""} `}
              onClick={() => setActiveContact(1)}
            >
              <img
                src={profilePic}
                alt=""
              />
              <div className="text_wrapper">
                <p>Adriana Boyd</p>
                <span>
                  <img
                    src={greendot}
                    alt=""
                  />
                  Active
                </span>
              </div>
            </div>
            <div
              className={"contact " + `${activeContact === 2 ? "active" : ""} `}
              onClick={() => setActiveContact(2)}
            >
              <img
                src={profilePic}
                alt=""
              />
              <div className="text_wrapper">
                <p>Adriana Boyd</p>
                <span>
                  <img
                    src={greendot}
                    alt=""
                  />
                  Active
                </span>
              </div>
            </div>
            <div
              className={"contact " + `${activeContact === 3 ? "active" : ""} `}
              onClick={() => setActiveContact(3)}
            >
              <img
                src={profilePic}
                alt=""
              />
              <div className="text_wrapper">
                <p>Adriana Boyd</p>
                <span>
                  <img
                    src={greendot}
                    alt=""
                  />
                  Active
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="deals-room-heading">
            <div className="heading_left">
              <div className="deals-room-heading-left">
                <img
                  src={profilePic2}
                  alt=""
                />
              </div>
              <div className="deals-room-heading-right">
                <div className="deals-room-heading-right-top">
                  <span>Top Estate</span>
                  <img
                    src={greendot}
                    alt=""
                  />
                </div>
                <div className="deals-room-heading-right-detail">
                  <p>Investmnet firm</p>
                </div>
                <div className="deals-room-heading-right-bottom">
                  <div className="deals-room-heading-right-text">
                    <div className="dls-room-head-back">
                      <span>68</span>
                    </div>
                    <span>Assets</span>
                  </div>
                  <div className="deals-room-heading-right-img">
                    <img
                      src={signal}
                      alt=""
                    />
                    <span>80% Match</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="heading_right">
              <div className="button">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_13820_358868)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.95773 0C3.99973 0 -0.735272 5.127 0.0947279 11.125C0.623728 14.947 3.52273 18.156 7.31973 19.168C8.73373 19.545 10.1937 19.625 11.6597 19.372C12.8837 19.16 14.1397 19.255 15.3387 19.555L16.7957 19.919C18.6847 20.392 20.4007 18.711 19.9177 16.859C19.9177 16.859 19.6477 15.823 19.6397 15.79C19.3377 14.63 19.2727 13.405 19.5847 12.248C19.9707 10.822 20.0357 9.26897 19.6887 7.65997C18.7707 3.41497 14.8727 0 9.95773 0ZM9.95773 2C13.9097 2 17.0047 4.71001 17.7337 8.08301C18.0007 9.31901 17.9737 10.544 17.6547 11.726C16.3047 16.718 20.2517 18.722 15.8237 17.614C14.3507 17.246 12.8157 17.142 11.3187 17.401C10.1637 17.601 8.99373 17.544 7.83473 17.236C4.80773 16.429 2.49273 13.863 2.07573 10.851C1.40473 5.99701 5.26873 2 9.95773 2Z"
                      fill="#9EA0A5"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_13820_358868">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>

                <p>Add Dispute</p>
              </div>
              <div className="button">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.9952 15.4689C13.9952 15.0765 12.9726 15.0805 12.5831 15.4719L11.8521 16.2084C11.5375 16.5245 10.9992 16.3028 10.9992 15.8552V7.03153C10.9992 6.48162 10.6007 6.03708 10.0555 6.03005C9.50819 6.03708 9.00187 6.48162 9.00187 7.03153V15.8552C9.00187 16.3028 8.46359 16.5265 8.14901 16.2104L7.41799 15.2672C7.02851 14.8758 6.40634 15.0594 6.01686 15.0594H6.01087C5.62139 16.0629 5.61839 16.2947 6.00887 16.6861L8.58942 19.3855C9.36938 20.1692 10.6337 20.2214 11.4136 19.4376L13.9952 16.8687C14.3857 16.4764 13.9952 15.8542 13.9952 15.4628V15.4689ZM10.0445 6.02805C10.0485 6.02805 10.0515 6.03005 10.0555 6.03005C10.0595 6.03005 10.0625 6.02805 10.0664 6.02805H10.0445ZM13.9952 14.0559C13.4439 14.0559 12.9965 13.6064 12.9965 13.0525C12.9965 12.4985 13.4439 12.049 13.9952 12.049H14.888C19.1423 12.049 18.8337 5.89559 14.894 6.03808C13.5478 -0.665212 3.21261 1.75419 5.24389 8.47756C3.16867 6.8198 0.677995 10.0279 2.79916 11.6115C4.29816 12.7313 7.00454 11.199 7.00454 13.0525C7.00454 14.3148 5.57845 14.0559 4.20928 14.0559C0.877728 14.0559 -1.17553 10.4835 0.731923 7.75204C1.6437 6.4475 3.07379 6.12539 3.07379 6.12539C3.73291 0.994555 9.56312 -1.71486 13.8814 1.18923C15.7349 2.43657 16.4379 4.2589 16.4379 4.2589C17.5275 4.59206 18.4622 5.28246 19.0984 6.19663C21.2405 9.27333 19.5757 14.0559 13.9952 14.0559Z"
                    fill="white"
                    fill-opacity="0.01"
                  />
                  <mask
                    id="mask0_13820_358876"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.9952 15.4689C13.9952 15.0765 12.9726 15.0805 12.5831 15.4719L11.8521 16.2084C11.5375 16.5245 10.9992 16.3028 10.9992 15.8552V7.03153C10.9992 6.48162 10.6007 6.03708 10.0555 6.03005C9.50819 6.03708 9.00187 6.48162 9.00187 7.03153V15.8552C9.00187 16.3028 8.46359 16.5265 8.14901 16.2104L7.41799 15.2672C7.02851 14.8758 6.40634 15.0594 6.01686 15.0594H6.01087C5.62139 16.0629 5.61839 16.2947 6.00887 16.6861L8.58942 19.3855C9.36938 20.1692 10.6337 20.2214 11.4136 19.4376L13.9952 16.8687C14.3857 16.4764 13.9952 15.8542 13.9952 15.4628V15.4689ZM10.0445 6.02805C10.0485 6.02805 10.0515 6.03005 10.0555 6.03005C10.0595 6.03005 10.0625 6.02805 10.0664 6.02805H10.0445ZM13.9952 14.0559C13.4439 14.0559 12.9965 13.6064 12.9965 13.0525C12.9965 12.4985 13.4439 12.049 13.9952 12.049H14.888C19.1423 12.049 18.8337 5.89559 14.894 6.03808C13.5478 -0.665212 3.21261 1.75419 5.24389 8.47756C3.16867 6.8198 0.677995 10.0279 2.79916 11.6115C4.29816 12.7313 7.00454 11.199 7.00454 13.0525C7.00454 14.3148 5.57845 14.0559 4.20928 14.0559C0.877728 14.0559 -1.17553 10.4835 0.731923 7.75204C1.6437 6.4475 3.07379 6.12539 3.07379 6.12539C3.73291 0.994555 9.56312 -1.71486 13.8814 1.18923C15.7349 2.43657 16.4379 4.2589 16.4379 4.2589C17.5275 4.59206 18.4622 5.28246 19.0984 6.19663C21.2405 9.27333 19.5757 14.0559 13.9952 14.0559Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_13820_358876)">
                    <rect
                      x="-1"
                      y="-1"
                      width="22"
                      height="22"
                      fill="#9EA0A5"
                    />
                  </g>
                </svg>
                <p>Pay Off Loan</p>
              </div>
            </div>
          </div>
          <div className="deals-room-stats">
            <div className="statsbox">
              <h2>
                $350,000 <span></span>
              </h2>
              <p>borrowed</p>
            </div>
            <div className="statsbox">
              <h2>
                $184,000 <span>62%</span>
              </h2>
              <p>paid</p>
            </div>
            <div className="statsbox">
              <h2>
                $166,000 <span>38%</span>
              </h2>
              <p>left</p>
            </div>
            <div className="statsbox">
              <h2>
                12 <span>months</span>
              </h2>
              <p>Loan term</p>
            </div>
          </div>
          <div className="deals-room-nav">
            <span
              className={navActive === 1 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(1);
              }}
            >
              Lender Profile
            </span>
            <span
              className={navActive === 2 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(2);
              }}
            >
              Documents
            </span>
            <span
              className={navActive === 3 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(3);
              }}
            >
              Tokens
            </span>
            <span
              className={navActive === 4 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(4);
              }}
            >
              Lender Activity
            </span>
            <span
              className={navActive === 5 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(5);
              }}
            >
              Communication
            </span>
          </div>
          {navActive === 1 && <SellerProfile />}
          {navActive === 2 && <Documents />}
          {navActive === 3 && <Token />}
          {navActive === 4 && <SellerActivity />}
          {navActive === 5 && <Communication />}
        </div>
      </div>
    </div>
  );
};

export default LenderResponsesDealRoom;

const SellerProfile = () => {
  return (
    <div>
      <div className="deals-room-nav-detail">
        <span>basic details</span>
        <div className="deals-room-nav-details">
          <div className="deals-room-nav-contact-details">
            <img
              src={messages}
              alt=""
            />
            <span>me@angenewton.co</span>
          </div>
          <div className="deals-room-nav-contact-details">
            <img
              src={phone}
              alt=""
            />
            <span>+1 (200) 7887 665 </span>
          </div>
          <div className="deals-room-nav-contact-details">
            <img
              src={location}
              alt=""
            />
            <span>United States</span>
          </div>
        </div>
      </div>
      <div className="deals-room-nav-det-btn">
        <img
          src={profileicon}
          alt=""
        />
        <span>Open Profile Page</span>
        <img
          src={profilearrow}
          alt=""
        />
      </div>
      <div className="deals-room-nav-bio">
        <span>Bio</span>
        <p>
          Working in automotive quality management for over 3 years. A passionate traveller, loves to spend time with her dog. Wine taster. Biggest dream is to travel the world with all the money I
          make from renting building to my dearest customers who pay well.
        </p>
        <span>Tags</span>
        <div className="deals-room-nav-bio-button">
          <div className="deals-room-nav-bio-btn bio-btn-first">
            <span>Office Management</span>
          </div>
          <div className="deals-room-nav-bio-btn bio-btn-second">
            <span>Commercial</span>
          </div>
          <div className="deals-room-nav-bio-btn bio-btn-third">
            <span>Development</span>
          </div>
        </div>
      </div>
      <div className="deals-room-nav-fact-box">
        <div className="deals-room-nav-fact">
          <p>Assets in Portfolio</p>
          <p>159</p>
          <p>12% more than average</p>
        </div>
        <div className="deals-room-nav-fact">
          <p>Average Price</p>
          <p>$2,074,705</p>
          <p>52% more than average</p>
        </div>
        <div className="deals-room-nav-fact">
          <p>Assets For sell</p>
          <p>15</p>
          <p>10% more than average</p>
        </div>
        <div className="deals-room-nav-fact">
          <p>Invested</p>
          <p>$5,080,000</p>
          <p>52% more than average</p>
        </div>
        <div className="deals-room-nav-fact">
          <p>Average Investment</p>
          <p>$74,705</p>
          <p>52% more than average</p>
        </div>
        <div className="deals-room-nav-fact">
          <p>MAX investment</p>
          <p>$1,000,000</p>
          <p>52% more than average</p>
        </div>
      </div>
      <div className="deals-room-nav-bio-cond">
        <div className="deals-room-nav-bio-cond-top">
          <p>Conditions</p>
          <div className="deals-room-nav-bio-cond-top-left">
            <span>See More</span>
            <img
              src={seemore}
              alt=""
            />
          </div>
        </div>
        <p>
          Working in automotive quality management for over 3 years. A passionate traveller, loves to spend time with her dog. Wine taster. Biggest dream is to travel the world with all the money I
          make from renting building to my dearest customers who pay well.{" "}
        </p>
      </div>
    </div>
  );
};

const Documents = () => {
  return (
    <div>
      <div className="deals-room-doc">
        <div className="deals-room-doc-nav">
          <div className="deals-room-doc-nav-left">
            <div className="deals-room-doc-nav-left-all doc-nav">
              <p>All</p>
              <span>4</span>
            </div>
            <div className="delas-room-doc-nav-inbox doc-nav">
              <div className="deals-room-doc-nav-count">
                <span>1</span>
              </div>
              <p>Inbox</p>
              <span>32</span>
            </div>
            <div className="delas-room-doc-nav-sent doc-nav">
              <p>Sent</p>
              <span>4</span>
            </div>
            <div className="delas-room-doc-nav-draft doc-nav">
              <p>Drafts</p>
              <span>36</span>
            </div>
          </div>
          <div className="deals-room-doc-nav-right">
            <div className="deals-room-doc-nav-filt">
              <img
                src={filter}
                alt=""
              />
              <span>Filter</span>
            </div>
            <Link to={"/dealsroom/adddoc"}>
              <div className="deals-room-doc-nav-addmore">
                <img
                  src={plusbtn}
                  alt=""
                />
                <span>Add Document</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="deals-room-doc-nav-det">
          <div className="deals-room-doc-nav-head">
            <div className="deals-room-doc-nav-head-first">
              <input type="checkbox" />
              <span>Document</span>
            </div>
            <span>Date Added/Updated</span>
            <span>my sign</span>
            <span>Investor's Sign</span>
            {/* <span>Originated by</span>
            <span>tags</span> */}
            <span>Action</span>
          </div>
        </div>
        {/* DOC UPLOADING COMPONENT LOADING */}
        <DocUpload />
        <DocUpload />
        <DocUpload />
        <DocUpload />
        <DocUpload />
        <DocUpload />
        <div className="deals-room-doc-page-nav">
          <div className="page-nav-cont">
            <img
              src={prevnav}
              alt=""
            />
            <span>2 of 10</span>
            <img
              src={fwdnav}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const DocUpload = () => {
  return (
    <div>
      <div className="deals-room-doc-upload">
        <div className="deals-room-doc-pdf">
          <input type="checkbox" />
          <img
            src={pdf}
            alt=""
          />
          <span>Agreement.pdf</span>
        </div>
        <div className="deals-room-doc-date">
          <span>5th of Nov, 18</span>
          <img
            src={clock}
            alt=""
          />
          <span>Nov. 24, 2018</span>
        </div>
        <div className="deals-room-doc-sign">
          <img
            src={sign}
            alt=""
          />
          <span>Signed</span>
        </div>
        <div className="deals-room-doc-import">
          <img
            src={importnat}
            alt=""
          />
          <span>Edited</span>
        </div>
        {/* <div className="deals-room-doc-org">
          <div className="delas-room-doc-org-img">
            <img
              id="womenimg"
              src={women}
              alt=""
            />
          </div>
          <div className="deals-room-doc-org-name">
            <p>Paityn Press</p>
            <span>InvestGroup</span>
          </div>
        </div>
        <div className="deals-room-doc-tag">
          <span>Tax</span>
          <span>Rent Roll</span>
        </div> */}
        <div className="deals-room-doc-action">
          <img
            src={eye}
            alt=""
          />
          <img
            src={action}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const Token = () => {
  return (
    <div>
      <div className="token-container">
        <div className="token-head">
          <div className="token-head-title">
            <h2>Token</h2>
          </div>
          <div className="token-head-btn">
            <img
              src={linktoken}
              alt=""
            />
            <span>Transfer another Token</span>
          </div>
        </div>
        <div className="token-link">
          <span>0x34EH887oseno8vnh89h98h9h9616816e1bf6df0#$5gh7&H*%^&*()(*guiqncnw</span>
        </div>
        <div className="token-option">
          <div className="token-save token-opt token-line">
            <img
              src={save}
              alt=""
            />
            <span>Save</span>
          </div>
          <div className="token-print token-opt token-line">
            <img
              src={print}
              alt=""
            />
            <span>Print</span>
          </div>
          <div className="token-copy token-opt">
            <img
              src={copy}
              alt=""
            />
            <span>Copy to Clipboard</span>
          </div>
        </div>
        <div className="token-frame">
          <img
            src={tokenimg}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
const SellerActivity = () => {
  return (
    <div>
      <div className="seller-activity-container">
        <div className="seller-card-container">
          <div className="seller-activity">
            <img
              src={greendot}
              alt=""
            />
          </div>
          <div className="seller-img">
            <img
              id="womenimg"
              src={women}
              alt=""
            />
          </div>
          <div className="seller-details">
            <p>
              Isabelle Neal <span>from Investor’s team</span>
            </p>
            <p>Uploaded 3 documents</p>
            <div className="seller-det-doc">
              <img
                id="pdf"
                src={pdf}
                alt=""
              />
              <img
                id="pdf"
                src={pdf}
                alt=""
              />
              <img
                id="pdf"
                src={pdf}
                alt=""
              />
            </div>
            <p id="date">4 days ago, on August 15, 12:15am</p>
          </div>
        </div>
        <div className="seller-card-container">
          <div className="seller-activity">
            <img
              src={greendot}
              alt=""
            />
          </div>
          <div className="seller-img">
            <img
              id="womenimg"
              src={women1}
              alt=""
            />
          </div>
          <div className="seller-details">
            <p>Jose Perkins</p>
            <p>at “Agreement C.pdf” document</p>
            <p>Suggested 1 edit</p>
            <div className="seller-det-invest">
              <div className="seller-invst invst-card">
                <span id="time">Before</span>
                <span id="min">Minimum Investment</span>
                <span>$15,500</span>
              </div>
              <img
                src={navarrow}
                alt=""
              />
              <div className="seller-invst-now invst-card">
                <span id="time"> Now</span>
                <span id="min">Minimum Investment</span>
                <span>$25,000</span>
              </div>
            </div>
            <p id="date">8 days ago, on August 11, 10:15am</p>
          </div>
        </div>
        <div className="seller-card-container">
          <div className="seller-activity">
            <img
              id="imgmedal"
              src={medal}
              alt=""
            />
          </div>
          <div className="seller-img">
            <img
              id="womenimg"
              src={women}
              alt=""
            />
          </div>
          <div className="seller-details">
            <p>
              Gregory Ray <span> from Investor’s team</span>
            </p>
            <p>commented at “Agreement C.pdf” document</p>

            <div className="seller-comment">
              <p>
                Can I Invest on behalf on my friend, I’ll provide my docs and Investor Certificate, but he’ll be a real Investor (100% his money)? If not can I borrow his money and then invest? Also,
                can I invest $48K, not $45K or $50?
              </p>
            </div>
            <div className="seller-cmt-date">
              <p id="date">4 days ago, on August 15, 12:15am</p>
              <div className="seller-cmt-reply">
                <span>Reply</span>
                <img
                  src={arrowright}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="seller-card-container">
          <div className="seller-activity">
            <img
              id="imgmedal"
              src={medal}
              alt=""
            />
          </div>
          <div className="seller-img">
            <img
              id="womenimg"
              src={women}
              alt=""
            />
          </div>
          <div className="seller-details">
            <div className="seller-detail-top">
              <div className="seller-det-name">
                <p>
                  Gregory Ray <span> from Investor’s team</span>
                </p>
                <p>commented at “Agreement C.pdf” document</p>
              </div>
              <div className="seller-detail-time">
                <span>1 month ago</span>
              </div>
            </div>
            <div className="seller-comment">
              <p>
                Can I Invest on behalf on my friend, I’ll provide my docs and Investor Certificate, but he’ll be a real Investor (100% his money)? If not can I borrow his money and then invest? Also,
                can I invest $48K, not $45K or $50?
              </p>
            </div>
            <div className="seller-cmt-date">
              <p id="date">4 days ago, on August 15, 12:15am</p>
              <div className="seller-cmt-reply">
                <span>Reply</span>
                <img
                  src={arrowright}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Communication = () => {
  return (
    <div>
      <div className="seller-chat-container">
        <div className="seller-chat-activity">
          <div className="seller-chat-activity-right">
            <div className="chat-user">
              <img
                id="chatimg"
                src={women}
                alt=""
              />
              <span>Norman Hernandez</span>
              <span>05:10 AM</span>
            </div>
            <div className="chat-box-right">
              <p>
                I always think teenagers must hate my place as there is nothing to do but relax and enjoy the sea view and sunsets and read books which is not a very teenagery thing to do (my idea of
                paradise when I was a teen though). No shops or even a park to hang out in. I had one lady stay with her young daughter who wanted to come back at Xmas for a month with husband and
                teenage daughter from over 1500km away but the teen nixed it as the oldest girls who live here are only about 10.
              </p>
            </div>
          </div>
          <div className="seller-chat-activity-left">
            <div className="chat-user">
              <img
                id="chatimg"
                src={women}
                alt=""
              />
              <span>Norman Hernandez</span>
              <span>05:10 AM</span>
            </div>
            <div className="chat-box-left chat-color-unread">
              <p>Hey y’all! We own Hidden Lake Forest which is in a private lake community. We wanted to see how others handle a waiver of liability to use of Kayaks, boats, swimming. 🛶🏖</p>
              <img
                src={action}
                alt=""
              />
            </div>
          </div>
          <div className="seller-chat-activity-right">
            <div className="chat-user">
              <img
                id="chatimg"
                src={women}
                alt=""
              />
              <span>Norman Hernandez</span>
              <span>05:10 AM</span>
            </div>
            <div className="chat-box-right">
              <p>
                I always think teenagers must hate my place as there is nothing to do but relax and enjoy the sea view and sunsets and read books which is not a very teenagery thing to do (my idea of
                paradise when I was a teen though). No shops or even a park to hang out in. I had one lady stay with her young daughter who wanted to come back at Xmas for a month with husband and
                teenage daughter from over 1500km away but the teen nixed it as the oldest girls who live here are only about 10.
              </p>
            </div>
            <div className="chat-box-img-upload">
              <img
                id="uploadimg"
                src={chatimg}
                alt=""
              />
              <img
                id="uploadimg"
                src={chatimg}
                alt=""
              />
              <img
                id="uploadimg"
                src={chatimg}
                alt=""
              />
            </div>
          </div>
          <div className="seller-chat-activity-left">
            <div className="chat-user">
              <img
                id="chatimg"
                src={women}
                alt=""
              />
              <span>Norman Hernandez</span>
              <span>05:10 AM</span>
            </div>
            <div className="chat-box-left chat-color-read">
              <p>Hey y’all! We own Hidden Lake Forest which is in a private lake community. We wanted to see how others handle a waiver of liability to use of Kayaks, boats, swimming. 🛶🏖</p>
              <img
                src={action}
                alt=""
              />
            </div>
          </div>
          <div className="seller-chat-activity-left">
            <div className="chat-user user-active">
              <img
                id="chatimg"
                src={women}
                alt=""
              />
              <span>Norman Hernandez</span>
              <span>Typing...</span>
            </div>
          </div>
        </div>
        <div className="seller-chat-box">
          <input
            id="input-box"
            type="text"
            placeholder="Type a message..."
          />
          <div className="chat-attacment">
            <img
              src={imgupload}
              alt=""
            />
            <img
              src={attachment}
              alt=""
            />
            <img
              src={emoji}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};
