import React from "react";
import {tailspin} from "ldrs";

tailspin.register();
export const Spinner = ({size = "40", stroke = "5", speed = "0.9", color = "black"}) => {
  return (
    <div>
      <l-tailspin
        size={size}
        stroke={stroke}
        speed={speed}
        color={color}
      ></l-tailspin>
    </div>
  );
};
