import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import Splide from "@splidejs/splide";
import "@splidejs/splide/dist/css/splide.min.css";
import "./CustomSlider.scss";
import defaultImage from "../../../assets/portfolio/images/2796258.jpg";
import uploadicon from "../../../assets/Upoad_images.svg";

const CustomSlider = ({images = [], totalPhotos, totalVideos, totalVRViews, onImageUpload, selectedItem}) => {
  const [imageList, setImageList] = useState(images.length > 0 ? images : [defaultImage]);
  const [showPopup, setShowPopup] = useState(false);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);
  const mainSliderRef = useRef(null);

  useEffect(() => {
    let mainSlider;
    let thumbnailSlider;

    if (imageList.length > 0) {
      mainSlider = new Splide("#main-slider", {
        pagination: false,
      });

      thumbnailSlider = new Splide("#thumbnail-slider", {
        fixedWidth: 70,
        fixedHeight: 70,
        isNavigation: true,
        gap: 10,
        focus: "center",
        pagination: false,
        cover: true,
        breakpoints: {
          600: {
            fixedWidth: 50,
            fixedHeight: 50,
          },
        },
      }).mount();

      mainSlider.sync(thumbnailSlider).mount();
      mainSliderRef.current = mainSlider;
    }

    return () => {
      if (mainSlider) {
        mainSlider.destroy();
      }
      if (thumbnailSlider) {
        thumbnailSlider.destroy();
      }
    };
  }, [imageList]);

  const handleFileChange = async (files) => {
    if (!selectedItem || !selectedItem._id) {

      return;
    }

    try {
      setUploading(true);
      await uploadImages(files, selectedItem._id);
    } catch (error) {

      setUploading(false);
    }
  };

  const uploadImages = async (files, assetId) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("image", file);
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const response = await axios.post(`/api/portfolio/upload-image?assetId=${assetId}`, formData, config);

      if (response.data.imageUrls && Array.isArray(response.data.imageUrls)) {
        setImageList((prevImages) => [...prevImages, ...response.data.imageUrls]);
        if (typeof onImageUpload === "function") {
          onImageUpload(response.data.imageUrls);
        }
      } 

      setUploading(false);
      closePopup();
    } catch (error) {
      setUploading(false);
    }
  };

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="custom-slider">
      <label
        htmlFor="fileInput"
        className="upload-button"
        onClick={openPopup}
        style={{cursor: "pointer"}}
      >
        Upload Image
      </label>
      {imageList.length > 0 ? (
        <>
          <div
            id="main-slider"
            className="splide"
          >
            <div className="splide__track">
              <ul className="splide__list">
                {imageList.map((image, index) => (
                  <li
                    className="splide__slide"
                    key={index}
                  >
                    <img
                      src={image}
                      alt={`Slide ${index}`}
                    />
                    <div className="image-overlay"></div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div
            id="thumbnail-slider"
            className="splide"
          >
            <div className="splide__track">
              <ul className="splide__list">
                {imageList?.map((image, index) => (
                  <li
                    className="splide__slide thumbnail"
                    key={index}
                  >
                    <img
                      src={image}
                      alt={`Thumbnail ${index}`}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            id="main-slider"
            className="splide"
          >
            <div className="splide__track">
              <ul className="splide__list">
                <li className="splide__slide">
                  <img
                    src={defaultImage}
                    alt="Default Image"
                  />
                </li>
              </ul>
            </div>
          </div>

          <div
            id="thumbnail-slider"
            className="splide"
          >
            <div className="splide__track">
              <ul className="splide__list">
                <li className="splide__slide thumbnail">
                  <img
                    src={defaultImage}
                    alt="Default Thumbnail"
                  />
                </li>
              </ul>
            </div>
          </div>
        </>
      )}

      <div className="CustomSlider-thumb-nav">
        <button>{totalPhotos} Photos</button>
        <button>{totalVideos} Videos</button>
        <button>{totalVRViews} VR Views</button>
      </div>

      {showPopup && (
        <Popup
          fileInputRef={fileInputRef}
          handleFileChange={handleFileChange}
          uploading={uploading}
          closePopup={closePopup}
        />
      )}
    </div>
  );
};

const Popup = ({fileInputRef, handleFileChange, uploading, closePopup}) => {
  const [imagePreviews, setImagePreviews] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFilePreview = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);

    if (files.length > 0) {
      const readerPromises = files.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({file, previewUrl: reader.result});
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(readerPromises).then((results) => {
        const previews = results.map((result) => result.previewUrl);
        setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
      });
    }
  };

  const handleUploadClick = () => {
    handleFileChange(selectedFiles);
  };

  return (
    <div className="popup-overlay">
      <div className="popup_imageupload">
        <div className="popup-content">
          <h2>File Upload!</h2>
          <div className="upload-container-popup">
            <input
              id="fileInput"
              name="image"
              ref={fileInputRef}
              type="file"
              accept="image/*"
              style={{display: "none"}}
              onChange={handleFilePreview}
              multiple
            />
            <label
              htmlFor="fileInput"
              className="upload-placeholder"
            >
              <img
                src={uploadicon}
                alt="Upload Icon"
                className="upload_icon"
              />
              <p>Drag and drop files here or select a file from your computer</p>
              <small>File type: doc, pdf, types of images</small>
            </label>
          </div>
          {uploading && <p>Uploading...</p>}
          {imagePreviews.length > 0 && (
            <div className="image-preview-container">
              {imagePreviews.map((preview, index) => (
                <div
                  key={index}
                  className="image_preview"
                >
                  <img
                    src={preview}
                    alt={`Preview ${index}`}
                  />
                </div>
              ))}
            </div>
          )}
          <div className="button-container">
            <button
              className="onclickupload"
              onClick={closePopup}
            >
              Close
            </button>
            <button
              className="onclickupload"
              onClick={handleUploadClick}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomSlider;
