import React from "react";
import botImg1 from "../../assets/acqusition/img/bottompic1.png";
import botImg2 from "../../assets/acqusition/img/bottompic2.png";
import botImg3 from "../../assets/acqusition/img/bottompic3.png";
import botImg4 from "../../assets/acqusition/img/bottompic4.png";
import botImg5 from "../../assets/acqusition/img/bottompic5.png";
import botImg6 from "../../assets/acqusition/img/bottompic6.png";
import OffmarketsCompo from "./OffMarketComp";
import "./offComp.scss";

function OffMarkets() {
  const data = [
    {
      img: botImg1,
      title: "Bauhaus Archive",
      disc: `7246 Woodland Rd. 
      Waukesha, WI 53186`,
      squareFoot: "96,706 SF",
      units: "36 Units",
    },
    {
      img: botImg2,
      title: "Bauhaus Archive",
      disc: `7246 Woodland Rd. 
      Waukesha, WI 53186`,
      squareFoot: "96,706 SF",
      units: "36 Units",
    },
    {
      img: botImg3,
      title: "Bauhaus Archive",
      disc: `7246 Woodland Rd. 
      Waukesha, WI 53186`,
      squareFoot: "96,706 SF",
      units: "36 Units",
    },
    {
      img: botImg4,
      title: "Bauhaus Archive",
      disc: `7246 Woodland Rd. 
      Waukesha, WI 53186`,
      squareFoot: "96,706 SF",
      units: "36 Units",
    },
    {
      img: botImg5,
      title: "Bauhaus Archive",
      disc: `7246 Woodland Rd. 
      Waukesha, WI 53186`,
      squareFoot: "96,706 SF",
      units: "36 Units",
    },
    {
      img: botImg6,
      title: "Bauhaus Archive",
      disc: `7246 Woodland Rd. 
      Waukesha, WI 53186`,
      squareFoot: "96,706 SF",
      units: "36 Units",
    },
  ];

  return (
    <div className="off-market">
      <div className="heading">Off-market assets</div>

      <div className="inner-off">
        {data.map(({ img, title, disc, squareFoot, units }) => {
          return (
            <OffmarketsCompo
              img={img}
              title={title}
              disc={disc}
              squareFoot={squareFoot}
              units={units}
            />
          );
        })}
      </div>
    </div>
  );
}

export default OffMarkets;
