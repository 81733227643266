import React from "react";
import "./InvesmentFirm.scss";
import map_svg from "../../assets/svg/map_svg.svg";
import mobile_icon from "../../assets/svg/Mobile_icon.svg";
import email_svg from "../../assets/svg/email_svg.svg";
import fb_icon from "../../assets/Image/facebook_icon.png";
import twitter_icon from "../../assets/svg/twitter_svg.svg";
import link_svg from "../../assets/svg/link_svgggg.svg";
import goLink from "../../assets/svg/go_link.svg";
import linkdin_icon from "../../assets/svg/lindin_icon.svg";
import location_svg from "../../assets/svg/location.svg";
import google_icon from "../../assets/svg/google.svg";
import career_grp from "../../assets/svg/career_group.svg";
import education_icon from "../../assets/svg/educaton_icon.svg";
import award_icon from "../../assets/svg/award_svg.svg";
import signal_svg from "../../assets/svg/signal_svg.svg";
import technical_svg from "../../assets/svg/technical_svg.svg";
import life360 from "../../assets/svg/360Life.svg";
import mozart_icon from "../../assets/svg/mozart_data_icon.svg";
import Samasource from "../../assets/svg/samasourse_icon.svg";
import technical_2 from "../../assets/svg/technical_2.svg";
import life_2 from "../../assets/svg/life_2.svg";
import mozart_2 from "../../assets/svg/mozart-2.svg";
import asset_2 from "../../assets/svg/asset_2.svg";
import estate from "../../assets/svg/estate_icon.svg";
import Syndicates from "./OpportunitiesSearch/Syndicates";
import knowUserPng from "../../assets/Image/know_user_png.png";
import follow_btn from "../../assets/svg/follow_btn.svg";
import small_grey_circle from "../../assets/svg/small_grey_circle.svg";
import addressLocation from "../../assets/svg/address_location.svg";
import primary_btn from "../../assets/svg/primary_btn.svg";

const InvestmentFirm = () => {
  return (
    <div className="details_container">
      <div className="overview_container">
        <div className="overview_without_contact">
          <div className="overview_heading">
            <h1> Overview</h1>
          </div>
          <div className="bio_container">
            <div className="bio_heading">
              <h1>Bio</h1>
            </div>
            <div className="bio_info">
              <p>
                Reimagining real estate investment process to make it easier than ever to move from one home to the next. Is a vertically integrated commercial real estate development and capital
                investment company with a strong track record of successful, high-quality medical and strategic acquisitions medical office development, post-acute hospitals, ... show more behavioral
                health hospitals, cancer centers, surgical and imaging centers, hospitality and full campus development.
              </p>
            </div>
            <div className="tags">
              <h1>Tags</h1>
              <div
                className="tags_names"
                style={{display: "flex", alignItems: "center", gap: "10px"}}
              >
                <p style={{background: "#7EC331"}}>Long Term</p>
                <p style={{background: "linear-gradient(0deg, #1665D8 2.22%, #1F6FE5 98.44%)"}}>Offices</p>
                <p style={{background: "#ED962D"}}>Development</p>
              </div>
            </div>
          </div>
          <div className="hr_line"></div>
          <div className="total_profit" style={{justifyContent:'flex-start',gap:'100px'}}>
            <div>
              <h1>201-500</h1>
              <p style={{color:'#9EA0A5',fontSize:'12px',fontWeight:'500',fontFamily:'Roboto'}}>Employees</p>
            </div>
            <div>
              <h1>Partnership</h1>
              <p style={{color:'#9EA0A5',fontSize:'12px',fontWeight:'500',fontFamily:'Roboto'}}>Company type </p>
            </div>
          </div>
        </div>
        <div className="members contact_info">
          <div className="member_heading contact_info_heading">
            <h1>Members</h1>
          </div>
          <div className="pepole_work_here">
            <h1>114</h1>
            <span>Pepole work here</span>
          </div>
          <div className="peple_you_know">
            <h1>People you may know</h1>
          </div>
          <div
            className="user_list"
            style={{display: "flex", alignItems: "center", gap: "30px"}}
          >
            <div className="userOne">
              <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                <img
                  src={knowUserPng}
                  alt=""
                />

                <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
                  <h2>Keith Holt</h2>
                  <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                    <p>3rd</p>
                    <img
                      src={small_grey_circle}
                      alt=""
                    />
                    <span>Broker</span>
                  </div>
                </div>
              </div>
              <img
                src={follow_btn}
                alt=""
              />
            </div>
            <div className="userOne">
              <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                <img
                  src={knowUserPng}
                  alt=""
                />

                <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
                  <h2>Keith Holt</h2>
                  <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                    <p>3rd</p>
                    <img
                      src={small_grey_circle}
                      alt=""
                    />
                    <span>Broker</span>
                  </div>
                </div>
              </div>
              <img
                src={follow_btn}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="contact_info">
          <div className="contact_info_heading">
            <h1>Contact Info</h1>
          </div>
          <div className="contact_info_details">
            <div className="contact_type">
              <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                <img
                  src={mobile_icon}
                  alt=""
                />
                <p>+1 7887 665 221</p>
              </div>
              <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                <img
                  src={fb_icon}
                  alt=""
                />
                <p>facebook.com/ NorthGroupTrust</p>
                <img
                  src={goLink}
                  alt=""
                />
              </div>
            </div>

            <div className="contact_type">
              <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                <img
                  src={email_svg}
                  alt=""
                />
                <p>me@angenewton.co</p>
              </div>
              <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                <img
                  src={twitter_icon}
                  alt=""
                />
                <p>twitter.com/ NorthGroupTrust</p>
                <img
                  src={goLink}
                  alt=""
                />
              </div>
            </div>

            <div className="contact_type">
              <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                <img
                  src={link_svg}
                  alt=""
                />
                <p>www.heritageattampa.com</p>
                <img
                  src={goLink}
                  alt=""
                  height="20px"
                  width="20px"
                />
              </div>
              <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                <img
                  src={linkdin_icon}
                  alt=""
                />
                <p>linkedin.com/ NorthGroupTrust</p>
                <img
                  src={goLink}
                  alt=""
                />
              </div>
            </div>
            <div className="contact_type">
              <div style={{display: "flex", alignItems: "center", gap: "20px", border: "none"}}>
                <img
                  src={location_svg}
                  alt=""
                />
                <div style={{display: "flex", flexDirection: "column", gap: "0px", border: "none"}}>
                  <p>
                    Works in <b>Baltimore, MD</b>
                  </p>
                  <span>Originally from Austin, TX</span>
                </div>
              </div>
              <div style={{display: "flex", alignItems: "center", gap: "20px", border: "none"}}>
                <img
                  src={google_icon}
                  alt=""
                />
                <p>facebook.com/ NorthGroupTrust</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="location contact_info"
          style={{height: "455px"}}
        >
          <div className="location_heading contact_info_heading">
            <h1>Location</h1>
          </div>
          <div className="location_details">
            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-around",gap:'50px'}}>
              <div className="headquarter">
                <img
                  src={primary_btn}
                  alt=""
                  className="primary_btn"
                />
                <h1>Headquarters</h1>
                <p>US, 21231, Baltimore, MD</p>
                <p>Dunnam Street, 34</p>
                <span style={{display: "flex", alignItems: "center", gap: "6px", color: "#7D55C7", fontSize: "14px"}}>
                  <img
                    src={addressLocation}
                    alt=""
                  />
                  Get directions
                </span>
              </div>
              <div className="ny_office">
                <h1>NY Office</h1>
                <p>US, 65556,New York, NY</p>
                <p>Broadway Street, 34</p>
              </div>
            </div>
            <div className="map_info">
              <img
                src={map_svg}
                alt=""
              />
            </div>
          </div>
        </div>

        <div
          className="recommended_syndicates"
          style={{marginTop: "30px"}}
          id="recommended"
        >
          <div className="heading">
            <img
              src={estate}
              alt=""
            />
            <div>
              <h1>2 Recommended Syndicates to Apply</h1>
              <span>Based on your interests, investments, and search history</span>
            </div>
          </div>
          <div className="syndicats_list">
            <Syndicates />
            <Syndicates />
            <Syndicates />
          </div>
        </div>

       
      </div>
    </div>
  );
};

export default InvestmentFirm;
