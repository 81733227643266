import React, {useState} from "react";
import "../../pages/assetDiscovery/AssetDiscovery.scss";

import {InputBox} from "../../components/reusableComponents/FormFields/FormFields";
import {AssetDiscoverySearch, PropertyCardMini} from "../assetDiscovery/AssetsSearch/AssetsSearch";
import pinsGroup from "../../assets/svg/pinsGroup.svg";
import {Modal} from "@mui/material";
import AssetInformation2 from "../DealsRoom/AssetInformation2";
const AiSearchComp = () => {
  const [acquisitionActive, setAcquisitionActive] = useState(true);
  const [isAdvFilters, setIsAdvFilters] = useState(false);
  const [isAiSearch, setIsAiSearch] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toggleTabination, setToggleTabination] = useState(false);
  const handleClose = () => {
    setIsModalOpen(false);
  };
  const handleViewDetails = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="AssetSearch ">
      <Modal
        open={isModalOpen}
        onClose={handleClose}
      >
        <AssetInformation2 />
      </Modal>
      <div className="topBgImg"></div>
      <div className="backGroundImg">
        <img
          style={{position: "absolute", left: "18%", top: "40%"}}
          src={pinsGroup}
          alt=""
        />
      </div>
      <div className="AssetDiscoverySearch-absoluteCon">
        <AssetDiscoverySearch
          acquisitionActive={acquisitionActive}
          setAcquisitionActive={setAcquisitionActive}
          isAdvFilters={isAdvFilters}
          setIsAdvFilters={setIsAdvFilters}
          isAiSearch={isAiSearch}
          setIsAiSearch={setIsAiSearch}
          variant={"large"}
        />
        <div
          className="hoverClickDiv"
          onClick={() => {
            
            setToggleTabination(!toggleTabination);
          }}
        ></div>
      </div>
    </div>
  );
};

export default AiSearchComp;
