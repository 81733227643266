import React from 'react';
import './OpportunitiesSearch.scss';
import commercial_img from "../../../assets/svg/commercial_img_svg.svg";
import left_slider from "../../../assets/svg/slider_left_icon.svg";
import right_slider from "../../../assets/svg/slider_right_icon.svg";
import rangeTrack from "../../../assets/svg/Range + Track.svg";
import eye_iconT from "../../../assets/svg/eye_icon_svg.svg";
import bulding_icon from '../../../assets/svg/bulding_svg.svg';
import { useNavigate } from 'react-router-dom';

const Syndicates = () => {
  const navigate = useNavigate();
  return (
    <div className="single_commercial">
    <div
      className="commercial_slider"
      style={{position: "relative", overflow: "hidden"}}
    >
      <img
        src={commercial_img}
        alt=""
        width="100%"
      />
      <span>Commercial</span>
      <div className="commercial_slider_btns">
        <p>1/14 photos</p>
        <div>
          <img
            src={left_slider}
            alt=""
          />
          <img
            src={right_slider}
            alt=""
          />
        </div>
      </div>
    </div>
    <div
      className="invest_group"
      style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px"}}
    >
      <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
        <p style={{height: "25px", width: "25px", backgroundColor: "#617D92", borderRadius: "50%" ,display:'flex',alignItems:'center',justifyContent:'center'}}>
          <img src={bulding_icon} alt="" />
        </p>
        <p>NorthGroup Invest</p>
      </div>
      <p>Debt</p>
    </div>
    <div className="heading_location">
      <h2>Waltham Woods Apartments</h2>
      <p>164 S. Carson Court Newport News,....</p>
    </div>
    <p className="offer_text">Offer Available for:</p>
    <div className="time_period">
      <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <p>02</p>
        <p>Days</p>
      </div>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <p>02</p>
        <p>Days</p>
      </div>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <p>02</p>
        <p>Days</p>
      </div>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <p>02</p>
        <p>Days</p>
      </div>
    </div>

    <div className="LTV">
      <div>
        <p>Yield</p>
        <h2>10%</h2>
      </div>
      <div>
        <p>LTV</p>
        <h2>75%</h2>
      </div>
      <div>
        <p>Hold Period</p>
        <h2>18 mo</h2>
      </div>
    </div>

    <div className="investments">
      <div>
        <p>Minimum Investment</p>
        <h2>$30,000</h2>
      </div>
      <div>
        <p>Redemption period</p>
        <h2>12 Month</h2>
      </div>
      <div>
        <p>Strategy</p>
        <h2>Core-Plus</h2>
      </div>
    </div>
    <div className="raised">
      <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
        <p>Raised</p>
        <p style={{color: "purple"}}>50%</p>
      </div>
      <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
        <p style={{height: "7px", width: "7px", background: "#34AA44", borderRadius: "50%"}}></p>
        <p>Active</p>
      </div>
    </div>
    <img
      src={rangeTrack}
      alt=""
      style={{marginLeft: "6px"}}
    />
    <button className="view_details"
    onClick={()=>navigate('/crowdfunding')}
    type='button'
    style={{cursor:'pointer'}}
    >
      <img
        src={eye_iconT}
        alt=""
      />{" "}
      View details
    </button>
  </div>
  )
}

export default Syndicates