import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; 
import rootReducer from "./reducers/index"; 
const persistConfig = {
  key: "lilypads-user-root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});


export const persistor = persistStore(store);
export const clearPersistedData = async () => {
  await persistor.purge();
};