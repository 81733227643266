import React from "react";
import {ReactComponent as calender} from "../../../assets/svg/calender.svg";
import Table from "../../../components/reusableComponents/Table/ReactTable";
import "./Financinghistory.scss";
import SearchInput from "../../../components/reusableComponents/Search/Search";
import arrow from "../../../assets/Manage-syndicate/Svg/arrow-up.png";
import SelectInput from "@mui/material/Select/SelectInput";
import {PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import filter from "../../../assets/Manage-syndicate/Svg/filter.svg";
import piegraph from "../../../assets/Manage-syndicate/Svg/Pie Chart.svg";
import PieChart from "../../../assets/Manage-syndicate/image/Pie Chart.png";

const Financinghistory = () => {
  return (
    <div>
      <div>
        <div style={{marginTop: "10px", marginBottom: "10px"}}>
          <h1>Financing History</h1>
          <br />
        </div>
      </div>
      <div className="Financinghistory-main-conatiner">
        <div>
          <div className="CapTab-details-chart-box">
            <div className="CapTab-details-chart">
              <div className="CapTab-alldata">
                <h1>View cap table as of</h1>
                <div className="CapTab-details-chart-data">
                  <div className="view_data">
                    <p>Fully Diluted Shares</p>
                  </div>
                  <div className="view_details">
                    <p>9,320,725</p>
                    <div className="images">
                      <img
                        src={arrow}
                        alt="image"
                      />
                    </div>
                  </div>
                </div>
                <div className="CapTab-details-chart-data">
                  <div className="view_data">
                    <p>Amount raised</p>
                  </div>
                  <div className="view_details_enddata">
                    <p>US$1,975,775.93</p>
                    <div className="images">
                      <img
                        src={arrow}
                        alt="image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="continer-captab">
              <div>
                <div className="captab-flex-data">
                  <h1>Ownership & fully diluted shares</h1>
                </div>
                <hr />
                <div className="Financinghistory-image-container">
                  <img
                    src={PieChart}
                    alt="image"
                  />
                  <div className="Financinghistory-cash">
                    <span> total cash raised</span>
                    <h1>$1,975,779</h1>
                    <div className="Financinghistory-div-flex">
                      <div className="Financinghistory-green" />
                      <p>Class A common</p>
                    </div>
                    <div className="Financinghistory-div-flex">
                      <div className="Financinghistory-blue" />
                      <p>convertibles</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Financinghistory-container">
          <div className="Financinghistory-container-data">
            <SearchInput />
            <div className="select-wrapper">
              <select
                name="Action"
                id="Action"
              >
                <option
                  value=""
                  disabled
                  selected
                >
                  Action
                </option>
                <option value="2nd">
                  2nd <i class="fas fa-arrow-up"></i>
                </option>
                <option value="3rd">
                  3rd <i class="fas fa-arrow-down"></i>
                </option>
              </select>
            </div>
            <PrimaryButtonSvgFirst
              name="Filter"
              classNames="Financinghistory-Filter"
              svgPath={filter}
              padding="10px"
              svgHeight="20px"
              svgWidth="20px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Financinghistory;
