import React from "react";
import "./AssetInformation.scss";
import photo from "../../assets/acqusition/svg/photo.svg";
import location from "../../assets/acqusition/svg/location.svg";
import street from "../../assets/acqusition/svg/street.svg";
import bookmark from "../../assets/acqusition/svg/bookmark.svg";
import share from "../../assets/acqusition/svg/share.svg";
import compare from "../../assets/acqusition/svg/compare.svg";
// import assettype from "../../assets/acqusition/svg/assettype.svg";
import assetype from "../../assets/acqusition/svg/assettype.svg";
import dropdown from "../../assets/acqusition/svg/dropdown.svg";
import file from "../../assets/acqusition/svg/file.svg";
import eye from "../../assets/acqusition/svg/eye.svg";
import utilities from "../../assets/acqusition/img/utilities.png";
import download from "../../assets/acqusition/svg/download.svg";
import prev from "../../assets/acqusition/svg/prev.svg";
import next from "../../assets/acqusition/svg/next.svg";
import contract from "../../assets/acqusition/img/contract.png";
import landdoc from "../../assets/acqusition/img/landdoc.png";
import n1 from "../../assets/acqusition/img/n1.png";
import map from "../../assets/acqusition/svg/map.svg";
import mappin from "../../assets/acqusition/svg/mappin.svg";
import cross from "../../assets/acqusition/svg/cross.svg";
import walk from "../../assets/acqusition/svg/walk.svg";
import car from "../../assets/acqusition/svg/car.svg";
import train from "../../assets/acqusition/svg/train.svg";
import cycle from "../../assets/acqusition/svg/cycle.svg";
import refresh from "../../assets/acqusition/svg/refresh.svg";
import calender from "../../assets/acqusition/svg/calender.svg";
import building_area from "../../assets/acqusition/svg/building_area.svg";
import total_unit from "../../assets/acqusition/svg/total_unit.svg";
import zoning from "../../assets/acqusition/svg/zoning.svg";
import metro from "../../assets/acqusition/svg/metro.svg";
import market from "../../assets/acqusition/svg/market.svg";
import sub_market from "../../assets/acqusition/svg/sub-market.svg";
import parking from "../../assets/acqusition/svg/parking.svg";
import price from "../../assets/acqusition/svg/price_squart.svg";
import builtgraph from "../../assets/acqusition/svg/bultgraph.svg";
import propertyGraph from "../../assets/acqusition/svg/propertygraph.svg";
import redirect from "../../assets/acqusition/svg/redirect.svg";
import mortgage from "../../assets/acqusition/svg/mortgage.svg";
import profit from "../../assets/acqusition/svg/profit.svg";
import increase from "../../assets/acqusition/svg/increase.svg";
import decrease from "../../assets/acqusition/svg/decrease.svg";
import pricehistory from "../../assets/acqusition/svg/pricehistory.svg";
import star from "../../assets/acqusition/svg/star.svg";
import mapround from "../../assets/acqusition/svg/mapround.svg";
import mall from "../../assets/acqusition/svg/mall.svg";
import show from "../../assets/acqusition/svg/show.svg";
import dropdownbg from "../../assets/acqusition/svg/dropdownbg.svg";
import verified from "../../assets/acqusition/svg/verified.svg";
import twitter from "../../assets/acqusition/svg/twitter.svg";
import love from "../../assets/acqusition/svg/love.svg";
import info from "../../assets/acqusition/svg/info.svg";
import comment from "../../assets/acqusition/svg/comment.svg";
import fillstar from "../../assets/acqusition/svg/fillstar.svg";
import emptystar from "../../assets/acqusition/svg/empty.svg";
import rightarrow from "../../assets/acqusition/svg/rightarrow.svg";
import prevnav from "../../assets/dealsroom/svg/prevnav.svg";
import fwdnav from "../../assets/dealsroom/svg/fwdnav.svg";
import person from "../../assets/acqusition/img/man.png";
import {useState} from "react";
import OffMarkets from "./OffMarketes";
import AssetCard from "./AssetCard";
import MaketSurvey from "./MaketSurvey";
import {InputBox, SelectBox} from "../../components/reusableComponents/FormFields/FormFields";
import {Checkmark, CheckmarkTICK} from "../../assets/svg/checkmark";
import DropDown from "../../components/reusableComponents/Dropdown/Dropdown";
import {Checkbox, FormControlLabel, FormGroup, Slider} from "@mui/material";
import {ThumbsSlider} from "../../components/reusableComponents/ThumbsSlider/ThumbsSlider";
import CrowdfundingHeader from "./CrowdFundingHeader";

const AssetInformation = () => {
  const [navActive, setNavActive] = useState(1);
  const [utiNavActive, setUtiNavActive] = useState(1);
  const [proNavActive, setProNavActive] = useState(1);
  const [expNavActive, setExpNavActive] = useState(1);
  const [mktNavActive, setMktNavActive] = useState(1);

  const [check, setCheck] = useState(false);
  const [builtGraph, setBuiltGraph] = useState(1);
  const [accordian, setAccordian] = useState(1);

  const [countryList, setCountrylist] = useState(["Kindergarden", "Elementarye", "Middle", "High"]);
  const [countryOfCitizenship, setCountryOfCitizenship] = useState("Kindergarden");
  const [countryOfCitizenshipDA, setCountryOfCitizenshipDA] = useState(false);
  const [pricethree, setPricethree] = useState({from: 0, to: 10, any: false});

  const [typeList, setTypeList] = useState(["All types", "Road", "New"]);
  const [typeListSel, setTypeListSel] = useState("All Types");
  const [typeListDa, setTypeListDa] = useState(false);
  return (
    <div className="deals-room-center asset-center-con">
      <CrowdfundingHeader title={"Asset Information"} />
      <div className="deals-room-center-header">
        <div className="acq-head-left">
          <div>
            <img
              src={photo}
              alt=""
            />
            <span>Photos</span>
          </div>
          <div>
            <img
              src={location}
              alt=""
            />
            <span>Location</span>
          </div>
          <div>
            <img
              src={street}
              alt=""
            />
            <span>Street View</span>
          </div>
        </div>
        <div className="acq-head-right">
          <div>
            <img
              src={bookmark}
              alt=""
            />
          </div>
          <div>
            <img
              src={share}
              alt=""
            />
          </div>
          <div>
            <img
              src={compare}
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="asset-main-container">
        <div className="asset-main-center">
          <div className="asset-img-hero">
            <ThumbsSlider />
          </div>
          <div className="deals-room-nav asset-nav ass-nav">
            <span
              className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(1);
              }}
            >
              Property Details
            </span>
            <span
              className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(2);
              }}
            >
              Utilities & Amenities
            </span>
            <span
              className={navActive === 3 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(3);
              }}
            >
              Property History
            </span>
            <span
              className={navActive === 4 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(4);
              }}
            >
              Comps
            </span>

            <span
              className={navActive === 5 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(5);
              }}
            >
              Explore
            </span>

            <span
              className={navActive === 6 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(6);
              }}
            >
              Market Survey
            </span>

            <span
              className={navActive === 7 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(7);
              }}
            >
              Market overview
            </span>
            <span
              className={navActive === 8 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(8);
              }}
            >
              Similar
            </span>
          </div>

          <div className="property-d">
            <span>Property Details</span>
          </div>
          <div className="property-wrapper">
            <div className="property-wrapper-left">
              <span>Description</span>
              <span className="property-description">
                Each unit will be constructed to a high standard and will benefit from minimum 10m eaves, a mix of dock and level loading doors and integral office accommodation, as well as generous
                loading yards.
                <br />
                <br />
                The site is situated on Coldharbour Lane which is located to the south of the A13 dual carriageway in turn providing swift access proving to Junction 31 of motorway M25.
                <br />
                <br />
                Each unit will be constructed to a high standard and will benefit from minimum 10m eaves, a mix of dock and level loading doors and integral office accommodation, as well as generous
                loading yards. The site is situated on Coldharbour Lane which is located to the south of the A13 dual carriageway in turn providing swift access proving to Junction 31 of motorway M25.
              </span>
              <div className="document-wrapper">
                <span className="d-w-h">Documents</span>

                <div
                  className="d-heading"
                  onClick={() => {
                    setAccordian(1);
                  }}
                >
                  <img
                    src={dropdown}
                    alt=""
                  />
                  <img
                    src={file}
                    alt=""
                  />

                  <span>Land documents</span>
                  <span>(2)</span>
                </div>
                {accordian === 1 && <LandDoc />}
                <div
                  className="d-heading"
                  onClick={() => {
                    setAccordian(2);
                  }}
                >
                  <img
                    src={dropdown}
                    alt=""
                  />
                  <img
                    src={file}
                    alt=""
                  />

                  <span>Advanced Techniques</span>
                  <span>(84)</span>
                </div>
                {accordian === 2 && <LandDoc />}
                <div
                  className="d-heading"
                  onClick={() => {
                    setAccordian(3);
                  }}
                >
                  <img
                    src={dropdown}
                    alt=""
                  />
                  <img
                    src={file}
                    alt=""
                  />

                  <span>Financing schemes</span>
                  <span>(17)</span>
                </div>
                {accordian === 3 && <LandDoc />}
              </div>
              <div className="divider"></div>
            </div>
            <div className="property-wrapper-right">
              <span>FLOOR PLAN</span>
              <div className="property-img-wrapper">
                <img
                  src={contract}
                  alt="image"
                />
                <img
                  src={contract}
                  alt="image"
                />
                <img
                  src={contract}
                  alt="image"
                />
                <img
                  src={contract}
                  alt="image"
                />
              </div>

              <div className="property-f-wrapper">
                <span className="property-f-title">Property Features</span>
                <PropertyFeatures
                  svgPath={assetype}
                  name={"Asset Type"}
                  details={"Commercial"}
                />
                <PropertyFeatures
                  svgPath={calender}
                  name={"Year Built"}
                  details={"1966"}
                />
                <div>
                  <PropertyFeatures
                    svgPath={building_area}
                    name={"Building Area"}
                    details={"7670 sqft"}
                  />
                  <PropertyFeatures
                    svgPath={total_unit}
                    name={"Total Units"}
                    details={"312"}
                  />
                  <PropertyFeatures
                    svgPath={zoning}
                    name={"Zoning"}
                    details={"C-2"}
                  />
                </div>
                <PropertyFeatures
                  svgPath={metro}
                  name={"Metro"}
                  details={"Baltimor"}
                />
                <PropertyFeatures
                  svgPath={market}
                  name={"Market"}
                  details={"Value"}
                />
                <PropertyFeatures
                  svgPath={sub_market}
                  name={"Sub-market"}
                  details={"Value"}
                />
                <PropertyFeatures
                  svgPath={parking}
                  name={"Parking"}
                  details={"3 places"}
                />
                <PropertyFeatures
                  svgPath={price}
                  name={"Price/sqft"}
                  details={"$524"}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="utilities">
              <div className="wrapper-heading">Utilities & Amenities</div>
              <div className="deals-room-nav asset-nav ut-nav">
                <span
                  className={utiNavActive === 1 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setUtiNavActive(1);
                  }}
                >
                  Utilities Arrangement
                </span>
                <span
                  className={utiNavActive === 2 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setUtiNavActive(2);
                  }}
                >
                  Amenities
                </span>
                <span
                  className={utiNavActive === 3 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setUtiNavActive(3);
                  }}
                >
                  Jurisdiction
                </span>
                <span
                  className={utiNavActive === 4 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setUtiNavActive(4);
                  }}
                >
                  Valuation & Taxes
                </span>
              </div>
              <div className={`utilities-main-wrapper ${(utiNavActive == 1 || utiNavActive == 4) && " utilities-w-p"} `}>
                {utiNavActive === 1 && (
                  <div>
                    <div className="table-wrapper">
                      <div className="table-h">
                        <span>UTILITY</span>
                        <span>SERVICE</span>
                        <span>PAID BY</span>
                        <span>METER TYPE</span>
                      </div>
                      <div>
                        <span>Water/Sewer</span>
                        <span>Pepco</span>
                        <span>Resident</span>
                        <span>Individual</span>
                      </div>
                      <div>
                        <span>Water/Sewer</span>
                        <span>Pepco</span>
                        <span>Resident</span>
                        <span>Individual</span>
                      </div>
                      <div>
                        <span>Water/Sewer</span>
                        <span>Pepco</span>
                        <span>Resident</span>
                        <span>Individual</span>
                      </div>
                    </div>
                    <img
                      src={utilities}
                      alt=""
                    />
                  </div>
                )}

                {utiNavActive === 2 && (
                  <div>
                    <div className="amenities-wrapper">
                      <span>Types of Locations</span>
                      <div className="amenities-w-main">
                        <div>
                          <span>View of Coastal, City or Hills</span>
                        </div>
                      </div>
                      <span>Other</span>
                      <div className="amenities-w-main">
                        <div>
                          <span>Portable water available</span>
                        </div>
                        <div>
                          <span>Bins available</span>
                        </div>
                        <div>
                          <span>Washing machine available</span>
                        </div>
                        <div>
                          <span>Free Wi-Fi</span>
                        </div>
                        <div>
                          <span>Showers available</span>
                        </div>
                      </div>
                    </div>
                    <div className="amenities-wrapper">
                      <span>Types of Locations</span>
                      <div className="amenities-w-main">
                        <div>
                          <span>View of Coastal, City or Hills</span>
                        </div>
                      </div>
                      <span>Other</span>
                      <div className="amenities-w-main">
                        <div>
                          <span>Portable water available</span>
                        </div>
                        <div>
                          <span>Bins available</span>
                        </div>
                        <div>
                          <span>Washing machine available</span>
                        </div>
                        <div>
                          <span>Free Wi-Fi</span>
                        </div>
                        <div>
                          <span>Showers available</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {utiNavActive === 3 && (
                  <div className="jurisdiction-wrapper">
                    <div>
                      <span>Jurisdiction:</span>
                      <span>Texas</span>
                    </div>
                    <div>
                      <span>Tax rate:</span>
                      <span>$0.85 per $100 of assessed value</span>
                    </div>
                    <div>
                      <span>Assessment:</span>
                      <span>2017 - $9,598,460</span>
                    </div>
                    <div>
                      <span>Parcel number:</span>
                      <span>TXC WASA</span>
                    </div>
                  </div>
                )}

                {utiNavActive === 4 && (
                  <div className="value-taxes-main">
                    <div className="valuation-taxes-wrapper">
                      <span>Valuation</span>

                      <div className="valuation-taxes-wrapper-main">
                        <div>
                          <div>
                            <img
                              src={calender}
                              alt=""
                            />
                            <span>Assessed year</span>
                          </div>
                          <span>2017</span>
                        </div>
                        <div className="divider"></div>
                        <div>
                          <div>
                            <img
                              src={mortgage}
                              alt=""
                            />
                            <span>Assessed amount</span>
                          </div>
                          <span>$2,545</span>
                        </div>
                      </div>
                    </div>
                    <div className="valuation-taxes-wrapper">
                      <span>Valuation</span>

                      <div className="valuation-taxes-wrapper-main">
                        <div>
                          <div>
                            <img
                              src={calender}
                              alt=""
                            />
                            <span>Assessed year</span>
                          </div>
                          <span>2017</span>
                        </div>
                        <div className="divider"></div>
                        <div>
                          <div>
                            <img
                              src={profit}
                              alt=""
                            />
                            <span>Assessed amount</span>
                          </div>
                          <span>$2,545</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="property-history-wrapper">
              <div className="wrapper-heading">Property History</div>
              <div className="deals-room-nav asset-nav table-nav ut-nav">
                <span
                  className={proNavActive === 1 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setProNavActive(1);
                  }}
                >
                  Sales History
                </span>
                <span
                  className={proNavActive === 2 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setProNavActive(2);
                  }}
                >
                  Price History
                </span>
              </div>

              {proNavActive === 1 && (
                <div className="p-table-wrapper">
                  <div className="p-table-header">
                    <div className="flex-body">
                      <span>Date</span>
                      <span>Event</span>
                    </div>
                    <div>
                      <span>PRICE</span>
                    </div>
                  </div>
                  <div className="p-table-body">
                    <div className="flex-body">
                      {" "}
                      <span>August 3, 2019</span>
                      <span>Sold</span>
                    </div>
                    <div>
                      <span> $540,300</span>
                    </div>
                  </div>
                  <div className="p-table-body">
                    <div className="flex-body">
                      {" "}
                      <span>December 31, 2018</span>
                      <span>Listed</span>
                    </div>
                    <div>
                      <span> $540,300</span>
                    </div>
                  </div>
                  <div className="p-table-body">
                    <div className="flex-body">
                      {" "}
                      <span>December 10, 2017</span>
                      <span>Off-market</span>
                    </div>
                    <div>
                      <span> </span>
                    </div>
                  </div>
                </div>
              )}

              {proNavActive === 2 && (
                <div className="property-wrapper-main">
                  <div className="price-history-upper">
                    <div className="price-h-card">
                      <div>
                        <span>This Asset</span>
                        <span>Deviation</span>
                      </div>
                      <div>
                        <span>$832,500</span>
                        <div>
                          <span>+6.9%</span>
                          <img
                            src={increase}
                            alt=""
                          />
                        </div>
                      </div>
                      <div>
                        <span>Aug. 14, 2018</span>
                        <span>Today</span>
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="price-h-card">
                      <div>
                        <span>Average Market</span>
                        <span>Deviation</span>
                      </div>
                      <div>
                        <span>$782,500</span>
                        <div>
                          <span>-3.1%</span>
                          <img
                            src={decrease}
                            alt=""
                          />
                        </div>
                      </div>
                      <div>
                        <span>Aug. 14, 2018</span>
                        <span>Today</span>
                      </div>
                    </div>

                    <InputBox
                      type={"date"}
                      placeholder={"7 years"}
                      svgPath={dropdown}
                    />
                  </div>
                  <div>
                    <img
                      src={pricehistory}
                      alt=""
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              className="explore-wrapper"
              id="explore-section"
            >
              <div className="wrapper-heading">Explore</div>
              <div className="deals-room-nav asset-nav table-nav ut-nav">
                <span
                  className={expNavActive === 1 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setExpNavActive(1);
                  }}
                >
                  Commute time
                </span>
                <span
                  className={expNavActive === 2 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setExpNavActive(2);
                  }}
                >
                  Educational
                </span>
                <span
                  className={expNavActive === 3 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setExpNavActive(3);
                  }}
                >
                  Lifestyle
                </span>
                <span
                  className={expNavActive === 4 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setExpNavActive(4);
                  }}
                >
                  Potential Pros & Cons
                </span>
              </div>

              {expNavActive == 1 && (
                <div className="explore-body-wrapper">
                  <div className="explore-body-right">
                    <img
                      src={map}
                      alt=""
                    />
                  </div>
                  <div className="explore-body-left">
                    <h1>Commute Time</h1>
                    <div className="inp">
                      <InputBox
                        conCLass={"explore-input"}
                        label={"Address"}
                        svgPath={cross}
                        name={"Address"}
                        placeholder={"600 N Michigan Ave, Atlantic Cit"}
                      />
                      <img
                        src={mappin}
                        alt=""
                      />
                    </div>
                    <div
                      className="explore-checkbox"
                      onClick={() => {
                        setCheck(!check);
                      }}
                    >
                      {check ? <CheckmarkTICK /> : <Checkmark />}
                      <h2>Include Traffic</h2>
                    </div>
                    <div className="explore-ways">
                      <span>Way of getting there</span>
                      <div>
                        <img
                          src={walk}
                          alt=""
                        />
                        <img
                          src={car}
                          alt=""
                        />
                        <img
                          src={train}
                          alt=""
                        />
                        <img
                          src={cycle}
                          alt=""
                        />
                      </div>
                    </div>
                    <span className="explore-para">
                      {" "}
                      <span>45 min</span> by walk to 600 N Michigan Ave,Atlantic City, NJ 08401
                    </span>

                    <div className="explore-refresh">
                      <img
                        src={refresh}
                        alt=""
                      />
                      <span> Recalculate</span>
                    </div>
                  </div>
                </div>
              )}

              {expNavActive == 2 && (
                <div className="explore-body-wrapper">
                  <div className="explore-body-right">
                    <img
                      src={mapround}
                      alt=""
                    />
                  </div>
                  <div className="educational-left">
                    <div>
                      <DropDown
                        label={"Type"}
                        classDataName={"dropdown-height "}
                        data={countryList}
                        selectedData={countryOfCitizenship}
                        setSelectedData={setCountryOfCitizenship}
                        dropdownState={countryOfCitizenshipDA}
                        dropdownSetter={setCountryOfCitizenshipDA}
                      />

                      <div>
                        <div className="slider-label">
                          <span>Radius</span>
                        </div>
                        <div className="slider_container">
                          <div className="top_div inputFlexLine">
                            <p></p>
                            <span>{pricethree.from}&nbsp;mi</span>
                          </div>
                          <Slider
                            defaultValue={pricethree.from}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            step={10}
                            min={0}
                            max={100}
                            onChange={(event) => {
                              setPricethree((prev) => ({
                                ...prev,
                                from: event.target.value.toLocaleString(),
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="educational-card">
                        <div>
                          <span>Northwood School</span>
                          <span>1.5 mi</span>
                        </div>
                        <div>
                          <img
                            src={star}
                            alt=""
                          />
                          <span>4,7</span>
                        </div>
                      </div>
                      <div className="educational-card">
                        <div>
                          <span>Northwood School</span>
                          <span>1.5 mi</span>
                        </div>
                        <div>
                          <img
                            src={star}
                            alt=""
                          />
                          <span>4,7</span>
                        </div>
                      </div>
                      <div className="educational-card">
                        <div>
                          <span>Northwood School</span>
                          <span>1.5 mi</span>
                        </div>
                        <div>
                          <img
                            src={star}
                            alt=""
                          />
                          <span>4,7</span>
                        </div>
                      </div>
                    </div>
                    <div className="page-nxt-slider">
                      <div className="page-nxt-slider-inner">
                        <div>
                          <img
                            src={prev}
                            alt=""
                          />
                        </div>
                        <div>1</div>
                        <div>2</div>
                        <div>3</div>
                        <div>4</div>
                        <div>5</div>
                        <div>
                          <img
                            src={next}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {expNavActive == 3 && (
                <div className="explore-body-wrapper">
                  <div className="explore-body-right">
                    <img
                      src={mapround}
                      alt=""
                    />
                  </div>
                  <div className="educational-left">
                    <div>
                      <DropDown
                        label={"Type"}
                        classDataName={"dropdown-height "}
                        data={countryList}
                        selectedData={countryOfCitizenship}
                        setSelectedData={setCountryOfCitizenship}
                        dropdownState={countryOfCitizenshipDA}
                        dropdownSetter={setCountryOfCitizenshipDA}
                      />

                      <div>
                        <div className="slider-label">
                          <span>Radius</span>
                        </div>
                        <div className="slider_container">
                          <div className="top_div inputFlexLine">
                            <p></p>
                            <span>{pricethree.from}&nbsp;mi</span>
                          </div>
                          <Slider
                            defaultValue={pricethree.from}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            step={10}
                            min={0}
                            max={100}
                            onChange={(event) => {
                              setPricethree((prev) => ({
                                ...prev,
                                from: event.target.value.toLocaleString(),
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="educational-card">
                        <div>
                          <span>Northwood School</span>
                          <span>1.5 mi</span>
                        </div>
                        <div>
                          <img
                            src={star}
                            alt=""
                          />
                          <span>4,7</span>
                        </div>
                      </div>
                      <div className="educational-card">
                        <div>
                          <span>Northwood School</span>
                          <span>1.5 mi</span>
                        </div>
                        <div>
                          <img
                            src={star}
                            alt=""
                          />
                          <span>4,7</span>
                        </div>
                      </div>
                      <div className="educational-card">
                        <div>
                          <span>Northwood School</span>
                          <span>1.5 mi</span>
                        </div>
                        <div>
                          <img
                            src={star}
                            alt=""
                          />
                          <span>4,7</span>
                        </div>
                      </div>
                    </div>
                    <div className="page-nxt-slider">
                      <div className="page-nxt-slider-inner">
                        <div>
                          <img
                            src={prev}
                            alt=""
                          />
                        </div>
                        <div>1</div>
                        <div>2</div>
                        <div>3</div>
                        <div>4</div>
                        <div>5</div>
                        <div>
                          <img
                            src={next}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {expNavActive == 4 && (
                <div className="explore-body-wrapper">
                  <div className="explore-body-right">
                    <img
                      src={mapround}
                      alt=""
                    />
                  </div>
                  <div className="educational-left">
                    <div>
                      <DropDown
                        label={"Type"}
                        classDataName={"dropdown-height "}
                        data={typeList}
                        selectedData={typeListSel}
                        setSelectedData={setTypeListSel}
                        dropdownState={typeListDa}
                        dropdownSetter={setTypeListDa}
                      />

                      <div>
                        <div className="slider-label">
                          <span>Radius</span>
                        </div>
                        <div className="slider_container">
                          <div className="top_div inputFlexLine">
                            <p></p>
                            <span>{pricethree.from}&nbsp;mi</span>
                          </div>
                          <Slider
                            defaultValue={pricethree.from}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            step={10}
                            min={0}
                            max={100}
                            onChange={(event) => {
                              setPricethree((prev) => ({
                                ...prev,
                                from: event.target.value.toLocaleString(),
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="potential">
                      <div className="edu-l">
                        <div className="mall">
                          <img
                            className="ml"
                            src={mall}
                            alt=""
                          />
                        </div>
                        <img
                          src={show}
                          alt=""
                        />
                      </div>
                      <div className="edu-r">
                        <div>
                          <span>A new shopping mall is under construction. </span>
                          <img
                            src={dropdownbg}
                            alt=""
                          />
                        </div>
                        <div>
                          <span>1.5 miles</span>
                          <span>That may bring inconvenience for 1-2 years in this area. However after that it’ll be a perfect location with a brand new crossroad.</span>
                        </div>
                      </div>
                    </div>

                    <div className="deals-room-doc-page-nav center">
                      <div className="page-nav-cont">
                        <img
                          src={prevnav}
                          alt=""
                        />
                        <span>2 of 10</span>
                        <img
                          src={fwdnav}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="market-survey-wrapper"
              name="test1"
              id="test1"
            >
              <div className="market-sur-heading">
                <span>Market Survey</span>
              </div>

              <div className="deals-room-nav asset-nav ut-nav">
                <span
                  className={mktNavActive === 1 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setMktNavActive(1);
                  }}
                >
                  Property Details
                </span>
                <span
                  className={mktNavActive === 2 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setMktNavActive(2);
                  }}
                >
                  Utilities & Amenities
                </span>
                <span
                  className={mktNavActive === 3 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setMktNavActive(3);
                  }}
                >
                  Property History
                </span>
              </div>

              {mktNavActive == 1 && (
                <div className="news-s-wrapper">
                  {" "}
                  <MaketSurvey />
                  <MaketSurvey />
                </div>
              )}

              {mktNavActive === 2 && (
                <div className="social-net-wrapper">
                  <div className="social-n-l">
                    <div className="social-heading">2,365 Posts</div>

                    <div className="social-n-card">
                      <div className="s-n-c-main">
                        <div className="n-card-upper">
                          <div className="n-c-u-l">
                            <img
                              className="ver"
                              src={person}
                              alt=""
                            />
                            <div>
                              <span>Gregory Ray</span>
                              <span>@Gregory Ray</span>
                            </div>
                            <img
                              className=""
                              src={verified}
                              alt=""
                            />
                          </div>
                          <div className="n-c-u-r">
                            {" "}
                            <img
                              src={twitter}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="n-card-center">
                          {" "}
                          <span>
                            I can’t figure out the how to do the mask on the circle and the speaker holes for the life of me. I select them all and then select the circle layer and choose layer mask.
                            I’ve tried all the options under layer mask and it’s not turning out…
                          </span>{" "}
                        </div>
                        <div className="n-card-down">
                          <div>
                            <div>
                              <img
                                src={love}
                                alt=""
                              />{" "}
                              <span>249</span>
                            </div>
                            <div>
                              {" "}
                              <span>7:48 PM - May 8, 2019</span>
                            </div>
                          </div>
                          <div>
                            {" "}
                            <img
                              src={info}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="divider d"></div>
                      <div className="s-n-c-main-down">
                        <div>
                          <img
                            src={comment}
                            alt=""
                          />
                          <span>110 people are talking about this</span>
                        </div>
                        <div>
                          {" "}
                          <img
                            src={rightarrow}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="social-n-card">
                      <div className="s-n-c-main">
                        <div className="n-card-upper">
                          <div className="n-c-u-l">
                            <img
                              className="ver"
                              src={person}
                              alt=""
                            />
                            <div>
                              <span>Gregory Ray</span>
                              <span>@Gregory Ray</span>
                            </div>
                            <img
                              className=""
                              src={verified}
                              alt=""
                            />
                          </div>
                          <div className="n-c-u-r">
                            {" "}
                            <img
                              src={twitter}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="n-card-center">
                          {" "}
                          <span>
                            I can’t figure out the how to do the mask on the circle and the speaker holes for the life of me. I select them all and then select the circle layer and choose layer mask.
                            I’ve tried all the options under layer mask and it’s not turning out…
                          </span>{" "}
                        </div>
                        <div className="n-card-down">
                          <div>
                            <div>
                              <img
                                src={love}
                                alt=""
                              />{" "}
                              <span>249</span>
                            </div>
                            <div>
                              {" "}
                              <span>7:48 PM - May 8, 2019</span>
                            </div>
                          </div>
                          <div>
                            {" "}
                            <img
                              src={info}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="divider d"></div>
                      <div className="s-n-c-main-down">
                        <div>
                          <img
                            src={comment}
                            alt=""
                          />
                          <span>110 people are talking about this</span>
                        </div>
                        <div>
                          {" "}
                          <img
                            src={rightarrow}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="social-n-r">
                    <div className="social-filter-heading">Filter Results</div>
                    <div className="show-filter">
                      <span>Show</span>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Facebook"
                          />
                        </FormGroup>
                        <span>3,550 posts</span>
                      </div>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Twitter"
                          />
                        </FormGroup>
                        <span>785 posts</span>
                      </div>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Instagram"
                          />
                        </FormGroup>
                        <span>785 posts</span>
                      </div>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Linkedin"
                          />
                        </FormGroup>
                        <span>3,550 posts</span>
                      </div>
                    </div>

                    <div className="show-filter">
                      <span>Date added</span>
                      <InputBox type={"date"} />
                    </div>
                    <div className="show-filter">
                      <span>Sort by</span>
                      <InputBox
                        type={"date"}
                        placeholder={"Last 30 Days"}
                      />
                    </div>
                  </div>
                </div>
              )}
              {mktNavActive === 3 && (
                <div className="social-net-wrapper">
                  <div className="social-n-l asset-review">
                    <div className="social-heading">165 Ratings</div>
                    <div className="divider d"></div>

                    <div className="rating-wrapper">
                      <div className="rating-container">
                        <span>Location</span>
                        <div>
                          <div>
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={emptystar}
                              alt=""
                            />
                          </div>
                          <span>4.2</span>
                        </div>
                      </div>
                      <div className="rating-container">
                        <span>Accuracy</span>
                        <div>
                          <div>
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={emptystar}
                              alt=""
                            />
                          </div>
                          <span>4.2</span>
                        </div>
                      </div>
                      <div className="rating-container">
                        <span>Noise</span>
                        <div>
                          <div>
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={emptystar}
                              alt=""
                            />
                          </div>
                          <span>4.2</span>
                        </div>
                      </div>
                      <div className="rating-container">
                        <span>Activity</span>
                        <div>
                          <div>
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={emptystar}
                              alt=""
                            />
                            <img
                              src={emptystar}
                              alt=""
                            />
                          </div>
                          <span>3.2</span>
                        </div>
                      </div>
                      <div className="rating-container">
                        <span>Cleanliness</span>
                        <div>
                          <div>
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={fillstar}
                              alt=""
                            />
                            <img
                              src={emptystar}
                              alt=""
                            />
                          </div>
                          <span>4.2</span>
                        </div>
                      </div>
                    </div>
                    <div className="divider d"></div>
                    <div className="person-rating-wrapper">
                      <div className="person-r-upper">
                        <div className="prof">
                          <img
                            src={person}
                            alt=""
                          />
                          <div>
                            <span>Susie Chapman</span>
                            <span>May 2017</span>
                          </div>
                        </div>
                        <div>
                          <span>Yelp</span>
                          <div>
                            <div>
                              <img
                                src={fillstar}
                                alt=""
                              />
                              <img
                                src={fillstar}
                                alt=""
                              />
                              <img
                                src={fillstar}
                                alt=""
                              />
                              <img
                                src={fillstar}
                                alt=""
                              />
                              <img
                                src={emptystar}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="person-r-center">
                        <span>
                          You are empowered to make decisions and choose your own goals/tasks. Every day you meet so many amazing entrepreneurs as well as continuously build these relationships.
                          Inspiring and fun meetings, all-hands & trips (Summer Camp & Summit). Support system! Everyone cares for your well-being and become family.
                        </span>
                      </div>
                    </div>
                    <div className="divider d net"></div>
                    <div className="person-rating-wrapper">
                      <div className="person-r-upper">
                        <div className="prof">
                          <img
                            src={person}
                            alt=""
                          />
                          <div>
                            <span>Shon McWu </span>
                            <span>May 2017</span>
                          </div>
                        </div>
                        <div>
                          <span>Yelp</span>
                          <div>
                            <div>
                              <img
                                src={fillstar}
                                alt=""
                              />
                              <img
                                src={fillstar}
                                alt=""
                              />
                              <img
                                src={fillstar}
                                alt=""
                              />
                              <img
                                src={fillstar}
                                alt=""
                              />
                              <img
                                src={emptystar}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="person-r-center">
                        <span>You are empowered to make decisions and choose your own goals/tasks. Every day you meet so many amazing entrepreneurs as well as continuously.</span>
                      </div>
                    </div>
                  </div>
                  <div className="social-n-r">
                    <div className="social-filter-heading">Filter Results</div>
                    <div className="show-filter">
                      <span>Show</span>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Facebook"
                          />
                        </FormGroup>
                        <span>3,550 posts</span>
                      </div>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Twitter"
                          />
                        </FormGroup>
                        <span>785 posts</span>
                      </div>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Instagram"
                          />
                        </FormGroup>
                        <span>785 posts</span>
                      </div>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Linkedin"
                          />
                        </FormGroup>
                        <span>3,550 posts</span>
                      </div>
                    </div>

                    <div className="show-filter">
                      <span>Date added</span>
                      <InputBox type={"date"} />
                    </div>
                    <div className="show-filter">
                      <span>Sort by</span>
                      <InputBox
                        type={"date"}
                        placeholder={"Last 30 Days"}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="page-nxt-slider">
                <div className="page-nxt-slider-inner">
                  <div>
                    <img
                      src={prev}
                      alt=""
                    />
                  </div>
                  <div>1</div>
                  <div>2</div>
                  <div>3</div>
                  <div>4</div>
                  <div>5</div>
                  <div>
                    <img
                      src={next}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="building-wrapper">
              <div className="building-wrapper-left">
                <img
                  src={propertyGraph}
                  alt=""
                />
              </div>
              <div className="building-wrapper-right">
                <div className="b-w-r-h">
                  <span>How does Waltham Woods Apartments compare to the average similar nearby?</span>
                </div>

                <div className="built-card">
                  <PropertyDetails
                    svgPath={calender}
                    name={"Year Built"}
                    name_={"2 years older."}
                    details={"1966"}
                    details_={"1978 average"}
                  />
                  <div className="built-card-middle">
                    <PropertyDetails
                      svgPath={building_area}
                      name={"Building Area"}
                      name_={"2 years older."}
                      details={"7,670 sqft"}
                      details_={"6,658 sqft average"}
                    />
                    <PropertyDetails
                      svgPath={total_unit}
                      name={"Total Units"}
                      name_={"There are more units for  the same price."}
                      details={"312"}
                      details_={"410 average"}
                    />
                    <PropertyDetails
                      svgPath={zoning}
                      name={"Zoning"}
                      name_={"There's no clear advantage."}
                      details={"C-2"}
                    />
                  </div>
                  <div className="built-card-bottom">
                    <PropertyDetails
                      svgPath={metro}
                      name={"Metro"}
                      details={"Baltimor"}
                    />
                    <PropertyDetails
                      svgPath={market}
                      name={"Market"}
                      details={"Value"}
                    />
                    <PropertyDetails
                      svgPath={sub_market}
                      name={"Sub-market"}
                      details={"Value"}
                    />
                    <PropertyDetails
                      svgPath={parking}
                      name={"Parking"}
                      details={"3 places"}
                    />
                    <PropertyDetails
                      svgPath={price}
                      name={"Price/sqft"}
                      details={"$524"}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="built-graph-wrapper">
              <div className="built-graph-nav">
                <div
                  className={builtGraph == 1 && "active"}
                  onClick={() => {
                    setBuiltGraph(1);
                  }}
                >
                  <img
                    src={calender}
                    alt=""
                  />
                  <span>Year Built</span>
                </div>
                <div
                  className={builtGraph == 2 && "active"}
                  onClick={() => {
                    setBuiltGraph(2);
                  }}
                >
                  <img
                    src={calender}
                    alt=""
                  />
                  <span>Building Area</span>
                </div>
                <div
                  className={builtGraph == 3 && "active"}
                  onClick={() => {
                    setBuiltGraph(3);
                  }}
                >
                  <img
                    src={calender}
                    alt=""
                  />
                  <span>Total Units</span>
                </div>
                <div
                  className={builtGraph == 4 && "active"}
                  onClick={() => {
                    setBuiltGraph(4);
                  }}
                >
                  <img
                    src={calender}
                    alt=""
                  />
                  <span>Price</span>
                </div>
                <div
                  className={builtGraph == 5 && "active"}
                  onClick={() => {
                    setBuiltGraph(5);
                  }}
                >
                  <img
                    src={calender}
                    alt=""
                  />
                  <span>Parking</span>
                </div>
              </div>
              <div className="b-g-g-s">
                <span>7,568 Assets have it</span>
                <img
                  src={builtgraph}
                  alt=""
                />
              </div>
            </div>

            <div className="nearby-wrapper">
              <span>Similar In this range nearby</span>

              <div className="nearby-card-wrappper">
                <div className="nearby-card">
                  <div>
                    <div className="n-c-l"></div>
                    <img
                      src={n1}
                      alt=""
                    />
                  </div>
                  <div className="n-c-b">
                    <span className="n-c-b-h">Waltham Woods Apartments</span>
                    <div>
                      <span>$1,290,000</span>
                      <img
                        src={redirect}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="nearby-card">
                  <div>
                    <div className="n-c-l"></div>
                    <img
                      src={n1}
                      alt=""
                    />
                  </div>
                  <div className="n-c-b">
                    <span className="n-c-b-h">Waltham Woods Apartments</span>
                    <div>
                      <span>$1,290,000</span>
                      <img
                        src={redirect}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="nearby-card">
                  <div>
                    <div className="n-c-l"></div>
                    <img
                      src={n1}
                      alt=""
                    />
                  </div>
                  <div className="n-c-b">
                    <span className="n-c-b-h">Waltham Woods Apartments</span>
                    <div>
                      <span>$1,290,000</span>
                      <img
                        src={redirect}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="nearby-card">
                  <div>
                    <div className="n-c-l"></div>
                    <img
                      src={n1}
                      alt=""
                    />
                  </div>
                  <div className="n-c-b">
                    <span className="n-c-b-h">Waltham Woods Apartments</span>
                    <div>
                      <span>$1,290,000</span>
                      <img
                        src={redirect}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="nearby-card">
                  <div>
                    <div className="n-c-l"></div>
                    <img
                      src={n1}
                      alt=""
                    />
                  </div>
                  <div className="n-c-b">
                    <span className="n-c-b-h">Waltham Woods Apartments</span>
                    <div>
                      <span>$1,290,000</span>
                      <img
                        src={redirect}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="nearby-card">
                  <div>
                    <div className="n-c-l"></div>
                    <img
                      src={n1}
                      alt=""
                    />
                  </div>
                  <div className="n-c-b">
                    <span className="n-c-b-h">Waltham Woods Apartments</span>
                    <div>
                      <span>$1,290,000</span>
                      <img
                        src={redirect}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <OffMarkets />
          </div>
        </div>
        <div className="asset-main-right">
          <AssetCard />
        </div>
      </div>
    </div>
  );
};

export default AssetInformation;

const PropertyDetails = ({svgPath, name, details, name_, details_}) => {
  return (
    <div className="property-list">
      <div className="p-l-l">
        <img
          src={svgPath}
          alt=""
        />
        <div>
          <span className="p-l-l-s-f">{name}</span>
          {name_ && <span className="p-l-l-s-l">{name_}</span>}
        </div>
      </div>
      <div className="p-l-r">
        <span className="p-l-r-s-f">{details}</span>
        {details_ && <span className="p-l-r-s-l">{details_}</span>}
      </div>
    </div>
  );
};

const PropertyFeatures = ({svgPath, name, details}) => {
  return (
    <div className="f-w-c">
      <div>
        {" "}
        <img
          src={svgPath}
          alt=""
        />
        <div>
          <span>{name}</span>
        </div>
      </div>

      <div>
        <span>{details}</span>
      </div>
    </div>
  );
};

const LandDoc = () => {
  return (
    <div className="land-doc-wrapper">
      <div className="land-doc-card">
        <img
          src={landdoc}
          alt=""
        />
        <span>Council Decision No. 135/1969. On the registra...</span>
        <div>
          <img
            src={eye}
            alt=""
          />
          <img
            src={download}
            alt=""
          />
        </div>
      </div>
      <div className="land-doc-card">
        <img
          src={landdoc}
          alt=""
        />
        <span>Council Decision No. 135/1969. On the registra...</span>
        <div>
          <img
            src={eye}
            alt=""
          />
          <img
            src={download}
            alt=""
          />
        </div>
      </div>
      <div className="land-doc-card">
        <img
          src={landdoc}
          alt=""
        />
        <span>Council Decision No. 135/1969. On the registra...</span>
        <div>
          <img
            src={eye}
            alt=""
          />
          <img
            src={download}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
