import lilypandsLogo from "../../assets/svg/lilipadsLogomain.svg";
// import singinRight from "../../assets/svg/signInRight.png";
import PRgif1 from "../../assets/svg/PRgif1.gif";
import PRsvg1 from "../../assets/svg/PRsvg1.svg";
import PRsvg2 from "../../assets/svg/PRsvg2.svg";
import PRsvg3 from "../../assets/svg/PRsvg3.svg";
import {useState} from "react";
import "./PasswordRecovery.scss";
import {PrimaryButton} from "../../components/reusableComponents/Buttons/Buttons";
import {InputBox} from "../../components/reusableComponents/FormFields/FormFields";
import {Link} from "react-router-dom";
import {FirstSection} from "./Steps/FirstSection";
import {SecondSection} from "./Steps/SecondSection";
import {ThirdSection} from "./Steps/ThirdSection";
import {FourthSection} from "./Steps/FourthSection";
import {FifthSection} from "./Steps/FifthSection";

const PasswordRecovery = () => {
  const [sliderState, setSliderState] = useState("one");
  return (
    <div className="signupMainCon passwordRecovery">
      <div className={"signupScroller " + sliderState}>
        <FirstSection setSliderState={setSliderState} />
        <SecondSection setSliderState={setSliderState} />
        <ThirdSection setSliderState={setSliderState} />
        <FourthSection setSliderState={setSliderState} />
        <FifthSection setSliderState={setSliderState} />
      </div>
    </div>
  );
};

export default PasswordRecovery;
