import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './PageNotFound.scss'

const PageNotFound = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/'); // Redirect to the homepage
  };

  return (
    <div className="not-found-page">
      <div className="not-found-container">
        <FaExclamationTriangle className="icon" />
        <h1>404</h1>
        <p>Oops! The page you're looking for doesn't exist.</p>
        <button className="home-button" onClick={goToHome}>
          Go to Home
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
