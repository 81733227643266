import React, {useState} from "react";
import {Modal, Button, message} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import "./ScheduleFollowUp.scss";
import {useSelector} from "react-redux";
import useAuthConfig from "../../../utils/Config";
import {ReactComponent as LeftSingleArrow} from "../../../assets/chatCompAssets/icons/leftSingleArrow.svg";
import {ReactComponent as LeftDoubleArrow} from "../../../assets/chatCompAssets/icons/leftDoubleArrow.svg";
import {ReactComponent as RightSingleArrow} from "../../../assets/chatCompAssets/icons/rightSingleArrow.svg";
import {ReactComponent as RightDoubleArrow} from "../../../assets/chatCompAssets/icons/rightDoubleArrow.svg";
import Endpoint from "../../../utils/Endpoint";
import {CircularProgress} from "@mui/material";
import {toast} from "react-toastify";
import {TaskAssignedToast, OpenAIErrorToast} from "../../../components/Tostify/SuccessToast/SuccessTostify";

const CustomSchedule = ({open, onClose, messageid, text_id, selectedText, fetchStatuses}) => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedHour, setSelectedHour] = useState("02");
  const [selectedMinute, setSelectedMinute] = useState("00");
  const [selectedSecond, setSelectedSecond] = useState("00");
  const [selectedPeriod, setSelectedPeriod] = useState("pm");
  const [loading, setLoading] = useState(false);
  const config = useAuthConfig();
  let {selectedProperty} = useSelector((state) => state.portfolio);
  const {userInformation} = useSelector((state) => state.auth);



  const handleSchedule = async () => {
    const formattedDateTime = selectedDate
      .hour(selectedPeriod === "pm" && selectedHour !== "12" ? +selectedHour + 12 : +selectedHour)
      .minute(+selectedMinute)
      .second(+selectedSecond)
      .toISOString(); 

    const queryParams = {
      Message_id: messageid,
      text_id: text_id,
      asset_id: selectedProperty?._id,
      user_id: userInformation?._id,
    };

    const queryString = new URLSearchParams(queryParams).toString();

    const payload = {
      text_data: JSON.stringify({
        header: selectedText.header,
        description: selectedText.text,
      }),
      scheduledFor: formattedDateTime,
      taskName: selectedText.header || "Follow-Up Task",
      taskDesc: selectedText.text || "No description provided.",
      desc: selectedText.text, 
      date: formattedDateTime, 
      owner_id: userInformation?._id,
    };

    try {
      setLoading(true);
      const response = await axios.post(`${Endpoint.FollowUp}?${queryString}`, payload, config);

      if (response.status === 200 || response.status === 201) {
        toast.success(
          <TaskAssignedToast
            deadline={formattedDateTime}
            selectedUsers={[userInformation?.name || "User"]}
            onViewTasks={() => {
              onClose();
              window.location.href = `/${userInformation._id}/tasks`;
            }}
          />,
          {
            position: "top-right",
            autoClose: false,
            hideProgressBar: true,
          },
        );

        await fetchStatuses();
        onClose();
      } else {
        throw new Error(response?.data?.message || "Unexpected error occurred.");
      }
    } catch (error) {
      toast.error(
        <OpenAIErrorToast
          errorMessage={error?.message || "Failed to schedule. Please try again."}
          onClose={() => toast.dismiss()}
        />,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const formattedTime = `${selectedHour}:${selectedMinute}:${selectedSecond} ${selectedPeriod.toUpperCase()}`;

  return (
    <Modal
      title="Select Time and Date to Schedule Follow Up"
      open={open}
      onCancel={onClose}
      footer={null}
      centered
      className="ScheduleFollowUp-modal"
    >
      <div className="ScheduleFollowUp-container">
        <div className="ScheduleFollowUp-calendarSection">
          <div className="ScheduleFollowUp-calendarHeader">
            <div className="arrowSection">
              <button
                className="ScheduleFollowUp-calendarButton"
                onClick={() => setSelectedDate(selectedDate.subtract(1, "year"))}
              >
                <LeftSingleArrow />
              </button>
              <button
                className="ScheduleFollowUp-calendarButton"
                onClick={() => setSelectedDate(selectedDate.subtract(1, "month"))}
              >
                <LeftDoubleArrow />
              </button>
            </div>
            <span className="ScheduleFollowUp_calender_name">{selectedDate?.format("MMMM YYYY")}</span>
            <div className="arrowSection">
              <button
                className="ScheduleFollowUp-calendarButton"
                onClick={() => setSelectedDate(selectedDate.add(1, "month"))}
              >
                <RightDoubleArrow />
              </button>
              <button
                className="ScheduleFollowUp-calendarButton"
                onClick={() => setSelectedDate(selectedDate.add(1, "year"))}
              >
                <RightSingleArrow />
              </button>
            </div>
          </div>

          <div className="ScheduleFollowUp-calendarGrid">
            <div className="weekBox">
              {["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"].map((day) => (
                <div
                  key={day}
                  className="ScheduleFollowUp-dayName"
                >
                  {day}
                </div>
              ))}
            </div>

            <div className="daysBox">
              {(() => {
                const startOfMonth = selectedDate.startOf("month");
                const endOfMonth = selectedDate.endOf("month");
                const daysInMonth = selectedDate.daysInMonth();
                const startDayOfWeek = startOfMonth.day();
                const adjustedStartDay = startDayOfWeek === 0 ? 6 : startDayOfWeek - 1;
                const totalCells = 42;
                const previousMonth = startOfMonth.subtract(1, "month");
                const nextMonth = startOfMonth.add(1, "month");
                const prevDays = Array.from({length: adjustedStartDay}, (_, i) => previousMonth.endOf("month").subtract(adjustedStartDay - i - 1, "day"));
                const currentDays = Array.from({length: daysInMonth}, (_, i) => startOfMonth.add(i, "day"));
                const nextDays = Array.from({length: totalCells - (prevDays.length + currentDays.length)}, (_, i) => endOfMonth.add(i + 1, "day"));
                const allDates = [...prevDays, ...currentDays, ...nextDays];

                return allDates?.map((date, index) => {
                  const isToday = date.isSame(dayjs(), "day");
                  const isCurrentMonth = date.month() === selectedDate.month();
                  const isPastDate = date.isBefore(dayjs(), "day"); // Check if the date is in the past

                  return (
                    <div
                      key={index}
                      className={`ScheduleFollowUp-dateCell 
            ${isToday ? "ScheduleFollowUp-today" : ""} 
            ${isCurrentMonth ? "" : "ScheduleFollowUp-otherMonth"} 
            ${date.isSame(selectedDate, "day") ? "ScheduleFollowUp-selected" : ""} 
            ${isPastDate ? "ScheduleFollowUp-disabled" : ""}
          `}
                      onClick={() => !isPastDate && isCurrentMonth && setSelectedDate(date)} // Disable click for past dates
                    >
                      {date.date()}
                    </div>
                  );
                });
              })()}
            </div>
          </div>
        </div>
        {/* Time Section */}
        <div className="ScheduleFollowUp-timeSection">
          <div className="ScheduleFollowUp-selectedTime">
            <p>{formattedTime}</p>
          </div>
          <div className="ScheduleFollowUp-timeColumns">
            <div className="ScheduleFollowUp-timeColumn">
              <div className="ScheduleFollowUp-scrollContainer">
                {Array?.from({length: 12})?.map((_, i) => {
                  const hour = (i + 1).toString().padStart(2, "0");
                  return (
                    <div
                      key={hour}
                      className={`ScheduleFollowUp-timeCell ${selectedHour === hour ? "ScheduleFollowUp-selected" : ""}`}
                      onClick={() => setSelectedHour(hour)}
                    >
                      {hour}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="ScheduleFollowUp-timeColumn">
              <div className="ScheduleFollowUp-scrollContainer">
                {Array?.from({length: 60})?.map((_, i) => {
                  const minute = i.toString().padStart(2, "0");
                  return (
                    <div
                      key={minute}
                      className={`ScheduleFollowUp-timeCell ${selectedMinute === minute ? "ScheduleFollowUp-selected" : ""}`}
                      onClick={() => setSelectedMinute(minute)}
                    >
                      {minute}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="ScheduleFollowUp-timeColumn">
              <div className="ScheduleFollowUp-scrollContainer">
                {Array?.from({length: 60})?.map((_, i) => {
                  const second = i.toString().padStart(2, "0");
                  return (
                    <div
                      key={second}
                      className={`ScheduleFollowUp-timeCell ${selectedSecond === second ? "ScheduleFollowUp-selected" : ""}`}
                      onClick={() => setSelectedSecond(second)}
                    >
                      {second}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="ScheduleFollowUp-timeColumn">
              <div
                className="ScheduleFollowUp-scrollContainer"
                style={{flexDirection: "column-reverse", justifyContent: "flex-end"}}
              >
                {["am", "pm"]?.map((period) => (
                  <div
                    key={period}
                    className={`ScheduleFollowUp-timeCell ${selectedPeriod === period ? "ScheduleFollowUp-selected" : ""}`}
                    onClick={() => setSelectedPeriod(period)}
                  >
                    {period.toUpperCase()}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ScheduleFollowUp_button_container">
        <Button
          variant="contained"
          fullWidth
          onClick={handleSchedule}
          disabled={loading}
          className="ScheduleFollowUp-scheduleButton"
        >
          {loading ? (
            <CircularProgress
              size={24}
              color="inherit"
            />
          ) : (
            "Schedule"
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default CustomSchedule;
