import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import {useSelector} from "react-redux";
import "./Disposition.scss";
import PhotosThumbnailImg from "../../assets/portfolio/images/asset-photos-thumbnail.png";
import HomeIcon from "../../assets/portfolio/svgs/home.svg";
import DescriptionIcon from "../../assets/portfolio/svgs/description.svg";
import PriceIcon from "../../assets/portfolio/svgs/price.svg";
import WebsiteIcon from "../../assets/portfolio/svgs/link.svg";
import ContactIcon from "../../assets/portfolio/svgs/profile.svg";
import ContactAddIcon from "../../assets/portfolio/svgs/prrofile-plus.svg";
import EmailIcon from "../../assets/portfolio/svgs/email.svg";
import PhoneIcon from "../../assets/portfolio/svgs/phone.svg";
import DeleteIcon from "../../assets/portfolio/svgs/delete.svg";
import PhotosIcon from "../../assets/portfolio/svgs/pictures.svg";
import RotateIcon from "../../assets/portfolio/svgs/rotate.svg";
import PdfIcon from "../../assets/portfolio/svgs/pdf.svg";
import FolderIcon from "../../assets/portfolio/svgs/folder.svg";
import MessageIcon from "../../assets/portfolio/svgs/message.svg";
import RemoveIcon from "../../assets/portfolio/svgs/delete-red.svg";
import TagIcon from "../../assets/portfolio/svgs/tag.svg";
import ManagerImg from "../../assets/portfolio/images/manager-img.png";
import MemberImg from "../../assets/portfolio/images/member-image.png";
import {InputWithIcon, InputWithAddFieldFunc, UploadFile, TextInputWithLabel, DropdownWithCheckbox} from "../portfolio/Inputs";
import Checkbox from "../profile/settings/Checkbox";
import EyeIcon from "../../assets/dealsroom/svg/eye.svg";
import SubCatIcon from "./SubCatIcon";
import {PropertyDocuments} from "./PropertyDocuments";
import {UtilitiesArrangement} from "./UtilitiesArrangement";
import {PropertyInfo} from "./PropertyInfo";
import {PropertyPlan} from "./PropertyPlan";
import {Amenities} from "./Amenities";
import {returnMessages} from "../../store/reducers/message";
import {returnErrors} from "../../store/reducers/error";
import {useDispatch} from "react-redux";
// import {selectSelectedProperty} from "../../store/reducers/selectedProperty.js";
import {fetchPortfolio, selectProperty, updateProperty} from "../../store/reducers/portfolioSlice.js";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, CircularProgress} from "@mui/material";

const Card = ({type}) => {
  const [propertyTabIndex, setPropertyTabIndex] = useState(0);
  const [utilityTabIndex, setUtilityTabIndex] = useState(0);
  const [editManager, setEditManager] = useState(true);
  const [showAdditionalFields, setShowAdditionalFields] = useState(true);
  // const [assetsData, setAssetsData] = useState([]);
  const [formData, setFormData] = useState({});
  const {userInformation} = useSelector((state) => state.userInfo);
  const {idToken} = useSelector((state) => state.auth);
  let dispatch = useDispatch();
  const [imageList, setImageList] = useState();
  const [uploading, setUploading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [loadingloader, setLoading] = useState(false);
  const selectedProperty = useSelector((state) => state.portfolio.selectedProperty);
  const {AssectDataOwnerOperator, loading, error} = useSelector((state) => state.portfolio);
  const propertyTabs = ["Info", "Plan", "Documents"];
  const utilityTabs = ["Utilities Arrangement", "Amenities"];
  const [selectedFile, setSelectedFile] = useState(null);
  const selectedPropertydata = useSelector((state) => state.portfolio.selectedProperty._id);
  let assetsData = useSelector((state) => state.AdminGivenAccess.assets);
  const [message, setMessage] = useState("");

  const renderHeading = () => {
    switch (type) {
      case "AssetName":
        return "Asset Name";
      case "Description":
        return "Description";
      case "Price":
        return "Price";
      case "Contact":
        return "Contact";
      case "Website":
        return "Website";
      case "Photos":
        return "Photos, Videos, 360° / VR tours";
      case "Marketing":
        return "Marketing Brochure";
      case "PropertyDetails":
        return "Property Details";
      case "Utilities":
        return "Utilities Arrangement & Amenities";
      case "Asset":
        return "Asset Tours date & time set up ";
      // case "Manager":
      //   return "Manager and collaborators";
      // case "Tags":
      //   return "Tags";
      default:
        return "";
    }
  };

  // Image Upload

  const [previews, setPreviews] = useState([]);
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    const filePreviews = files.map((file) => URL.createObjectURL(file));
    setPreviews(filePreviews);
  };

  const handleUpload = async () => {
    if (selectedFiles && selectedFiles.length > 0) {
      const formData = new FormData();
      Array.from(selectedFiles).forEach((file) => {
        formData.append("image", file);
      });
      setUploading(true);
      try {
        const response = await axios.post(`/api/portfolio/upload-image?assetId=${selectedProperty._id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${idToken}`,
          },
        });

        if (response) {
          dispatch(fetchPortfolio(userInformation.email));
        }

        setUploading(false);
        dispatch(returnMessages("Images uploaded successfully.", 200));
      } catch (error) {
        let errorMsg = "Error uploading file";
        if (error.response) {
          errorMsg = `Server error: ${error.response.data}`;
          dispatch(returnErrors(errorMsg, error.response.status));
        } else if (error.request) {
          errorMsg = "Network error, please try again";
          dispatch(returnErrors(errorMsg, 500));
        } else {
          errorMsg = `Error: ${error.message}`;
          dispatch(returnErrors(errorMsg, 400));
        }
        setUploading(false);
      }
    }
  };
  const removeImage = (indexToRemove) => {
    const newFiles = selectedFiles.filter((_, index) => index !== indexToRemove);
    const newPreviews = previews.filter((_, index) => index !== indexToRemove);
    URL.revokeObjectURL(previews[indexToRemove]);
    setSelectedFiles(newFiles);
    setPreviews(newPreviews);
  };

  // Refresh data
  useEffect(() => {
    const filterData = AssectDataOwnerOperator.find((item) => item._id === selectedProperty._id);

    if (filterData) {
      dispatch(selectProperty(filterData));
    }
  }, [selectedProperty, AssectDataOwnerOperator]);

  // Update Data
  const handleUpdate = async () => {
    setLoading(true);
    try {
      if (!formData || !selectedProperty) {
        dispatch(returnErrors("Invalid form data or property", 400));
        return;
      }

      const updatedFields = {
        ...formData,
        propertyDetails: {...propertyDetails},
      };

      const response = await axios.patch(`api/portfolio/updateAsset/${selectedProperty._id}`, updatedFields, {
        headers: {
          "auth-token": idToken,
        },
      });

      if (response) {
        dispatch(fetchPortfolio(userInformation.email));
      }

      dispatch(
        updateProperty({
          propertyId: selectedProperty._id,
          updatedData: response.data,
          selectedItem: selectedProperty,
        }),
      );
      dispatch(returnMessages(`Updated Successfully: ${response.data}`, 200));
    } catch (error) {
      if (error.response) {
        dispatch(returnErrors(`Server error: ${error.response.data}`, error.response.status));
      } else if (error.request) {
        dispatch(returnErrors("Network error, please try again", 500));
      } else {
        dispatch(returnErrors(`Error: ${error.message}`, 400));
      }
    } finally {
      setLoading(false);
    }
  };

  const renderIcon = () => {
    const icons = {
      AssetName: HomeIcon,
      Description: DescriptionIcon,
      Price: PriceIcon,
      Contact: ContactIcon,
      Website: WebsiteIcon,
      Photos: PhotosIcon,
      Marketing: PdfIcon,
      PropertyDetails: FolderIcon,
      Utilities: FolderIcon,
      Asset: EyeIcon,
      Manager: ContactIcon,
      Tags: TagIcon,
    };
    return icons[type] || null;
  };

  const RenderpropertyTabContents = () => {
    switch (propertyTabIndex) {
      case 0:
        return (
          <PropertyInfo
            onChange={handlePropertyInfoChange}
            propertyDetails={propertyDetails}
          />
        );
      case 1:
        return <PropertyPlan />;
      case 2:
        return <PropertyDocuments />;
      default:
        return <PropertyInfo />;
    }
  };

  const RenderUtilityTabContents = () => {
    switch (utilityTabIndex) {
      case 0:
        return <UtilitiesArrangement />;
      case 1:
        return <Amenities />;
      default:
        return <UtilitiesArrangement />;
    }
  };

  const PermissionSubmodules = ({permissions, onPermissionChange}) => {
    const permissionLabels = {
      "Financial KPI": "Budgeting",
      OperatingIncomeExpenses: "Market Intelligence",
      "Market Analysis": "Deal proposal management",
      Utilities: "Maintenance & Utilities",
      Maintenance: "Inventory Management",
      NewsSummaries: "Disposition",
      SocialNetwork: "Social Network",
      AssetReviews: "Asset Reviews",
      PotentialProsCons: "Potential Pros & Cons",
      assetName: "Asset Name",
      assetDescription: "Asset Description",
    };

    return (
      <div className="permission-submodules">
        {Object.entries(permissionLabels).map(([key, label]) => (
          <Checkbox
            key={key}
            label={label}
            checked={permissions[key] || false}
            onChange={(newValue) => onPermissionChange(key, newValue)}
          />
        ))}
      </div>
    );
  };

  const [propertyDetails, setPropertyDetails] = useState({
    lotArea: "",
    zoning: "",
    totalUnits: "",
    buildingArea: "",
    yearBuilt: "",
  });
  const handlePropertyInfoChange = (field, value) => {
    setPropertyDetails((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleInputChange = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  // File Upload

  const handleFileChangepdf = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUploadfile = async () => {
    if (!selectedFile) {
      setMessage("Please select a file to upload");
      return;
    }

    setUploading(true);
    setMessage("");

    const formData = new FormData();
    formData.append("files", selectedFile);

    try {
      const response = await axios.post("api/Portfolio/uploadPdf", formData, {
        params: {
          assetId: selectedPropertydata,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        setMessage("File uploaded successfully");
      } else {
        throw new Error("File upload failed");
      }
    } catch (error) {
      setMessage("Error uploading file");
    } finally {
      setUploading(false);
    }
  };

  // Delete Image
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const handleClickOpen = (imgSrc) => {
    setCurrentImage(imgSrc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentImage(null);
  };

  const handleImageDelete = async () => {
    setLoading(true);
    try {
      const response = await axios.delete(`/api/Portfolio/delete_image`, {
        headers: {
          "auth-token": idToken,
        },
        params: {
          assetId: selectedProperty?._id,
          imageUrl: currentImage,
        },
      });

      if (response.status === 200) {
        dispatch(fetchPortfolio(userInformation.email));
        dispatch(returnMessages("Image deleted successfully.", 200));
      }
    } catch (error) {
      dispatch(returnErrors("Error deleting image, please try again", 500));
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const RenderCardContent = () => {
    switch (type) {
      case "AssetName":
        return (
          <div>
            {/* <h2>{selectedProperty?.assetName}</h2> */}
            <input
              className="asset-name"
              value={formData.assetName || selectedProperty?.assetName || ""}
              onChange={(e) => handleInputChange("assetName", e.target.value)}
            />
          </div>
        );
      case "Description":
        return (
          <div className="description">
            {/* <h2>{selectedProperty?.assetDescription}</h2> */}
            <textarea
              className="text_area_Description"
              placeholder="Value"
              rows="6"
              name="description"
              value={formData?.assetDescription || selectedProperty?.assetDescription || ""}
              onChange={(e) => handleInputChange("assetDescription", e.target.value)}
            ></textarea>
          </div>
        );
      case "Price":
        return (
          <div className="Card_Assetvalue">
            {/* <h2>$ {selectedProperty?.assetValue}</h2> */}
            <div className="price-input-container">
              <span className="currency-symbol">$</span>
              <input
                type="text"
                placeholder="Enter price"
                value={formData.assetValue || selectedProperty?.assetValue || ""}
                onChange={(e) => handleInputChange("assetValue", e.target.value)}
              />
            </div>
          </div>
        );
      case "Contact":
        return (
          <div className="contacts-container">
            <h2>Primary Contact</h2>
            <div>
              {/* <h1>Name - {selectedProperty?.addedBy}</h1> */}
              <input
                label={"NAME"}
                className="asset-name"
                placeholder={"Tom Waits"}
                imageSrc={ContactIcon}
                value={formData.addedBy || selectedProperty?.addedBy || ""}
                onChange={(e) => handleInputChange("addedBy", e.target.value)}
              />
            </div>
            <div>
              {/* <h1>Email - {selectedProperty?.addedByEmail}</h1> */}
              <input
                label={"EMAIL"}
                className="asset-name"
                placeholder={"sales1@hotels.biz"}
                imageSrc={EmailIcon}
                value={formData.addedByEmail || selectedProperty?.addedByEmail || ""}
                onChange={(e) => handleInputChange("addedByEmail", e.target.value)}
              />
            </div>
            {/* <h1>Phone - {selectedProperty?.contactPhone}</h1> */}
            <input
              label={"PHONE"}
              className="asset-name"
              placeholder={"+1 404-795-4989"}
              imageSrc={PhoneIcon}
              value={formData.contactPhone || selectedProperty?.contactPhone || ""}
              onChange={(e) => handleInputChange("contactPhone", e.target.value)}
            />
            {/* {showAdditionalFields && (
              <div>
                <div className="additional-contact-header">
                  <h2>Additional contact</h2>
                  <img
                    src={DeleteIcon}
                    alt=""
                    onClick={() => setShowAdditionalFields(false)}
                  />
                </div>
                <InputWithIcon
                  label={"NAME"}
                  placeholder={"Tom Waits"}
                  imageSrc={ContactIcon}
                  value={formData.additionalContactName || selectedProperty?.additionalContactName || ""}
                  onChange={(e) => handleInputChange("additionalContactName", e.target.value)}
                />
                <InputWithIcon
                  label={"EMAIL"}
                  placeholder={"sales1@hotels.biz"}
                  imageSrc={EmailIcon}
                  value={formData.additionalContactEmail || selectedProperty?.additionalContactEmail || ""}
                  onChange={(e) => handleInputChange("additionalContactEmail", e.target.value)}
                />
                <InputWithAddFieldFunc
                  label={"PHONE"}
                  placeholder={"+1 404-795-4989"}
                  imageSrc={PhoneIcon}
                  value={formData.additionalContactPhone || selectedProperty?.additionalContactPhone || ""}
                  onChange={(e) => handleInputChange("additionalContactPhone", e.target.value)}
                />
              </div>
            )}
            <Link className="contact-button">
              <img
                src={ContactAddIcon}
                alt="Contact icon"
              />
              <h4>Add contact</h4>
            </Link> */}
          </div>
        );
      case "Website":
        return (
          <h2 className="website-container">
            {/* <h2>{selectedProperty?.contactWebsite ? `Website - ${selectedProperty.contactWebsite}` : "Add Website"}</h2> */}
            <input
              className="asset-name"
              type="text"
              value={formData.contactWebsite || selectedProperty?.contactWebsite || ""}
              onChange={(e) => handleInputChange("contactWebsite", e.target.value)}
            />
          </h2>
        );
      case "Photos":
        return (
          <div className="photo-container">
            <div className="photo-card-grid">
              {selectedProperty?.assetsImages.map((imgSrc, index) => (
                <div
                  className="photos-card"
                  key={index}
                >
                  <img
                    src={imgSrc}
                    alt="Image"
                    className="assets_images"
                  />
                  <div className="controls-wrapper">
                    {/* <img
                      src={RotateIcon}
                      alt="Rotate Icon"
                    /> */}
                    <img
                      src={DeleteIcon}
                      alt="Delete Icon"
                      onClick={() => handleClickOpen(imgSrc)}
                    />
                  </div>
                </div>
              ))}
            </div>

            <h2>ADD MULTIMEDIA</h2>

            <div className="preview-container">
              {previews?.map((preview, index) => (
                <div
                  key={index}
                  className="preview-wrapper"
                >
                  <img
                    src={preview}
                    alt={`Preview ${index + 1}`}
                    className="preview-image"
                  />
                  <span
                    onClick={() => removeImage(index)}
                    className="close-icon"
                  >
                    &times;
                  </span>
                </div>
              ))}
            </div>

            <div className="file-input-wrapper">
              <input
                type="file"
                id="file-input"
                onChange={handleFileChange}
                style={{display: "none"}}
                multiple
              />
              <label
                htmlFor="file-input"
                className="file-input-label"
              >
                Select Image
              </label>
            </div>

            <button
              type="button"
              className={`update_card_button_image ${uploading ? "disabled" : ""}`}
              onClick={handleUpload}
              disabled={uploading}
            >
              {uploading ? <div className="spinner" /> : "Upload Image"}
            </button>
            <Dialog
              open={open}
              onClose={handleClose}
            >
              <DialogTitle>{"Confirm Deletion"}</DialogTitle>
              <DialogContent>
                <DialogContentText>Are you sure you want to delete this image? This action cannot be undone.</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="primary"
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleImageDelete}
                  color="secondary"
                  autoFocus
                  disabled={loading}
                >
                  {loadingloader ? <CircularProgress size={24} /> : "Delete"}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      case "Marketing":
        return (
          <div className="pdf_file_upload">
            <h2>Marketing PDF</h2>
            <div className="file-input-wrapper">
              {/* <input
                type="file"
                id="file-input"
                onChange={handleFileChangepdf}
              /> */}
              <input
                type="file"
                id="file-input"
                onChange={handleFileChangepdf}
                style={{display: "none"}}
              />
              <label
                htmlFor="file"
                className="file-input-label"
              >
                Select PDF File
              </label>
            </div>
            <button
              type="button"
              className={`update_card_button_pdf ${uploading ? "disabled" : ""}`}
              onClick={handleUploadfile}
              disabled={uploading}
            >
              {uploading ? "Uploading..." : "Upload"}
            </button>
            {message && <p style={{color: "red", marginLeft: "4%"}}>{message}</p>}
          </div>
        );
      case "PropertyDetails":
        return (
          <div className="property-details-container">
            <div className="property-tabs">
              {propertyTabs.map((tab, index) => (
                <Link
                  key={index}
                  className={propertyTabIndex === index ? "active" : ""}
                  onClick={() => setPropertyTabIndex(index)}
                >
                  {tab}
                </Link>
              ))}
            </div>
            <div className="property-tab-content">{RenderpropertyTabContents()}</div>
          </div>
        );
      case "Utilities":
        return (
          <div className="utility-details-container">
            <div className="utility-tabs">
              {utilityTabs.map((tab, index) => (
                <Link
                  key={index}
                  className={utilityTabIndex === index ? "active" : ""}
                  onClick={() => setUtilityTabIndex(index)}
                >
                  {tab}
                </Link>
              ))}
            </div>
            <div className="utility-tab-content">{RenderUtilityTabContents()}</div>
          </div>
        );
        // case "Manager":
        const options = ["Budgeting", "Market Intelligence", "Deal proposal management", "Maintenance & Utilities", "Inventory Management", "Disposition"];
        return (
          <div className="manager-container">
            <h2>{editManager ? "Asset Manager" : "Assign Manager"}</h2>
            {!editManager ? (
              <div className="manager-details">
                <div className="manager-profile">
                  <img
                    src={ManagerImg}
                    alt="Manager"
                  />
                  <div className="manager-name">
                    <h4>Jeff Morgan</h4>
                    <p>Marketing</p>
                  </div>
                </div>
                <Link
                  className="manager-edit-button"
                  onClick={() => setEditManager(true)}
                >
                  <h4>Add Utility</h4>
                </Link>
              </div>
            ) : (
              <div className="edit-manager">
                <div className="edit-input-wrapper">
                  <input
                    type="text"
                    name="assignManager"
                    id="assignManager"
                    placeholder="Name or email address"
                    onChange={(e) => handleInputChange("assignManager", e.target.value)}
                  />
                  <div className="btn-group">
                    <Link
                      className="edit-input-button"
                      style={{backgroundImage: "linear-gradient(to top, #7858c0, #8164c1)"}}
                      onClick={() => setEditManager(false)}
                    >
                      <h4 style={{color: "white"}}>Save</h4>
                    </Link>
                    <Link
                      className="edit-input-button"
                      style={{backgroundImage: "linear-gradient(to top, #f7f8fa, #fefefe)"}}
                      onClick={() => setEditManager(false)}
                    >
                      <h4>Cancel</h4>
                    </Link>
                  </div>
                </div>
                <p>There must be at least one manager on this page.</p>
              </div>
            )}
            <div className="line">&nbsp;</div>
            <h2>COLLABORATORS ({assetsData.length})</h2>
            <h2>ADD PEOPLE</h2>
            <div className="add-collaborator-wrapper">
              <input
                type="text"
                name="addCollaborator"
                id="addCollaborator"
                placeholder="Name or email address"
                onChange={(e) => handleInputChange("addCollaborator", e.target.value)}
              />
              <DropdownWithCheckbox options={options} />
              <Link
                className="edit-input-button"
                style={{backgroundImage: "linear-gradient(to top, #7858c0, #8164c1)"}}
              >
                <h4 style={{color: "white"}}>Save</h4>
              </Link>
            </div>
            <p className="add-collaborator-info">After people will be notified by email they have to confirm invitation.</p>
            {/* <div className="member-permission-container">
              {assetsData.map((member, index) => (
                <div
                  className="member-permission-card"
                  key={index}
                >
                  <div className="grid-1 member-detail">
                    <img
                      src={MemberImg}
                      alt="image"
                    />
                    <div className="member-name">
                      <h6>{member.userName}</h6>
                      <p>{member.accountType}</p>
                    </div>
                  </div>
                  <div className="grid-2 member-chat">
                    <Checkbox label="Can invite others" />
                    <Link className="start-chat-button">
                      <img
                        src={MessageIcon}
                        alt="Contact icon"
                      />
                      <h4>Add Utility</h4>
                    </Link>
                  </div>
                  <div className="grid-3 remove-wrapper">
                    <img
                      src={RemoveIcon}
                      alt="image"
                    />
                    <h6>Remove</h6>
                  </div>
                  <div className="grid-4 permission-options">
                    <div className="permission-module-header">
                      <SubCatIcon /> <p>All</p>
                    </div>
                    {member.assetPermissions.map((permission, idx) => (
                      <PermissionSubmodules
                        key={idx}
                        permissions={permission.permissions}
                        onPermissionChange={(key, newValue) => {}}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div> */}
          </div>
        );
        // case "Tags":
        const tags = ["Della Garrett", "river side"];
        return (
          <div className="tags-container">
            <div className="assign-input-wrapper">
              <input
                type="text"
                name="tags"
                id="tags"
                placeholder="Start Typing"
                value={formData.tags || ""}
                onChange={(e) => handleInputChange("tags", e.target.value)}
              />
              <Link
                className="edit-input-button"
                style={{backgroundImage: "linear-gradient(to top, #7858c0, #8164c1)"}}
              >
                <h4 style={{color: "white"}}>Assign</h4>
              </Link>
            </div>
            <div className="tags-list">
              {tags.map((tag, index) => (
                <div
                  key={index}
                  className="tag"
                >
                  <h6>{tag}</h6>
                  <button onClick={() => handleRemoveTag(index)}>x</button>
                </div>
              ))}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleRemoveTag = (index) => {
    setFormData((prevState) => {
      const newTags = [...prevState.tags];
      newTags.splice(index, 1);
      return {
        ...prevState,
        tags: newTags,
      };
    });
  };

  return (
    <div className="card-container">
      <div className="card-header">
        <img
          src={renderIcon()}
          alt="card-icon"
        />
        <h3 className="card-heading">{renderHeading()}</h3>
      </div>
      <div className="card-content">{RenderCardContent()}</div>
      <div className="card-footer">
        <button
          onClick={handleUpdate}
          className="update_card_button"
          disabled={loadingloader}
        >
          {loadingloader ? (
            <CircularProgress
              size={24}
              style={{color: "#fff", marginRight: "10px"}}
            />
          ) : (
            "Update"
          )}
        </button>
      </div>
    </div>
  );
};

export default Card;
