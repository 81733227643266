import React, {useEffect, useState} from "react";
import "./BlockchainOnboard.scss";
import basic_info from "../../assets/svg/basic_info_icon.svg";
import tokenizeIcon from "../../assets/svg/tokenize.svg";
import create_token_icon from "../../assets/svg/create_token.svg";
import accreditation_icon from "../../assets/svg/Accreditation.svg";
import payments_icon from "../../assets/svg/payment_icon.svg";
import confirmation_icon from "../../assets/svg/confirmation_icon.svg";
import calender_icon from "../../assets/svg/calendar 1.svg";
import india_flag from "../../assets/svg/india_flag.svg";
import upload_video from "../../assets/svg/upload_video.svg";
import walletIocn from "../../assets/svg/wallet 1.svg";
import debt_icon from "../../assets/svg/debt_svg.svg";
import real_estate_icon from "../../assets/svg/real_eastate_icon.svg";
import private_equity_icon from "../../assets/svg/private_equty_icon.svg";
import cmpls from "../../assets/svg/cmpls_icons.svg";
import configIcon from "../../assets/svg/configure_icon.svg";
import execute_icon from "../../assets/svg/execute_icon.svg";
import logoUploadIcon from "../../assets/svg/logo_upload_icon.svg";
import symbol_upload_icon from "../../assets/svg/symbol_upload_icon.svg";
import smart_contract_icon from "../../assets/svg/smart_contract_icon.svg";
import defaul_smartContract from "../../assets/svg/new_d_contract.svg";
import create_template_icon from "../../assets/svg/template_icon.svg";
import Accreditation_icon from "../../assets/svg/Accreditation.svg";
import wallet_icon from "../../assets/svg/wallet_icon_svg.svg";
import crypto_icon from "../../assets/svg/crypto_icon.svg";
import card from "../../assets/svg/card_icon.svg";
import center_arrow from "../../assets/svg/center_arrow.svg";
import pay_icon from "../../assets/svg/pay_icon.svg";
import received_icon from "../../assets/svg/received_icon.svg";
import idImage from "../../assets/svg/id_image.svg";
import cpy_icon from "../../assets/svg/cpy_icon.svg";
import code_img from "../../assets/svg/code_img.svg";
import threTwo from "../../assets/svg/32Per.svg";
import twoNine from "../../assets/svg/29Per.svg";
import twoOne from "../../assets/svg/twoOne.svg";
import checked_icon from "../../assets/svg/agree_checked_icon.svg";

const BlockchainOnboard = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [heading, setHeading] = useState("Basic information");

  const tabination = [
    {
      tab_name: "Basic information",
      tab_icon: basic_info,
    },
    {
      tab_name: "what do you want to tokenize ?",
      tab_icon: tokenizeIcon,
    },
    {
      tab_name: "Create your token",
      tab_icon: create_token_icon,
    },

    {
      tab_name: "Compliance",
      tab_icon: cmpls,
    },
    {
      tab_name: "Execute Code",
      tab_icon: execute_icon,
    },
    {
      tab_name: "Accreditation",
      tab_icon: Accreditation_icon,
    },
    {
      tab_name: "Payments",
      tab_icon: payments_icon,
    },
  ];

  return (
    <div className="onBoard_container">
      <div className="heading">
        <h1>{heading}</h1>
      </div>
      <div className="tabination_header">
        {tabination?.map((item, index) => {
          const {tab_name, tab_icon} = item;
          return (
            <p
              onClick={() => {
                setActiveTab(index);
                setHeading(tab_name);
              }}
              className={activeTab === index ? "tab_name activeTab" : "tab_name"}
              key={index}
            >
              <img
                src={tab_icon}
                alt=""
              />
              {tab_name}
            </p>
          );
        })}
      </div>
      <div className="content">
        {activeTab === 0 && <BasicInfo />}
        {activeTab === 1 && <Tokenize />}
        {activeTab === 2 && <Configure />}
        {activeTab === 3 && <Compliance />}
        {activeTab === 4 && <ExecuteCode />}
        {activeTab === 5 && <Accreditation />}
        {activeTab === 6 && <Payments />}

        <PrevNextBar
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />
      </div>
    </div>
  );
};

export default BlockchainOnboard;

const BasicInfo = () => {
  return (
    <div className="BlockchainOnboard-container">
      <div className="basic_info">
        <div className="indentity_information">
          <h1>Identity information</h1>
          <div className="form_container">
            <div className="form_row">
              <div className="input_group">
                <label htmlFor="f_name">First Name</label>
                <input type="text" placeholder="John" id="f_name" />
              </div>
              <div className="input_group">
                <label htmlFor="l_name">Last Name</label>
                <input type="text" placeholder="Doe" id="l_name" />
              </div>
            </div>
            <div className="form_row">
              <div className="input_group">
                <label>Date of Birth</label>
                <div className="dob_input">
                  <label htmlFor="dob">
                    <img src={calender_icon} alt="calendar icon" />
                  </label>
                  <input type="text" placeholder="Select date of birth" id="dob" />
                </div>
              </div>
              <div className="input_group">
                <label>Nationality</label>
                <select>
                  <option value="India">India</option>
                  <option value="America">America</option>
                  <option value="Canada">Canada</option>
                </select>
              </div>
            </div>
            <div className="input_group">
              <label>City</label>
              <select>
                <option value="">Select City</option>
                <option value="Pune">Pune</option>
                <option value="Delhi">Delhi</option>
                <option value="Gurugram">Gurugram</option>
              </select>
            </div>
            <div className="form_row">
              <div className="input_group">
                <label>Phone Number</label>
                <div className="dob_input">
                  <label htmlFor="phone">
                    <img src={india_flag} alt="India flag" />
                  </label>
                  <input type="text" placeholder="+91 8798798798" id="phone" />
                </div>
              </div>
              <div className="input_group">
                <label>Email Id</label>
                <input type="text" placeholder="email@example.com" id="email" />
              </div>
            </div>
            <div className="form_row">
              <div className="input_group">
                <label>Occupation</label>
                <select>
                  <option value="">Eg. Developer</option>
                  <option value="Management">Management</option>
                  <option value="Team">Team</option>
                </select>
              </div>
              <div className="input_group">
                <label>Designation</label>
                <select>
                  <option value="">Eg. Sr. Developer</option>
                  <option value="Manager">Manager</option>
                  <option value="Lead">Lead</option>
                </select>
              </div>
            </div>
            <div className="input_group">
              <label>Sources of funds</label>
              <select>
                <option value="">Fund</option>
                <option value="Lorem1">Lorem1</option>
                <option value="Lorem2">Lorem2</option>
                <option value="Lorem3">Lorem3</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="basic_info">
        <div className="indentity_information">
          <h1>Residence</h1>
          <div className="form_container">
            <div className="form_row">
              <div className="input_group">
                <label htmlFor="building">Building number</label>
                <input type="text" placeholder="For eg, Block 12, Marol Church Rd" id="building" />
              </div>
              <div className="input_group">
                <label htmlFor="street">Street</label>
                <input type="text" placeholder="For eg, near Airport Road, Bori Colony" id="street" />
              </div>
            </div>
            <div className="form_row">
              <div className="input_group">
                <label>City</label>
                <select>
                  <option value="Mumbai">Mumbai</option>
                  <option value="Pune">Pune</option>
                  <option value="Delhi">Delhi</option>
                </select>
              </div>
              <div className="input_group">
                <label>State</label>
                <select>
                  <option value="Maharashtra">Maharashtra</option>
                  <option value="Gujarat">Gujarat</option>
                  <option value="Karnataka">Karnataka</option>
                </select>
              </div>
            </div>
            <div className="input_group">
              <label>Country</label>
              <div className="select_country">
                <img src={india_flag} alt="India flag" />
                <select>
                  <option value="India">India</option>
                  <option value="Lorem1">Lorem1</option>
                  <option value="Lorem2">Lorem2</option>
                  <option value="Lorem3">Lorem3</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="documents">
        <h1>Documents</h1>
        <div className="video_upload_box">
          <p>Video Verification</p>
          <div className="video_input">
            <img src={upload_video} alt="upload video" />
            <p>Drag & Drop your video file here</p>
            <span>mp4, MOV, or AVI, Smaller than 200MB</span>
            <input type="file" id="video_file" style={{ display: 'none' }} />
            <label htmlFor="video_file" className="choose_btn">Choose file</label>
          </div>
        </div>
      </div>
    </div>
  );
};

const Tokenize = () => {
  const [selectedBox, setSelectedBox] = useState(0);
  const tokenizeItems = [
    {
      text: "Funds",
      desc: "Closed-end funds, VCs, and even UCITS can launch their security token offerings on a distributed ledger to increase the transferability of their shares and automate and streamline portfolio management for their investors",
      icon: walletIocn,
    },
    {
      text: "Debts",
      desc: "Tokenized debt accelates the issuance and transfers of securities. Participants can also automate dividend payments, compliance enforcements, and access global markets at their fingertips.",
      icon: debt_icon,
    },
    {
      text: "private equity",
      desc: "Tokenized Private Equity on a blockchain allows companies to utilize a digital interface to reach a  a global investor network and automate issuing, selling and managing the equity during its lifecycle. ",
      icon: private_equity_icon,
    },
    {
      text: "real estate asset",
      desc: "Tokenized real estate allows investors to streamline and digitize the entire onboarding and KYC processes, buying and selling of assets and bring much needed liquidity to their operation.",
      icon: real_estate_icon,
    },
  ];
  return (
    <div className="tokenize_container">
      <h1>Select One</h1>
      <div className="tokenize">
        {tokenizeItems?.map((item, index) => {
          const {text, desc, icon} = item;
          return (
            <div
              className={selectedBox === index ? "tokenize_box selectedBox" : "tokenize_box"}
              onClick={() => {
                setSelectedBox(index);
              }}
              key={index}
            >
              <img
                src={icon}
                alt=""
              />
              <div>
                <h1>{text}</h1>
                <p>{desc}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Configure = () => {
  const [selectedBox, setSelectedBox] = useState(0);
  const tokenType = [
    {
      name: "Token Logo",
      requiredFile: "JPEG or PNG, Smaller than 200MB",
      icon: logoUploadIcon,
    },
    {
      name: "Token Symbol",
      requiredFile: "JPEG or PNG, Smaller than 200MB",
      icon: symbol_upload_icon,
    },
  ];
  return (
    <div className="create-token_container">
      <div className="content">
        <div className="logo_and_symbol">
          {tokenType?.map((item, index) => {
            return (
              <div
                className={selectedBox === index ? "upload_box selected_box" : "upload_box"}
                onClick={() => {
                  setSelectedBox(index);
                }}
                key={index}
              >
                <img
                  src={item.icon}
                  alt=""
                />
                <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                  <h1>{item.name}</h1>
                  <p>{item.requiredFile}</p>
                  <button>Choose File</button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="form_container">
          <div className="token_name">
            <label htmlFor="">Token name</label>
            <input
              type="text"
              placeholder="Eg. TRUSTEMP34"
            />
          </div>
          <div className="numberOfToken">
            <label htmlFor="">No. Of token to be minted</label>
            <input
              type="text"
              placeholder="333000"
            />
          </div>
          <div className="token_holders">
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "4px"}}>
              <label htmlFor="">Token Holders</label>
              <label htmlFor="">% of token</label>
            </div>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
              <input
                type="text"
                placeholder="Danieal Radcliff"
              />
              <select
                name=""
                id=""
              >
                <option value="">12</option>
                <option value="">12</option>
                <option value="">12</option>
              </select>
            </div>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
              <input
                type="text"
                placeholder="Danieal Radcliff"
              />
              <select
                name=""
                id=""
              >
                <option value="">12</option>
                <option value="">12</option>
                <option value="">12</option>
              </select>
            </div>
            <div className="add_more">
              <button>+ Add More</button>
            </div>
          </div>
        </div>
        {/* {activeTab === 1 && (
        <div className="compliance_container">
          <div className="heading">
            <h1>Compliance</h1>
            <p>Please configure Compliance Smart Contract of your Token</p>
          </div>
          <div className="complience_boxes">
            {compliance?.map((item, index) => {
              return (
                <div
                  className={selectedBox === index ? "compliance_box selected_box" : "compliance_box"}
                  onClick={() => {
                    setSelectedBox(index);
                  }}
               key={index}
                >
                  <div className="img">
                    <img
                      src={item.icon}
                      alt=""
                    />
                  </div>
                  <div className="descri">
                    <p>{item.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="contract_name">
            <h1>Compliance Smart Contract Name</h1>
            <input
              type="text"
              placeholder="USDTTether Smart Compliance contract"
            />
          </div>
          <div className="feature">
            <h1>Compliance Features</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sstandard dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled.
            </p>
            <select
              name=""
              id=""
            >
              <option value="">Select Template</option>
              <option value="">lorem</option>
              <option value="">lorem</option>
            </select>
          </div>
        </div>
      )}
      {activeTab === 2 && (
        <div className="execute_code">
          <div className="token">
            <img
              src={idImage}
              alt=""
            />
            <h1>USDTTether</h1>
          </div>
          <div className="unit_in_circulation">
            <label htmlFor="">Units in Circulation</label>
            <input
              type="text"
              placeholder="333000"
            />
          </div>
          <div className="cost_usd">
            <div className="cost">
              <label htmlFor="">Cost of token</label>
              <select
                name=""
                id=""
              >
                <option value="">1</option>
              </select>
            </div>
            <div className="usd">
              <label htmlFor="">in USD</label>
              <select
                name=""
                id=""
              >
                <option value="">145.65</option>
              </select>
            </div>
          </div>
          <div className="token_address">
            <label htmlFor="">Token Address</label>
            <div className="token_input">
              <input
                type="text"
                placeholder="1BoatSLRHtKNngkdXEeobR76b53LETtpyT"
              />
              <img
                src={cpy_icon}
                alt=""
              />
            </div>
          </div>
          <div className="users">
            {userss?.map((item, index) => {
              return (
                <div
                  className="user_box"
                  style={{background:item.bgColor}}
                  onClick={() => {
                    setSelectedBox(index);
                  }}
                  key={index}
                >
                  <div className="img">
                    <img
                      src={item.icon}
                      alt=""
                    />
                  </div>
                  <div className="descri">
                    <p>{item.name}</p>
                    <span>{item.desc}</span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="code">
            <div className="heading">
            <div>
            <h1>Loreum Ipsum</h1>
            <p>Live building code of your security token</p>
            </div>
            <button>Copy Code</button>
            </div>
          <div className="code_img">
          <img src={code_img} alt="" width='100%' />
          </div>
          </div>
        </div>
      )} */}
      </div>
    </div>
  );
};

const Compliance = () => {
  // const [activeTab, setActiveTab] = useState(0);
  const [selectedBox, setSelectedBox] = useState(0);

  const compliance = [
    {
      icon: defaul_smartContract,
      desc: "Use Default compliance smart contract",
    },
    {
      icon: smart_contract_icon,
      desc: "Provide Smart Contract Address",
    },
    {
      icon: create_template_icon,
      desc: "Create a Template",
    },
  ];

  return (
    <div className="create-token_container">
      {/* <div className="tabination">
        {tabination?.map((item, index) => {
          const {tab_name, tab_icon} = item;
          return (
            <p
              // onClick={() => {
              //   setActiveTab(index);
              // }}
              className={activeTab === index ? "tab_name activeTab" : "tab_name"}
              key={index}
            >
              <img
                src={tab_icon}
                alt=""
              />
              {tab_name}
            </p>
          );
        })}
      </div> */}
      <div className="content">
        {/* {activeTab === 0 && (
          <>
            <div className="logo_and_symbol">
              {tokenType?.map((item, index) => {
                return (
                  <div
                    className={selectedBox === index ? "upload_box selected_box" : "upload_box"}
                    onClick={() => {
                      setSelectedBox(index);
                    }}
                    key={index}
                  >
                    <img
                      src={item.icon}
                      alt=""
                    />
                    <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                      <h1>{item.name}</h1>
                      <p>{item.requiredFile}</p>
                      <button>Choose File</button>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="form_container">
              <div className="token_name">
                <label htmlFor="">Token name</label>
                <input
                  type="text"
                  placeholder="Eg. TRUSTEMP34"
                />
              </div>
              <div className="numberOfToken">
                <label htmlFor="">No. Of token to be minted</label>
                <input
                  type="text"
                  placeholder="333000"
                />
              </div>
              <div className="token_holders">
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "4px"}}>
                  <label htmlFor="">Token Holders</label>
                  <label htmlFor="">% of token</label>
                </div>
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                  <input
                    type="text"
                    placeholder="Danieal Radcliff"
                  />
                  <select
                    name=""
                    id=""
                  >
                    <option value="">12</option>
                    <option value="">12</option>
                    <option value="">12</option>
                  </select>
                </div>
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                  <input
                    type="text"
                    placeholder="Danieal Radcliff"
                  />
                  <select
                    name=""
                    id=""
                  >
                    <option value="">12</option>
                    <option value="">12</option>
                    <option value="">12</option>
                  </select>
                </div>
                <div className="add_more">
                  <button>+ Add More</button>
                </div>
              </div>
            </div>
          </>
        )} */}

        <div className="compliance_container">
          <div className="heading">
            <h1>Compliance</h1>
            <p>Please configure Compliance Smart Contract of your Token</p>
          </div>
          <div className="complience_boxes">
            {compliance?.map((item, index) => {
              return (
                <div
                  className={selectedBox === index ? "compliance_box selected_box" : "compliance_box"}
                  onClick={() => {
                    setSelectedBox(index);
                  }}
                  key={index}
                >
                  <div className="img">
                    <img
                      src={item.icon}
                      alt=""
                    />
                  </div>
                  <div className="descri">
                    <p>{item.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="contract_name">
            <h1>Compliance Smart Contract Name</h1>
            <input
              type="text"
              placeholder="USDTTether Smart Compliance contract"
            />
          </div>
          <div className="feature">
            <h1>Compliance Features</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry'sstandard dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled.
            </p>
            <select
              name=""
              id=""
            >
              <option value="">Select Template</option>
              <option value="">lorem</option>
              <option value="">lorem</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

const ExecuteCode = () => {
  const [selectedBox, setSelectedBox] = useState(0);
  const userss = [
    {
      icon: threTwo,
      name: "Abhishek",
      desc: "lorem ispum",
      bgColor: "var(--Gradient-G-150, linear-gradient(52deg, rgba(83, 13, 233, 0.10) -21.09%, rgba(153, 111, 240, 0.10) 100.58%))",
    },
    {
      icon: twoNine,
      name: "Henry",
      desc: "lorem ispum",
      bgColor: "var(--Gradient-G-350, linear-gradient(161deg, rgba(66, 227, 151, 0.10) -36.23%, rgba(60, 183, 180, 0.10) 87.4%))",
    },
    {
      icon: twoOne,
      name: "Rupert",
      desc: "lorem ispum",
      bgColor: "var(--Gradient-G-450, linear-gradient(141deg, rgba(28, 228, 218, 0.10) -57.65%, rgba(89, 133, 232, 0.10) 84.08%))",
    },
    {
      icon: twoNine,
      name: "John",
      desc: "lorem ispum",
      bgColor: "var(--Gradient-G-950, linear-gradient(141deg, rgba(28, 228, 218, 0.10) -57.65%, rgba(89, 133, 232, 0.10) 84.08%))",
    },
    {
      icon: threTwo,
      name: "Richard",
      desc: "lorem ispum",
      bgColor: "var(--Gradient-G-550, linear-gradient(141deg, rgba(28, 228, 218, 0.10) -57.65%, rgba(89, 133, 232, 0.10) 84.08%))",
    },
  ];

  return (
    <div className="create-token_container">
      <div className="execute_code">
        <div className="token">
          <img
            src={idImage}
            alt=""
          />
          <h1>USDTTether</h1>
        </div>
        <div className="unit_in_circulation">
          <label htmlFor="">Units in Circulation</label>
          <input
            type="text"
            placeholder="333000"
          />
        </div>
        <div className="cost_usd">
          <div className="cost">
            <label htmlFor="">Cost of token</label>
            <select
              name=""
              id=""
            >
              <option value="">1</option>
            </select>
          </div>
          <div className="usd">
            <label htmlFor="">in USD</label>
            <select
              name=""
              id=""
            >
              <option value="">145.65</option>
            </select>
          </div>
        </div>
        <div className="token_address">
          <label htmlFor="">Token Address</label>
          <div className="token_input">
            <input
              type="text"
              placeholder="1BoatSLRHtKNngkdXEeobR76b53LETtpyT"
            />
            <img
              src={cpy_icon}
              alt=""
            />
          </div>
        </div>
        <div className="users">
          {userss?.map((item, index) => {
            return (
              <div
                className="user_box"
                style={{background: item.bgColor}}
                onClick={() => {
                  setSelectedBox(index);
                }}
                key={index}
              >
                <div className="img">
                  <img
                    src={item.icon}
                    alt=""
                  />
                </div>
                <div className="descri">
                  <p>{item.name}</p>
                  <span>{item.desc}</span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="code">
          <div className="heading">
            <div>
              <h1>Loreum Ipsum</h1>
              <p>Live building code of your security token</p>
            </div>
            <button>Copy Code</button>
          </div>
          <div className="code_img">
            <img
              src={code_img}
              alt=""
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Accreditation = () => {
  const [selectedBox, setSelectedBox] = useState(0);
  const infor = [
    {
      isUserAgree: "Yes, I agree",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy",
    },
    {
      isUserAgree: "No, i am a retail investor",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy",
    },
  ];
  return (
    <div className="Accreditation_container">
      <div className="acc_content">
        <div className="heading">
          <img
            src={Accreditation_icon}
            alt=""
          />
          <p>Loreum Ipsm</p>
        </div>
        <div className="sub_heading">
          <h1>To participate in the ticket sale, please prove your accreditation investor status.</h1>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
            galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It
            was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
          </p>
          {infor?.map((item, index) => {
            return (
              <div
                className={index === selectedBox ? "agree_notAgree selectedBox" : "agree_notAgree"}
                onClick={() => setSelectedBox(index)}
                key={index}
              >
                {selectedBox === index ? (
                  <img
                    src={checked_icon}
                    alt=""
                    height="50px"
                    width="50px"
                  />
                ) : (
                  <div
                    className="unchecked"
                    style={{height: "45px", width: "50px", borderRadius: "50%", background: "#FFFFFF"}}
                  ></div>
                )}
                <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                  <h2>{item.isUserAgree}</h2>
                  <p>{item.desc}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Payments = () => {
  const [selectedBox, setSelectedBox] = useState(0);
  const paymentOption = [
    {
      name: "Crypto",
      details: "BTC, ETH, EUR, GBP, USD, etc",
      icon: crypto_icon,
    },
    {
      name: "Cards",
      details: "Debit or Credit cards",
      icon: card,
    },
    {
      name: "Wallets",
      details: "Loreum ipsum, Loreum",
      icon: wallet_icon,
    },
  ];
  return (
    <div className="payment_container">
      <div className="option_container">
        <h1>Select Payment option</h1>
        <div className="payment_option">
          {paymentOption?.map((option, index) => {
            return (
              <div
                className={index === selectedBox ? "option selected_option" : "option"}
                onClick={() => setSelectedBox(index)}
                key={index}
              >
                <div className="logo">
                  <img
                    src={option.icon}
                    alt=""
                  />
                </div>
                <div className="name_details">
                  <h1>{option.name}</h1>
                  <p>{option.details}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="convert_amount">
        <h2>Enter the amount you want to convert</h2>
        <div className="pay_receive">
          <div className="pay">
            <label htmlFor="">Pay</label>
            <select
              name=""
              id=""
            >
              <option value="">110.352 BTC</option>
            </select>
          </div>
          <div style={{marginTop: "20px"}}>
            <img
              src={center_arrow}
              alt=""
            />
          </div>
          <div className="receive">
            <label htmlFor="">Pay</label>
            <select
              name=""
              id=""
            >
              <option value="">110.352 BTC</option>
              <option value="">110.352 BTC</option>
              <option value="">110.352 BTC</option>
            </select>
          </div>
        </div>
        <div className="info">
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem IpsumA</p>
          <span>Token price: 1TRU = 145.65USD</span>
        </div>
        <button>Buy</button>
      </div>
    </div>
  );
};

const PrevNextBar = ({setActiveTab, activeTab}) => {
  return (
    <div
      className="next_back_btns"
      style={{marginTop: "30px"}}
    >
      <button
        className="prev_btn"
        onClick={() => {
          setActiveTab(activeTab <= 0 ? 0 : activeTab - 1);
          window.scrollTo(0, 0);
        }}
      >
        Prev
      </button>
      {activeTab === 6 ? (
        <button className="submit_btn next_btn">Submit</button>
      ) : (
        <button
          className="next_btn"
          onClick={() => {
            setActiveTab(activeTab < 6 ? activeTab + 1 : 6);
            window.scrollTo(0, 0);
          }}
        >
          Next
        </button>
      )}
    </div>
  );
};
