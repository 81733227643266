import "./Safefinancings.scss";
import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import {PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import Brightness from "../../../assets/Manage-syndicate/Svg/Brightness.svg";
import shuttle from "../../../assets/Manage-syndicate/Svg/shuttle.svg";
import download from "../../../assets/Manage-syndicate/Svg/download.svg";
import filter from "../../../assets/Manage-syndicate/Svg/filter.svg";

const Safefinancings = ({completed}) => {
  return (
    <div>
    <div style={{marginTop: "10px", marginBottom: "10px"}}>
        <h1>SAFE Financings </h1>
      </div>
      <div>
        <div className="FundraisingSAFEFinancings">
          <div className="color-3">
            <div className="Safe-first-box-Conatiner">
              {/* Section-1 */}
              <div className="Safefinancings-first-box-data">
                <div className="Safefinancings-first-box-data-flex">
                  <div className="safe-image">
                    <img
                      src={Brightness}
                      alt="image"
                    />
                  </div>
                  <div className="text-data-safe">
                    <h1>Get customized SAFE market insights</h1>
                    <p>86% of pre- seed companies raise SAFE with post- money valuation caps</p>
                  </div>
                </div>
                <div className="container-data">
                  <p>Go to SAFE fundraise insights</p>
                  
                </div>
              </div>
            </div>
            <div className="Safefinancings-data">
              <div className="process-bar">
                <h1>Current SAFEs</h1>
                <div className="processbar-data">
                  <ProgressBar
                    // completed={completed}
                    isLabelVisible={false}
                    maxCompleted={50}
                    className="Safefinancings-progress-bar"
                  />
                </div>
                <div className="safe-box-data">
                  <div className="safe-flex-box">
                    <div className="Safefinancings-received">
                      <p>Received funds</p>
                      <p>US$0.00</p>
                    </div>
                    <div className="flex-box-data">
                      <h1>Financing Target = </h1>
                      <input
                        type="text"
                        name=""
                        id=""
                      />
                    </div>
                  </div>
                  <div className="safe-second-box">
                    <div className="safe-pending">
                      <p>Pending or outstanding funds</p>
                      <p>US$0.00</p>
                    </div>
                    <div className="safe-fincal-target">
                      <p>0</p>
                    </div>
                  </div>
                </div>
                <div className="button-components">
                  <div className="button-componts-buttoncompo">
                    <PrimaryButtonSvgFirst
                      name="Download"
                      classNames="Safefinancings-Download"
                      svgPath={download}
                      padding="10px"
                      svgHeight="20px"
                      svgWidth="20px"
                    />
                    <PrimaryButtonSvgFirst
                      name="Filter"
                      classNames="Safefinancings-Filter"
                      svgPath={filter}
                      padding="10px"
                      svgHeight="20px"
                      svgWidth="20px"
                    />
                  </div>
                  <div className="button-componts-buttoncompo">
                    <PrimaryButtonSvgFirst
                      name="Add Issue SAFE"
                      classNames="Safefinancings-Issue"
                      svgHeight="20px"
                      svgWidth="20px"
                    />
                    <PrimaryButtonSvgFirst
                      name="Create Safe"
                      classNames="button-Create"
                      padding="10px"
                      svgHeight="20px"
                      svgWidth="20px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="safe-finance-data">
            <div className="Safe-Carta">
              <div className="Safe-Carta-data">
                <h1>Carta Startup Stack</h1>
                <p>A curated directory for you with special offers on products to help you grow your company</p>
                <span>See All Offers</span>
              </div>
              <div className="shuttle">
                <img
                  src={shuttle}
                  alt="image"
                />
              </div>
            </div>
            <div className="Safe-Carta">
              <div className="Safe-Carta-data">
                <h1>Brek</h1>
                <p>Get a business cash accounts, credit card, and accounting all in one place to keep you in one control of your growth.</p>
                <p>As a Carta customer, you get 80,000 points ($800 value) after spending $1,000.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Safefinancings;
