import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highcharts3d from "highcharts/highcharts-3d";

highcharts3d(Highcharts);

const StackBarChart = () => {
  const barChartOptions = {
    chart: {
      type: "column",
      options3d: {
        enabled: true,
        alpha: 10,
        beta: 60,
        depth: 50,
        viewDistance: 55,
      },
    },
    xAxis: {
      visible: false, // Hide x-axis
      gridLineWidth: 0, // Remove grid lines
    },
    yAxis: {
      visible: false, // Hide y-axis
      gridLineWidth: 0, // Remove grid lines
    },
    title: {
      text: "", // Empty title
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      show: false,
      column: {
        stacking: "normal", // Enable stacking
        depth: 150,
      },
    },
    series: [
      {
        // name: "John",
        data: [20],
        color: "#8d57f2",
      },
      {
        // name: "Jane",
        data: [15],
        color: "#eb5757",
      },
      {
        // name: "Joe",
        data: [65],
        color: "#eaeaea",
      },
    ],
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={barChartOptions}
      />
    </div>
  );
};

export default StackBarChart;
