import React, {useEffect, useState} from "react";
import OffmarketsCompo from "./OffMarketComp";
import "./offComp.scss";
import {useSelector} from "react-redux";
import axios from "axios";

function OffMarkets() {
  const {idToken} = useSelector((state) => state.auth);
  const [assetsList, setAssetsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAssets = async () => {
    const config = {
      headers: {
        "auth-token": idToken,
      },
    };
    setIsLoading(true);
    try {
      const response = await axios.get("api/assets/AssetDiscovery/getDispositionsWithAssets", config);
      setAssetsList(response.data.data.offMarket);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAssets();
  }, [idToken]);

  return (
    <div className="off-market">
      <div className="inner-off">
        {assetsList?.map((item, index) => {
          return (
            <div>
              <div className="heading">Off-market assets</div>
              <OffmarketsCompo
                key={index}
                item={item}
                index={index}
                assetsList={assetsList}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default OffMarkets;
