import React from "react";
import "./AllocationChart.scss";

const AllocationChart = () => {
  const data = [
    {location: "San Francisco-Oakland-Hayward, CA", value: 39, colorCode: "#1665D8"},
    {location: "Los Angeles-Long Beach-Anaheim, CA", value: 18, colorCode: "#6FCF97"},
    {location: "San Francisco-Oakland-Hayward, CA", value: 12, colorCode: "#F2994A"},
    {location: "San Diego-Carlsbad, CA", value: 24, colorCode: "#9EA0A5"},
  ];

  const totalValue = data.reduce((sum, item) => sum + item.value, 0);

  return (
    <div className="bar_chart_wrapper">
      <div className="bar_chart_cylinder">
        {data.map((item, index) => {
          const heightPercentage = (item.value / totalValue) * 100;
          return (
            <div
              className="bar_chart_cylinder_item"
              key={index}
              style={{height: `${heightPercentage}%`}}
            >
              <div
                className="bar_chart_cylinder_item_value"
                style={{backgroundColor: item.colorCode}}
              >
                {item.value}%
              </div>
            </div>
          );
        })}
      </div>
      <div className="bar_chart_legends">
        {data.map((item, index) => {
          return (
            <div
              className="bar_chart_legend_item"
              key={index}
            >
              <div
                className="bar_chart_legend_color"
                style={{backgroundColor: item.colorCode}}
              ></div>
              <div className="bar_chart_legend_text">{item.location}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AllocationChart;
