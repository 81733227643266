import React, {useState} from "react";
import {Link} from "react-router-dom";
import "./PortfolioCards.scss";
import roadSvg from "../../assets/portfolio/svgs/road.svg";
import shopSvg from "../../assets/portfolio/svgs/shopping.svg";
import {ReactComponent as ThumbsUpIcon} from "../../assets/portfolio/svgs/thumb-up-normal.svg";
import {ReactComponent as ThumbsDownIcon} from "../../assets/portfolio/svgs/thumb-down-normal.svg";
import shareSvg from "../../assets/portfolio/svgs/share.svg";
export const RecommendationsCard = ({title, source, distance, description, icon}) => {
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);

  const toggleLikeButton = () => {
    setLiked(!liked);
  };

  const toggleDislikeButton = () => {
    setDisliked(!disliked);
  };
  return (
    <div className="card-container">
      <div className="card-header">
        <div className="heading-container">
          <img
            src={icon === "roadSvg" ? roadSvg : shopSvg}
            alt=""
          className="portfolio_reccomended_img"
          />
          <div className="heading">
            <h2>{title}</h2>
            <p>{distance}</p>
          </div>
        </div>
        <div className="heading-side-container">
          <p>Source</p>
          <h6>{source}</h6>
        </div>
      </div>
      <div className="card-content">
        <h4
          className="directon-arrow"
          style={{color: "#7757c0"}}
        >
          <span className="arrow">⮝</span>
          <br /> Show
        </h4>
        <p>{description}</p>
      </div>
      <div className="line">&nbsp;</div>
      <div className="card-footer">
        <p>Was this Recommendation helpfull?</p>
        <div className="like-dislike-button-wrapper">
          <ThumbsUpIcon
            onClick={toggleLikeButton}
            style={{fill: liked ? "#7757c0" : "", cursor: "pointer"}}
          />
          <ThumbsDownIcon
            onClick={toggleDislikeButton}
            style={{fill: liked ? "#7757c0" : "", cursor: "pointer"}}
          />
        </div>

        <div className="share-button">
          <img
            src={shareSvg}
            alt="share"
          />
          <h4>Share</h4>
        </div>
      </div>
    </div>
  );
};
