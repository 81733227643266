import React from "react";
import "./MarketSurvey.scss";
import Logo from "../../assets/acqusition/img/Logo.png";
import like from "../../assets/acqusition/svg/like.svg";
import dislike from "../../assets/acqusition/svg/dislike.svg";
import redirect from "../../assets/acqusition/svg/redirect.svg";
import share from "../../assets/acqusition/svg/share.svg";

const MaketSurvey = () => {
  return (
    <div className="market-s-main-wrapper">
      <div className="market-s-u">
        <div>
          <div>
            <img
              src={Logo}
              alt=""
            />
          </div>
          <div className="market-s-u-h">
            <span>The new york times</span>
            <span>Find breaking news, multimedia, reviews & opinion...</span>
          </div>
        </div>
        <div className="market-s-u-d">
          <span>Industry</span>
          <span>Financial Services</span>
        </div>
      </div>
      <div className="market-s-c">
        <div className="market-s-c-d">
          <span>
            Each unit will be constructed to a high standard and will benefit from minimum 10m eaves, a mix of dock and level loading doors and integral office accommodation, as well as generous
            loading yards.
          </span>
        </div>

        <div className="full-artical">
          <div>
            <img
              src={redirect}
              alt=""
            />
            <span>Read full article at Source Page</span>
          </div>
          <span>Published on December 26, 2018</span>
        </div>
      </div>
      <div className="market-s-d">
        <span>Was this article helpfull?</span>
        <div className="like-dislike">
          <img
            src={like}
            alt=""
          />
          <img
            src={dislike}
            alt=""
          />
        </div>
        <div>
          <img
            src={share}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default MaketSurvey;
