import React, {useEffect, useState} from "react";
import "./Task.scss";
import ProjectsAndCalendar from "./ProjectsAndCalendar";
import TaskModal from "./TaskModal";
import {useSelector} from "react-redux";
import axios from "axios";
import {AddProject} from "./TaskModules/AddProject";
import {AddTaskPopup} from "./TaskModules/AddTaskPopup";
import {Calendar} from "./TaskModules/Calendar";
import {TaskDetailsModal} from "./TaskModules/TaskDetailsModal";
import {TimeTableBar} from "./TaskModules/TimeTableBar";
import {TaskSidebar} from "./TaskModules/TaskSidebar";
import {TaskSidebarList} from "./TaskModules/TaskSidebarList";
import {format} from "date-fns";
import Endpoint from "../../utils/Endpoint";
import useAuthConfig from "../../utils/Config";
import {projects as DummyTaskdata} from "./DummyTask/DummyTaskdata";
import Timeout from "../../components/Autologout/Timeout";
import {getTasks} from "../../utils/Axios";

function Task() {
  const [renderKey, setRenderKey] = useState(1);
  const [showAddProject, setShowAddProject] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [project, setProject] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const {idToken} = useSelector((state) => state.auth);
  let [getTaskdata, setTaskData] = useState();
  const [isTaskModalOpen, setTaskModalOpen] = useState(false);
  const [isAddTaskPopupOpen, setAddTaskPopupOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const {selectedProperty} = useSelector((state) => state.portfolio);
  const {userInformation} = useSelector((state) => state.auth);
  const [selectedTask, setSelectedTask] = useState(null);
  const [projects, setProjects] = useState(DummyTaskdata);
  Timeout(idToken);

  const handleProject = () => {
    setProject(!project);
  };

  const openTaskModal = (columnStatus) => {
    setTaskModalOpen(true);
    setSelectedColumn(columnStatus);
  };

  const closeTaskModal = () => {
    setTaskModalOpen(false);
    setSelectedTask(null);
  };

  const [columns, setColumns] = useState(() => {
    const columnsData = localStorage.getItem("columns");
    return columnsData
      ? JSON.parse(columnsData)
      : [
          {name: "open", border: "#ffffff", projects: projects.filter((project) => project.status === "open")},
          {name: "in-progress", border: "#34AA44", projects: projects.filter((project) => project.status === "in-progress")},
          {name: "closed", border: "#6758F3", projects: projects.filter((project) => project.status === "closed")},
        ];
  });

  useEffect(() => {
    localStorage.setItem("columns", JSON.stringify(columns));
  }, [columns]);

  const handleTaskSubmission = (taskTitle, taskDate, status) => {
    const newTask = {
      id: projects.length + 1,
      title: taskTitle,
      date: taskDate,
      completed: false,
      img: "",
      status: selectedColumn || "open",
    };

    setProjects((prevProjects) => [...prevProjects, newTask]);
    closeTaskModal();
    setRenderKey((prevKey) => prevKey + 1);

    setColumns((prevColumns) => {
      const updatedColumns = [...prevColumns];
      const targetColumn = updatedColumns.find((column) => column.name === selectedColumn);

      if (targetColumn) {
        targetColumn.projects.push(newTask);
      }

      return updatedColumns;
    });
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleDateClick = (dateStr) => {
    setSelectedDate(dateStr);

    const formattedDateStr = format(new Date(dateStr), "yyyy-MM-dd");

    const tasksOnSelectedDate = getTaskdata?.filter((task) => {
      const formattedTaskDate = format(new Date(task?.date), "yyyy-MM-dd");
      return formattedTaskDate === formattedDateStr;
    });

    if (tasksOnSelectedDate?.length > 0) {
      setSelectedTask(tasksOnSelectedDate);
      setTaskModalOpen(true);
    } else {
      setAddTaskPopupOpen(true);
    }
  };



  const fetchTaskData = async () => {
    try {
      const tasks = await getTasks(userInformation?._id, idToken);
      setTaskData(tasks);
    } catch (error) {
      setTaskData([]);
    }
  };

  useEffect(() => {
    fetchTaskData();
  }, []);

  return (
    <>
      {!project ? (
        <div className="task">
          <span
            className={`hamburger-menu ${showSidebar ? "active" : ""}`}
            onClick={toggleSidebar}
          >
            <div></div>
            <div></div>
            <div></div>
          </span>
          <div className="Task-flex">
            <Calendar
              openTaskModal={openTaskModal}
              tasksList={projects}
              setAddTaskPopupOpen={setAddTaskPopupOpen}
              handleDateClick={handleDateClick}
              getTaskdata={getTaskdata}
            />

            <TimeTableBar getTaskdata={getTaskdata} />
            <AddTaskPopup
              isOpen={isAddTaskPopupOpen}
              onClose={() => setAddTaskPopupOpen(false)}
              selectedDate={selectedDate}
              idToken={idToken}
              selectedProperty={selectedProperty}
              userInformation={userInformation}
              getTaskdata={getTaskdata}
            />
            <TaskDetailsModal
              isOpen={isTaskModalOpen}
              onClose={closeTaskModal}
              tasks={selectedTask}
            />
          </div>
        </div>
      ) : (
        <ProjectsAndCalendar
          columns={columns}
          setColumns={setColumns}
          key={renderKey}
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          openTaskModal={openTaskModal}
          closeTaskModal={closeTaskModal}
          projectActive={project}
          handleProject={handleProject}
          projects={projects}
          setProjects={setProjects}
        />
      )}
      {showAddProject && (
        <AddProject
          showAddProject={showAddProject}
          setShowAddProject={setShowAddProject}
        />
      )}

      <TaskModal
        selectedColumn={selectedColumn}
        isOpen={isTaskModalOpen}
        onClose={closeTaskModal}
        onSubmit={(title, date, selectedColumn) => handleTaskSubmission(title, date, selectedColumn)}
      />
    </>
  );
}

export default Task;