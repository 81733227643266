import React, { useState } from 'react';
import './organisation.scss';
import { InputBox } from '../../../components/reusableComponents/FormFields/FormFields';
import add from '../../../assets/svg/add-gray.svg';

function Organisation() {
    const [departments, setDepartments] = useState([
        'Asset Discover',
        'Networking',
        'Deal',
        'Portfolio Management',
        'Crowdfunding',
      ]);
      
  const addDepartment = () => {
    const newDepartments = [...departments, ''];
    setDepartments(newDepartments);
  };

  const handleDepartmentChange = (index, value) => {
    const newDepartments = [...departments];
    newDepartments[index] = value;
    setDepartments(newDepartments);
  };

  return (
    <div className='organisation'>
      <div className='basic_header'>
        <div>
          <p>Organisation Structure</p>
        </div>
      </div>

      <div className="inputs">
        <div className='input_box'>
          <p className='title'>Departments</p>
          <div>
            {departments.map((department, index) => (
              <InputBox
                key={index}
                width={'330px'}
                label={''}
                placeholder={`Department ${index + 1}`}
                value={department}
                onChange={(e) => handleDepartmentChange(index, e.target.value)}
              />
            ))}
            <p className='add_btn' onClick={addDepartment}>
              <img src={add} alt='' />
              Add Department
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Organisation;
