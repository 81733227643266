import React, {useState} from "react";
import downArrow from "../../../assets/svg/down_arrow.svg";
import "./FilterHeader.scss";

const FilterForHeader = () => {
  const [RotateIcon, setRotateIcon] = useState(false);
  const items = [
    {
      name: "Ivan Robinson",
      balance: "$0.00",
    },
  ];

  const [values, setValues] = useState(items);
  const [Recipient, setRecipient] = useState("Ivan Robinson");
  return (
    <>
      <div className="recipient_type hover">
        <p
          onClick={() => {
            setRotateIcon(!RotateIcon);
            setValues(items);
          }}
        >
          <span style={{fontSize: "15px", color: "grey", marginRight: "5px"}}> Fund</span> {Recipient}
        </p>
        <img
          className={RotateIcon ? "rotate" : ""}
          src={downArrow}
          alt=""
        />
        {RotateIcon && (
          <div className="recipient_modal">
            <div className="input">
              <input
                type="text"
                placeholder="filter"
                onChange={(e) => {
                  setValues(items.filter((val, ind) => val?.name?.toLocaleLowerCase().includes(e.target.value)));
                }}
              />
            </div>
            <div className="values">
              {values?.map((item) => {
                return (
                  <div className="values_item">
                    <p
                      style={{fontWeight: "600", fontSize: "15px"}}
                      onClick={() => setRecipient(item.name)}
                    >
                      {item.name}
                    </p>
                    <span>{item.balance} balance</span>
                  </div>
                );
              })}
              <div className="reset">
                <p>Add new fund</p>
                <p>Add new gp entity</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FilterForHeader;
