import React from "react";
import "./Dropdown.scss";
import dropdownArrows from "../../../assets/Image/dropdownArrows.png";
import {useState} from "react";
function DropDown({label, paddingLeft, label2, Img, Img2, data, selectedData, setSelectedData, dropdownState, dropdownSetter, classDataName, width, margin, setSignupData}) {
  const dummyData = ["Select", "Option 2", "Option 3"];
  const [dummyDA, setDummyDA] = useState(false);
  const [dummySelected, setDummySelected] = useState(dummyData[0]);

  return (
    <div
      className={"dropDownOuterCon " + classDataName}
      style={{width: width, margin: margin}}
    >
      <p> {label}</p>
      <div
        onClick={() => {
          if (!dropdownSetter === null) {
            dropdownSetter(!dropdownState);
          } else {
            setDummyDA(!dummyDA);
          }
        }}
        className={"dropDownMAIN"}
      >
        {Img && (
          <img
            src={Img}
            alt=""
            style={{top: "10px", left: "9px"}}
          />
        )}
        <div
          style={{display: "flex", gap: "2px", alignItems: "center", textWrap: "nowrap", width: "100%"}}
          className="dropDownOuter"
        >
          {label2 && (
            <span
              style={{
                color: "#9EA0A5",
              }}
            >
              {label2}
            </span>
          )}
          {Img2 && (
            <img
              src={Img2}
              alt=""
              style={{top: "10px", left: "0px"}}
            />
          )}

          {selectedData || dummySelected}
        </div>
        <img
          style={{paddingLeft: paddingLeft}}
          src={dropdownArrows}
          alt=""
        />
        <div className={"dropDownDrop " + ((dropdownState && "active") || (dummyDA && "active"))}>
          {data
            ? data.map((item) => {
                return (
                  <div
                    onClick={() => {
                      setSelectedData(item);
                      dropdownSetter(false);
                    }}
                    className="dropDownDropData"
                  >
                    {item}
                  </div>
                );
              })
            : dummyData.map((item) => {
                return (
                  <div
                    onClick={() => {
                      setDummySelected(item);
                      setDummyDA(false);
                    }}
                    className="dropDownDropData"
                  >
                    {item}
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
}
export default DropDown;
