import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import img_Logo from "../../../assets/Image/Investment_Pipeline/Base.png";
import PortfolioOverview from "../../../components/InvestmentPipelineComponents/Portfolio_Overview";
import "./style.scss";
import Portfolio_Performence from "../../../components/InvestmentPipelineComponents/Portfolio_Performence";
import PortfolioPerformence from "../../../components/InvestmentPipelineComponents/Portfolio_Performence";
import SyndicateOwnerAnalytics from "../../../components/InvestmentPipelineComponents/SyndicateOwnerAnalytics";

const PipelineDashboard = ({setIsClickedOne}) => {
  const [isClicked, setIsClicked] = useState(0);
  const [activeTab, setActiveTab] = useState(1);
  const handleMenuClicked = (data) => {
    setIsClicked(data);
  };
  const handleTabClicked = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div style={{marginLeft: "10px"}}>
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434509385!2d144.96315781550483!3d-37.814107979751554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf57744e51f97b6e1!2sFederation%20Square!5e0!3m2!1sen!2sau!4v1626093397898!5m2!1sen!2sau"
            width="100%"
            height="300"
            style={{border: 0}}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Map"
          ></iframe>
        </div>
        <div className="tab-container">
          <div
            className={`tab ${activeTab === 1 ? "active" : ""}`}
            onClick={() => handleTabClicked(1)}
          >
            Portfolio Overview
          </div>
          <div
            className={`tab ${activeTab === 2 ? "active" : ""}`}
            onClick={() => handleTabClicked(2)}
          >
            Portfolio Performance
          </div>
          <div
            className={`tab ${activeTab === 3 ? "active" : ""}`}
            onClick={() => handleTabClicked(3)}
          >
            Syndicate Owner Analytics
          </div>
        </div>
        <div className="tab-content">
          {activeTab === 1 && (
            <div>
              <PortfolioOverview setIsClickedOne={setIsClickedOne} />
            </div>
          )}
          {activeTab === 2 && (
            <div>
              <PortfolioPerformence />
            </div>
          )}
          {activeTab === 3 && (
            <div>
              <SyndicateOwnerAnalytics />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PipelineDashboard;
