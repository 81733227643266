import React from "react";
import "./style.scss";
import TickIcon from "../../../assets/icons/New_tick.svg";
import {useDispatch, useSelector} from "react-redux";
import { clearMessages } from "../../../../../store/reducers/message";

function SuccessModal() {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  // const {t} = useTranslation();

  const handleClose = (e) => {
    if (e) e.preventDefault();
    setOpen(false);
    dispatch(clearMessages());
  };

  const {title, msg, status} = useSelector((state) => state.mesage);

  React.useEffect(() => {
    setOpen(true);
  });

  return msg && status ? (
    <div
      className="success_modal_overlay"
      onClick={() => handleClose(false)}
    >
      <div className="success_modal_container">
        <div
          className="success_modal_container_top"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="tick_icon">
            <img
              src={TickIcon}
              alt="successful tick"
            />
          </div>
        </div>

        <div
          className="success_modal_container_bottom"
          onClick={(e) => e.stopPropagation()}
        >
          <h1>Success!</h1>
          {/* <p>{msg}</p> */}
          <button
            className="success_modal_close_btn"
            onClick={() => handleClose(false)}
          >
            {"Done"}
          </button>
        </div>
      </div>
    </div>
  ) : (
    false
  );
}

export default SuccessModal;
