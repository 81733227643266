import React, {useState, useEffect} from "react";
import { FiChevronRight } from 'react-icons/fi';
import SvgColorChanger from "../../DealsRoom/SvgColorChanger";
import {ReactComponent as Dashboardimage} from "../../../assets/Manage-syndicate/Svg/Dash.svg";
import {ReactComponent as Essentialsimage} from "../../../assets/Manage-syndicate/Svg/Essentials.svg";
import {ReactComponent as Securitiesimage} from "../../../assets/Manage-syndicate/Svg/Securities.svg";
import {ReactComponent as Captabimage} from "../../../assets/Manage-syndicate/Svg/Cap Table.svg";
import {ReactComponent as Stakeholdersimage} from "../../../assets/Manage-syndicate/Svg/stakeholders.svg";
import {ReactComponent as Fundraisingimage} from "../../../assets/Manage-syndicate/Svg/Fundraising.svg";
import {ReactComponent as Compliancetaximage} from "../../../assets/Manage-syndicate/Svg/compliance & tax.svg";
import {ReactComponent as Documentsimage} from "../../../assets/Manage-syndicate/Svg/documents.svg";
import {ReactComponent as TotalCompensationimage} from "../../../assets/Manage-syndicate/Svg/Total Compensation.svg";
import {ReactComponent as Math} from "../../../assets/Manage-syndicate/Svg/math.svg";
import Dashboard from "./Dashboard";
import ViewCaptable from "./CapTab";
import Stakeholders from "./Stakeholders";
import Draftshares from "./Draftshares";
import Draftoptiongrants from "./Draftoptiongrants";
import Safefinancings from "./Safefinancings";
import RunReport from "./RunReport";
import CabTable from "./CabTable";
import CaptableStake from "./CaptableStake";
import CapReport from "./CapReport";
import Reviewcap from "./Reviewcap";
import Reportedissues from "./Reportedissues";
import Captableaccess from "./Captableaccess";
import Securitiesshare from "./Securitiesshare";
import Equityawards from "./Equityawards";
import Warrants from "./Warrants";
import Convertibles from "./Convertibles";
import ShareClasses from "./ShareClasses";
import Equityplans from "./Equityplans";
import Securitiestemplates from "./Securitiestemplates";
import ManageStakeholders from "./ManageStakeholders";
import Stakeholderscharrings from "./Stakeholderscharrings";
import CreateNewSAFE from "./CreateNewSAFE";
import FundraisingSAFEFinancings from "./FundraisingSAFEFinancings";
import Financinghistory from "./Financinghistory";
import AValuations from "./AValuations";
import Financialreporting from "./Financialreporting";
import ISO100K from "./ISO100K";
import Taxrules from "./Taxrules";
import Form3921 from "./Form3921";
import CompanyDocument from "./CompanyDocument";
import Boardmeetings from "./Boardmeetings";
import Boardconsents from "./Boardconsents";
import Companyfinancials from "./Companyfinancials";
import Communications from "./Communications";
import Investorupdates from "./Investorupdates";
import Datarooms from "./Datarooms";
import TotalCompensation from "./TotalCompensation";
import TenderOffers from "./TenderOffers";
import OSBSTaxBrack from "./OSBSTaxBrack";
import EquityAdvisory from "./EquityAdvisory";
import DealTeam from "./DealTeam";
import CompanySetting from "./CompanySetting";
import "./TreasurySidebar.scss";

const TreasurySidebar = () => {
  
  const [isSubMenuClicked, setIsSubMenuClicked] = useState(null);
  const [isClicked, setIsClicked] = useState(1);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [subMenuActive, setSubMenuActive] = useState(false);
  const [capTableSubMenuActive, setCapTableSubMenuActive] = useState(false);
  const [securitiesSubMenuActive, setSecuritiesSubMenuActive] = useState(false);
  const [fundraisingSubMenuActive, setfundraisingSubMenuActive] = useState(false);
  const [StakeholdersSubMenuActive, setStakeholdersSubMenuActive] = useState(false);
  const [CompliancetaxSubMenuActive, setCompliancetaxSubMenuActive] = useState(false);
  const [DocumentsSubMenuActive, setDocumentsSubMenuActive] = useState(false);
  const [TotalCompensationSubMenuActive, setTotalCompensationSubMenuActive] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [CompanySettingSubMenuActive, setCompanySettingSubMenuActive] = useState(null);
  // useEffect(() => {
  //   setIsClicked(valuationParams?.state?.isClicked || 1);
  // }, [valuationParams]);
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const handleMenuClicked = (data) => {
    setIsClicked(data);

    if (data === 50) {
      setSubMenuActive(!subMenuActive);
      setCapTableSubMenuActive(false);
      setSecuritiesSubMenuActive(false);
      setStakeholdersSubMenuActive(false);
      setfundraisingSubMenuActive(false);
      setCompliancetaxSubMenuActive(false);
      setDocumentsSubMenuActive(false);
      setTotalCompensationSubMenuActive(false);
      setIsSubMenuClicked(data);
    } else if (data === 51) {
      setCapTableSubMenuActive(!capTableSubMenuActive);
      setSubMenuActive(false);
      setSecuritiesSubMenuActive(false);
      setStakeholdersSubMenuActive(false);
      setfundraisingSubMenuActive(false);
      setCompliancetaxSubMenuActive(false);
      setDocumentsSubMenuActive(false);
      setTotalCompensationSubMenuActive(false);
    } else if (data === 52) {
      setSecuritiesSubMenuActive(!securitiesSubMenuActive);
      setSubMenuActive(false);
      setCapTableSubMenuActive(false);
      setStakeholdersSubMenuActive(false);
      setfundraisingSubMenuActive(false);
      setCompliancetaxSubMenuActive(false);
      setDocumentsSubMenuActive(false);
      setTotalCompensationSubMenuActive(false);
    } else if (data === 53) {
      setStakeholdersSubMenuActive(!StakeholdersSubMenuActive);
      setSubMenuActive(false);
      setCapTableSubMenuActive(false);
      setSecuritiesSubMenuActive(false);
      setfundraisingSubMenuActive(false);
      setCompliancetaxSubMenuActive(false);
      setDocumentsSubMenuActive(false);
      setTotalCompensationSubMenuActive(false);
    } else if (data === 54) {
      setfundraisingSubMenuActive(!fundraisingSubMenuActive);
      setSubMenuActive(false);
      setCapTableSubMenuActive(false);
      setSecuritiesSubMenuActive(false);
      setStakeholdersSubMenuActive(false);
      setCompliancetaxSubMenuActive(false);
      setDocumentsSubMenuActive(false);
      setTotalCompensationSubMenuActive(false);
    } else if (data === 55) {
      setCompliancetaxSubMenuActive(!CompliancetaxSubMenuActive);
      setSubMenuActive(false);
      setCapTableSubMenuActive(false);
      setSecuritiesSubMenuActive(false);
      setStakeholdersSubMenuActive(false);
      setfundraisingSubMenuActive(false);
      setDocumentsSubMenuActive(false);
      setTotalCompensationSubMenuActive(false);
    } else if (data === 56) {
      setDocumentsSubMenuActive(!DocumentsSubMenuActive);
      setSubMenuActive(false);
      setCapTableSubMenuActive(false);
      setSecuritiesSubMenuActive(false);
      setStakeholdersSubMenuActive(false);
      setfundraisingSubMenuActive(false);
      setCompliancetaxSubMenuActive(false);
      setTotalCompensationSubMenuActive(false);
    } else if (data === 57) {
      setTotalCompensationSubMenuActive(!TotalCompensationSubMenuActive);
      setSubMenuActive(false);
      setCapTableSubMenuActive(false);
      setSecuritiesSubMenuActive(false);
      setStakeholdersSubMenuActive(false);
      setfundraisingSubMenuActive(false);
      setCompliancetaxSubMenuActive(false);
      setDocumentsSubMenuActive(false);
    }
    window.scrollTo(0, 0);
  };

  return (
    <div className="deals-room profile">
      <div className="deals-room-left">
        {/*_______________________________________ Dashboard______________________________ */}
        <LeftMenu
          icon={<Dashboardimage />}
          name={"Dashboard"}
          id={1}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
          isActive={isClicked === 1}
        />
        {/*_______________________________________ Essentials______________________________ */}
        <LeftMenuDrop
          icon={<Essentialsimage />}
          name={"Essentials"}
          id={50}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
          isSubMenuActive={subMenuActive}
          isActive={isClicked === 50}
          isClicked={isClicked}
          arrow={<FiChevronRight />}
        />
        {subMenuActive && (
          <>
            <LeftMenuSort
              name={"View cap table"}
              id={3}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Manage stakeholders"}
              id={4}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Draft shares"}
              id={5}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Draft option grants"}
              id={6}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Safe financings"}
              id={7}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Run reports"}
              id={8}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
          </>
        )}
        {/*_______________________________________ Cap Table______________________________ */}
        {/* Cap Table */}
        <LeftMenuDrop
          icon={<Captabimage />}
          name={"Cap Table"}
          id={51}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
          isSubMenuActive={capTableSubMenuActive}
          isActive={isClicked === 51}
          isClicked={isClicked}
          arrow={<FiChevronRight />}
        />
        {capTableSubMenuActive && (
          <>
            <LeftMenuSort
              name={"View by share class"}
              id={10}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Run reports"}
              id={11}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Review cap table health"}
              id={12}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Reported issues"}
              id={13}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Cap table access"}
              id={14}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
              isSubMenuActive={subMenuActive}
            />
          </>
        )}
        {/*_______________________________________ Securities ______________________________ */}
        {/* Securities */}
        <LeftMenuDrop
          icon={<Securitiesimage />}
          name={"Securities"}
          id={52}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
          isSubMenuActive={securitiesSubMenuActive}
          isActive={isClicked === 52}
          isClicked={isClicked}
          arrow={<FiChevronRight />}
        />
        {securitiesSubMenuActive && (
          <>
            <LeftMenuSort
              name={"Shares"}
              id={15}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Equity awards"}
              id={16}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Warrants"}
              id={17}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Convertibles"}
              id={18}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Manage share classes"}
              id={19}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Equity plans"}
              id={20}
              statisClickede={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Securities templates"}
              id={21}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Secondaries"}
              id={200}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
          </>
        )}
        {/*_______________________________________ Stakeholders ______________________________ */}
        <LeftMenuDrop
          icon={<Stakeholdersimage />}
          name={"Stakeholders"}
          id={53}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
          isSubMenuActive={StakeholdersSubMenuActive}
          isActive={isClicked === 53}
          isClicked={isClicked}
          arrow={<FiChevronRight />}
        />
        {StakeholdersSubMenuActive && (
          <>
            {/* Stakeholders */}
            <LeftMenuSort
              name={"Manage Stakeholders"}
              id={22}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Stakeholders charrings"}
              id={23}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
          </>
        )}
        {/*_______________________________________ Fundraising ______________________________ */}
        <LeftMenuDrop
          icon={<Fundraisingimage />}
          name={"Fundraising"}
          id={54}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
          isSubMenuActive={fundraisingSubMenuActive}
          isActive={isClicked === 54}
          isClicked={isClicked}
          arrow={<FiChevronRight />}
        />
        {fundraisingSubMenuActive && (
          <>
            {/* Fundraising */}
            <LeftMenuSort
              name={"Safe financings"}
              id={24}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
              isSubMenuActive={subMenuActive}
            />
            <LeftMenuSort
              name={"Model your fundraise"}
              id={26}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
              isSubMenuActive={subMenuActive}
            />
            <LeftMenuSort
              name={"Financing history"}
              id={27}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
              isSubMenuActive={subMenuActive}
            />
          </>
        )}
        {/*_______________________________________ Compliance & tax ______________________________ */}
        <LeftMenuDrop
          icon={<Compliancetaximage />}
          name={"Compliance & tax"}
          id={55}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
          isSubMenuActive={CompliancetaxSubMenuActive}
          isActive={isClicked === 55}
          isClicked={isClicked}
          arrow={<FiChevronRight />}
        />
        {CompliancetaxSubMenuActive && (
          <>
            {/* Compliance & tax */}
            <LeftMenuSort
              name={"409A Valuations"}
              id={28}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Financial reporting"}
              id={29}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"ISO 100K "}
              id={30}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Tax rules"}
              id={31}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenuSort
              name={"Form 3921"}
              id={32}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
          </>
        )}
        {/*_______________________________________ Documents ______________________________ */}
        <LeftMenuDrop
          icon={<Documentsimage />}
          name={"Documents"}
          id={56}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
          isSubMenuActive={DocumentsSubMenuActive}
          isActive={isClicked === 56}
          isClicked={isClicked}
          arrow={<FiChevronRight />}
        />
        {DocumentsSubMenuActive && (
          <>
            {/* Documents */}
            <LeftMenuSort
              name={"Company documents"}
              id={33}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
              isSubMenuActive={subMenuActive}
            />
            <LeftMenuSort
              name={"Board meetings"}
              id={34}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
              isSubMenuActive={subMenuActive}
            />

            <LeftMenuSort
              name={"Board consents"}
              id={35}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
              isSubMenuActive={subMenuActive}
            />
            <LeftMenuSort
              name={"Company financials"}
              id={36}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
              isSubMenuActive={subMenuActive}
            />
            <LeftMenuSort
              name={"Communications"}
              id={37}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
              isSubMenuActive={subMenuActive}
            />
            <LeftMenuSort
              name={"Investor updates"}
              id={38}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
              isSubMenuActive={subMenuActive}
            />
            <LeftMenuSort
              name={"Data rooms"}
              id={39}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
              isSubMenuActive={subMenuActive}
            />
          </>
        )}
         <p className="TreasurySidebar-more">more</p>
        {/*_______________________________________ Total Compensation ______________________________ */}
        <LeftMenuDrop
          icon={<TotalCompensationimage />}
          name={"Total Compensation"}
          id={57}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
          isSubMenuActive={TotalCompensationSubMenuActive}
          isActive={isClicked === 57}
          isClicked={isClicked}
          arrow={<FiChevronRight />}
        />
        {TotalCompensationSubMenuActive && (
          <>
            {/* Total Compensation */}
            <LeftMenuSort
              name={"Total Compensation"}
              id={40}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
              isSubMenuActive={subMenuActive}
            />
            <LeftMenuSort
              name={"Tender Offers"}
              id={41}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
              isSubMenuActive={subMenuActive}
            />
            <LeftMenuSort
              name={"OSBS  Tax Brack"}
              id={42}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
              isSubMenuActive={subMenuActive}
            />
            <LeftMenuSort
              name={"Equity Advisory"}
              id={43}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
              isSubMenuActive={subMenuActive}
            />
            <LeftMenuSort
              name={"Deal Team"}
              id={44}
              isClicked={isClicked}
              handleMenuClicked={handleMenuClicked}
              isSubMenuActive={subMenuActive}
            />
          </>
        )}
       
        {/*_______________________________________ Company Setting __________________________________ */}
        <LeftMenuDrop
          icon={<Math />}
          name={"Company Setting"}
          id={45}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
          isSubMenuActive={TotalCompensationSubMenuActive}
          isActive={isClicked === 45}
        />
      </div>
      <div className="center-wrapper">
        {isClicked === 1 && <Dashboard />}
        {isClicked === 3 && <ViewCaptable />}
        {isClicked === 50 && <ViewCaptable />}
        {isClicked === 4 && <Stakeholders />}
        {isClicked === 5 && <Draftshares />}
        {isClicked === 6 && <Draftoptiongrants />}
        {isClicked === 7 && <Safefinancings />}
        {isClicked === 8 && <RunReport />}
        {isClicked === 9 && <CabTable />}
        {isClicked === 10 && <CaptableStake />}
        {isClicked === 51 && <CaptableStake />}
        {isClicked === 11 && <CapReport />}
        {isClicked === 12 && <Reviewcap />}
        {isClicked === 13 && <Reportedissues />}
        {isClicked === 14 && <Captableaccess />}
        {isClicked === 15 && <Securitiesshare />}
        {isClicked === 52 && <Securitiesshare />}
        {isClicked === 16 && <Equityawards />}
        {isClicked === 17 && <Warrants />}
        {isClicked === 18 && <Convertibles />}
        {isClicked === 19 && <ShareClasses />}
        {isClicked === 20 && <Equityplans />}
        {isClicked === 21 && <Securitiestemplates />}
        {isClicked === 22 && <ManageStakeholders />}
        {isClicked === 53 && <ManageStakeholders />}
        {isClicked === 23 && <Stakeholderscharrings />}
        {isClicked === 24 && <CreateNewSAFE />}
        {isClicked === 54 && <CreateNewSAFE />}
        {isClicked === 26 && <FundraisingSAFEFinancings />}
        {isClicked === 27 && <Financinghistory />}
        {isClicked === 28 && <AValuations />}
        {isClicked === 55 && <AValuations />}
        {isClicked === 29 && <Financialreporting />}
        {isClicked === 30 && <ISO100K />}
        {isClicked === 31 && <Taxrules />}
        {isClicked === 32 && <Form3921 />}
        {isClicked === 33 && <CompanyDocument />}
        {isClicked === 56 && <CompanyDocument />}
        {isClicked === 34 && <Boardmeetings />}
        {isClicked === 35 && <Boardconsents />}
        {isClicked === 36 && <Companyfinancials />}
        {isClicked === 37 && <Communications />}
        {isClicked === 38 && <Investorupdates />}
        {isClicked === 39 && <Datarooms />}
        {isClicked === 40 && <TotalCompensation />}
        {isClicked === 57 && <TotalCompensation />}
        {isClicked === 41 && <TenderOffers />}
        {isClicked === 42 && <OSBSTaxBrack />}
        {isClicked === 43 && <EquityAdvisory />}
        {isClicked === 44 && <DealTeam />}

        {isClicked === 45 && <CompanySetting />}
      </div>
    </div>
  );
};

export default TreasurySidebar;

const LeftMenu = ({icon, name, id, state, handleMenuClicked, isActive}) => {
  return (
    <div
      className={`deals-left-menu ${isActive ? "deals-menu-active" : ""}`}
      onClick={() => {
        handleMenuClicked(id);
      }}
    >
      <SvgColorChanger
        svgContent={icon}
        color={isActive ? "activeColor" : "normalColor"}
      />
      <span className={`left-menu-title ${isActive ? "active" : ""}`}>{name}</span>
     
    </div>
  );
};

const LeftMenuSort = ({name, id, isClicked, handleMenuClicked, icon}) => {
  const isActive = isClicked === id;

  const handleClick = () => {
    handleMenuClicked(id);
  };

  return (
    <div
      className={`deals-left-menu left-menu-sort ${isActive ? "deals-menu-active-sort" : ""}`}
      onClick={handleClick}
    >
      <SvgColorChanger
        svgContent={icon}
        color={isActive ? "activeColor" : "normalColor"}
      />
      <span className="left-menu-title">{name}</span>
    </div>
  );
};

const LeftMenuDrop = ({icon, name, id, state, handleMenuClicked, isActive,arrow}) => {
  return (
    <div
      className={`deals-left-menu drop ${isActive ? "deals-menu-active" : ""}`}
      onClick={() => {
        handleMenuClicked(id);
      }}
    >
      <SvgColorChanger
        svgContent={icon}
        color={isActive ? "activeColor" : "normalColor"}
      />
      <span className="left-menu-title">{name}</span>
      {arrow && <span className="arrow-icon">{arrow}</span>}
    </div>
  );
};
