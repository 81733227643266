import React from 'react'
import './Posts.scss'
import man from "../../../assets/notes/man.png";
import Pic1 from "../../../assets/notes/Pic1.png";
import Pic2 from "../../../assets/notes/Pic2.png";
import Pic3 from "../../../assets/notes/Pic3.png";
import camera from "../../../assets/notes/camera.svg";
import dot from "../../../assets/notes/dot.svg";
import comment from "../../../assets/notes/comment.svg";
import like from "../../../assets/notes/like.svg";
import share from "../../../assets/notes/share.svg";
import smile from "../../../assets/notes/smile.svg";
import Like from '../../../assets/Image/assetDiscovery/Like.svg'

function Posts() {
  return (
    <div className='posts'>
        <Post />
    </div>
  )
}

export default Posts


const Post = () => {
    return (
      <div className="counter-party-center">
        <div className="user-card">
          <div className="left">
            <img
              src={man}
              alt=""
            />
            <div>
              <h3>Roxie Mills</h3>
              <span>December 28, 2018</span>
            </div>
          </div>
          <div>
            <img
              src={dot}
              alt=""
            />
          </div>
        </div>
        <h3>Interion ideas for renovations</h3>
        <div className="c-p-c-i">
          <div className="pic-left">
            <img
              src={Pic1}
              alt=""
            />
          </div>
          <div className="pic-right">
            <img
              src={Pic2}
              alt=""
            />
            <img
              src={Pic3}
              alt=""
            />
          </div>
        </div>
        <div className="divider"></div>
        <div className="c-p-comment">
          <div>
          <div>
              <img
                src={like}
                alt=""
              />
              <span>Like</span>
            </div>
            <div>
              <img
                src={comment}
                alt=""
              />
              <span>Comment</span>
            </div>
            <div>
              <img
                src={share}
                alt=""
              />
              <span>Share</span>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className='liked'>
            <img src={Like} alt='' />
            Ryan Reynolds, Eula Reyes and 25 others</div>
        <div className="divider"></div>
        <div className="user-card">
          <div className="left">
            <img
              src={man}
              alt=""
            />
            <div>
              <input type='text' placeholder='Write a Comment' />
            </div>
          </div>
          <div className="right">
            <img
              src={camera}
              alt=""
            />
            <img
              src={smile}
              alt=""
            />
          </div>
        </div>
      </div>
    );
  };