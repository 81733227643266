import {Spinner} from "../Spinner/Spinner";
import "./Buttons.scss";

function PrimaryButton({name, onclick, classNames}) {
  return (
    <div
      onClick={onclick}
      className={"PrimaryButton " + classNames}
    >
      {name}
    </div>
  );
}
function SmallSvgButton({secondary, name, onclick, className}) {
  return (
    <div
      onClick={onclick}
      className={"smallSvgButton " + (secondary && "secondary") + className}
    >
      <svg
        className="svgL"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.6654 9.16536H6.5237L11.182 4.50703L9.9987 3.33203L3.33203 9.9987L9.9987 16.6654L11.1737 15.4904L6.5237 10.832H16.6654V9.16536Z"
          fill="#9EA0A5"
        />
      </svg>

      {name}
      <svg
        className="svgR"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.9987 3.33203L8.8237 4.50703L13.4737 9.16536H3.33203V10.832H13.4737L8.8237 15.4904L9.9987 16.6654L16.6654 9.9987L9.9987 3.33203Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
function BeforeSmallSvgButton({secondary, name, onclick, className}) {
  return (
    <div
      onClick={onclick}
      className={"smallSvgButton " + (secondary && "secondary") + className}
    >
      <svg
        className="svgRbefore"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.9987 3.33203L8.8237 4.50703L13.4737 9.16536H3.33203V10.832H13.4737L8.8237 15.4904L9.9987 16.6654L16.6654 9.9987L9.9987 3.33203Z"
          fill="white"
        />
      </svg>

      {name}
    </div>
  );
}

function PrimaryButtonSvg({name, onclick, classNames, svgPath}) {
  return (
    <div
      onClick={onclick}
      className={"PrimaryButton " + classNames}
    >
      <span style={{fontSize: "14px"}}>{name}</span>
      {svgPath && (
        <img
          src={svgPath}
          alt={name + "-svg"}
          className="svg-icon"
        />
      )}
    </div>
  );
}

function PrimaryButtonSvgFirst({name, onclick, classNames, svgPath, padding, svgHeight, svgWidth, svg}) {
  return (
    <div
      onClick={onclick}
      className={"PrimaryButton " + classNames}
      style={{padding: `${padding && `${padding}`}`}}
    >
      {svgPath && (
        <img
          src={svgPath || svgPath.src}
          alt={name + "-svg"}
          className="svg-icon"
          style={{height: `${svgHeight && `${svgHeight}`}`, width: `${svgWidth && `${svgWidth}`}`}}
        />
      )}
      {svg && svg}
      <span style={{fontSize: "14px", marginLeft: "8px"}}>{name}</span>
    </div>
  );
}

function PrimaryButtonNameFirst({name, svgPath, onclick, classNames, padding, svgHeight, svgWidth, svg}) {
  return (
    <div
      onClick={onclick}
      className={"PrimaryButton " + classNames}
      style={{padding: `${padding && `${padding}`}`}}
    >
      <span style={{fontSize: "14px", marginRight: "8px"}}>{name}</span>
      {svgPath && (
        <img
          src={svgPath || svgPath.src}
          alt={name + "-svg"}
          className="svg-icon"
          style={{height: `${svgHeight && `${svgHeight}`}`, width: `${svgWidth && `${svgWidth}`}`}}
        />
      )}
      {svg && svg}
    </div>
  );
}

const PrimaryButtonloading = ({name, onClick, disabled, loading, classNames, padding}) => {
  return (
    <div
      onClick={onClick}
      className={`PrimaryButton ${classNames || ""}`}
      style={{padding: padding || ""}}
    >
      {loading ? (
        <>
          {/* <div className="spinner-wrapper">
          <div className="spinner"></div>
        </div> */}
          <Spinner
            size="30"
            stroke="3"
            color="white"
          />
        </>
      ) : (
        <span>{name}</span>
      )}
    </div>
  );
};

export {PrimaryButton, PrimaryButtonloading, SmallSvgButton, PrimaryButtonSvg, PrimaryButtonSvgFirst, PrimaryButtonNameFirst, BeforeSmallSvgButton};
