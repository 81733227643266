import React, {useState} from "react";
import "./Token.scss";
import transfer_icon from "../../assets/svg/transfer_icon.svg";
import save_icon from "../../assets/svg/save.svg";
import print_icon from "../../assets/svg/print_icon.svg";
import copy_icon from "../../assets/svg/copy_icon.svg";
import tokenBg from '../../assets/Image/tokenBgImage.png';

const Token = () => {
  const [generatedToken, setGeneratedToken] = useState("0x34EH887oseno8vnh89h98h9h9616816e1bf6df0#$5gh7&H*%^&*()(*guiqncnw");
  return (
    <div className="token_container">
      <div className="heading">
        <h1>Token</h1>
        <button>
          <img
            src={transfer_icon}
            alt=""
          />
          Transfer another Token
        </button>
      </div>
      <p className="generated_token">{generatedToken}</p>
      <div className="action_header">
        <div className="save">
          <img
            src={save_icon}
            alt=""
          />
          <p>Save</p>
        </div>
        <div className="print">
          <img src={print_icon} alt="" />
          <p>Print</p>
        </div>
        <div className="copy">
          <img src={copy_icon} alt="" />
          <p>Copy to Clipboard</p>
        </div>
      </div>
      <div className="tokenPage_image" style={{width:'550px',margin:'auto'}}>
        <img src={tokenBg} alt="" width='100%'/>
      </div>
    </div>
  );
};

export default Token;
