import React, {useEffect, useState} from "react";
import "./DealsRoom.scss";
import SvgColorChanger from "../DealsRoom/SvgColorChanger";
import back from "../../assets/dealsroom/svg/back.svg";
import rect from "../../assets/dealsroom/images/Rectangle.jpeg";
import {ReactComponent as Assetinformation} from "../../assets/dealsroom/svg/assetinformation.svg";
import {ReactComponent as Dealroom} from "../../assets/dealsroom/svg/dealroom.svg";
import {ReactComponent as KeyDeals} from "../../assets/svg/key-deal.svg";
import {ReactComponent as Dealeconomics} from "../../assets/svg/investment.svg";
import {ReactComponent as Loanrequest} from "../../assets/svg/group.svg";
import {ReactComponent as Valuatio} from "../../assets/svg/business-plan.svg";
import {ReactComponent as History} from "../../assets/svg/general-partner.svg";
import dropdown from "../../assets/dealsroom/svg/dropdown.svg";
import {Link, useNavigate} from "react-router-dom";
import AssetInformation from "./AssetInformation";
import Investment from "./Investment";
import BusinessPlan from "./BusinessPlan";
import Team from "./Team";
import Disclaimer from "./Disclaimer";
import GeneralPartner from "./GeneralPartner";
import KeyDeal from "./KeyDeal";
import {useSelector} from "react-redux";

const CrowdFunding = (props) => {
  const [isClicked, setIsClicked] = useState(1);
  const [syndicateState, setSyndicate] = useState(1);
  const [loanReqState, setLoanReq] = useState(1);
  let {selectedCrowdfunding} = useSelector((state) => state.crowdfundingSlice);

  const handleMenuClicked = (data) => {
    setIsClicked(data);
    if (data === 4) {
      setLoanReq(1);
    } else if (data == 5) {
      setSyndicate(1);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="deals-room">
      <div className="deals-room-left">
        <Link to={"/asset-discovery?cf=true"}>
          <div className="acqu">
            <img
              src={back}
              alt=""
            />
            <span>Asset Discovery</span>
          </div>
        </Link>
        <div className="deal-title">
          <div className="deal-left-img">
            <img
              src={selectedCrowdfunding?.assetDetails?.assetsImages[0]}
              alt="Rectangle deal img"
            />
          </div>
          <div className="deal-left-img-text">
            <span>{selectedCrowdfunding?.assetDetails?.assetName}</span>
            <span>{selectedCrowdfunding?.assetDetails?.assetsAddress}</span>
          </div>
        </div>
        <div
          style={{margin: "20px 0"}}
          className="deal-room-left-menu"
        >
          <span>main menu</span>
        </div>

        <LeftMenu
          icon={<Assetinformation />}
          name={"Asset Information"}
          id={1}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenu
          icon={<KeyDeals />}
          name={"Key Deal Points"}
          id={2}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenu
          icon={<Dealeconomics />}
          name={"Investments"}
          id={3}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenu
          icon={<Valuatio />}
          name={"Business Plan"}
          id={1110}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenu
          icon={<History />}
          name={"General Partner"}
          id={19}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenu
          icon={<Loanrequest />}
          name={"Team"}
          id={1111}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />

        <LeftMenu
          icon={<Dealroom />}
          name={"Disclaimer"}
          id={23}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
      </div>
      <div className="center-wrapper">
        {isClicked === 1 && <AssetInformation selectedCrowdfunding={selectedCrowdfunding} />}
        {isClicked === 2 && <KeyDeal />}
        {isClicked === 3 && <Investment />}
        {isClicked === 1110 && <BusinessPlan />}
        {isClicked === 1111 && <Team />}
        {isClicked === 23 && <Disclaimer />}
        {isClicked === 19 && <GeneralPartner />}
      </div>
    </div>
  );
};

export default CrowdFunding;

const LeftMenu = ({icon, name, id, state, handleMenuClicked}) => {
  return (
    <div
      className={`deals-left-menu ${id === state && "deals-menu-active"}`}
      onClick={() => {
        handleMenuClicked(id);
      }}
    >
      <SvgColorChanger svgContent={icon} />

      <span className="left-menu-title">{name} </span>
    </div>
  );
};
const LeftMenuDrop = ({icon, name, id, state, handleMenuClicked}) => {
  return (
    <div
      className={`deals-left-menu drop ${id === state && "deals-menu-active"}`}
      onClick={() => {
        handleMenuClicked(id);
      }}
    >
      <div>
        <SvgColorChanger svgContent={icon} />

        <span className="left-menu-title">{name} </span>
      </div>

      <img
        src={dropdown}
        alt=""
      />
    </div>
  );
};

const LeftMenuSort = ({icon, name, id, state, handleMenuClicked}) => {
  return (
    <div
      className={`deals-left-menu left-menu-sort ${id === state && "deals-menu-active-sort"}`}
      onClick={() => {
        handleMenuClicked(id);
      }}
    >
      <SvgColorChanger svgContent={icon} />

      <span className="left-menu-title">{name} </span>
    </div>
  );
};
