import React from 'react'
import './viewhistory.scss'
import DropDown from '../../../components/reusableComponents/Dropdown/Dropdown'
import { useState } from 'react';
import calendar from '../../../assets/svg/calender.svg'
import sort from '../../../assets/svg/sort.svg'
import '../saveditems/SavedHeader.scss'
import SvgColorChanger from '../../DealsRoom/SvgColorChanger';
import clock from '../../../assets/svg/clock.svg'
import asset from '../../../assets/svg/asset.svg'
import clockActive from '../../../assets/svg/clockActive.svg'
import assetActive from '../../../assets/svg/assetActive.svg'
import AssetHistory from './AssetHistory';
import ActivityHistory from './ActivityHistory';

function ViewHistory() {
    const [isClicked, setIsClicked] = useState(1);

    const handleMenuClicked = (data) => {
        setIsClicked(data);
   
    };
    return (

        <>
            <SavedHeader title={'View History'} handleMenuClicked={handleMenuClicked} isClicked={isClicked} />
            <div className='view_history'>
                {isClicked === 1 && <AssetHistory />}
                {isClicked === 2 && <ActivityHistory />}
            </div>
        </>
    )
}

export default ViewHistory


function SavedHeader({ title, handleMenuClicked, isClicked }) {

    const [timeList, setTimeList] = useState(["Hourly", "Daily"]);
    const [selectedTimeList, setSelectedTimeList] = useState("Hourly");
    const [timeListDa, setTimeListDa] = useState(false);

    return (
        <div className='savedHeader'>
            <h2>{title}</h2>
            <div style={{ display: 'flex', gap: '10px' }}>
                <div className={`btns ${isClicked === 1 ? 'active' : ''}`}

                    onClick={() => handleMenuClicked(1)}>
                    <img src={isClicked === 1 ? assetActive : asset} alt='' />
                    Asset view History
                </div>

                <div
                    className={`btns ${isClicked === 2 ? 'active' : ''}`}
                    onClick={() => handleMenuClicked(2)}
                >
                    <img src={isClicked === 2 ? clockActive : clock} alt='' />
                    Activity History
                </div>

                <DropDown
                    label=""
                    label2={''}
                    Img2={calendar}
                    data={timeList}
                    selectedData={'This Year'}
                    width={'150px'}
               
                />
                <DropDown
                    label=""
                    label2={'Sort:'}
                    Img2={sort}
                    paddingLeft={'28px'}
                    data={timeList}
                    selectedData={'Date Added'}
                    width={'200px'}

                />
            </div>
        </div>
    )
}

