import React from "react";
import ReactECharts from "echarts-for-react";

const NewBarChart = ({ data }) => {

  // const {
  //   dates,
  //   gpr,
  //   interest_payment,
  //   management_fee,
  //   other_expenses,
  //   other_income,
  //   noi
  // } = data;

 

  const option = {
    xAxis: {
      type: 'category',
      data: data?.dates,
    },
    yAxis: [
      {
        type: 'value',
        name: 'Amount',
      },
      {
        type: 'value',
        name: 'NOI',
      },
    ],
    legend: {
      data: ['NOI','GPR', 'Other Income', 'Interest Payment', 'Management Fee', 'Other Expenses'],
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    series: [
      {
        name: 'NOI',
        type: 'line',
        yAxisIndex: 1,
        data: data?.noi,
        lineStyle: {
          color: '#2F80ED', 
        },
        tooltip: {
          formatter: '{b} <br/> NOI: {c}', 
        },
      },
      {
        name: 'Other Income',
        type: 'bar',
        stack: 'total',
        data: data?.other_income,
        itemStyle: {
          color: '#6FCF97', 
        },
        tooltip: {
          formatter: '{b} <br/> Other Income: {c}', 
        },
      },
      {
        name: 'Other Expenses',
        type: 'bar',
        stack: 'total',
        data: data?.other_expenses,
        itemStyle: {
          color: '#F2994A', 
        },
        tooltip: {
          formatter: '{b} <br/> Other Expenses: {c}', 
        },
      },
      {
        name: 'Management Fee',
        type: 'bar',
        stack: 'total',
        data: data?.management_fee,
        itemStyle: {
          color: '#9EA0A5', 
        },
        tooltip: {
          formatter: '{b} <br/> Management Fee: {c}', 
        },
        barWidth:'30%'
      },
      {
        name: 'Interest Payment',
        type: 'bar',
        stack: 'total',
        data: data?.interest_payment,
        itemStyle: {
          color: '#C4C4C4', 
        },
        tooltip: {
          formatter: '{b} <br/> Interest Payment: {c}'
        },
      },
      {
        name: 'GPR',
        type: 'bar',
        stack: 'total',
        data: data?.gpr,
        itemStyle: {
          color: '#56CCF2', 
        },
        tooltip: {
          formatter: '{b} <br/> GPR: {c}', 
        },
      },
    ],
  };

  return (
    <ReactECharts
      option={option}
      style={{ height: '400px', width: '100%' }}
    />
  );
};

export default NewBarChart;
