import React from "react";
import ActiveInvestmentsChart from "../Charts/ActiveInvestmentsChart";
import PastInvestmentsChart from "../Charts/PastInvestmentsChart";
import "./style.scss";
import EarningsDistributionsChart from "../Charts/EarningsDistributionsChart";
import InvestedPrincipalBalance from "../Charts/InvestedPrincipalBalance";
import ReactTable from "../../../pages/InvestmentPipeline/reusuableComponents/ReactTable/ReactTable";
import northGroup from "../../../assets/Image/Investment_Pipeline/northGroup.png";

const PortfolioPerformence = () => {
  const tableDataOne = [
    {
      investment: "West Group Trust",
      date_invested: "01/01/20",
      exit_date_anticipated: "01/07/20",
      invested: "$2,000.00",
      returned: "$2,400.00",
      earnings_paid: "$400.00",
      cumulative_annualized_return: "20% Monthlyy",
      distribution_frequency: "01/06/20",
      next_payment_date: "On Time",
      payment_status: "PAID",
    },
    {
      investment: "East Group Trust",
      date_invested: "15/03/19",
      exit_date_anticipated: "15/09/19",
      invested: "$1,500.00",
      returned: "$1,800.00",
      earnings_paid: "$300.00",
      cumulative_annualized_return: "25% Monthlyy",
      distribution_frequency: "01/06/20ly",
      next_payment_date: "On Time",
      payment_status: "PAID",
    },
    {
      investment: "South Group Trust",
      date_invested: "10/05/18",
      exit_date_anticipated: "10/11/18",
      invested: "$3,000.00",
      returned: "$3,450.00",
      earnings_paid: "$450.00",
      cumulative_annualized_return: "15% Monthlyy",
      distribution_frequency: "01/06/20",
      next_payment_date: "On Time",
      payment_status: "PAID",
    },
    {
      investment: "Central Group Trust",
      date_invested: "25/06/21",
      exit_date_anticipated: "25/12/21",
      invested: "$2,500.00",
      returned: "$2,875.00",
      earnings_paid: "$375.00",
      cumulative_annualized_return: "15% Monthlyy",
      distribution_frequency: "01/06/20",
      next_payment_date: "On Time",
      payment_status: "PAID",
    },
    {
      investment: "North Group Trust",
      date_invested: "05/08/22",
      exit_date_anticipated: "05/02/23",
      invested: "$2,200.00",
      returned: "$2,530.00",
      earnings_paid: "$330.00",
      cumulative_annualized_return: "15% Monthlyy",
      distribution_frequency: "01/06/20",
      next_payment_date: "On Time",
      payment_status: "PAID",
    },
    {
      investment: "North Group Trust",
      date_invested: "15/11/18",
      exit_date_anticipated: "15/11/18",
      invested: "$3,053.43",
      returned: "$3,053.43",
      earnings_paid: "$3,053.43",
      cumulative_annualized_return: "78% Monthly",
      distribution_frequency: "01/06/20",
      next_payment_date: "On Time",
      payment_status: "PAID",
    },
  ];

  const columnsOne = [
    {
      Header: "Investment",
      accessor: "investment",
      Cell: ({row}) => (
        <div className="porfolio_performence_tableOne_investment">
          <div style={{width: "67px"}}>
            <img
              src={northGroup}
              alt=""
            />
          </div>
          <div className="PortfolioPerformence_investment_inner">
            <h3>North Group Trust</h3>
            <p>Address</p>
          </div>
        </div>
      ),
    },
    {
      Header: "Date Invested",
      accessor: "date_invested",
    },
    {
      Header: "Exit Date (Anticipated)",

      accessor: "exit_date_anticipated",
    },
    {
      Header: "Invested",
      accessor: "invested",
    },
    {
      Header: "Returned",
      accessor: "returned",
    },
    {
      Header: "Earnings (Paid)",
      accessor: "earnings_paid",
    },
    {
      Header: "Cumulative Annualized Return",
      accessor: "cumulative_annualized_return",
    },
    {
      Header: "Distribution Frequency",
      accessor: "distribution_frequency",
    },
    {
      Header: "Next Payment Date",
      accessor: "next_payment_date",
    },
    {
      Header: "Payment Status",
      accessor: "payment_status",
      Cell: ({row}) => <div className="porfolio_performence_tableOne_status">Paid</div>,
    },
  ];

  const tableDataTwo = [
    {
      investment: "West Group Trust",
      date_invested: "01/01/20",
      exit_date_anticipated: "01/07/20",
      invested: "$2,000.00",
      distribution_frequency: "01/06/20",
      next_payment_date: "On Time",
    },
    {
      investment: "West Group Trust",
      date_invested: "01/01/20",
      exit_date_anticipated: "01/07/20",
      invested: "$2,000.00",
      distribution_frequency: "01/06/20",
      next_payment_date: "On Time",
    },
    {
      investment: "West Group Trust",
      date_invested: "01/01/20",
      exit_date_anticipated: "01/07/20",
      invested: "$2,000.00",
      distribution_frequency: "01/06/20",
      next_payment_date: "On Time",
    },
    {
      investment: "West Group Trust",
      date_invested: "01/01/20",
      exit_date_anticipated: "01/07/20",
      invested: "$2,000.00",
      distribution_frequency: "01/06/20",
      next_payment_date: "On Time",
    },
    {
      investment: "West Group Trust",
      date_invested: "01/01/20",
      exit_date_anticipated: "01/07/20",
      invested: "$2,000.00",
      distribution_frequency: "01/06/20",
      next_payment_date: "On Time",
    },
  ];

  const columnsTwo = [
    {
      Header: "Investment",
      accessor: "investment",
      Cell: ({row}) => (
        <div className="porfolio_performence_tableTwo_investment">
          {/* <div style={{width: "67px"}}>
            <img
              src={northGroup}
              alt=""
            />
          </div> */}
          <div className="PortfolioPerformence_investment_inner">
            <h3>North Group Trust</h3>
            <p>Address</p>
          </div>
        </div>
      ),
    },
    {
      Header: "Date Invested",
      accessor: "date_invested",
    },
    {
      Header: "Exit Date (Anticipated)",
      accessor: "exit_date_anticipated",
    },
    {
      Header: "Invested",
      accessor: "invested",
    },
    {
      Header: "Distribution Frequency",
      accessor: "distribution_frequency",
    },
    {
      Header: "Next Payment Date",
      accessor: "next_payment_date",
    },
  ];

  const tableDataThree = [
    {
      investment: "West Group Trust",
      date: "01/01/20",
      amount_type: "$2,000.00",
      status: "PENDING",
    },
    {
      investment: "West Group Trust",
      date: "01/01/20",
      amount_type: "$2,000.00",
      status: "PENDING",
    },
    {
      investment: "West Group Trust",
      date: "01/01/20",
      amount_type: "$2,000.00",
      status: "PENDING",
    },
    {
      investment: "West Group Trust",
      date: "01/01/20",
      amount_type: "$2,000.00",
      status: "PENDING",
    },
    {
      investment: "West Group Trust",
      date: "01/01/20",
      amount_type: "$2,000.00",
      status: "PENDING",
    },
  ];

  const columnsThree = [
    {
      Header: "Investment",
      accessor: "investment",
      Cell: ({row}) => (
        <div className="porfolio_performence_tableThree_investment">
          {/* <div style={{width: "67px"}}>
            <img
              src={northGroup}
              alt=""
            />
          </div> */}
          <div className="PortfolioPerformence_investment_inner">
            <h3>North Group Trust</h3>
            <p>Address</p>
          </div>
        </div>
      ),
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "AMOUNT/TYPE",
      accessor: "amount_type",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({row}) => <div className="porfolio_performence_tableThree_status">Pending</div>,
    },
  ];

  return (
    <div className="porfolio_performence_main">
      <div className="porfolio_performence_heading">
        <h2>Portfolio performence</h2>
      </div>
      <div className="porfolio_performence_one">
        <div className="investment_summary">
          <div className="investment_summary_header">
            <h3>Investment Summary</h3>
          </div>
          <div className="investment_summary_one">
            <p>Total Invested</p>
            <h3>$1,632,569.00</h3>
          </div>
          <div className="investment_summary_two">
            <p>Total Distributed</p>
            <h3>$935,558</h3>
          </div>
        </div>
        <div style={{width: "100%"}}>
          <PastInvestmentsChart />
        </div>
        <div style={{width: "100%"}}>
          <ActiveInvestmentsChart />
        </div>
      </div>

      <div className="porfolio_performence_two">
        <div className="investment_summary">
          <div className="investment_summary_header">
            <h3>Lifetime Portfolio</h3>
          </div>
          <div className="investment_summary_one">
            <p>Invested</p>
            <h3>$1,632,569.00</h3>
          </div>
          <div className="investment_summary_two">
            <p>Earnings (Paid)</p>
            <h3>$935,558</h3>
          </div>
          <div className="investment_summary_two">
            <p>Return (Cumulative)</p>
            <h3>$935,558</h3>
          </div>
        </div>
        <div style={{width: "100%"}}>
          <EarningsDistributionsChart />
        </div>
      </div>
      <div className="porfolio_performence_three">
        <div className="investment_summary">
          <div className="investment_summary_header">
            <h3>Current Portfolio</h3>
          </div>
          <div className="investment_summary_one">
            <p>Capital Balance</p>
            <h3>$1,632,569.00</h3>
          </div>
          <div className="investment_summary_two">
            <p>Earnings (Paid)</p>
            <h3>$935,558</h3>
          </div>
          <div className="investment_summary_two">
            <p>Return (Annualized)</p>
            <h3>$935,558</h3>
          </div>
        </div>
        <div style={{width: "100%"}}>
          <InvestedPrincipalBalance />
        </div>
      </div>

      <div className="porfolio_performence_tableOne">
        <h3>My Cash-flowing Investments</h3>
        <ReactTable
          columns={columnsOne}
          data={tableDataOne}
        />
      </div>
      <div className="porfolio_performence_table_lower">
        <div className="porfolio_performence_tableTwo">
          <h3>My Cash-flowing Investments</h3>
          <ReactTable
            columns={columnsTwo}
            data={tableDataTwo}
          />
        </div>
        <div className="porfolio_performence_tableThree">
          <h3>My Cash-flowing Investments</h3>
          <ReactTable
            columns={columnsThree}
            data={tableDataThree}
          />
        </div>
      </div>
    </div>
  );
};

export default PortfolioPerformence;
