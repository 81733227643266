import React from 'react'
import './Connections.scss'
import Woman from '../../../assets/Image/assetDiscovery/woman.png'
function Connections() {

    
    const connectiondata = [
        {
          img: Woman,
          name: 'Ruby Soto',
          text1:'Product Designer at Dribbble',
          text2:'Eathn and 5 other mutual friends'
        },
        {
            img: Woman,
            name: 'Ruby Soto',
            text1:'Product Designer at Dribbble',
            text2:'Product Designer at Dribbble'
          },
          {
            img: Woman,
            name: 'Ruby Soto',
            text1:'Product Designer at Dribbble',
            text2:'Product Designer at Dribbble'
          },
          {
            img: Woman,
            name: 'Ruby Soto',
            text1:'Product Designer at Dribbble',
            text2:'Product Designer at Dribbble'
          },

      
      ];

  return (
    <div className='connections'>
        <div className='connections_header'>
            <h2>Connections</h2>
            <div className="search_bar">
                        <input
                            type="text"
                            placeholder="Search for your contacts"
                        />
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M20 18.586L18.586 20L12.929 14.343L14.343 12.929L20 18.586ZM7 12C4.243 12 2 9.757 2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12ZM7 0C3.134 0 0 3.134 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0Z"
                                fill="white"
                                fill-opacity="0.01"
                            />
                            <mask
                                id="mask0_9862_58034"
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="20"
                                height="20"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M20 18.586L18.586 20L12.929 14.343L14.343 12.929L20 18.586ZM7 12C4.243 12 2 9.757 2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12ZM7 0C3.134 0 0 3.134 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0Z"
                                    fill="white"
                                />
                            </mask>
                            <g mask="url(#mask0_9862_58034)">
                                <rect
                                    opacity="0.5"
                                    x="-1"
                                    y="-1"
                                    width="22"
                                    height="22"
                                    fill="#9EA0A5"
                                />
                            </g>
                        </svg>
                    </div>
        </div>

        <div className='connectons_wrapper'>
        {connectiondata.map((deal) => (
            <ConnectionBox key={deal.id} {...deal} />
          ))}  
        </div>
    </div>
  )
}

export default Connections



function ConnectionBox({img , name , text1 , text2 }) {
    return (
      <div className='connection_box'>
        <div className='connection_box_left'>
            <img src={img} alt='' />
        <div>
            <p className='name'>{name}</p>
            <p className='text1'>{text1}</p>
            <span className='text2'>{text2}</span>
        </div>
        </div>
        <div className='connection_box_right'>
          <button>Follow</button>
        </div>
      </div>
    );
  }