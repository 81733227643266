import React, {useEffect} from "react";
import ReactECharts from "echarts-for-react";

const CashFlowchart = () => {
  useEffect(() => {
    // Add chart interaction logic here if needed
  }, []); // Empty dependency array to run effect only once

  const xAxisData = ["2020", "2021", "2023", "2024", "2025", "2026", "2027", "2028"];

  const data1 = [222, 444, 555, 63, 222, 54, 77, 312];
  const data2 = [323, 131, 423, 567, 223, 311, 131, 121];

  const emphasisStyle = {
    itemStyle: {
      shadowBlur: 10,
      shadowColor: "rgba(0,0,0,0.3)",
    },
  };

  const series = [
    {
      // name: "bar",
      type: "bar",
      stack: "one",
      emphasis: emphasisStyle,
      data: data1.map((value) => Math.max(value, 0)), // Filter out negative values
      itemStyle: {
        color: "#57ccf2", // Set the color for bar1
      },
    },
    {
      // name: "bar2",
      type: "bar",
      stack: "two",
      emphasis: emphasisStyle,
      data: data2.map((value) => Math.max(value, 0)), // Filter out negative values
      itemStyle: {
        color: "#03629c", // Set the color for bar2
      },
    },
    {
      name: "line",
      type: "line",
      stack: "one",
      emphasis: emphasisStyle,
      data: data2.map((value) => Math.max(value, 0)), // Filter out negative values
      itemStyle: {
        color: "#2f80ed", // Set the color for bar2
      },
    },
  ];

  const option = {
    legend: {
      data: ["bar", "bar2", "bar3", "bar4"],
      left: "10%",
    },
    brush: {
      toolbox: ["rect", "polygon", "lineX", "lineY", "keep", "clear"],
      xAxisIndex: 0,
    },
    toolbox: {
      show: false,
      feature: {
        magicType: {
          type: ["stack"],
        },
        dataView: {},
      },
    },
    tooltip: {},
    xAxis: {
      data: xAxisData,
      splitLine: {show: false},
      splitArea: {show: false},
    },
    yAxis: {
      position: "left",
      axisLabel: {
        formatter: "{value}k", // Add dollar sign to y-axis labels
      },
    },
    grid: {
      bottom: 100,
    },
    series: series,
  };

  return <ReactECharts option={option} />;
};

export default CashFlowchart;
