import axios from "axios";
import {useDispatch} from "react-redux";
import Endpoint from "./Endpoint";

// GET USER PROGILE DETAILS
const getUserDetails = async (idToken) => {
  let config = {
    headers: {
      "auth-token": idToken,
    },
  };

  try {
    const res = await axios.get(Endpoint.getUserDetails, config);
    return res.data;
  } catch (error) {
    // return error.response.data;
  }
};

// POST SIGNUP DATA
const PostSignUpData = async (payload) => {
  let output;
  await axios
    .post(Endpoint.Signupdata, payload)
    .then((res) => {
      output = res?.data;
    })
    .catch(function (error) {
      output = error?.response?.data;
    });
  return output;
};

// UPDATE USER PASSWORD ON PASSWORD PAGE
const UpdateUserPassword = async (idToken, payload) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };

  let output;
  await axios
    .post(Endpoint.updatepassword, payload, config)
    .then((res) => {
      output = res.data;
    })
    .catch(function (error) {
      output = error.response.data;
    });
  return output;
};

// GET USER PORTFOLIO DETAILS FOR PORTFOLIO PAGE
const getUserPortfolio = async (idToken) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };

  let output;
  await axios
    .get(Endpoint.getUserPortfolio, config)
    .then((res) => {
      output = res?.data;
    })
    .catch(function (error) {
      output = error?.response?.data;
    });
  return output;
};

// USER LOG IN
const UserLogIn = async (payload) => {
  let output;
  await axios
    .post(Endpoint.UserLogIn, payload)
    .then((res) => {
      output = res?.data;
    })
    .catch(function (error) {
      output = error?.response?.data;
    });
  return output;
};

// POST QUESTIONERIES DATA AFTER SIGNUP
const PostQuestionsData = async (idToken, payload) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
  };

  let output;
  await axios
    .post(Endpoint.PostQuestionsData, payload, config)
    .then((res) => {
      output = res?.data;
    })
    .catch(function (error) {
      output = error?.response?.data;
    });
  return output;
};

// GET ASSETS LIST FOR ASSET DISCOVERY PAGE
const getAsset = async (idToken, query) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
  };

  let output;
  await axios
    .get(`api/assets/search/assets/?assets_name=${query}`, config)
    .then((res) => {
      output = res?.data;
    })
    .catch(function (error) {
      output = error?.response?.data;
    });
  return output;
};

// POST LOAN SETUP DATA
const PostLoanSetupData = async (idToken, payload) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
  };

  try {
    const response = await axios.post(Endpoint.PostLoanSetupData, payload, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// VERIFY EMAIL WHILE SIGNUP
const VerifyEmail = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    let output;
    const response = await axios.post(Endpoint.VerifyEmail, payload, config);
    output = response?.data;
    return output;
  } catch (error) {
    return error?.response?.data;
  }
};

// ------- FETCH VALUATION DATA-------------
const FetchValuationInfo = async (idToken) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
  };

  let output;
  await axios
    .get(Endpoint.FetchValuationInfo, config)
    .then((res) => {
      output = res?.data;
    })
    .catch(function (error) {
      output = error?.response?.data;
    });
  return output;
};

const fetchCrownFunding = async (idToken) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
  };

  let output;
  await axios
    .get(Endpoint.fetchCrownFunding, config)
    .then((res) => {
      output = res?.data;
    })
    .catch(function (error) {
      output = error?.response?.data;
    });
  return output;
};

// POST SETUP_SYNDICATE DATA

const PostSetupSyndicate = async (payload, idToken) => {
  const config = {
    headers: {
      "auth-token": idToken,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.post(Endpoint.PostSetupSyndicate, payload, config);
    return response.data; 
  } catch (error) {

    return error.response?.data || {success: false, message: "An error occurred"}; 
  }
};

const GetSyndicates = async (idToken, params) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
    params: params,
  };

  let output;
  await axios
    .get(Endpoint.GetSyndicates, config)
    .then((res) => {
      output = res?.data;
    })
    .catch(function (error) {
      output = error?.response?.data;
    });
  return output;
};

// manage Sindicate

const PostManageSindicate = async (payload, idToken) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
  };

  let output;
  await axios
    .post(Endpoint.PostManageSindicate, payload, config)
    .then((res) => {
      output = res?.data;
    })
    .catch(function (error) {
      output = error?.response?.data;
    });
  return output;
};

// Get Maintenance data

const fetchMaintenanceData = async (idToken) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
  };

  let output;
  await axios
    .get(Endpoint.fetchMaintenanceData, config)
    .then((res) => {
      output = res?.data;
    })
    .catch(function (error) {
      output = error?.response?.data;
    });
  return output;
};

// Murn data
const fetchDataFromApi = async (idToken) => {
  try {
    const config = {
      headers: {
        "auth-token": idToken,
      },
    };
    let output;
    const response = await axios.get(Endpoint.Maintenance, config);
    output = response.data;

    return output;
  } catch (error) {

    return null;
  }
};

// Get poartfolir by Email iD
const fetchPortfoliodata = async (email, idToken) => {
  try {
    const response = await axios.get(`/api/Portfolio/get-portfolio`, {
      headers: {
        "Content-Type": "application/json",
        "auth-token": idToken,
      },
    });
    if (response.data.success) {
      return response.data.data;
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

// GET ALL INVISTOR DATA
const addingfetchinvistor = async (email, idToken, selectedItem, dispatch) => {
  try {
    const config = {
      headers: {
        "auth-token": idToken,
      },
    };
    const requestBody = {
      userEmail: email,
      assetIds: [selectedItem._id],
    };

    const response = await axios.post(Endpoint.addingfetchinvistor, config, requestBody);
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error(response.data.message || "No Data Portfolio Add From this Id");
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

const fetchAssetsByAdminEmail = async (idToken, userInformation) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
    params: {
      adminEmail: userInformation.email,
    },
  };

  try {
    const response = await axios.get(Endpoint.fetchAssetsByAdminEmail, config);
    if (response.status === 200) {
      return {
        assetsData: response.data.data.assetsData,
        accountType: response.data.data.accountType,
      };
    } else {
      return {
        assetsData: [],
        accountType: null,
      };
    }
  } catch (error) {

    return {
      assetsData: [],
      accountType: null,
    };
  }
};

// Send Email
const sendemail = async (payload, idToken) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
  };

  let output;
  await axios
    .post(Endpoint.Sendemailtgiveaccess, payload, config)
    .then((res) => {
      output = res?.data;
    })
    .catch(function (error) {
      output = error?.response?.data;
    });
  return output;
};

// graph data line graph
const linechartgarph = async (assetId, idToken) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
    params: {
      assetId: assetId,
    },
  };
  const response = await axios.get(Endpoint.linechartdata, config);
  return response.data;
};

const PasswordReset = async (payload, idToken) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
  };

  let output;
  try {
    const res = await axios.post(Endpoint.updatepassword, payload, config);
    output = res?.data;
  } catch (error) {
    output = error?.response?.data;
  }
  return output;
};

// Getdata by asset id

const getassetbyid = async (payload, idToken) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
  };

  try {
    const res = await axios.post(Endpoint.getassetbyid, payload, config);
    return res?.data;
  } catch (error) {

    return error?.response?.data;
  }
};

// get ownerdata
const Getallownerdata = async (idToken, params) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
    params: params,
  };

  try {
    const response = await axios.get(Endpoint.getallownerdata, config);
    return response.data;
  } catch (error) {

    return error?.response?.data;
  }
};

const FetchAssetsByAdminEmail = async (idToken, adminEmail) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
    params: {
      adminEmail: adminEmail,
    },
  };

  try {
    const response = await axios.get(Endpoint.getassectbyemail, config);
    if (response.status === 200) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (error) {

    return null;
  }
};

const fetchMurndataByEmail = async (idToken, email) => {
  try {
    const config = {
      headers: {
        "auth-token": idToken,
      },
    };
    const response = await axios.get(Endpoint.fetchMurndataByEmail(email), config);
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error(response.data.message || "No Data Murndata Found for this Email");
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

const fetchTableData = async (idToken, assetIds, page, limit) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
    params: {
      assetIds: assetIds.join(","),
      page: page,
      limit: limit,
    },
  };

  try {
    const response = await axios.get("/api/Portfolio/Maintenance_Table", config);
    return response.data;
  } catch (error) {

    return error?.response?.data;
  }
};

let updateasset = async (propertyId, updatedData, idToken, selectedItem) => {
  const assectid = selectedItem?._id;


  const url = `api/portfolio/updateAsset/${assectid}`;
  try {
    const response = await axios.patch(url, updatedData, {
      headers: {
        "auth-token": idToken,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error updating property: ${error.message}`);
  }
};

// Deals

const startDeals = async (idToken, payload) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
  };

  let output;
  await axios
    .post(Endpoint.PostQuestionsData, payload, config)
    .then((res) => {
      output = res?.data;
    })
    .catch(function (error) {
      output = error?.response?.data;
    });
  return output;
};

// Send Data to AddCheckList
const addCheckList = async (idToken, payload) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
  };

  let output;
  await axios
    .post(Endpoint.addCheckList, payload, config)
    .then((res) => {
      output = res?.data;
    })
    .catch(function (error) {
      output = error?.response?.data;
    });
  return output;
};

const getChatAccessUsers = async ({idToken, chatId, assetId}) => {
  try {
    const config = {
      headers: {
        "auth-token": idToken,
      },
    };

    const response = await axios.get(`/api/Portfolio/employee-Chat-Access/?assetId=${assetId}`, config);
    if (response) {
      return response.data.data;
    } else {
      throw new Error(response.data.message || "No Data Murndata Found for this Email");
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

const getBudgetingdata = async (idToken, payload) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
  };

  let output;
  await axios
    .post("api/Portfolio/Budgeting", payload, config)
    .then((res) => {
      output = res?.data;
    })
    .catch(function (error) {
      output = error?.response?.data;
    });
  return output;
};

const addToInvestNow = async (investmentData, idToken, assetId) => {
  try {
    const config = {
      headers: {
        "auth-token": idToken,
      },
    };
    const url = `/api/investment/${assetId}`;
    const response = await axios.post(url, investmentData, config);

    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : "Failed to add investment");
  }
};

const SimilarProperties_list = async (idToken, assetId) => {
  let config = {
    headers: {
      "auth-token": idToken,
    },
  };

  let output;
  try {
    const response = await axios.get(
      `api/assets/GetSimilarProperties/?assetId=${assetId}`,
      config
    );
    output = response?.data;
  } catch (error) {
    output = error?.response?.data;
  }

  return output;
};

// 

const getoffassets = async (payload, idToken) => {
  const config = {
    headers: {
      "auth-token": idToken,
    },
  };

  let output;
  await axios
    .post(Endpoint.Sendemailtgiveaccess, payload, config)
    .then((res) => {
      output = res?.data;
    })
    .catch(function (error) {
      output = error?.response?.data;
    });
  return output;
};

// task -------------------

const getTasks = async (userId, idToken) => {
  if (!userId) throw new Error("User ID is required.");

  const config = {
    headers: { "auth-token": idToken },
  };

  try {
    const res = await axios.get(`${Endpoint.getalltask}?userId=${userId}`, config);
    return res.data?.tasks || [];
  } catch (error) {
    if (error.response) throw error.response.data;
    if (error.request) throw new Error("Network error, please try again later.");
    throw new Error(error.message);
  }
};

export {
  getUserDetails,
  PostSignUpData,
  UpdateUserPassword,
  getUserPortfolio,
  PostQuestionsData,
  getAsset,
  UserLogIn,
  PostLoanSetupData,
  VerifyEmail,
  FetchValuationInfo,
  PostSetupSyndicate,
  GetSyndicates,
  fetchCrownFunding,
  PostManageSindicate,
  fetchMaintenanceData,
  fetchDataFromApi,
  fetchPortfoliodata,
  addingfetchinvistor,
  fetchAssetsByAdminEmail,
  sendemail,
  linechartgarph,
  PasswordReset,
  getassetbyid,
  Getallownerdata,
  FetchAssetsByAdminEmail,
  fetchMurndataByEmail,
  fetchTableData,
  updateasset,
  startDeals,
  addCheckList,
  getChatAccessUsers,
  getBudgetingdata,
  addToInvestNow,
  SimilarProperties_list,
  getTasks
};
