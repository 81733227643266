import React from 'react'
import './basicProfile.scss'
import HeaderImg from '../../../assets/Image/real-estate.png'
import { InputBox } from '../../../components/reusableComponents/FormFields/FormFields'
import { useState } from 'react';
import dropdown from "../../../assets/syndicate/svg/dropdown.svg";
import dollar from "../../../assets/loanrequest/svg/dollar.svg";
import DropDown from '../../../components/reusableComponents/Dropdown/Dropdown';

function BasicProfile() {

    const [countryList, setCountrylist] = useState(["My Next Asset", "Refinancing existing details", "Construction"]);
    const [countryOfCitizenship, setCountryOfCitizenship] = useState("My Next Asset");
    const [countryOfCitizenshipDA, setCountryOfCitizenshipDA] = useState(false);
    const [purchageList, setPurchageList] = useState(["Under Contract", "Making an Offer", "Activity Looking"]);
    const [selectedPurchageList, setSelectedPurchageList] = useState("Under Contract");
    const [purchageListDa, setPurchageListDa] = useState(false);
    const [statusList, setStatusList] = useState(["Active", "Pending", "Cancelled", "Closed"]);
    const [selectedStatusList, setSelectedStatusList] = useState("Active");
    const [statusListDa, setStatusListDa] = useState(false);
    return (
        <div className='basic'>
            <div className='basic_header'>
                <div>
                    <p>Basic Profile</p>
                </div>
            </div>

            <div className="inputs">
                <div className='input_box'>
                    <p className='title'>General Info</p>
            <div>
                    <InputBox label={'Company Name'} placeholder={'John'} />
                    </div>
                <div className="genral-input ">
                
                    <InputBox label={'Contact Email Address'} type={'email'} placeholder={'john.doe@example.com'} />
                    <InputBox label={'Phone Number'} type={'tel'} placeholder={'(123) 456-7890'} />
             

                </div>
                <div className='input_lower'>
                    <span>Description</span><br></br>
                    <textarea></textarea>
                    <span>Optional</span>
                </div>

                </div>
                <div style={{marginTop:'20px' , paddingBottom:'20px'}} className='input box'>
                <p className='title'>Location</p>

                <div className="genral-input b-i">
                    <InputBox label={'Street Address'} placeholder={'123 Main St'} />
                    <InputBox label={'Secondary Address Designation'} placeholder={'Apartment, Suite, etc.'} />
                    <InputBox label={'City'} placeholder={'Your City'} />
                    <InputBox label={'State'} placeholder={'Your State'} />
                    <InputBox label={'Zipcode'} placeholder={'Your Zipcode'} />
                    <DropDown
                        label="country"
                        data={statusList}
                        selectedData={selectedStatusList}
                        setSelectedData={setStatusList}
                        dropdownState={statusListDa}
                        dropdownSetter={setStatusListDa}
                    />

              
                </div>
          </div>
            </div>
            <div style={{display:'flex' , alignItems:'flex-start' , gap:'6px'}} className='lower'>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.49967 13.4993L3.99967 9.99935L2.83301 11.166L7.49967 15.8327L17.4997 5.83268L16.333 4.66602L7.49967 13.4993Z" fill="#9EA0A5"/>
</svg>

                <span>Changes are saved automatically</span>
            </div>
        </div>
    )
}

export default BasicProfile