import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  checklist: [],
  task: null,
  loading: false,
  error: null,
};

export const fetchChecklists = createAsyncThunk("checklist/fetchChecklists", async (assetId, {getState, rejectWithValue}) => {
  try {
    const {auth} = getState();
    const idToken = auth.idToken;

    const response = await axios.get(`api/assets/getCheckListData/${assetId}/checklist`, {
      headers: {
        "auth-token": idToken,
      },
    });

    if (!response.data.data || response.data.data.length === 0) {
      return [];
    }

    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchTask = createAsyncThunk("checklist/fetchTask", async ({assetId, checklistId, taskId}, {getState, rejectWithValue}) => {
  try {
    const {auth} = getState();
    const idToken = auth.idToken;

    const response = await axios.get(`/api/assets/${assetId}/checklists/${checklistId}/tasks`, {
      headers: {
        "auth-token": idToken,
      },
    });

    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const checkListSlice = createSlice({
  name: "checklist",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchChecklists.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchChecklists.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload.length > 0) {
          state.checklist = action.payload;
        } else {
          state.checklist = [];
        }
      })
      .addCase(fetchChecklists.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default checkListSlice.reducer;
