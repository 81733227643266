import React from "react";
import "./Reportedissues.scss";
import error from "../../../assets/Manage-syndicate/Svg/error.svg";
import SearchInput from "../../../components/reusableComponents/Search/Search";

const Reportedissues = () => {
  return (
    <div>
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <h1>Reported Issues</h1>
      </div>
      <div className="header-container">
        <div className="header-container-box">
          <div className="header-container-box-data">
            <h1>Unresolved</h1>
            <h1>Archived</h1>
            <h1>{""}</h1>
          </div>
        </div>
        <div className="back-report">
          <SearchInput />
        </div>
      </div>
      <div>
          <div className="Equityplans-data">
            <div className="Equityplans-data-container-maindiv">
              <div className="Equityplans-Error-Container">
                <div className="Equityplans-data-container-image">
                  <img
                    src={error}
                    alt="image"
                  />
                </div>
                <p>No active errors found</p>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Reportedissues;
