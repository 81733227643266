import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Endpoint from "../../utils/Endpoint";

const initialState = {
  assets: [],
  loading: false,
  error: null,
};


export const inviteinvestor = createAsyncThunk(
  "assets/inviteinvestor",
  async ({ idToken, email }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${Endpoint.getaddinvestor}?email=${email}`,  
        {
          headers: { "auth-token": idToken },
        }
      );
      if (response.status === 200) {
        return response.data.data || [];
      } else {
        return rejectWithValue("Failed to fetch assets");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const assetsSlice = createSlice({
  name: "InviteInvestor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(inviteinvestor.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(inviteinvestor.fulfilled, (state, action) => {
        state.loading = false;
        state.assets = action.payload;
      })
      .addCase(inviteinvestor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default assetsSlice.reducer;
