import React, { useState } from "react";
import "./Signup.scss";
import { Checkmark, CheckmarkTICK } from "../../assets/svg/checkmark";
import {
  IndividualActive,
  IndividualDisable,
  OrganizationActive,
  OrganizationDisable,
} from "../../assets/svg/SignUpSVGs";
import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";
import imgOne from "../../assets/Image/signupImg.png";
import emailConfirm from "../../assets/Image/emailConfirm.png";
import searchImg from "../../assets/Image/search.png";
import bankOne from "../../assets/Image/banks/one.png";
import bankTwo from "../../assets/Image/banks/two.png";
import bankThree from "../../assets/Image/banks/three.png";
import bankFour from "../../assets/Image/banks/four.png";
import bankFive from "../../assets/Image/banks/five.png";
import bankSix from "../../assets/Image/banks/six.png";
import bankSeven from "../../assets/Image/banks/seven.png";
import bankEight from "../../assets/Image/banks/eight.png";
import DropDown from "../../components/reusableComponents/Dropdown/Dropdown";
import {
  PrimaryButton,
  SmallSvgButton,
} from "../../components/reusableComponents/Buttons/Buttons";
import {
  InputBox,
  InfoBox,
} from "../../components/reusableComponents/FormFields/FormFields";
import { Slider } from "@mui/material";
const SignupLender = () => {
  const [sliderState, setSliderState] = useState("one");
  const [accreditedInvestor, setAccreditedInvestor] = useState(true);
  const [accountType, setAccountType] = useState("");
  const [accountSUBType, setAccountSUBType] = useState("");
  const [linkedAccount, setLinkedAccount] = useState("link");
  const [cityList, setCitylist] = useState(["Austin", "New York", "Atlanta"]);
  const [stateList, setStateList] = useState([
    "Texas",
    "Georgia",
    "California",
  ]);
  //all dropdownState
  //DA= Dropdown ACTIVE
  const [countryList, setCountrylist] = useState(["USA", "UK", "INDIA"]);
  const [countryOfCitizenship, setCountryOfCitizenship] = useState("USA");
  const [countryOfCitizenshipDA, setCountryOfCitizenshipDA] = useState(false);
  const [countryOfResidence, setCountryOfResidence] = useState("USA");
  const [countryOfResidenceDA, setCountryOfResidenceDA] = useState(false);

  //sixth section Data

  const [phonenumber, setPhoneNumber] = useState("");
  const entityList = [
    "Limited Partnership",
    "Single-Member LLC",
    "S-Corporation",
    "LLC",
    "C Corporation",
  ];
  const jointAccountList = [
    "Tenants in Common",
    "Rights of Survivorship",
    "Community Property",
  ];
  //sixth section dropdown
  const [city, setCity] = useState(cityList[0]);
  const [cityDA, setCityDA] = useState(false);
  const [stateAddress, setStateAddress] = useState(stateList[0]);
  const [stateAddressDA, setStateAddressDA] = useState(false);
  const [accountSignupCheck, setAccountSignupCheck] = useState(false);

  const [entity, setEntity] = useState(entityList[0]);
  const [entityDA, setEntityDA] = useState(false);

  const [juridiction, setJuridiction] = useState(stateList[0]);
  const [juridictionDA, setJuridictionDA] = useState(false);

  const [jointAccount, setJointAccount] = useState(jointAccountList[0]);
  const [jointAccountDA, setJointAccountDA] = useState(false);

  const [jointAccCity, setJointAccCity] = useState(cityList[0]);
  const [jointAccCityDA, setJointAccCityDA] = useState(false);

  const [jointAccState, setJointAccState] = useState(stateList[0]);
  const [jointAccStateDA, setJointAccStateDA] = useState(false);
  const [percentOwnership, setPercentOwnership] = useState(30);
  //seventh section data
  const amountList = ["$10,000", "$20,000", "$50,000"];
  const autoAmountList = ["$10,000", "$20,000", "$50,000"];
  const frequencyList = ["Once a month", "Once in 3 months", "Once a year"];
  const paymentDayList = () => {
    let array = [];
    const nth = function (d) {
      if (d === 1) {
        return d + "st";
      } else if (d === 2) {
        return d + "nd";
      } else if (d === 3) {
        return d + "rd";
      } else {
        return d + "th";
      }
    };
    for (let index = 0; index < 30; index++) {
      let value = nth(index + 1);
      array.push(value);
    }
    return array;
  };

  // seventh section dropdown
  const [initialContri, setInitialContri] = useState(amountList[0]);
  const [initialContriDA, setInitialContriDA] = useState(false);
  const [autoInvest, setAutoInvest] = useState(false);
  const [autoInvestAmount, setAutoInvestAmount] = useState(autoAmountList[0]);
  const [autoInvestDA, setAutoInvestDA] = useState(false);
  const [frequency, setFrequency] = useState(frequencyList[0]);
  const [frequencyDA, setFrequencyDA] = useState(false);
  const [paymentDay, setPaymentDay] = useState(() => {
    const array = paymentDayList();
    return array[0];
  });
  const [paymentDayDA, setPaymentDayDA] = useState(false);

  //Eight section

  const bankAccountTypeList = ["Checking", "Savings"];
  const [bankAccount, setBankAccount] = useState(bankAccountTypeList[0]);
  const [bankAccountDA, setBankAccountDA] = useState(false);

  //Ninth section data
  const [acknowledgementsChecks, setAcknowledgementsChecks] = useState({
    first: false,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
  });

  //tenth section
  const [confrimEmailChecks, setConfrimEmailChecks] = useState({
    first: false,
    second: false,
  });

  return (
    <div className="signupMainCon signupLender ">
      <div className="signupCon-L">
        <div className={"signupScroller " + sliderState}>
          <FirstSection setSliderState={setSliderState} />
          <SecondSection setSliderState={setSliderState} />
          {/* <ThirdSection
            setSliderState={setSliderState}
            accreditedInvestor={accreditedInvestor}
            setAccreditedInvestor={setAccreditedInvestor}
          /> */}
          <FourSection
            setSliderState={setSliderState}
            countryList={countryList}
            countryOfCitizenship={countryOfCitizenship}
            setCountryOfCitizenship={setCountryOfCitizenship}
            countryOfCitizenshipDA={countryOfCitizenshipDA}
            setCountryOfCitizenshipDA={setCountryOfCitizenshipDA}
            countryOfResidence={countryOfResidence}
            setCountryOfResidence={setCountryOfResidence}
            countryOfResidenceDA={countryOfResidenceDA}
            setCountryOfResidenceDA={setCountryOfResidenceDA}
          />
          <FiveSection
            accountType={accountType}
            setAccountType={setAccountType}
            accountSUBType={accountSUBType}
            setAccountSUBType={setAccountSUBType}
            setSliderState={setSliderState}
          />
          <SixtSection
            setSliderState={setSliderState}
            accountType={accountType}
            accountSUBType={accountSUBType}
            countryList={countryList}
            cityList={cityList}
            city={city}
            setCity={setCity}
            cityDA={cityDA}
            setCityDA={setCityDA}
            stateList={stateList}
            stateAddress={stateAddress}
            setStateAddress={setStateAddress}
            stateAddressDA={stateAddressDA}
            setStateAddressDA={setStateAddressDA}
            phonenumber={phonenumber}
            setPhoneNumber={setPhoneNumber}
            entityList={entityList}
            entity={entity}
            setEntity={setEntity}
            entityDA={entityDA}
            setEntityDA={setEntityDA}
            juridiction={juridiction}
            setJuridiction={setJuridiction}
            juridictionDA={juridictionDA}
            setJuridictionDA={setJuridictionDA}
            accountSignupCheck={accountSignupCheck}
            setAccountSignupCheck={setAccountSignupCheck}
            jointAccountList={jointAccountList}
            jointAccount={jointAccount}
            setJointAccount={setJointAccount}
            jointAccountDA={jointAccountDA}
            setJointAccountDA={setJointAccountDA}
            jointAccCity={jointAccCity}
            setJointAccCity={setJointAccCity}
            jointAccCityDA={jointAccCityDA}
            setJointAccCityDA={setJointAccCityDA}
            jointAccState={jointAccState}
            setJointAccState={setJointAccState}
            jointAccStateDA={jointAccStateDA}
            setJointAccStateDA={setJointAccStateDA}
            percentOwnership={percentOwnership}
            setPercentOwnership={setPercentOwnership}
          />
          <SevenSection
            setSliderState={setSliderState}
            accountType={accountType}
            accountSUBType={accountSUBType}
            amountList={amountList}
            stateList={stateList}
            initialContri={initialContri}
            setInitialContri={setInitialContri}
            initialContriDA={initialContriDA}
            setInitialContriDA={setInitialContriDA}
            setAutoInvest={setAutoInvest}
            autoInvest={autoInvest}
            autoAmountList={autoAmountList}
            autoInvestAmount={autoInvestAmount}
            setAutoInvestAmount={setAutoInvestAmount}
            autoInvestDA={autoInvestDA}
            setAutoInvestDA={setAutoInvestDA}
            frequencyList={frequencyList}
            frequency={frequency}
            setFrequency={setFrequency}
            frequencyDA={frequencyDA}
            setFrequencyDA={setFrequencyDA}
            paymentDayList={paymentDayList}
            paymentDay={paymentDay}
            setPaymentDay={setPaymentDay}
            paymentDayDA={paymentDayDA}
            setPaymentDayDA={setPaymentDayDA}
          />
          <EightSection
            setSliderState={setSliderState}
            accountType={accountType}
            accountSUBType={accountSUBType}
            linkedAccount={linkedAccount}
            setLinkedAccount={setLinkedAccount}
            bankAccountTypeList={bankAccountTypeList}
            bankAccount={bankAccount}
            setBankAccount={setBankAccount}
            bankAccountDA={bankAccountDA}
            setBankAccountDA={setBankAccountDA}
          />
          <NineSection
            setSliderState={setSliderState}
            accountType={accountType}
            accountSUBType={accountSUBType}
            acknowledgementsChecks={acknowledgementsChecks}
            setAcknowledgementsChecks={setAcknowledgementsChecks}
          />
          <TenSection
            setSliderState={setSliderState}
            accountType={accountType}
            accountSUBType={accountSUBType}
            confrimEmailChecks={confrimEmailChecks}
            setConfrimEmailChecks={setConfrimEmailChecks}
          />
        </div>
      </div>
      <div className="signupCon-R">
        {/* {sliderState} */}
        {sliderState === "nine" ? (
          <>
            <img className="imgemailConfirm" src={emailConfirm} alt="" />
          </>
        ) : (
          <img className="imgOneSignup" src={imgOne} alt="" />
        )}
      </div>
    </div>
  );
};

export default SignupLender;

// 1 Create Your Account
// 2 Did you receive an email address from your organization?
// 3 Are you an accredited investor?
// 4 We currently only accept investment from US residents.
// 5 Which account type would you like to open?
// 6 Contact Information (INDIVIDUAL)

function FirstSection({ setSliderState }) {
  return (
    <div className="signupFirstSection signupsection">
      <h1>Create Your Account</h1>
      <h2>Get started with Lilypads and invest wisely.</h2>
      <div className="inputFlexLine">
        <InputBox label="name" type="text" />{" "}
        <InputBox label="Surname" type="text" />
      </div>
      <InputBox label="Company name" type="text" />
      <InputBox label="business E-mail address" type="email" />
      <InputBox label="Password" type="password" />
      <PrimaryButton
        name="Get Started with Lilypads!"
        onclick={() => {
          setSliderState("two");
        }}
      />

      <h3>
        Already on Lilypads? <span>Sign In.</span>
      </h3>
    </div>
  );
}

function SecondSection({ setSliderState }) {
  return (
    <div className="signupSecondSection signupsection">
      <h1>Did you receive an email address from your organization?</h1>
      <h2>
        Lilypads is designed for use by employees in the organization, so the
        email address can be seen by other users who log on using . If the
        e-mail address has not been provided by your organization, you cannot
        use it in Lilypads. Addresses from free services, such as outlook.com or
        from general email service providers, are not allowed.
      </h2>

      <div className="checkBoxCon">
        {true ? <CheckmarkTICK /> : <Checkmark />}

        <h2>
          By registering I agree with <a href="#">Term & Conditions.</a>{" "}
        </h2>
      </div>

      {/* <div className="spacerMT200"></div> */}

      <div className="bottomPosiLock">
        <PrimaryButton
          name="Yes, use this e-mail"
          onclick={() => {
            setSliderState("four");
          }}
        />
        <div className="spacerMTM30"></div>
        <PrimaryButton
          name="No, I’ll use another"
          onclick={() => {
            setSliderState("one");
          }}
          classNames="grey"
        />{" "}
        <h3>
          Already on Lilypads? <span>Sign In.</span>
        </h3>
      </div>
    </div>
  );
}

function ThirdSection({
  setSliderState,
  accreditedInvestor,
  setAccreditedInvestor,
}) {
  return (
    <div className="signupThirdSection signupsection">
      <h1>Are you an accredited investor?</h1>
      <h2>
        An accredited investor is a person or a business entity who is allowed
        to deal in securities that may not be registered with financial
        authorities.
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.16797 14.168H10.8346V9.16797H9.16797V14.168ZM10.0013 1.66797C5.4013 1.66797 1.66797 5.4013 1.66797 10.0013C1.66797 14.6013 5.4013 18.3346 10.0013 18.3346C14.6013 18.3346 18.3346 14.6013 18.3346 10.0013C18.3346 5.4013 14.6013 1.66797 10.0013 1.66797ZM10.0013 16.668C6.3263 16.668 3.33464 13.6763 3.33464 10.0013C3.33464 6.3263 6.3263 3.33464 10.0013 3.33464C13.6763 3.33464 16.668 6.3263 16.668 10.0013C16.668 13.6763 13.6763 16.668 10.0013 16.668ZM9.16797 7.5013H10.8346V5.83463H9.16797V7.5013Z"
            fill="#3E3F42"
          />
        </svg>
      </h2>
      <div className="spacerMT4"></div>
      <div className={"radioButtonCon " + (accreditedInvestor && " active ")}>
        <div
          onClick={() => setAccreditedInvestor(true)}
          className="radioButtonO"
        >
          <div className="radioButtonI"></div>
        </div>
        Yes, I’m an accredited investor
      </div>
      <div className={"radioButtonCon " + (!accreditedInvestor && " active ")}>
        <div
          onClick={() => setAccreditedInvestor(false)}
          className="radioButtonO"
        >
          <div className="radioButtonI"></div>
        </div>
        No, I’m not an accredited investor
      </div>

      {/* <div className="spacerMT390"></div> */}

      <div className="bottomPosiLock">
        <div className="flexlineC">
          <SmallSvgButton
            onclick={() => {
              setSliderState("two");
            }}
            secondary={true}
            name="Back"
          />
          <SmallSvgButton
            onclick={() => {
              setSliderState("four");
            }}
            name="Continue"
          />
        </div>
        <h3>
          Already on Lilypads? <span>Sign In.</span>
        </h3>
      </div>
    </div>
  );
}

function FourSection({
  setSliderState,
  countryList,
  countryOfCitizenship,
  setCountryOfCitizenship,
  countryOfCitizenshipDA,
  setCountryOfCitizenshipDA,
  countryOfResidence,
  setCountryOfResidence,
  countryOfResidenceDA,
  setCountryOfResidenceDA,
}) {
  return (
    <div className="signupFourthSection signupsection">
      <h1>We currently only accept investment from US residents.</h1>
      <h2>Please specify your location details.</h2>

      {/* <InputBox label="COUNTRY OF CITIZENSHIP" type="text" /> */}

      {/* //custom drop down */}
      <DropDown
        label="COUNTRY OF CITIZENSHIP"
        data={countryList}
        selectedData={countryOfCitizenship}
        setSelectedData={setCountryOfCitizenship}
        dropdownState={countryOfCitizenshipDA}
        dropdownSetter={setCountryOfCitizenshipDA}
      />
      <DropDown
        label="COUNTRY OF RESIDENCE"
        data={countryList}
        selectedData={countryOfResidence}
        setSelectedData={setCountryOfResidence}
        dropdownState={countryOfResidenceDA}
        dropdownSetter={setCountryOfResidenceDA}
      />

      {/* <InputBox label="COUNTRY OF RESIDENCE" type="email" /> */}

      <div className="bottomPosiLock">
        <div className="flexlineC">
          <SmallSvgButton
            onclick={() => {
              setSliderState("three");
            }}
            secondary={true}
            name="Back"
          />
          <SmallSvgButton
            onclick={() => {
              setSliderState("five");
            }}
            name="Continue"
          />
        </div>
        <h3>
          Already on Lilypads? <span>Sign In.</span>
        </h3>
      </div>
    </div>
  );
}

function FiveSection({
  setSliderState,
  setAccountType,
  accountType,
  accountSUBType,
  setAccountSUBType,
}) {
  return (
    <div className="signupFifthSection signupsection">
      <h1>Which account type would you like to open?</h1>
      <h2>Let’s finish registering your account.</h2>

      <div
        style={{ marginBottom: "20px", marginTop: "40px" }}
        onClick={() => setAccountType("Individual")}
        className={
          "AccountSeclector_Individual " +
          (accountType === "Individual" && "active")
        }
      >
        {accountType === "Individual" ? (
          <IndividualActive />
        ) : (
          <IndividualDisable />
        )}
        Individual
        <div className="accountTypeCheck">
          {accountType === "Individual" ? <CheckmarkTICK /> : <Checkmark />}
        </div>
      </div>

      <div
        onClick={() => {
          setAccountType("Organization");
          if (accountSUBType === "") {
            setAccountSUBType("Entity");
          }
        }}
        className={
          "AccountSeclector_Individual org " +
          (accountType === "Organization" && "active")
        }
      >
        <div className="AccountSeclector_Individual-T">
          {accountType === "Organization" ? (
            <OrganizationActive />
          ) : (
            <OrganizationDisable />
          )}
          Organization
        </div>

        <div className="organisationExpand">
          <div
            onClick={() => {
              setAccountType("Organization");
              setAccountSUBType("Entity");
            }}
            className={
              "radioButtonCon accountselector " +
              (accountSUBType === "Entity" && " active ")
            }
          >
            <div className="radioButtonO">
              <div className="radioButtonI "></div>
            </div>
            Entity Account
          </div>
          <div
            onClick={() => {
              setAccountType("Organization");
              setAccountSUBType("Joint");
            }}
            className={
              "radioButtonCon accountselector " +
              (accountSUBType === "Joint" && " active ")
            }
          >
            <div className="radioButtonO">
              <div className="radioButtonI "></div>
            </div>
            Joint Account
          </div>
          <div
            onClick={() => {
              setAccountType("Organization");
              setAccountSUBType("Trust");
            }}
            className={
              "radioButtonCon  accountselector " +
              (accountSUBType === "Trust" && " active ")
            }
          >
            <div className="radioButtonO">
              <div className="radioButtonI "></div>
            </div>
            Trust Account
          </div>
        </div>
        <div className="accountTypeCheck">
          {accountType === "Organization" ? <CheckmarkTICK /> : <Checkmark />}
        </div>
      </div>

      <div className="bottomPosiLock">
        <div className="flexlineC">
          <SmallSvgButton
            onclick={() => {
              setSliderState("three");
            }}
            secondary={true}
            name="Back"
          />
          <SmallSvgButton
            onclick={() => {
              if (accountType === "") {
                alert("Select Account Type");
                return;
              }
              if (accountType === "Individual") {
                setSliderState("six");
                return;
              }
              if (
                (accountType === "Organization" &&
                  accountSUBType === "Entity") ||
                accountSUBType === "Joint" ||
                accountSUBType === "Trust"
              ) {
                setSliderState("six");
              } else {
                alert("Select Organization Type");
              }
            }}
            name="Continue"
          />
        </div>
        <h3>
          Already on Lilypads? <span>Sign In.</span>
        </h3>
      </div>
    </div>
  );
}

function SixtSection({
  setSliderState,
  accountType,
  accountSUBType,
  city,
  cityList,
  setCity,
  cityDA,
  setCityDA,
  stateList,
  stateAddress,
  setStateAddress,
  stateAddressDA,
  setStateAddressDA,
  countryList,
  phonenumber,
  setPhoneNumber,
  entityList,
  entity,
  setEntity,
  entityDA,
  setEntityDA,
  juridiction,
  setJuridiction,
  juridictionDA,
  setJuridictionDA,
  accountSignupCheck,
  setAccountSignupCheck,
  jointAccount,
  setJointAccount,
  jointAccountDA,
  setJointAccountDA,
  jointAccountList,
  jointAccCity,
  setJointAccCity,
  jointAccCityDA,
  setJointAccCityDA,
  jointAccState,
  setJointAccState,
  jointAccStateDA,
  setJointAccStateDA,
  percentOwnership,
  setPercentOwnership,
}) {
  return (
    <>
      {accountType === "Individual" && (
        <div className="signupFirstSection signupsection">
          <h1>Contact Information</h1>
          <h2>Lilypads needs a few more details about you.</h2>
          <InputBox label="ADDRESS LINE 1" type="text" />{" "}
          <h2 style={{ opacity: "0.8" }}>
            This should be the address used for tax purposes.
          </h2>
          <InputBox label="ADDRESS LINE 2" type="text" />
          <DropDown
            label="CITY"
            data={cityList}
            selectedData={city}
            setSelectedData={setCity}
            dropdownState={cityDA}
            dropdownSetter={setCityDA}
          />
          <div className="inputFlexLine">
            <DropDown
              classDataName="small135"
              label="State"
              data={stateList}
              selectedData={stateAddress}
              setSelectedData={setStateAddress}
              dropdownState={stateAddressDA}
              dropdownSetter={setStateAddressDA}
            />
            <InputBox label="ZIP CODE" type="text" />
          </div>
          <div className="phoneInpCon">
            <PhoneInput
              international
              defaultCountry="US"
              placeholder="Enter phone number"
              value={phonenumber}
              onChange={setPhoneNumber}
            />
          </div>
          <InputBox label="Birthday Date" type="date" />
          <div className="bottomPosiLock">
            <div className="flexlineC">
              <SmallSvgButton
                onclick={() => {
                  setSliderState("five");
                }}
                secondary={true}
                name="Back"
              />
              <SmallSvgButton
                onclick={() => {
                  setSliderState("seven");
                }}
                name="Continue"
              />
            </div>
            <h3>
              Already on Lilypads? <span>Sign In.</span>
            </h3>
          </div>
        </div>
      )}
      {accountType === "Organization" && accountSUBType === "Entity" && (
        <div className="signupSixthSection signupsection">
          <h1>Entity Information</h1>
          <h2>We just need a few more details.</h2>
          <InputBox label="Entity Name" type="text" />{" "}
          <DropDown
            label="Entity Type"
            data={entityList}
            selectedData={entity}
            setSelectedData={setEntity}
            dropdownState={entityDA}
            dropdownSetter={setEntityDA}
          />
          <InputBox label="EIN" type="text" placeholder={"___-______"} />
          <InputBox label="Industry" type="text" />
          <InputBox label="Date of Formation" type="date" />
          <DropDown
            label="Jurisdiction of Registration"
            data={jointAccountList}
            selectedData={jointAccount}
            setSelectedData={setJointAccount}
            dropdownState={jointAccountDA}
            dropdownSetter={setJointAccountDA}
          />
          <div className="entity_upload_buttons">
            <InputBox label="Upload Certificate of Formation" type="file" />
            <InputBox label="Upload Operating Agreement" type="file" />
          </div>
          <div
            className="acknowlegeCON"
            onClick={() => setAccountSignupCheck(!accountSignupCheck)}
          >
            {accountSignupCheck ? <CheckmarkTICK /> : <Checkmark />}
            <h2>
              I will provide the documents later. I acknowledge that my
              investment will not be processed until I provide this document.
            </h2>
          </div>
          <div className="flexlineC">
            <SmallSvgButton
              onclick={() => {
                setSliderState("four");
              }}
              secondary={true}
              name="Back"
            />
            <SmallSvgButton
              onclick={() => {
                setSliderState("six");
              }}
              name="Continue"
            />
          </div>
          <h3>
            Already on Lilypads? <span>Sign In.</span>
          </h3>
        </div>
      )}
      {accountType === "Organization" && accountSUBType === "Joint" && (
        <div className="signupSixthSection signupsection">
          <h1>Joint Account</h1>
          <h2>We just need a few more details.</h2>
          <DropDown
            label="Joint Account Type"
            data={jointAccountList}
            selectedData={jointAccount}
            setSelectedData={setJointAccount}
            dropdownState={jointAccountDA}
            dropdownSetter={setJointAccountDA}
          />
          <div className="spacerLine30"></div>
          <h2>Account Holder #1</h2>
          <InputBox label="ADDRESS LINE 1" type="text" placeholder="Street" />
          <h2 style={{ opacity: "0.8" }}>
            This should be the address used for tax purposes.
          </h2>
          <InputBox
            label="ADDRESS LINE 2"
            type="text"
            placeholder="Apt, ZIP, etc."
          />
          <DropDown
            label="CITY"
            data={cityList}
            selectedData={jointAccCity}
            setSelectedData={setJointAccCity}
            dropdownState={jointAccCityDA}
            dropdownSetter={setJointAccCityDA}
          />
          <div style={{ maxWidth: "" }} className="inputFlexLine">
            <DropDown
              label="STATE"
              data={stateList}
              selectedData={jointAccState}
              setSelectedData={setJointAccState}
              dropdownState={jointAccStateDA}
              dropdownSetter={setJointAccStateDA}
            />
            <InputBox label="ZIP CODE" type="text" />
          </div>
          <div className="phoneInpCon">
            <PhoneInput
              international
              defaultCountry="US"
              placeholder="Enter phone number"
              value={phonenumber}
              onChange={setPhoneNumber}
            />
          </div>
          {/* <InputBox label="Percent Ownership" type="text" /> */}

          <div className="dropDownOuterCon ">
            <p style={{ marginBottom: "0px" }}> Percent Ownership </p>

            <div className="slider_container">
              <div className="top_div inputFlexLine">
                <span>{percentOwnership}</span>
                <p>%</p>
              </div>
              <Slider
                defaultValue={percentOwnership}
                aria-label="Default"
                valueLabelDisplay="auto"
                onChange={(event) => {
                  setPercentOwnership(event.target.value);
                }}
              />
            </div>
          </div>

          <InputBox label="Social Security Number" type="text" />
          <InputBox label="Date of Formation" type="date" />
          <div className="spacerLine30"></div>
          <h2>Account Holder #2</h2>
          <div style={{ maxWidth: "" }} className="inputFlexLine">
            <InputBox label="Name" type="text" placeholder="Herman" />
            <InputBox label="Surname" type="text" placeholder="Hesse" />
          </div>
          <InputBox label="Social Security Number" type="text" />
          <InputBox label="Date of Formation" type="date" />
          <div
            className="acknowlegeCON"
            onClick={() => setAccountSignupCheck(!accountSignupCheck)}
          >
            {accountSignupCheck ? <CheckmarkTICK /> : <Checkmark />}
            <h2>
              The second person associated with this joint account has the same
              address and phone number.
            </h2>
          </div>
          <div className="flexlineC">
            <SmallSvgButton
              onclick={() => {
                setSliderState("four");
              }}
              secondary={true}
              name="Back"
            />
            <SmallSvgButton
              onclick={() => {
                setSliderState("six");
              }}
              name="Continue"
            />
          </div>
          <h3>
            Already on Lilypads? <span>Sign In.</span>
          </h3>
        </div>
      )}
      {accountType === "Organization" && accountSUBType === "Trust" && (
        <div className="signupSixthSection signupsection">
          <h1>Trust Account</h1>
          <h2>We just need a few more details.</h2>
          <DropDown
            label="Joint Account Type"
            data={jointAccountList}
            selectedData={jointAccount}
            setSelectedData={setJointAccount}
            dropdownState={jointAccountDA}
            dropdownSetter={setJointAccountDA}
          />
          <InfoBox
            icon={null}
            message={
              "Note: This form of account ownership is only available to married individuals residing in Arizona, California, Idaho, Louisiana, Nevada, New Mexico, Texas, Wisconsin, or Washington."
            }
          />
          <div className="spacerLine30"></div>
          <h2>Account Holder #1</h2>
          <InputBox label="ADDRESS LINE 1" type="text" placeholder="Street" />
          <h2 style={{ opacity: "0.8" }}>
            This should be the address used for tax purposes.
          </h2>
          <InputBox
            label="ADDRESS LINE 2"
            type="text"
            placeholder="Apt, ZIP, etc."
          />
          <DropDown
            label="CITY"
            data={cityList}
            selectedData={jointAccCity}
            setSelectedData={setJointAccCity}
            dropdownState={jointAccCityDA}
            dropdownSetter={setJointAccCityDA}
          />
          <div style={{ maxWidth: "" }} className="inputFlexLine">
            <DropDown
              label="STATE"
              data={stateList}
              selectedData={jointAccState}
              setSelectedData={setJointAccState}
              dropdownState={jointAccStateDA}
              dropdownSetter={setJointAccStateDA}
            />
            <InputBox label="ZIP CODE" type="text" />
          </div>
          <div className="phoneInpCon">
            <PhoneInput
              international
              defaultCountry="US"
              placeholder="Enter phone number"
              value={phonenumber}
              onChange={setPhoneNumber}
            />
          </div>
          {/* <InputBox label="Percent Ownership" type="text" /> */}

          <InputBox label="Social Security Number" type="text" />
          <InputBox label="Date of Formation" type="date" />
          <div className="spacerLine30"></div>
          <h2>Account Holder #2</h2>
          <div style={{ maxWidth: "" }} className="inputFlexLine">
            <InputBox label="Name" type="text" placeholder="Herman" />
            <InputBox label="Surname" type="text" placeholder="Hesse" />
          </div>
          <InputBox label="Social Security Number" type="text" />
          <InputBox label="Date of Formation" type="date" />
          <div
            className="acknowlegeCON"
            onClick={() => setAccountSignupCheck(!accountSignupCheck)}
          >
            {accountSignupCheck ? <CheckmarkTICK /> : <Checkmark />}
            <h2>
              The second person associated with this joint account has the same
              address and phone number.
            </h2>
          </div>
          <div className="flexlineC">
            <SmallSvgButton
              onclick={() => {
                setSliderState("four");
              }}
              secondary={true}
              name="Back"
            />
            <SmallSvgButton
              onclick={() => {
                setSliderState("six");
              }}
              name="Continue"
            />
          </div>
          <h3>
            Already on Lilypads? <span>Sign In.</span>
          </h3>
        </div>
      )}
    </>
  );
}
function SevenSection({
  setSliderState,
  initialContri,
  setInitialContri,
  initialContriDA,
  setInitialContriDA,
  amountList,
  autoInvest,
  setAutoInvest,
  autoAmountList,
  stateList,
  autoInvestAmount,
  setAutoInvestAmount,
  autoInvestDA,
  setAutoInvestDA,
  frequencyList,
  frequency,
  setFrequency,
  frequencyDA,
  setFrequencyDA,
  paymentDayList,
  paymentDay,
  setPaymentDay,
  paymentDayDA,
  setPaymentDayDA,
}) {
  return (
    <div className="signupFourthSection signupsection">
      <h1>Lender Details</h1>
      <h2>We just need a few more details.</h2>

      <InputBox type="text" label="JOB TITLE" />
      <InputBox type="text" label="Responsibilities" />
      <InputBox type="text" label="License" />
      <InputBox
        type="date"
        label="Date of Formation"
        placeholder="_ _ _ _ _ _ _ _ _"
      />
      <DropDown label="JURISDICTION OF REGISTRATION" />
      <InputBox
        type="file"
        label="Upload License files"
        labelBtm="Optional"
        conCLass="L"
      />

      <div
        className="acknowlegeCON"
        onClick={() => {
          setAutoInvest(!autoInvest);
        }}
      >
        {autoInvest ? <CheckmarkTICK /> : <Checkmark />}
        <h2>
          <span style={{ opacity: "0.7", marginTop: "10px" }}>
            I will provide the documents later. I acknowledge that my investment
            will not be processed until I provide this document.
          </span>
        </h2>
      </div>

      <div className="bottomPosiLock">
        <div className="flexlineC">
          <SmallSvgButton
            onclick={() => {
              setSliderState("five");
            }}
            secondary={true}
            name="Back"
          />
          <SmallSvgButton
            onclick={() => {
              setSliderState("seven");
            }}
            name="Continue"
          />
        </div>
        <h3>
          Already on Lilypads? <span>Sign In.</span>
        </h3>
      </div>
    </div>
  );
}
function EightSection({
  setSliderState,
  linkedAccount,
  setLinkedAccount,
  bankAccountTypeList,
  bankAccount,
  setBankAccount,
  bankAccountDA,
  setBankAccountDA,
}) {
  return (
    <div className="signupEightSection signupsection">
      <h1>Choose a source of funds.</h1>
      <h2>
        Connect your account by authenticating through your online banking
        credentials.
      </h2>
      <div className={"tabselectorL " + linkedAccount}>
        <div className="tabSelT">
          <div onClick={() => setLinkedAccount("link")} className="tabItem">
            Link Account
          </div>
          <div onClick={() => setLinkedAccount("manual")} className="tabItem">
            Enter Manually
          </div>
        </div>
        <div className="tabSelB"></div>

        <div className="tabline">
          <div className="tablineInner"></div>
        </div>
      </div>
      {linkedAccount === "link" && (
        <div className="linkedAccountCon">
          <div className="searchBar">
            <input placeholder="Search" type="text" />
            <img src={searchImg} alt="" />
          </div>

          <div className="linkAccountImgCon">
            <img src={bankOne} alt="" />
            <img src={bankTwo} alt="" />
            <img src={bankThree} alt="" />
            <img src={bankFour} alt="" />
            <img src={bankFive} alt="" />
            <img src={bankFive} alt="" />
            <img src={bankSix} alt="" />
            <img src={bankEight} alt="" />
          </div>
        </div>
      )}
      {linkedAccount === "manual" && (
        <>
          <DropDown
            label="ACCOUNT TYPE"
            data={bankAccountTypeList}
            selectedData={bankAccount}
            setSelectedData={setBankAccount}
            dropdownState={bankAccountDA}
            dropdownSetter={setBankAccountDA}
          />
          <InputBox label="BANK NAME" type="text" />
          <InputBox label="ROUTING NUMBER" type="text" />
          <InputBox label="ACCOUNT NUMBER" type="text" />
          <InputBox label="CONFIRM ACCOUNT NUMBER" type="text" />
        </>
      )}

      <div className="bottomPosiLock">
        <div className="flexlineC">
          <SmallSvgButton
            onclick={() => {
              setSliderState("six");
            }}
            secondary={true}
            name="Back"
          />
          <SmallSvgButton
            onclick={() => {
              setSliderState("eight");
            }}
            name="Continue"
          />
        </div>
        <h3>
          Already on Lilypads? <span>Sign In.</span>
        </h3>
      </div>
    </div>
  );
}
function NineSection({
  setSliderState,
  acknowledgementsChecks,
  setAcknowledgementsChecks,
}) {
  return (
    <div className="signupnine signupsection">
      <h1>Aknowledgements</h1>
      <h2>
        Please indicate agreement with the following: count by authenticating
        through your online banking credentials.
      </h2>
      <div
        style={{ margin: "20px 0" }}
        className="acknowlegeCON"
        onClick={() =>
          setAcknowledgementsChecks((prev) => ({ ...prev, first: !prev.first }))
        }
      >
        {acknowledgementsChecks.first ? <CheckmarkTICK /> : <Checkmark />}
        <h2>
          I have reviewed each Offering Circular and Subscription Agreement, per
          my selected investment plan, and understand the risks associated with
          such offerings.
        </h2>
      </div>
      <div
        style={{ margin: "20px 0" }}
        className="acknowlegeCON"
        onClick={() =>
          setAcknowledgementsChecks((prev) => ({
            ...prev,
            second: !prev.second,
          }))
        }
      >
        {acknowledgementsChecks.second ? <CheckmarkTICK /> : <Checkmark />}
        <h2>
          I reoresent that my investment(s) in this offering(s) does not
          constitute more than the greater of 10% of my gross annual income or
          net worth, either individually or in the aggregate.
        </h2>
      </div>
      <div
        style={{ margin: "20px 0" }}
        className="acknowlegeCON"
        onClick={() =>
          setAcknowledgementsChecks((prev) => ({ ...prev, third: !prev.third }))
        }
      >
        {acknowledgementsChecks.third ? <CheckmarkTICK /> : <Checkmark />}
        <h2>
          I understand that there is no guarantee of any financial return on
          this investment(s) and that I run the risk of loss in my
          investment(s); and that I have not been provided tax advice by
          Lilypads.
        </h2>
      </div>
      <div
        style={{ margin: "20px 0" }}
        className="acknowlegeCON"
        onClick={() =>
          setAcknowledgementsChecks((prev) => ({
            ...prev,
            fourth: !prev.fourth,
          }))
        }
      >
        {acknowledgementsChecks.fourth ? <CheckmarkTICK /> : <Checkmark />}
        <h2>
          I recognize that my investment is in real property, which is
          fundamentally a long-term, illiquid investment; that liquidations, if
          approved, are paid out quarterly for the eRElTs, and monthly after a
          minimum 60-day waiting period for the eFunds; and requests for
          liquidation may be suspended during periods of financial stress.
        </h2>
      </div>
      <div
        style={{ margin: "20px 0", marginBottom: "40px" }}
        className="acknowlegeCON"
        onClick={() =>
          setAcknowledgementsChecks((prev) => ({ ...prev, fifth: !prev.fifth }))
        }
      >
        {acknowledgementsChecks.fifth ? <CheckmarkTICK /> : <Checkmark />}
        <h2>
          I certify that the information provided is true and correct and
          understand it will be used in the W-9. I have reviewed and acknowledge
          the W-9.
        </h2>
      </div>

      <div className="flexlineC">
        <SmallSvgButton
          onclick={() => {
            setSliderState("seven");
          }}
          secondary={true}
          name="Back"
        />
        <SmallSvgButton
          onclick={() => {
            setSliderState("nine");
          }}
          name="Continue"
        />
      </div>
      <h3>
        Already on Lilypads? <span>Sign In.</span>
      </h3>
    </div>
  );
}
function TenSection({
  setSliderState,
  confrimEmailChecks,
  setConfrimEmailChecks,
}) {
  return (
    <div className="signupnine signupsection">
      <h1>Confirm your email</h1>
      <h2>
        We’ve sent an email to you@example.com. Please check your inbox for an
        activation link.
      </h2>
      <InputBox label="Confirmation Code" type="password" />
      <div
        style={{ margin: "40px 0px" }}
        className="acknowlegeCON"
        onClick={() =>
          setConfrimEmailChecks((prev) => ({
            ...prev,
            first: !prev.first,
          }))
        }
      >
        {confrimEmailChecks.first ? <CheckmarkTICK /> : <Checkmark />}
        <h2>
          Let Lilypads pass on my information to certain partners so they can
          send me up-to-date information about their products and services. If
          you would like to receive more information or unsubscribe at any time,
          please read the privacy statement.
        </h2>
      </div>
      <div
        style={{ marginBottom: "80px" }}
        className="acknowlegeCON"
        onClick={() =>
          setConfrimEmailChecks((prev) => ({
            ...prev,
            second: !prev.second,
          }))
        }
      >
        {confrimEmailChecks.second ? <CheckmarkTICK /> : <Checkmark />}
        <h2>
          Lilypads can send me promotional and commercial offers about{" "}
          <a href="#">Lilypads</a> products and services for the enterprise.
        </h2>
      </div>
      <Link to="/signup/lender/successfull">
        <PrimaryButton name="Confirm" />
      </Link>
      <div className="spacerMTM30"></div>
      <PrimaryButton
        name="Re-send registration code"
        onclick={() => {
          setSliderState("one");
        }}
        classNames="grey"
      />
      <h3>
        Already on Lilypads? <span>Sign In.</span>
      </h3>
    </div>
  );
}
