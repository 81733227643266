import "./App.scss";
import "react-phone-number-input/style.css";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./store/store";
import "./App.scss";
import axios from "axios";
import React, {useState, useEffect} from "react";
import Router from "./router/router";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  // useEffect(() => {
  //   if (window.location.origin != 'http://localhost:3000')
  //
  //   //
  // }, [window.location])

  const [isVPNUsed, setIsVPNUsed] = useState(false);
  const [userIP, setUserIP] = useState("");
  const API = "7c1b339f6db84415803ab42a21c951ff";
  const styles = {
    fontSize: "50px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    color: "red",
  };

  // useEffect(() => {
  //   const fetchUserIP = async () => {
  //     try {
  //       const response = await axios.get("https://api.ipify.org?format=json");
  //       setUserIP(response.data.ip);
  //       checkVPNUsage(response.data.ip);
  //     } catch (error) {

  //     }
  //   };

  //   fetchUserIP();
  // }, []);

  // const checkVPNUsage = async (ipAddress) => {
  //   try {
  //     const response = await axios.get(`https://api.focsec.com/v1/ip/${ipAddress}?api_key=${API}`);
  //     if (response?.data?.is_vpn) {
  //       setIsVPNUsed(true);
  //     } else {
  //       setIsVPNUsed(false);
  //     }
  //   } catch (error) {

  //   }
  // };

  if (isVPNUsed) {
    return <h1 style={styles}>You are using vpn....</h1>;
  }

  // BASE URL -----------

  // Old Server -

  // axios.defaults.baseURL = "http://139.5.189.142:8101/";
  // axios.defaults.baseURL = "http://18.223.151.220";
  // axios.defaults.baseURL = "192.168.1.10:6001/";

  // Local ---------------------------------------------------

  // axios.defaults.baseURL = "http://localhost:6001/";

  // Tech Demo -

  axios.defaults.baseURL = "https://demoapi.lilypads.com";

  return (
    <div className="">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <div className="App">
            {/* <RouterProvider router={router} /> */}
            <Router />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          </div>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
