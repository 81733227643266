import { PrimaryButton } from "../../components/reusableComponents/Buttons/Buttons";
import check from "../../assets/svg/check.svg";
import plus from "../../assets/svg/plus.svg";
import "./style.scss";
const SignupLanding = () => {
  return (
    <div className="SignupPricing .singupLanding">
      <div className="cardsContainer">
        <div className="cardsContainerT">
          <h1>Pricing for investors of any level</h1>
        </div>
        <div className="cardsContainerB">
          <div className="card">
            <div className="top">
              <div className="text_wrapper">
                <h2>Investor</h2>
                <p>
                  An Individual or other Entity (such as a firm or mutual fund)
                  who commits capital with the expectation of receiving
                  financial returns.
                </p>
                <a href="/signup/investor">
                  <PrimaryButton name="Continue" />
                </a>
              </div>
              <div className="spacerLine30"></div>
            </div>
            <div className="bottom">
              <div className="row">
                <img src={check} alt="" />
                <p> Networking </p>
              </div>
              <div className="row">
                <img src={check} alt="" />
                <p> Crowdfunding </p>
              </div>
              <div className="row">
                <img src={check} alt="" />
                <p> Invest in Funds (Syndicate) </p>
              </div>
              <div className="row">
                <img src={check} alt="" />
                <p> Smart Contract </p>
              </div>
              <div className="row">
                <img src={check} alt="" />
                <p> Track Investments</p>
              </div>
              <div className="row">
                <img src={check} alt="" />
                <p> Promote Content </p>
              </div>
              <div className="row">
                <img src={check} alt="" />
                <p> Follow Investment Managers </p>
              </div>

              <span>It is possible to expand the profile to Fund Manager:</span>
              <div className="row">
                <img src={plus} alt="" />
                <p> Set up & Manage Funds (Syndicate)</p>
              </div>
              <div className="row">
                <img src={plus} alt="" />
                <p> Find Investors</p>
              </div>
              <div className="row">
                <img src={plus} alt="" />
                <p>Acquisition (Invest in Assets) </p>
              </div>
              <div className="row">
                <img src={plus} alt="" />
                <p> Reporting and compliance</p>
              </div>
              <div className="row">
                <img src={plus} alt="" />
                <p>Transaction management workflow</p>
              </div>
            </div>
          </div>
          {/* <div className="card">
            <div className="top">
              <div className="text_wrapper">
                <h2>Broker</h2>
                <p>
                  An Individual or Firm that acts as an intermediary between an
                  investor and a securities exchange.
                </p>
                <a href="/signup/broker">
                  <PrimaryButton name="Continue" />
                </a>
              </div>
              <div className="spacerLine30"></div>
            </div>
            <div className="bottom">
              <div className="row">
                <img src={check} alt="" />
                <p> Networking </p>
              </div>
              <div className="row">
                <img src={check} alt="" />
                <p> Crowdfunding </p>
              </div>
              <div className="row">
                <img src={check} alt="" />
                <p> Invest in Funds (Syndicate) </p>
              </div>
              <div className="row">
                <img src={check} alt="" />
                <p> Smart Contract </p>
              </div>
              <div className="row">
                <img src={check} alt="" />
                <p> Track Investments</p>
              </div>
              <div className="row">
                <img src={check} alt="" />
                <p> Promote Content </p>
              </div>
              <div className="row">
                <img src={check} alt="" />
                <p> Find Investors </p>
              </div>

              <div className="row">
                <img src={plus} alt="" />
                <p> Acquisition (Invest in assets)</p>
              </div>
              <div className="row">
                <img src={plus} alt="" />
                <p> Reporting and compliance</p>
              </div>
              <div className="row">
                <img src={plus} alt="" />
                <p>Transaction management workflow</p>
              </div>
              <div className="row">
                <img src={plus} alt="" />
                <p> Portfolio Management</p>
              </div>
              <div className="row">
                <img src={plus} alt="" />
                <p>User Management</p>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="top">
              <div className="text_wrapper">
                <h2>Lender</h2>
                <p>
                  An Individual, a public or private group, or a financial
                  institution that makes funds available to another with the
                  expectation that the funds will be repaid.
                </p>
                <a href="/signup/lender">
                  <PrimaryButton name="Continue" />
                </a>
              </div>
              <div className="spacerLine30"></div>
            </div>
            <div className="bottom">
              <div className="row">
                <img src={check} alt="" />
                <p> Networking </p>
              </div>
              <div className="row">
                <img src={check} alt="" />
                <p> Crowdfunding </p>
              </div>
              <div className="row">
                <img src={plus} alt="" />
                <p>Acquisition (Invest in Assets) </p>
              </div>
              <div className="row">
                <img src={check} alt="" />
                <p> Smart Contract </p>
              </div>
              <div className="row">
                <img src={check} alt="" />
                <p> Track Investments</p>
              </div>
              <div className="row">
                <img src={check} alt="" />
                <p> Promote Content </p>
              </div>

              <div className="row">
                <img src={plus} alt="" />
                <p> Find Investors</p>
              </div>

              <div className="row">
                <img src={plus} alt="" />
                <p> Reporting and compliance</p>
              </div>
              <div className="row">
                <img src={plus} alt="" />
                <p>Transaction management workflow</p>
              </div>
              <div className="row">
                <img src={plus} alt="" />
                <p>Lender Management (scoring)</p>
              </div>
              <div className="row">
                <img src={plus} alt="" />
                <p>TUser Management</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SignupLanding;
