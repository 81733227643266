import React from 'react'
import './savedItems.scss'
import see from '../../../assets/svg/see-arrow.svg'
import searched from '../../../assets/svg/searched.svg'
import fav from '../../../assets/svg/fav.svg'
import budget from '../../../assets/svg/budget.svg'

function SavedItems({handleMenuClicked}) {
  return (
    <div className='saveditems'>
       <SavedBox
        imageUrl={searched}
        title='Saved Searches'
        description="Save your searches so you don't have to reconfigure the filters each time."
        seeMoreText='See More'
        id={13}
        handleMenuClicked={handleMenuClicked}
        
      />
      <SavedBox
        imageUrl={fav}
        title='Favourite Assets'
        description='Review the assets you liked during previous searches'
        seeMoreText='Explore More'
        id={23}
        handleMenuClicked={handleMenuClicked}
      />
      <SavedBox
        imageUrl={budget}
        title='Budegting Forecast'
        description='Custom description for the savedbox.'
        seeMoreText='View All'
        id={19}
        handleMenuClicked={handleMenuClicked}
      />

        
    </div>
  )
}

export default SavedItems



const SavedBox = ({ imageUrl, title, description, seeMoreLink ,id, handleMenuClicked }) => {
    return (
      <div className='savedbox'>
        <div className='img'>
        <img src={imageUrl} alt='' />
        </div>
        <div>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <span onClick={() => handleMenuClicked(id)}>See More  <img src={see} alt='' /></span>
      </div>
    );
  };