import React, {useState} from "react";
import {useTable} from "react-table";
import Pagination from "@mui/material/Pagination";
import "./ReactTable.scss";
import {PaginationItem} from "@mui/material";

const ReactTable = ({columns, data, totalData}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 10; 
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, gotoPage} = useTable({
    columns,
    data,
  });

  const totalItems = rows.length;
  const totalPages = Math.ceil(totalItems / pageSize);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage - 1);
  };

  const getPageItems = () => {
    const startIndex = currentPage * pageSize;
    const endIndex = Math.min(startIndex + pageSize, totalItems);
    return rows.slice(startIndex, endIndex);
  };

  const [gotoPageInput, setGotoPageInput] = useState("");

  const handleGotoPage = () => {
    const pageNumber = parseInt(gotoPageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber - 1);
      setGotoPageInput("");
    }
  };
  const formatPageNumber = (pageNumber) => {
    return pageNumber?.toString().padStart(2, "0");
  };

  return (
    <div className="main_table">
      <div className="table_container">
        <table
          {...getTableProps()}
          className="table"
        >
          <thead className="table_head">
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="head_row"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="table_tbody"
          >
            {getPageItems().map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="tbody_row"
                  key={index}
                >
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>{" "}
          {totalData && (
            <tr className="total_row">
              {columns.map((column, index) => (
                <td
                  key={index}
                  colSpan={index === 0 ? columns.length - totalData[0].length : 1}
                >
                  {totalData[0][column.accessor] || (index === 0 ? "Totals" : "")}
                </td>
              ))}
            </tr>
          )}
        </table>
      </div>
      {totalPages > 1 && (
        <div className="pagination_container">
          <Pagination
            className="custom_pagination"
            count={totalPages}
            page={currentPage + 1}
            onChange={handlePageChange}
            color="primary"
            variant="outlined"
            shape="rounded"
            renderItem={(item) => {
              return (
                <PaginationItem
                  {...item}
                  page={formatPageNumber(item.page)}
                />
              );
            }}
          />
          <div className="goto-page-input">
            <input
              type="number"
              placeholder="Go to Page"
              value={gotoPageInput}
              onChange={(e) => setGotoPageInput(e.target.value)}
            />
            <button
              className="goToButton"
              onClick={handleGotoPage}
            >
              Go
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReactTable;
