import React, {useState} from "react";
import ReactApexChart from "react-apexcharts";
import "./CashFlowHistoryChart.scss";
import actionBtnIcon from "../../../../../../../../assets/investor-pipeline/icons/action_icon.svg";
import cloudBtnIcon from "../../../../../../../../assets/investor-pipeline/icons/cloud_download_btn.svg";
import distributionLegend from "../../../../../../../../assets/investor-pipeline/icons/distribution_legend_icon.svg";
import contributionLegend from "../../../../../../../../assets/investor-pipeline/icons/contribution_legend_icon.svg";
import commitmentLegend from "../../../../../../../../assets/investor-pipeline/icons/commitment_legend_icon.svg";
const CashFlowHistoryChart = () => {
  const series = [
    {
      name: "Series 1",
      data: [25, 30, 28, 29, 24, 25, 30, 26, 27, 25, 24, 23, 22, 24, 25, 23, 21, 24, 25, 26, 27, 28, 29, 25, 26, 27, 28, 29, 25, 26, 27],
      color: "#3FA2F7",
    },
    {
      name: "Series 2",
      data: [20, 25, 23, 24, 19, 20, 25, 21, 22, 20, 19, 18, 17, 19, 20, 18, 16, 19, 20, 21, 22, 23, 24, 20, 21, 22, 23, 24, 20, 21, 22],
      color: "#CB333B",
    },
    {
      name: "Series 3",
      data: [28, 33, 31, 32, 27, 28, 33, 29, 30, 28, 27, 26, 25, 27, 28, 26, 24, 27, 28, 29, 30, 31, 32, 28, 29, 30, 31, 32, 28, 29, 30],
      color: "#7D55C7B5",
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line",
    },
    stroke: {
      width: [2, 2, 2],
      curve: "straight",
      dashArray: [0, 0, 5], // Dotted line for the third series
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + "";
      },
    },
    markers: {
      size: [0, 0, 4], // Markers only for the third series
    },
    xaxis: {
      categories: Array.from({length: 31}, (_, i) => i + 1), // Days of the month
    },
    yaxis: {
      opposite: true, // Moves the y-axis to the right side
      //   title: {
      //     text: "Price ($)",
      //   },
      labels: {
        formatter: function (value) {
          return `$${value}`;
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  const [isFilterOn, setIsFilterOn] = useState(false);

  const toggleSwitch = () => {
    setIsFilterOn(!isFilterOn);
  };

  return (
    <div
      id="chart"
      className="chart_main_wrapper"
    >
      <div className="chart_config">
        <div className="top_box">
          <h2>Cash flow history</h2>
          <div className="chart_action_btns">
            <div className="cloud_download_btn">
              <button>
                <img
                  src={cloudBtnIcon}
                  alt="cloud_btn"
                />
              </button>
            </div>
            <div className="action_btn">
              <button>
                December
                <img
                  src={actionBtnIcon}
                  alt="action_icon"
                />
              </button>
              <button>
                2019
                <img
                  src={actionBtnIcon}
                  alt="filter_icon"
                />
              </button>
            </div>
            <div className="filter_switch">
              <div
                onClick={() => setIsFilterOn(false)}
                className={`year_switch internal_switch ${!isFilterOn ? "active_filter_switch" : ""}`}
              >
                Y
              </div>
              <div
                onClick={() => setIsFilterOn(true)}
                className={`month_switch internal_switch ${isFilterOn ? "active_filter_switch" : ""}`}
              >
                M
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_box">
          <div className="legends">
            <img
              src={commitmentLegend}
              alt="Commitment"
            />
            Commitment
          </div>
          <div className="legends">
            <img
              src={contributionLegend}
              alt="Contributions"
            />
            Contributions
          </div>
          <div className="legends">
            <img
              src={distributionLegend}
              alt="Distributions"
            />
            Distributions
          </div>
        </div>
      </div>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
        style={{padding: "10px"}}
      />
    </div>
  );
};

export default CashFlowHistoryChart;
