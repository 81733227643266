import React from "react";
import "./loanreq.scss";
import meeting from "../../assets/loanrequest/svg/meeting.svg";

const LoanRequstComp = ({setLoanReq}) => {
  return (
    <div className="LoanRequstComp-center asset-center-con">
      <div className="deals-room-center-header">
        <p>Loan Request</p>
      </div>
      <div className="loan-req-main-conatiner">
        <div className="loan-req-left">
          <div className="loan-req-left-title">
            <span>Don’t have enough cash? No worries!</span>
          </div>
          <div className="loan-req-left-para">
            <span>You can request a loan from wealthy lenders.</span>
          </div>
          <div
            className="loan-req-btn"
            onClick={() => {
              setLoanReq(2);
            }}
          >
            <span>Setup a Loan Request</span>
          </div>
        </div>
        <div className="loan-req-right">
          <img
            src={meeting}
            alt=""
          />
        </div>
      </div>
      <div className="loan-req-scalton-loanreq">
        <div className="scalton-div"></div>
        <div className="scalton-div"></div>
        <div className="scalton-div"></div>
      </div>
    </div>
  );
};

export default LoanRequstComp;
