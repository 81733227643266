import React from 'react';
import './ShimmerEffect.scss';

const ShimmerEffect = () => {
  return (
    <div className="shimmer-effect">
      <div className="shimmer-line"></div>
      <div className="shimmer-line"></div>
      <div className="shimmer-line"></div>
      <div className="shimmer-line"></div>
    </div>
  );
};

export default ShimmerEffect;
