import React, {useState} from "react";
import "./CrowdFundingHeader.scss";
import cross from "../../assets/svg/cross-circle.svg";
import {Link, useNavigate} from "react-router-dom";
import {addToInvestNow} from "../../utils/Axios";
import {useSelector} from "react-redux";

const CrowdfundingHeader = ({title, user_id, asset_id}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const assetId = useSelector((state) => state.crowdfundingSlice.selectedCrowdfunding.assetDetails._id);
  const idToken = useSelector((state) => state.auth.idToken);
  const navigate = useNavigate();
  let {userInformation} = useSelector((state) => state.auth);

  const handleInvestNow = async () => {
    if (!assetId) {
      setError("No asset ID available.");
      return;
    }
    setLoading(true);
    setError(null);
    setSuccessMessage(null);
    try {
      const investmentData = {asset_id: assetId};
      const result = await addToInvestNow(investmentData, idToken, assetId);
      setSuccessMessage(result.message);
      setError(null);
      setLoading(false);
      navigate("/deals");
    } catch (err) {
      setError("Failed to add investment");
      setSuccessMessage(null);
      setLoading(false);
    }
  };

  return (
    <div className="crowdfunding_header deals-room-center-header">
      <div className="acq-head-left">
        <div>
          <h2>{title}</h2>
        </div>
      </div>
      {/* {userInformation?.roleTypes === 2 && ( */}
        <div className="crowdfunding_header_right acq-head-right">
          <Link to={"/asset-discovery?cf=true"}>
            <button className="btn_left">
              <img
                src={cross}
                alt="Decline Offer"
              />
              Decline Offer
            </button>
          </Link>
          <button
            className="btn_right"
            onClick={handleInvestNow}
            disabled={loading}
          >
            <span className={`spinner-CrowdFundingHeader ${loading ? "" : "hidden"}`}></span>
            {loading ? "Investing..." : "Invest Now"}
          </button>
        </div>
      {/* )} */}
    </div>
  );
};

export default CrowdfundingHeader;
