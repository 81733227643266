import React, { useState, useEffect } from 'react';

const VPNDetector = () => {
    const [isUsingVPN, setIsUsingVPN] = useState(false);
    const [userLocation, setUserLocation] = useState('');

    useEffect(() => {
        const checkVPN = async () => {
            try {
                // Fetch user's IP address
                const response = await fetch('https://api.ipify.org?format=json');
                const { ip } = await response.json();

                // Check if the IP address belongs to a known VPN range
                const isVPN = await isIPInVPNRange(ip);

                // Additional check: Geolocation analysis
                const location = await getUserLocation(ip);

                // If the IP address is in a known VPN range or if the geolocation is suspicious, set isUsingVPN to true
                if (isVPN || isSuspiciousLocation(location)) {
                    setIsUsingVPN(true);
                }

                setUserLocation(location);
            } catch (error) {
               
            }
        };

        checkVPN();
    }, []);

    // Function to check if the IP address belongs to a known VPN range
    const isIPInVPNRange = async (ip) => {
        // Implement your logic to check if the IP is in a known VPN range
        // You can maintain a list of known VPN IP address ranges and check against it
        return false; // Placeholder
    };

    // Function to get user's location based on IP address
    const getUserLocation = async (ip) => {
        try {
            // Fetch geolocation data
            const response = await fetch(`https://ipapi.co/${ip}/json/`);
            const data = await response.json();

            // Extract relevant location information
            const location = `${data.city}, ${data.region}, ${data.country_name}`;
            return location;
        } catch (error) {
            return 'Unknown';
        }
    };

    // Function to check if the geolocation is suspicious
    const isSuspiciousLocation = (location) => {
        // Implement your logic to check if the location is suspicious
        // For example, if it frequently changes or doesn't match expected locations
        return false; // Placeholder
    };

    return (
        <div>
            {/* {isUsingVPN ? (
                <p>VPN Detected! Please disable your VPN to access this service.</p>
            ) : (
                <p>Welcome to our service from {userLocation || 'your location'}!</p>
            )} */}
        </div>
    );
};

export default VPNDetector;
