import React from "react";
import {PrimaryButton, PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import "./CaptableStake.scss";
import Calender from "../../../assets/Manage-syndicate/Svg/Calender.svg";
import SearchInput from "../../../components/reusableComponents/Search/Search";
import filter from "../../../assets/Manage-syndicate/Svg/filter.svg";

const CaptableStake = () => {
  const data = [
    {
      STAKEHOLDER: "Belien S.",
      OUTSTANDING: "3,755,000",
      OUTSTANDINGOWNERSHIP: "40.287%",
      FULLYDILUTED: "200,000",
      FULYDILUTEDOWNERSHIP: "40.287%",
      Totalcashraised: "US$0.00",
    },
    {
      STAKEHOLDER: "Belien S.",
      OUTSTANDING: "3,755,000",
      OUTSTANDINGOWNERSHIP: "40.287%",
      FULLYDILUTED: "200,000",
      FULYDILUTEDOWNERSHIP: "40.287%",
      Totalcashraised: "US$0.00",
    },
    {
      STAKEHOLDER: "Belien S.",
      OUTSTANDING: "3,755,000",
      OUTSTANDINGOWNERSHIP: "40.287%",
      FULLYDILUTED: "200,000",
      FULYDILUTEDOWNERSHIP: "40.287%",
      Totalcashraised: "US$0.00",
    },
    {
      STAKEHOLDER: "Belien S.",
      OUTSTANDING: "3,755,000",
      OUTSTANDINGOWNERSHIP: "40.287%",
      FULLYDILUTED: "200,000",
      FULYDILUTEDOWNERSHIP: "40.287%",
      Totalcashraised: "US$0.00",
    },
    {
      STAKEHOLDER: "Belien S.",
      OUTSTANDING: "3,755,000",
      OUTSTANDINGOWNERSHIP: "40.287%",
      FULLYDILUTED: "200,000",
      FULYDILUTEDOWNERSHIP: "40.287%",
      Totalcashraised: "US$0.00",
    },
    {
      STAKEHOLDER: "Belien S.",
      OUTSTANDING: "3,755,000",
      OUTSTANDINGOWNERSHIP: "40.287%",
      FULLYDILUTED: "200,000",
      FULYDILUTEDOWNERSHIP: "40.287%",
      Totalcashraised: "US$0.00",
    },
  ];
  return (
    <div className="CaptableStake">
      <div className="title">
        <h1>Cap table by stakeholder</h1>
      </div>
      <div className="content">
        <div className="header">
          <div className="header-data">
            <p>View Cap table as of</p>
            <div className="calender-data">
              <img
                src={Calender}
                alt="Calendar"
              />
              <p>02/7/2024</p>
            </div>
          </div>
          <div>
            <PrimaryButton
              name="Export"
              classNames="Report-see-plan"
              svgPath="/path/to/svg/image.svg"
              padding="10px"
              svgHeight="20px"
              svgWidth="20px"
            />
          </div>
        </div>
        <div className="summary-data">
          <div className="CaptableStake-data-container">
            <div className="CaptableStake-data">
              <p>FULLY DILUTED SHARES</p>
              <div className="CaptableStake-data-total">
                <h1>9,320,725</h1>
              </div>
            </div>
            <div className="CaptableStake-data">
              <p>AMOUNT RAISED</p>
              <div className="CaptableStake-data-total">
                <h1>US$1,975,778.93</h1>
              </div>
            </div>
            <div className="CaptableStake-data">
              <p>AVAILABLE SHARES</p>
              <div className="CaptableStake-data-total">
                <h1>0</h1>
              </div>
            </div>
          </div>
        </div>
        {/* Second */}
        <div className="search-filter">
          <div className="search-filter-flex">
            <SearchInput />
            <PrimaryButtonSvgFirst
              name="Filter"
              classNames="CaptableStake-Filterdata"
              svgPath={filter}
              padding="10px"
              svgHeight="20px"
              svgWidth="20px"
            />
          </div>
          <div className="summary-details">
            <p>See a summary shsre class view</p>
          </div>
        </div>
        {/* Table */}
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>STAKEHOLDER</th>
                <th>OUTSTANDING</th>
                <th>OUTSTANDING OWNERSHIP</th>
                <th>FULLY DILUTED</th>
                <th>FULLY DILUTED OWNERSHIP</th>
                <th>TOTAL CASH RAISED</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.STAKEHOLDER}</td>
                  <td>{item.OUTSTANDING}</td>
                  <td>{item.OUTSTANDINGOWNERSHIP}</td>
                  <td>{item.FULLYDILUTED}</td>
                  <td>{item.FULYDILUTEDOWNERSHIP}</td>
                  <td>{item.Totalcashraised}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CaptableStake;
