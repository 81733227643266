
import React, {useEffect, useState} from "react";
import {InputBox} from "../../../components/reusableComponents/FormFields/FormFields";
import upload from "../../../assets/svg/upload.svg";
import person from "../../../assets/svg/profileAvatar.png";
import building from "../../../assets/Image/assetDiscovery/general-bg.png";

export const AddProject = ({showAddProject, setShowAddProject}) => {
    return (
      <div className="add_project">
        <div className="add_project_header">
          <h2>New Project</h2>
        </div>
  
        <div className="inputs">
          <InputBox
            type={"text"}
            label={"Name"}
          />
          <div className="desc">
            <InputBox
              type={"text"}
              label={"description"}
            />
          </div>
          <span>Optional</span>
  
          <p className="title">Attachments</p>
          <div className="attachment">
            <p>
              <img
                src={upload}
                alt=""
              />
              Drag and drop your file <br></br>
              or click to upload
            </p>
            <br></br>
          </div>
          <img
            style={{height: "70px", width: "90px", borderRadius: "4px", marginTop: "10px"}}
            src={building}
            alt=""
          />
  
          <p className="title">Invited People</p>
          <div style={{display: "flex", alignItems: "center", gap: "4px", marginTop: "5px", paddingBottom: "20px", borderBottom: "1px solid #eaedf3"}}>
            <img
              style={{height: "38px", width: "38px"}}
              src={person}
              alt=""
            />
            <img
              style={{height: "38px", width: "38px"}}
              src={person}
              alt=""
            />
            <img
              style={{height: "38px", width: "38px"}}
              src={person}
              alt=""
            />
          </div>
  
          <div className="btns">
            <button
              className="btn_cancel"
              onClick={() => setShowAddProject(false)}
            >
              Cancel
            </button>
            <button className="btn_create">Create</button>
          </div>
        </div>
      </div>
    );
  };