import React, { useState } from 'react'
import './More.scss'
import photo from "../../assets/acqusition/svg/photo.svg";
import location from "../../assets/acqusition/svg/location.svg";
import street from "../../assets/acqusition/svg/street.svg";
import bookmark from "../../assets/acqusition/svg/bookmark.svg";
import share from "../../assets/acqusition/svg/share.svg";
import compare from "../../assets/acqusition/svg/compare.svg";
import Propcard1 from "../../assets/Image/assetDiscovery/propcard1.png";
import Propcard1Bottom from "../../assets/Image/assetDiscovery/propcard1bottom.png";
import ProgressBar from "../../components/reusableComponents/ProgressBar/ProgressBar";
import {Link, NavLink} from "react-router-dom";
import labelFlag from "../../assets/svg/label_flag.png";
import ProfileAvatar from "../../assets/svg/ADProfileIcon.png";
import '../assetDiscovery/AssetDiscovery.scss'
import SyndicateComp from './SyndicateComp';
import Syndicate from './more/Syndicate';
import Funds from './more/Funds';
import Posts from './more/Posts';
import Connections from './more/Connections';
import Reviews from './more/Reviews';

function More({navDropdownActive}) {
  return (
    <div className='more'>

        {navDropdownActive === 1 && <Syndicate />}
        {navDropdownActive === 2 && <Funds />}
        {navDropdownActive === 3 && <Posts />}
        {navDropdownActive === 4 && <Connections />}
        {navDropdownActive === 5 && <Reviews />}


    </div>
  )
}

export default More
