import React from 'react';
import './contactdetails.scss';
import Person from '../../../assets/Image/profile-pic.png';
import cross from '../../../assets/svg/cross.svg';
import chat from '../../../assets/svg/chat.svg'
import DropDown from '../../../components/reusableComponents/Dropdown/Dropdown';
import phone from "../../../assets/dealsroom/svg/phone.svg";
import location from "../../../assets/dealsroom/svg/location.svg";
import tag from '../../../assets/svg/tag.svg'
import building from '../../../assets/Image/assetDiscovery/general-bg.png'
import Delete from '../../../assets/svg/delete.svg'
import profile from '../../../assets/svg/profileAvatar.png'
import see from '../../../assets/svg/see-arrow.svg'

function ContactDetails({handlePersonClick , className}) {
    return (
        <div className={`contact_details ${className}`}>
            <div className='contact_details_header'>
                <div className='left'>
                    <img className='person' src={Person} alt='' />
                    <div>
                        <p>Angelina Newton</p>
                        <span>Leasing Expert</span>
                        <button className='chat_btn'>
                            <img src={chat} alt='' />
                            Start Chat</button>
                    </div>
                </div>

                <img style={{height:'24px' , width:'24px'}} onClick={handlePersonClick} src={cross} alt='' />
            </div>

            <div className='contact_details_middle'>
                <DropDown
                    label={'Department'}
                    selectedData={'Leasement'}
                    width={'150px'}
                />

                <div className='middle_row'>
                    <p className='title'>
                        Basic Details
                    </p>
                    <div className='contact_details_nav_wrapper'>
                        <div className="contact_details_nav">
                            <img
                                src={chat}
                                alt=""
                            />
                            <span>me@angenewton.co</span>
                        </div>
                        <div className="contact_details_nav">
                            <img
                                src={phone}
                                alt=""
                            />
                            <span>+1 (200) 7887 665 </span>
                        </div>
                        <div className="contact_details_nav">
                            <img
                                src={location}
                                alt=""
                            />
                            <span>United States</span>
                        </div>
                    </div>
                </div>

                <div className='contact_details_bio'>
                    <p className='title'>Bio</p>
                    <p className='info'>
                        Working in automotive quality management for over 3 years. A passionate traveller, loves to spend time with her dog. Wine taster. Biggest dream is to travel the world with all the money I make from renting building to my dearest customers who pay well.
                    </p>
                </div>

                <div className='contact_details_tag'>
                    <p className='title'>Tags</p>
                    <div className='tag_input_wrapper'>
                        <img src={tag} alt='' />
                        <input type='text' placeholder='Enter Tag' />
                    </div>
                    <div className='tags_wrapper'>
                        <span className='tag green'>office Management</span>
                        <span className='tag orange'>commercial</span>
                        <span className='tag blue'>Development</span>

                    </div>
                </div>

                <div className='contact_details_assets'>
                    <div className='header'>
                        <p className='title'>Associated with Assets (45)</p>
                        <span>See All </span>
                    </div>
                    <div className='assets_wrapper'>
                        <div>
                            <img src={building} alt='' />
                            <p className='name'>Amazon<br></br> House</p>
                            <span className='desc'>Md 132</span>
                        </div>

                        <div>
                            <img src={building} alt='' />
                            <p className='name'>Amazon<br></br> House</p>
                            <span className='desc'>Md 132</span>
                        </div>


                        <div>
                            <img src={building} alt='' />
                            <p className='name'>Amazon<br></br> House</p>
                            <span className='desc'>Md 132</span>
                        </div>


                        <div>
                            <img src={building} alt='' />
                            <p className='name'>Amazon<br></br> House</p>
                            <span className='desc'>Md 132</span>
                        </div>

                        <div>
                            <img src={building} alt='' />
                            <p className='name'>Amazon<br></br> House</p>
                            <span className='desc'>Md 132</span>
                        </div>

                        <div>
                            <img src={building} alt='' />
                            <p className='name'>Amazon<br></br> House</p>
                            <span className='desc'>Md 132</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='contact_details_activity'>
                <p className='title'>Activity</p>

                 <ActivityHistoryBox />
                 <ActivityHistoryBox comment={true} />
                 <ActivityHistoryBox />
                 <ActivityHistoryBox />
                 <ActivityHistoryBox />

            </div>

        </div>
    );
}

export default ContactDetails;



const ActivityHistoryBox = ({comment}) => {
    return (
        <div className='activity_history_box'>
            <div className='box_left'>
            <img src={profile} alt='' />
            <div>
                <p>Minnie Porter started progress on article Postcard from Paris – CSS3 Keyframes Animations Tutorial</p>
                {comment && 
                <>
                <div className='box_comment'>
                    I can’t figure out the how to do the mask on the circle and the speaker holes for the life of me. I select them all and then select the circle layer and choose layer mask. I’ve tried all the…
                </div>

                <span className='comment'>
                Go to comment <img src={see} alt='' />
                </span>
                </>
                }
                 <span>2 Hours ago</span>
            </div>
            </div>
            <img src={Delete} alt='' />
        </div>
    )
}