import React, { useState } from "react";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";

const TenthSectionSQ = ({ setPreferredProperties, selectedOption, setSelectedOption, error }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setPreferredProperties([option]);
    setDropdownOpen(false);
  };

  let dropdowndata = ["Resort", "Hotel", "Villa", "Condo", "Lodge", "Apartment", "Chalet"];

  return (
    <div className="FirstSectionSQ sectionSignupQ">
      <div className="sectionSignupQ-T">
        <h1>Preferred Properties</h1>
        <h2>Please Select A Property Type</h2>
      </div>
      <div className="sectionSignupQ-B">
        <div className="additional_info">
          <DropDown
            label="Property Type"
            paddingLeft="10px"
            data={dropdowndata}
            selectedData={selectedOption}
            setSelectedData={handleOptionSelect}
            dropdownState={dropdownOpen}
            dropdownSetter={setDropdownOpen}
            classDataName="SignupQuestionsNewDropdown"
          />
        </div>
      </div>
      {error && <p className="validation-error" style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default TenthSectionSQ;
