import React, {useState} from "react";
import "./Equityawards.scss";
import {PrimaryButtonSvg, PrimaryButtonSvgFirst, PrimaryButton} from "../../../components/reusableComponents/Buttons/Buttons";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";
import SearchInput from "../../../components/reusableComponents/Search/Search";
import filter from "../../../assets/Manage-syndicate/Svg/filter.svg";
import servererror from "../../../assets/Manage-syndicate/Svg/servererror.svg";
import Select from "../../../components/reusableComponents/Select/Select";

const Equityawards = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const options = [
    {value: "", label: "Action"},
    {value: "2nd", label: "2nd"},
    {value: "3rd", label: "3rd"},
  ];
  const options1 = [
    {value: "", label: "Select column"},
    {value: "2nd", label: "2nd "},
    {value: "3rd", label: "3rd"},
  ];
  const [activeOption, setActiveOption] = useState(null);

  const handleOptionClick = (option) => {
    setActiveOption(option);
  };
  return (
    <div>
      <div>
        <div style={{marginTop: "10px", marginBottom: "10px"}}>
          <h1>Equity Awards</h1>
        </div>
      </div>
      <div>
        <div className="Equality-Container">
          <div className="Equality-Container-data">
            <SearchInput />
            <div className="select-wrapper">
              <Select
                options={options}
                onSelectChange={handleSelectChange}
              />
            </div>
            <PrimaryButtonSvgFirst
              name="Filter"
              classNames="Equityawards-Filter"
              svgPath={filter}
              padding="10px"
              svgHeight="20px"
              svgWidth="20px"
            />
            <div className="select-wrapper">
              <Select
                options={options1}
                onSelectChange={handleSelectChange}
              />
            </div>
            <PrimaryButton
              name="View ISO 100k"
              classNames="Report-see-View"
              svgPath="/path/to/svg/image.svg"
              padding="10px"
              svgHeight="20px"
              svgWidth="20px"
            />
            <p>Request repricing</p>
            <PrimaryButton
              name="Draft Option"
              classNames="Report-see-plan-Option"
              svgPath="/path/to/svg/image.svg"
              padding="10px"
              svgHeight="20px"
              svgWidth="20px"
            />
          </div>
          <div className="Equality-Like-selector">
          <div className="Equality-Like-selector-data">
      <h5
        className={activeOption === 'OPTION' ? 'active' : ''}
        onClick={() => handleOptionClick('OPTION')}
      >
        OPTION
      </h5>
      <h5
        className={activeOption === 'RSAs' ? 'active' : ''}
        onClick={() => handleOptionClick('RSAs')}
      >
        RSAs
      </h5>
      <h5
        className={activeOption === 'RSUs' ? 'active' : ''}
        onClick={() => handleOptionClick('RSUs')}
      >
        RSUs
      </h5>
      <h5
        className={activeOption === 'SARs' ? 'active' : ''}
        onClick={() => handleOptionClick('SARs')}
      >
        SARs
      </h5>
    </div>
          </div>
        </div>
      </div>
        <div>
          <div className="Equityplans-data">
            <div className="Equityplans-data-container-maindiv">
              <div className="Equityplans-Error-Container">
                <div className="Equityplans-data-container-image">
                  <img
                    src={servererror}
                    alt="image"
                  />
                </div>
                <p>No equity plan have been created</p>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Equityawards;
