import React from "react";
import Chart from "react-apexcharts";
import "./Style.scss";

const PastInvestmentsChart = () => {
  const options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#1E90FF", "#A9A9A9"], // Blue and Grey
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3],
      curve: "straight", // Changed to straight lines
      dashArray: [0, 5],
    },
    title: {
      text: "Past Investments",
      align: "left",
      style: {
        fontSize: "18px",
        fontWeight: "bold",
        color: "#000",
      },
    },
    subtitle: {
      text: "14% Yearly Increase",
      align: "right",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#32CD32", // Green
      },
    },
    markers: {
      size: 0, // Removed the dots on the lines
      hover: {
        sizeOffset: 2,
      },
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      labels: {
        style: {
          colors: ["#373d3f"],
          fontSize: "14px", // Increased font size
        },
        rotate: -45, // Rotate labels for better visibility
      },
      tickAmount: 6, // Ensure tick amount matches the number of categories
    },
    yaxis: {
      opposite: true,
      labels: {
        formatter: function (val) {
          return `$${val}M`;
        },
        style: {
          colors: ["#808080"],
          fontSize: "12px",
        },
      },
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
      offsetY: -10,
      labels: {
        colors: ["#808080"],
        useSeriesColors: false,
      },
      markers: {
        width: 10,
        height: 10,
        radius: 12,
      },
    },
    grid: {
      borderColor: "#f1f1f1",
      padding: {
        top: 10,
        right: 20,
        bottom: 0,
        left: 30, // Add left padding to prevent cutting off labels
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `$${val}M`;
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          xaxis: {
            labels: {
              rotate: 0,
            },
          },
        },
      },
    ],
  };

  const series = [
    {
      name: "CONTRIBUTION",
      data: [8, 10, 6, 7, 5, 6],
    },
    {
      name: "DISTRIBUTION",
      data: [7, 11, 7, 5, 6, 7],
    },
  ];

  return (
    <div
      id="chart"
      style={{width: "100%"}}
    >
      <Chart
        options={options}
        series={series}
        type="line"
        height={280}
        width="100%"
      />
    </div>
  );
};

export default PastInvestmentsChart;
