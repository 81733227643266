import React from "react";
import "./PaymentHistory.scss";
import filter_btn from "../../assets/svg/filter_btn.svg";
import export_btn from "../../assets/svg/export_btn.svg";
import pending_transition_notification from '../../assets/svg/transition_pending_alert.svg';
import completed_transition_notification from '../../assets/svg/completed_payment_notification.svg';

const PaymentHistory = () => {
  const payments = [
    {
      bank_name: "Bank of America",
      payment_type: "Loan repayment",
      month: "JUL",
      date: "29",
      isCompleted: false,
      payment_amount: "-12,450.00",
      payment_currency: "USD",
    },
    {
      bank_name: "Gumroad, Inc",
      payment_type: "Loan repayment",
      month: "JUL",
      date: "28",
      isCompleted: false,
      payment_amount: "-12,450.00",
      payment_currency: "USD",
    },
    {
      bank_name: "Spotify Limited",
      payment_type: "Loan repayment",
      month: "JUL",
      date: "29",
      isCompleted: true,
      payment_amount: "-12,450.00",
      payment_currency: "USD",
    },
    {
      bank_name: "Bank of America",
      payment_type: "Loan repayment",
      month: "JUL",
      date: "27",
      isCompleted: true,
      payment_amount: "-12,450.00",
      payment_currency: "USD",
    },
    {
      bank_name: "Donald Trump Lawyer (Secret)",
      payment_type: "Loan repayment",
      month: "JUL",
      date: "29",
      isCompleted: true,
      payment_amount: "-12,450.00",
      payment_currency: "USD",
    },
    {
      bank_name: "Jason Lemon",
      payment_type: "Loan repayment",
      month: "JUL",
      date: "29",
      isCompleted: true,
      payment_amount: "-12,450.00",
      payment_currency: "USD",
    },
    {
      bank_name: "Bank of America",
      payment_type: "Loan repayment",
      month: "JUL",
      date: "29",
      isCompleted: true,
      payment_amount: "-12,450.00",
      payment_currency: "USD",
    },
  ];
  const isPending = payments.filter(item=>item.isCompleted === false);
  
  return (
    <div className="payments_container">
      <div className="notification" style={{marginBottom:'20px'}}>
       {
        isPending.length === 0 ? <img src={completed_transition_notification} alt="" /> :  <img src={pending_transition_notification} alt="" />
       }
      </div>
      <div className="heading">
        <h1>Payments</h1>
        <div className="actions">
          <img
            src={filter_btn}
            alt=""
          />
          <img
            src={export_btn}
            alt=""
          />
        </div>
      </div>
      <div className="details">
        {payments?.map((data, index) => {
          return (
            <div
              className="payment_info"
              key={index}
            >
              <div className="date_and_bank">
                <div className="date">
                  <span>{data?.month}</span>
                  <p>{data?.date}</p>
                </div>
                <div className="bank">
                  <p>{data?.bank_name}</p>
                  <span>{data?.payment_type}</span>
                </div>
              </div>
              <div className="status_and_amount">
                <div className={data?.isCompleted ? "completed_status" : "pending_status"}>{data?.isCompleted ? "COMPLETED" : "PENDING"}</div>
                <div className="amount">
                  <p>{data?.payment_amount}</p>
                  <span>{data?.payment_currency}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PaymentHistory;
