import React, {useMemo} from "react";
import "./History.scss";
import {PrimaryButton} from "../../../components/reusableComponents/Buttons/Buttons";
import information from "../../../assets/Success Popup/infomation_svg_icon.svg";
import Chart from "react-apexcharts";
import AIchat_Icon from "../../../assets/Success Popup/AI_chart_Icon.svg";
import userAvatar from "../../../assets/Success Popup/userAvatar.svg";
import Send_button from "../../../assets/Success Popup/send_button.svg";

const History = () => {
  const donutChartOptions = useMemo(
    () => ({
      chart: {
        type: "donut",
      },
      labels: [],
      colors: ["#1976d2", "#d32f2f"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["#fff"],
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "85%",
            labels: {
              show: false,
            },
          },
        },
      },
    }),
    [],
  );

  const donutChartData = useMemo(() => [80, 20], []);

  const profitGrowthOptions = useMemo(
    () => ({
      chart: {
        type: "line",
        height: 100,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      grid: {
        show: false,
      },
      legend: {
        show: false,
      },
    }),
    [],
  );

  const profitGrowthSeries = useMemo(
    () => [
      {
        name: "Profit",
        data: [10, 15, 25, 20, 30, 32],
      },
      {
        name: "Profit/Expenses",
        data: [5, 8, 7, 10, 12, 9],
      },
    ],
    [],
  );

  return (
    <div className="History_main_container">
      <div className="Predicted_data_container">
        <h2>Predicted Data for River View Apartments</h2>
        <div className="Bugget_data_History">
          <PrimaryButton
            name="Download"
            classNames="BeartacticAI_History"
            padding="10px"
            svgHeight="20px"
            svgWidth="20px"
          />
          <PrimaryButton
            name="Upload-Excel"
            classNames="BeartacticAI_History"
            padding="10px"
            svgHeight="20px"
            svgWidth="20px"
          />
          <PrimaryButton
            name="Generate Budget"
            classNames="BeartacticAI_History"
            padding="10px"
            svgHeight="20px"
            svgWidth="20px"
          />
        </div>
      </div>

      <div className="predicted-data-container">
        <div className="left-section">
          <div className="data-card combined-card">
            <div className="section no-action">
              <div className="card_infomation_withimage">
                <h3>Needs No Action</h3>
                <img
                  src={information}
                  alt="image"
                />
              </div>
              <p className="paragraphtext_forBuggetting">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua . Ut enim ad minim veniam. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua . Ut enim ad minim veniam.
              </p>
            </div>
            <hr className="divider" />
            <div className="section needs-change">
              <div className="card_infomation_withimage">
                <h3>Needs Change</h3>
                <img
                  src={information}
                  alt="image"
                />
              </div>
              <p className="paragraphtext_forBuggetting">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua . Ut enim ad minim veniam. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua . Ut enim ad minim veniam.
              </p>
            </div>
            <hr className="divider" />
            <div className="section suggestions">
              <div className="card_infomation_withimage">
                <h3>Suggestions</h3>
                <img
                  src={information}
                  alt="image"
                />
              </div>
              <p className="paragraphtext_forBuggetting">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua . Ut enim ad minim veniam. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua . Ut enim ad minim veniam.
              </p>
            </div>
            <hr className="divider" />
            <div className="section prediction">
              <h3>Prediction</h3>
              <div className="invoice-info">
                <div className="invoice-chart">
                  <Chart
                    options={donutChartOptions}
                    series={donutChartData}
                    type="donut"
                    width="320"
                    height={250}
                  />
                </div>
                <div className="invoice-details-for-BeartacticAI">
                  <div>
                    <h4>$1,123,595</h4>
                    <p>Invoice for 2025</p>
                  </div>
                  <div className="total_amonuts_data">
                    <p>Paid: $10,095</p>
                    <p>Unpaid: $2,500</p>
                  </div>
                </div>
              </div>
              <div className="profit-growth-section">
                <h4>Net Profit Growth</h4>
                <div className="growth-container">
                  <div className="growth-chart">
                    <Chart
                      options={profitGrowthOptions}
                      series={profitGrowthSeries}
                      type="line"
                      height={100}
                    />
                    <Chart
                      options={profitGrowthOptions}
                      series={profitGrowthSeries}
                      type="line"
                      height={100}
                    />
                  </div>
                  <div className="growth-data">
                    <div className="data-box">
                      <h2 className="growth-percentage">32%</h2>
                      <p className="growth-text">6 Months Increase</p>
                    </div>
                    <div className="data-box">
                      <h2 className="growth-percentage">9%</h2>
                      <p className="growth-text">Yearly Increase</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right-section">
          <div className="ai-chat-container">
            <div className="ai-chat-header">
              <div className="ai-chat-header_for_BeartacticAI">
                <img
                  src={AIchat_Icon}
                  alt="AI icon"
                  className="ai-chat-icon"
                />
                <h3>Beartactic AI</h3>
              </div>
              <button className="maximize-btn">⤢</button>

            </div>

            <div className="chat-messages">
              <div className="message ai-message">
                <img
                  src={AIchat_Icon}
                  alt="AI icon"
                  className="ai-chat-icon"
                />
                <div className="chat-text-container">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>
                  <span className="chat-time">12:01</span>
                </div>
              </div>

              <div className="message user-message">
                <div className="chat-text-container">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>
                  <span className="chat-time">12:02</span>
                </div>
                <img
                  src={userAvatar}
                  alt="User avatar"
                  className="user-avatar"
                />
              </div>

              <div className="message ai-message">
                <img
                  src={AIchat_Icon}
                  alt="AI icon"
                  className="ai-chat-icon"
                />
                <div className="chat-text-container">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>
                  <span className="chat-time">12:03</span>
                </div>
              </div>
            </div>

            <div className="chat-input">
              <input
                type="text"
                placeholder="Type your message here..."
              />
              <img
                src={Send_button}
                alt="button"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
