import React, {useState} from "react";
import {Range, getTrackBackground} from "react-range";
import "./Financial.scss";

const RangeSelector = ({min, max, step, initialValues, color}) => {
  const [values, setValues] = useState(initialValues);

  return (
    <div className="range-selector">
      <label>COMMON EQUITY RETURN</label>
      <div className="range-selector__inputs">
        <input
          type="text"
          readOnly
          value={`From ${values[0]}%`}
        />
        <input
          type="text"
          readOnly
          value={`To ${values[1]}%`}
        />
      </div>
      <Range
        values={values}
        step={step}
        min={min}
        max={max}
        onChange={(values) => setValues(values)}
        renderTrack={({props, children}) => (
          <div
            {...props}
            className="range-selector__track"
            style={{
              background: getTrackBackground({
                values,
                colors: [color, "#ccc", color],
                min,
                max,
              }),
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({props}) => (
          <div
            {...props}
            className="range-selector__thumb"
            style={{backgroundColor: color}}
          />
        )}
      />
    </div>
  );
};

export default RangeSelector;
