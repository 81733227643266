import React, { useState } from 'react'
import './GpProfile.scss'
import building from '../../assets/investment/building.png'
import TickIcon from '../../assets/investment/tick-square.svg'
import chair from '../../assets/investment/chair.png'
import moreImgs from '../../assets/investment/more-imgs.svg'
import table from '../../assets/investment/table-chairs.png'
// import profileIcon from "../../assets/Image/assetDiscovery/profile.png"
import prevnav from "../../assets/dealsroom/svg/prevnav.svg";
import fwdnav from "../../assets/dealsroom/svg/fwdnav.svg";
import Star from "../../assets/Image/assetDiscovery/star.svg";
import profileIcon from '../../assets/investment/profile.svg'
import mobileIcon from '../../assets/investment/phone.svg'
import emailIcon from '../../assets/investment/email.svg'
import linkIcon from '../../assets/investment/link.svg'
import fileIcon from '../../assets/investment/file.svg'
import openIcon from '../../assets/investment/open.svg'
import tableIcon from "../../assets/Image/assetDiscovery/profile.png"

function GpProfile({setIsClicked}) {
    const [navActive, setNavActive] = useState(1);


    return (
        <div className=' gp_profile_wrapper'>
            <div className='gp_profile_left'>
                <div className='gp_profile_top'>
                    <img src={building} alt='' />
                    <div className='img_text'>
                        <p>North Group Trust</p>
                        <span>45 Groups</span>
                    </div>

                    <div className='gp_profile_top_nav'>
                        <div className='top_nav_left'>
                        <button>
                            <img src={TickIcon} alt='' />
                            Connected</button>
                            <span>
                            1 year ago (Since July 03, 2019) <br></br>
                            15 mutual deals
                            </span>
                            </div>
                        <div className=" deals-room-nav gp_nav asset-nav ass-nav">
                            <span
                                className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
                                onClick={() => {
                                    setNavActive(1);
                                }}
                            >
                                About       </span>
                            <span
                                className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
                                onClick={() => {
                                    setNavActive(2);
                                }}
                            >
                                Investments
                            </span>

                        </div>
                    </div>
                </div>

                <div className='gp_profile_center'>
                    {navActive === 1 && <About setNavActive={setNavActive} />}
                    {navActive === 2 && <Investment />}

                </div>
            </div>

            <div className='gp_profile_right'>
                <h2>Contacts</h2>
                <div className='contact_info'>
                    <span>
                       <img src={profileIcon} alt='' />
                        Randall Renegade
                    </span>
                    <span>
                       <img src={mobileIcon} alt='' />
                       +1 7887 665 221
                    </span>

                    <span>
                       <img src={emailIcon} alt='' />
                       me@angenewton.co
                    </span>
                    <span>
                       <img src={linkIcon} alt='' />
                       www.heritageattampa.com
                    </span>
                        <span>
                       <img src={fileIcon} alt='' />
                       Marketing Brochure
                       <img src={openIcon} alt='' />

                    </span>

                </div>
            </div>

        </div>
    )
}

export default GpProfile

const About = ({setNavActive}) => {
    return (
        <div className='about'>
            <div className='about_left'>
                <h4>General Partner</h4>

                <div className='about_text_wrapper'>
                    <h1>North Group Trust</h1>
                    <span>164 S. Carson Court Newport News, VA 23601</span>
                    <p>North Group Trust is a vertically integrated commercial real estate development and capital investment company with a strong track record of successful, high-quality medical and strategic acquisitions. New Era works with healthcare systems, large medical groups and regional healthcare companies to provide the solutions for on and off campus medical office development, post-acute hospitals, behavioral health hospitals, cancer centers, surgical and imaging centers, hospitality and full campus development.</p>
                </div>

                <div className='about_data'>
                    <div className='data_item'>
                        <p>Total Portfolio Activity</p>
                        <p>$376,722,967</p>
                    </div>
                    <div className='data_item'>
                        <p>Total Portfolio Activity</p>
                        <p>$376,722,967</p>
                    </div>
                    <div className='data_item'>
                        <p>Total Portfolio Activity</p>
                        <p>$376,722,967</p>
                    </div>
                </div>

                <div className='investment_data'>
                    <h1>Investment Interests <span>based on portfolio</span></h1>
                    <div className='investment_data_box'>

                        <div className='row1'>

                            <div className='data_box_wrapper'>
                                <h3>Property Type</h3>
                                <div className='data_box'>
                                    <div className='data_box_item'>
                                        <p>Built to Rent </p>
                                        <p>24</p>
                                    </div>
                                    <div className='data_box_item'>
                                        <p>Industrial</p>
                                        <p>45</p>
                                    </div>
                                    <div className='data_box_item'>
                                        <p>Retail</p>
                                        <p>2</p>
                                    </div>
                                </div>
                            </div>
                            <div className='data_box_wrapper'>
                                <h3>Hold On Period</h3>
                                <div className='data_box'>
                                    <div className='data_box_item'>
                                        <p>Less than 1 year </p>
                                        <p>24</p>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className='row2'>

                            <div className='data_box_wrapper'>
                                <h3>Market Size</h3>
                                <div className='data_box'>
                                    <div className='data_box_item'>
                                        <p>Built to Rent </p>
                                        <p>24</p>
                                    </div>

                                </div>
                            </div>
                            <div className='data_box_wrapper'>
                                <h3>Regions</h3>
                                <div className='data_box'>
                                    <div className='data_box_item'>
                                        <p>Midwest </p>
                                        <p>24</p>
                                    </div>
                                    <div className='data_box_item'>
                                        <p>Southwest </p>
                                        <p>24</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='about_right'>
                <div className='about_right_header'>
                    <h4>Investments</h4>
                    <p style={{cursor:'pointer'}} onClick={() => setNavActive(2)}>See More</p>

                </div>

                <div className='about_right_img_box'>
                    <div className='main_img'>
                        <img src={chair} alt='' />
                    </div>
                    <div className='img_wrapper img_wrapper_1'>
                        <img src={table} alt='' />
                        <img src={table} alt='' />

                    </div>
                    <div className='img_wrapper img_wrapper_2'>
                        <img src={table} alt='' />
                        <img src={table} alt='' />

                    </div>
                    <div className='img_wrapper'>
                        <img src={table} alt='' />
                        <img src={moreImgs} alt='' />

                    </div>
                </div>
            </div>
        </div>
    )
}




function Investment() {

    const [navActive, setNavActive] = useState(1);

    return (
        <div className='gp_investment general_investments'>
            <div className='upper'>
                <span className='upper_heading'>
                    <h2>Investments</h2>
                </span>
                <div>
                    <div className='general_investment_nav'>
                        <div className=" deals-room-nav gp_nav asset-nav ass-nav">
                            <span
                                className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
                                onClick={() => {
                                    setNavActive(1);
                                }}
                            >
                                Multifamily            </span>
                            <span
                                className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
                                onClick={() => {
                                    setNavActive(2);
                                }}
                            >
                                Commercial
                            </span>
                            <span
                                className={navActive === 3 && "deals-room-nav-active ut-nav-active"}
                                onClick={() => {
                                    setNavActive(3);
                                }}
                            >
                                All
                            </span>



                        </div> </div>


                </div>
            </div>

            <div className='lower'>
                <Table />
            </div>
        </div>
    )
}



const Table = () => {
    return (
        <div>
            <div className="documents_box-B table">
                <div className="uploaded_docs">
                    <div className="header">
                        <div className="left">
                            <p>Name</p>
                            <p>date added</p>
                            <p>Performance</p>
                            <p>Potential Growth</p>
                        </div>

                    </div>
                    <Row2 />
                    <Row2 />
                    <Row2 />
                    <Row2 />

                </div>
            </div>


        </div>
    )
}


const Row2 = () => {
    return (
        <div className="table_row table_row2">
            <div className="left">
                <div className="item first">
                    <img
                        src={tableIcon}
                        alt=""
                    />

                    <div className="text_wrapper">
                        <p>Aurora</p>
                    </div>
                </div>

                <div className="item">
                    <div className="text_wrapper">
                        <p>08 Nov 2018</p>
                    </div>
                </div>
                <div className="item">
                    <div className="text_wrapper">
                        <p>
                            <span>
                                {" "}
                                <img
                                    src={Star}
                                    alt=""
                                />
                                <img
                                    src={Star}
                                    alt=""
                                />
                                <img
                                    src={Star}
                                    alt=""
                                />
                                <img
                                    src={Star}
                                    alt=""
                                />
                                <img
                                    src={Star}
                                    alt=""
                                />
                            </span>
                        </p>
                    </div>
                </div>
                <div className="item">
                    <div className="text_wrapper">
                        <p>$500,900.00</p>
                    </div>
                </div>
            </div>

        </div>
    );
};