import React, {useState, useEffect} from "react";
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Popover} from "@mui/material";
import {Input} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";
import "./AssignTaskDialog.scss";
import {useSelector} from "react-redux";
import Endpoint from "../../../utils/Endpoint";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuthConfig from "../../../utils/Config";
import {ReactComponent as LeftSingleArrow} from "../../../assets/chatCompAssets/icons/leftSingleArrow.svg";
import {ReactComponent as LeftDoubleArrow} from "../../../assets/chatCompAssets/icons/leftDoubleArrow.svg";
import {ReactComponent as RightSingleArrow} from "../../../assets/chatCompAssets/icons/rightSingleArrow.svg";
import {ReactComponent as RightDoubleArrow} from "../../../assets/chatCompAssets/icons/rightDoubleArrow.svg";
import {ReactComponent as CalendarIcon} from "../../../assets/chatCompAssets/icons/purpleCalendar.svg";
import {CircularProgress} from "@mui/material";
import {TaskAssignedToast, OpenAIErrorToast} from "../../../components/Tostify/SuccessToast/SuccessTostify";

const AssignTaskDialog = ({open, onClose, messageid, selectedText, text_id, fetchStatuses}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [Userlist, setUserlist] = useState([]);
  const [anchorElDropdown, setAnchorElDropdown] = useState(null);
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const {idToken, userInformation} = useSelector((state) => state.auth);
  const config = useAuthConfig();
  const [isLoading, setIsLoading] = useState(false);
  let {selectedProperty} = useSelector((state) => state.portfolio);
  const selectedUserIds = selectedUsers.map((user) => user.userId);

  useEffect(() => {
    if (!open || !idToken || !userInformation?.email) return;
  
    const fetchUserList = async () => {
      try {
        const response = await axios.get(
          Endpoint?.Userlist(userInformation.email), 
          config
        );
        setUserlist(response?.data?.data || []);
      } catch (error) {
        toast.error("Failed to fetch user list.");
      }
    };
  
    fetchUserList();
  }, [open, idToken, userInformation?.email]);
  

  const handleAssign = async () => {
    if (!idToken) {
      toast.error(
        <OpenAIErrorToast
          errorMessage="Failed to assign task. Authentication token missing."
          onClose={() => toast.dismiss()}
        />,
        {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
        },
      );
      return;
    }

    if (!userInformation?._id) {
      toast.error(
        <OpenAIErrorToast
          errorMessage="User information is missing. Please log in again."
          onClose={() => toast.dismiss()}
        />,
        {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
        },
      );
      return;
    }

    if (!selectedUsers.length) {
      toast.error(
        <OpenAIErrorToast
          errorMessage="Please select at least one user to assign the task."
          onClose={() => toast.dismiss()}
        />,
        {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
        },
      );
      return;
    }

    if (!selectedDate) {
      toast.error(
        <OpenAIErrorToast
          errorMessage="Please select a deadline for the task."
          onClose={() => toast.dismiss()}
        />,
        {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
        },
      );
      return;
    }

    const payload = {
      text_data: JSON.stringify({
        header: selectedText?.header,
        description: selectedText?.text,
      }),
      taskName: selectedText?.header,
      taskDesc: selectedText?.text,
      desc: selectedText.text,
      date: selectedDate?.format("YYYY-MM-DD"),
      employee_ids: selectedUserIds,
      status: "Assign to Team",
    };

    const queryParams = {
      Message_id: messageid,
      text_id: text_id,
      asset_id: selectedProperty?._id,
      user_id: userInformation?._id,
    };

    const queryString = new URLSearchParams(queryParams).toString();

    setIsLoading(true);

    try {
      const response = await axios.post(`${Endpoint.addassigntask}?${queryString}`, payload, config);

      if (response.status === 200 || response.status === 201) {
        toast.success(
          <TaskAssignedToast
            deadline={selectedDate}
            selectedUsers={selectedUsers}
            onViewTasks={() => (window.location.href = `/${userInformation._id}/tasks`)}
          />,
          {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
          },
        );

        await fetchStatuses();
        onClose();
      } else {
        throw new Error(response?.data?.message || "Unexpected error occurred.");
      }
    } catch (error) {
      toast.error(
        <OpenAIErrorToast
          errorMessage={error?.response?.data?.message || "Failed to assign task. Please try again."}
          onClose={() => toast.dismiss()}
        />,
        {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
        },
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setAnchorElCalendar(null);
  };

  const handleOpenCalendar = (event) => {
    setAnchorElCalendar(event.currentTarget);
  };

  const handleCloseCalendar = () => {
    setAnchorElCalendar(null);
  };

  const handleOpenDropdown = (event) => {
    setAnchorElDropdown(event?.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorElDropdown(null);
  };

  const handleUserSelect = (user) => {
    const isAlreadySelected = selectedUsers.some((selectedUser) => selectedUser.email === user.email);
    if (isAlreadySelected) {
      setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser.email !== user.email));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const handleUserDeselect = (email) => {
    setSelectedUsers(selectedUsers.filter((user) => user.email !== email));
  };

  const renderCalendar = () => {
    const startOfMonth = selectedDate ? selectedDate.startOf("month") : dayjs().startOf("month");
    const endOfMonth = selectedDate ? selectedDate.endOf("month") : dayjs().endOf("month");
    const daysInMonth = startOfMonth.daysInMonth();
    const startDay = startOfMonth.day() || 7; 
    const previousMonthDays = Array.from({length: startDay - 1}, (_, i) => startOfMonth.subtract(startDay - 1 - i, "day"));
    const currentMonthDays = Array.from({length: daysInMonth}, (_, i) => startOfMonth.add(i, "day"));
    const nextMonthDays = Array.from({length: 42 - previousMonthDays.length - currentMonthDays.length}, (_, i) => endOfMonth.add(i + 1, "day"));
    const allDays = [...previousMonthDays, ...currentMonthDays, ...nextMonthDays];

    return (
      <div className="custom-calendar">
        <div className="calendar-header">
          <div className="leftComp">
            <button onClick={() => setSelectedDate((prev) => (prev || dayjs()).subtract(1, "year"))}>
              <LeftSingleArrow />
            </button>
            <button onClick={() => setSelectedDate((prev) => (prev || dayjs()).subtract(1, "month"))}>
              <LeftDoubleArrow />
            </button>
          </div>
          <div className="midComp">
            <span>{(selectedDate || dayjs()).format("MMMM YYYY")}</span>
          </div>
          <div className="rightComp">
            <button onClick={() => setSelectedDate((prev) => (prev || dayjs()).add(1, "month"))}>
              <RightDoubleArrow />
            </button>
            <button onClick={() => setSelectedDate((prev) => (prev || dayjs()).add(1, "year"))}>
              <RightSingleArrow />
            </button>
          </div>
        </div>
        <div className="calendar-grid">
          <div className="weekRow">
            {["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"].map((day) => (
              <div
                key={day}
                className="calendar-day-header"
              >
                {day}
              </div>
            ))}
          </div>
          <div className="dateRow">
            {allDays.map((date, index) => {
              const isPastDate = date.isBefore(dayjs(), "day");
              const isSelected = selectedDate && date.isSame(selectedDate, "day");
              const isToday = !isSelected && date.isSame(dayjs(), "day"); 
              const isCurrentMonth = date.month() === (selectedDate || dayjs()).month();

              return (
                <div
                  key={index}
                  className={`calendar-day 
                    ${isPastDate ? "disabled" : ""} 
                    ${!isCurrentMonth ? "outside-month" : ""} 
                    ${isToday ? "today" : ""} 
                    ${isSelected ? "selected" : ""}`}
                  onClick={() => !isPastDate && setSelectedDate(date)}
                >
                  {date.date()}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      className="assignTaskContainer"
    >
      <h4>Assign Task</h4>
      <DialogContent className="assignTaskWrapper">
        <div className="DialogContent_input_calender_container">
          <label className="DialogContent_input_calender">Deadline</label>
          <div
            style={{display: "flex", alignItems: "center", position: "relative"}}
            className="calendarInputBox"
          >
            <Input
              value={selectedDate ? selectedDate?.format("DD/MM/YYYY") : ""}
              onClick={handleOpenCalendar}
              readOnly
              placeholder="DD/MM/YYYY"
            />
            <CalendarIcon
              style={{cursor: "pointer"}}
              onClick={handleOpenCalendar}
            />
          </div>
        </div>

        <Popover
          open={Boolean(anchorElCalendar)}
          anchorEl={anchorElCalendar}
          onClose={handleCloseCalendar}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {renderCalendar()}
        </Popover>

        <div className="DialogContent_input_selector_container">
          <label className="DialogContent_input_calender">Assign To</label>
          <div
            style={{
              border: "1px solid #d9d9d9",
              padding: "10px",
              borderRadius: "8px",
              cursor: "pointer",
              marginTop: "8px",
              width: "551px",
            }}
            onClick={handleOpenDropdown}
          >
            <div style={{display: "flex", flexWrap: "wrap", gap: "5px", fontSize: "14px"}}>
              {selectedUsers?.map((user) => (
                <span
                  key={user?.email}
                  style={{
                    backgroundColor: "#EFEFEF",
                    padding: "5px 10px",
                    borderRadius: "3px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  {user?.name} {user?.surname}
                  <CloseOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUserDeselect(user.email);
                    }}
                    style={{cursor: "pointer"}}
                  />
                </span>
              ))}
              {!selectedUsers.length && "Select"}
            </div>
          </div>
        </div>

        <Popover
          open={Boolean(anchorElDropdown)}
          anchorEl={anchorElDropdown}
          onClose={handleCloseDropdown}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            className: "popover-paper",
          }}
        >
          <div>
            {Userlist?.map((user) => (
              <div
                key={user?.userId}
                onClick={() => handleUserSelect(user)}
                className={`popover-user ${selectedUsers?.find((selectedUser) => selectedUser?.email === user?.email) ? "selected" : ""}`}
              >
                {user?.name}-{user?.surname}
              </div>
            ))}
          </div>
        </Popover>
      </DialogContent>
      <DialogActions className="assignTaskBtnBox">
        <div className="Assign_button_container">
          <Button
            onClick={handleAssign}
            variant="contained"
            className="Assign_button"
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress
                size={24}
                style={{color: "white"}}
              />
            ) : (
              "Assign"
            )}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default AssignTaskDialog;
