import React, {useState} from "react";
import "./CrowdFundingHeader.scss";
import cross from "../../assets/svg/cross-circle.svg";
import {Link} from "react-router-dom";
// import "./style.scss";

import radioButton from "../../assets/svg/radioButton.svg";
import edit from "../../assets/svg/edit.svg";
import plus from "../../assets/svg/plus_icn.svg";
import Checkbox from "../profile/settings/Checkbox";
import {ReactComponent as Tick} from "../../assets/svg/tick-active.svg";
import {ReactComponent as Cross} from "../../assets/svg/cross.svg";

const CrowdfundingHeader = ({title}) => {
  const [activeModal, setActiveModal] = useState(null);

  const toggleModal = (index) => {
    setActiveModal(index === activeModal ? null : index);
  };

  const closeModal = () => {
    setActiveModal(null);
  };
  return (
    <>
      <div className="crowdfunding_header deals-room-center-header">
        <div className="acq-head-left">
          <div>
            <h2>{title}</h2>
          </div>
        </div>
        <div className="crowdfunding_header_right acq-head-right">
          <Link to={"/asset-discovery?cf=true"}>
            <button className="btn_left">
              <img
                src={cross}
                alt=""
              />
              Decline Offer
            </button>
          </Link>
          <button
            className="btn_left"
            onClick={() => toggleModal(1)}
          >
            {/* <img
              src={cross}
              alt=""
            /> */}
            Pledge
          </button>
          <button className="btn_right">Invest Now</button>
        </div>
        {/* {activeModal && <ReplyModal closeModal={closeModal} />} */}
      </div>
    </>
  );
};

export default CrowdfundingHeader;

const ReplyModal = ({closeModal}) => {
  const [answer, setAnswer] = useState("");

  const handleAnswerChange = (e) => {
    setAnswer(e.target.value);
  };

  const handleOverlayClick = (e) => {
    if (e.target.className === "reply-modal_overlay") {
      closeModal();
    }
  };
  const [navActive, setNavActive] = useState(1);
  return (
    <div
      className="reply-modal_overlay"
      onClick={handleOverlayClick}
    >
      <div className="pledge_modal">
        <div className="pledge_modal_header">
          <h2>Pledge</h2>
          <Cross
            onClick={() => closeModal()}
            style={{cursor: "pointer"}}
          />
        </div>

        <div className="pledge_modal_one">
          <div>
            <h3>Welcome To the Investment!</h3>
            <p>You can pledge now and execute the contract later. Please specify how much you’re going to invest?</p>
          </div>

          <div className="inputField_div">
            <label htmlFor="amount">Desirable Investment Amount</label>
            <input type="number" />
          </div>

          <div>
            <div>
              <Tick />
              <p>$25,000 minimum investment commitment</p>
            </div>
            <div>s</div>

            <Tick />
            <p>The increasment step should be equal to the minimum investment amount. If you want to invest more than minimum then input there $50,000 or $75,000 or more.</p>
          </div>
        </div>
      </div>
    </div>
  );
};
