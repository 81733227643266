import React, {useEffect, useState} from "react";
import "./Valuation.scss";
import {CustomTable} from "../StartSyndicate/NewSyndicate";

import DropDown from "../../components/reusableComponents/Dropdown/Dropdown";
import {PrimaryButtonSvgFirst} from "../../components/reusableComponents/Buttons/Buttons";
import {InputBox} from "../../components/reusableComponents/FormFields/FormFields";

import bath from "../../assets/Valuation/bath.svg";
import bed from "../../assets/Valuation/bed.svg";
import blueprint from "../../assets/Valuation/blueprint.svg";
import download from "../../assets/Valuation/download.svg";
import idea from "../../assets/Valuation/idea.svg";
import pricetag from "../../assets/Valuation/pricetag.svg";
import save from "../../assets/syndicate/svg/save.svg";
import filter from "../../assets/dealsroom/svg/filter.svg";
import arrow from "../../assets/Valuation/arrow.svg";
import arrowred from "../../assets/Valuation/arrowred.svg";
import incomegraph from "../../assets/Valuation/incomegraph.svg";
import savebtn from "../../assets/dealsroom/svg/save.svg";
import expense from "../../assets/Valuation/expense.svg";
import noi from "../../assets/Valuation/noi.svg";
import other from "../../assets/Valuation/other.svg";
import gpr from "../../assets/Valuation/gpr.svg";
import otherexp from "../../assets/Valuation/otherexp.svg";
import graphlegent from "../../assets/Valuation/graphlegent.svg";
import incomechart from "../../assets/Valuation/incomechart.svg";
import incomegraphtwo from "../../assets/Valuation/incomegraphtwo.svg";
import incomegraphthree from "../../assets/Valuation/incomegraphthree.svg";
import barcharttwo from "../../assets/Valuation/barcharttwo.svg";
import equity from "../../assets/Valuation/equity.svg";
import equityellispse from "../../assets/Valuation/equityellispse.svg";
import equityone from "../../assets/Valuation/equityone.svg";
import equitydot from "../../assets/Valuation/equitydot.svg";
import what from "../../assets/syndicate/svg/what.svg";
import arrowdown from "../../assets/Valuation/arrowdown.svg";
import user from "../../assets/Valuation/user.svg";
import waterfallone from "../../assets/Valuation/waterfallone.svg";
import waterfalltwo from "../../assets/Valuation/waterfalltwo.png";
import unitType from "../../assets/Valuation/unitType.svg";
import leaseType from "../../assets/Valuation/leaseType.png";
import renovation from "../../assets/Valuation/renovation.png";
import gpgraph from "../../assets/Valuation/gpgraph.png";
import action from "../../assets/dealsroom/svg/action.svg";
import prevnav from "../../assets/dealsroom/svg/prevnav.svg";
import fwdnav from "../../assets/dealsroom/svg/fwdnav.svg";
import tenants from "../../assets/Valuation/tenants.svg";
import statistics from "../../assets/Valuation/statistics.svg";
import leasinggraph from "../../assets/Valuation/leasinggraph.svg";
import leasingbar from "../../assets/Valuation/leasingbar.svg";
import reddot from "../../assets/Valuation/reddot.svg";
import bluedot from "../../assets/Valuation/bluedot.svg";
import tablegraph from "../../assets/Valuation/tablegraph.svg";
import bulb from "../../assets/Valuation/bulb.svg";
import uploadbtn from "../../assets/Valuation/uploadbtn.svg";
import upload from "../../assets/syndicate/svg/upload.svg";
import fairmarket from "../../assets/Valuation/fairmarket.png";
import ddarrows from "../../assets/Image/dropdownArrows.png";
import review from "../../assets/Valuation/review.png";
import highopportunity from "../../assets/Valuation/highopportunity.png";
import leftArrow from "../../assets/svg/leftArrow.svg";
import returnArrow from "../../assets/svg/returnArrow.svg";
import rightArrow from "../../assets/svg/rightArrow.svg";
import {Slider} from "@mui/material";
import plus from "../../assets/svg/circlePlus.svg";
import minus from "../../assets/svg/circleMinus.svg";
import bad from "../../assets/svg/bad.svg";
import bathBar from "../../assets/svg/bath.svg";
import toggleoff from "../../assets/svg/toggle-off.svg";
import toggleon from "../../assets/svg/toggle-on.svg";
import timelinechart from "../../assets/Image/timeline-chart.png";
import useschart from "../../assets/Image/uses-chart.png";
import developmentchart from "../../assets/Image/development-chart.png";
import AiSupport from "../portfolio/AiSupport";
import cover from "../../assets/Image/assetDiscovery/general-bg.png";
import chart1 from "../../assets/Image/Graph.png";
import map from "../../assets/Image/map-compare.png";
import bookmark from "../../assets/svg/bookmark-2.svg";
import compare from "../../assets/svg/compare.svg";
import add from "../../assets/svg/add.svg";
import shortAdv from "../../assets/svg/short-adv.svg";
import CrossCircle from "../../assets/svg/cross-circle.svg";
import view from "../../assets/svg/view.svg";
import Delete from "../../assets/svg/delete.svg";
import StarChart from "../../assets/Image/star-chart.png";
import ArrowDown from "../../assets/Image/assetDiscovery/arrow_down.svg";
import info from "../../assets/svg/info.svg";
import zoning from "../../assets/svg/zoning.svg";
import house from "../../assets/svg/house.svg";
import cross from "../../assets/svg/cross-red.svg";
import tick from "../../assets/svg/tick-circle.svg";
import InsightsChart from "../../assets/Image/insights-chart.png";
import NewBarChart from "./NewBarChart";
import EquityPieChart from "./EquityPieChart";
import TimeChart from "./TimeChart";
import RedChart from "./RedChart";
import BluePieChart from "./BluePieChart";
import CashFlowchart from "./CashFlowChart";
import StackBarChart from "./StackBarChart";
import DevlopmentBarChart from "./DevlopmentBarChart";
import NormalBarChart from "./NormalBarChart";
import HorizontalBarChart from "./HorizontalBarChart";
import HorizontalBarChartTwo from "./HorizontalBarChartTwo";
import {FetchValuationInfo} from "../../utils/Axios";
import {useSelector} from "react-redux";

const Valuation = () => {
  const [showAi, setShowAi] = useState(false);
  const handleAiSupport = () => {
    setShowAi(!showAi);
  };

  return (
    <div>
      <div className="dealeconomics-container acquisition_dealEconomics_container">
        <div className="economics-header">
          <EconomicsHeader handleAiSupport={handleAiSupport} />
        </div>
        <div style={{position: "relative"}}>
          <EconomicsBodyHeader />
        </div>
      </div>
      {showAi && (
        <AiSupport
          handleAiSupport={handleAiSupport}
          showAi={showAi}
        />
      )}
    </div>
  );
};

export default Valuation;

const EconomicsHeader = ({handleAiSupport}) => {
  return (
    <>
      <div className="ec-header-left">
        <span>Deal Economics</span>
      </div>
      <div className="ec-header-right">
        {/* <img
          onClick={handleAiSupport}
          src={idea}
          alt=""
        /> */}
        <PrimaryButtonSvgFirst
          name="Save Version"
          svgPath={save}
          classNames={"savebtn-val"}
        />
        <PrimaryButtonSvgFirst
          name="Download"
          svgPath={download}
          classNames={"downloadbtn-val"}
        />
      </div>
    </>
  );
};

const EconomicsBodyHeader = () => {
  const [isClicked, setIsClicked] = useState(1);
  const [navActive, setNavActive] = useState(1);
  const [toggle, setToggle] = useState(false);

  const handleQuick = () => {
    setIsClicked(1);
  };

  return (
    <div className="acquition_dealEconomics_contentWrapper">
      <div className="economics-body-head">
        <div>
          <div className="switch-btn switch-economics">
            <span
              onClick={() => {
                setIsClicked(1);
              }}
              className={isClicked === 1 && "btn-active"}
            >
              QUICK MODELING
            </span>
            <span
              onClick={() => {
                setIsClicked(2);
              }}
              className={isClicked === 2 && "btn-active"}
            >
              DEEP MODELING
            </span>
          </div>
        </div>
        <div>
          <div className="deals-room-nav economics-nav">
            {isClicked === 2 ? (
              <>
                <span
                  className={navActive === 1 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(1);
                  }}
                >
                  Purchase, Financing, & Sale
                </span>
                <span
                  className={navActive === 2 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(2);
                  }}
                >
                  Unit Mix
                </span>
                <span
                  className={navActive === 3 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(3);
                  }}
                >
                  Income & Expense
                </span>
                <span
                  className={navActive === 4 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(4);
                  }}
                >
                  Loan
                </span>
                <span
                  className={navActive === 5 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(5);
                  }}
                >
                  Development Budgets
                </span>
                <span
                  className={navActive === 6 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(6);
                  }}
                >
                  Annual Assumptions
                </span>
              </>
            ) : (
              <>
                <span
                  className={navActive === 1 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(1);
                  }}
                >
                  Acquisition & Operation
                </span>
                <span
                  className={navActive === 2 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(2);
                  }}
                >
                  Operating Expenses
                </span>
                <span
                  className={navActive === 3 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(3);
                  }}
                >
                  Unit Mix
                </span>
                <span
                  className={navActive === 4 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(4);
                  }}
                >
                  Renovation
                </span>
                <span
                  className={navActive === 5 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(5);
                  }}
                >
                  Loan
                </span>
                <span
                  className={navActive === 6 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(6);
                  }}
                >
                  Refinancing
                </span>
                <span
                  className={navActive === 7 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(7);
                  }}
                >
                  Waterfall
                </span>
                <span
                  className={navActive === 8 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(8);
                  }}
                >
                  Sale
                </span>
                <span
                  className={navActive === 9 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(9);
                  }}
                >
                  Fee
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        {
          <EconomicsBody
            navActive={navActive}
            setNavActive={setNavActive}
            isClicked={isClicked}
            setIsClicked={setIsClicked}
            toggle={toggle}
            setToggle={setToggle}
            handleQuick={handleQuick}
          />
        }
      </div>
    </div>
  );
};

const EconomicsBody = ({setNavActive, toggle, navActive, isClicked, setIsClicked, handleQuick, setToggle}) => {
  const [navActives, setNavActives] = useState(1);
  const handleRight = () => {
    if (navActive > 1) {
      setNavActive((prevValue) => prevValue - 1);
    } else {
      setNavActive(1);
    }
  };
  const handleLeft = () => {
    if (navActive < 9) {
      setNavActive((prevValue) => prevValue + 1);
    } else {
      setNavActive(9);
    }
  };

  const handleToggle = () => {
    if (navActives === 5) {
      setIsClicked(1);
      setNavActives(1);
    }
  };
  return (
    <>
      <div className="economics-body">
        <div className="economics-body-right">
          <div className="deals-room-nav economics-nav">
            <span
              style={{display: "flex", alignItems: "center"}}
              className={navActives === 1 && "deals-room-nav-active"}
              onClick={() => {
                setNavActives(1);
              }}
            >
              Summary
            </span>
            <span
              style={{display: "flex", alignItems: "center"}}
              className={(navActives === 2 || navActive === 4) && "deals-room-nav-active"}
              onClick={() => {
                setNavActives(2);
              }}
            >
              Rent Roll
            </span>
            <span
              style={{display: "flex", alignItems: "center"}}
              className={navActives === 3 && "deals-room-nav-active"}
              onClick={() => {
                setNavActives(3);
              }}
            >
              Comp Data
            </span>
            {isClicked === 2 && (
              <span
                style={{display: "flex", alignItems: "center", gap: "4px"}}
                className={navActives === 5 && "deals-room-nav-active"}
                onClick={() => {
                  setNavActives(5);
                }}
              >
                Development
                {navActives === 5 ? (
                  <img
                    src={toggleon}
                    alt=""
                    onClick={handleToggle}
                  />
                ) : (
                  <img
                    src={toggleoff}
                    alt=""
                    onClick={handleToggle}
                  />
                )}
              </span>
            )}
          </div>
          <div>{navActives === 1 && <EconomicsSummary />}</div>
          <div>{navActives === 2 && <RentRoll setNavActives={setNavActives} />}</div>
          <div>{navActives === 3 && <CompData />}</div>
          <div>{navActives === 4 && <EconomicsRentRoll />}</div>
          <div>{navActives === 5 && <Development setNavActives={setNavActives} />}</div>
        </div>
        <div className="economics-body-left">
          <div className="tabination-left-page">
            <div className="tabination_slider">
              <div onClick={() => handleRight()}>
                {navActive === 1 ? (
                  <div></div>
                ) : (
                  <img
                    src={rightArrow}
                    alt="rightArrow"
                  />
                )}
              </div>

              {isClicked === 2 ? (
                <p>
                  {navActive === 1
                    ? "Purchase, Financing, & Sale"
                    : navActive === 2
                    ? "Unit Mix"
                    : navActive === 3
                    ? "Income & Expense"
                    : navActive === 4
                    ? "Loan"
                    : navActive === 5
                    ? "Development Budget"
                    : "Annual Assumptions"}
                </p>
              ) : (
                <p>
                  {navActive === 1
                    ? "Acquisition & Operation"
                    : navActive === 2
                    ? "Operating Expenses"
                    : navActive === 3
                    ? " Unit Mix"
                    : navActive === 4
                    ? "Renovation"
                    : navActive === 5
                    ? "Loan"
                    : navActive === 6
                    ? "Refinancing"
                    : navActive === 7
                    ? "Waterfall"
                    : navActive === 8
                    ? "Sale"
                    : "Fee"}
                </p>
              )}

              {navActive === 9 ? (
                <img
                  src={returnArrow}
                  alt="leftArrow"
                  onClick={() => setNavActive(1)}
                />
              ) : (
                <img
                  src={leftArrow}
                  alt="leftArrow"
                  onClick={handleLeft}
                />
              )}
            </div>
            {/* SLIDER LABELS */}
            {isClicked === 1 ? (
              <div>
                {navActive === 1 ? (
                  <div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Purchase Price
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Closing Costs
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      {/* <p
                    style={{marginBottom: "0px"}}
                    className="slider_name"
                  >
                    Closing Costs
                  </p> */}
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>%</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Rent, per SF per year
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <span>0</span> <span>100</span>
                      </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Rent, per SF per year
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <span>0</span> <span>100</span>
                      </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Net Leasable Area
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              className="Slider-range"
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Annual Vacancy Rate
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Annual Rent Growth
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Expenses, per SF per year
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <span>0</span> <span>100</span>
                      </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Annual Expense Growth
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Other Income, monthly per unit
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <span>0</span> <span>100</span> 
                    </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Management Fee (as % of EGR)
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : navActive === 2 ? (
                  <div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Property Management Fees
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Personnel
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        General & Administrative
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Marketing
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              className="Slider-range"
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                      <span>0</span> <span>100</span>
                    </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Turnover
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              className="Slider-range"
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Repairs & Maintenance
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              className="Slider-range"
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Contract Services
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Utilities
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              className="Slider-range"
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                      <span>0</span> <span>100</span>
                    </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Utility Reimbursements
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Property Taxes
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                      <span>0</span> <span>100</span> 
                  </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Insurance
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Capital Expense Reserves
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : navActive === 3 ? (
                  <div>
                    <p style={{padding: "20px 0 0 15px", fontSize: "18px"}}>Studio</p>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Number of Units
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              className="Slider-range"
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Area
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Rent
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              className="Slider-range"
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{display: "flex", alignItems: "center", gap: "8px", padding: "10px 20px"}}>
                      <img
                        src={bad}
                        alt=""
                      />
                      <span style={{fontWeight: "500"}}>1 Bad</span>
                      <img
                        src={bathBar}
                        alt=""
                      />
                      <span style={{fontWeight: "500"}}>1 Bath</span>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Number of Units
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Area
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Rent
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                          <span>0</span> <span>100</span>
                        </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{display: "flex", alignItems: "center", gap: "8px", padding: "10px 20px"}}>
                      <img
                        src={bad}
                        alt=""
                      />
                      <span style={{fontWeight: "500"}}>1 Bad</span>
                      <img
                        src={bathBar}
                        alt=""
                      />
                      <span style={{fontWeight: "500"}}>1 Bath</span>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Number of Units
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              className="Slider-range"
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Area
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              className="Slider-range"
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Rent
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                          <span>0</span> <span>100</span>
                        </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <p style={{marginBottom: "0px", padding: "15px", display: "flex", alignItems: "center", gap: "20px"}}>
                      <img
                        src={plus}
                        alt="plus"
                      />{" "}
                      Add custom unit type
                    </p>
                  </div>
                ) : navActive === 4 ? (
                  <div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Rehab Budget
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Year Rehab Begins
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              className="Slider-range"
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Year Rehab Complete
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              className="Slider-range"
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                          <span>0</span> <span>100</span>
                        </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : navActive === 5 ? (
                  <div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        LTV constrains
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              className="Slider-range"
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Loan Fees
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Interest Rate
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Acquisition Loan Amortization
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : navActive === 6 ? (
                  <div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Refinance At End Of
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              className="Slider-range"
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <span>0</span> <span>100</span>
                  </p> */}
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Cap Rate Used For Valuation
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Refinance LTV
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Refinance Loan Fees
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Refinance Loan Interest Rate
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Refinance Loan Amortization
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <span>0</span> <span>100</span>
                  </p> */}
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : navActive === 7 ? (
                  <div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        General Partner Equity
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Limited Partner Equity
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Preferred Return
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Promote Over Preferred Return
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <p style={{padding: "15px", fontSize: "18px", color: "#3E3F42", fontWeight: "500"}}>Promote Structure (IRR Hurdles)</p>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Hurdle 1 (Preferred Return)
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Hurdle 2
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        GP Promote after Hurdle 2
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Hurdle 3
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        GP Promote after Hurdle 3
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        GP Promote after Hurdle 4
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : navActive === 8 ? (
                  <div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Exit Cap Rate
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Costs of Sale
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Sale Year
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : navActive === 9 ? (
                  <div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Acquisition Fee
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Construction Management Fee
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Asset Management Fee
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Disposition Fee
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  false
                )}
              </div>
            ) : (
              <div>
                <h2>Property Details</h2>
                {navActive === 1 ? (
                  <div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Number of Buildings
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>Buildings</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Net Acres
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="              inputFlexLine">
                              <p>Acres</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <h2>Lease-Up Information</h2>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Lease-Up Period Begins
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>Year</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Property Stabilization
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>%</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <span>0</span> <span>100</span>
                      </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <h2>Land Acquisition Information</h2>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Land Acquisition Price
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Closing Costs
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      ></p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon dateDiv">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Land Closing Date
                      </p>
                      <InputBox type={"date"} />
                    </div>
                    <h2>Sale Details</h2>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Exit Cap Rate
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>%</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <span>0</span> <span>100</span>
                      </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Costs of Sale
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>%</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Hold Period
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>360</p>
                              <span>{"MO"}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon dateDiv">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Projected Exit Date
                      </p>
                      <InputBox type={"date"} />
                    </div>
                  </div>
                ) : navActive === 2 ? (
                  <div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Property Management Fees
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Personnel
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        General & Administrative
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Marketing
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                      <span>0</span> <span>100</span>
                    </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Turnover
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Repairs & Maintenance
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Contract Services
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Utilities
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                      <span>0</span> <span>100</span>
                    </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Utility Reimbursements
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Property Taxes
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                      <span>0</span> <span>100</span> 
                  </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Insurance
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Capital Expense Reserves
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : navActive === 3 ? (
                  <div>
                    <p style={{padding: "20px 0 0 15px", fontSize: "18px"}}>Studio</p>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Number of Units
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Area
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Rent
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{display: "flex", alignItems: "center", gap: "8px", padding: "10px 20px"}}>
                      <img
                        src={bad}
                        alt=""
                      />
                      <span style={{fontWeight: "500"}}>1 Bad</span>
                      <img
                        src={bathBar}
                        alt=""
                      />
                      <span style={{fontWeight: "500"}}>1 Bath</span>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Number of Units
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Area
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Rent
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                          <span>0</span> <span>100</span>
                        </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{display: "flex", alignItems: "center", gap: "8px", padding: "10px 20px"}}>
                      <img
                        src={bad}
                        alt=""
                      />
                      <span style={{fontWeight: "500"}}>1 Bad</span>
                      <img
                        src={bathBar}
                        alt=""
                      />
                      <span style={{fontWeight: "500"}}>1 Bath</span>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Number of Units
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Area
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Rent
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                          <span>0</span> <span>100</span>
                        </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <p style={{marginBottom: "0px", padding: "15px", display: "flex", alignItems: "center", gap: "20px"}}>
                      <img
                        src={plus}
                        alt="plus"
                      />{" "}
                      Add custom unit type
                    </p>
                  </div>
                ) : navActive === 4 ? (
                  <div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Rehab Budget
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Year Rehab Begins
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: ""}}> from </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Year Rehab Complete
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                          <span>0</span> <span>100</span>
                        </p> */}
                        </div>

                        <div className="priceBtn">
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : navActive === 5 ? (
                  <div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        LTV constrains
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Loan Fees
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Interest Rate
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Acquisition Loan Amortization
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : navActive === 6 ? (
                  <div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Refinance At End Of
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <span>0</span> <span>100</span>
                  </p> */}
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Cap Rate Used For Valuation
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Refinance LTV
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Refinance Loan Fees
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Refinance Loan Interest Rate
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Refinance Loan Amortization
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          {/* <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <span>0</span> <span>100</span>
                  </p> */}
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : navActive === 7 ? (
                  <div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        General Partner Equity
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Limited Partner Equity
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Preferred Return
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Promote Over Preferred Return
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <p style={{padding: "15px", fontSize: "18px", color: "#3E3F42", fontWeight: "500"}}>Promote Structure (IRR Hurdles)</p>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Hurdle 1 (Preferred Return)
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Hurdle 2
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        GP Promote after Hurdle 2
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Hurdle 3
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        GP Promote after Hurdle 3
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        GP Promote after Hurdle 4
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : navActive === 8 ? (
                  <div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Exit Cap Rate
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Costs of Sale
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Sale Year
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : navActive === 9 ? (
                  <div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Acquisition Fee
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Construction Management Fee
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Asset Management Fee
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dropDownOuterCon ">
                      <p
                        style={{marginBottom: "0px"}}
                        className="slider_name"
                      >
                        Disposition Fee
                      </p>
                      <div className="sildermain">
                        <div
                          className=""
                          style={{width: "80%"}}
                        >
                          <div className="slider_container">
                            <div className="top_div inputFlexLine">
                              <p>$</p>
                              <span>{4000}</span>
                            </div>
                            <Slider
                              defaultValue={10000}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              step={5000}
                              min={0}
                              max={300000}
                              // onChange={(event) => {
                              //   setPrice((prev) => ({
                              //     ...prev,
                              //     from: event.target.value.toLocaleString(),
                              //   }));
                              // }}
                            />
                          </div>
                          <p style={{marginBottom: "", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>0</span> <span>100</span>
                          </p>
                        </div>

                        <div
                          className="priceBtn"
                          style={{marginBottom: "25px"}}
                        >
                          <img
                            src={minus}
                            alt=""
                          />
                          <img
                            src={plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  false
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const Development = ({setNavActives}) => {
  const timePeriods = ["3 m", "6 m", "1 year", "2 years", "All period"];
  const [navActive, setNavActive] = useState(0);
  const incomeType = [
    {img: expense, value: "Construction Coste"},
    {img: gpr, value: "GPR"},
    {img: other, value: "Other Income"},
    {img: otherexp, value: "Other expenses"},
    {img: expense, value: "Interest Payment"},
    {img: expense, value: "Management Fee"},
    {img: expense, value: "Interest Payment"},
    {img: expense, value: "Management Fee"},
    {img: otherexp, value: "Other expenses"},
  ];
  return (
    <div className="development_wrapper">
      <div className="summary-body-A">
        <div
          style={{height: "70px", borderRight: "1px solid #eaedf3", padding: "0 10px"}}
          className="price-summary"
        >
          <span>Stabilized Yield</span>
          <span>10X</span>
        </div>
        <div
          style={{height: "70px", borderRight: "1px solid #eaedf3", padding: "0 10px"}}
          className="price-summary"
        >
          <span>Stabilized Debt</span>
          <span>3X</span>
        </div>
        <div
          style={{height: "70px", borderRight: "1px solid #eaedf3", padding: "0 10px"}}
          className="price-summary"
        >
          <span>Total Project Cost</span>
          <span>7 years</span>
        </div>
        <div
          style={{height: "70px", borderRight: "1px solid #eaedf3", padding: "0 10px"}}
          className="price-summary"
        >
          <span>Stabilized Debt</span>
          <span>2.9%</span>
        </div>
        <div
          style={{height: "70px", borderRight: "1px solid #eaedf3", padding: "0 10px"}}
          className="price-summary"
        >
          <span>Stabilized Debt</span>
          <span>2.9%</span>
        </div>
      </div>

      <div className="body-B-header">
        <h2>Return Metrics</h2>
        <div>
          <p>Levered</p>
          <span>Unlevered</span>
        </div>
      </div>
      <div className="summary-body-B">
        <div
          style={{height: "fit-content"}}
          className="price-summary-B"
        >
          <span>IRR</span>
          <span>5.24%</span>
          <div className="div-img">
            <img
              src={arrow}
              alt=""
            />
            <p>5.9% &nbsp;</p>
            <span>vs. Goal</span>
          </div>
          <div className="price-summary-B-lower">
            <span></span>
            <span>5.24%</span>
            <div className="div-img">
              <img
                src={arrow}
                alt=""
              />
              <p>5.9% &nbsp;</p>
              <span>vs. Goal</span>
            </div>
          </div>
        </div>

        <div
          style={{height: "fit-content"}}
          className="price-summary-B"
        >
          <span>IRR</span>
          <span>5.24%</span>
          <div className="div-img">
            <img
              src={arrow}
              alt=""
            />
            <p>5.9% &nbsp;</p>
            <span>vs. Goal</span>
          </div>
          <div className="price-summary-B-lower">
            <span></span>
            <span>5.24%</span>
            <div className="div-img">
              <img
                src={arrow}
                alt=""
              />
              <p>5.9% &nbsp;</p>
              <span>vs. Goal</span>
            </div>
          </div>
        </div>

        <div
          style={{height: "fit-content"}}
          className="price-summary-B"
        >
          <span>IRR</span>
          <span>5.24%</span>
          <div className="div-img">
            <img
              src={arrow}
              alt=""
            />
            <p>5.9% &nbsp;</p>
            <span>vs. Goal</span>
          </div>
          <div className="price-summary-B-lower">
            <span></span>
            <span>5.24%</span>
            <div className="div-img">
              <img
                src={arrow}
                alt=""
              />
              <p>5.9% &nbsp;</p>
              <span>vs. Goal</span>
            </div>
          </div>
        </div>

        <div
          style={{height: "fit-content"}}
          className="price-summary-B"
        >
          <span>IRR</span>
          <span>5.24%</span>
          <div className="div-img">
            <img
              src={arrow}
              alt=""
            />
            <p>5.9% &nbsp;</p>
            <span>vs. Goal</span>
          </div>
          <div className="price-summary-B-lower">
            <span></span>
            <span>5.24%</span>
            <div className="div-img">
              <img
                src={arrow}
                alt=""
              />
              <p>5.9% &nbsp;</p>
              <span>vs. Goal</span>
            </div>
          </div>
        </div>
      </div>

      <div className="development-charts">
        <div className="net-income-cont">
          <div className="net-income-head">
            <div>
              <h1>Development Roadmap </h1>
            </div>
          </div>
          <div
            style={{padding: "10px"}}
            className="income-type"
          >
            {incomeType.map((type, index) => (
              <div key={index}>
                <img
                  src={type.img}
                  alt=""
                />
                <span>{type.value}</span>
              </div>
            ))}
          </div>

          <div className="income-graphs">
            <DevlopmentBarChart />
            {/* <img
              src={developmentchart}
              alt=""
            /> */}
          </div>
        </div>

        <div className="net-income-cont">
          <div className="net-income-head">
            <div>
              <h1>Total Uses </h1>
            </div>
          </div>

          <div className="income-graphs">
            <HorizontalBarChart />
            {/* <img
              src={useschart}
              alt=""
            /> */}
          </div>

          <div
            style={{padding: "10px"}}
            className="income-type"
          >
            {incomeType.map((type, index) => (
              <div key={index}>
                <img
                  src={type.img}
                  alt=""
                />
                <span>{type.value}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="net-income-cont">
          <div className="net-income-head">
            <div>
              <h1>Development Roadmap </h1>
            </div>
            <div className="income-time">
              {timePeriods.map((period, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setNavActive(index);
                  }}
                  className={`${navActive === index && "timeactive"}`}
                >
                  <span>{period}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="income-graphs">
            <HorizontalBarChartTwo />
            <img
              src={timelinechart}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const EconomicsSummary = () => {
  const [navActive, setNavActive] = useState(0);
  const [navActiveone, setNavActiveone] = useState(0);
  const timePeriods = ["3 m", "6 m", "1 year", "2 years", "5 years", "10 years", "All period"];

  const incomeType = [
    {img: noi, value: "NOI"},
    // { img: gpr, value: "GPR" },
    {img: other, value: "Other Income"},
    {img: expense, value: "Interest Payment"},
    {img: expense, value: "Management Fee"},
    {img: otherexp, value: "Other expenses"},
  ];
  const incomePlan = [
    {img: noi, value: "Levered Net Cash Flow"},
    {img: other, value: "Unlevered Net Cash Flow"},
    {img: otherexp, value: "Principal Payment"},
    {img: expense, value: "Interest Payment"},
    {img: otherexp, value: "Ending Loan Balance"},
  ];

  const formatPrice = (price) => {
    if (price) {
      let priceString = price.toString();

      let parts = priceString.split(".");

      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return parts.join(".");
    }
  };

  // Example usage:
  const formattedPrice = formatPrice(10485985);

  // const data = {

  //   summary: {
  //     lily_pads_estimate: 10485985,
  //     appreciation_estimate: 17.4,
  //     optimal_hold_time: 7,
  //     annual_income_growth: 2.9,
  //     cap_rate: 5.24,
  //     irr: 11.5,
  //     noi: 503893,
  //     income_metrics: {
  //       effective_gross_income: 231232,
  //       cash_on_cash_return: {
  //         year: 2.3,
  //         avg: 5.6
  //       },
  //       equity_multiple: 4.7
  //     },
  //     expense_metrics: {
  //       operating_expenses: 255985,
  //       property_taxes: 45985,
  //       property_management: 85985,
  //       utilities_and_periodic_expenses: 148985
  //     },
  //     unit_mix: [
  //       {
  //         Studio: false,
  //         br: "1",
  //         ba: "2",
  //         units: 20,
  //         percentage_of_total: "20%",
  //         unit_net_income: "$20,000",
  //         percentage_of_total_income: "11%",
  //         area_sf: 1000,
  //         rent: "$1,250"
  //       },
  //       {
  //         Studio: true,
  //         br: null,
  //         ba: null,
  //         units: 20,
  //         percentage_of_total: "26%",
  //         unit_net_income: "$20,000",
  //         percentage_of_total_income: "25%",
  //         area_sf: 1000,
  //         rent: "$1,250"
  //       },
  //       {
  //         Studio: true,
  //         br: null,
  //         ba: null,
  //         units: 20,
  //         percentage_of_total: "26%",
  //         unit_net_income: "$65,000",
  //         percentage_of_total_income: "34%",
  //         area_sf: 1000,
  //         rent: "$1,250"
  //       },
  //       {
  //         Studio: false,
  //         br: "1",
  //         ba: "2",
  //         units: 20,
  //         percentage_of_total: "26%",
  //         unit_net_income: "$50,000",
  //         percentage_of_total_income: "28%",
  //         area_sf: 1000,
  //         rent: "$1,250"
  //       }
  //     ],
  //     net_operating_income: {
  //       dates: [
  //         "2023-01-01",
  //         "2023-02-01",
  //         "2023-03-01",
  //         "2023-04-01",
  //         "2023-05-01"
  //       ],
  //       noi: [
  //         3800000,
  //         3900000,
  //         4100000,
  //         4000000,
  //         4200000
  //       ],
  //       gpr: [
  //         3700000,
  //         3800000,
  //         4000000,
  //         3900000,
  //         4100000
  //       ],
  //       other_income: [
  //         3700000,
  //         3800000,
  //         4000000,
  //         3900000,
  //         4100000
  //       ],
  //       interest_payment: [
  //         3700000,
  //         3800000,
  //         4000000,
  //         3900000,
  //         4100000
  //       ],
  //       management_fee: [
  //         3700000,
  //         3800000,
  //         4000000,
  //         3900000,
  //         4100000
  //       ],
  //       other_expenses: [
  //         3700000,
  //         3800000,
  //         4000000,
  //         3900000,
  //         4100000
  //       ]
  //     },
  //     cash_flow_plan: {
  //       loan_proceeds: "9,750,000",
  //       loan_origination_fees: "9,750,000",
  //       cash_flow: {
  //         dates: [
  //           "2023-01-01",
  //           "2023-02-01",
  //           "2023-03-01",
  //           "2023-04-01",
  //           "2023-05-01"
  //         ],
  //         levered_net_cash_flow: [
  //           3800000,
  //           3900000,
  //           4100000,
  //           4000000,
  //           4200000
  //         ],
  //         unlevered_net_cash_flow: [
  //           3700000,
  //           3800000,
  //           4000000,
  //           3900000,
  //           4100000
  //         ],
  //         principal_payment: [
  //           3700000,
  //           3800000,
  //           4000000,
  //           3900000,
  //           4100000
  //         ],
  //         interest_payment: [
  //           3700000,
  //           3800000,
  //           4000000,
  //           3900000,
  //           4100000
  //         ],
  //         ending_loan_balance: [
  //           3700000,
  //           3800000,
  //           4000000,
  //           3900000,
  //           4100000
  //         ]
  //       }
  //     },
  //     equity_to_loan: {
  //       equity: "$838,500",
  //       max_loan: "$0",
  //       refinance_loan_amount: "20%",
  //       common_equity: "15%",
  //       preferred_equity: "65%",
  //       debt: "40%",
  //       senior_debt: "25%",
  //       mezzanine_financing: "15%"
  //     },
  //     water_flow_structure: {
  //       pie_chart: {
  //         data: [
  //           3700000,
  //           3800000,
  //           4000000,
  //           3900000,
  //           4100000
  //         ]
  //       },
  //       time_chart: {
  //         data: [
  //           3700000,
  //           3800000,
  //           4000000,
  //           3900000,
  //           4100000
  //         ]
  //       }
  //     },
  //     cash_flow: {
  //       gp_irr: "10%",
  //       gp_equity_multiple: "2.6",
  //       lp_iir: "7.5",
  //       lp_equity_multiple: "2.1",
  //       gp: [
  //         323,
  //         131,
  //         423,
  //         567,
  //         223,
  //         311,
  //         131,
  //         121
  //       ],
  //       lp: [
  //         222,
  //         444,
  //         555,
  //         63,
  //         222,
  //         54,
  //         77,
  //         312
  //       ],
  //       date: [
  //         "2020",
  //         "2021",
  //         "2023",
  //         "2024",
  //         "2025",
  //         "2026",
  //         "2027",
  //         "2028"
  //       ]
  //     }
  //   }
  // }

  const [data, setData] = useState();
  const {idToken} = useSelector((state) => state.auth);
  const {userInformation} = useSelector((state) => state.userInfo);

  useEffect(() => {
    (async () => {
      FetchValuationInfo(idToken)?.then((res) => setData(res?.data[0]));
    })();
  }, []);

  const CashFlowMetrics = [
    {title: "GP IRR", value: data?.summary?.cash_flow?.gp_irr},
    {title: "GP Equity Multiple", value: data?.summary?.cash_flow?.lp_equity_multiple},
    {title: "LP IIR", value: data?.summary?.cash_flow?.lp_iir},
    {title: "LP Equity Multiple", value: data?.summary?.cash_flow?.lp_equity_multiple},
  ];

  const expenseMetrics = [
    {label: "Operating Expenses", value: data?.summary?.expense_metrics?.operating_expenses},
    {label: "Property Taxes", value: data?.summary?.expense_metrics?.property_taxes},
    {label: "Property Management", value: data?.summary?.expense_metrics?.property_management},
    {label: "Utilities and Perodic Exp.", value: data?.summary?.expense_metrics?.utilities_and_periodic_expenses},
  ];

  const {dates, gpr, interest_payment, management_fee, other_expenses, other_income} = data?.summary?.net_operating_income ?? {}; // Use optional chaining and nullish coalescing operator

  const [filteredData, setFilteredData] = useState(data?.summary?.net_operating_income);
  const [selectedPeriod, setSelectedPeriod] = useState("All period"); // state for Net Operating Income
  const [cashFlowPeriod, setCashFlowPeriod] = useState("All period");

  const handleTimePeriodChange = (period) => {
    setSelectedPeriod(period);

    // Filter data based on selected period
    if (period !== "All period") {
      const index = timePeriods.indexOf(period);
      const filteredNetOperatingIncome = {
        ...data.summary.net_operating_income,
        dates: data.summary.net_operating_income.dates.slice(-index),
        noi: data.summary.net_operating_income.noi.slice(-index),
        gpr: data.summary.net_operating_income.gpr.slice(-index),
        other_income: data.summary.net_operating_income.other_income.slice(-index),
        interest_payment: data.summary.net_operating_income.interest_payment.slice(-index),
        management_fee: data.summary.net_operating_income.management_fee.slice(-index),
        other_expenses: data.summary.net_operating_income.other_expenses.slice(-index),
      };
      setFilteredData(filteredNetOperatingIncome);
    } else {
      // If all period selected, set data to original
      setFilteredData(data?.summary?.net_operating_income);
    }
  };
  useEffect(() => {
    handleTimePeriodChange("All period");
  }, [data?.summary?.net_operating_income]);

  return (
    <div className="summary-main-container">
      <div className="summary-body-A">
        <div className="price-summary">
          <span>LilyPads Estimate</span>
          <span>${formatPrice(data?.summary?.lily_pads_estimate)}</span>
          <div className="div-img">
            <img
              src={arrow}
              alt=""
            />
            <span>5.9%</span>
          </div>
          <span>Compare to listed $12.4M</span>
        </div>
        <div className="price-summary">
          <span>Appreciation Estimate</span>
          <span>{data?.summary?.appreciation_estimate}%</span>
          <div className="div-img img-red">
            <img
              src={arrowred}
              alt=""
            />
            <span>9.1%</span>
          </div>
          <span>Compare to listed $12.4M</span>
        </div>
        <div className="price-summary">
          <span>Optimal Hold Time</span>
          <span>{data?.summary?.optimal_hold_time} years</span>
        </div>
        <div className="price-summary">
          <span>Annual Income Growth</span>
          <span>{data?.summary?.annual_income_growth}%</span>
        </div>
      </div>
      <div className="summary-body-B">
        <div className="price-summary-B">
          <span>CAP RATE</span>
          <span>{data?.summary?.cap_rate}%</span>
          <div className="div-img">
            <img
              src={arrow}
              alt=""
            />
            <p>5.9% &nbsp;</p>
            <span>vs. Goal</span>
          </div>
        </div>
        <div className="price-summary-B">
          <span>IRR</span>
          <span>{data?.summary?.irr}%</span>
          <div className="div-img">
            <img
              src={arrow}
              alt=""
            />
            <p>5.9% &nbsp;</p>
            <span>vs. Goal</span>
          </div>
        </div>
        <div className="price-summary-B">
          <span>NOI</span>
          <span>${formatPrice(data?.summary?.noi)}</span>
          <div className="div-img">
            <img
              src={arrow}
              alt=""
            />
            <p>5.9% &nbsp;</p>
            <span>vs. Goal</span>
          </div>
        </div>
      </div>
      <div className="income-container">
        <div className="income-left">
          <h1>Income Metrics</h1>
          <div className="income-flex">
            <span>Effective Gross Income</span>
            <span>${formatPrice(data?.summary?.income_metrics?.effective_gross_income)}</span>
          </div>
          <div className="income-flex">
            <span>Cash-on-Cash Return</span>
            <div>
              <div>
                <p>{data?.summary?.income_metrics?.cash_on_cash_return?.year}%</p>
                <span>Year 1</span>
              </div>
              <div>
                <p>{data?.summary?.income_metrics?.cash_on_cash_return?.avg}%</p>
                <span>Avg</span>
              </div>
            </div>
          </div>
          <div className="income-flex">
            <span>Equity Multiple</span>
            <span>{data?.summary?.income_metrics?.equity_multiple}x</span>
          </div>
        </div>
        <NormalBarChart />
        {/* <img
          src={incomegraph}
          alt=""
        /> */}
        <div className="income-right">
          <h1>Expense Metrics</h1>
          {expenseMetrics.map((metric, index) => (
            <div key={index}>
              <p>{metric.label}</p>
              <span>${metric.value} </span>
            </div>
          ))}
        </div>
      </div>
      <div className="unit-container">
        <div className="unit-head">
          <span>Unit Mix</span>
          <img
            src={savebtn}
            alt=""
          />
        </div>
        <div className="unit-card-body">
          <>
            {/* <ReusableUnitCard
            title="Studio"
            units={20}
            unitNetIncome="$20,000"
            area={1000}
            rent="$1,250"
            blueprint={blueprint}
            pricetag={pricetag}
          /> */}
            {/* <ReusableUnitCardB
            contentArray={[
              { title: "1 BR", icon: bed, image: blueprint, percentage: "26%" },
              { title: "1 BA", icon: bath, image: pricetag, percentage: "25%" },
            ]}
            units={20}
            unitNetIncome="$20,000"
            area={1000}
            rent="$1,250"
          />
          <ReusableUnitCardB
            contentArray={[
              { title: "2 BR", icon: bed, image: blueprint, percentage: "26%" },
              { title: "2 BA", icon: bath, image: pricetag, percentage: "34%" },
            ]}
            units={20}
            unitNetIncome="$65,000"
            area={1000}
            rent="$1,250"
          />
          <ReusableUnitCardB
            contentArray={[
              { title: "3 BR", icon: bed, image: blueprint, percentage: "26%" },
              { title: "2 BA", icon: bath, image: pricetag, percentage: "28%" },
            ]}
            units={20}
            unitNetIncome="$50,000"
            area={1000}
            rent="$1,250"
          /> */}
          </>

          {data?.summary?.unit_mix?.map((item, index) => {
            return (
              <ReusableUnitCard
                data={item}
                blueprint={blueprint}
                pricetag={pricetag}
                bed={bed}
                bath={bath}
              />
            );
          })}
        </div>
      </div>
      <div className="net-income-cont">
        <div className="net-income-head">
          <div>
            <h1>Net Operating Income</h1>
          </div>
          <div className="income-head-right">
            <img
              src={savebtn}
              alt=""
            />
            <div>
              <img
                src={filter}
                alt=""
              />
              <span>Filter</span>
            </div>
          </div>
        </div>
        <div>
          {/* Filter bar */}
          <div className="income-time">
            {timePeriods.map((period, index) => (
              <div
                key={index}
                onClick={() => handleTimePeriodChange(period)}
                className={`${selectedPeriod === period && "timeactive"}`}
              >
                <span>{period}</span>
              </div>
            ))}
          </div>
          {/* Chart component */}
          <div className="income-graphs"></div>
          <div className="income-graphs">
            <NewBarChart data={filteredData} />
            {/* <img
            src={incomegraphtwo}
            alt=""
          /> */}
            <img
              src={graphlegent}
              alt=""
            />
            <img
              src={incomechart}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="net-income-cont">
        <div className="net-income-head">
          <div>
            <h1>Cash Flow Plan</h1>
          </div>
          <div className="income-head-right">
            <img
              src={savebtn}
              alt=""
            />
            <div>
              <img
                src={filter}
                alt=""
              />
              <span>Filter</span>
            </div>
          </div>
        </div>
        <div className="net-income-body">
          <div className="income-time">
            {timePeriods.map((period, index) => (
              <div
                key={index}
                onClick={() => {
                  setNavActiveone(index);
                  setCashFlowPeriod(period);
                }}
                className={`${navActiveone === index && "timeactive"}`}
              >
                <span>{period}</span>
              </div>
            ))}
          </div>
          <div className="loan-ammount">
            <div>
              <span>Loan Proceeds</span>
              <span>${data?.summary?.cash_flow_plan?.loan_proceeds}</span>
            </div>
            <div>
              <span>Loan Origination Fees</span>
              <span>${data?.summary?.cash_flow_plan?.loan_origination_fees}</span>
            </div>
          </div>
          {/* <div className="income-type">
            {incomePlan.map((type, index) => (
              <div key={index}>
                <img
                  src={type.img}
                  alt=""
                />
                <span>{type.value}</span>
              </div>
            ))}
          </div> */}
        </div>
        <div className="income-graphs">
          <RedChart
            data={data?.summary?.cash_flow_plan?.cash_flow}
            period={cashFlowPeriod}
          />
          {/* <img
            src={incomegraphthree}
            alt=""
          /> */}
          {/* <img
            src={graphlegent}
            alt=""
          /> */}
          <img
            src={barcharttwo}
            alt=""
          />
        </div>
      </div>
      <div className="equity-loan-container">
        <h1>Capital Stack</h1>
        <div className="net-income-cont">
          <div className="net-income-head">
            <div className="income-head-left">
              <div>
                <img
                  src={equity}
                  alt=""
                />
              </div>
              <h1>Equity to Loan</h1>
            </div>
            <div>
              <img
                src={savebtn}
                alt=""
              />
            </div>
          </div>
          <div className="equity-body">
            <div className="equity-body-top">
              {/* <img
                src={equityellispse}
                alt=""
              /> */}
              <EquityPieChart data={data?.summary?.equity_to_loan} />
              <div>
                <p>$451,500</p>
                <div>
                  <img
                    src={equitydot}
                    alt=""
                  />
                  <span>EQUITY</span>
                </div>
              </div>
              <div>
                <p>$838,500</p>
                <div>
                  <img
                    src={expense}
                    alt=""
                  />
                  <span>MAX LOAN</span>
                </div>
              </div>
              <div>
                <p>$0</p>
                <span>Refinance Loan Amount</span>
              </div>
            </div>
            <div className="equity-body-bottom">
              <div className="stackBarWrapper">
                <div style={{width: "100%"}}>
                  <StackBarChart />
                </div>
                {/* <img
                src={equityone}
                alt=""
              /> */}
                <div className="equity-bottom-left">
                  <div className="bottom-left-A bottom-left-flex">
                    <p>20%</p>
                    <div>
                      <span>Common Equity</span>
                      <img
                        src={what}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="bottom-left-B bottom-left-flex">
                    <p>15%</p>
                    <div>
                      <span>Preferred Equity</span>
                      <img
                        src={what}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="bottom-left-C bottom-left-flex">
                    <p>65%</p>
                    <div>
                      <img
                        src={arrowdown}
                        alt=""
                      />
                      <span>Debt</span>
                      <img
                        src={what}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="bottom-left-D">
                    <div>
                      <div>
                        <p>40%</p>
                        <img
                          src={what}
                          alt=""
                        />
                      </div>
                      <span>Senior Debt</span>
                    </div>
                    <div>
                      <div>
                        <p>25%</p>
                        <img
                          src={what}
                          alt=""
                        />
                      </div>
                      <span>Mezzanine Financing</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="net-income-cont">
        <div className="net-income-head">
          <div className="income-head-left">
            <div>
              <img
                src={user}
                alt=""
              />
            </div>
            <h1>Waterfal Structure</h1>
          </div>
          <div>
            <img
              src={savebtn}
              alt=""
            />
          </div>
        </div>
        <div className="waterfall-body">
          {/* <img
            src={waterfallone}
            alt=""
          /> */}
          <BluePieChart />
          {/* <img
            src={waterfalltwo}
            alt=""
          /> */}
          <TimeChart />
        </div>
      </div>
      <div className="net-income-cont">
        <div className="net-income-head">
          <div className="income-head-left">
            <div className="head-left-upper">
              <div></div>
              <div>
                <p>
                  GP <span>VS.</span>
                </p>
              </div>
              <div></div>
              <div>
                <p>
                  LP <span>/</span> Cash Flow
                </p>
              </div>
            </div>
          </div>
          <div>
            <img
              src={savebtn}
              alt=""
            />
          </div>
        </div>
        <div className="cash-flow-body">
          <div className="cash-flow-body-upper">
            {CashFlowMetrics.map((metric, index) => (
              <div key={index}>
                <h1>{metric.title}</h1>
                <span>{metric.value}</span>
              </div>
            ))}
          </div>
          <CashFlowchart data={data?.summary?.cash_flow} />
          <img
            src={gpgraph}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const RentRoll = ({setNavActives}) => {
  const [btnClicked, setButtonClicked] = useState(false);
  return (
    <div>
      <div className="rent-roll-container">
        <div className="rent-roll-head">
          <h1>Rent Roll</h1>
          <PrimaryButtonSvgFirst
            onclick={() => {
              setButtonClicked(!btnClicked);
              setNavActives(4);
            }}
            name="Upload File"
            svgPath={uploadbtn}
            classNames="uploadbtn"
          />
        </div>
        <div className="select-doc roll-doc">
          <img
            src={upload}
            alt=""
          />
          <InputBox type={"file"} />

          <p>Supported file formats: PDF. Max file size 250 MB</p>
        </div>
      </div>
    </div>
  );
};

const EconomicsRentRoll = () => {
  const [isClicked, setIsClicked] = useState(1);
  const [navActive, setNavActive] = useState(1);
  const [navActiveone, setNavActiveone] = useState(1);
  const columns = ["Unit ID", "Tenant", "Unit Type", "Area,SF", "Lease Status", "Base Rent", "Extra Fees"];

  const data = [
    [
      8479,
      "Darlene Steward",
      "3BR-2BA",
      "1000",
      () => (
        <div className="table-btn">
          <span>OCCUPIED</span>
        </div>
      ),
      "$1,860",
      "$60",
    ],
    [
      4647,
      "-",
      "1BR-1BA",
      "1000",
      () => (
        <div className="table-btn">
          <span>vacant</span>
        </div>
      ),
      "$1,860",
      0,
    ],
    [
      2579,
      "Wendy Watson",
      "1BR-1BA",
      "1000",
      () => (
        <div className="table-btn">
          <span>OCCUPIED</span>
        </div>
      ),
      "$1,860",
      0,
    ],
    [
      6329,
      "-",
      "1BR-1BA",
      "1000",
      () => (
        <div className="table-btn">
          <span>model</span>
        </div>
      ),
      "$1,860",
      "$100",
    ],
    [
      3558,
      "Audrey Williamson",
      "3BR-2BA",
      "1000",
      () => (
        <div className="table-btn">
          <span>OCCUPIED</span>
        </div>
      ),
      "$1,860",
      0,
    ],
    [
      5078,
      "Colleen Warren",
      "1BR-1BA",
      "1000",
      () => (
        <div className="table-btn">
          <span>OCCUPIED</span>
        </div>
      ),
      "$1,860",
      0,
    ],
  ];
  const [mothList, setMothList] = useState(["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]);
  const defaultStatus = "January";
  const [status, setStatus] = useState(defaultStatus);
  const [statusDA, setStatusDA] = useState(false);

  const [yearList, setYearList] = useState([2023, 2022, 2021, 2020, 2019, 2018, 2017]); // Modify the list as needed
  const defaultYear = 2023; // Set the default year
  const [selectedYear, setSelectedYear] = useState(defaultYear);
  const [yearDropdownOpen, setYearDropdownOpen] = useState(false);

  return (
    <div>
      <div className="rent-roll-container">
        <div className="rent-roll-head">
          <h1>Rent Roll</h1>
          <div className="rent-roll-right">
            <div>
              <img
                src={filter}
                alt=""
              />
              <span>Filter</span>
            </div>
            <img
              src={action}
              alt=""
            />
          </div>
        </div>
        <CustomTable
          data={data}
          columns={columns}
        />
        <div className="deals-room-doc-page-nav">
          <div className="page-nav-cont">
            <img
              src={prevnav}
              alt=""
            />
            <span>2 of 10</span>
            <img
              src={fwdnav}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="rent-roll-card-cont">
        <RentRollCard
          title="Unit Types"
          imageSrc={unitType}
        />
        <RentRollCard
          title="Lease Types"
          imageSrc={leaseType}
        />
        <RentRollCard
          title="Ronovation Status"
          imageSrc={renovation}
        />
      </div>
      <div className="rent-roll-card-cont">
        <div className="rent-roll-card-B">
          <div className="roll-card-head">
            <h1>Top Tenants</h1>
            <img
              src={savebtn}
              alt=""
            />
          </div>
          <img
            id="card-img"
            src={tenants}
            alt=""
          />
        </div>
        <div className="rent-roll-card-B">
          <div className="roll-card-head">
            <h1>Monthly Statistics</h1>
            <img
              src={savebtn}
              alt=""
            />
          </div>
          <img
            id="card-img-B"
            src={statistics}
            alt=""
          />
        </div>
      </div>
      <div className="leasing-trend-cont">
        <div className="trend-head">
          <div className="trend-head-B">
            <h1>Leasing Trends</h1>
            <div>
              <img
                src={savebtn}
                alt=""
              />
              <DropDown
                data={mothList}
                selectedData={status}
                setSelectedData={setStatus}
                dropdownState={statusDA}
                dropdownSetter={setStatusDA}
                width="120px"
                margin="0"
              />
              <DropDown
                data={yearList}
                selectedData={selectedYear}
                setSelectedData={setSelectedYear}
                dropdownState={yearDropdownOpen}
                dropdownSetter={setYearDropdownOpen}
                width="90px"
                margin="0"
              />
              <div className="switch-btn trend-switch">
                {[1, 2].map((index) => (
                  <span
                    key={index}
                    onClick={() => setIsClicked(index)}
                    className={isClicked === index && "back"}
                  >
                    {index === 1 ? "Y" : "M"}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="trend-head-C">
            {[1, 2, 3, 4].map((index) => (
              <div
                key={index}
                className={navActive === index && "trend-active"}
                onClick={() => setNavActive(index)}
              >
                <img
                  src={index === 1 ? other : index === 2 ? otherexp : index === 3 ? bluedot : reddot}
                  alt=""
                />
                <span>{index === 1 ? "Studio" : index === 2 ? "1BR" : index === 3 ? "2BR" : "3BR"}</span>
              </div>
            ))}
          </div>
        </div>
        <img
          id="leasing-img"
          src={leasinggraph}
          alt=""
        />
      </div>

      <div className="leasing-trend-cont">
        <div className="trend-head">
          <div className="trend-head-B">
            <h1>Leasing Expiration Schedule</h1>
            <div>
              <img
                src={savebtn}
                alt=""
              />
              <DropDown
                data={mothList}
                selectedData={status}
                setSelectedData={setStatus}
                dropdownState={statusDA}
                dropdownSetter={setStatusDA}
                width="120px"
                margin="0"
              />
              <DropDown
                data={yearList}
                selectedData={selectedYear}
                setSelectedData={setSelectedYear}
                dropdownState={yearDropdownOpen}
                dropdownSetter={setYearDropdownOpen}
                width="90px"
                margin="0"
              />
            </div>
          </div>
          <div className="trend-head-C">
            {[1, 2, 3, 4].map((index) => (
              <div
                key={index}
                className={navActiveone === index && "trend-active"}
                onClick={() => setNavActiveone(index)}
              >
                <img
                  src={index === 1 ? other : index === 2 ? otherexp : index === 3 ? bluedot : reddot}
                  alt=""
                />
                <span>{index === 1 ? "Studio" : index === 2 ? "1BR" : index === 3 ? "2BR" : "3BR"}</span>
              </div>
            ))}
          </div>
        </div>
        <img
          id="leasing-img"
          src={leasingbar}
          alt=""
        />
      </div>
    </div>
  );
};

const CompData = () => {
  const columns = ["Metric", "LOW", "Mean", "Median", "High", ""];

  const data = [
    ["Current Asking Rent Per Unit	", "$1,461.00", "$3,053.43", "$2,852.00", "$8,068.00", tablegraph],
    ["Current Effective Rent Per Unit	-", "$1,410.35", "$3,053.43", "$2,852.00", "$7,795.91", tablegraph],
    ["Current Vacancy Rate	", "0.0%", "3.0%", "2.4%", "26.5%", tablegraph],
    ["Property Size (Unit)", "20.00", "99.67", "62.00", "1,107.00", tablegraph],
    ["Year Built	", 1890, 1942, 1925, 2019, tablegraph],
  ];
  const [navActive, setNavActive] = useState(1);

  return (
    <div>
      <div className="comp-summary-container">
        <div className="comp-summary-head">
          <h1>Lilypads Rent Comps Summary Table</h1>
          <div>
            <img
              src={bulb}
              alt=""
            />
            <span>Insights</span>
          </div>
        </div>
        <div className="comp-summary-body">
          <h1>Average values</h1>
          <div className="summary-body-card">
            <div>
              <h1>Size</h1>
              <span>48</span>
              <span>Units</span>
            </div>
            <div>
              <h1>Year Built</h1>
              <span>1926</span>
              <span>Average</span>
            </div>
            <div>
              <h1>Tax Ammount</h1>
              <div>
                <span>$142,294</span>
                <img
                  src={arrow}
                  alt=""
                />
              </div>
              <span>Average</span>
            </div>
            <div>
              <h1>Sale Price</h1>
              <div>
                <span>$142,294</span>
                <img
                  src={arrow}
                  alt=""
                />
              </div>
              <span>Average</span>
            </div>
            <div>
              <h1>Rent</h1>
              <div>
                <span>$142,294</span>
                <img
                  src={arrow}
                  alt=""
                />
              </div>
              <span>Per Unit</span>
            </div>
            <div>
              <h1>New Construction</h1>
              <span>54</span>
              <span>Projects</span>
            </div>
          </div>
          <CustomTable
            data={data}
            columns={columns}
            classNames={"comptable"}
          />
        </div>
      </div>
      <div className="comp-summary-container">
        <div className="comp-summary-head">
          <h1>Lilypads Sales Comps Summary Table</h1>
          <div>
            <img
              src={bulb}
              alt=""
            />
            <span>Insights</span>
          </div>
        </div>
        <div className="comp-summary-body">
          <CustomTable
            data={data}
            columns={columns}
            classNames={"comptable"}
          />
        </div>
      </div>
      <div className="comp-summary-container">
        <div className="comp-summary-head">
          <h1>New Construction Comps Summary Table</h1>
          <div>
            <img
              src={bulb}
              alt=""
            />
            <span>Insights</span>
          </div>
        </div>
        <div className="comp-summary-body">
          <CustomTable
            data={data}
            columns={columns}
            classNames={"comptable"}
          />
        </div>
      </div>
      <div className="comp-summary-container">
        <div className="comp-summary-head">
          <h1>New Construction Comps Summary Table</h1>
          <PrimaryButtonSvgFirst
            name="Upload File"
            svgPath={uploadbtn}
            classNames="uploadbtn"
          />
        </div>
        <div className="select-doc comp-doc">
          <img
            src={upload}
            alt=""
          />
          <InputBox type={"file"} />

          <p>Supported file formats: PDF. Max file size 250 MB</p>
        </div>
      </div>
      <div className="comp-card-cont">
        <RentRollCard
          title="Fair Investment  Market"
          imageSrc={fairmarket}
        />
        <div className="comp-card">
          <div className="comp-card-head">
            <h1>High Opportunity</h1>
            <img
              src={savebtn}
              alt=""
            />
          </div>
          <img
            id="card-img"
            src={highopportunity}
            alt=""
          />
        </div>
        <RentRollCard
          title="Reviews Influence"
          imageSrc={review}
        />
      </div>
      <div className="compdata_lower">
        <div className="chartbox chartbox_1">
          <div className="chartbox_header">
            <p>Average Market vs NER</p>
          </div>
          <div className="chart_wrapper">
            <img
              src={chart1}
              alt=""
            />
          </div>
        </div>
        <div className="chartbox chartbox_1">
          <div className="chartbox_header">
            <p>Average Market vs Base Rent</p>
          </div>
          <div className="chart_wrapper">
            <img
              src={chart1}
              alt=""
            />
          </div>
        </div>

        <div className="similar_wrapper">
          <p>Similar Assets Nearby</p>
          <div>
            <img
              src={map}
              alt=""
            />
            <div className="comparision_box">
              <p className="sort">
                <span>Sort:</span>Price
                <img
                  style={{marginLeft: "12px"}}
                  src={ddarrows}
                  alt=""
                />
              </p>

              <div className="comparision_box_row">
                <img
                  src={cover}
                  alt=""
                />
                <div className="right">
                  <div className="right_upper">
                    <p>Property Name</p>
                    <span>
                      164 S. Carson Court Newport <br></br> News, VA 23601
                    </span>
                  </div>
                  <div className="right_lower">
                    <p>$1,000,000</p>
                    <div>
                      <img
                        src={bookmark}
                        alt=""
                      />
                      <img
                        src={compare}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="comparision_box_row">
                <img
                  src={cover}
                  alt=""
                />
                <div className="right">
                  <div className="right_upper">
                    <p>Property Name</p>
                    <span>
                      164 S. Carson Court Newport <br></br> News, VA 23601
                    </span>
                  </div>
                  <div className="right_lower">
                    <p>$1,000,000</p>
                    <div>
                      <img
                        src={bookmark}
                        alt=""
                      />
                      <img
                        src={compare}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="comparision_box_row">
                <img
                  src={cover}
                  alt=""
                />
                <div className="right">
                  <div className="right_upper">
                    <p>Property Name</p>
                    <span>
                      164 S. Carson Court Newport <br></br> News, VA 23601
                    </span>
                  </div>
                  <div className="right_lower">
                    <p>$1,000,000</p>
                    <div>
                      <img
                        src={bookmark}
                        alt=""
                      />
                      <img
                        src={compare}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <button className="add_compare">
                <img
                  src={add}
                  alt=""
                />
                Add Comparision
              </button>
            </div>
          </div>
        </div>

        <div
          style={{marginLeft: "0px"}}
          className="compare"
        >
          <div className="view_asset_wrapper">
            <ViewAsset
              title={"Waltamwoods Appartments"}
              background={"#7D55C7"}
            />
            <ViewAsset
              title={"Dome of the Rock"}
              background={"#6FCF97"}
            />
            <ViewAsset
              title={"Space Needle"}
              background={"#7D55C7"}
            />
            <ViewAsset
              title={"Lloyds Building"}
              background={"#ED962D"}
            />
          </div>
          <div className="nav_wrapper">
            <div className="compare_nav">
              <div className=" deals-room-nav asset-nav ass-nav">
                <span
                  className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setNavActive(1);
                  }}
                >
                  Lotus Temple{" "}
                </span>
                <span
                  className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setNavActive(2);
                  }}
                >
                  Dome of the Rock
                </span>
                <span
                  className={navActive === 3 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setNavActive(3);
                  }}
                >
                  Space Needle
                </span>
                <span
                  className={navActive === 4 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setNavActive(4);
                  }}
                >
                  Lloyd's Building
                </span>
              </div>{" "}
            </div>

            <div
              style={{width: "100%"}}
              className="chart"
            >
              <img
                src={StarChart}
                alt=""
              />
              <div>
                <h2>How does lotus Temple Compare to others?</h2>
                <p>Twice cheaper</p>
                <p>Better Performance</p>
              </div>
              <img
                src={InsightsChart}
                alt=""
              />
            </div>
          </div>

          <div className="property_features_wrapper">
            <div className="property_features">
              <p>Property Features</p>
              <img
                src={ArrowDown}
                alt=""
              />
            </div>
            <div>
              <div
                style={{justifyContent: "flex-start", gap: "20px"}}
                className="property_features_box_wrapper"
              >
                <PropertyFeaturesBox1 />
                <PropertyFeaturesBox2 />
              </div>
              <PropertyFeaturesBox3 />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ViewAsset = ({title, banner, background}) => {
  return (
    <div className="view_asset">
      <div
        style={{background: background}}
        className="color_line"
      ></div>
      <div className="view_asset_box">
        <img
          className="bg"
          src={cover}
          alt=""
        />
        {banner && (
          <img
            className="img_right"
            src={shortAdv}
            alt=""
          />
        )}
        <div>
          <p className="name">{title}</p>
          <p className="text">164 S. Carson Court </p>
          <p className="text">Newport News, VA 23601</p>
        </div>
        <div className="price">
          <p>$/sqm</p>
          <span>Sale</span>
        </div>

        <div className="btn">
          <button>
            <img
              src={view}
              alt=""
            />
            View Asset
          </button>
          <img
            src={Delete}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const PropertyFeaturesBox1 = () => {
  return (
    <div className="property_features_box">
      <div className="box_header">
        <div>
          <p>Building Area</p>
          <span>
            <img
              src={info}
              alt=""
            />
            Usually, the more the better.
          </span>
        </div>
        <img
          src={house}
          alt=""
        />
      </div>
      <div className="box_row box1">
        <div>
          <p>Lotus Temple</p>
          <span>7,670</span>
        </div>
        <div class="progressbar">
          <div class="bar bar1"></div>
        </div>
      </div>
      <div className="box_row box1">
        <div className="box1">
          <p>Lotus Temple</p>
          <span>7,670</span>
        </div>
        <div class="progressbar">
          <div class="bar bar1"></div>
        </div>
      </div>
      <div className="box_row box1">
        <div>
          <p>Lotus Temple</p>
          <span>7,670</span>
        </div>
        <div class="progressbar">
          <div class="bar bar1"></div>
        </div>
      </div>
      <div className="box_row box1">
        <div>
          <p>Lotus Temple</p>
          <span>7,670</span>
        </div>
        <div class="progressbar">
          <div class="bar bar1"></div>
        </div>
      </div>
    </div>
  );
};

const PropertyFeaturesBox2 = () => {
  return (
    <div className="property_features_box">
      <div className="box_header">
        <div>
          <p>Own power substation</p>
          <span>
            <img
              src={info}
              alt=""
            />
            If presense, than great!
          </span>
        </div>
      </div>
      <div className="box_row box2">
        <div className="">
          <img
            src={cross}
            alt=""
          />
          <p>Lotus Temple</p>
        </div>
      </div>
      <div className="box_row box2">
        <div className="">
          <img
            src={tick}
            alt=""
          />
          <p>Lotus Temple</p>
        </div>
      </div>
      <div className="box_row box2">
        <div className="">
          <img
            src={cross}
            alt=""
          />
          <p>Lotus Temple</p>
        </div>
      </div>
      <div className="box_row box2">
        <div className="">
          <img
            src={tick}
            alt=""
          />
          <p>Lotus Temple</p>
        </div>
      </div>
    </div>
  );
};

const PropertyFeaturesBox3 = () => {
  return (
    <div className="property_features_box">
      <div className="box_header">
        <div>
          <p>Zoning</p>
          <span>
            <img
              src={info}
              alt=""
            />
            There's no clear advantage.
          </span>
        </div>
        <img
          src={zoning}
          alt=""
        />
      </div>
      <div className="box_row box1">
        <div>
          <p>Lotus Temple</p>
          <span>C-2</span>
        </div>
      </div>
      <div className="box_row box1">
        <div className="box1">
          <p>Lotus Temple</p>
          <span>C-2</span>
        </div>
      </div>
      <div className="box_row box1">
        <div>
          <p>Lotus Temple</p>
          <span>C-2</span>
        </div>
      </div>
      <div className="box_row box1">
        <div>
          <p>Lotus Temple</p>
          <span>C-2</span>
        </div>
      </div>
    </div>
  );
};

const ReusableUnitCard = ({data, blueprint, pricetag, bed, bath}) => (
  <div className="unit-card">
    <div className="unit-card-inner">
      <div>
        <h1 style={{display: "flex", alignItems: "center", gap: "10px"}}>
          {data?.Studio ? (
            "Studio"
          ) : (
            <>
              BR {data?.br}{" "}
              <img
                src={bed}
                alt=""
              />
              &nbsp; &nbsp;BA {data?.ba}{" "}
              <img
                src={bath}
                alt=""
              />
            </>
          )}
        </h1>
      </div>

      <div className="unit-card-price">
        <div>
          <span>Units</span>
          <span>{data?.units}</span>
          <span>20% of total</span>
        </div>
        <div></div>
        <div>
          <span>Unit Net Income</span>
          <span>{data?.unit_net_income}</span>
          <span>11% of total</span>
        </div>
      </div>
      <div className="unit-card-bottom">
        <div>
          <img
            src={blueprint}
            alt=""
          />
          <div>
            <p>Area, SF</p>
            <span>{data?.area_sf}</span>
          </div>
        </div>
        <div>
          <img
            src={pricetag}
            alt=""
          />
          <div>
            <p>Rent</p>
            <span>{data?.rent}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const ReusableUnitCardB = ({contentArray, units, unitNetIncome, area, rent}) => (
  <div className="unit-card">
    <div className="unit-card-head">
      {contentArray.map((content, index) => (
        <div key={index}>
          <h1>{content.title}</h1>
          <img
            src={content.icon}
            alt=""
          />
        </div>
      ))}
    </div>

    <div className="unit-card-price">
      <div>
        <span>Units</span>
        <span>{units}</span>
        <span>{contentArray[0].percentage} of total</span>
      </div>
      <div></div>
      <div>
        <span>Unit Net Income</span>
        <span>{unitNetIncome}</span>
        <span>{contentArray[1].percentage} of total</span>
      </div>
    </div>
    <div className="unit-card-bottom">
      <div>
        <img
          src={contentArray[0].image}
          alt=""
        />
        <div>
          <p>Area, SF</p>
          <span>{area}</span>
        </div>
      </div>
      <div>
        <img
          src={contentArray[1].image}
          alt=""
        />
        <div>
          <p>Rent</p>
          <span>{rent}</span>
        </div>
      </div>
    </div>
  </div>
);

const RentRollCard = ({title, imageSrc}) => (
  <div className="rent-roll-card">
    <div className="roll-card-head">
      <h1>{title}</h1>
      <img
        src={savebtn}
        alt=""
      />
    </div>
    <img
      id="card-img"
      src={imageSrc}
      alt=""
    />
  </div>
);
