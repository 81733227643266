import React from "react";
import ReactECharts from "echarts-for-react";

const LineChartNew = ({ data }) => {
  // Check if data is available
  

  const option = {
    title: {
      show: false,
      text: "Log Axis",
      left: "center",
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : ${c}",
    },
    legend: {
      left: "left",
      show: true,
    },
    xAxis: {
      boundaryGap: false,
      show: true,
      type: "category",
      name: "x",
      splitLine: { show: false },
      data: data?.date || [], // Check if data.date is available
    },
    grid: {
      show: true,
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    yAxis: {
      show: true,
      type: "value",
      name: "y",
      position: "right",
      
    },
    series: [
      {
        name: "Log2",
        type: "line",
        symbol: "none", // Remove points on the line
        lineStyle: {
          normal: {
            color: "#724bbf", // Custom line color
            width: 2, // Custom line width
          },
        },
        data: data?.data || [], // Check if data.data is available
        areaStyle: {
          normal: {
            color: "rgb(91, 143, 211, 0.2)", // Set area color with reduced opacity
          },
        },
      },
    ],
  };

  return (
    <ReactECharts
      option={option}
    />
  );
};

export default LineChartNew;
