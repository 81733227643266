import React, {useState, useEffect, useRef} from "react";
import AssignTaskDialog from "./Popup/AssignTaskDialog";
import CustomSchedule from "./ScheduleFollowUp/ScheduleFollowUp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import "./ThreeDotMenu.scss";
import {useSelector} from "react-redux";
import Endpoint from "../../utils/Endpoint";
import {toast} from "react-toastify";
import {TaskAssignedToast, OpenAIErrorToast} from "../../components/Tostify/SuccessToast/SuccessTostify";

const ThreeDotMenu = ({onSelect, onAssignToTeam, selectedText, handleParagraphClick, description, header, messageid, fetchStatuses, text_id}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [scheduleOpen, setScheduleOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const menuRef = useRef();
  let {selectedProperty} = useSelector((state) => state?.portfolio);
  const {idToken, userInformation} = useSelector((state) => state.auth);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setMenuOpen((prev) => !prev);

    if (selectedText && handleParagraphClick) {
      handleParagraphClick(description, header);
    } else if (onSelect) {
      onSelect(description, header);
    }
  };

  const handleOptionClick = (option) => {
    if (option === "Schedule Follow Up") {
      setScheduleOpen(true);
    } else if (option === "Assign to Team") {
      setDialogOpen(true);
    } else if (option === "Implement") {
      handleImplementClick();
    } else {
      onSelect(option);
    }
    setMenuOpen(false);
  };

  const handleImplementClick = async () => {
    if (!selectedText || !selectedText.id) {
      toast.error(
        <OpenAIErrorToast
          errorMessage="No recommendation selected!"
          onClose={() => toast.dismiss()}
        />,
        {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
        },
      );
      return;
    }

    setLoading(true);
    try {
      const config = {
        headers: {
          "auth-token": idToken,
        },
      };

      const queryParams = {
        Message_id: messageid,
        text_id: selectedText.id,
        asset_id: selectedProperty?._id,
      };

      const payload = {
        text_data: JSON.stringify({
          header: selectedText.header,
          description: selectedText.text,
        }),
        taskName: selectedText.header,
        taskDesc: selectedText.text,
        desc: selectedText.text,
        date: new Date().toISOString(),
        assetType: selectedProperty?.type || "Tours",
        status: "Implemented",
      };

      const response = await axios.post(`${Endpoint.Implement}?Message_id=${queryParams.Message_id}&text_id=${queryParams.text_id}&asset_id=${queryParams.asset_id}`, payload, config);

      if (response?.data?.success) {
        toast.success(
          <TaskAssignedToast
            message="Task assigned successfully!"
            deadline={new Date().toISOString()}
            selectedUsers={[{name: userInformation?.name, surname: userInformation?.surname}]}
            onViewTasks={() => {
              fetchStatuses();
              window.location.href = `/${userInformation._id}/tasks`;
            }}
          />,
          {
            position: "top-right",
            autoClose: false,
            hideProgressBar: true,
          },
        );

        if (response?.data?.text_data) {
          try {
            const parsedTextData = JSON.parse(response.data.text_data);
            response.data.text_data = {
              header: parsedTextData.header,
              description: parsedTextData.description,
            };
          } catch (parseError) {}
        }

        fetchStatuses();
      } else {
        toast.error(
          <OpenAIErrorToast
            errorMessage="Failed to mark as Implemented."
            onClose={() => toast.dismiss()}
          />,
          {
            position: "top-right",
            autoClose: false,
            hideProgressBar: true,
          },
        );
      }
    } catch (error) {
      toast.error(
        <OpenAIErrorToast
          errorMessage={error.response?.data?.message || "An error occurred. Please try again."}
          onClose={() => toast.dismiss()}
        />,
        {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
        },
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="threedotmenu-container"
      onClick={handleMenuClick}
    >
      <IconButton onClick={handleMenuClick}>{loading ? <CircularProgress size={24} /> : <MoreVertIcon />}</IconButton>

      {menuOpen && (
        <div
          ref={menuRef}
          className={`threedotmenu-menu ${menuOpen ? "open" : ""}`}
        >
          {["Implement", "Schedule Follow Up", "Assign to Team", "View Detailed Report"]?.map((option) => (
            <button
              key={option}
              className={option === "Implement" ? "threedotmenu-active" : ""}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </button>
          ))}
        </div>
      )}

      {dialogOpen && (
        <AssignTaskDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onAssign={(assignedUsers) => setDialogOpen(false)}
          selectedText={selectedText}
          messageid={messageid}
          text_id={selectedText.id}
          fetchStatuses={fetchStatuses}
        />
      )}

      {scheduleOpen && (
        <CustomSchedule
          open={scheduleOpen}
          onClose={() => setScheduleOpen(false)}
          selectedText={selectedText}
          messageid={messageid}
          text_id={text_id}
          fetchStatuses={fetchStatuses}
        />
      )}
    </div>
  );
};

export default ThreeDotMenu;
