import React from "react";
import "./CompanyDocument.scss";
import Table from "../../../components/reusableComponents/Table/ReactTable";
import { Button } from "react-scroll";

const CompanyDocument = () => {
  const headers = [];
  const tableData = [
    { Location: "Incorporation document", Occupation: "1 Document", Button: "Edit" },
    { Location: "Bylaws", Occupation: "1 Document", Button: "Edit" },
    { Location: "State qualification", Occupation: "1 Document ", Button: "Edit" },
  ];
  return (
    <div className="CompanyDocument">
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <h1>Company Document</h1>
      </div>
      <div className="Container-CompanyDocument">
        <div className="Container-CompanyDocument-box">
          <div className="Container-CompanyDocument-box-data">
            <h1>INCORPORATION DOCUMENT</h1>
            <h1>SECURITIES FILLINGS</h1>
            <h1>CLOSING VOLUMES</h1>
            <h1>MINUTE BOOK</h1>
            <h1>DISCLOSURES</h1>
            <h1>OTHER</h1>
          </div>
          <div className="Container-CompanyDocument-secondbox">
            <div className="Container-CompanyDocument-secondbox-data">
              <h1>Date Document</h1>
            </div>
          </div>
          <div>
            <Table headers={headers} tabledata={tableData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDocument;
