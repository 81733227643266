import React, {useState} from "react";
import "./Transaction.scss";
import CashFlowHistoryChart from "./CashFlowHistoryChart/CashFlowHistoryChart";
import ReactTable from "../../../../../reusuableComponents/ReactTable/ReactTable";
import {useNavigate} from "react-router-dom";
import cloudIcon from "../../../../../../../assets/investor-pipeline/icons/cloud_download_btn.svg";
import printIcon from "../../../../../../../assets/investor-pipeline/icons/print_icon_gray.svg";
import trendingIcon from "../../../../../../../assets/investor-pipeline/icons/trendingIcon_green.svg";
import BluePieChart from "./BluePieChart";

// import Transection_overview from "../../../../../../../assets/svg/Transection_overview.svg";

const Transaction = () => {
  const [navActive, setNavActive] = useState(1);
  return (
    <div className="transaction_wrapper">
      <div className="topHeader_nav">
        <div className="deals-room-nav gp_nav asset-nav ass-nav">
          <span
            className={navActive === 1 ? "deals-room-nav-active ut-nav-active" : ""}
            onClick={() => {
              setNavActive(1);
            }}
          >
            Transaction Overview
          </span>
          <span
            className={navActive === 2 ? "deals-room-nav-active ut-nav-active" : ""}
            onClick={() => {
              setNavActive(2);
            }}
          >
            Captial Calls
          </span>
          <span
            className={navActive === 3 ? "deals-room-nav-active ut-nav-active" : ""}
            onClick={() => {
              setNavActive(3);
            }}
          >
            Contributions
          </span>
          <span
            className={navActive === 4 ? "deals-room-nav-active ut-nav-active" : ""}
            onClick={() => {
              setNavActive(4);
            }}
          >
            Distributions
          </span>
          <span
            className={navActive === 5 ? "deals-room-nav-active ut-nav-active" : ""}
            onClick={() => {
              setNavActive(5);
            }}
          >
            Net asset value
          </span>
          <span
            className={navActive === 6 ? "deals-room-nav-active ut-nav-active" : ""}
            onClick={() => {
              setNavActive(6);
            }}
          >
            Ownership
          </span>
        </div>
      </div>
      <div className="main_content_wrapper">
        {navActive === 1 && <TransactionOverview />}
        {navActive === 2 && <CapitalCallTable />}
        {navActive === 3 && <ContributionTable />}
        {navActive === 4 && <DistributionsTable />}
        {navActive === 5 && <NetAssetValueTable />}
        {navActive === 6 && <OwnershipTable />}
      </div>
    </div>
  );
};

export default Transaction;

const TransactionOverview = () => {
  return (
    <div className="transaction_overview_wrapper">
      <div className="row1_section">
        <div className="infobox">
          <label
            htmlFor=""
            className="infobox_header"
          >
            Commitment
          </label>
          <p className="infobox_value">$1,350,000</p>
        </div>
        <div className="infobox">
          <label
            htmlFor=""
            className="infobox_header"
          >
            Ownership
          </label>
          <p className="infobox_value">100.00%</p>
        </div>
        <div className="infobox">
          <label
            htmlFor=""
            className="infobox_header"
          >
            Contributions
          </label>
          <p className="infobox_value">$1,350,000</p>
        </div>

        <div className="infobox">
          <label
            htmlFor=""
            className="infobox_header"
          >
            Distributions
          </label>
          <p className="infobox_value">
            $712,083
            <span className="value_caption">
              <p>$204,719.00 </p>Return of capital
            </span>
          </p>
        </div>
        <div className="infobox">
          <label
            htmlFor=""
            className="infobox_header"
          >
            Net asset value
          </label>
          <p className="infobox_value">$1,500,000</p>
        </div>
        <div className="infobox">
          <label
            htmlFor=""
            className="infobox_header"
          >
            Equity balance
          </label>
          <p className="infobox_value">$1,145,281</p>
        </div>
      </div>
      <div className="row2_section">
        <CashFlowHistoryChart />
      </div>
    </div>
  );
};

const CapitalCallTable = () => {
  const data = React.useMemo(
    () => [
      {
        date_due: "May 30, 2018",
        send_date: "-",
        description: "Capital Call #3",
        amount_called: "$200,000",
        receivement_status: "Not Yet Received",
        amount_received: "",
      },
      {
        date_due: "May 30, 2018",
        send_date: "-",
        description: "Capital Call #3",
        amount_called: "$450,000",
        receivement_status: "Received",
        amount_received: "$100,000,000",
      },
      {
        date_due: "May 30, 2018",
        send_date: "-",
        description: "Capital Call #3",
        amount_called: "$850,000",
        receivement_status: "Not Yet Received",
        amount_received: "",
      },
      {
        date_due: "May 30, 2018",
        send_date: "-",
        description: "Capital Call #3",
        amount_called: "$80,000",
        receivement_status: "Received",
        amount_received: "$100,000,000",
      },
      {
        date_due: "May 30, 2018",
        send_date: "-",
        description: "Capital Call #3",
        amount_called: "$400,000",
        receivement_status: "Not Yet Received",
        amount_received: "",
      },
    ],
    [],
  );

  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        Header: "Date Due",
        accessor: "date_due",
      },
      {
        Header: "Send Date",
        accessor: "send_date",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Amount called",
        accessor: "amount_called",
      },
      {
        Header: "Receivement Status",
        accessor: "receivement_status",
        Cell: ({row}) => (
          <div className="receivement_status_cell">
            <div className="status_wrapper">
              <div className={`status_box ${row.original.receivement_status === "Not Yet Received" ? "not_received" : "received"}`}>
                {row.original.receivement_status === "Not Yet Received" ? "Not Yet Received" : "Received"}
              </div>
            </div>
            {row.original.receivement_status === "Received" ? row.original.date_due : ""}
          </div>
        ),
      },
      {
        Header: "Amount received",
        accessor: "amount_received",
      },
    ],
    [],
  );

  const totalData = React.useMemo(
    () => [
      {
        amount_called: "$1,980,000",
        amount_received: "$1,300,000",
      },
    ],
    [],
  );

  return (
    <div className="capitalCall_table_wrapper">
      <div className="table_heading">
        <div className="table_heading_left">
          <p>Capital Calls</p>
        </div>
        <div className="table_heading_right">
          <img
            src={printIcon}
            alt="print_icon"
          />
          <img
            src={cloudIcon}
            alt="download_icon"
          />
        </div>
      </div>
      <ReactTable
        columns={columns}
        data={data || []}
        totalData={totalData || []}
      />
    </div>
  );
};

const ContributionTable = () => {
  const data = React.useMemo(
    () => [
      {
        date: "May 30, 2018",
        type: "Contribution",
        description: "Capital Call #3",
        amount_received: "$100,000",
      },
    ],
    [],
  );

  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Amount received",
        accessor: "amount_received",
      },
    ],
    [],
  );

  const totalData = React.useMemo(
    () => [
      {
        amount_received: "$1,300,000",
      },
    ],
    [],
  );

  return (
    <div className="contribution_table_wrapper">
      <div className="table_heading">
        <div className="table_heading_left">
          <p>Contribution</p>
        </div>
        <div className="table_heading_right">
          <img
            src={printIcon}
            alt="print_icon"
          />
          <img
            src={cloudIcon}
            alt="download_icon"
          />
        </div>
      </div>
      <ReactTable
        columns={columns}
        data={data || []}
        totalData={totalData || []}
      />
    </div>
  );
};
const DistributionsTable = () => {
  const data = React.useMemo(
    () => [
      {
        distribution_date: "Jan 5, 2015",
        send_date: "-",
        amount: "$100,000",
      },
      {
        distribution_date: "Jan 5, 2015",
        send_date: "-",
        amount: "$100,000",
      },
      {
        distribution_date: "Jan 5, 2015",
        send_date: "-",
        amount: "$100,000",
      },
      {
        distribution_date: "Jan 5, 2015",
        send_date: "-",
        amount: "$100,000",
      },
      {
        distribution_date: "Jan 5, 2015",
        send_date: "-",
        amount: "$100,000",
      },
    ],
    [],
  );

  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        Header: "Distribution Date",
        accessor: "distribution_date",
      },
      {
        Header: "Send Date",
        accessor: "send_date",
      },

      {
        Header: "Amount",
        accessor: "amount",
      },
    ],
    [],
  );

  const totalData = React.useMemo(
    () => [
      {
        amount: "$50,000.00",
      },
    ],
    [],
  );

  return (
    <div className="contribution_table_wrapper">
      <div className="table_heading">
        <div className="table_heading_left">
          <p>Distributions</p>
        </div>
        <div className="table_heading_right">
          <img
            src={printIcon}
            alt="print_icon"
          />
          <img
            src={cloudIcon}
            alt="download_icon"
          />
        </div>
      </div>
      <ReactTable
        columns={columns}
        data={data || []}
        totalData={totalData || []}
      />
    </div>
  );
};
const NetAssetValueTable = () => {
  const data = React.useMemo(
    () => [
      {
        date: "Jan 5, 2015",
        description: "2019 NAV",
        amount: "$1,500,000.00",
      },
    ],
    [],
  );

  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Description",
        accessor: "description",
      },

      {
        Header: "Amount",
        accessor: "amount",
      },
    ],
    [],
  );

  const subHeaders = React.useMemo(
    () => [
      {
        amount: "$50,000.00",
      },
    ],
    [],
  );

  return (
    <div className="contribution_table_wrapper">
      <div className="table_heading">
        <div className="table_heading_left">
          <p>Net asset value</p>
        </div>
        <div className="table_heading_right">
          <img
            src={printIcon}
            alt="print_icon"
          />
          <img
            src={cloudIcon}
            alt="download_icon"
          />
        </div>
      </div>
      <ReactTable
        columns={columns}
        data={data || []}
      />
    </div>
  );
};
const OwnershipTable = () => {
  const data = React.useMemo(
    () => [
      {
        date: "Jan 5, 2015",
        event: "2019 NAV",
        commitment_total: "$1,500,000.00",
      },
    ],
    [],
  );

  const dataSecondClosing = React.useMemo(
    () => [
      {
        date: "Jan 5, 2015",
        initial_commitment: "Initial commitment",
        ownership_percentage: "$1,250,000.00",
      },
    ],
    [],
  );

  const dataFirstClosing = React.useMemo(
    () => [
      {
        date: "Jan 5, 2015",
        initial_commitment: "Initial commitment",
        ownership_percentage: "$1,250,000.00",
      },
    ],
    [],
  );

  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "event",
        accessor: "event",
        Cell: ({row}) => (
          <div className="event_cell">
            <div className="status_wrapper">
              <img
                src={trendingIcon}
                alt="trending_icon"
              />
              {row.original.commitment_total}
            </div>
          </div>
        ),
      },

      {
        Header: "Commitment Total",
        accessor: "commitment_total",
      },
    ],
    [],
  );
  const columnsSecondClosing = React.useMemo(
    () => [
      {
        Header: "2nd Closing",
        accessor: "date",
      },
      {
        Header: "",
        accessor: "initial_commitment",
      },
      {
        Header: () => (
          <div className="header_Cell">
            <p>100%</p>
          </div>
        ),
        accessor: "ownership_percentage",
      },
    ],
    [],
  );
  const columnsFirstClosing = React.useMemo(
    () => [
      {
        Header: "1st Closing",
        accessor: "date",
      },
      {
        Header: "",
        accessor: "initial_commitment",
      },
      {
        Header: () => (
          <div className="header_Cell">
            <p>100%</p>
          </div>
        ),
        accessor: "ownership_percentage",
      },
    ],
    [],
  );

  return (
    <div className="ownership_table_wrapper">
      <div className="table_wrapper_left">
        <div className="table_heading">
          <div className="table_heading_left">
            <p>Net asset value</p>
          </div>
          <div className="table_heading_right">
            <img
              src={printIcon}
              alt="print_icon"
            />
            <img
              src={cloudIcon}
              alt="download_icon"
            />
          </div>
        </div>
        <ReactTable
          columns={columns}
          data={data || []}
        />
        <ReactTable
          columns={columnsSecondClosing}
          data={dataSecondClosing || []}
        />
        <ReactTable
          columns={columnsFirstClosing}
          data={dataFirstClosing || []}
        />
      </div>
      <BluePieChart />
    </div>
  );
};
