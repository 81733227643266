import React, {useEffect, useState} from "react";
import Projects from "./Projects";
import ProjectList from "./ProjectList";
import {DndProvider, useDrag, useDrop} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import update from "immutability-helper";
import "./Project.scss";
import add from "../../assets/svg/add.svg";
import arrowup from "../../assets/Image/assetDiscovery/arrow_down.svg";
import eye from "../../assets/Image/assetDiscovery/eye.png";
import document from "../../assets/svg/document.svg";
import chekclist from "../../assets/svg/tick-square.svg";
import chart from "../../assets/svg/project-sidebar-chart.svg";
import person from "../../assets/svg/profileAvatar.png";
import search from "../../assets/svg/search.svg";
import list from "../../assets/dealsroom/svg/listing.svg";
import board from "../../assets/svg/board.svg";
import addGray from "../../assets/svg/add-gray.svg";
import attachment from "../../assets/svg/attachment.svg";
import settings from "../../assets/svg/settings.svg";
import img from "../../assets/Image/assetDiscovery/general-bg.png";
import listActive from "../../assets/svg/list-active.svg";
import boardActive from "../../assets/svg/board-active.svg";
import Document from "./Document";

function ProjectsAndCalendar({columns, setColumns, projects, setProjects, selectedColumn, setSelectedColumn, handleProject, openTaskModal, closeTaskModal}) {
  const [viewBoard, setViewBoard] = useState("board");
  const [menu, setMenu] = useState(1);
  const handleView = (view) => {
    setViewBoard(view);
  };

  const handleMenu = (data) => {
    setMenu(data);
  };

  if (!columns || !projects) {
    return <p>Loading or No Data Available</p>;
  }

  const addNewProjectColumn = () => {
    const newColumnName = `New Column ${columns.length + 1}`;
    const newColumn = {name: newColumnName, border: "#ffffff", projects: []};

    setColumns((prevColumns) => [newColumn, ...prevColumns]);
  };

  const handleRenameColumn = (columnId, newName) => {
    const updatedColumns = columns.map((column) => (column.name === columnId ? {...column, name: newName} : column));

    updatedColumns.forEach((column) => {
      column.projects = column.projects.map((project) => (project.status === columnId ? {...project, status: newName} : project));
    });

    const updatedProjects = projects.map((project) => (project.status === columnId ? {...project, status: newName} : project));

    setColumns(updatedColumns);
    setProjects(updatedProjects);
    setSelectedColumn(null);
  };

  const handleDeleteColumn = (columnId) => {
    const updatedColumns = columns.filter((column) => column.name !== columnId);

    const deletedColumnProjects = columns.find((column) => column.name === columnId)?.projects || [];

    const updatedProjects = projects.filter((project) => !deletedColumnProjects.includes(project));

    setColumns(updatedColumns);
    setProjects(updatedProjects);
    setSelectedColumn(null);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const {source, destination} = result;
    const draggedProject = columns.find((column) => column.name === source.droppableId).projects[source.index];
    const updatedColumns = [...columns];

    updatedColumns.forEach((column) => {
      if (column.name === source.droppableId) {
        column.projects.splice(source.index, 1);
      }
    });

    updatedColumns.forEach((column) => {
      if (column.name === destination.droppableId) {
        column.projects.splice(destination.index, 0, {...draggedProject, status: column.name});
      }
    });

    setColumns(updatedColumns);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="projects">
        <ProjectSidebar
          menu={menu}
          handleMenu={handleMenu}
          handleProject={handleProject}
        />
        {menu === 1 ? (
          <div className="project_main">
            <ProjectHeader
              addNewProjectColumn={addNewProjectColumn}
              handleView={handleView}
              viewBoard={viewBoard}
            />

            {viewBoard != "board" ? (
              <ProjectList
                selectedColumn={selectedColumn}
                setSelectedColumn={setSelectedColumn}
                openTaskModal={openTaskModal}
                columns={columns}
                setColumns={setColumns}
                handleDeleteColumn={handleDeleteColumn}
                handleRenameColumn={handleRenameColumn}
              />
            ) : (
              <Projects
                selectedColumn={selectedColumn}
                setSelectedColumn={setSelectedColumn}
                openTaskModal={openTaskModal}
                onDragEnd={onDragEnd}
                columns={columns}
                setColumns={setColumns}
                projects={projects}
                setProjects={setProjects}
                handleDeleteColumn={handleDeleteColumn}
                handleRenameColumn={handleRenameColumn}
              />
            )}
          </div>
        ) : (
          <Document />
        )}
      </div>
    </DndProvider>
  );
}

export default ProjectsAndCalendar;

const ProjectHeader = ({handleView, viewBoard, addNewProjectColumn}) => {
  return (
    <div className="project_header">
      <div className="header_left">
        <h2>Asset Tour</h2>
        <img
          src={search}
          alt=""
        />
      </div>
      <div className="header_right">
        <div
          className={`btn btn_list ${viewBoard === "list" && "active"}`}
          onClick={() => handleView("list")}
        >
          {viewBoard === "list" ? (
            <img
              src={listActive}
              alt=""
            />
          ) : (
            <img
              src={list}
              alt=""
            />
          )}
          List
        </div>
        <div
          className={`btn ${viewBoard === "board" && "active"}`}
          onClick={() => handleView("board")}
        >
          {viewBoard === "board" ? (
            <img
              src={boardActive}
              alt=""
            />
          ) : (
            <img
              src={board}
              alt=""
            />
          )}
          Board
        </div>
        <button onClick={addNewProjectColumn}>
          <img
            src={add}
            alt=""
          />
          New Board
        </button>
      </div>
    </div>
  );
};

const ProjectSidebar = ({handleMenu, menu, handleProject}) => {
  return (
    <div className="project_sidebar">
      <div
        className="project_sidebar_header"
        onClick={handleProject}
      >
        <img
          style={{rotate: "90deg"}}
          src={arrowup}
          alt=""
        />
        <h3>Project & Calendar</h3>
      </div>
      <div className="project_sidebar_details">
        <div className="task_sidebar_list">
          <div className="list_left">
            <div className="task_initials">At</div>
            <div className="task_content">
              <h3>Asset Tours</h3>
              <p>6 tasks</p>
            </div>
          </div>

          <img
            src={arrowup}
            alt=""
          />
        </div>

        <div className="project_sidebar_menu">
          <span className="title">Main Menu</span>

          <div className="menu_list_wrapper">
            <div
              className={`menu_list ${menu === 1 && "active"}`}
              onClick={() => handleMenu(1)}
            >
              <img
                src={chekclist}
                alt=""
              />
              <h3>Checklists</h3>
            </div>
            <div
              className={`menu_list ${menu === 2 && "active"}`}
              onClick={() => handleMenu(2)}
            >
              <img
                src={document}
                alt=""
              />
              <h3>Documents</h3>
            </div>
          </div>
        </div>

        <div className="project_sidebar_performance">
          <span className="title">Performance</span>

          <h3>Week Load</h3>
          <p>Total Tasks in the Project</p>

          <img
            style={{padding: "16px 0"}}
            src={chart}
            alt=""
          />
        </div>

        <div className="project_sidebar_activity">
          <span className="title">Activity</span>

          <div className="activity_list_wrapper">
            <div className="activity_list">
              <img
                src={person}
                alt=""
              />
              <div>
                <p className="text">
                  Nancy Holmes <span>added</span> Create videos for final submission (30 minutes) to Open
                </p>
                <span style={{fontSize: "12px", marginTop: "5px"}}>Nov 15 at 5:29 PM</span>
              </div>
            </div>

            <div className="activity_list">
              <img
                src={person}
                alt=""
              />
              <div>
                <p className="text">
                  Nancy Holmes <span>added</span> Create videos for final submission (30 minutes) to Open
                </p>
                <span style={{fontSize: "12px", marginTop: "5px"}}>Nov 15 at 5:29 PM</span>
              </div>
            </div>

            <div className="activity_list">
              <img
                src={person}
                alt=""
              />
              <div>
                <p className="text">
                  Nancy Holmes <span>added</span> Create videos for final submission (30 minutes) to Open
                </p>
                <span style={{fontSize: "12px", marginTop: "5px"}}>Nov 15 at 5:29 PM</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
