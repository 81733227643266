import React, {useState} from "react";
import OfficePic1 from "../../../../assets/investor-pipeline/icons/office_pic1.svg";
import OfficePic2 from "../../../../assets/investor-pipeline/icons/office_pic2.svg";
import OfficePic3 from "../../../../assets/investor-pipeline/icons/office_pic3.svg";
import InvestorPic from "../../../../assets/investor-pipeline/icons/investor_pic.svg";
import BuilidingIcon from "../../../../assets/investor-pipeline/icons/buildings_icon_purple.svg";
import EyeIcon from "../../../../assets/investor-pipeline/icons/eye_icon_grey.svg";
import SlashedEyeIcon from "../../../../assets/investor-pipeline/icons/slashedEye_icon_grey.svg";
import uncheckedIcon from "../../../../assets/investor-pipeline/icons/unchecked_chechbox.svg";
import "./NewOfferings.scss";
import {useNavigate} from "react-router-dom";
const NewOfferings = ({handleMenuClicked}) => {
  const dummyData = [
    {
      id: 1,
      office_name: "Aurora Offices",
      office_address: "2464 Royal Ln. Mesa, New Jersey 45463",
      office_type: "Commercials",
      investor_name: "Lora Boone",
      investor_group: "BestInvest Group",
      office_picture: OfficePic1,
      investor_picture: InvestorPic,
      isStarted: "false",
      isInvestmentFinished: "true",
      additional_data: {
        strategy: "Opportunistic",
        offering_type: "Equity",
        offering_size: "$55,000,000",
        capitalization: "$120,000,000",
        hold_time: "48 Month",
        current_preferred_return: "9%",
        estimated_irr: "16-20%",
        minimum_investment: "$100,000",
        closing: "Mar 30, 2019",
        equity_multiple: "3.00-3.50x",
        ltc: "70%",
        total_preferred_return: "18%",
      },
    },
    {
      id: 2,
      office_name: "Cliff Appartments",
      office_address: "4140 Parker Rd. Allentown, New Mexico 31134",
      office_type: "Commercials",
      investor_name: "Lora Boone",
      investor_group: "BestInvest Group",
      office_picture: OfficePic2,
      investor_picture: InvestorPic,
      isStarted: "false",
      isInvestmentFinished: "true",
      additional_data: {
        strategy: "Value Add",
        offering_type: "Preferred Equity",
        offering_size: "$38,000,000",
        capitalization: "$80,000,000",
        hold_time: "24 Month",
        current_preferred_return: "8%",
        estimated_irr: "14-18%",
        minimum_investment: "$25,000",
        closing: "Jun 30, 2018",
        equity_multiple: "2.80-3.00x",
        ltc: "72%",
        total_preferred_return: "15%",
      },
    },
    {
      id: 3,
      office_name: "Asset Name",
      office_address: "3891 Ranchview Dr. Richardson, California 62639",
      office_type: "Commercials",
      investor_name: "Lora Boone",
      investor_group: "BestInvest Group",
      office_picture: OfficePic3,
      investor_picture: InvestorPic,
      isInvestmentFinished: "true",
      isStarted: "true",
      additional_data: {
        strategy: "Core Plus",
        offering_type: "Joint Venture",
        offering_size: "$45,000,000",
        capitalization: "$100,000,000",
        hold_time: "36 Month",
        current_preferred_return: "7%",
        estimated_irr: "12-16%",
        minimum_investment: "$50,000",
        closing: "Dec 15, 2018",
        equity_multiple: "2.50-3.20x",
        ltc: "68%",
        total_preferred_return: "14%",
      },
    },
  ];

  const [expandedId, setExpandedId] = useState(null);

  const handleSeeMore = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const [investmentId, setInvestmentId] = useState(false);

  const hanldeInvestmentClick = (id) => {
    setInvestmentId((prev) => !prev);
  };

  const [offeringId, setOfferingId] = useState(true);

  const handleOfferingList = () => {
    setOfferingId((prev) => !prev);
  };

  return (
    <div className="newOfferings_container">
      <div className="newOfferings_wrapper">
        <div className="newOfferings_content">
          <div className="main_content_box">
            <div className="notifi_container">
              <div
                className="offer_notifi"
                onClick={handleOfferingList}
              >
                <h2>{offeringId === true ? "Respond to your 3 Offers" : "Offering List"}</h2>
              </div>

              {offeringId === true ? (
                <>
                  <div className="notifi_header_wrapper">
                    <div className="notifi_header">
                      <h4
                        style={{cursor: "pointer"}}
                        onClick={hanldeInvestmentClick}
                      >
                        Investment
                      </h4>
                      <h4>Entity</h4>
                      <h4>Actions</h4>
                    </div>
                  </div>
                  {dummyData.map((item, index) => (
                    <NotifiBox
                      key={item.id}
                      item={item}
                      index={index}
                      handleSeeMore={handleSeeMore}
                      expandedId={expandedId}
                      investmentId={investmentId}
                      handleMenuClicked={handleMenuClicked}
                    />
                  ))}
                </>
              ) : (
                <>
                  <div className="no_offering_container">
                    <div className="no_offering_wrapper">
                      <div className="no_offering_content">
                        <div className="sectionA">
                          <h2>0</h2>
                          <p>New Offerings</p>
                        </div>
                        <div className="sectionB">
                          <img
                            src={BuilidingIcon}
                            alt="buildings"
                          />
                          <p>Don't you want to expect an offer? Try to offer your services.</p>
                          <button>Become a Public Investor</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOfferings;

const NotifiBox = ({item, index, handleSeeMore, expandedId, investmentId, handleMenuClicked}) => {
  const navigate = useNavigate();
  return (
    <div className="notifi_wrapper">
      <div className="information_detail">
        <div className="groupA">
          <div className="notifi_checkbox">
            <img
              src={uncheckedIcon}
              alt="checkbox"
            />
          </div>{" "}
          <div
            className="investor_address"
            onClick={() => navigate("/investment-pipeline/new-offerings", {state: 19})}
          >
            <img
              src={item.office_picture}
              alt="office_pic"
            />
            <div
              className="address_detail"
              style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: "65px"}}
            >
              <div className="address_detail_wrapper">
                <h3>{item.office_name}</h3>
                {investmentId === false ? <p>{item.office_address} </p> : <p>Address</p>}
                <p>{item.office_type}</p>
              </div>
              {investmentId === false ? "" : <p className="unfinished_tag">Unfinished</p>}
            </div>
          </div>
        </div>
        <div className="groupB">
          <img
            src={item.investor_picture}
            alt="investor_picture"
          />
          <div className="investor_personal_info">
            <h4>{item.investor_name}</h4>
            <p>{item.investor_group}</p>
          </div>
        </div>
        <div className="action_btns">
          <div className="btn_container">
            <button
              className="see_details"
              onClick={() => handleSeeMore(item.id)}
            >
              <img
                src={expandedId === item.id ? SlashedEyeIcon : EyeIcon}
                alt="eye_icon"
              />
              {expandedId === item.id ? "Hide Details" : "Show Details"}
            </button>

            <button
              onClick={() => navigate("/investment-pipeline/new-offerings")}
              className="proceed_continue"
            >
              {item.isStarted === "true" ? "Continue" : "Proceed"}
            </button>

            <button className="delete_req">Delete Request</button>
          </div>

          {item.isStarted === "true" ? (
            <>
              <p className="isStarted_tag">Started</p>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {expandedId === item.id && (
        <div className="more_information_detail">
          <p className="caption">Opportunity to invest in a ground-up, 84-unit, condo development project in the downtown area of Santa Barbara, CA.</p>
          <div className="more_info_box">
            <div class="detail_box">
              <label>STRATEGY</label>
              <value>{item.additional_data?.strategy}</value>
            </div>
            <div class="detail_box">
              <label>OFFERING TYPE</label>
              <value>{item.additional_data?.offering_type}</value>
            </div>
            <div class="detail_box">
              <label>OFFERING SIZE</label>
              <value>{item.additional_data?.offering_size}</value>
            </div>
            <div class="detail_box">
              <label>CAPITALIZATION</label>
              <value>{item.additional_data?.capitalization}</value>
            </div>
            <div class="detail_box">
              <label>HOLD TIME</label>
              <value>{item.additional_data?.hold_time}</value>
            </div>
            <div class="detail_box">
              <label>CURRENT PREFERRED RETURN</label>
              <value>{item.additional_data?.current_preferred_return}</value>
            </div>
            <div class="detail_box">
              <label>ESTIMATED IRR</label>
              <value>{item.additional_data?.estimated_irr}</value>
            </div>
            <div class="detail_box">
              <label>MINIMUM INVESTMENT</label>
              <value>{item.additional_data?.minimum_investment}</value>
            </div>
            <div class="detail_box">
              <label>CLOSING</label>
              <value>{item.additional_data?.closing}</value>
            </div>
            <div class="detail_box">
              <label>EQUITY MULTIPLE</label>
              <value>{item.additional_data?.equity_multiple}</value>
            </div>
            <div class="detail_box">
              <label>LTC</label>
              <value>{item.additional_data?.ltc}</value>
            </div>
            <div class="detail_box">
              <label>TOTAL PREFERRED RETURN</label>
              <value>{item.additional_data?.total_preferred_return}</value>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
