export const projects = [
    { id: 1, title: "Project 1", img: "img", status: "open", date: "2023-01-15" },
    { id: 2, title: "Project 2", img: "", status: "in-progress", date: "2023-03-22" },
    { id: 3, title: "Project 3", img: "img", status: "closed", date: "2023-05-10" },
    { id: 4, title: "Project 4", img: "", status: "open", date: "2023-07-07" },
    { id: 5, title: "Project 5", img: "img", status: "in-progress", date: "2023-09-18" },
    { id: 6, title: "Project 6", img: "", status: "closed", date: "2023-11-02" },
    { id: 7, title: "Project 7", img: "img", status: "open", date: "2023-02-14" },
    { id: 8, title: "Project 8", img: "", status: "in-progress", date: "2023-04-30" },
    { id: 9, title: "Project 9", img: "img", status: "closed", date: "2023-08-05" },
    { id: 10, title: "Project 10", img: "", status: "open", date: "2023-12-20" },
  ];
  