import React, {useState, useEffect} from "react";
import "./Deals.scss";
import {ReactComponent as Acquisition} from "../../assets/svg/acquisition.svg";
import {ReactComponent as Syndicate} from "../../assets/svg/syndicate.svg";
import incdec from "../../assets/svg/incdec.svg";
import search from "../../assets/svg/search.svg";
import filter from "../../assets/svg/filtter.svg";
import add from "../../assets/svg/add.svg";
import upload from "../../assets/svg/upload.svg";
import DealCard from "./DealCard";
import DealFilter from "./DealFilter";
import Filter from "./Filter";
import LeftMenu from "./LeftMenu";
import {useSelector, useDispatch} from "react-redux";
import {getDeals} from "../../store/reducers/deals";
import {fetchNotifications, markNotificationsAsRead} from "../../store/reducers/notificationSlice";
import bell from "../../assets/notification-bell_9187467.svg";
import defaultProfileImage from "../../assets/profile.png";
import LoaderOverlay from "../../components/reusableComponents/Loader";
import calendarIcon from "../../assets/dealsroom/svg/calendarIcon.svg";
import sortIcon from "../../assets/dealsroom/svg/sort_button_icon.svg";
import donwloadIcon from "../../assets/dealsroom/svg/downloadIcon.svg";
import LeftMenuChart from "./LeftMenuChart";
import DispositionCards from "./DispositionCards";
import AnalyticsDetails from "./AnalyticsDetails";
import AcquitionDealCard from "./AcquitionDealCard";
import {fetchAllAssets} from "../../store/reducers/DealsSyndicate";

const Deals = () => {
  const [dealData, setDealData] = useState([
    {name: "Waltamwoods Appartments", status: "Pending"},
    {name: "Waltamwoods Appartments", status: "Pending"},
    {name: "Krishna Appartments", status: "Completed"},
    {name: "Waltamwoods Appartments", status: "Active"},
    {name: "Waltamwoods Appartments", status: "Pending"},
    {name: "Krishna Appartments", status: "Completed"},
    {name: "Waltamwoods Appartments", status: "Active"},
    {name: "Waltamwoods Appartments", status: "Pending"},
  ]);

  const [isFilter, setIsFilter] = useState(false);
  const [isDealFilter, setIsDealFilter] = useState(false);
  const [updatedDealData, setUpdatedDealData] = useState(dealData);
  const [navSlider, setNavSlider] = useState(1);
  const [isClicked, setIsClicked] = useState(1);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  let DealsSyndicate = useSelector((state) => state.DealsSyndicateSlice.assets);
  const userInformation = useSelector((state) => state.userInfo.userInformation);
  const roleType = userInformation?.roleTypes;
  const notifications = useSelector((state) => state.notifications?.notifications) || [];
  const newNotifications = useSelector((state) => state.notifications?.newNotifications) || 0;
  const {idToken} = useSelector((state) => state.auth);
  const [analyticsModal, setAnalyticsModal] = useState(false);

  const handleMenuClicked = (data) => {
    setIsClicked(data);
  };

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  const handleBellClick = () => {
    setIsOpen(!isOpen);
    if (notifications.some((notification) => !notification.read)) {
      const unreadNotificationIds = notifications.filter((notification) => !notification.read).map((notification) => notification._id);
      dispatch(markNotificationsAsRead(unreadNotificationIds));
    }
  };

  // navigation
  const handleNav = (status) => {
    let updatedData;
    if (status === "Pending") {
      updatedData = dealData.filter((data) => data.status === "Pending");
      setUpdatedDealData(updatedData);
    } else if (status === "Completed") {
      updatedData = dealData.filter((data) => data.status === "Completed");
      setUpdatedDealData(updatedData);
    } else if (status === "Active") {
      updatedData = dealData.filter((data) => data.status === "Active");
      setUpdatedDealData(updatedData);
    } else {
      setUpdatedDealData(dealData);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const result = await dispatch(getDeals(idToken));

        setUpdatedDealData(result.payload);
      } catch (error) {

      } finally {
        setIsLoading(false);
      }
    };

    if (idToken) {
      fetchData();
    }
  }, [idToken, dispatch]);

  const handleAnalyticsBtn = () => {
    setAnalyticsModal(!analyticsModal);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [handleMenuClicked, analyticsModal]);

  useEffect(() => {
    dispatch(fetchAllAssets());
  }, [dispatch]);

  // Tab Chnages

  const determineAssetsArray = () => {
    if (isClicked === 1) {
      // const acquisitionAssets = updatedDealData?.data?.map((item) => item?.assets).flat() || [];
      return DealsSyndicate;
    } else if (isClicked === 2) {
      return DealsSyndicate || [];
    }
    return [];
  };

  const assetsArray = determineAssetsArray();

  return (
    <div className="dealPage_container">
      {isLoading && <LoaderOverlay />}
      <div
        className="hamburger-menu"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <div className="hamburger-menu-lines"></div>
        <div className="hamburger-menu-lines"></div>
        <div className="hamburger-menu-lines"></div>
      </div>
      <div className="deals">
        <div className={`deals-left ${isMenuOpen ? "open" : ""}`}>
          <div className="deals-left-inner">
            <span className="deals-left-heading">Deals</span>
            {/* <LeftMenu
              icon={<Acquisition />}
              name={"Acquisition"}
              id={1}
              state={isClicked}
              handleMenuClicked={handleMenuClicked}
            /> */}
            <LeftMenu
              icon={<Syndicate />}
              name={"Syndicate"}
              id={1}
              state={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            {/* <LeftMenu
              icon={<Syndicate />}
              name={"Disposition"}
              id={3}
              state={isClicked}
              handleMenuClicked={handleMenuClicked}
            /> */}
            <div className="left_chart_wrapper">
              {assetsArray.length > 0 && <LeftMenuChart assetsArray={assetsArray} />}
              <button
                className="open_analytics_btn"
                onClick={handleAnalyticsBtn}
              >
                Open Analytics details
              </button>
            </div>
          </div>
        </div>

        <div className="deals-center">
          {/* {isClicked === 1 && (
            <div className="deals_content_container">
              <div className="deals-upper">
                <div className="deals-upper-inner">
                  <div className="deals-u-left">
                    <div className="deals-upper-name">
                      <span>Acquisition</span>
                    </div>
                    <div className="deals-c-upper-left">
                      <ul>
                        <li
                          onClick={() => {
                            handleNav("all");
                            setNavSlider(1);
                          }}
                          className={navSlider === 1 ? "active" : ""}
                        >
                          All
                        </li>
                        <li
                          className={navSlider === 2 ? "active" : ""}
                          onClick={() => {
                            handleNav("Active");
                            setNavSlider(2);
                          }}
                        >
                          Active
                        </li>
                        <li
                          className={navSlider === 3 ? "active" : ""}
                          onClick={() => {
                            setNavSlider(3);
                            handleNav("Pending");
                          }}
                        >
                          Pending
                        </li>
                        <li
                          className={navSlider === 4 ? "active" : ""}
                          onClick={() => {
                            setNavSlider(4);
                            handleNav("Completed");
                          }}
                        >
                          Completed
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="deals-u-right">
                    <div>
                      <img
                        src={search}
                        alt="image"
                      />
                    </div>
                    <div
                      onClick={() => {
                        setIsDealFilter(false);
                        setIsFilter(!isFilter);
                      }}
                    >
                      <img
                        src={filter}
                        alt="image"
                      />
                      <span>Filter</span>
                    </div>
                    <div>
                      <img
                        src={upload}
                        alt="image"
                      />{" "}
                      <img
                        src={incdec}
                        alt="image"
                      />{" "}
                    </div>
                    <div
                      className="add-deals-btn"
                      onClick={() => {
                        setIsFilter(false);
                        setIsDealFilter(!isDealFilter);
                      }}
                    >
                      <img
                        src={add}
                        alt="image"
                      />
                      <span>Add Deals</span>
                      <img
                        src={incdec}
                        alt="image"
                      />
                    </div>
                    <div className="deals-u-right">
                      {userInformation?.roleTypes === 2 && (
                        <div className="notification-bell">
                          <div
                            className="bell-icon"
                            onClick={handleBellClick}
                          >
                            <img
                              src={bell}
                              className={`fa fa-bell ${newNotifications > 0 ? "new-notifications" : ""}`}
                              alt="bellIcon"
                            />
                            {newNotifications > 0 && <span className="badge">{newNotifications}</span>}
                          </div>
                          {isOpen && (
                            <div className="notifications-dropdown">
                              <div className="notifications-header">
                                <h3>Notifications</h3>
                              </div>
                              {notifications?.length === 0 ? (
                                <div className="no-notifications">
                                  <p>No new notifications</p>
                                </div>
                              ) : (
                                <div className="notifications-list">
                                  {notifications?.map((notification, index) => (
                                    <div
                                      key={index}
                                      className={`notification-item ${notification.read ? "read" : "unread"}`}
                                    >
                                      <div className="notification-image">
                                        <img
                                          src={notification?.userId?.userProfileImage || defaultProfileImage}
                                          alt={`${notification?.userId.name} ${notification.userId.surname}`}
                                        />
                                      </div>
                                      <div className="notification-content">
                                        <div className="notification-message">{notification?.userId?.email}</div>
                                        <div className="notification-message">{notification?.assetId[0]?.assetName}</div>
                                        <div className="notification-timestamp">{new Date(notification?.createdAt).toLocaleString()}</div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {analyticsModal === false ? (
                <div className="deal-card-wrapper">
                  {roleType === 4
                    ? updatedDealData?.data?.assets?.map((data, index) => (
                        <DealCard
                          name={data.name}
                          sta={data.status}
                          // key={index}
                          data={data}
                          roleType={roleType}
                          key={data._id || index}
                        />
                      ))
                    : roleType === 2 && updatedDealData?.data?.length > 0
                    ? updatedDealData?.data[0]?.assets?.map((data, index) => (
                        <AcquitionDealCard
                          name={data.assetName}
                          sta={data.status}
                          // key={index}
                          key={data._id || index}
                          data={data}
                          roleType={roleType}
                        />
                      ))
                    : null}
                </div>
              ) : (
                ""
              )}

              {isFilter && <Filter />}
              {isDealFilter && <DealFilter />}
            </div>
          )} */}
          {isClicked === 1 && (
            <div className="deals_content_container">
              <div className="deals-upper">
                <div className="deals-upper-inner">
                  <div className="deals-u-left">
                    <div className="deals-upper-name">
                      <span>Syndicates</span>
                    </div>
                    <div className="deals-c-upper-left">
                      <ul>
                        <li
                          onClick={() => {
                            handleNav("all");
                            setNavSlider(1);
                          }}
                          className={navSlider === 1 ? "active" : ""}
                        >
                          All
                        </li>
                        <li
                          className={navSlider === 2 ? "active" : ""}
                          onClick={() => {
                            handleNav("Active");
                            setNavSlider(2);
                          }}
                        >
                          Active
                        </li>
                        <li
                          className={navSlider === 3 ? "active" : ""}
                          onClick={() => {
                            setNavSlider(3);
                            handleNav("Pending");
                          }}
                        >
                          Pending
                        </li>
                        <li
                          className={navSlider === 4 ? "active" : ""}
                          onClick={() => {
                            setNavSlider(4);
                            handleNav("Completed");
                          }}
                        >
                          Completed
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="deals-u-right">
                    <div>
                      <img
                        src={search}
                        alt="image"
                      />
                    </div>
                    <div
                      onClick={() => {
                        setIsDealFilter(false);
                        setIsFilter(!isFilter);
                      }}
                    >
                      <img
                        src={filter}
                        alt="image"
                      />
                      <span>Filter</span>
                    </div>
                    <div>
                      <img
                        src={upload}
                        alt="image"
                      />{" "}
                      <img
                        src={incdec}
                        alt="image"
                      />{" "}
                    </div>
                    <div
                      className="add-deals-btn"
                      onClick={() => {
                        setIsFilter(false);
                        setIsDealFilter(!isDealFilter);
                      }}
                    >
                      <img
                        src={add}
                        alt="image"
                      />
                      <span>Add Deals</span>
                      <img
                        src={incdec}
                        alt="image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {analyticsModal === false ? (
                <div className="deal-card-wrapper">
                  {DealsSyndicate?.map((data, index) => {
                    return (
                      <DealCard
                        name={data.name}
                        sta={data.status}
                        key={index}
                        data={data}
                        roleType={roleType}
                        onSelect={(selectedData) => {
                         
                        }}
                      />
                    );
                  })}
                </div>
              ) : (
                "Please Refresh"
              )}
            </div>
          )}
          {isClicked === 3 && (
            <div className="deals_content_container">
              <div className="deals-upper">
                <div className="deals-upper-inner">
                  <div className="deals-u-left">
                    <div className="deals-upper-name">
                      <span>Disposition Deals</span>
                    </div>
                    <div className="deals-c-upper-left">
                      <ul>
                        <li
                          onClick={() => {
                            handleNav("all");
                            setNavSlider(1);
                          }}
                          className={navSlider === 1 ? "active" : ""}
                        >
                          All Stages
                        </li>
                        <li
                          className={navSlider === 2 ? "active" : ""}
                          onClick={() => {
                            handleNav("Active");
                            setNavSlider(2);
                          }}
                        >
                          Active
                        </li>
                        <li
                          className={navSlider === 3 ? "active" : ""}
                          onClick={() => {
                            setNavSlider(3);
                            handleNav("Pending");
                          }}
                        >
                          Pending
                        </li>
                        <li
                          className={navSlider === 4 ? "active" : ""}
                          onClick={() => {
                            setNavSlider(4);
                            handleNav("Completed");
                          }}
                        >
                          Closed
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="deals-u-right">
                    <div
                      className="commercial_btn optional_btns"
                      onClick={() => {}}
                    >
                      <span>Commercial</span>
                      <img
                        src={incdec}
                        alt="image"
                      />
                    </div>
                    <div
                      className="year_btn optional_btns"
                      onClick={() => {}}
                    >
                      <img
                        src={calendarIcon}
                        alt="image"
                      />
                      <span>This year</span>
                      <img
                        src={incdec}
                        alt="image"
                      />
                    </div>
                    <div
                      className="sort_btn optional_btns"
                      onClick={() => {}}
                    >
                      <span>Sort</span>
                      <img
                        src={sortIcon}
                        alt="image"
                      />
                      <span>Price</span>
                      <img
                        src={incdec}
                        alt="image"
                      />
                    </div>
                    <div
                      className="add-deals-btn optional_btns"
                      onClick={() => {}}
                    >
                      <img
                        src={donwloadIcon}
                        alt="image"
                      />
                      <span>Download</span>
                      <img
                        src={incdec}
                        alt="image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {analyticsModal === false ? (
                <div className="deal-card-wrapper">
                  {dealData?.map((data, index) => {
                    return (
                      <DispositionCards
                        name={data.name}
                        sta={data.status}
                        key={index}
                        data={data}
                        roleType={roleType}
                      />
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>

      <div className={`analytics_modal ${analyticsModal === true ? "openAnalytics" : "closeAnalytics"}`}>
        <AnalyticsDetails
          setAnalyticsModal={setAnalyticsModal}
          deals={assetsArray}
        />
      </div>
      {/* <AnalyticsDetails deals={assetsArray} /> */}
    </div>
  );
};

export default Deals;
