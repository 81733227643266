import React from "react";
import "./ReactTable.scss";

//Header
const TableHeader = ({headers}) => (
  <thead>
    <tr>
      {headers.map((header) => (
        <th key={header}>{header}</th>
      ))}
    </tr>
  </thead>
);

// Body
const TableBody = ({data}) => (
  <tbody>
    {data.map((row, index) => (
      <tr key={index}>
        {Object.entries(row).map(([key, value]) => (
          <td key={key}>{value}</td>
        ))}
      </tr>
    ))}
  </tbody>
);

//Table
const Table = ({headers, tabledata}) => (
  <div className="table-container">
    <table className="Table-head-data">
      <TableHeader headers={[...headers]} />
      <TableBody data={tabledata} />
    </table>
  </div>
);

export default Table;
