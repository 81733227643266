import React, { useState } from 'react'
import './Messaging.scss'
import cross from '../../assets/svg/cross.svg'
import expand from '../../assets/svg/expand.svg'
import person from '../../assets/svg/profileAvatar.png'
import building from '../../assets/Image/assetDiscovery/general-bg.png'
import dot from '../../assets/svg/dot-green.svg'
import imgupload from "../../assets/dealsroom/svg/imgupload.svg";
import attachment from "../../assets/dealsroom/svg/attachment.svg";
import emoji from "../../assets/dealsroom/svg/emoji.svg";
import women from "../../assets/dealsroom/images/women.png";
import action from "../../assets/dealsroom/svg/action.svg";
import chatimg from "../../assets/dealsroom/images/chatimg.png";
import dropdown from '../../assets/Image/assetDiscovery/arrow_down.svg'
import video from '../../assets/Image/assetDiscovery/video.svg'
import add from '../../assets/svg/add-gray.svg'
import phone from '../../assets/svg/phone.svg'

function Messaging({handleChat}) {
    const [navActive, setNavActive] = useState(1);
    const [expanded, setExpanded] = useState(true)

    return (
        <div className={`chat_dropdown_wrapper_2 messaging_wrapper ${expanded && 'expand'}`}>


                <div style={{ display: 'flex' }}>
                    <div className='chat_dropdown'>


                            <>
                                <div className='chat_title'>
                                    <p>
                                        Teams
                                    </p> </div>
                                <TeamsBox name={'Operational Teams'} expanded={true} active={true} navActive={navActive} setNavActive={setNavActive} />
                                <TeamsBox name={'Finance Teams'} expanded={true} navActive={navActive} setNavActive={setNavActive}  />

                                <div className='chat_title'>
                                    <p>
                                        Personal
                                    </p> </div>

                                <PersonalBox name={'Delia Saunders'} />
                                <PersonalBox name={'Delia Saunders'} />
                                <PersonalBox name={'Delia Saunders'} />
                            </>
                        

                     <ContactsBox />



                    </div>
                    <ChatBox handleChat={handleChat} expanded={expanded} />
                </div>
            
        </div>
    )
}

export default Messaging


const TeamsBox = ({ name, active, navActive, setNavActive, expanded }) => {
    return (
        <div onClick={() => expanded ? setNavActive(1) : setNavActive(3)} className={`teambox ${active && 'active'}`}>
            <div className='teambox_left'>
                <img src={person} alt='' />
            </div>
            <div className='teambox_right'>
                <div className='right_upper'>
                    <h3>{name}</h3>
                    <span className='time'>05:52 AM</span>
                </div>
                <div>
                    <p className='text'>We have lake-front vacation rentals. No specific liability waiver for that, but…</p>
                </div>
            </div>
        </div>
    )
}


const PersonalBox = ({ name }) => {
    return (
        <div className='teambox personalbox'>
            <div className='teambox_left'>
                <img src={person} alt='' />
            </div>
            <div className='teambox_right'>
                <div className='right_upper'>
                    <h3>{name}</h3>
                    <span className='time'>05:52 AM</span>
                </div>
                <div>
                    <p className='text'>We have lake-front vacation rentals. No specific liability waiver for that, but…</p>
                </div>

                <div className='right_lower'>
                    <img src={building} alt='' />
                    <p>Bauhaus Archive</p>
                </div>
            </div>
        </div>
    )
}


const ContactsBox = () => {
    return (
        <div className='contactsbox'>
            <p className='title'>Favourites</p>
            <Contact name={'Lift Lights'} />
            <Contact name={'JP Captain Morgan'} />
            <p style={{ marginTop: '20px' }} className='title'>Contact</p>
            <Contact name={'Francisco Gibbs'} active={true} />
            <Contact name={'Francisco Gibbs'} active={true} />
            <Contact name={'Lift Lights'} active={true} />
            <Contact name={'Lift Lights'} active={true} />
            <Contact name={'Lift Lights'} active={true} />
            <Contact name={'Lift Lights'} active={true} />
            <p style={{ marginTop: '20px' }} className='title'>Groups</p>
            <Contact name={'Operational Teams'} group={true} />
            <Contact name={'Office Managers'} group={true} />

        </div>
    )
}

const Contact = ({ active, name, group }) => {
    return (
        <div className='contact'>
            <div className='contact_left'>
                <img src={person} alt='' />
                <div>
                    <p className='name'>{name}</p>
                    {group &&
                        <div className='left_lower'>
                            <span>
                                Lucy, Claire, Sarah
                            </span>
                        </div>}
                </div>
            </div>
            {active &&
                <div className='contact_right'>
                    <img src={dot} alt='' />
                </div>

            }
        </div>
    )
}


const ChatBox = ({ expanded , handleChat }) => {
    return (
        <div className='chatbox'>
            <div className='chatbox_header'>
                <div className='chatbox_header_left'>
                    {!expanded &&
                        <img style={{ rotate: '90deg' }} src={dropdown} alt='' />
                    }
                    <h3>Operational Teams</h3>
                </div>

                <div className='chatbox_header_right'>
                    {expanded ?
                        <>
                            <div>
                                <img src={person} alt='' />
                                <img src={person} alt='' />
                                <img src={person} alt='' />
                                <img src={person} alt='' />

                                <img src={add} alt='' />
                            </div>

                            <div>
                                <img src={phone} alt='' />
                                <img src={video} alt='' />
                            </div>

                            <img onClick={handleChat} src={cross} alt='' />
                        </> :
                        <img  src={cross} alt='' />

                    }
                </div>
            </div>
            <div>
                <div className={`seller-chat-container  ${expanded && 'seller-chat-expanded'}`}>
                    <div className="seller-chat-activity">
                        <div className={`seller-chat-activity-right `}>
                            <div className="chat-user">
                                <img
                                    id="chatimg"
                                    src={women}
                                    alt=""
                                />
                                <span>Norman Hernandez</span>
                                <span>05:10 AM</span>
                            </div>
                            <div className={`chat-box-right  ${expanded && 'seller-chat-right-expanded'}`}>
                                <p>
                                    I always think teenagers must hate my place as there is nothing to do but relax and enjoy the sea view and sunsets and read books which is not a very teenagery thing to do (my idea of

                                </p>
                            </div>
                        </div>
                        <div className="seller-chat-activity-left">
                            <div className="chat-user">
                                <img
                                    id="chatimg"
                                    src={women}
                                    alt=""
                                />
                                <span>Norman Hernandez</span>
                                <span>05:10 AM</span>
                            </div>
                            <div className={`chat-box-left chat-color-unread  ${expanded && 'seller-chat-left-expanded'}`}>
                                <p> We wanted to see how others handle a waiver of liability to use of Kayaks, boats, swimming. 🛶🏖</p>
                                <img
                                    src={action}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="seller-chat-activity-right">
                            <div className="chat-user">
                                <img
                                    id="chatimg"
                                    src={women}
                                    alt=""
                                />
                                <span>Norman Hernandez</span>
                                <span>05:10 AM</span>
                            </div>
                            <div className={`chat-box-right  ${expanded && 'seller-chat-right-expanded'}`}>
                                <p>
                                    I always think teenagers must hate my place as there is nothing to do but relax and enjoy the sea view and sunsets and read books which is not a very teenagery thing to do (my idea of

                                </p>
                            </div>

                        </div>
                        <div className="seller-chat-activity-left">
                            <div className="chat-user">
                                <img
                                    id="chatimg"
                                    src={women}
                                    alt=""
                                />
                                <span>Norman Hernandez</span>
                                <span>05:10 AM</span>
                            </div>
                            <div className={`chat-box-left chat-color-read  ${expanded && 'seller-chat-left-expanded'}`}>
                                <p>Hey y’all! We own Hidden    I always think teenagers must hate my place as there is nothing to do but relax and enjoy the sea view and sunsets and read books which is not a very teenagery thing to do (my idea of
                                    Lake Forest which is in a private lake community. 🛶🏖</p>
                                <img
                                    src={action}
                                    alt=""
                                />
                            </div>
                        </div>

                        <div className="seller-chat-activity-left">
                            <div className="chat-user user-active">
                                <img
                                    id="chatimg"
                                    src={women}
                                    alt=""
                                />
                                <span>Norman Hernandez</span>
                                <span>Typing...</span>
                            </div>
                        </div>
                    </div>
                    <div className="seller-chat-box">
                        <input
                            id="input-box"
                            type="text"
                            placeholder="Type a message..."
                        />
                        <div className="chat-attacment">
                            <img
                                src={imgupload}
                                alt=""
                            />
                            <img
                                src={attachment}
                                alt=""
                            />
                            <img
                                src={emoji}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}