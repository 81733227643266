import React, {useEffect, useRef, useState} from "react";
import "./Dashboard.scss";
import PageStatisticsChart from "../../components/InvestmentPipelineComponents/DashboardCharts/PastInvestmentsChart";
import ActiveInvestmentsChart from "../../components/InvestmentPipelineComponents/DashboardCharts/ActiveInvestmentsChart";
import map from "../../assets/investment/map.png";
import tableIcon from "../../assets/Image/assetDiscovery/profile.png";
import search from "../../assets/svg/search.svg";
import filter from "../../assets/svg/filter_icon.svg";
import arrowIcon from "../../assets/investment/arrow-right.svg";

const Dashboard = ({handleMenuClicked}) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const filterRef = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (filterRef.current && !filterRef.current.contains(event.target)) {
  //       setIsFilterModalOpen(false);
  //     }
  //   };

  //   document?.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document?.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [filterRef]);
  return (
    <div className="dashboard_main">
      <div className="db_header">
        <div className="db_header_box">
          <h3>$1,632,569.00</h3>
          <p>Total Invested</p>
        </div>
        <div className="db_header_box">
          <h3>$935,558</h3>
          <p>Total Distributed</p>
        </div>
      </div>
      <div className="db_one">
        <div className="db_charts_wrapper">
          <div>
            <h2>Past Investments</h2>
            <PageStatisticsChart />
          </div>
          <div>
            <h2>Active Investments</h2>
            <ActiveInvestmentsChart />
          </div>
        </div>
        <div className="db_map_wrapper">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434509385!2d144.96315781550483!3d-37.814107979751554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf57744e51f97b6e1!2sFederation%20Square!5e0!3m2!1sen!2sau!4v1626093397898!5m2!1sen!2sau"
            width="100%"
            height="300"
            style={{border: 0}}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Map"
          ></iframe>
        </div>
        <div className="db_table general_investments">
          <div className="upper">
            <span className="upper_heading">
              <h2>Active Investments</h2>
            </span>
            <div className="upper_right">
              <div className="search_bar">
                <input
                  type="text"
                  placeholder="Search"
                />
                <img
                  src={search}
                  alt=""
                />
              </div>

              <button
                className="active_investment_filter_button"
                onClick={() => setIsFilterModalOpen(!isFilterModalOpen)}
              >
                <img
                  src={filter}
                  alt="filter"
                />
                <p>Filter</p>
              </button>
              <button onClick={() => handleMenuClicked(3)}>
                View All
                <img
                  src={arrowIcon}
                  alt=""
                />
              </button>
            </div>
          </div>
          {isFilterModalOpen && (
            <div className="filter_modal_overlay">
              <div
                className="filter_modal"
                ref={filterRef}
              >
                <div className="filter_modal_head">
                  <h2>Filters</h2>
                </div>
                <div className="filter_modal_one">
                  <p>Asset Type</p>
                  <button>Commercial</button>
                </div>
                <div className="filter_modal_two">
                  <p>Asset Type</p>
                  <button>NorthInvest Group</button>
                </div>
                <div className="filter_modal_button">
                  <button className="apply">Apply</button>
                  <button
                    className="cancel"
                    onClick={() => setIsFilterModalOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="lower">
            <Table />
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default Dashboard;

const Table = () => {
  return (
    <div>
      <div className="documents_box-B table">
        <div className="uploaded_docs">
          <div className="header">
            <div className="left">
              <p>Name</p>
              <p>Asset Type</p>
              <p>Investing Entity</p>
              <p>Commited</p>
              <p>Distribution</p>
              <p>Contribution</p>
            </div>
          </div>
          <Row2 />
          <Row2 />
          <Row2 />
          <Row2 />
        </div>
      </div>
    </div>
  );
};

const Row2 = () => {
  return (
    <div className="table_row table_row2">
      <div className="left">
        <div className="item first">
          <img
            src={tableIcon}
            alt=""
          />

          <div className="text_wrapper">
            <p>Aurora</p>
          </div>
        </div>

        <div className="item">
          <div className="text_wrapper">
            <p>Commercial</p>
          </div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p>North Invest Group</p>
          </div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p>$500,900.00</p>
          </div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p>$500,900.00</p>
          </div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p>$500,900.00</p>
          </div>
        </div>
      </div>
    </div>
  );
};
