import React from "react";
import { Link } from "react-router-dom";

const SuccessfulSignup = () => {
  return (
    <div className="SuccessfulSignup">
      <h1>Congratulations!</h1>
      <h2>You’ve successfully created an account in Lilypads.</h2>
      <svg
        width="265"
        height="265"
        viewBox="0 0 265 265"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M131.227 256.181C199.783 256.181 255.358 224.095 255.358 184.514C255.358 144.934 199.783 112.848 131.227 112.848C62.6712 112.848 7.0957 144.934 7.0957 184.514C7.0957 224.095 62.6712 256.181 131.227 256.181Z"
          fill="#455A64"
        />
        <path
          d="M219.006 133.841C173.956 107.829 102.888 105.995 54.1758 128.335L228.546 229.035C267.23 200.87 264.056 159.848 219.006 133.841Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.1"
          d="M219.006 133.841C173.956 107.829 102.888 105.995 54.1758 128.335L228.546 229.035C267.23 200.87 264.056 159.848 219.006 133.841Z"
          fill="black"
        />
        <path
          d="M43.4588 133.84C25.7409 144.069 14.5049 156.63 9.74023 169.806L156.715 254.659C179.542 251.909 201.288 245.421 219.011 235.192C222.296 233.3 225.481 231.237 228.551 229.013L54.1807 128.312C50.4637 130.023 46.8897 131.865 43.4588 133.84Z"
          fill="#F0F0F0"
        />
        <path
          d="M142.787 246.622L200.918 213.062L203.069 214.308L144.939 247.868L142.787 246.622Z"
          fill="#E6E6E6"
        />
        <path
          d="M82.5039 211.817L140.634 178.258L142.786 179.498L84.6557 213.058L82.5039 211.817Z"
          fill="#E6E6E6"
        />
        <path
          d="M22.2227 177.017L80.353 143.457L82.5048 144.703L24.3745 178.262L22.2227 177.017Z"
          fill="#E6E6E6"
        />
        <path
          d="M145.596 255.706C149.323 255.458 153.033 255.105 156.726 254.646L9.73532 169.809C8.98345 171.9 8.38647 174.043 7.94922 176.222L145.596 255.706Z"
          fill="#E0E0E0"
        />
        <path
          opacity="0.07"
          d="M56.0637 174.355C69.344 174.355 80.1098 168.141 80.1098 160.474C80.1098 152.808 69.344 146.594 56.0637 146.594C42.7834 146.594 32.0176 152.808 32.0176 160.474C32.0176 168.141 42.7834 174.355 56.0637 174.355Z"
          fill="black"
        />
        <path
          d="M116.971 152.13L80.3535 131.026L114.766 111.246L151.4 132.292L116.971 152.13Z"
          fill="#FAFAFA"
        />
        <path
          d="M80.3535 131.023L116.971 152.128V154.64L80.3535 133.509V131.023Z"
          fill="#F0F0F0"
        />
        <path
          d="M151.4 132.297L116.971 152.129V154.642L151.4 134.756V132.297Z"
          fill="#E0E0E0"
        />
        <path
          d="M116.945 147.185L142.274 132.557V25.168L116.945 39.796V147.185Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.65"
          d="M116.945 147.185L142.274 132.557V25.168L116.945 39.796V147.185Z"
          fill="white"
        />
        <path
          d="M116.944 39.7942L89.3047 23.8359V131.225L116.944 147.183V39.7942Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.8"
          d="M116.944 39.7942L89.3047 23.8359V131.225L116.944 147.183V39.7942Z"
          fill="white"
        />
        <path
          d="M142.273 25.1678L114.639 9.21484L89.3047 23.8375L116.944 39.7958L142.273 25.1678Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.9"
          d="M142.273 25.1678L114.639 9.21484L89.3047 23.8375L116.944 39.7958L142.273 25.1678Z"
          fill="white"
        />
        <path
          d="M99.6504 137.211V126.574L106.811 130.692V141.324L99.6504 137.211Z"
          fill="#37474F"
        />
        <path
          d="M102.777 128.371L103.472 128.774V139.411L102.777 139.008V128.371Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.8"
          d="M102.777 128.371L103.472 128.774V139.411L102.777 139.008V128.371Z"
          fill="white"
        />
        <path
          d="M114.051 140.908V134.977L109.312 132.242V138.173L114.051 140.908Z"
          fill="#BA68C8"
        />
        <path
          d="M114.051 129.544V123.614L109.312 120.879V126.81L114.051 129.544Z"
          fill="#BA68C8"
        />
        <path
          d="M114.051 118.341V112.411L109.312 109.676V115.606L114.051 118.341Z"
          fill="#BA68C8"
        />
        <path
          d="M114.051 107.138V101.207L109.312 98.4727V104.403L114.051 107.138Z"
          fill="#BA68C8"
        />
        <path
          d="M114.051 95.935V90.0043L109.312 87.2695V93.2002L114.051 95.935Z"
          fill="#BA68C8"
        />
        <path
          d="M114.051 84.7358V78.8051L109.312 76.0703V81.9957L114.051 84.7358Z"
          fill="#BA68C8"
        />
        <path
          d="M114.051 73.5327V67.602L109.312 64.8672V70.7926L114.051 73.5327Z"
          fill="#BA68C8"
        />
        <path
          d="M114.051 62.4038V56.4731L109.312 53.7383V59.669L114.051 62.4038Z"
          fill="#BA68C8"
        />
        <path
          d="M114.051 51.2866V45.3559L109.312 42.6211V48.5465L114.051 51.2866Z"
          fill="#BA68C8"
        />
        <path
          d="M96.9472 131.04V125.11L92.209 122.375V128.306L96.9472 131.04Z"
          fill="#BA68C8"
        />
        <path
          d="M96.9472 119.677V113.747L92.209 111.012V116.942L96.9472 119.677Z"
          fill="#BA68C8"
        />
        <path
          d="M96.9472 108.451V102.52L92.209 99.7852V105.716L96.9472 108.451Z"
          fill="#BA68C8"
        />
        <path
          d="M96.9472 97.2319V91.3012L92.209 88.5664V94.4971L96.9472 97.2319Z"
          fill="#BA68C8"
        />
        <path
          d="M96.9472 86.0053V80.0746L92.209 77.3398V83.2705L96.9472 86.0053Z"
          fill="#BA68C8"
        />
        <path
          d="M96.9472 74.7866V68.8559L92.209 66.1211V72.0518L96.9472 74.7866Z"
          fill="#BA68C8"
        />
        <path
          d="M96.9472 63.56V57.6293L92.209 54.8945V60.8252L96.9472 63.56Z"
          fill="#BA68C8"
        />
        <path
          d="M96.9472 52.3452V46.4145L92.209 43.6797V49.6104L96.9472 52.3452Z"
          fill="#BA68C8"
        />
        <path
          d="M96.9472 41.1303V35.1996L92.209 32.4648V38.3902L96.9472 41.1303Z"
          fill="#BA68C8"
        />
        <g opacity="0.15">
          <path
            opacity="0.15"
            d="M114.051 140.908V134.977L109.312 132.242V138.173L114.051 140.908Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M114.051 129.544V123.614L109.312 120.879V126.81L114.051 129.544Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M114.051 118.341V112.411L109.312 109.676V115.606L114.051 118.341Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M114.051 107.138V101.207L109.312 98.4727V104.403L114.051 107.138Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M114.051 95.9311V90.0004L109.312 87.2656V93.1963L114.051 95.9311Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M114.051 84.7319V78.8012L109.312 76.0664V81.9918L114.051 84.7319Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M114.051 73.5288V67.5981L109.312 64.8633V70.7887L114.051 73.5288Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M114.051 62.4038V56.4731L109.312 53.7383V59.669L114.051 62.4038Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M114.051 51.2866V45.3559L109.312 42.6211V48.5465L114.051 51.2866Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M96.9472 131.04V125.11L92.209 122.375V128.306L96.9472 131.04Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M96.9472 119.677V113.747L92.209 111.012V116.942L96.9472 119.677Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M96.9472 108.451V102.52L92.209 99.7852V105.716L96.9472 108.451Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M96.9472 97.2319V91.3012L92.209 88.5664V94.4971L96.9472 97.2319Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M96.9472 86.0053V80.0746L92.209 77.3398V83.2705L96.9472 86.0053Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M96.9472 74.7866V68.8559L92.209 66.1211V72.0518L96.9472 74.7866Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M96.9472 63.56V57.6293L92.209 54.8945V60.8252L96.9472 63.56Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M96.9472 52.3452V46.4145L92.209 43.6797V49.6104L96.9472 52.3452Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M96.9472 41.1303V35.1996L92.209 32.4648V38.3902L96.9472 41.1303Z"
            fill="black"
          />
        </g>
        <path
          d="M99.6504 36.4609L102.417 38.0509V122.835L99.6504 121.245V36.4609Z"
          fill="#BA68C8"
        />
        <path
          d="M104.045 38.9688L106.812 40.5587V125.343L104.045 123.753V38.9688Z"
          fill="#BA68C8"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M99.6504 36.4609L102.417 38.0509V122.835L99.6504 121.245V36.4609Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M104.045 38.9688L106.812 40.5587V125.343L104.045 123.753V38.9688Z"
            fill="black"
          />
        </g>
        <path
          d="M93.9102 23.8371L114.638 11.875L137.667 25.1674L116.944 37.1348L93.9102 23.8371Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.6"
          d="M93.9102 23.8371L114.638 11.875L137.667 25.1674L116.944 37.1348L93.9102 23.8371Z"
          fill="white"
        />
        <path
          d="M114.639 11.875V14.5303L135.367 26.4977L137.667 25.1674L114.639 11.875Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.7"
          d="M114.639 11.875V14.5303L135.367 26.4977L137.667 25.1674L114.639 11.875Z"
          fill="white"
        />
        <path
          d="M114.638 11.875L93.9102 23.8371L96.2157 25.1674L114.638 14.5303V11.875Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.8"
          d="M114.638 11.875L93.9102 23.8371L96.2157 25.1674L114.638 14.5303V11.875Z"
          fill="white"
        />
        <path
          d="M119.875 140.908V134.977L124.613 132.242V138.173L119.875 140.908Z"
          fill="#BA68C8"
        />
        <path
          d="M127.242 136.673V130.743L131.975 128.008V133.939L127.242 136.673Z"
          fill="#BA68C8"
        />
        <path
          d="M134.609 132.447V126.516L139.348 123.781V129.712L134.609 132.447Z"
          fill="#BA68C8"
        />
        <path
          d="M119.875 129.544V123.614L124.613 120.879V126.81L119.875 129.544Z"
          fill="#BA68C8"
        />
        <path
          d="M127.242 125.313V119.387L131.975 116.652V122.578L127.242 125.313Z"
          fill="#BA68C8"
        />
        <path
          d="M134.609 121.083V115.153L139.348 112.418V118.349L134.609 121.083Z"
          fill="#BA68C8"
        />
        <path
          d="M119.875 118.341V112.411L124.613 109.676V115.606L119.875 118.341Z"
          fill="#BA68C8"
        />
        <path
          d="M127.242 114.103V108.172L131.975 105.438V111.368L127.242 114.103Z"
          fill="#BA68C8"
        />
        <path
          d="M134.609 109.857V103.926L139.348 101.191V107.122L134.609 109.857Z"
          fill="#BA68C8"
        />
        <path
          d="M119.875 107.138V101.207L124.613 98.4727V104.403L119.875 107.138Z"
          fill="#BA68C8"
        />
        <path
          d="M127.242 102.888V96.9575L131.975 94.2227V100.153L127.242 102.888Z"
          fill="#BA68C8"
        />
        <path
          d="M134.609 98.6343V92.7036L139.348 89.9688V95.8995L134.609 98.6343Z"
          fill="#BA68C8"
        />
        <path
          d="M119.875 95.935V90.0043L124.613 87.2695V93.2002L119.875 95.935Z"
          fill="#BA68C8"
        />
        <path
          d="M127.242 91.6733V85.7426L131.975 83.0078V88.9385L127.242 91.6733Z"
          fill="#BA68C8"
        />
        <path
          d="M134.609 87.4116V81.4862L139.348 78.7461V84.6768L134.609 87.4116Z"
          fill="#BA68C8"
        />
        <path
          d="M119.875 84.7358V78.8051L124.613 76.0703V81.9957L119.875 84.7358Z"
          fill="#BA68C8"
        />
        <path
          d="M127.242 80.4624V74.5317L131.975 71.7969V77.7276L127.242 80.4624Z"
          fill="#BA68C8"
        />
        <path
          d="M134.609 76.1889V70.2582L139.348 67.5234V73.4541L134.609 76.1889Z"
          fill="#BA68C8"
        />
        <path
          d="M119.875 73.5327V67.602L124.613 64.8672V70.7926L119.875 73.5327Z"
          fill="#BA68C8"
        />
        <path
          d="M127.242 69.2475V63.3168L131.975 60.582V66.5127L127.242 69.2475Z"
          fill="#BA68C8"
        />
        <path
          d="M134.609 64.9663V59.0356L139.348 56.3008V62.2315L134.609 64.9663Z"
          fill="#BA68C8"
        />
        <path
          d="M119.875 62.4038V56.4731L124.613 53.7383V59.669L119.875 62.4038Z"
          fill="#BA68C8"
        />
        <path
          d="M127.242 58.0757V52.1503L131.975 49.4102V55.3409L127.242 58.0757Z"
          fill="#BA68C8"
        />
        <path
          d="M134.609 53.7514V47.8207L139.348 45.0859V51.0166L134.609 53.7514Z"
          fill="#BA68C8"
        />
        <path
          d="M119.875 51.2866V45.3559L124.613 42.6211V48.5465L119.875 51.2866Z"
          fill="#BA68C8"
        />
        <path
          d="M127.242 46.9077V40.977L131.975 38.2422V44.1729L127.242 46.9077Z"
          fill="#BA68C8"
        />
        <path
          d="M134.609 42.5366V36.6059L139.348 33.8711V39.8018L134.609 42.5366Z"
          fill="#BA68C8"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M119.875 140.912V134.981L124.613 132.246V138.177L119.875 140.912Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M127.242 136.673V130.743L131.975 128.008V133.939L127.242 136.673Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M134.609 132.447V126.516L139.348 123.781V129.712L134.609 132.447Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M119.875 129.544V123.614L124.613 120.879V126.81L119.875 129.544Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M127.242 125.313V119.387L131.975 116.652V122.578L127.242 125.313Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M134.609 121.083V115.153L139.348 112.418V118.349L134.609 121.083Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M119.875 118.341V112.411L124.613 109.676V115.606L119.875 118.341Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M127.242 114.103V108.172L131.975 105.438V111.368L127.242 114.103Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M134.609 109.857V103.926L139.348 101.191V107.122L134.609 109.857Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M119.875 107.138V101.207L124.613 98.4727V104.403L119.875 107.138Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M127.242 102.888V96.9575L131.975 94.2227V100.153L127.242 102.888Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M134.609 98.6382V92.7075L139.348 89.9727V95.9034L134.609 98.6382Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M119.875 95.935V90.0043L124.613 87.2695V93.2002L119.875 95.935Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M127.242 91.6733V85.7426L131.975 83.0078V88.9385L127.242 91.6733Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M134.609 87.4116V81.4862L139.348 78.7461V84.6768L134.609 87.4116Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M119.875 84.7358V78.8051L124.613 76.0703V81.9957L119.875 84.7358Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M127.242 80.4624V74.5317L131.975 71.7969V77.7276L127.242 80.4624Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M134.609 76.1928V70.2621L139.348 67.5273V73.458L134.609 76.1928Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M119.875 73.5288V67.5981L124.613 64.8633V70.7887L119.875 73.5288Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M127.242 69.2475V63.3168L131.975 60.582V66.5127L127.242 69.2475Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M134.609 64.9663V59.0356L139.348 56.3008V62.2315L134.609 64.9663Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M119.875 62.4077V56.477L124.613 53.7422V59.6729L119.875 62.4077Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M127.242 58.0757V52.1503L131.975 49.4102V55.3409L127.242 58.0757Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M134.609 53.7514V47.8207L139.348 45.0859V51.0166L134.609 53.7514Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M119.875 51.2866V45.3559L124.613 42.6211V48.5465L119.875 51.2866Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M127.242 46.9077V40.977L131.975 38.2422V44.1729L127.242 46.9077Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M134.609 42.5366V36.6059L139.348 33.8711V39.8018L134.609 42.5366Z"
            fill="black"
          />
        </g>
        <path
          opacity="0.05"
          d="M114.665 11.8711L112.062 16.021L132.791 27.9884L137.667 25.1688L114.665 11.8711Z"
          fill="black"
        />
        <path
          d="M149.432 127.089L145.912 134.027L146.479 134.315L149.999 127.377L149.432 127.089Z"
          fill="#263238"
        />
        <path
          d="M142.906 126.532L142.365 126.867L145.799 132.415L146.34 132.08L142.906 126.532Z"
          fill="#263238"
        />
        <path
          d="M146.731 125.883V142.61C146.726 142.656 146.709 142.7 146.682 142.738C146.655 142.776 146.619 142.806 146.578 142.827C146.461 142.884 146.332 142.914 146.201 142.914C146.071 142.914 145.942 142.884 145.825 142.827C145.782 142.807 145.745 142.777 145.717 142.739C145.689 142.701 145.672 142.656 145.666 142.61V125.883H146.731Z"
          fill="#263238"
        />
        <path
          d="M151.606 128.443C155.895 128.443 159.371 124.967 159.371 120.679C159.371 116.39 155.895 112.914 151.606 112.914C147.318 112.914 143.842 116.39 143.842 120.679C143.842 124.967 147.318 128.443 151.606 128.443Z"
          fill="#455A64"
        />
        <path
          d="M154.044 118.718C155.046 115.565 156.111 114.335 155.433 113.948C154.147 113.215 152.678 112.865 151.199 112.939C149.721 113.014 148.294 113.509 147.088 114.367C145.882 115.226 144.946 116.411 144.391 117.783C143.836 119.155 143.684 120.658 143.955 122.113C144.226 123.569 144.907 124.917 145.918 125.998C146.929 127.079 148.228 127.849 149.662 128.216C151.096 128.583 152.606 128.533 154.012 128.071C155.419 127.609 156.664 126.754 157.601 125.608C155.29 126.292 152.746 122.783 154.044 118.718Z"
          fill="#37474F"
        />
        <path
          d="M144.767 123.763C145.25 120.787 143.229 117.983 140.253 117.5C137.277 117.017 134.473 119.038 133.99 122.014C133.507 124.99 135.528 127.794 138.504 128.277C141.48 128.76 144.284 126.739 144.767 123.763Z"
          fill="#455A64"
        />
        <path
          d="M139.39 117.422C138.248 117.422 137.136 117.782 136.21 118.45C136.062 121.789 138.892 125.038 138.102 126.13C137.312 127.222 135.362 126.554 134.684 125.626C135.165 126.454 135.856 127.14 136.687 127.617C137.517 128.093 138.459 128.342 139.416 128.34C140.374 128.337 141.314 128.083 142.142 127.602C142.97 127.122 143.657 126.432 144.135 125.602C144.612 124.772 144.862 123.831 144.861 122.873C144.86 121.916 144.606 120.975 144.127 120.146C143.647 119.318 142.958 118.63 142.128 118.151C141.299 117.673 140.358 117.422 139.401 117.422H139.39Z"
          fill="#37474F"
        />
        <path
          d="M155.94 119.289C157.21 113.908 153.877 108.516 148.496 107.245C143.114 105.975 137.722 109.308 136.452 114.69C135.182 120.071 138.515 125.463 143.896 126.733C149.278 128.004 154.67 124.671 155.94 119.289Z"
          fill="#455A64"
        />
        <path
          d="M155.948 119.28C155.568 120.899 154.79 122.399 153.685 123.642C152.58 124.885 151.183 125.834 149.619 126.402C148.056 126.97 146.376 127.139 144.73 126.895C143.085 126.65 141.526 126 140.196 125.002C138.865 124.004 137.803 122.691 137.108 121.18C136.412 119.669 136.103 118.008 136.21 116.348C136.317 114.689 136.836 113.081 137.72 111.672C138.603 110.263 139.824 109.096 141.272 108.277C140.405 108.77 139.616 109.388 138.929 110.111C138.755 110.862 138.666 111.63 138.664 112.401C138.663 114.405 139.263 116.364 140.387 118.023C141.511 119.683 143.107 120.967 144.969 121.709C146.831 122.451 148.872 122.618 150.83 122.186C152.787 121.755 154.57 120.747 155.948 119.291V119.28Z"
          fill="#37474F"
        />
        <path
          d="M203.054 201.85L144.939 168.275L192.3 141.012L250.431 174.534L203.054 201.85Z"
          fill="#FAFAFA"
        />
        <path
          d="M144.939 168.273L203.054 201.849V204.361L144.939 170.759V168.273Z"
          fill="#F0F0F0"
        />
        <path
          d="M250.431 174.531L203.055 201.847V204.36L250.431 176.99V174.531Z"
          fill="#E0E0E0"
        />
        <path
          d="M154.627 32.1953L170.771 41.518V178.258L154.627 168.935V32.1953Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.8"
          d="M154.627 32.1953L170.771 41.518V178.258L154.627 168.935V32.1953Z"
          fill="white"
        />
        <path
          d="M186.92 50.8359L203.064 60.1586V196.904L186.92 187.581V50.8359Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.8"
          d="M186.92 50.8359L203.064 60.1586V196.904L186.92 187.581V50.8359Z"
          fill="white"
        />
        <path
          d="M160.008 159.613V165.83L165.393 168.936V162.719L160.008 159.613Z"
          fill="#BA68C8"
        />
        <path
          d="M160.008 150.289V156.506L165.393 159.612V153.395L160.008 150.289Z"
          fill="#BA68C8"
        />
        <path
          d="M160.008 137.863V144.08L165.393 147.186V140.969L160.008 137.863Z"
          fill="#BA68C8"
        />
        <path
          d="M160.008 128.539V134.756L165.393 137.862V131.645L160.008 128.539Z"
          fill="#BA68C8"
        />
        <path
          d="M160.008 72.5938V78.8054L165.393 81.9165V75.6996L160.008 72.5938Z"
          fill="#BA68C8"
        />
        <path
          d="M160.008 63.2695V69.4811L165.393 72.5922V66.3753L160.008 63.2695Z"
          fill="#BA68C8"
        />
        <path
          d="M160.008 50.8359V57.0528L165.393 60.1586V53.947L160.008 50.8359Z"
          fill="#BA68C8"
        />
        <path
          d="M160.008 41.5195V47.7311L165.393 50.8369V44.6253L160.008 41.5195Z"
          fill="#BA68C8"
        />
        <path
          d="M192.301 178.258V184.475L197.686 187.581V181.364L192.301 178.258Z"
          fill="#BA68C8"
        />
        <path
          d="M192.301 168.938V175.154L197.686 178.26V172.049L192.301 168.938Z"
          fill="#BA68C8"
        />
        <path
          d="M192.301 156.508V162.725L197.686 165.831V159.614L192.301 156.508Z"
          fill="#BA68C8"
        />
        <path
          d="M192.301 147.184V153.401L197.686 156.506V150.289L192.301 147.184Z"
          fill="#BA68C8"
        />
        <path
          d="M192.301 91.2383V97.4499L197.686 100.561V94.3441L192.301 91.2383Z"
          fill="#BA68C8"
        />
        <path
          d="M192.301 81.9141V88.1257L197.686 91.2368V85.0199L192.301 81.9141Z"
          fill="#BA68C8"
        />
        <path
          d="M192.301 69.4883V75.6999L197.686 78.8057V72.5941L192.301 69.4883Z"
          fill="#BA68C8"
        />
        <path
          d="M192.301 60.1641V66.3757L197.686 69.4868V63.2699L192.301 60.1641Z"
          fill="#BA68C8"
        />
        <path
          d="M160.008 116.031V122.248L165.393 125.354V119.137L160.008 116.031Z"
          fill="#BA68C8"
        />
        <path
          d="M160.008 106.707V112.924L165.393 116.03V109.818L160.008 106.707Z"
          fill="#BA68C8"
        />
        <path
          d="M160.008 94.2812V100.498L165.393 103.604V97.3871L160.008 94.2812Z"
          fill="#BA68C8"
        />
        <path
          d="M160.008 84.957V91.1739L165.393 94.2797V88.0628L160.008 84.957Z"
          fill="#BA68C8"
        />
        <path
          d="M192.301 134.676V140.893L197.686 143.998V137.787L192.301 134.676Z"
          fill="#BA68C8"
        />
        <path
          d="M192.301 125.359V131.571L197.686 134.682V128.465L192.301 125.359Z"
          fill="#BA68C8"
        />
        <path
          d="M192.301 112.926V119.143L197.686 122.248V116.037L192.301 112.926Z"
          fill="#BA68C8"
        />
        <path
          d="M192.301 103.602V109.818L197.686 112.924V106.713L192.301 103.602Z"
          fill="#BA68C8"
        />
        <g opacity="0.15">
          <path
            opacity="0.15"
            d="M160.008 159.613V165.83L165.393 168.936V162.719L160.008 159.613Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M160.008 150.293V156.51L165.393 159.616V153.399L160.008 150.293Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M160.008 137.863V144.08L165.393 147.186V140.969L160.008 137.863Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M160.008 128.539V134.756L165.393 137.862V131.645L160.008 128.539Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M160.008 72.5938V78.8054L165.393 81.9165V75.6996L160.008 72.5938Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M160.008 63.2695V69.4811L165.393 72.5922V66.3753L160.008 63.2695Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M160.008 50.8359V57.0528L165.393 60.1586V53.947L160.008 50.8359Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M160.008 41.5195V47.7311L165.393 50.8369V44.6253L160.008 41.5195Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M192.301 178.258V184.475L197.686 187.581V181.364L192.301 178.258Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M192.301 168.938V175.154L197.686 178.26V172.049L192.301 168.938Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M192.301 156.508V162.725L197.686 165.831V159.614L192.301 156.508Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M192.301 147.188V153.404L197.686 156.51V150.293L192.301 147.188Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M192.301 91.2383V97.4499L197.686 100.561V94.3441L192.301 91.2383Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M192.301 81.918V88.1296L197.686 91.2407V85.0238L192.301 81.918Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M192.301 69.4883V75.6999L197.686 78.8057V72.5941L192.301 69.4883Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M192.301 60.1641V66.3757L197.686 69.4867V63.2699L192.301 60.1641Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M160.008 116.031V122.248L165.393 125.354V119.137L160.008 116.031Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M160.008 106.711V112.928L165.393 116.034V109.822L160.008 106.711Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M160.008 94.2812V100.498L165.393 103.604V97.3871L160.008 94.2812Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M160.008 84.957V91.1739L165.393 94.2797V88.0628L160.008 84.957Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M192.301 134.676V140.893L197.686 143.998V137.787L192.301 134.676Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M192.301 125.359V131.571L197.686 134.682V128.465L192.301 125.359Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M192.301 112.926V119.143L197.686 122.248V116.037L192.301 112.926Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M192.301 103.605V109.822L197.686 112.928V106.717L192.301 103.605Z"
            fill="black"
          />
        </g>
        <path
          d="M203.064 60.1574L240.742 38.4062V175.146L203.064 196.903V60.1574Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.65"
          d="M203.064 60.1574L240.742 38.4062V175.146L203.064 196.903V60.1574Z"
          fill="white"
        />
        <path
          d="M213.829 178.258V184.475L208.449 187.581V181.364L213.829 178.258Z"
          fill="#BA68C8"
        />
        <path
          d="M213.829 168.938V175.154L208.449 178.26V172.049L213.829 168.938Z"
          fill="#BA68C8"
        />
        <path
          d="M213.829 156.508V162.725L208.449 165.831V159.614L213.829 156.508Z"
          fill="#BA68C8"
        />
        <path
          d="M213.829 147.184V153.401L208.449 156.506V150.289L213.829 147.184Z"
          fill="#BA68C8"
        />
        <path
          d="M213.829 91.2383V97.4499L208.449 100.561V94.3441L213.829 91.2383Z"
          fill="#BA68C8"
        />
        <path
          d="M213.829 81.9141V88.1257L208.449 91.2368V85.0199L213.829 81.9141Z"
          fill="#BA68C8"
        />
        <path
          d="M213.829 69.4883V75.6999L208.449 78.8057V72.5941L213.829 69.4883Z"
          fill="#BA68C8"
        />
        <path
          d="M213.829 60.1641V66.3757L208.449 69.4868V63.2699L213.829 60.1641Z"
          fill="#BA68C8"
        />
        <path
          d="M224.592 172.082V178.294L219.213 181.405V175.188L224.592 172.082Z"
          fill="#BA68C8"
        />
        <path
          d="M224.592 162.762V168.979L219.213 172.084V165.868L224.592 162.762Z"
          fill="#BA68C8"
        />
        <path
          d="M224.592 150.332V156.544L219.213 159.655V153.438L224.592 150.332Z"
          fill="#BA68C8"
        />
        <path
          d="M224.592 141.012V147.229L219.213 150.334V144.118L224.592 141.012Z"
          fill="#BA68C8"
        />
        <path
          d="M224.592 85.0586V91.2755L219.213 94.3813V88.1697L224.592 85.0586Z"
          fill="#BA68C8"
        />
        <path
          d="M224.592 75.7422V81.9538L219.213 85.0596V78.848L224.592 75.7422Z"
          fill="#BA68C8"
        />
        <path
          d="M224.592 63.3086V69.5255L219.213 72.6313V66.4197L224.592 63.3086Z"
          fill="#BA68C8"
        />
        <path
          d="M224.592 53.9844V60.2013L219.213 63.3071V57.0955L224.592 53.9844Z"
          fill="#BA68C8"
        />
        <path
          d="M235.363 165.91V172.122L229.979 175.228V169.016L235.363 165.91Z"
          fill="#BA68C8"
        />
        <path
          d="M235.363 156.586V162.798L229.979 165.909V159.692L235.363 156.586Z"
          fill="#BA68C8"
        />
        <path
          d="M235.363 144.152V150.369L229.979 153.475V147.263L235.363 144.152Z"
          fill="#BA68C8"
        />
        <path
          d="M235.363 134.836V141.048L229.979 144.153V137.942L235.363 134.836Z"
          fill="#BA68C8"
        />
        <path
          d="M235.363 78.8828V85.0997L229.979 88.2055V81.9886L235.363 78.8828Z"
          fill="#BA68C8"
        />
        <path
          d="M235.363 69.5625V75.7794L229.979 78.8852V72.6736L235.363 69.5625Z"
          fill="#BA68C8"
        />
        <path
          d="M235.363 57.1328V63.3497L229.979 66.4555V60.2386L235.363 57.1328Z"
          fill="#BA68C8"
        />
        <path
          d="M235.363 47.8086V54.0255L229.979 57.1313V50.9144L235.363 47.8086Z"
          fill="#BA68C8"
        />
        <path
          d="M213.829 134.676V140.893L208.449 143.998V137.787L213.829 134.676Z"
          fill="#BA68C8"
        />
        <path
          d="M213.829 125.359V131.571L208.449 134.682V128.465L213.829 125.359Z"
          fill="#BA68C8"
        />
        <path
          d="M213.829 112.926V119.143L208.449 122.248V116.037L213.829 112.926Z"
          fill="#BA68C8"
        />
        <path
          d="M213.829 103.602V109.818L208.449 112.924V106.713L213.829 103.602Z"
          fill="#BA68C8"
        />
        <path
          d="M224.592 128.504V134.721L219.213 137.827V131.61L224.592 128.504Z"
          fill="#BA68C8"
        />
        <path
          d="M224.592 119.18V125.397L219.213 128.502V122.291L224.592 119.18Z"
          fill="#BA68C8"
        />
        <path
          d="M224.592 106.75V112.967L219.213 116.073V109.856L224.592 106.75Z"
          fill="#BA68C8"
        />
        <path
          d="M224.592 97.4297V103.647L219.213 106.752V100.535L224.592 97.4297Z"
          fill="#BA68C8"
        />
        <path
          d="M235.363 122.328V128.54L229.979 131.651V125.434L235.363 122.328Z"
          fill="#BA68C8"
        />
        <path
          d="M235.363 113.004V119.216L229.979 122.327V116.11L235.363 113.004Z"
          fill="#BA68C8"
        />
        <path
          d="M235.363 100.578V106.79L229.979 109.896V103.684L235.363 100.578Z"
          fill="#BA68C8"
        />
        <path
          d="M235.363 91.2539V97.4655L229.979 100.577V94.3597L235.363 91.2539Z"
          fill="#BA68C8"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M213.829 178.258V184.475L208.449 187.581V181.364L213.829 178.258Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M213.829 168.934V175.151L208.449 178.256V172.045L213.829 168.934Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M213.829 156.508V162.725L208.449 165.831V159.614L213.829 156.508Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M213.829 147.184V153.401L208.449 156.506V150.289L213.829 147.184Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M213.829 91.2383V97.4499L208.449 100.561V94.3441L213.829 91.2383Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M213.829 81.9141V88.1257L208.449 91.2368V85.0199L213.829 81.9141Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M213.829 69.4883V75.6999L208.449 78.8057V72.5941L213.829 69.4883Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M213.829 60.1641V66.3757L208.449 69.4868V63.2699L213.829 60.1641Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M224.592 172.082V178.294L219.213 181.405V175.188L224.592 172.082Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M224.592 162.762V168.979L219.213 172.084V165.868L224.592 162.762Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M224.592 150.332V156.544L219.213 159.655V153.438L224.592 150.332Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M224.592 141.008V147.225L219.213 150.331V144.114L224.592 141.008Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M224.592 85.0547V91.2716L219.213 94.3774V88.1658L224.592 85.0547Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M224.592 75.7383V81.9499L219.213 85.0557V78.8441L224.592 75.7383Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M224.592 63.3086V69.5255L219.213 72.6313V66.4197L224.592 63.3086Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M224.592 53.9844V60.2013L219.213 63.3071V57.0955L224.592 53.9844Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M235.363 165.91V172.122L229.979 175.228V169.016L235.363 165.91Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M235.363 156.586V162.798L229.979 165.909V159.692L235.363 156.586Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M235.363 144.152V150.369L229.979 153.475V147.263L235.363 144.152Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M235.363 134.836V141.048L229.979 144.153V137.942L235.363 134.836Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M235.363 78.8828V85.0997L229.979 88.2055V81.9886L235.363 78.8828Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M235.363 69.5586V75.7755L229.979 78.8813V72.6697L235.363 69.5586Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M235.363 57.1328V63.3497L229.979 66.4555V60.2386L235.363 57.1328Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M235.363 47.8086V54.0255L229.979 57.1313V50.9144L235.363 47.8086Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M213.829 134.676V140.893L208.449 143.998V137.787L213.829 134.676Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M213.829 125.359V131.571L208.449 134.682V128.465L213.829 125.359Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M213.829 112.926V119.143L208.449 122.248V116.037L213.829 112.926Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M213.829 103.602V109.818L208.449 112.924V106.713L213.829 103.602Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M224.592 128.5V134.717L219.213 137.823V131.606L224.592 128.5Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M224.592 119.18V125.397L219.213 128.502V122.291L224.592 119.18Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M224.592 106.75V112.967L219.213 116.073V109.856L224.592 106.75Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M224.592 97.4258V103.643L219.213 106.748V100.532L224.592 97.4258Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M235.363 122.328V128.54L229.979 131.651V125.434L235.363 122.328Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M235.363 113.004V119.216L229.979 122.327V116.11L235.363 113.004Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M235.363 100.574V106.786L229.979 109.892V103.68L235.363 100.574Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M235.363 91.2539V97.4655L229.979 100.577V94.3597L235.363 91.2539Z"
            fill="black"
          />
        </g>
        <path
          d="M170.771 41.5194V178.259L173.496 176.685V39.9453L170.771 41.5194Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.65"
          d="M170.771 41.5194V178.259L173.496 176.685V39.9453L170.771 41.5194Z"
          fill="white"
        />
        <path
          d="M240.741 38.4056L192.299 10.4375L154.627 32.194L170.771 41.5167L173.495 39.9426L189.644 49.26L186.92 50.8341L203.064 60.1568L240.741 38.4056Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.9"
          d="M240.741 38.4056L192.299 10.4375L154.627 32.194L170.771 41.5167L173.495 39.9426L189.644 49.26L186.92 50.8341L203.064 60.1568L240.741 38.4056Z"
          fill="white"
        />
        <path
          d="M192.221 50.8371L194.945 49.263L173.496 36.8875L170.772 38.4563L159.928 32.197L192.3 13.5039L235.442 38.4086L203.064 57.1017L192.221 50.8371Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.6"
          d="M192.221 50.8371L194.945 49.263L173.496 36.8875L170.772 38.4563L159.928 32.197L192.3 13.5039L235.442 38.4086L203.064 57.1017L192.221 50.8371Z"
          fill="white"
        />
        <path
          d="M192.301 13.5039V16.6574L232.708 39.988L235.443 38.4086L192.301 13.5039Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.7"
          d="M192.301 13.5039V16.6574L232.708 39.988L235.443 38.4086L192.301 13.5039Z"
          fill="white"
        />
        <path
          d="M159.928 32.197L162.7 33.7976L192.3 16.6574V13.5039L159.928 32.197Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.8"
          d="M159.928 32.197L162.7 33.7976L192.3 16.6574V13.5039L159.928 32.197Z"
          fill="white"
        />
        <path
          opacity="0.05"
          d="M192.301 13.4961L189.137 18.4251L229.39 41.8988L235.443 38.4061L192.301 13.4961Z"
          fill="black"
        />
        <path
          d="M173.496 39.9453V176.685L186.921 184.471V50.8368L189.645 49.2627L173.496 39.9453Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.45"
          d="M173.496 39.9453V176.685L186.921 184.471V50.8368L189.645 49.2627L173.496 39.9453Z"
          fill="black"
        />
        <path
          d="M176.156 178.26V168.938L184.228 173.596V182.914L176.156 178.26Z"
          fill="#263238"
        />
        <path
          d="M182.283 166.979L184.228 168.102V50.8345L182.283 49.7109V166.979Z"
          fill="#BA68C8"
        />
        <path
          d="M176.898 163.881L178.849 165.005V47.7369L176.898 46.6133V163.881Z"
          fill="#BA68C8"
        />
        <path
          d="M247.954 168.108L244.434 175.047L245.001 175.335L248.521 168.396L247.954 168.108Z"
          fill="#263238"
        />
        <path
          d="M241.978 166.624L241.438 166.961L244.895 172.507L245.436 172.17L241.978 166.624Z"
          fill="#263238"
        />
        <path
          d="M245.263 166.91V183.632C245.258 183.679 245.241 183.724 245.213 183.763C245.185 183.801 245.147 183.831 245.104 183.849C244.987 183.909 244.858 183.94 244.727 183.94C244.596 183.94 244.467 183.909 244.351 183.849C244.308 183.83 244.272 183.799 244.245 183.761C244.218 183.723 244.201 183.678 244.197 183.632V166.91H245.263Z"
          fill="#263238"
        />
        <path
          d="M250.14 169.47C254.428 169.47 257.904 165.994 257.904 161.706C257.904 157.418 254.428 153.941 250.14 153.941C245.851 153.941 242.375 157.418 242.375 161.706C242.375 165.994 245.851 169.47 250.14 169.47Z"
          fill="#455A64"
        />
        <path
          d="M252.571 159.718C253.573 156.565 254.638 155.33 253.965 154.948C252.679 154.221 251.212 153.875 249.737 153.952C248.261 154.029 246.838 154.526 245.635 155.383C244.431 156.241 243.498 157.424 242.944 158.794C242.389 160.164 242.238 161.664 242.506 163.117C242.775 164.57 243.452 165.916 244.459 166.998C245.466 168.079 246.761 168.85 248.192 169.222C249.622 169.593 251.129 169.548 252.534 169.092C253.94 168.637 255.187 167.79 256.128 166.651C253.828 167.318 251.273 163.81 252.571 159.718Z"
          fill="#37474F"
        />
        <path
          d="M237.9 169.363C240.915 169.363 243.359 166.919 243.359 163.904C243.359 160.889 240.915 158.445 237.9 158.445C234.885 158.445 232.441 160.889 232.441 163.904C232.441 166.919 234.885 169.363 237.9 169.363Z"
          fill="#455A64"
        />
        <path
          d="M237.902 158.469C236.76 158.471 235.648 158.83 234.722 159.497C234.579 162.831 237.409 166.085 236.614 167.177C235.819 168.268 233.874 167.601 233.195 166.673C233.677 167.501 234.368 168.187 235.198 168.664C236.029 169.14 236.97 169.389 237.928 169.387C238.885 169.384 239.825 169.13 240.654 168.649C241.482 168.169 242.169 167.479 242.646 166.649C243.124 165.819 243.374 164.878 243.373 163.92C243.371 162.962 243.118 162.022 242.639 161.193C242.159 160.365 241.47 159.677 240.64 159.198C239.811 158.72 238.87 158.469 237.912 158.469H237.902Z"
          fill="#37474F"
        />
        <path
          d="M254.46 160.309C255.73 154.927 252.397 149.535 247.015 148.265C241.634 146.995 236.242 150.328 234.972 155.709C233.702 161.091 237.034 166.483 242.416 167.753C247.797 169.023 253.189 165.69 254.46 160.309Z"
          fill="#455A64"
        />
        <path
          d="M254.474 160.307C254.094 161.927 253.317 163.427 252.212 164.671C251.107 165.915 249.709 166.864 248.146 167.432C246.582 168 244.901 168.17 243.255 167.925C241.609 167.681 240.051 167.03 238.72 166.032C237.388 165.033 236.327 163.719 235.632 162.208C234.936 160.696 234.628 159.035 234.736 157.375C234.843 155.715 235.363 154.107 236.248 152.698C237.133 151.289 238.355 150.123 239.804 149.305C238.934 149.796 238.143 150.414 237.456 151.139C237.281 151.889 237.192 152.657 237.191 153.428C237.189 155.432 237.789 157.391 238.913 159.051C240.037 160.71 241.633 161.994 243.495 162.736C245.357 163.479 247.399 163.645 249.356 163.214C251.313 162.783 253.096 161.774 254.474 160.318V160.307Z"
          fill="#37474F"
        />
        <path
          d="M62.7835 162.607L66.3079 161.807L65.1684 156.273L61.5645 157.238L62.7835 162.607Z"
          fill="#FFA8A7"
        />
        <path
          d="M62.2327 163.637C62.026 163.727 62.1532 164.559 62.2751 164.782C62.5414 165.179 62.9067 165.501 63.3351 165.714C63.9608 166.034 64.5048 166.492 64.9251 167.055C65.6331 167.914 66.5192 168.608 67.5221 169.09C68.1774 169.351 68.88 169.471 69.5847 169.444C70.2893 169.416 70.9804 169.242 71.6137 168.931C72.9705 168.295 73.0765 167.898 72.997 167.341L62.2327 163.637Z"
          fill="#455A64"
        />
        <path
          d="M66.2491 159.773C66.6413 160.605 67.6695 162.81 69.3284 163.748C70.6428 164.495 72.3971 165.306 73.0384 166.329C73.6797 167.352 71.3159 168.343 70.2559 168.396C69.4291 168.433 68.3373 168.523 67.4734 168.078C67.0475 167.857 66.6445 167.594 66.2703 167.294C65.523 166.711 64.9506 165.942 64.4047 165.471C63.8588 164.999 62.7458 164.586 62.3748 163.981C62.1669 163.385 62.0927 162.75 62.1575 162.121C62.1575 161.469 62.078 160.876 62.3748 160.77L62.4596 161.141C62.8712 161.149 63.2827 161.121 63.6892 161.056C63.8588 161.024 64.2881 161.496 64.4524 161.406C64.6167 161.315 64.251 160.955 64.4524 160.642C64.6454 160.349 64.9144 160.113 65.2308 159.96C65.5471 159.807 65.899 159.742 66.2491 159.773Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.3"
          d="M66.2491 159.773C66.6413 160.605 67.6695 162.81 69.3284 163.748C70.6428 164.495 72.3971 165.306 73.0384 166.329C73.6797 167.352 71.3159 168.343 70.2559 168.396C69.4291 168.433 68.3373 168.523 67.4734 168.078C67.0475 167.857 66.6445 167.594 66.2703 167.294C65.523 166.711 64.9506 165.942 64.4047 165.471C63.8588 164.999 62.7458 164.586 62.3748 163.981C62.1669 163.385 62.0927 162.75 62.1575 162.121C62.1575 161.469 62.078 160.876 62.3748 160.77L62.4596 161.141C62.8712 161.149 63.2827 161.121 63.6892 161.056C63.8588 161.024 64.2881 161.496 64.4524 161.406C64.6167 161.315 64.251 160.955 64.4524 160.642C64.6454 160.349 64.9144 160.113 65.2308 159.96C65.5471 159.807 65.899 159.742 66.2491 159.773Z"
          fill="black"
        />
        <path
          d="M67.4629 168.077C67.4629 168.077 67.6219 165.957 69.3391 164.977C71.0563 163.996 71.7771 164.706 72.0739 164.881C72.3707 165.056 73.807 166.021 73.0014 167.362C72.2661 168.04 71.3382 168.473 70.3461 168.601C69.3539 168.729 68.3464 168.546 67.4629 168.077Z"
          fill="#FAFAFA"
        />
        <path
          d="M44.6938 148.25L42.4731 151.43C42.3198 151.772 42.296 152.157 42.4062 152.515C42.5164 152.873 42.753 153.179 43.072 153.375C43.9677 154.038 45.1443 153.905 45.4146 153.592L47.4286 151.398L44.6938 148.25Z"
          fill="#FFA8A7"
        />
        <path
          d="M40.5445 152.687C40.3431 152.586 39.7919 153.217 39.7018 153.455C39.6323 153.934 39.6703 154.423 39.8131 154.886C40.0047 155.601 40.0781 156.342 40.0304 157.08C39.9288 158.104 40.0205 159.138 40.3007 160.128C40.5631 161.14 41.1565 162.035 41.9862 162.67C42.816 163.306 43.8346 163.646 44.8799 163.636C46.666 163.636 46.9999 162.916 46.9999 162.502L40.5445 152.687Z"
          fill="#455A64"
        />
        <path
          d="M46.0457 153.264C45.6694 154.091 44.9274 156.444 45.9503 158.474C46.6393 159.847 46.9626 161.182 46.5916 162.316C46.2206 163.451 41.9382 161.654 41.4135 160.726C40.881 159.796 40.6049 158.741 40.6132 157.668C40.5761 156.725 40.7563 156.105 40.6556 155.395C40.5549 154.684 40.1256 153.667 40.3641 152.994C40.6868 152.456 41.1227 151.995 41.6414 151.642C42.1714 151.239 42.5318 150.784 42.8021 150.943L42.6272 151.234C42.8256 151.586 43.0988 151.89 43.4275 152.125C43.56 152.231 43.4752 152.856 43.6501 152.925C43.825 152.994 43.8621 152.485 44.2119 152.395C44.5681 152.355 44.9284 152.415 45.2523 152.569C45.5762 152.722 45.851 152.963 46.0457 153.264Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.3"
          d="M46.0457 153.264C45.6694 154.091 44.9274 156.444 45.9503 158.474C46.6393 159.847 46.9626 161.182 46.5916 162.316C46.2206 163.451 41.9382 161.654 41.4135 160.726C40.881 159.796 40.6049 158.741 40.6132 157.668C40.5761 156.725 40.7563 156.105 40.6556 155.395C40.5549 154.684 40.1256 153.667 40.3641 152.994C40.6868 152.456 41.1227 151.995 41.6414 151.642C42.1714 151.239 42.5318 150.784 42.8021 150.943L42.6272 151.234C42.8256 151.586 43.0988 151.89 43.4275 152.125C43.56 152.231 43.4752 152.856 43.6501 152.925C43.825 152.994 43.8621 152.485 44.2119 152.395C44.5681 152.355 44.9284 152.415 45.2523 152.569C45.5762 152.722 45.851 152.963 46.0457 153.264Z"
          fill="black"
        />
        <path
          d="M41.4141 160.737C41.7669 160.246 42.2254 159.842 42.7556 159.553C43.2859 159.264 43.8744 159.098 44.4775 159.067C46.4438 159.041 46.8148 159.867 46.9844 160.164C47.154 160.461 47.8483 162.136 46.4968 162.904C45.5292 163.13 44.5148 163.042 43.6008 162.652C42.6869 162.262 41.9209 161.591 41.4141 160.737Z"
          fill="#FAFAFA"
        />
        <path
          d="M67.4003 112.837C67.4798 115.121 66.4887 126.087 64.8192 130.454C63.897 132.939 62.7343 135.328 61.3477 137.588C60.8495 138.441 47.4299 151.776 47.4299 151.776C46.65 151.763 45.8917 151.518 45.2523 151.071C44.613 150.625 44.1218 149.997 43.8418 149.269C43.8418 149.269 49.6347 140.211 50.9067 138.165C52.1787 136.12 55.9152 131.97 55.9152 131.97L56.5936 112.641L67.4003 112.837Z"
          fill="#455A64"
        />
        <path
          d="M61.3419 121.627C62.3778 120.671 63.3056 119.605 64.1085 118.447C66.4193 115.267 66.4193 112.521 66.4193 112.521L65.1155 112.41L51.4097 113.311C51.3188 115.802 51.356 118.297 51.521 120.784C52.051 126.985 56.291 138.804 56.291 138.804C56.2168 139.657 56.7256 143.044 57.5365 146.224C58.8191 151.238 61.8666 159.596 61.8666 159.596C61.8666 159.596 64.6915 159.596 65.778 158.335C65.778 158.335 63.658 141.539 63.128 137.135C62.5821 132.37 61.3419 121.627 61.3419 121.627Z"
          fill="#455A64"
        />
        <path
          d="M62.7622 134.223C62.7622 134.223 61.7022 123.051 61.3418 121.63C61.3418 121.63 63.9123 120.65 64.702 119.754C64.1389 120.756 63.305 121.579 62.2958 122.128L62.7622 134.223Z"
          fill="#37474F"
        />
        <path
          d="M54.541 133.559L56.2953 138.859C56.2953 138.859 56.4384 141.021 56.7193 142.489L55.9773 143.242C55.7404 141.842 55.6745 140.418 55.7812 139.002L54.541 133.559Z"
          fill="#37474F"
        />
        <path
          d="M65.3748 106.361C63.2548 99.4713 62.852 92.5813 62.4492 90.8164H62.5817C65.3324 90.9595 66.8217 92.0884 67.4524 94.7967C67.887 96.7418 69.0053 103.526 69.7208 106.52C70.3515 109.17 71.2048 112.435 72.2224 114.47C72.3932 114.815 72.6832 115.086 73.0386 115.234C74.0986 115.636 74.5491 116.161 75.3706 116.76C76.0013 117.221 76.4783 117.29 76.4306 117.47C76.363 117.686 76.2158 117.868 76.019 117.979C75.8221 118.09 75.5903 118.122 75.3706 118.069C74.8857 117.97 74.4142 117.813 73.9661 117.603C74.4272 118.363 75.007 119.045 75.6833 119.622C77.2256 120.947 77.4535 121.89 74.8989 122.367C73.3089 122.669 71.8461 121.912 70.007 118.599C69.307 117.392 68.6942 116.136 68.1732 114.841C66.9542 111.444 66.0161 108.444 65.3748 106.361Z"
          fill="#FFA8A7"
        />
        <path
          d="M70.7341 110.367L71.6775 113.213C71.6775 113.213 70.0875 113.113 68.1954 114.803C67.7626 113.967 67.4453 113.075 67.252 112.153C67.252 112.153 67.9463 110.659 70.7341 110.367Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.45"
          d="M70.7341 110.367L71.6775 113.213C71.6775 113.213 70.0875 113.113 68.1954 114.803C67.7626 113.967 67.4453 113.075 67.252 112.153C67.252 112.153 67.9463 110.659 70.7341 110.367Z"
          fill="black"
        />
        <path
          d="M62.0565 90.8165C62.0565 90.8165 64.1765 90.4402 65.5757 91.2988C66.9749 92.1574 67.7858 93.8746 68.3264 97.3938C68.867 100.913 69.4871 104.406 70.0012 106.202C70.8227 109.096 71.2626 109.769 70.7326 110.368C70.7326 110.368 68.1515 111.126 67.2505 112.17C66.7591 111.746 66.3927 111.197 66.1905 110.58C65.6923 109.361 64.4309 104.422 63.885 102.18C63.3391 99.9378 61.8392 92.6821 62.0565 90.8165Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.3"
          d="M62.0565 90.8165C62.0565 90.8165 64.1765 90.4402 65.5757 91.2988C66.9749 92.1574 67.7858 93.8746 68.3264 97.3938C68.867 100.913 69.4871 104.406 70.0012 106.202C70.8227 109.096 71.2626 109.769 70.7326 110.368C70.7326 110.368 68.1515 111.126 67.2505 112.17C66.7591 111.746 66.3927 111.197 66.1905 110.58C65.6923 109.361 64.4309 104.422 63.885 102.18C63.3391 99.9378 61.8392 92.6821 62.0565 90.8165Z"
          fill="black"
        />
        <path
          d="M51.3672 114.351V116.196C51.3672 116.196 53.678 118.369 58.8985 118.406C61.9871 118.45 64.9916 117.398 67.3785 115.438L67.4898 113.625L51.3672 114.351Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.45"
          d="M51.3672 114.351V116.196C51.3672 116.196 53.678 118.369 58.8985 118.406C61.9871 118.45 64.9916 117.398 67.3785 115.438L67.4898 113.625L51.3672 114.351Z"
          fill="black"
        />
        <path
          d="M67.2667 96.5245C66.9699 94.0494 65.8834 91.039 62.0568 90.8164L57.1172 90.9595C55.8929 91.2563 54.5361 91.4895 53.2588 91.9294L50.8791 102.439C50.8791 102.439 50.1424 112.456 51.372 114.354C54.8647 116.659 62.555 117.205 67.4575 113.628C68.597 111.349 67.5582 98.9784 67.2667 96.5245Z"
          fill="#455A64"
        />
        <path
          d="M67.2667 96.5245C66.9699 94.0494 65.8834 91.039 62.0568 90.8164C62.0568 90.8164 63.3076 93.6095 61.7865 95.438C60.6086 95.1687 59.5033 94.6467 58.5471 93.9083C57.5908 93.1698 56.8063 92.2324 56.248 91.1609C55.2675 91.3835 54.2393 91.6061 53.2588 91.9294L50.8791 102.439C50.8791 102.439 50.1424 112.456 51.372 114.354C54.8647 116.659 62.555 117.205 67.4575 113.628C68.597 111.349 67.5582 98.9784 67.2667 96.5245Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.15"
          d="M67.2667 96.5245C66.9699 94.0494 65.8834 91.039 62.0568 90.8164C62.0568 90.8164 63.3076 93.6095 61.7865 95.438C60.6086 95.1687 59.5033 94.6467 58.5471 93.9083C57.5908 93.1698 56.8063 92.2324 56.248 91.1609C55.2675 91.3835 54.2393 91.6061 53.2588 91.9294L50.8791 102.439C50.8791 102.439 50.1424 112.456 51.372 114.354C54.8647 116.659 62.555 117.205 67.4575 113.628C68.597 111.349 67.5582 98.9784 67.2667 96.5245Z"
          fill="black"
        />
        <path
          d="M57.3097 81.141C57.2826 82.4344 57.7696 83.6858 58.6638 84.6207C59.5579 85.5556 60.7863 86.0977 62.0797 86.1283C64.7297 86.1283 66.3197 83.8334 66.8497 81.141C67.9097 76.212 64.7297 76.1484 62.0797 76.1484C60.7859 76.1804 59.5575 76.7238 58.6634 77.6595C57.7694 78.5953 57.2826 79.8471 57.3097 81.141Z"
          fill="#263238"
        />
        <path
          d="M54.8594 83.1562C55.1313 84.5688 55.4763 85.9664 55.8929 87.3432C56.0812 87.7051 56.4051 87.9776 56.7939 88.1011L56.7409 85.5731L54.8594 83.1562Z"
          fill="#263238"
        />
        <path
          d="M55.7829 78.0111C55.7829 78.0111 55.2529 77.8786 54.7229 78.7531C54.2459 79.5004 54.1346 81.1858 54.8872 83.1574L56.2758 83.327L55.7829 78.0111Z"
          fill="#263238"
        />
        <path
          d="M55.274 78.735L53.578 78.7668C53.5647 78.6562 53.5746 78.544 53.6072 78.4374C53.6397 78.3309 53.6941 78.2323 53.767 78.1479C53.8398 78.0636 53.9294 77.9955 54.0302 77.9478C54.1309 77.9001 54.2404 77.874 54.3518 77.8711C54.5859 77.8731 54.8106 77.9631 54.9815 78.1231C55.1523 78.2831 55.2567 78.5016 55.274 78.735Z"
          fill="#263238"
        />
        <path
          d="M55.0666 78.3344L53.8105 77.126C53.8831 77.0405 53.9727 76.9709 54.0735 76.9218C54.1743 76.8727 54.2843 76.845 54.3963 76.8405C54.5084 76.836 54.6202 76.8548 54.7246 76.8958C54.8291 76.9367 54.9239 76.9988 55.003 77.0783C55.1695 77.2414 55.2685 77.4611 55.2803 77.6939C55.2921 77.9266 55.2158 78.1553 55.0666 78.3344Z"
          fill="#263238"
        />
        <path
          d="M56.6621 83.3274C56.3812 83.497 55.989 82.9458 55.6445 82.5695C55.4999 82.424 55.3198 82.3186 55.1221 82.2638C54.9243 82.209 54.7157 82.2066 54.5168 82.2569C54.3179 82.3071 54.1354 82.4083 53.9875 82.5505C53.8395 82.6927 53.7311 82.8709 53.6729 83.0677C53.1058 84.3132 54.0651 85.5004 54.8601 85.8979C56.2911 86.6081 56.7416 85.5746 56.7416 85.5746L56.8582 91.2668C57.5737 93.2384 62.9585 93.62 61.7925 91.1184V89.3853C62.4665 89.4977 63.1509 89.535 63.833 89.4966C64.9513 89.3164 65.6509 88.3995 65.9901 87.1434C66.5201 85.1241 66.7374 83.497 66.2763 79.5273C65.7463 75.176 60.9127 75.1283 58.2892 76.8508C55.6657 78.5733 56.6621 83.3274 56.6621 83.3274Z"
          fill="#FFA8A7"
        />
        <path
          d="M62.7204 78.842C61.5597 78.7307 60.4096 78.4922 59.2489 78.4074C58.9978 78.3747 58.7429 78.3873 58.4963 78.4445C57.6006 78.683 57.7172 79.7218 57.5741 80.4426C57.3303 81.6881 57.1289 83.8028 56.6625 83.8134C56.5247 83.8134 55.9894 82.8965 55.6449 82.5679C55.4552 82.2869 55.3487 81.9581 55.3375 81.6192C55.2531 81.1013 55.1947 80.5795 55.1626 80.0557C55.1255 79.6158 55.0831 79.1653 55.0831 78.7201C55.0633 78.2208 55.1025 77.7209 55.1997 77.2308C55.31 76.8122 55.5231 76.4278 55.8198 76.1125L55.9099 76.0171C56.4929 75.4057 57.2018 74.9283 57.9875 74.6179C59.1155 74.1941 60.2992 73.937 61.5014 73.8547C62.7023 73.7713 63.8982 73.6262 65.0842 73.4201C65.1519 73.4016 65.2233 73.4016 65.2909 73.4201C65.3397 73.4458 65.3811 73.4837 65.4108 73.5302C65.4405 73.5766 65.4577 73.63 65.4605 73.6851C65.5089 73.9598 65.4783 74.2426 65.3723 74.5007C65.2663 74.7587 65.0892 74.9813 64.8616 75.1426C65.6619 75.2062 66.5841 74.7875 67.3579 74.5808C67.4838 74.5307 67.6242 74.5307 67.7501 74.5808C67.8265 74.6341 67.881 74.7131 67.9038 74.8034C67.9516 75.0145 67.9516 75.2336 67.9038 75.4447C67.8308 76.0827 67.5934 76.6909 67.2148 77.2096C66.615 77.9856 65.771 78.5364 64.8192 78.7731C64.1272 78.9026 63.4194 78.9258 62.7204 78.842Z"
          fill="#263238"
        />
        <path
          d="M61.7879 89.3825C60.6385 89.1565 59.5133 88.821 58.4277 88.3808C57.9114 88.0975 57.4922 87.6653 57.2246 87.1406C57.3558 87.6678 57.588 88.1646 57.9083 88.6034C58.5443 89.4514 61.7879 90.0715 61.7879 90.0715V89.3825Z"
          fill="#F28F8F"
        />
        <path
          d="M61.1887 82.6784C61.1825 82.7754 61.1481 82.8684 61.0898 82.946C61.0314 83.0237 60.9516 83.0825 60.8602 83.1154C60.7688 83.1483 60.6698 83.1538 60.5753 83.1311C60.4809 83.1084 60.3951 83.0586 60.3286 82.9879C60.262 82.9171 60.2176 82.8284 60.2009 82.7327C60.1841 82.6371 60.1956 82.5386 60.2341 82.4494C60.2725 82.3602 60.3362 82.2842 60.4173 82.2307C60.4984 82.1772 60.5934 82.1486 60.6905 82.1484C60.8255 82.1566 60.9523 82.216 61.0449 82.3145C61.1375 82.413 61.189 82.5432 61.1887 82.6784Z"
          fill="#263238"
        />
        <path
          d="M60.3875 80.5295L59.3276 81.1443C59.2504 80.9924 59.2331 80.8171 59.2793 80.6531C59.3254 80.4891 59.4316 80.3485 59.5767 80.2592C59.647 80.2206 59.7246 80.197 59.8045 80.1898C59.8844 80.1827 59.9649 80.192 60.0411 80.2174C60.1172 80.2428 60.1872 80.2836 60.2469 80.3373C60.3065 80.391 60.3544 80.4564 60.3875 80.5295Z"
          fill="#263238"
        />
        <path
          d="M61.559 86.2617L62.6985 86.7069C62.6765 86.7855 62.6384 86.8587 62.5867 86.922C62.5351 86.9852 62.4709 87.0371 62.3983 87.0744C62.3257 87.1117 62.2461 87.1336 62.1647 87.1388C62.0832 87.1439 62.0015 87.1323 61.9247 87.1044C61.7692 87.0354 61.6453 86.9105 61.5776 86.7544C61.5098 86.5983 61.5032 86.4225 61.559 86.2617Z"
          fill="#F28F8F"
        />
        <path
          d="M65.7623 80.9858L64.7871 80.2067C64.8309 80.1386 64.8886 80.0804 64.9563 80.036C65.0241 79.9916 65.1004 79.9619 65.1804 79.9489C65.2604 79.9359 65.3422 79.9399 65.4205 79.9605C65.4989 79.9812 65.572 80.0182 65.6351 80.0689C65.7654 80.1794 65.8503 80.3339 65.8738 80.5031C65.8973 80.6722 65.8576 80.8441 65.7623 80.9858Z"
          fill="#263238"
        />
        <path
          d="M65.3333 82.53C65.3271 82.6269 65.2927 82.7199 65.2343 82.7976C65.1759 82.8752 65.0961 82.9341 65.0047 82.967C64.9133 82.9999 64.8143 83.0053 64.7198 82.9826C64.6254 82.96 64.5396 82.9102 64.4731 82.8394C64.4066 82.7686 64.3622 82.68 64.3454 82.5843C64.3286 82.4886 64.3402 82.3901 64.3786 82.3009C64.4171 82.2117 64.4808 82.1357 64.5619 82.0823C64.643 82.0288 64.7379 82.0002 64.8351 82C64.9701 82.0081 65.0968 82.0675 65.1895 82.166C65.2821 82.2646 65.3335 82.3948 65.3333 82.53Z"
          fill="#263238"
        />
        <path
          d="M62.666 82.1133L62.7243 85.2615L64.5104 84.8534L62.666 82.1133Z"
          fill="#F28F8F"
        />
        <path
          d="M54.6592 118.256C53.9172 117.726 53.7105 117.313 52.7459 116.841C52.3273 116.637 51.9637 116.335 51.6859 115.962C50.5411 114.107 49.1366 110.132 48.6066 107.916C50.345 104.847 52.6823 99.8602 52.6823 99.8602C52.6823 99.8602 55.9577 96.8816 53.2441 91.9102C51.2036 91.9102 49.0677 93.5903 48.3787 95.2333C47.0855 98.3338 45.0609 102.653 43.9532 107.185C43.7942 107.771 43.7942 108.39 43.9532 108.976C44.5521 111.096 46.476 114.684 48.1932 117.573C49.0438 118.951 49.9747 120.279 50.981 121.548C51.3882 121.994 51.8372 122.401 52.3219 122.761C52.9447 122.54 53.4964 122.155 53.9189 121.647C54.3414 121.138 54.6191 120.526 54.7228 119.873C54.3572 119.632 54.0163 119.355 53.7052 119.046C54.0715 119.268 54.4789 119.414 54.903 119.475C55.539 119.475 55.8835 119.242 55.8729 119.014C55.8623 118.786 55.2846 118.718 54.6592 118.256Z"
          fill="#FFA8A7"
        />
        <path
          d="M54.4248 91.5898C52.7341 91.6375 50.4074 91.4573 48.7379 94.0225C47.0684 96.5877 43.899 105.306 43.4379 106.615C42.9768 107.924 43.6128 110.755 44.8053 112.641C45.9978 114.528 46.5808 114.687 46.5808 114.687C46.5808 114.687 48.1708 112.684 50.1689 112.663C50.1689 112.663 50.2908 112.191 49.8244 110.378C49.2944 108.391 49.1089 107.765 49.1089 107.765L53.0309 100.171C53.8813 98.7981 54.4237 97.2574 54.6209 95.6549C55.082 92.5279 54.4248 91.5898 54.4248 91.5898Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.3"
          d="M54.4248 91.5898C52.7341 91.6375 50.4074 91.4573 48.7379 94.0225C47.0684 96.5877 43.899 105.306 43.4379 106.615C42.9768 107.924 43.6128 110.755 44.8053 112.641C45.9978 114.528 46.5808 114.687 46.5808 114.687C46.5808 114.687 48.1708 112.684 50.1689 112.663C50.1689 112.663 50.2908 112.191 49.8244 110.378C49.2944 108.391 49.1089 107.765 49.1089 107.765L53.0309 100.171C53.8813 98.7981 54.4237 97.2574 54.6209 95.6549C55.082 92.5279 54.4248 91.5898 54.4248 91.5898Z"
          fill="black"
        />
        <path
          d="M46.5645 114.686L48.0432 117.299C48.0432 117.299 49.2357 115.29 51.3663 115.38L50.1526 112.661C50.1526 112.661 48.0856 112.29 46.5645 114.686Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.45"
          d="M46.5645 114.686L48.0432 117.299C48.0432 117.299 49.2357 115.29 51.3663 115.38L50.1526 112.661C50.1526 112.661 48.0856 112.29 46.5645 114.686Z"
          fill="black"
        />
        <path
          d="M61.7864 95.4352C59.9049 94.5395 57.6365 92.8223 57.3238 91.9266C56.9827 91.233 56.8119 90.4681 56.8256 89.6953C55.533 89.7308 54.265 90.0568 53.1156 90.6493C52.9219 90.7524 52.7587 90.9046 52.6425 91.0906C52.5262 91.2767 52.4609 91.49 52.4531 91.7093L54.4406 91.6086C54.6732 91.5886 54.9073 91.621 55.1256 91.7036C55.3439 91.7861 55.5409 91.9167 55.702 92.0856C56.1366 92.5096 56.5871 93.7233 58.4845 94.7091C59.5064 95.2255 60.6422 95.4752 61.7864 95.4352Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.35"
          d="M61.7864 95.4352C59.9049 94.5395 57.6365 92.8223 57.3238 91.9266C56.9827 91.233 56.8119 90.4681 56.8256 89.6953C55.533 89.7308 54.265 90.0568 53.1156 90.6493C52.9219 90.7524 52.7587 90.9046 52.6425 91.0906C52.5262 91.2767 52.4609 91.49 52.4531 91.7093L54.4406 91.6086C54.6732 91.5886 54.9073 91.621 55.1256 91.7036C55.3439 91.7861 55.5409 91.9167 55.702 92.0856C56.1366 92.5096 56.5871 93.7233 58.4845 94.7091C59.5064 95.2255 60.6422 95.4752 61.7864 95.4352Z"
          fill="black"
        />
        <path
          d="M61.7871 95.4378C62.5645 94.9803 63.1344 94.2394 63.3771 93.3707C63.6031 92.503 63.5303 91.5844 63.1704 90.7631C63.0284 90.5078 62.8317 90.287 62.5944 90.1166C62.3571 89.9462 62.085 89.8303 61.7977 89.7773V90.8373C62.0732 91.2347 62.2545 91.6897 62.3277 92.1676C62.3966 92.8937 62.5397 94.3089 61.7871 95.4378Z"
          fill="#BA68C8"
        />
        <path
          opacity="0.35"
          d="M61.7871 95.4378C62.5645 94.9803 63.1344 94.2394 63.3771 93.3707C63.6031 92.503 63.5303 91.5844 63.1704 90.7631C63.0284 90.5078 62.8317 90.287 62.5944 90.1166C62.3571 89.9462 62.085 89.8303 61.7977 89.7773V90.8373C62.0732 91.2347 62.2545 91.6897 62.3277 92.1676C62.3966 92.8937 62.5397 94.3089 61.7871 95.4378Z"
          fill="black"
        />
        <path
          d="M61.3409 104.063C61.3209 104.061 61.3014 104.055 61.2838 104.046C61.2661 104.036 61.2506 104.023 61.2382 104.007C61.2258 103.991 61.2168 103.973 61.2117 103.953C61.2065 103.934 61.2054 103.914 61.2084 103.894C61.5357 101.232 61.484 98.5377 61.0547 95.8907C61.0544 95.8535 61.0672 95.8173 61.0908 95.7885C61.1145 95.7598 61.1475 95.7402 61.1841 95.7333C61.2207 95.7263 61.2585 95.7324 61.291 95.7506C61.3236 95.7687 61.3487 95.7976 61.3621 95.8324C61.78 98.5131 61.8192 101.239 61.4787 103.931C61.4741 103.965 61.4579 103.997 61.4329 104.021C61.4079 104.045 61.3754 104.06 61.3409 104.063Z"
          fill="#455A64"
        />
        <path
          d="M61.1133 103.293C61.1147 103.214 61.1471 103.138 61.2036 103.083C61.2601 103.027 61.3362 102.996 61.4154 102.996C61.4879 103.006 61.5545 103.041 61.6028 103.097C61.651 103.152 61.6776 103.222 61.6776 103.296C61.6776 103.369 61.651 103.439 61.6028 103.495C61.5545 103.55 61.4879 103.585 61.4154 103.595C61.3353 103.595 61.2584 103.563 61.2018 103.507C61.1451 103.45 61.1133 103.373 61.1133 103.293Z"
          fill="#455A64"
        />
        <path
          d="M63.1649 105.692C63.1446 105.69 63.125 105.684 63.1071 105.675C63.0892 105.665 63.0734 105.652 63.0606 105.636C63.0478 105.62 63.0382 105.602 63.0324 105.583C63.0267 105.563 63.0249 105.543 63.0271 105.522C63.3826 102.167 63.1404 98.7759 62.3116 95.5055C62.3015 95.4662 62.3069 95.4245 62.3267 95.389C62.3464 95.3535 62.3789 95.327 62.4176 95.3147C62.437 95.309 62.4575 95.3073 62.4776 95.3096C62.4978 95.3118 62.5173 95.3181 62.535 95.3279C62.5528 95.3378 62.5684 95.3511 62.581 95.367C62.5936 95.3829 62.6029 95.4011 62.6084 95.4207C63.4468 98.7292 63.6926 102.16 63.3345 105.554C63.3325 105.575 63.3265 105.594 63.3169 105.612C63.3073 105.63 63.2942 105.646 63.2784 105.659C63.2626 105.671 63.2445 105.681 63.225 105.687C63.2055 105.692 63.1851 105.694 63.1649 105.692Z"
          fill="#455A64"
        />
        <path
          d="M62.9258 104.937C62.9258 104.859 62.957 104.783 63.0127 104.728C63.0684 104.672 63.1439 104.641 63.2226 104.641C63.3018 104.641 63.3778 104.672 63.4343 104.727C63.4908 104.783 63.5233 104.858 63.5247 104.937C63.5233 105.017 63.491 105.093 63.4347 105.15C63.3783 105.206 63.3023 105.238 63.2226 105.24C63.1434 105.238 63.0679 105.206 63.0124 105.149C62.9569 105.093 62.9258 105.017 62.9258 104.937Z"
          fill="#455A64"
        />
      </svg>

      <Link to="/signup/investor/questions">
        <div className="successFulsignupBtn">Start Investing</div>
      </Link>
    </div>
  );
};

export default SuccessfulSignup;
