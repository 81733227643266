import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  showDealsroomData: true,
  dealsroomData: null,
};

const dealsroomSlice = createSlice({
  name: "dealsroom",
  initialState,
  reducers: {
    setShowDealsroomData: (state, action) => {
      state.showDealsroomData = action.payload;
    },
    setDealsroomData: (state, action) => {
      state.dealsroomData = action.payload;
    },
    clearDealsroomData: (state) => {
      state.dealsroomData = null;
    },
  },
});

export const {setShowDealsroomData, setDealsroomData, clearDealsroomData} = dealsroomSlice.actions;
export default dealsroomSlice.reducer;
