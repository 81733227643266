import React, {useState, useEffect} from "react";
import {Dialog, DialogTitle, DialogContent, Button, Typography, Box, IconButton, Grid, Divider, Select, MenuItem, FormControl, InputLabel} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {format} from "date-fns";
import "./TimeTableBar.scss";

export const TaskDetailsModal = ({isOpen, onClose, tasks}) => {
  const [selectedTaskId, setSelectedTaskId] = useState("");
  const formatDateTime = (date) => (date ? format(new Date(date), "MMMM dd, yyyy hh:mm a") : "N/A");
  const selectedTask = tasks?.find((task) => task?._id === selectedTaskId);

  useEffect(() => {
    if (tasks && tasks?.length > 0) {
      setSelectedTaskId(tasks[0]?._id);
    }
  }, [tasks]);

  const handleTaskChange = (event) => {
    setSelectedTaskId(event.target.value);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      className="taskmodelpopup-modal"
    >
      <DialogTitle className="taskmodelpopup-title">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">Task Details</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers
        className="taskmodelpopup-content"
      >
        {tasks && tasks?.length > 0 ? (
          <>
            <FormControl
              fullWidth
              sx={{marginBottom: 2}}
            >
              <Select
                labelId="task-select-label"
                value={selectedTaskId}
                onChange={handleTaskChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200, 
                      overflow: "auto", 
                    },
                  },
                }}
              >
                {tasks?.map((task) => (
                  <MenuItem
                    key={task?._id}
                    value={task?._id}
                  >
                    {task?.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Task Details */}
            {selectedTask && (
              <Box>
                <Grid
                  container
                  spacing={2}
                >
                  {/* Task Name */}
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                    >
                      Task Name
                    </Typography>
                    <Typography variant="body1">{selectedTask?.Name || "N/A"}</Typography>
                  </Grid>

                  {/* Description */}
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                    >
                      Description
                    </Typography>
                    <Typography variant="body1">{selectedTask?.desc || "N/A"}</Typography>
                  </Grid>

                  {/* Status */}
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                    >
                      Status
                    </Typography>
                    <Typography variant="body1">{selectedTask?.status || "N/A"}</Typography>
                  </Grid>

                  {/* Assigned Employees */}
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                    >
                      Assigned Employees
                    </Typography>
                    <Typography variant="body1">
                      {selectedTask?.employee_id?.length > 0 ? selectedTask?.employee_id?.map((employee) => `${employee?.name} ${employee?.surname}`).join(", ") : ""}
                    </Typography>
                  </Grid>

                  {/* Date */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                    >
                      Deadline
                    </Typography>
                    <Typography variant="body1">{formatDateTime(selectedTask?.date)}</Typography>
                  </Grid>

                  {/* Created At */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                    >
                      Created At
                    </Typography>
                    <Typography variant="body1">{formatDateTime(selectedTask?.createdAt)}</Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </>
        ) : (
          <Typography
            variant="body1"
            color="textSecondary"
          >
            No tasks available.
          </Typography>
        )}
      </DialogContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          className="taskmodelpopup-close-button"
        >
          Close
        </Button>
      </Box>
    </Dialog>
  );
};
