import React, {useEffect, useRef, useState} from "react";
import "./InvestmentCard.scss";
import OfficePic1 from "../../../../../assets/investor-pipeline/icons/office_pic2.svg";
import CoverPic1 from "../../../../../assets/investor-pipeline/icons/cover_pic1.svg";
import CoverPic2 from "../../../../../assets/investor-pipeline/icons/cover_pic2.svg";
import CoverPic3 from "../../../../../assets/investor-pipeline/icons/cover_pic3.svg";
import delBtnIcon from "../../../../../assets/investor-pipeline/icons/delete_icon_red.svg";
import btn3DotsIcon from "../../../../../assets/investor-pipeline/icons/btn_3dots.svg";
const InvestmentCard = () => {
  const data = [
    {
      office_picture: OfficePic1,
      cover_picture: CoverPic1,
      investmentEntity: "351 California St, LLC",
      address: "Address",
      committed: "$16,542,250",
      contributions: "$16,542,250",
      distributed: "$6,542,250",
      netAssetValue: "$16,542,250",
      equityBalance: "$16,542,250",
      yield: "3.5%",
      cashFlow: {
        progress1: "30%",
        progress2: "50%",
      },
    },
    {
      office_picture: OfficePic1,
      cover_picture: CoverPic2,
      investmentEntity: "123 Main St, Inc.",
      address: "123 Main St, Los Angeles, CA",
      committed: "$8,750,000",
      contributions: "$7,200,000",
      distributed: "$3,500,000",
      netAssetValue: "$10,000,000",
      equityBalance: "$5,250,000",
      yield: "4.2%",
      cashFlow: {
        progress1: "45%",
        progress2: "35%",
      },
    },
    {
      office_picture: OfficePic1,
      cover_picture: CoverPic3,
      investmentEntity: "789 Broadway, Ltd.",
      address: "789 Broadway, New York, NY",
      committed: "$12,000,000",
      contributions: "$11,500,000",
      distributed: "$4,000,000",
      netAssetValue: "$9,000,000",
      equityBalance: "$8,500,000",
      yield: "5.0%",
      cashFlow: {
        progress1: "25%",
        progress2: "40%",
      },
    },
    {
      office_picture: OfficePic1,
      cover_picture: CoverPic1,
      investmentEntity: "456 Market St, Corp.",
      address: "456 Market St, Chicago, IL",
      committed: "$18,500,000",
      contributions: "$15,000,000",
      distributed: "$7,000,000",
      netAssetValue: "$14,000,000",
      equityBalance: "$9,500,000",
      yield: "3.8%",
      cashFlow: {
        progress1: "50%",
        progress2: "30%",
      },
    },
    {
      office_picture: OfficePic1,
      cover_picture: CoverPic1,
      investmentEntity: "101 First Ave, LLP",
      address: "101 First Ave, Austin, TX",
      committed: "$9,000,000",
      contributions: "$8,500,000",
      distributed: "$2,500,000",
      netAssetValue: "$7,000,000",
      equityBalance: "$6,500,000",
      yield: "4.5%",
      cashFlow: {
        progress1: "35%",
        progress2: "45%",
      },
    },
  ];

  const handleClickOutside = (e) => {
    if (deleteBoxRef.current && !deleteBoxRef.current.contains(e.target)) {
      setIsDeleteVisible(null);
    }
  };

  // useEffect(() => {
  //   document?.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document?.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const [isDeleteVisible, setIsDeleteVisible] = useState(null);
  const deleteBoxRef = useRef(null);

  const handleActionBtnClick = (e, index) => {
    e.stopPropagation();
    setIsDeleteVisible((prev) => (prev === index ? null : index));
  };

  return (
    <div className="investment_card_wrapper">
      {data.map((item, index) => (
        <div
          className="investment_card"
          key={index}
        >
          <div className="investment_card_header">
            <div
              className="topSection"
              style={{backgroundImage: `url(${item.cover_picture})`}}
            >
              <div className="investment_card_checkbox">
                <input type="checkbox" />
              </div>
              <img
                src={item.office_picture}
                alt="header"
                className="investment_card_header_img"
              />
              <div
                className="btn_3dots"
                onClick={(e) => handleActionBtnClick(e, index)}
              >
                <button>
                  <img
                    src={btn3DotsIcon}
                    alt="btn_3dots"
                  />
                </button>{" "}
                {isDeleteVisible === index && (
                  <div
                    className="del_btn_wrapper option_btn_wrapper"
                    ref={deleteBoxRef}
                  >
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <img
                        src={delBtnIcon}
                        alt="action_icon"
                      />
                      Delete
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="bottomSection">
              <div className="investment_card_header_title">
                <h2>{item.investmentEntity}</h2>
                <p>{item.address}</p>
              </div>
            </div>
          </div>
          <div className="investment_card_body">
            <div className="investment_card_detail">
              <span className="investment_card_detail_label">Committed</span>
              <span className="investment_card_detail_value">{item.committed}</span>
            </div>
            <div className="investment_card_detail">
              <span className="investment_card_detail_label">Contributions</span>
              <span className="investment_card_detail_value">{item.contributions}</span>
            </div>
            <div className="investment_card_detail">
              <span className="investment_card_detail_label">Distributed</span>
              <span className="investment_card_detail_value">{item.distributed}</span>
            </div>
            <div className="investment_card_detail">
              <span className="investment_card_detail_label">Net Asset Value</span>
              <span className="investment_card_detail_value">{item.netAssetValue}</span>
            </div>
            <div className="investment_card_detail">
              <span className="investment_card_detail_label">Equity Balance</span>
              <span className="investment_card_detail_value">{item.equityBalance}</span>
            </div>
            <div className="investment_card_detail">
              <span className="investment_card_detail_label">Yield</span>
              <span className="investment_card_detail_value">{item.yield}</span>
            </div>
            <div className="investment_card_cash_flow">
              <span className="investment_card_detail_label">Cash Flow</span>
              <div className="cash_flow_bars">
                <div className="progressbar_container wrapper1">
                  <div
                    className="progress-bar blue"
                    style={{width: item.cashFlow.progress1}}
                  ></div>
                </div>
                <div className="progressbar_container wrapper2">
                  <div
                    className="progress-bar green"
                    style={{width: item.cashFlow.progress2}}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className="investment_card_footer">
            <button>VIEW DETAILS</button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default InvestmentCard;
