import React, {useState} from "react";
import "./Deals.scss";
import SvgColorChanger from "../DealsRoom/SvgColorChanger";

const LeftMenu = ({icon, name, id, state, handleMenuClicked}) => {
  return (
    <div
      className={`deals-left-menu ${id === state && "deals-menu-active"}`}
      onClick={() => {
        handleMenuClicked(id);
      }}
    >
      <SvgColorChanger svgContent={icon} />
      <span className="left-menu-title">{name} </span>
    </div>
  );
};

export default LeftMenu;
