import React from "react";
import "./ManageStakeholders.scss";
import {PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import download from "../../../assets/Manage-syndicate/Svg/download.svg";
import eye from "../../../assets/Manage-syndicate/Svg/eye.svg";
import filter from "../../../assets/Manage-syndicate/Svg/filter.svg";
import image1 from "../../../assets/Manage-syndicate/image/Person (Circle).png";
import image2 from "../../../assets/Manage-syndicate/image/Person (Circle1).png";
import image3 from "../../../assets/Manage-syndicate/image/Person (Circle10).png";
import image4 from "../../../assets/Manage-syndicate/image/Person (Circle11).png";
import image5 from "../../../assets/Manage-syndicate/image/Person (Circle3).png";
import image6 from "../../../assets/Manage-syndicate/image/Person (Circle4).png";
import image7 from "../../../assets/Manage-syndicate/image/Person (Circle5).png";
import image8 from "../../../assets/Manage-syndicate/image/Person (Circle6).png";
import Search from "../../../components/reusableComponents/Search/Search";
import DotMenu from "../../../components/reusableComponents/Tripledot/Tripledot";

const ManageStakeholders = () => {
  const profileMenuItems = [
    {label: "Profile", action: "profile"},
    {label: "Settings", action: "settings"},
    {label: "Log out", action: "logout"},
  ];

  return (
    <div>
      <div className="container">
        <form className="example">
          <Search />
        </form>
        <div className="button-container">
          <PrimaryButtonSvgFirst
            name="Download"
            classNames="ManageStakeholders-Download"
            svgPath={download}
            padding="10px"
            svgHeight="20px"
            svgWidth="20px"
          />
          <PrimaryButtonSvgFirst
            name="Bulk Action"
            classNames="ManageStakeholders-Download"
            svgPath={eye}
            padding="10px"
            svgHeight="20px"
            svgWidth="20px"
          />
          <PrimaryButtonSvgFirst
            name="Manage Stakeholders"
            classNames="ManageStakeholders-Manage"
            padding="10px"
            svgHeight="20px"
            svgWidth="20px"
          />
        </div>
      </div>
      <div>
        <div>
          <div className="dropdown-data">
            <select name="Saved views">
              <option
                value=""
                disabled
                selected
              >
                Saved View
              </option>
              <option value="view1">View 1</option>
              <option value="view2">View 2</option>
            </select>
            <select name="columns">
              <option
                value=""
                disabled
                selected
              >
                Columns
              </option>
              <option value="column1">Column 1</option>
              <option value="column2">Column 2</option>
            </select>
            <select name="RELATIONSHIP">
              <option
                value=""
                disabled
                selected
              >
                Relationship
              </option>
              <option value="relationship1">Relationship 1</option>
              <option value="relationship2">Relationship 2</option>
            </select>
            <select name="COST CENTER">
              <option
                value=""
                disabled
                selected
              >
                Cost Center
              </option>
              <option value="center1">Cost Center 1</option>
              <option value="center2">Cost Center 2</option>
            </select>
            <select name="HOLDING(1)">
              <option
                value=""
                disabled
                selected
              >
                Holding(1)
              </option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
            </select>
            <select name="NOTICES">
              <option
                value=""
                disabled
                selected
              >
                Notices
              </option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
            </select>
            <select name="ADDRESS">
              <option
                value=""
                disabled
                selected
              >
                Address
              </option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
            </select>
            <select name="DATE CREATED">
              <option
                value=""
                disabled
                selected
              >
                Date Created
              </option>
              <option value="date_created1">Created 1</option>
              <option value="date_created2">Created 2</option>
            </select>
            <PrimaryButtonSvgFirst
              name="Filter"
              classNames="ManageStakeholders-Download"
              svgPath={filter}
              padding="10px"
              svgHeight="20px"
              svgWidth="20px"
            />
          </div>
        </div>
        <hr className="hrtag" />
      </div>
      {/* Stack Holder */}
      <div>
        <div className="userdata">
          <p>Stakeholder name</p>
          <p>Contact email</p>
          <p className="userdata_ptag">RELATIONSHIP</p>
        </div>
      </div>
      <hr className="hrtag" />
      <div>
        {[image1, image2, image3, image4, image5, image6, image7, image8].map((image, index) => (
          <div
            className="StackHolder-details"
            key={index}
          >
            <div className="StackHolder-details-profile">
              <input
                type="checkbox"
                name=""
                id=""
              />
              <img
                src={image}
                alt="image"
              />
              <p>name</p>
            </div>
            <p>georgia.young@example.com</p>
            <p>Advisor</p>
            <DotMenu menuItems={profileMenuItems} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManageStakeholders;
