import fileIcon from "../../../assets/svg/fileIcon.svg";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";

const SecondSectionSQ = ({
  setTotalInvestment,
  totalInvestment,
  setTotalInvestmentDA,
  totalInvestmentDA,
  InvestAmountList,
  setExpectedPerProject,
  expectedPerProject,
  setExpectedPerProjectDA,
  expectedPerProjectDA,
  expectedPerProjectList,
  setRiskTolerance,
  riskTolerance,
  setRiskToleranceDA,
  riskToleranceDA,
  riskToleranceList,
}) => {
  return (
    <>
      <div className="FirstSectionSQ sectionSignupQ">
        <div className="sectionSignupQ-T">
          <h1>
            Investment Objectives{" "}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z"
                fill="#9EA0A5"
              />
            </svg>
          </h1>
          <h2>Which investment objectives suits you best</h2>
        </div>
        <div className="sectionSignupQ-B">
          <div className="investment_objectives">
            <div className="top">
              <img
                src={fileIcon}
                alt=""
              />
              <p>Investment Objectives</p>
            </div>
            <div className="bottom">
              <div className="upper">
                <div className="selector_container">
                  <DropDown
                    label="Expected Total Investment in Next Twelve Months"
                    data={InvestAmountList}
                    selectedData={totalInvestment}
                    setSelectedData={setTotalInvestment}
                    dropdownState={totalInvestmentDA}
                    dropdownSetter={setTotalInvestmentDA}
                  />
                  <p>How much you are looking to invest over the next year in real estate opportunities?</p>
                </div>
                <div className="selector_container">
                  <DropDown
                    label="Expected Investment Amount Per Project"
                    data={expectedPerProjectList}
                    selectedData={expectedPerProject}
                    setSelectedData={setExpectedPerProject}
                    dropdownState={expectedPerProjectDA}
                    dropdownSetter={setExpectedPerProjectDA}
                  />
                  <p>Average</p>
                </div>
              </div>
              <div className="lower">
                <div className="selector_container">
                  <DropDown
                    label="Risk Tolerance"
                    data={riskToleranceList}
                    selectedData={riskTolerance}
                    setSelectedData={setRiskTolerance}
                    dropdownState={riskToleranceDA}
                    dropdownSetter={setRiskToleranceDA}
                  />
                  <p>Average</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondSectionSQ;
