import React, {useEffect, useState} from "react";
import "../crowdFunding/DealsRoom.scss";
import "./InvestmentPipeline.scss";
import SvgColorChanger from "../DealsRoom/SvgColorChanger";
import "./InvestmentPipeline.scss";
import back from "../../assets/investment/arrow-left.png";
import rect from "../../assets/dealsroom/images/Rectangle.jpeg";
import {ReactComponent as GpProfileIcon} from "../../assets/investment/gp-profile.svg";
import {ReactComponent as DashboardIcon} from "../../assets/investment/dashboard.svg";
import {ReactComponent as InvestmentIcon} from "../../assets/investment/investment.svg";
import {ReactComponent as NewOfferingIcon} from "../../assets/investment/new-offerings.svg";
import {ReactComponent as MessagingIcon} from "../../assets/investment/message.svg";
import {ReactComponent as DocumentsIcon} from "../../assets/investment/document.svg";

import {Link, useNavigate} from "react-router-dom";
import GpProfile from "./GpProfile";
import tableImg from "../../assets/Image/Investment_Pipeline/Pic.png";
import buildingImg from "../../assets/Image/Investment_Pipeline/AuroraAssets.png";
import filterIcon from "../../assets/investor-pipeline/icons/action_icon.svg";

import Dashboard from "./Dashboard";
import PipelineDashboard from "./PipelineDashboard";
import NewOfferings from "./Content/NewOfferings/NewOfferings";
import Investment from "./Content/Investment/Investment";
import search from "../../assets/svg/search.svg";
import Document from "./Document";
import Messaging from "./Messaging";
import {useSelector} from "react-redux";

const InvestmentPipeline = (props) => {
  const [isClicked, setIsClicked] = useState(0);
  const [activeTab, setActiveTab] = useState(1);
  const [navActive, setNavActive] = useState(1);

  const handleMenuClicked = (data) => {
    setIsClicked(data);
  };
  const handleTabClicked = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let DealsSyndicate = useSelector((state) => state.DealsSyndicateSlice.assets);

  let open_details = (dealId) => {
    navigate(`/${dealId}/dealsroom`);
  };

  return (
    <div className="investment-deals-room deals-room">
      <div className="investment_sidebar deals-room-left">
        <div className="upper">
          <span className="title heading">Portfolio Summary</span>
          <div className="upper_box">
            <div>
              <p>{DealsSyndicate.length}</p>
              <span className="title">Investments</span>
            </div>
            <div>
              <p>1</p>
              <span className="title">Gp</span>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.99984 6L8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6Z"
                fill="#9EA0A5"
              />
            </svg>
          </div>
        </div>
        <div className=" deals-room-nav sidebar_nav asset-nav ass-nav">
          <span
            className={(navActive === 1 || navActive === 3) && "deals-room-nav-active ut-nav-active"}
            onClick={() => {
              setNavActive(1);
            }}
          >
            By Gp{" "}
          </span>
          <span
            className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
            onClick={() => {
              setNavActive(2);
              setIsClicked(0);
            }}
          >
            By Investments
          </span>
        </div>

        {navActive === 2 && (
          <>
            <div className="search_bar">
              <input
                type="text"
                placeholder="Search"
              />
              <img
                src={search}
                alt=""
              />
            </div>

            <div className="lower">
              <div className="lower_header">
                <span className="title">New Offers</span>
                <p>
                  Show all
                  <img
                    src={filterIcon}
                    alt=""
                  />
                </p>
              </div>
            </div>

            <div className="deal-title">
              <div className="deal-left-img">
                <img
                  src={tableImg}
                  alt="Rectangle deal img"
                />
              </div>
              <div className="sb-deal-left-wrapper">
                <div className=" deal-left-img-text">
                  <h2 className="deal-title-text">Waltham Woods</h2>
                  <span>$2,500,000 Requested </span>
                </div>
                <span className="new">New</span>
              </div>
            </div>

            <div className="deal-title">
              <div className="deal-left-img">
                <img
                  src={buildingImg}
                  alt="Rectangle deal img"
                />
              </div>
              <div className=" deal-left-img-text">
                <h2 className="deal-title-text">Lotus Temple</h2>
                <span>$1,290,000 Requested </span>
              </div>
            </div>

            <div className="deal-title">
              <div className="deal-left-img">
                <img
                  src={rect}
                  alt="Rectangle deal img"
                />
              </div>
              <div className=" deal-left-img-text">
                <h2 className="deal-title-text">Dome of the Rock</h2>
                <span>$790,000 Requested </span>
              </div>
            </div>

            <div className="lower-2 lower">
              <div className="lower_header">
                <span className="title">Investments (84)</span>
              </div>
            </div>

            <div className="sb-deals-wrapper">
              <div className="deal-title">
                <div className="deal-left-img">
                  <img
                    src={buildingImg}
                    alt="Rectangle deal img"
                  />
                </div>
                <div className=" deal-left-img-text">
                  <h2 className="deal-title-text">Lotus Temple</h2>
                  <span>$1,290,000 Requested </span>
                </div>
              </div>

              <div className="deal-title">
                <div className="deal-left-img">
                  <img
                    src={rect}
                    alt="Rectangle deal img"
                  />
                </div>
                <div className=" deal-left-img-text">
                  <h2 className="deal-title-text">Dome of the Rock</h2>
                  <span>
                    <span className="green-dot"></span>
                    In progress / $2,500,000 Lent{" "}
                  </span>
                </div>
              </div>

              <div className="deal-title">
                <div className="deal-left-img">
                  <img
                    src={rect}
                    alt="Rectangle deal img"
                  />
                </div>
                <div className=" deal-left-img-text">
                  <h2 className="deal-title-text">Dome of the Rock</h2>
                  <span>
                    <span className="green-dot"></span>
                    In progress / $2,500,000 Lent{" "}
                  </span>
                </div>
              </div>

              <div className="deal-title">
                <div className="deal-left-img">
                  <img
                    src={rect}
                    alt="Rectangle deal img"
                  />
                </div>
                <div className=" deal-left-img-text">
                  <h2 className="deal-title-text">Lotus Temple</h2>
                  <span>
                    <span className="green-dot"></span>
                    In progress / $2,500,000 Lent{" "}
                  </span>
                </div>
              </div>

              <div className="deal-title">
                <div className="deal-left-img">
                  <img
                    src={rect}
                    alt="Rectangle deal img"
                  />
                </div>
                <div className=" deal-left-img-text">
                  <h2 className="deal-title-text">Dome of the Rock</h2>
                  <span>
                    <span
                      style={{background: "rgba(125, 85, 199, 1)"}}
                      className="green-dot"
                    ></span>
                    pending / $2,500,000 Lent{" "}
                  </span>
                </div>
              </div>

              <div className="deal-title">
                <div className="deal-left-img">
                  <img
                    src={rect}
                    alt="Rectangle deal img"
                  />
                </div>
                <div className=" deal-left-img-text">
                  <h2 className="deal-title-text">Lotus Temple</h2>
                  <span>
                    <span className="green-dot"></span>
                    In progress / $2,500,000 Lent{" "}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}

        {navActive === 1 && (
          <>
            <div className="lower-3 lower">
              <div className="lower_header">
                <span className="title">General Partners</span>
              </div>
            </div>
            <div className="sb-deals-wrapper">
              {DealsSyndicate?.map((deal, index) => (
                <div
                  key={index}
                  style={{cursor: "pointer"}}
                  className="sb-deal-left-wrapper"
                >
                  <div
                    className="deal-title"
                    onClick={() => open_details(deal._id)}
                  >
                    <div className="deal-left-img">
                      <img
                        src={deal.assetsImages[0]}
                        alt="Rectangle deal img"
                      />
                    </div>
                    <div className="deal-left-img-text">
                      <h2 className="deal-title-text">{deal.assetName}</h2>
                      <span>
                        {deal.investments} Investments / {deal.mutual} Mutual
                      </span>
                    </div>
                    {deal.onClickNav && <span className="gp-number">3</span>}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        {navActive === 3 && (
          <>
            <div className="lower-2 lower">
              <div className="lower_header">
                <span
                  onClick={() => setNavActive(1)}
                  className="title"
                >
                  <img
                    src={back}
                    alt=""
                  />
                  Back to General Partners{" "}
                </span>
              </div>
            </div>

            <div
              style={{cursor: "pointer"}}
              className="sb-deal-left-wrapper-2"
            >
              <div
                className="deal-title"
                onClick={() => setNavActive(3)}
              >
                <div className="deal-left-img">
                  <img
                    src={rect}
                    alt="Rectangle deal img"
                  />
                </div>
                <div className=" deal-left-img-text">
                  <h2 className="deal-title-text">North Group Trust</h2>
                  <span>45 Investments / 15 Mutual </span>
                </div>
                <img
                  src={filterIcon}
                  alt=""
                />
              </div>
            </div>

            <div
              style={{margin: "20px 0"}}
              className="deal-room-left-menu"
            >
              <span> Menu</span>
            </div>
            <LeftMenu
              icon={<GpProfileIcon />}
              name={"Gp Profile"}
              id={1}
              state={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenu
              icon={<DashboardIcon />}
              name={"Dashboard"}
              id={2}
              state={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenu
              icon={<InvestmentIcon />}
              name={"Investments"}
              id={3}
              state={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenu
              icon={<NewOfferingIcon />}
              name={"New Offerings"}
              id={4}
              state={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenu
              icon={<MessagingIcon />}
              name={"Messaging"}
              id={5}
              state={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenu
              icon={<DocumentsIcon />}
              name={"Documents"}
              id={6}
              state={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
          </>
        )}
      </div>

      <div className="investment_center_wrapper center-wrapper">
        {isClicked === 0 && <PipelineDashboard setIsClickedOne={setIsClicked} />}
        {isClicked === 1 && <GpProfile />}
        {isClicked === 2 && <Dashboard handleMenuClicked={setIsClicked} />}
        {isClicked === 3 && <Investment />}
        {isClicked === 4 && <NewOfferings handleMenuClicked={setIsClicked} />}
        {isClicked === 6 && <Document />}
        {isClicked === 5 && <Messaging />}
      </div>
    </div>
  );
};

export default InvestmentPipeline;

const LeftMenu = ({icon, name, id, state, handleMenuClicked}) => {
  return (
    <div
      className={`deals-left-menu ${id === state && "deals-menu-active"}`}
      onClick={() => {
        handleMenuClicked(id);
      }}
    >
      <SvgColorChanger svgContent={icon} />
      <span className="left-menu-title">{name}</span>
    </div>
  );
};
