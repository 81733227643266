import React from "react";
import "./ViewCaptable.scss";
import {ReactComponent as calender} from "../../../assets/svg/calender.svg";
import Table from "../../../components/reusableComponents/Table/ReactTable";
import arrow from "../../../assets/Manage-syndicate/Svg/arrow-up.png";
import PieChart from "../../../assets/Manage-syndicate/image/Pie Chart.png";

const ViewCaptable = () => {
  const data = {
    labels: ["Red", "Blue", "Yellow"],
    datasets: [
      {
        label: "My First Dataset",
        data: [300, 50, 100],
        backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 205, 86)"],
        hoverOffset: 4,
      },
    ],
  };

  const config = {
    type: "doughnut",
    data: data,
  };

  // table data

  const headers = ["Authorized shares", "OUTDTSNDING", "OWNERSHIP", "FULLY DILUTED", "Ownership", "Amount raised"];
  const tabledata = [
    {"": "CN Notes", Authorizedshares: 9000, OUTDTSNDING: 5165486, OWNERSHIP: 10000, "FULLY DILUTED": 20000, Ownership: 50, "Amount raised": 5000},
    {"": "CN1", Authorizedshares: 8000, OUTDTSNDING: 6000000, OWNERSHIP: 12000, "FULLY DILUTED": 18000, Ownership: 30, "Amount raised": 6000},
  ];
  const total = headers.reduce((acc, header) => {
    if (header !== "Name") {
      acc[header] = tabledata.reduce((sum, row) => sum + (row[header] || 0), 0);
    }
    return acc;
  }, {});

  const tabledataWithTotal = [...tabledata, {Name: "Total", ...total}];

  const columns = Object.keys(tabledata[0]);
  const leftColumnData = tabledata.map((item) => item.Name);

  return (
    <div>
      <div>
        <div style={{marginTop: "10px", marginBottom: "10px"}}>
          <h1>Cap Table by share class</h1>
          <br />
        </div>
      </div>
      <div>
        <div className="CapTab-details-chart-box">
          <div className="CapTab-details-chart">
            <div className="CapTab-alldata">
              <h1>View cap table as of</h1>
              <div className="CapTab-details-chart-data">
                <div className="view_data">
                  <p>Fully Diluted Shares</p>
                </div>
                <div className="view_details">
                  <p>9,320,725</p>
                  <div className="images">
                    <img
                      src={arrow}
                      alt="image"
                    />
                  </div>
                </div>
              </div>
              <div className="CapTab-details-chart-data">
                <div className="view_data">
                  <p>Amount raised</p>
                </div>
                <div className="view_details_enddata">
                  <p>US$1,975,775.93</p>
                  <div className="images">
                    <img
                      src={arrow}
                      alt="image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
      
          <div className="continer-captab">
            <div>
              <div className="captab-flex-data">
                <h1>Ownership & fully diluted shares</h1>
                <hr />
                <h1>Amount raised</h1>
              </div>
              <hr />
              <div className="image-container">
                <img
                  src={PieChart}
                  alt="image"
                />
                <img
                  src={PieChart}
                  alt="image"
                />
              </div>
            </div>
            <div className="CapTab-color-flex-conatiner">
              {/* first */}
              <div className="CapTab-color-flex">
                <div className="color-graph" />
                <span>Common shares</span>
              </div>
              {/* second*/}
              <div className="CapTab-color-flex">
                <div className="color-graph" />
                <span>Convertible (All Terms)</span>
              </div>
              {/* third */}
              <div className="CapTab-color-flex">
                <div className="color-graph" />
                <span>Preferred shares</span>
              </div>
            </div>
            <div className="CapTab-color-flex-conatiner-next">
              {/* first */}
              <div className="CapTab-color-flex">
                <div className="color-graph" />
                <span>Outstanding 9All Plans)</span>
              </div>
              {/* second*/}
              <div className="CapTab-color-flex">
                <div className="color-graph" />
                <span>Available (All Plans)</span>
              </div>
              {/* third */}
              <div className="CapTab-color-flex">
                <div className="color-graph" />
                <span>Convertibles (All Terms)</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* table */}
      <div>
        <div className="table">
          <div className="table-data">
            <h1>Share Class</h1>
          </div>
          <Table
            headers={headers}
            tabledata={tabledataWithTotal}
            leftColumnData={leftColumnData}
            rightColumnData={tabledataWithTotal}
            // columns={columns}
          />
        </div>
      </div>
      {/* table */}
      <div>
        <div className="table">
          <div className="table-data">
            <h1>Convertibles</h1>
          </div>
          <Table
            headers={headers}
            tabledata={tabledataWithTotal}
            leftColumnData={leftColumnData}
            rightColumnData={tabledataWithTotal}
            // columns={columns}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewCaptable;
