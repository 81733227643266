import React from "react";
import "./BorrowerActivity.scss";
import pdfLogo from "../../assets/svg/pdfLogoIcon.svg";
import womenProfilePic from "../../assets/svg/women_profilePic.svg";
import menProfilePic from "../../assets/svg/man_profile_pic.svg";
import Arrow from "../../assets/svg/Arrow_forward.svg";
import reply_icon from '../../assets/svg/reply_arrow_icon.svg';

const BorrowerActivity = () => {
  return (
    <div className="borrowerActivity_container">
      <UserInfoOne />
      <UserInfoTwo />
      <UserInfoThree/>
    </div>
  );
};

export default BorrowerActivity;

const UserInfoOne = () => {
  return (
    <div className="user_info">
      <div className="active_icon"></div>
      <img
        src={womenProfilePic}
        alt=""
        style={{borderRadius: "50%"}}
      />
      <div style={{display: "flex", flexDirection: "column", gap: "2px"}}>
        <h1>
          Isabelle Neal <span>from Lender’s team</span>
        </h1>
        <p style={{color: "#6B6C6F",fontSize:'14px'}}>Uploaded 3 documents</p>
        <div className="more_info">
          <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
            <div style={{height: "48px", width: "48px", background: "#EAEDF3", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "4px"}}>
              <img
                src={pdfLogo}
                alt=""
                width="50%"
              />
            </div>
            <div style={{height: "48px", width: "48px", background: "#EAEDF3", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "4px"}}>
              <img
                src={pdfLogo}
                alt=""
                width="50%"
              />
            </div>
            <div style={{height: "48px", width: "48px", background: "#EAEDF3", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "4px"}}>
              <img
                src={pdfLogo}
                alt=""
                width="50%"
              />
            </div>
          </div>
          <p>4 days ago, on August 15, 12:15am</p>
        </div>
      </div>
    </div>
  );
};
const UserInfoTwo = () => {
  return (
    <div
      className="user_info"
      style={{paddingTop: "20px"}}
    >
      <div className="active_icon"></div>
      <img
        src={menProfilePic}
        alt=""
        style={{borderRadius: "50%"}}
      />
      <div style={{display: "flex", flexDirection: "column", gap: "2px"}}>
        <h1>Jose Perkins</h1>
        <p style={{color: "#6B6C6F",fontSize:'14px'}}>at “Agreement C.pdf” document</p>
        <div className="more_info">
          <p style={{fontSize: "14px", color: "#6B6C6F"}}>Suggested 1 edit</p>
          <div className="before_now">
            <div className="before">
              <span>Before</span>
              <p>Minimum investment</p>
              <p style={{color: "var(--LP-mid-grey-_lib, #6B6C6F)"}}>$15,500</p>
            </div>
            <img src={Arrow} alt="" />
            <div className="now">
              <span>Now</span>
              <p>Minimum investment</p>
              <p style={{color:'var(--LP-Pantone, #7D55C7)'}}>$25,000</p>
            </div>
          </div>
          <p>4 days ago, on August 15, 12:15am</p>
        </div>
      </div>
    </div>
  );
};
const UserInfoThree = () => {
    return (
        <div
          className="user_info"
          style={{paddingTop: "20px"}}
        >
          <div className="active_icon"></div>
          <img
            src={menProfilePic}
            alt=""
            style={{borderRadius: "50%"}}
          />
          <div style={{display: "flex", flexDirection: "column", gap: "2px"}}>
            <h1>Gregory Ray  <span>from Investor’s team</span></h1>
            <p style={{color: "#6B6C6F",fontSize:'14px'}}>commented at “Agreement C.pdf” document</p>
            <div className="more_info">
              <div className="postedCommentBox">
                <p>Can I Invest on behalf on my friend, I’ll provide my docs and Investor Certificate, but he’ll be a real Investor (100% his money)? If not can I borrow his money and then invest? Also, can I invest $48K, not $45K or $50?</p>
              </div>
              <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
              <p>2 days ago</p>
              <p style={{color:'#1665D8',cursor:'pointer'}}>Reply <img src={reply_icon} alt="" /></p>
              </div>
            </div>
          </div>
        </div>
      );
}
