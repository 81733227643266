import React from "react";
import "./Form3921.scss";
import {PrimaryButton, PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import timespan from "../../../assets/Manage-syndicate/Svg/timespan.svg";
import note from "../../../assets/Manage-syndicate/Svg/Note.svg";
import setting from "../../../assets/Manage-syndicate/Svg/Setting.svg";
import check from "../../../assets/Manage-syndicate/Svg/Checkmark.svg";

const Form3921 = () => {
  const headers = ["Tax year", "OUTDTSNDING", "Date ", "copy B", ""];
  const data = [
    [
      2014,
      "9,000,000",
      "5,320,725",
      "57,085%",
      <PrimaryButton
        name="download-button"
        classNames="download-button"
        padding="10px"
        svgHeight="20px"
        svgWidth="20px"
      />,
    ],
    [
      2014,
      "9,000,000",
      "5,320,725",
      "57,085%",
      <PrimaryButton
        name="download-button"
        classNames="download-button"
        padding="10px"
        svgHeight="20px"
        svgWidth="20px"
      />,
    ],
  ];
  return (
    <div>
      <div className="Form-Container">
        <div className="Form-Container-header">
          <div className="Form-Container-headerdata">
            <div className="Form-Container-headerdatas">
              <h5>File Form 3921 with the IRS</h5>
              <hr />
              <p>With a few clicks. generate and download copies of the forms for all employees who exercised incenrive stock option in the last year.</p>
            </div>
            <div className="Form3921-button">
              <PrimaryButton
                name="Upgrade "
                classNames="Form3921-Filter-data"
                padding="10px"
                svgHeight="20px"
                svgWidth="20px"
              />
              <PrimaryButtonSvgFirst
                name="See Plan"
                classNames="Form3921-Filter"
                padding="10px"
                svgHeight="20px"
                svgWidth="20px"
              />
            </div>
          </div>
          <div>
            <div className="Form3921-conatiner">
              <div className="Form3921-conatiner-data">
                <div className="Form3921-formdata">
                  <div className="Form3921-formdata-data">
                    <h1>FROM 3921</h1>
                    <h1>STAKEHOLDER DETAILS</h1>
                  </div>
                  <PrimaryButton
                    name="Generate 3921"
                    classNames="Report-see-plan"
                    svgPath="/path/to/svg/image.svg"
                    padding="10px"
                    svgHeight="20px"
                    svgWidth="20px"
                  />
                </div>

                <div className="flex">
                  <div>
                    <img
                      src={check}
                      alt="image"
                    />
                  </div>
                  <div className="Form3921-color-text">
                    <h5>Stakeholder tax details Confirm</h5>
                    <p>Ready to generate from 3921</p>
                  </div>
                </div>
                <table className="table-data">
                  <thead className="table-header">
                    <tr>
                      {headers.map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="table-body-data">
                    {data.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex}>{cell}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="Form-rules-container">
          <div className="Form-rules-container-data">
            <div className="Form-rules-container-datafirst">
              <div className="Taxrules-image-footer">
                <img
                  src={timespan}
                  alt="image"
                />
              </div>
              <h1>Save time on data entry</h1>
              <span>We’ll pull in FMVs already in Carta and give you a chance to edit company and employee details,</span>
            </div>
          </div>
          <div className="Form-rules-container-second">
            <div className="Form-rules-container-secondbox">
              <div className="Taxrules-image-footer">
                <img
                  src={note}
                  alt="image"
                />
              </div>
              <h1>Generate all forms 3921 documents </h1>
              <span>Get copies A, B and C for your company records, employees. and the IRS.</span>
            </div>
          </div>
          <div className="Form-rules-container-thid">
            <div className="Form-rules-container-secondbox">
              <div className="Taxrules-image-footer">
                <img
                  src={setting}
                  alt="image"
                />
              </div>
              <h1>Easily share with employees</h1>
              <span>Seamlessly deliver Copy B directly to you employee’s Carta account.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form3921;
