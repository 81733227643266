import {PrimaryButton} from "../../../components/reusableComponents/Buttons/Buttons";
import {InputBox} from "../../../components/reusableComponents/FormFields/FormFields";
import {Link} from "react-router-dom";
import lilypandsLogo from "../../../assets/svg/lilipadsLogomain.svg";
// import singinRight from "../../assets/svg/signInRight.png";
import PRgif1 from "../../../assets/svg/PRgif1.gif";









export function FirstSection({setSliderState}) {
    return (
      <div className="prFirstSection">
        <div className="signupCon-L">
          <div className="signupFirstSection signupsection">
            <div className="top_header">
              <img
                src={lilypandsLogo}
                alt=""
              />
  
              <div className="text_wrapper">
                <h2>Password recovery</h2>
                <p>Enter your email address and we’ll send you a link to reset your password.</p>
              </div>
            </div>
  
            <InputBox
              label="E-mail address"
              type="email"
            />
            <br />
            <PrimaryButton
              classNames=" noMargin "
              name="Reset Password!"
              onclick={() => {
                //Add conditional slide here
                setSliderState("two");
              }}
            />
            <h3>
              I’ve remembered password.
              <Link to="/signin">
                <span>Sign In.</span>
              </Link>
            </h3>
  
            <div className="spacerLine30"></div>
            <Link to="/signup">
              <PrimaryButton
                classNames="grey noMargin"
                name="Create Account!"
                onclick={() => {}}
              />
            </Link>
          </div>
        </div>
        <div className="signupCon-R ">
          <div className="container">
            <div className="passwordRecovery_svg_wrapper">
              <img
                src={PRgif1}
                alt=""
              />
            </div>
            <div className="color_stripes-data">
              <div className="stripe1"></div>
              <div className="stripe2"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }