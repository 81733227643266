import {useEffect, useState} from "react";

import "./PageLayout.scss";
// import HeaderOne from "../Headers/HeaderOne";
import HeaderTwo from "../Headers/HeaderTwo";
import NewHeader from '../Headers/NewHeader'

const PageLayoutWithHeader = (props) => {
  return (
    <div className="PageL-M">
      <NewHeader crossActive={props.crossActive} />

      <div className="PageL-RB">{props.children}</div>
    </div>
  );
};

export default PageLayoutWithHeader;
