import React from 'react'
import './Team.scss'
import AssestCard from './AssetCard'
import photo from "../../assets/acqusition/svg/photo.svg";
import location from "../../assets/acqusition/svg/location.svg";
import street from "../../assets/acqusition/svg/street.svg";
import bookmark from "../../assets/acqusition/svg/bookmark.svg";
import share from "../../assets/acqusition/svg/share.svg";
import compare from "../../assets/acqusition/svg/compare.svg";
import Cover from '../../assets/Image/assetDiscovery/cover.png'
import Profile from '../../assets/Image/assetDiscovery/profile.png'
import CrowdfundingHeader from './CrowdFundingHeader';

function Team() {

    const teamdata = [
        {
          id: 1,
          heading: 'Focus on Driving Returns with Steeply Discounted Assets',
          text: 'McFarlin Group seeks to generate superior returns by acquiring distressed senior living assets including poorly positioned, over leveraged or under-capitalized assets that provide an opportunity for acquisition at a steep discount to replacement cost. COVID-19 has created a unique opportunity to acquire hard-hit senior living assets at considerable discounts which the fund will seek to capitalize upon.',
        },
        {
            id: 2,
            heading: 'Focus on Driving Returns with Steeply Discounted Assets',
            text: 'McFarlin Group seeks to generate superior returns by acquiring distressed senior living assets including poorly positioned, over leveraged or under-capitalized assets that provide an opportunity for acquisition at a steep discount to replacement cost. COVID-19 has created a unique opportunity to acquire hard-hit senior living assets at considerable discounts which the fund will seek to capitalize upon.',
          },
          {
            id: 3,
            heading: 'Focus on Driving Returns with Steeply Discounted Assets',
            text: 'McFarlin Group seeks to generate superior returns by acquiring distressed senior living assets including poorly positioned, over leveraged or under-capitalized assets that provide an opportunity for acquisition at a steep discount to replacement cost. COVID-19 has created a unique opportunity to acquire hard-hit senior living assets at considerable discounts which the fund will seek to capitalize upon.',
          },
          {
            id: 4,
            heading: 'Focus on Driving Returns with Steeply Discounted Assets',
            text: 'McFarlin Group seeks to generate superior returns by acquiring distressed senior living assets including poorly positioned, over leveraged or under-capitalized assets that provide an opportunity for acquisition at a steep discount to replacement cost. COVID-19 has created a unique opportunity to acquire hard-hit senior living assets at considerable discounts which the fund will seek to capitalize upon.',
          },
      ];
      
  return (
    <div className='team'>
       <CrowdfundingHeader title={'Leadership Team'} />

    <div className='team_container'>
      <div className='team_center'>
        <div className='teambox_wrapper'>
      {teamdata.map((deal) => (
            <TeamBox key={deal.id} {...deal} />
          ))}    </div>
          </div>
    <div className='team_right'>
        <AssestCard />
    </div>
    </div> </div>
  )
}

export default Team

const TeamBox = () => {
    return(
        <div className='teambox'>
                 <div style={{backgroundImage: `url(${Cover})` }} className='teambox_upper'>
       
                 </div>

                 <div className='teambox_lower'>
                     <img src={Profile} alt='' />
                     <div>
                     <span>Evan McCormick</span>
                     <p>Founder & Chief Executive Officer</p>
                     </div>

                     <p>is responsible for construction management for New Era. Early in his career, Brad established himself as a skilled construction professional comfortable collaborating with clients, contractors, subcontractors, and suppliers. Brad has been involved in the commercial construction industry since 2001 and has... <span>Read More</span> </p>
                 </div>
        </div>
    )
}


