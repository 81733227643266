import React from "react";
import ReactECharts from "echarts-for-react"; 

const DealCountChart = ({deals}) => {
  const option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      show: false,
      top: "5%",
      left: "center",
    },
    series: [
      {
        name: "Analytics",
        type: "pie",
        radius: ["80%", "60%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 40,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          {value: 1048, name: "Active"},
          {value: 735, name: "Pending"},
          {value: 580, name: "Closed"},
          {value: 484, name: "Others"},
        ],
      },
    ],
  };

  return (
    <div className="dealCountChart_container">
      <div className="chart_details">
        <p className="dealCount_caption">Deal count total</p>
        <p className="deal_count">{deals?.length}</p>
      </div>
      <ReactECharts
        option={option}
        style={{height: "400px", width: "400px"}}
      />
    </div>
  );
};

export default DealCountChart;
