import React from "react";
import "./DiscoveredOpp.scss";
import settings from "../../assets/Image/Icon-Left auto _LIB.png";
import saveAs from "../../assets/Image/file_icons.png";
import setting_icon_2 from "../../assets/Image/settings [#1491] _LIB.png";
import bulding_img from "../../assets/Image/bulding_blue.png";
import riverImg from "../../assets/Image/river_image.png";
import userPic from "../../assets/Image/user_pic.png";
import badge from "../../assets/svg/Badge.svg";
import greyBadge from "../../assets/Image/grey_badge.png";

// import user_logo from "../../assets/Image/user";

const DiscoveredOpp = () => {
  return (
    <div className="discovered_opportunity">
      <div className="apply_header">
        <div className="search">
          <input
            type="text"
            placeholder="Search"
          />
        </div>
        <div className="select">
          <select
            name=""
            id=""
          >
            <option value="">
              {" "}
              <p>Owner: </p>All Categories
            </option>
          </select>
        </div>
        <div className="location">
          <div>Location</div>
        </div>
        <div className="all_types">
          <select
            name=""
            id=""
          >
            <option value="">All Types</option>
          </select>
        </div>
        <div className="date_added">
          <select
            name=""
            id=""
          >
            <option value="">Date Added</option>
          </select>
        </div>
        <div className="match">
          <p>Match 75%-95%</p>
        </div>
        <div className="settings">
          <img
            src={settings}
            alt=""
          />
        </div>
        <div className="save_as">
          <img
            src={saveAs}
            alt=""
          />
        </div>
        <div className="apply_btn">
          <button>Apply</button>
        </div>
      </div>
      <div className="discoverd_opportunities_info">
        <div className="side_users">
          <div className="side_users_heading">
            <div>
              <h2>Discovered Opportunities</h2>
              <p>156 Results</p>
            </div>
            <img
              src={setting_icon_2}
              alt=""
            />
          </div>
          <div className="side_user_list">
            <div className="single_side_user">
              <div>
                <img
                  src={bulding_img}
                  alt=""
                />
              </div>
              <div className="single_user_descri">
                <h2>Astrics Invest</h2>
                <p>Public Brokerage Group</p>
                <p>6 Syndicates</p>
              </div>
            </div>

            <div className="single_side_user_fund">
              <div>
                <img
                  src={bulding_img}
                  alt=""
                />
              </div>
              <div className="single_user_descri_fund">
                <h2>Astrics Invest</h2>
                <p>Public Brokerage Group</p>
                <p>6 Syndicates</p>
              </div>
            </div>
          </div>
        </div>
        <div className="single_page_overview"></div>
      </div>
    </div>
  );
};

export default DiscoveredOpp;
