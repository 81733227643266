// import React from 'react'
// import './notifications.scss'
// import { InputBox } from '../../../components/reusableComponents/FormFields/FormFields'
// import Checkbox from './Checkbox'
// import dropdownArrows from "../../../assets/Image/dropdownArrows.png";
// import info from '../../../assets/svg/info.svg'
// import DropDown from '../../../components/reusableComponents/Dropdown/Dropdown';
// import { useState } from 'react';

// function Notifications() {
//     const [timeList, setTimeList] = useState(["1", "2" , "3 " , "9" , "10"]);
//     const [selectedTimeList, setSelectedTimeList] = useState("9");
//     const [timeListDa, setTimeListDa] = useState(false);

//     return (
//         <div className='notifications'>
//             <div className='basic_header'>
//                 <div>
//                     <p>Notifications</p>
//                     <span>Receive notifications, requests to write a review, pricing notices, and other reminders related to your activities on Building platform.</span>
//                 </div>

//             </div>
//             <div className='receive'>
//                 <p>Receive SMS notifications to phone number:</p>
//                <div>
//                <DropDown
//                         label=""
//                         data={timeList}
//                         selectedData={selectedTimeList}
//                         setSelectedData={setTimeList}
//                         dropdownState={timeListDa}
//                         dropdownSetter={setTimeListDa}
//                     />
//                 <InputBox label={''} type={'tel'} placeholder={'(123) 456-7890'} />
//                </div>

//             </div>

//             <div className='notifications_box'>
//                 <p>Notifications</p>
//                 <div className='row_wrapper'>
//                     <div className='row'>
//                         <Checkbox label={'comments'} />
//                         <Checkbox label={'email'} />
//                         <Checkbox label={'SMS'} />
//                         <Checkbox label={'messenger'} />
//                         <div>
//                         <img style={{height:'10px' , width:'6px'}} src={dropdownArrows} alt='' />
//                             <img src={info} alt='' />
//                         </div>
//                     </div>

//                     <div className='row'>
//                         <Checkbox label={'comments'} />
//                         <Checkbox label={'email'} />
//                         <Checkbox label={'SMS'} />
//                         <Checkbox label={'messenger'} />
//                         <div>
//                         <img style={{height:'10px' , width:'6px'}} src={dropdownArrows} alt='' />
//                             <img src={info} alt='' />
//                         </div>
//                     </div>

//                     <div className='row'>
//                         <Checkbox label={'comments'} />
//                         <Checkbox label={'email'} />
//                         <Checkbox label={'SMS'} />
//                         <Checkbox label={'messenger'} />
//                         <div>
//                         <img style={{height:'10px' , width:'6px'}} src={dropdownArrows} alt='' />
//                             <img src={info} alt='' />
//                         </div>
//                     </div>

//                     <div className='row'>
//                         <Checkbox label={'comments'} />
//                         <Checkbox label={'email'} />
//                         <Checkbox label={'SMS'} />
//                         <Checkbox label={'messenger'} />
//                         <div>
//                         <img style={{height:'10px' , width:'6px'}} src={dropdownArrows} alt='' />
//                             <img src={info} alt='' />
//                         </div>
//                     </div>

//                     <div className='row'>
//                         <Checkbox label={'comments'} />
//                         <Checkbox label={'email'} />
//                         <Checkbox label={'SMS'} />
//                         <Checkbox label={'messenger'} />
//                         <div>
//                             <img style={{height:'10px' , width:'6px'}} src={dropdownArrows} alt='' />
//                             <img src={info} alt='' />
//                         </div>
//                     </div>

//                 </div>

//                 <div className='schedule'>
//                     <p>Chooose a convenient time to receive recommendations</p>
//                     <div className='days_wrapper'>
//                         <div className='days'>
//                             s
//                         </div>
//                         <div className='days'>
//                             m
//                         </div>
//                         <div className='days'>
//                             t
//                         </div>
//                         <div className='days'>
//                             w
//                         </div>
//                         <div className='days'>
//                             Th
//                         </div>
//                         <div className='days'>
//                             f
//                         </div>
//                         <div className='days'>
//                             St
//                         </div>
//                     </div>
//                     <div className='timer'>

//                     <DropDown
//                         label=""
//                         data={timeList}
//                         selectedData={selectedTimeList}
//                         setSelectedData={setTimeList}
//                         dropdownState={timeListDa}
//                         dropdownSetter={setTimeListDa}

//                     />

//                         <DropDown
//                         label=""
//                         data={timeList}
//                         selectedData={'15'}
//                         setSelectedData={setTimeList}
//                         dropdownState={timeListDa}
//                         dropdownSetter={setTimeListDa}
//                     />

//                         <p className='selected'>Am</p>
//                         <p>Pm</p>
//                     </div>
//                 </div>
//                 <p className='promotions'>Promotions</p>
//                 <div className='promotions_row'>
//                   <Checkbox label={'email'} />
//                   <Checkbox label={'push notifications'} />
//                   <Checkbox label={'messages'} />
//                   <Checkbox label={'Phone Calls'} />

//                 </div>
//             </div>
//             <div style={{display:'flex' , alignItems:'flex-start' , gap:'6px'}} className='lower'>
//             <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path fill-rule="evenodd" clip-rule="evenodd" d="M7.49967 13.4993L3.99967 9.99935L2.83301 11.166L7.49967 15.8327L17.4997 5.83268L16.333 4.66602L7.49967 13.4993Z" fill="#9EA0A5"/>
// </svg>

//                 <span>Changes are saved automatically</span>
//             </div>
//         </div>
//     )
// }

// export default Notifications

import React, {useState} from "react";
import "./notifications.scss";
import {InputBox} from "../../../components/reusableComponents/FormFields/FormFields";
import Checkbox from "./Checkbox";
import dropdownArrows from "../../../assets/Image/dropdownArrows.png";
import info from "../../../assets/svg/info.svg";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";

function Notifications() {
  const [timeList, setTimeList] = useState(["1", "2", "3", "9", "10"]);
  const daysOfWeek = ["S","M", "T", "W", "T", "F", "S",];
  const [selectedTimeList, setSelectedTimeList] = useState("9");
  const [timeListDa, setTimeListDa] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState("AM");

  const handleDaySelection = (day) => {
    setSelectedDay(day === selectedDay ? null : day); // Toggle selection
  };

  const handleTimeSelection = (time) => {
    setSelectedTime(time);
  };

  const handlePeriodSelection = (period) => {
    setSelectedPeriod(period);
  };

  return (
    <div className="notifications">
      <div className="basic_header">
        <div>
          <p>Notifications</p>
          <span>Receive notifications, requests to write a review, pricing notices, and other reminders related to your activities on Building platform.</span>
        </div>
      </div>
      <div className="receive">
        <p>Receive SMS notifications to phone number:</p>
        <div>
          <DropDown
            label=""
            data={timeList}
            selectedData={selectedTimeList}
            setSelectedData={setTimeList}
            dropdownState={timeListDa}
            dropdownSetter={setTimeListDa}
          />
          <InputBox
            label={""}
            type={"tel"}
            placeholder={"(123) 456-7890"}
          />
        </div>
      </div>
      <div className="notifications_box">
        <p>Notifications</p>
        <div className="row_wrapper">
          {[...Array(7).keys()].map((day) => (
            <div
              key={day}
              className={`row ${selectedDay === day ? "selected" : ""}`}
              onClick={() => handleDaySelection(day)}
            >
              <Checkbox label={"comments"} />
              <Checkbox label={"email"} />
              <Checkbox label={"SMS"} />
              <Checkbox label={"messenger"} />
              <div>
                <img
                  style={{height: "10px", width: "6px"}}
                  src={dropdownArrows}
                  alt=""
                />
                <img
                  src={info}
                  alt=""
                />
              </div>
            </div>
          ))}
        </div>
        <div className="schedule">
          <p>Choose a convenient time to receive recommendations</p>
          <div className="days_wrapper">
            {daysOfWeek.map((day, index) => (
              <div
                key={index}
                className={`days ${selectedDay === index ? "active" : ""}`}
                onClick={() => handleDaySelection(index)}
              >
                {day}
              </div>
            ))}
          </div>
          <div className="timer">
            <DropDown
              label=""
              data={timeList}
              selectedData={selectedTime}
              setSelectedData={handleTimeSelection}
              dropdownState={timeListDa}
              dropdownSetter={setTimeListDa}
            />
            <DropDown
              label=""
              data={["AM", "PM"]}
              selectedData={selectedPeriod}
              setSelectedData={handlePeriodSelection}
              dropdownState={timeListDa}
              dropdownSetter={setTimeListDa}
            />
          </div>
        </div>
        <p className="promotions">Promotions</p>
        <div className="promotions_row">
          <Checkbox label={"email"} />
          <Checkbox label={"push notifications"} />
          <Checkbox label={"messages"} />
          <Checkbox label={"Phone Calls"} />
        </div>
      </div>
      <div
        style={{display: "flex", alignItems: "flex-start", gap: "6px"}}
        className="lower"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.49967 13.4993L3.99967 9.99935L2.83301 11.166L7.49967 15.8327L17.4997 5.83268L16.333 4.66602L7.49967 13.4993Z"
            fill="#9EA0A5"
          />
        </svg>
        <span>Changes are saved automatically</span>
      </div>
    </div>
  );
}

export default Notifications;
