import React, {useState} from "react";
import home_icon from "../../../assets/svg/home icon.svg";
import crossIcon from "../../../assets/svg/crossIcon.svg";
import {TextField} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import pdf_view from '../../../assets/svg/pdf_viw.svg';
import "./MarketFund.scss";
import {useNavigate} from "react-router-dom";

const MarketFund = () => {
  const navigate = useNavigate();
  const [isFormValidate, setIsFormValidate] = useState(false);
  const [isInputChange, setIsInputChange] = useState();
  const form = [
    {
      content: "The fund entity has an Exempt Payee Code",
    },
    {
      content: "The fund entity has an Exemption from FATCA reporting code",
    },
    {
      content:
        "The fund entity is not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding, and",
    },
    {
      content: "For tax purposes, the fund entity is considered a U.S. citizen or other",
    },
  ];
  const [selectedIndex, setSelectedIndex] = useState(0);

  const tabination = [
    {
      tab_name: "U.S. citizen",
    },
    {
      tab_name: "Non-U.S citizen",
    },
  ];
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <div className="market_fund_container">
      <div className="market_fund_header">
        <div
          className="cross"
          onClick={() => navigate("/manage-syndicate")}
        >
          <img
            src={crossIcon}
            alt=""
          />
        </div>
        <h2>Invest in a money market fund</h2>
      </div>
      <div className="market_fund_child">
        <div className="market_fund_content">
          <div className="left_side_market_fund">
            <div className="heading">
              <img
                src={home_icon}
                alt=""
              />
              <h1>Open a brokerage account to invest in a money market fund</h1>
              <p>The JPMorgan U.S. Government Money Market Fund is offered through Frec Securities LLC, member of FINRA and SIPC.</p>
            </div>
            <div className="progreess">
              <p>Additional details</p>
              <p>Terms of service</p>
            </div>
          </div>
          {isFormValidate ? (
            <div className="final_submission_page">
              <div className="heading">
                <h1>Account operating agreements</h1>
                <p>Please read and agree to the following.</p>
              </div>
              <div className="view_agreement">
                <img src={pdf_view} alt="" />
              <p>View agreements</p>
              </div>
              <div className="understand_check" style={{border:isInputChange ? '1px solid #002b31' : 'none'}}>
                <label htmlFor="understand">
                  Under penalties Of perjury, I certify all information provided in this application is accurate. I furthermore consent to opening the account and acknowledge receiving the documents
                  and disclosures above.
                </label>
                <input type="checkbox" id="understand" onChange={(e)=>setIsInputChange(e.target.checked ? true : false)}/>
              </div>
              <div className="final_submit_btn">
                <button className="back_btn" onClick={()=>setIsFormValidate(false)}>Back</button>
                <button className="submit_btn_final">Submit application</button>
              </div>
              <div className="bottom_info">
                <p>By agreeing to invest in the JPMorgan U.S. Government Money Market Fund, you are agreeing to open a brokerage account with Frec Securities LLC and to hold fund shares through that account. Neither the brokerage account nor the JPMorgan U.S. Government Money Market Fund are bank depository accounts or insured by the Federal Deposit Insurance Corporation. You can lose money investing in a fund. For more information about Frec Securities LLC, please see it's customer relationship statement. For more information about the JPMorgan U.S. Government Money Market Fund, please see the fund's prospectus and statement of additional information.</p>
              </div>
            </div>
          ) : (
            <div className="right_side_market_fund">
              <div className="heading">
                <h1>Additional details</h1>
                <p>Please provide some additional details required to open your brokerage account for investing into a money market fund.</p>
              </div>
              <div className="auth_signer">
                <h1>Authorized signer</h1>
                <div
                  className="Tabination"
                  style={{gap: selectedIndex === 0 ? "30px" : "10px"}}
                >
                  <div className="tabination_header">
                    {tabination?.map((item, index) => {
                      return (
                        <span
                          className={index === selectedIndex ? "active" : ""}
                          key={index}
                          onClick={() => {
                            setSelectedIndex(index);
                          }}
                        >
                          {item.tab_name}
                        </span>
                      );
                    })}
                  </div>
                  <div className="tabination_content">
                    <>
                      {selectedIndex === 1 && (
                        <div className="inputs">
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Age</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={age}
                              label="Age"
                              onChange={handleChange}
                              required
                            >
                              <MenuItem value={10}>Ten</MenuItem>
                              <MenuItem value={20}>Twenty</MenuItem>
                              <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                          </FormControl>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Outlined"
                            variant="outlined"
                          />
                        </div>
                      )}
                      <div className="form_w9">
                        <div className="form_w-9_heading">
                          <h1>Form W-9</h1>
                          <p>Please select all that apply to your fund entity.</p>
                        </div>
                        <div className="form_content">
                          {form?.map((data, index) => {
                            return (
                              <div key={index}>
                                <input
                                  type="checkbox"
                                  id={index}
                                />
                                <label htmlFor={index}>{data.content}</label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </>

                    <button
                      className="continue_btn"
                      onClick={() => setIsFormValidate(true)}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketFund;
