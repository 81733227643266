import React, {useState} from "react";
import "./FullPageSlider.scss";
import img1 from "../../assets/Image/floor-plan-big.png";
import add from "../../assets/svg/add.svg";
import cross from "../../assets/svg/cross.svg";
import logo from "../../assets/svg/lilipadsLogomain.svg";
import download from "../../assets/svg/download-btn.svg";
import img2 from "../../assets/Image/slider-img.png";
import {useSelector} from "react-redux";
import {PrimaryButtonSvgFirst} from "../../components/reusableComponents/Buttons/Buttons";

import {Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton} from "@mui/material";
import {CloudUpload as CloudUploadIcon} from "@mui/icons-material";
import {styled} from "@mui/system";
import {Modal} from "antd";

const FullPageSlider = ({handleSlider}) => {
  const images = [img1, img2, img1, img1, img1, img1];
  const [currentImage, setCurrentImage] = useState(0);
  const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false);

  const handleThumbnailClick = (index) => {
    setCurrentImage(index);
  };

  const handleUploadButtonClick = () => {
    setIsUploadPopupOpen(true);
  };
  const handleUploadClose = () => {
    setIsUploadPopupOpen(false);
  };



  let {selectedProperty} = useSelector((state) => state.portfolio);

  return (
    <div className="full-page-slider-container">
      <div className="main-slider-FullPageSlider">
        <div className="slider-header">
          <div className="left-header">
            <img
              src={logo}
              alt="img"
            />
            <div className="details-header">
              <img
                src={selectedProperty?.assetsImages[0]}
                alt="img"
              />
              <div>
                <h3>{selectedProperty?.assetName}</h3>
                <p>{selectedProperty?.assetsAddress}</p>
                <span>${selectedProperty?.assetValue}</span>
              </div>
            </div>
          </div>
          <div className="right-header">
            <PrimaryButtonSvgFirst
              name="Upload"
              classNames="Boardmeetings-pen"
              padding="10px"
              svgHeight="20px"
              svgWidth="20px"
              onclick={handleUploadButtonClick}
            />
            <PrimaryButtonSvgFirst
              name="Download"
              classNames="Boardmeetings-pen"
              padding="10px"
              svgHeight="20px"
              svgWidth="20px"
            />
            <img
              className="cross"
              src={cross}
              alt="Close"
              onClick={handleSlider}
            />
          </div>
        </div>
        {isUploadPopupOpen && (
          <div className="upload_floor_plan">
            <Modal
              title="Basic Modal"
              open={isUploadPopupOpen}
              onCancel={handleUploadClose}
              className="ant_popup"
            >
              <p>Some contents...</p>
              <p>Some contents...</p>
              <p>Some contents...</p>
            </Modal>
          </div>
        )}
        <div className="main-slider-wrapper">
          <img
            src={images[currentImage]}
            alt={`Slide ${currentImage + 1}`}
            className="main-image"
          />

          <div id="thumbnail-container">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index + 1}`}
                className={`thumbnail ${index === currentImage ? "active" : ""}`}
                onClick={() => handleThumbnailClick(index)}
              />
            ))}

            <button
              className="upload-button"
              onClick={handleUploadButtonClick}
            >
              <img
                src={add}
                alt="Upload"
              />
              Drag & drop plan here or click to upload
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const UploadButton = styled(Button)({
  backgroundColor: "#3f51b5",
  color: "#fff",
});

const PhotoUploadPopup = ({open, onClose, onUpload}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (selectedFile) {
      onUpload(selectedFile);
      onClose();
    }
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
    >
      <DialogTitle>Upload Photo</DialogTitle>
      <DialogContent>
        <input
          accept="image/*"
          style={{display: "none"}}
          id="raised-button-file"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="raised-button-file">
          <UploadButton
            variant="contained"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            Choose File
          </UploadButton>
        </label>
        {selectedFile && <p>Selected file: {selectedFile.name}</p>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleUpload}
          color="primary"
          variant="contained"
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FullPageSlider;
