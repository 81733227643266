import React, {useState} from "react";
import "./InvestmentDashboard.scss";
import inbox from "../../../../../../../assets/svg/inbox.svg";
import share from "../../../../../../../assets/svg/share.svg";
import investmentDahboardImg from "../../../../../../../assets/InvestmentDashboard/Head Pic.png";
import upArrorwsmall from "../../../../../../../assets/svg/upArrorwsmall.svg";
import Dollar_in_hand from "../../../../../../../assets/svg/Dollar_in_hand.svg";
import clockGray from "../../../../../../../assets/svg/clockGray.svg";
import dividerLine from "../../../../../../../assets/svg/dividerLine.svg";
import EarningsDistributionsChart from "../../../../../../../components/InvestmentPipelineComponents/Charts/EarningsDistributionsChart";
import uparrow from "../../../../../../../assets/svg/upArrorwsmall.svg";
import publishButtonIcons from "../../../../../../../assets/svg/publishButtonIcon.svg";
import rightArrow from "../../../../../../../assets/svg/rightArrowBlue.svg";
import Performance_Overview from "./InnerPages/Performance_Overview";
import SyndicateOwnerAnalytics from "../../../../../../../components/InvestmentPipelineComponents/SyndicateOwnerAnalytics";
import AssetInformation from "../../../../../../DealsRoom/AssetInformation";
import Sponsors from "./InnerPages/Sponsors";
import CommentsSection from "./InnerPages/CommentsSection";
import UpdateSection from "./InnerPages/UpdateSection";

const InvestmentDashboard = ({handleMenuClicked}) => {
  const dummyLatestActivity = [
    {
      month: "July",
      date: "28",
      newHeader: "New distribution for 2810 Whiptail Loop, LLC",
      amount: "12,423,412",
      subHeader: "was distributed to Hobart Family Capital, LP",
    },
    {
      month: "July",
      date: "28",
      newHeader: "New distribution for 2810 Whiptail Loop, LLC",
      amount: "12,423,412",
      subHeader: "was distributed to Hobart Family Capital, LP",
    },
    {
      month: "July",
      date: "28",
      newHeader: "New distribution for 2810 Whiptail Loop, LLC",
      amount: "12,423,412",
      subHeader: "was distributed to Hobart Family Capital, LP",
    },
    {
      month: "July",
      date: "28",
      newHeader: "New distribution for 2810 Whiptail Loop, LLC",
      amount: "12,423,412",
      subHeader: "was distributed to Hobart Family Capital, LP",
    },
  ];

  const [navActive, setNavActive] = useState(1);

  return (
    <div className="investmentDashboard_main">
      <div className="investmentDashboard_header">
        <div>
          <h1>Investment Dashboard</h1>
        </div>
        <div className="investmentDashboard_header_buttons">
          <button
            className="InBox_button"
            onClick={() => handleMenuClicked(2)}
          >
            <img
              src={inbox}
              alt="inbox"
            />
            <p>Inbox</p>
            <p className="InBox_message_count">1</p>
          </button>
          <button className="InBox_button">
            <img
              src={share}
              alt="inbox"
            />
            <p>Share</p>
          </button>
        </div>
      </div>
      <div>
        <img
          src={investmentDahboardImg}
          alt=""
          style={{width: "100%"}}
        />
      </div>

      <div className="syndicateHighlight_upper">
        <div className="syndicateHighlight_upper_left">
          <div className="syndicatePerformence_one">
            <div className="syndicatePerformence_one_inner">
              <div style={{display: "flex", gap: "6px"}}>
                <h3>4,125</h3>
                <div style={{display: "flex", gap: "4px", alignItems: "center", justifyContent: "center"}}>
                  <img
                    src={upArrorwsmall}
                    alt="upArrorwsmall"
                  />
                  <p>5.9%</p>
                </div>
              </div>
              <p>VIEWS</p>
            </div>
            <div className="syndicatePerformence_one_inner">
              <div style={{display: "flex", gap: "6px"}}>
                <h3>4,125</h3>
                <div style={{display: "flex", gap: "4px", alignItems: "center", justifyContent: "center"}}>
                  <img
                    src={upArrorwsmall}
                    alt="upArrorwsmall"
                  />
                  <p>5.9%</p>
                </div>
              </div>
              <p>VIEWS</p>
            </div>
            <div className="syndicatePerformence_one_inner">
              <div style={{display: "flex", gap: "6px"}}>
                <h3>4,125</h3>
                <div style={{display: "flex", gap: "4px", alignItems: "center", justifyContent: "center"}}>
                  <img
                    src={upArrorwsmall}
                    alt="upArrorwsmall"
                  />
                  <p>5.9%</p>
                </div>
              </div>
              <p>VIEWS</p>
            </div>
            <div className="syndicatePerformence_one_inner">
              <div style={{display: "flex", gap: "6px"}}>
                <h3>4,125</h3>
                <div style={{display: "flex", gap: "4px", alignItems: "center", justifyContent: "center"}}>
                  <img
                    src={upArrorwsmall}
                    alt="upArrorwsmall"
                  />
                  <p>5.9%</p>
                </div>
              </div>
              <p>VIEWS</p>
            </div>
            <div className="syndicatePerformence_one_inner">
              <div style={{display: "flex", gap: "6px"}}>
                <h3>4,125</h3>
                <div style={{display: "flex", gap: "4px", alignItems: "center", justifyContent: "center"}}>
                  <img
                    src={upArrorwsmall}
                    alt="upArrorwsmall"
                  />
                  <p>5.9%</p>
                </div>
              </div>
              <p>VIEWS</p>
            </div>
          </div>
          <div className="syndicatePerformence_two">
            <div className="syndicate-performance">
              <div className="syndicate-performance_header">
                <h3>Syndicate performance</h3>
              </div>
              <div className="progress-bar">
                <div className="progress-bar_one"></div>
                <div className="progress-bar_two"></div>
                <div className="progress-bar_three"></div>
              </div>
              <div className="funded-stats">
                <div className="funded-percentage">
                  <div className="funded-percentage_one">
                    <p>Funded statistics</p>
                    <p className="funded_statistics">$5,600,000</p>
                  </div>
                  <div className="funded-percentage_two">
                    <img
                      src={Dollar_in_hand}
                      alt="Dollar_in_hand"
                    />
                    <p>70% Funded</p>
                  </div>
                </div>

                <div style={{display: "flex", justifyContent: "center"}}>
                  <img
                    src={dividerLine}
                    alt="dividerLine"
                  />
                </div>

                <div className="pledged-percentage">
                  <div style={{display: "flex", gap: "4px"}}>
                    <img
                      src={clockGray}
                      alt=""
                    />
                    <p>14% Pledged</p>
                  </div>
                  <div className="funding-target">
                    <p>Funding target</p>
                    <p>$8,000,000</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="syndicateHighlight_upper_right">
          <div className="syndicateHighlight_upper_header">
            <p>Offer Available for:</p>
            <div className="syndicateHighlight_timestemp">
              <div className="timeStemp">
                <p>02</p>
                <p className="timeStemp_child">Days</p>
              </div>
              <div className="timeStemp">
                <p>02</p>
                <p className="timeStemp_child">Hours</p>
              </div>
              <div className="timeStemp">
                <p>02</p>
                <p className="timeStemp_child">Hours</p>
              </div>
              <div className="timeStemp">
                <p>02</p>
                <p className="timeStemp_child">Hours</p>
              </div>
            </div>
          </div>
          <div className="syndicateHighlight_upper_middle">
            <p>Public Deal Starts: </p>
            <h3>
              August 15, 2020 <span> at 11:30AM</span>
            </h3>
          </div>
          {/* <button className="syndicateHighlight_lower">
              <img
                src={publishButtonIcons}
                alt="publishButtonIcon"
              />
              Publish to the market now
            </button> */}
        </div>
      </div>

      <div className="syndicate_main">
        <div className="deals-room-nav gp_nav asset-nav ass-nav">
          <span
            className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
            onClick={() => {
              setNavActive(1);
            }}
          >
            Performance Overview
          </span>
          <span
            className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
            onClick={() => {
              setNavActive(2);
            }}
          >
            Analytics
          </span>
          <span
            className={navActive === 3 && "deals-room-nav-active ut-nav-active"}
            onClick={() => {
              setNavActive(3);
            }}
          >
            Assets Details
          </span>
          <span
            className={navActive === 4 && "deals-room-nav-active ut-nav-active"}
            onClick={() => {
              setNavActive(4);
            }}
          >
            Sponsors
          </span>
          <span
            className={navActive === 5 && "deals-room-nav-active ut-nav-active"}
            onClick={() => {
              setNavActive(5);
            }}
          >
            Comments
          </span>
          <span
            className={navActive === 6 && "deals-room-nav-active ut-nav-active"}
            onClick={() => {
              setNavActive(6);
            }}
          >
            Updates
          </span>
        </div>

        {navActive === 1 && <Performance_Overview />}
        {navActive === 2 && <SyndicateOwnerAnalytics />}
        {navActive === 3 && <AssetInformation />}
        {navActive === 4 && <Sponsors />}
        {navActive === 5 && <CommentsSection />}
        {navActive === 6 && <UpdateSection />}
      </div>
    </div>
  );
};

export default InvestmentDashboard;
