import React from "react";
import "./Securitiestemplates.scss";
import SearchInput from "../../../components/reusableComponents/Search/Search";

const Securitiestemplates = () => {
  return (
    <div>
      <div style={{marginTop: "10px", marginBottom: "10px"}}>
        <h1>Securities Template</h1>
      </div>
      <div className="Securitiestemplates-main-conatiner">
        <div className="Securitiestemplates-Conatiner">
          <div className="Conatiner-Securities">
            <div className="Conatiner-Securities-data">
              <p>Document sets</p>
              <p>legends</p>
              <p>vesting schedules</p>
              <p>performance consitions</p>
              <p>acceleration terms</p>
            </div>
          </div>
          <SearchInput />
        </div>
        <div>
          <div className="Conatiner-Securities-header">
            <h1>DOCUMENT NAME</h1>
            <h1>DOCUMENT</h1>
          </div>
          <hr />
        </div>
      </div>
    </div>
  );
};

export default Securitiestemplates;
