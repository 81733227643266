import navThumbnailOne from "../../../assets/portfolio/images/nav-thumbnail-1.png";
import navThumbnailTwo from "../../../assets/portfolio/images/nav-thumbnail-2.png";

export const items = [
    {
      id: "1",
      name: "Waltham Woods Apartments",
      img: navThumbnailOne,
      addressLine1: "164 S. Carson Court",
      addressLine2: "Newport News, VA 23601",
      owner: "Marvin Bell",
      status: "Actively managed",
      currency: "$",
      price: "1,290,000",
      newPricing: "1,386,239",
      propertyType: "Commercial",
      totalArea: "1400",
      suitableFor: "1625 People",
    },
    {
      id: "2",
      name: "Lotus Temple",
      img: navThumbnailTwo,
      addressLine1: "164 S. Carson Court",
      addressLine2: "Newport News, VA 23601",
      owner: "Ricardo Edwards",
      status: "For Sale",
      currency: "$",
      price: "1,570,000",
      newPricing: "1,886,239",
      propertyType: "Commercial",
      totalArea: "1400",
      suitableFor: "1625 People",
    },
    {
      id: "3",
      name: "Dome of the Rock",
      img: navThumbnailOne,
      addressLine1: "164 S. Carson Court",
      addressLine2: "Newport News, VA 23601",
      owner: "Randall Williamson",
      status: "Actively managed ",
      currency: "$",
      price: "690,000",
      newPricing: "886,239",
      propertyType: "Private",
      totalArea: "1400",
      suitableFor: "1625 People",
    },
    {
      id: "4",
      name: "Lloyd's Building",
      img: navThumbnailTwo,
      addressLine1: "164 S. Carson Court",
      addressLine2: "Newport News, VA 23601",
      owner: "Tyrone Richards",
      status: "For Rent",
      currency: "$",
      price: "1,290,000",
      newPricing: "1,386,239",
      propertyType: "Commercial",
      totalArea: "1400",
      suitableFor: "1625 People",
    },
    {
      id: "5",
      name: "Lotus Temple",
      img: navThumbnailOne,
      addressLine1: "164 S. Carson Court",
      addressLine2: "Newport News, VA 23601",
      owner: "Ricardo Edwards",
      status: "Actively managed ",
      currency: "$",
      price: "1,290,000",
      newPricing: "1,386,239",
      propertyType: "Private",
      totalArea: "1400",
      suitableFor: "1625 People",
    },
    {
      id: "6",
      name: "Lotus Temple",
      img: navThumbnailTwo,
      addressLine1: "164 S. Carson Court",
      addressLine2: "Newport News, VA 23601",
      owner: "Ricardo Edwards",
      status: "For Rent",
      currency: "$",
      price: "1,290,000",
      newPricing: "1,386,239",
      propertyType: "Private",
      totalArea: "1400",
      suitableFor: "1625 People",
    },
  ];

