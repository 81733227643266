import React, {useState} from "react";
import PlanImg from "../../assets/Image/plan.png";
import DeleteIcon from "../../assets/portfolio/svgs/delete.svg";
import RotateIcon from "../../assets/portfolio/svgs/rotate.svg";
import DropDown from "../../components/reusableComponents/Dropdown/Dropdown";
import {InputWithIcon, InputWithAddFieldFunc, UploadFile, TextInputWithLabel, DropdownWithCheckbox} from "../portfolio/Inputs";

export const PropertyPlan = () => {
  return (
    <div className="photo-container">
      <div className="photo-card-grid">
        <div className="photos-card">
          <img
            className="img"
            src={PlanImg}
            alt=""
          />
          <div className="controls-wrapper">
            <img
              src={RotateIcon}
              alt="Rotate Icon"
            />
            <img
              src={DeleteIcon}
              alt="Delete Icon"
            />
          </div>
          <div className="media-description">
            <DropDown
              label={"Description"}
              selectedData={"3 bedroom plan"}
              width={"288px"}
            />
          </div>
        </div>
        <div className="photos-card">
          <img
            className="img"
            src={PlanImg}
            alt=""
          />
          <div className="controls-wrapper">
            <img
              src={RotateIcon}
              alt="Rotate Icon"
            />
            <img
              src={DeleteIcon}
              alt="Delete Icon"
            />
          </div>
          <div className="media-description">
            <DropDown
              label={"Description"}
              selectedData={"3 bedroom plan"}
              width={"288px"}
            />
          </div>
        </div>
        <div className="photos-card">
          <img
            className="img"
            src={PlanImg}
            alt=""
          />
          <div className="controls-wrapper">
            <img
              src={RotateIcon}
              alt="Rotate Icon"
            />
            <img
              src={DeleteIcon}
              alt="Delete Icon"
            />
          </div>
          <div className="media-description">
            <DropDown
              label={"Description"}
              selectedData={"3 bedroom plan"}
              width={"288px"}
            />
          </div>
        </div>
        <div className="photos-card">
          <img
            className="img"
            src={PlanImg}
            alt=""
          />
          <div className="controls-wrapper">
            <img
              src={RotateIcon}
              alt="Rotate Icon"
            />
            <img
              src={DeleteIcon}
              alt="Delete Icon"
            />
          </div>
          <div className="media-description">
            <DropDown
              label={"Description"}
              selectedData={"3 bedroom plan"}
              width={"288px"}
            />
          </div>
        </div>
      </div>
      <h2>Add a floor plan</h2>
      <div className="upload-media">
        <UploadFile />
      </div>
    </div>
  );
};
