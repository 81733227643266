import React from "react";
import "./ISO100K.scss";
import SearchInput from "../../../components/reusableComponents/Search/Search";
import pagenotfound from "../../../assets/Manage-syndicate/Svg/page-not-found.svg";
import partnership from "../../../assets/Manage-syndicate/Svg/partnership-handshake.svg";

const ISO100K = () => {
  return (
    <div>
      <div style={{marginTop: "10px", marginBottom: "10px"}}>
        <h1>ISO 100K</h1>
      </div>
      <div className="ISO-conatiner">
        <div className="ISO-conatiner-data">
          <div className="ISO-conatiner-div-data">
            <span>Stakeholders with ISO and option grants</span>
            <span>Select a stakeholder to view detailed information about their holdings</span>
          </div>
          <div>
            <SearchInput />
          </div>
        </div>
      </div>
      {/* Second Section */}
      <div className="ISO-stakeholder">
        <div className="ISO-stakeholder-data">
          <div className="ISO-stakeholder-ISO">
            <div className="ISO-stakeholder-ISO-image">
              <img
                src={pagenotfound}
                alt="image"
              />
            </div>
            <span>No stakeholder with ISO grant founds</span>
          </div>
          <div className="left-ISO-Container">
            <div className="left-ISO-Container-data">
              <div className="ISO100K-data-Container">
                <h5>Select a stakeholder on the left to see a detailed breakdown og their ISO and ISO/NSO split grants</h5>
                <div className="ISO-stakeholder-ISO-partnership">
                  <img
                    src={partnership}
                    alt="image"
                  />
                </div>
              </div>
              <hr />
              <p>
                The ISO 100L limit is a rule imposed by the IRS that prevents an employee from treating more than $100,000 worth of exercisable option as incentive option (ISOs) in a given calendar
                year
              </p>
              <p>If your company is issuing or modifying an ISO option grant, Carta may automatically bifurcate an option grant into ISO & NSO shares based on the ISO 100k rules</p>
              <p>Issue and/or their legal counsel are responsible for reviewing the ISO/NSO split values to ensure compliance under the 100k rule.</p>
              <p>Certain modification to option grants like vesting acceleration, partial</p>
              <p>
                Certain modification to option grants like vesting acceleration, partial cancellation , and post- termination exercise period modification will likely require review and adjustment via
                the grant editor. Speak with your legal counsel and/or a tax professional to determine if this is necessary
              </p>
              <p>Modification to ISO/NSO split will impact cap table exercise reporting, disqualifying dispositions, and more</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ISO100K;
