import React from "react";
import ReactECharts from "echarts-for-react";

const PieChart = ({data}) => {

  const option = {
    title: {
      show: false,
      text: "Log Axis",
      left: "center",
    },
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        return params.seriesName + "<br/>" + params.name + ": " + params.value + "<br/>" + params.percent + "%";
      },
    },
    legend: {
      left: "left",
      show: false,
    },
    xAxis: {
      boundaryGap: false,
      show: false,
      type: "category",
      name: "x",
      splitLine: {show: false},
      // data: ["A", "B", "C", "D", "E", "F", "G", "H", "I"],
    },
    grid: {
      show: false,
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    yAxis: {
      show: false,
      type: "value",
      name: "y",
      position: "right",
      // min: 0,
      // max: 100,
      minorSplitLine: {
        show: true,
      },
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["40%", "60%"],
        avoidLabelOverlap: true,
        label: {
          bottom: "100px",
          show: true, // Show labels
          position: "center", // Position labels inside the pie chart
          formatter: "{d}%", // Show category name and percentage value
          fontSize: 10,
          fontWeight: "bold",
        },
        labelLine: {
          show: true,
        },
        data: [
          {value: data?.equity, name: "Search Engine", itemStyle: {color: "#7d55c6", bottom: "100px"}}, // Custom color
          {value: data?.max_loan, name: "Direct", itemStyle: {color: "#dbe0eb"}}, // Custom color
        ],
      },
      {
        name: "Access From",
        type: "pie",
        radius: ["40%", "60%"],
        avoidLabelOverlap: true,
        label: {
          show: true, // Show labels
          position: "center", // Position labels inside the pie chart
          formatter: "{d}%", // Show category name and percentage value
          fontSize: 10,
          fontWeight: "bold",
        },
        labelLine: {
          show: true,
        },
        data: [
          {value: data?.equity, name: "Search Engine", itemStyle: {color: "#7d55c6"}}, // Custom color
          {value: data?.max_loan, name: "Direct", itemStyle: {color: "#dbe0eb"}}, // Custom color
        ],
      },
    ],
  };

  return (
    <ReactECharts
      option={option}
      style={{width: "400px", height: "200px"}}
    />
  );
};

export default PieChart;
