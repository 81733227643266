import React, {useEffect, useRef} from "react";
// import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";

const BarChart = () => {
  const chartRef = useRef(null);
  const option = {
    color: "#c2c2c2",

    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : ${c}",
    },
    legend: {
      show: false,
      left: "left",
    },
    xAxis: {
      show: false,
      title: "trust",
      type: "category",
      name: "x",
      splitLine: {show: false},
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    yAxis: {
      title: "trust",
      type: "value",
      position: "right",
      min: 0,
      max: 100,
      minorSplitLine: {
        show: true,
      },
    },
    series: [
      {
        name: "Direct",
        type: "bar",
        barWidth: "60%",
        data: [10, 52, 100, 33, 390, 80, 220, 31, 211],
        emphasis: {
          itemStyle: {
            color: "#7d55c7", // Change color on hover
          },
        },
      },
      //   {
      //     name: "Log3",
      //     type: "line",
      //     symbol: "circle",
      //     symbolSize: 10,
      //     data: [1, 2, 4, 8, 16, 32, 64, 12, 25],
      //   },
      //   {
      //     name: "Log1/2",
      //     type: "line",
      //     symbol: "circle",
      //     symbolSize: 10,
      //     data: [50, 25, 12, 6, 3, 1.5, 0.75, 0.375, 0.1875],
      //   },
    ],
  };

  return <ReactECharts option={option} />;
};

export default BarChart;
