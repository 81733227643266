import React from "react";
import Chart from "react-apexcharts";

const PastInvestmentsChart = () => {
  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#1E90FF", "#A9A9A9"], // Blue and Grey
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3],
      curve: "smooth",
      dashArray: [0, 5],
    },
 
    markers: {
      size: 4,
      hover: {
        sizeOffset: 2,
      },
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      labels: {
        style: {
          colors: ["#808080"],
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      opposite: true,
      labels: {
        formatter: function (val) {
          return `$${val}M`;
        },
        style: {
          colors: ["#808080"],
          fontSize: "12px",
        },
      },
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
      offsetY: 10,
      labels: {
        colors: ["#808080"],
        useSeriesColors: false,
      },
      markers: {
        width: 10,
        height: 10,
        radius: 12,
      },
    },
    grid: {
      borderColor: "#f1f1f1",
      padding: {
        top: 10,
        right: 20,
        bottom: 0,
        left: 0,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `$${val}M`;
        },
      },
    },
  };

  const series = [
    {
      name: "CONTRIBUTION",
      data: [5, 10, 3, 7, 2, 8],
    },
    {
      name: "DISTRIBUTION",
      data: [7, 5, 6, 8, 1, 10],
    },
  ];

  return (
    <div className="chart">
      <Chart
        options={options}
        series={series}
        type="line"
        height={200}
      />
    </div>
  );
};

export default PastInvestmentsChart;
