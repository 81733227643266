import lilypandsLogo from "../../../assets/svg/lilipadsLogomain.svg";
import PRsvg3 from "../../../assets/svg/PRsvg3.svg";
import {PrimaryButton} from "../../../components/reusableComponents/Buttons/Buttons";
import {InputBox} from "../../../components/reusableComponents/FormFields/FormFields";

export function FourthSection({setSliderState}) {
  return (
    <div className="prFourthSection">
      <div className="signupCon-L">
        <div className="signupFirstSection signupsection">
          <div className="top_header">
            <img
              src={lilypandsLogo}
              alt=""
            />

            <div className="text_wrapper">
              <h2>Create new password</h2>
              <p>{"Good password consist of 8+ characters, upper and lower case letters, digits, special symbols e.g. !@#$%^&*()-=_+"}</p>
            </div>
          </div>
          <InputBox
            label="New password"
            type="password"
          />
          <br />
          <PrimaryButton
            classNames=" noMargin "
            name="Save password & Sign In"
            onclick={() => {
              //Add conditional slide here
              setSliderState("five");
            }}
          />
          <br />
          <p>Try to remember it and/or keep in a safe place.</p>
          <div className="spacerLine30"></div>

          <h3>
            I’ve remembered password. <span>Sign In.</span>
          </h3>
        </div>
      </div>
      <div className="signupCon-R ">
        <div className="container  ">
          <div className="passwordRecovery_svg_wrapper">
            <img
              src={PRsvg3}
              alt=""
            />
          </div>
          <div className="color_stripes">
            <div className="stripe1"></div>
            <div className="stripe2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
