import React, { useState, useEffect } from "react";
import "./NewHeader.scss";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserDetails, setIsChatOpen } from "../../store/reducers/userDetailsSlice";
import { PrimaryButton } from "../reusableComponents/Buttons/Buttons";
import logo from "../../assets/Image/Logo.png";
import menu_icon from "../../assets/Image/menu_icon.png";
import cross_icon from "../../assets/Image/cross_icon.png";
import Deals from "../../assets/Openai/deals.svg";
import Task from "../../assets/Openai/Task.svg";
import profile from "../../assets/profile.png";
import IncDev from "../../assets/svg/incdec.svg";
import ChatDropdown from "./ChatDropdown";

const Header = () => {
  const [showSideNav, setShowSidenav] = useState(false);
  const [chatDropdown, setChatDropdown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isAuthenticated, userInformation, idToken } = useSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    userInformation: state.auth.userInformation,
    idToken: state.auth.idToken,
  }));

  const { notificationCount, isChatopen } = useSelector((state) => state.userInfo);
  const [unseenMessages, setUnSeenMessages] = useState([]);

  const handleChat = () => {
    setChatDropdown(!chatDropdown);
    dispatch(setIsChatOpen());
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchUserDetails(idToken));
    }
  }, [dispatch, idToken, isAuthenticated]);

  useEffect(() => {
    setUnSeenMessages(
      notificationCount?.length > 0 && notificationCount?.filter((item) => item?.seen === false)
    );
  }, [notificationCount]);


  const checkPermissions = (path) => {
    if (isAuthenticated) {
      return true;
    }

    window.location.href = "/signin";
    return false;
  };
  

  return (
    <div className="headerO">
      <div className="headerO_CON">
        <div className="headerO-L">
          <img
            src={logo}
            alt="Logo"
            onClick={() => navigate("/")}
          />
          {/* <svg width="1" height="38" viewBox="0 0 1 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="1" height="38" fill="#EAEDF3" />
          </svg> */}
          <div className={showSideNav ? "header-tabs side_nav" : "header-tabs"}>
            {showSideNav && (
              <img
                src={cross_icon}
                width="25px"
                alt="Close"
                style={{ position: "absolute", top: "40px", left: "10px" }}
                onClick={() => setShowSidenav(false)}
              />
            )}
            {isAuthenticated && (
              <>
                {userInformation?.roleTypes === 2 && (
                  <Link
                    to={`/${userInformation?._id}/${userInformation?.roleTypes}/LilypadsAI`}
                    className={location.pathname === `/${userInformation?._id}/${userInformation?.roleTypes}/LilypadsAI` ? "active" : ""}
                    onClick={(e) => {
                      if (!checkPermissions(`/${userInformation?._id}/${userInformation?.roleTypes}/LilypadsAI`)) {
                        e.preventDefault();
                      }
                    }}
                  >
                    Lilypads AI
                  </Link>
                )}
              </>
            )}
          </div>
        </div>

        <div className="headerO-R">
          {isAuthenticated ? (
            <>
              <div className="header-info">
                <div className="leftComp">
                  <div className="message_icon_wrapper">
                    <Link
                      to={`/${userInformation?._id}/deals`}
                      onClick={(e) => {
                        if (!checkPermissions(`/${userInformation?._id}/deals`)) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <div className="message_icon">
                        <img src={Deals} alt="Deals" />
                      </div>
                      <p className="message_caption">Deal</p>
                    </Link>
                  </div>
                  <div className="message_icon_wrapper">
                    <Link
                      to={`/${userInformation?._id}/tasks`}
                      onClick={(e) => {
                        if (!checkPermissions(`/${userInformation?._id}/tasks`)) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <div className="message_icon">
                        <img src={Task} alt="Task" />
                      </div>
                      <p className="message_caption">Task</p>
                    </Link>
                  </div>
                  <div className="message_icon_wrapper">
                    <div className="message_icon">
                      <img
                        onClick={handleChat}
                        src={Deals}
                        alt="Message"
                      />
                    </div>
                    <p className="message_caption">Chat</p>
                  </div>
                </div>
                <div className="rightComp">
                  <Link
                    to={`/profile/${userInformation?._id}/${userInformation?.roleTypes}`}
                    onClick={(e) => {
                      if (!checkPermissions(`/profile/${userInformation?._id}/${userInformation?.roleTypes}`)) {
                        e.preventDefault();
                      }
                    }}
                  >
                    <div className="profile-details">
                      <div className="header_profile">
                        {userInformation?.profile_image ? (
                          <img
                            src={userInformation?.profile_image}
                            alt="Profile"
                            className="profile-pic"
                            style={{ borderRadius: "50%" }}
                          />
                        ) : (
                          <img
                            src={profile}
                            alt="Default Profile"
                            className="profile-pic"
                            style={{ borderRadius: "50%" }}
                          />
                        )}
                      </div>
                      <h6>
                        {`${userInformation?.name} ${userInformation?.surname}`} <br />
                        <span>{userInformation?.role}</span>
                      </h6>
                      <img src={IncDev} alt="Increase/Decrease" />
                    </div>
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <>
              <Link to="/signin">
                <p>Sign In</p>
              </Link>
              <Link to="/signup/investor">
                <PrimaryButton name="Sign up" />
              </Link>
              <div className="menu_icon_sideNav">
                <img
                  src={menu_icon}
                  alt="Menu"
                  width="25px"
                  onClick={() => setShowSidenav(true)}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {isChatopen && <ChatDropdown handleChat={handleChat} />}
    </div>
  );
};

export default Header;
