import React from "react";
import "./Draftshares.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faDownload, faPlus, faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import {PrimaryButton} from "../../../components/reusableComponents/Buttons/Buttons";
import pagenotfound from "../../../assets/Manage-syndicate/Svg/page-not-found 1.svg";
import SearchInput from "../../../components/reusableComponents/Search/Search";

const Draftshares = () => {
  return (
    <div>
      <div style={{marginTop: "10px", marginBottom: "10px"}}>
        <h1>Share Draft Sets</h1>
      </div>
      <div className="Draftshares-container">
        <div>
         <div className="header-draft-share">
         <SearchInput />
          <PrimaryButton
          name="New share draft set"
      
          classNames="Draftoptiongrants-share"
        />
         </div>
        </div>
        <div className="draft-share-data-container">
          <div className="draft-share-data">
            <h1>DRAFT NAME</h1>
            <h1>STATUS</h1>
            <h1>NUMBER OF SECURITIES</h1>
            <h1>LAST UPDATED</h1>
          </div>
        </div>
  
          <div>
          <div className="Equityplans-data">
            <div className="Equityplans-data-container-maindiv">
              <div className="Equityplans-Error-Container">
                <div className="Equityplans-data-container-image">
                  <img
                    src={pagenotfound}
                    alt="image"
                  />
                </div>
                <p>No Draft Found</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Draftshares;
