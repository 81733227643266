import React from "react";
import ReactApexChart from "react-apexcharts";

const renderPieChart = (pieData) => {
  if (!pieData) return null;

  return (
    <div className="chart">
      <ReactApexChart
        type="pie"
        series={pieData.map((item) => item.percentage)}
        options={{
          labels: pieData.map((item) => item.category),
          title: {text: "Occupancy Status"},
        }}
        height={300}
      />
    </div>
  );
};




const renderLineChart = (lineData) => {
  if (!lineData) return null;

  return (
    <div className="chart">
      <ReactApexChart
        type="line"
        series={[
          { name: "Average Rent", data: lineData.avg_rent },
          { name: "Occupancy Rate", data: lineData.occupancy_rate },
        ]}
        options={{
          xaxis: {
            categories: lineData.dates,
            axisBorder: { show: true },
            axisTicks: { show: true },
            // title: {
            //   text: "Month",
            //   style: {
            //     fontSize: "12px",
            //     fontWeight: "bold",
            //   },
            // },
          },
          yaxis: {
            axisBorder: { show: true },
            axisTicks: { show: true },
            // title: {
            //   text: "Revenue ($)",
            //   style: {
            //     fontSize: "12px",
            //     fontWeight: "bold",
            //   },
            // },
          },
          grid: {
            show: true,
            borderColor: "#e0e0e0",
            strokeDashArray: 5,
          },
          chart: {
            toolbar: { show: false },
            background: "transparent",
          },
          title: {
            text: "Trends Over Time",
            align: "center",
            style: {
              fontSize: "16px",
              fontWeight: "bold",
            },
          },
          legend: {
            show: true,
            position: "top",
            horizontalAlign: "center",
          },
          tooltip: {
            enabled: true,
            theme: "light", 
            style: {
              fontSize: "12px",
              fontFamily: undefined,
              color: "#000",
            },
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
              const value = series[seriesIndex][dataPointIndex];
              return `<div style="background: #fff; color: #000; padding: 10px; border-radius: 5px; border: 1px solid #ccc;">
                        <strong>${w.globals.seriesNames[seriesIndex]}:</strong> ${value}
                      </div>`;
            },
          },
        }}
        height={300}
      />
    </div>
  );
};




export {renderPieChart, renderLineChart};
