import React from "react";
import "./Equityplans.scss";
import {PrimaryButton} from "../../../components/reusableComponents/Buttons/Buttons";
import planning from "../../../assets/Manage-syndicate/Svg/planning.svg";

const Equityplans = () => {
  return (
    <div>
      <div>
        <div>
          <div style={{marginTop: "10px", marginBottom: "10px"}}>
            <h1>Equity Plans</h1>
          </div>
        </div>
        <div className="container-Equityplan">
          <div className="container-Equityplan-data">
            <PrimaryButton
              name="Create new plan"
              classNames="Equityplans-Filter"
            />
            <PrimaryButton
              name="Geerate Report"
              classNames="Equityplans-Filter-blue"
            />
          </div>
        </div>
        {/* <div className="box-data">
          <div className="box-data-container-maindiv">
            <div className="box-data-container">
              <div className="box-images">
                <img
                  src={planning}
                  alt="image"
                />
              </div>
              <p>No equity plan have been created</p>
            </div>
          </div>
        </div> */}
        <div>
          <div className="Equityplans-data">
            <div className="Equityplans-data-container-maindiv">
              <div className="Equityplans-Error-Container">
                <div className="Equityplans-data-container-image">
                  <img
                    src={planning}
                    alt="image"
                  />
                </div>
                <p>No equity plan have been created</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Equityplans;
