import {IOSSwitch, InputBox, SelectBox} from "../../../components/reusableComponents/FormFields/FormFields";
import coins1 from "../../../assets/svg/coins1.svg";
import coins2 from "../../../assets/svg/coins2.svg";
import editIcon from "../../../assets/svg/edit.svg";
import coins3 from "../../../assets/svg/coins3.svg";




const NinethSectionSQ = ({investmentBox, setInvestmentBox, lpBox, setLpBox, setInvestmentFrequency, setLps, setStrategyDescription, strategyDescription}) => {
    const handleClick = (id) => {
      setInvestmentBox(id);
      setInvestmentFrequency(id === 1 ? "1 to 5" : id === 2 ? "5 to 15" : id === 3 ? "more than 15" : "1 to 5");
    };
    const handleLpClick = (id) => {
      setLpBox(id);
      setLps(id === 1 ? "yes" : id === 2 ? "no yet" : id === 3 ? "No, Im looking to meet LPs through Lilypads" : "yes");
    };
    return (
      <>
        <div className="FirstSectionSQ sectionSignupQ">
          <div className="sectionSignupQ-T">
            <h1>
              Prospects{" "}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z"
                  fill="#9EA0A5"
                />
              </svg>
            </h1>
            <h2>Specify a few things for the future plans so we can do a better forecasts for you</h2>
          </div>
          <div className="sectionSignupQ-B">
            <div className="prospects">
              <div className="property_row">
                <div className="title">
                  <h2>How many investments do you plan to make in the next 12 months?</h2>
                </div>
                <div className="boxes_container">
                  <div className="investment_vehicles">
                    <div
                      className="container"
                      onClick={() => handleLpClick(1)}
                    >
                      <SelectBox
                        icon={coins1}
                        title={"1 to 5"}
                        id={1}
                        activeId={lpBox}
                      />
                    </div>
                    <div
                      className="container"
                      onClick={() => handleLpClick(2)}
                    >
                      <SelectBox
                        icon={coins2}
                        title={"5 to 15"}
                        id={2}
                        activeId={lpBox}
                      />
                    </div>
                    <div
                      className="container"
                      onClick={() => handleLpClick(3)}
                    >
                      <SelectBox
                        icon={coins3}
                        title={"more than 15"}
                        id={3}
                        activeId={lpBox}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="property_row">
                <div className="title">
                  <h2>Do you have LP’s lined up that want to invest in your fund?</h2>
                </div>
                <div className="boxes_container">
                  <div className="investment_vehicles">
                    <div
                      className="container"
                      onClick={() => handleClick(1)}
                    >
                      <SelectBox
                        // icon={coins1}
                        title={"Yes"}
                        id={1}
                        activeId={investmentBox}
                      />
                    </div>
                    <div
                      className="container"
                      onClick={() => handleClick(2)}
                    >
                      <SelectBox
                        // icon={coins2}
                        title={"Not yet"}
                        id={2}
                        activeId={investmentBox}
                      />
                    </div>
                    <div
                      className="container"
                      onClick={() => handleClick(3)}
                    >
                      <SelectBox
                        // icon={coins3}
                        title={"No, I'm looking to meet LPs through Lilypads"}
                        id={3}
                        activeId={investmentBox}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="property_row">
                <div className="title">
                  <h2>Tell your own strategy as if you were telling that to a friend</h2>
                </div>
                <div className="strategies">
                  <div className="strategy_card">
                    <div className="title">
                      <h2>
                        Buying Strategy
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z"
                            fill="#9EA0A5"
                          />
                        </svg>
                      </h2>
                    </div>
                    <p>acquisition goal</p>
                    <div className="textarea_wrapper">
                      <textarea
                        rows="10"
                        placeholder="eg. E.g. Looking to acquire property with high cap rate in under developed market to stabilize."
                        onChange={(e) => setStrategyDescription((prev) => ({...prev, buying: e.target.value}))}
                      />
                      <div className="buttons_wrapper">
                        <button>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.25 11.25L2.25 11.25L2.25 12.75L11.25 12.75V11.25ZM11.25 5.25L2.25 5.25L2.25 6.75L11.25 6.75V5.25ZM2.25 9.75L15.75 9.75V8.25L2.25 8.25L2.25 9.75ZM2.25 15.75L15.75 15.75V14.25L2.25 14.25L2.25 15.75ZM2.25 2.25L2.25 3.75L15.75 3.75V2.25L2.25 2.25Z"
                              fill="#9EA0A5"
                            />
                          </svg>
                          <span>Use suggested text</span>
                        </button>
                        <img
                          src={editIcon}
                          alt=""
                        />
                      </div>
                    </div>
                    <p>200 characters max</p>
                  </div>
                  <div className="strategy_card">
                    <div className="title">
                      <h2>
                        Managing Strategy
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z"
                            fill="#9EA0A5"
                          />
                        </svg>
                      </h2>
                    </div>
                    <p>Portfolio Management Goal</p>
                    <div className="textarea_wrapper">
                      <textarea
                        rows="10"
                        placeholder="E.g. We are focus on redevelopment cost of no more than 20% of the total assets value. We are specifically focus on integrating green appliances through out the asset with occupancy level of 95%"
                        onChange={(e) => setStrategyDescription((prev) => ({...prev, managing: e.target.value}))}
                      />
                      <div className="buttons_wrapper">
                        <button>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.25 11.25L2.25 11.25L2.25 12.75L11.25 12.75V11.25ZM11.25 5.25L2.25 5.25L2.25 6.75L11.25 6.75V5.25ZM2.25 9.75L15.75 9.75V8.25L2.25 8.25L2.25 9.75ZM2.25 15.75L15.75 15.75V14.25L2.25 14.25L2.25 15.75ZM2.25 2.25L2.25 3.75L15.75 3.75V2.25L2.25 2.25Z"
                              fill="#9EA0A5"
                            />
                          </svg>
                          <span>Use suggested text</span>
                        </button>
                        <img
                          src={editIcon}
                          alt=""
                        />
                      </div>
                    </div>
                    <p>200 characters max</p>
                  </div>
                  <div className="strategy_card">
                    <div className="title">
                      <h2>
                        Projected Exit Strategy
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z"
                            fill="#9EA0A5"
                          />
                        </svg>
                      </h2>
                    </div>
                    <p>Disposition Goal</p>
                    <div className="textarea_wrapper">
                      <textarea
                        rows="10"
                        placeholder="E.g. We look to exit this property in 5 years through a cash out refinance loan with Internal rate of return of %12 - 18% on value add."
                        onChange={(e) => setStrategyDescription((prev) => ({...prev, projected: e.target.value}))}
                      />
                      <div className="buttons_wrapper">
                        <button>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.25 11.25L2.25 11.25L2.25 12.75L11.25 12.75V11.25ZM11.25 5.25L2.25 5.25L2.25 6.75L11.25 6.75V5.25ZM2.25 9.75L15.75 9.75V8.25L2.25 8.25L2.25 9.75ZM2.25 15.75L15.75 15.75V14.25L2.25 14.25L2.25 15.75ZM2.25 2.25L2.25 3.75L15.75 3.75V2.25L2.25 2.25Z"
                              fill="#9EA0A5"
                            />
                          </svg>
                          <span>Use suggested text</span>
                        </button>
                        <img
                          src={editIcon}
                          alt=""
                        />
                      </div>
                    </div>
                    <p>200 characters max</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  export default NinethSectionSQ