import {useSelector} from "react-redux";

const useAuthConfig = () => {
  const {idToken} = useSelector((state) => state.auth);

  return {
    headers: {
      "auth-token": idToken,
    },
  };
};

export default useAuthConfig;


// import useAuthConfig from "../../utils/Config";
// const config = useAuthConfig();