import React, { useState } from "react";
import SvgColorChanger from "../DealsRoom/SvgColorChanger";
import dropdown from "../../assets/dealsroom/svg/dropdown.svg";
import History from "./History/History";
import { AiOutlineRight } from "react-icons/ai";  // Imported AiOutlineRight
import "./BeartacticAI.scss";
import Securities from "./Securities/Securities";
import Stakeholders from "./stakeholders/Stakeholders";
import Fundraising from "./Fundraising/Fundraising";
import Compliancetax from "./compliance & tax/Compliancetax";
import CompanySetting from "./Company Setting/CompanySetting";
import TotalCompensation from "./Total Compensation/TotalCompensation";
import Documents from "./documents/Documents";

const BeartacticAI = () => {
  const [isClicked, setIsClicked] = useState(1);
  const [syndicateState, setSyndicate] = useState(1);
  const [loanReqState, setLoanReq] = useState(1);
  const [expandedMenus, setExpandedMenus] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuClicked = (data) => {
    setIsClicked(data);
    if (data === 4) {
      setLoanReq(1);
    } else if (data === 5) {
      setSyndicate(1);
    }
    setIsMenuOpen(false);
  };

  const toggleMenu = (id) => {
    if (expandedMenus.includes(id)) {
      setExpandedMenus(expandedMenus.filter((menuId) => menuId !== id));
    } else {
      setExpandedMenus([...expandedMenus, id]);
    }
  };

  return (
    <div className="deals-room">
      <div
        className="hamburger-DealsRoom"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <div className="hamburger-DealsRoom-lines"></div>
        <div className="hamburger-DealsRoom-lines"></div>
        <div className="hamburger-DealsRoom-lines"></div>
      </div>
      {isClicked !== 20 && (
        <div className={`deals-room-left-container ${isMenuOpen ? "open" : ""}`}>
          <LeftMenu
            name={"History"}
            id={1}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
            expanded={expandedMenus.includes(1)}
            toggleMenu={toggleMenu}
          />
          <LeftMenu
            name={"Securities"}
            id={2}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
            expanded={expandedMenus.includes(2)}
            toggleMenu={toggleMenu}
          />
          <LeftMenu
            name={"Stakeholders"}
            id={3}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
            expanded={expandedMenus.includes(3)}
            toggleMenu={toggleMenu}
          />
          <LeftMenu
            name={"Fundraising"}
            id={4}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
          />
          <LeftMenu
            name={"Compliance & Tax"}
            id={5}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
          />
          <LeftMenu
            name={"Documents"}
            id={6}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
          />
          <LeftMenu
            name={"Total Compensation"}
            id={7}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
          />
          <LeftMenu
            name={"Company Setting"}
            id={8}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
          />
        </div>
      )}
      <div className="center-wrapper">
        {isClicked === 1 && <History />}
        {isClicked === 2 && <Securities />}
        {isClicked === 3 && <Stakeholders />}
        {isClicked === 4 && <Fundraising />}
        {isClicked === 5 && <Compliancetax />}
        {isClicked === 6 && <Documents />}
        {isClicked === 7 && <TotalCompensation />}
        {isClicked === 8 && <CompanySetting />}
      </div>
    </div>
  );
};

export default BeartacticAI;

const LeftMenu = ({ icon, name, id, state, handleMenuClicked }) => {
  return (
    <div
      className={`deals-left-menu ${id === state && "deals-menu-active"}`}
      onClick={() => handleMenuClicked(id)}
      style={{ marginBottom: "15px" }}
    >
      <SvgColorChanger svgContent={icon} />
      <span className="left-menu-title">{name}</span>
      <AiOutlineRight className="menu-arrow-icon" /> {/* Replaced with AiOutlineRight */}
    </div>
  );
};

const LeftMenuDrop = ({
  icon,
  name,
  id,
  state,
  handleMenuClicked,
  expanded,
  toggleMenu,
}) => {
  return (
    <div
      className={`deals-left-menu drop ${id === state && "deals-menu-active"}`}
      onClick={() => {
        handleMenuClicked(id);
        toggleMenu(id);
      }}
      style={{ marginBottom: "15px" }}
    >
      <div className="deal_left_data_icon">
        <SvgColorChanger svgContent={icon} />
        <span className="left-menu-title">{name}</span>
        <div className="menu_icon">
          <img src={dropdown} alt="dropdown icon" />
        </div>
      </div>
    </div>
  );
};

const LeftMenuSort = ({
  icon,
  name,
  id,
  state,
  handleMenuClicked,
  expanded,
}) => {
  return (
    expanded && (
      <div
        className={`deals-left-menu left-menu-sort ${
          id === state && "deals-menu-active-sort"
        }`}
        onClick={() => handleMenuClicked(id)}
        style={{ marginBottom: "10px" }}
      >
        <SvgColorChanger svgContent={icon} />
        <span className="left-menu-title">{name}</span>
        <AiOutlineRight className="menu-arrow-icon" /> {/* Replaced with AiOutlineRight */}
      </div>
    )
  );
};
