import React, { useState } from 'react'
import './Document.scss'
import uploadWhite from '../../assets/svg/upload-white.svg'
import search from '../../assets/svg/search.svg'
import attachment from '../../assets/svg/attachment.svg'
import document from '../../assets/svg/document.svg'
import DocumentDetails from './DocumentDetails'
import Checkbox from '../profile/settings/Checkbox'
import cross from '../../assets/svg/cross.svg'

function Document() {

    const [navActive, setNavActive] = useState(1);

    const [selectedRow, setSelectedRow] = useState(null);

    const [upload , setUpload] = useState(false)

    const handleUpload = () => {
        setUpload(!upload)
    }
    const handleRowClick = (row) => {
        setSelectedRow(row);
    };
    return (
        <div className='document'>
            <div className='document_header'>
                <h2>Document</h2>


                <div className='header_right'>

                    <div className="search_bar">
                        <input
                            type="text"
                            placeholder="Search"
                        />
                        <img src={search} alt='' />
                    </div>
                    <button onClick={handleUpload}>
                        <img src={uploadWhite} alt='' />
                        Upload Document
                    </button>
                </div>
            </div>

            {selectedRow ? (
              <DocumentDetails handleRowClick={handleRowClick} setSelectedRow={setSelectedRow} selectedRow={selectedRow} />
            ) : (

            <div className='document_table_box'>
                <div className='table_header'>
                <div className='table_nav'>
                            <div className=" deals-room-nav asset-nav ass-nav">
                                <span
                                    className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
                                    onClick={() => {
                                        setNavActive(1);
                                    }}
                                >
                                   All   <p>12</p>        </span>
                                <span
                                    className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
                                    onClick={() => {
                                        setNavActive(2);
                                    }}
                                >
                                    Attachments <p>18</p>
                                </span>

                                <span
                                    className={navActive === 3 && "deals-room-nav-active ut-nav-active"}
                                    onClick={() => {
                                        setNavActive(3);
                                    }}
                                >
                                    Project Docs
                                </span>


                            </div> </div>

                </div>

          
                <DocumentTable handleRowClick={handleRowClick} />

                {upload && <UploadBox handleUpload={handleUpload} />}
            </div>
            )}
        </div>
    )
}

export default Document



const DocumentTable = ({handleRowClick}) => {
    return (
        <div className="document-table">
            <div className="table-row header">
                <div className="column" style={{ minWidth: '300px' , display:'flex' , alignItems:'center' , gap:'10px'}}>
                    Name
                </div>
                <div className="column ">Type</div>
                <div className="column ">Uploaded</div>
                <div className="column ">Owner</div>
                <div className="column ">

                </div>
            </div>
            <TableRow
                name="Simple Icons.rar"
                type="Archive"
                uploaded="January 4, 2018 at 5:46am"
                owner="Jaxson Calzoni"
                onRowClick={() => handleRowClick({
                    name: "Simple Icons.rar",
                })}
            />
            <TableRow
                name="Simple Icons.rar"
                type="Archive"
                uploaded="January 4, 2018 at 5:46am"
                owner="Jaxson Calzoni"
            />
            <TableRow
                name="Simple Icons.rar"
                type="Archive"
                uploaded="January 4, 2018 at 5:46am"
                owner="Jaxson Calzoni"
            />
            <TableRow
                name="Simple Icons.rar"
                type="Archive"
                uploaded="January 4, 2018 at 5:46am"
                owner="Jaxson Calzoni"
            />
            <TableRow
                name="Simple Icons.rar"
                type="Archive"
                uploaded="January 4, 2018 at 5:46am"
                owner="Jaxson Calzoni"
            />
            <TableRow
                name="Simple Icons.rar"
                type="Archive"
                uploaded="January 4, 2018 at 5:46am"
                owner="Jaxson Calzoni"
            />
        </div>
    );
};

const TableRow = ({ name, type, uploaded, owner, onRowClick }) => {
    return (
        <div className="row table-row" onClick={onRowClick}>
            <div className="cell1" style={{ minWidth: '300px' , display:'flex' , alignItems:'center' , gap:'10px'}}>
                <img src={document} alt='' />
                {name}
            </div>
            <div className="cell ">{type}</div>
            <div className="cell ">{uploaded}</div>
            <div className="cell ">{owner}</div>
            <div className="cell right">
                <img src={attachment} alt='' />
            </div>
        </div>
    );
};



const UploadBox = ({ handleUpload }) => {
    return (
        <div className='upload_modal'>
            <div className='uploadbox'>
                <div className='basic_header'>
                    <p>Upload Files</p>
                    <img onClick={handleUpload} src={cross} alt='' />
                </div>

                <div className='inputs'>

                   <div className='attachment'>
                    <img src={document} alt='' />
                    <span style={{fontSize:'14px'}}>Drag & Drop files here</span>
                    <span style={{fontSize:'12px'}}>Max. size 10MB</span>
                   </div>

                   <p>Options</p>
          

                </div>
                <div className='users'>
                <Checkbox label={'Notify Users'} />
                </div>
                <div className='btns'>
                    <div>
                        <button className='btn_cancel'>
                            Cancel
                        </button>
                    </div>
                    <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                        <button className='btn_right'>Upload</button>
                    </div>
                </div>
            </div>
        </div>
    )
}