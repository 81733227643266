import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Typography, CircularProgress} from "@mui/material";

// The ReadToInvest Dialog Component

export const ReadToInvest = ({open, onClose, iban, bic, setIban, setBic, handleInvestment}) => {
  const handleConfirm = async () => {
    await handleInvestment();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Investment Details</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="IBAN Number"
          type="text"
          fullWidth
          variant="outlined"
          value={iban}
          onChange={(e) => setIban(e.target.value)}
        />
        <TextField
          margin="dense"
          label="BIC Code"
          type="text"
          fullWidth
          variant="outlined"
          value={bic}
          onChange={(e) => setBic(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
