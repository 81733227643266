import {IOSSwitch, InputBox} from "../../../components/reusableComponents/FormFields/FormFields";
import cross from "../../../assets/svg/cross.svg";
import profilebig from "../../../assets/svg/profilebig.png";
import profile from "../../../assets/svg/profileAvatar.png";

const InviteBox = ({handleInvite}) => {
  return (
    <div
      style={{top: "3%", right: "223px", zIndex: "111111"}}
      className="invitebox_modal"
    >
      <div className="invitebox_wrapper invite-pointer">
        <div className="invitebox_header">
          <h2>Deals Manager</h2>
          <img
            src={cross}
            alt=""
            onClick={handleInvite}
          />
        </div>
        <div className="invitebox_profile">
          <img
            src={profilebig}
            alt=""
          />
          <div>
            <p>Jeff Morgan</p>
            <span>Marketing</span>
          </div>
        </div>
        <div className="invitebox_people">
          <div style={{display: "flex", alignItems: "center", gap: "4px"}}>
            <img
              style={{height: "38px", width: "38px"}}
              src={profile}
              alt=""
            />
            <p className="text">
              <b style={{fontSize: "13px"}}>1 person</b> has access to the Insurance Checkllist{" "}
            </p>
          </div>
        </div>

        <div className="add_people">
          <div>
            <InputBox
              label={"add people"}
              width={"394px"}
            />
            <button>Add</button>
          </div>
          <span>After people will be notified by email they have to confirm invitation.</span>
        </div>
      </div>{" "}
    </div>
  );
};

export default InviteBox;
