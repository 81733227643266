import React from 'react'
import './documentDetails.scss'
import book from '../../assets/svg/book.svg'
import profile from '../../assets/svg/profile-plus.svg'
import Print from '../../assets/dealsroom/svg/print.svg'
import Delete from '../../assets/svg/delete.svg'
import upload from '../../assets/svg/upload.svg'
import cross from '../../assets/svg/cross.svg'

function DocumentDetails({ selectedRow , setSelectedRow  }) {
    return (
        <div className='document_details'>
            <div className='details_header'>
                <div className='details_header_left'>
                    <img src={book} alt='' />
                    <h3>{selectedRow.name}</h3>
                </div>
                <div className='details_header_right'>
                    <img src={profile} alt='' />
                    <img src={upload} alt='' />
                    <img src={Print} alt='' />
                    <img src={Delete} alt='' />
                    <img style={{marginLeft:'12px'}} src={cross} alt='' onClick={() => setSelectedRow(null)} />
                </div>
            </div>
            <div className='document_content'>
                <div className='document_content_upper'>
                    <h2>PacEater Game</h2>
                    <p>Pacman Artificial Intelligence Python project for Duke's Intro to AI Course</p>
                </div>

                <div className='document_content_lower'>
                    <p>
                    This is a two-part class project completed for Duke's Intro to AI (COMPSCI 270) course taught by Dr. Konidaris.
                    <br></br>
                    <br></br>
                    Depth-First Search (DFS): Graph search that avoids expanding already visited states. Fringe implemented via stack.
                    Breadth-First Search (BFS): Graph search that avoids expanding already visited states. Fringe implemented via queue.
                    Uniform Cost Search (UCS): Graph search that avoids expanding already visited states. Fringe implemented via Priority Queue.
                    A* Search: uses Manhattan distance heuristic to find optimal solution
                    CornersProblem: Search problem and heuristic for pacman to reach all active corner dots on board.
                    FoodSearchProblem: Search problem and heuristic for pacman to eat all active dots on board.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default DocumentDetails