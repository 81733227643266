import React from 'react'
import './Budget.scss'
import SavedHeader from './SavedHeader'
import edit from '../../../assets/svg/edit.svg'
import Propcard1 from "../../../assets/Image/assetDiscovery/general-bg.png";
import budget from '../../../assets/Image/budget.png'
import clock from '../../../assets/svg/clock.svg'
import person from '../../../assets/Image/profile-pic.png'
import dot from '../../../assets/svg/dot-gray.svg'
function Budget() {
    return (
        <>
            <SavedHeader title={'Saved Forecasts'} />
            <div className='budget'>
                <BudgetBox />
                <BudgetBox />

            </div>
        </>
    )
}

export default Budget


const BudgetBox = () => {
    return (
        <div className='budget_box'>
            <div className='budget_box_header'>
                <h3 style={{marginBottom:'10px'}}>Apartments Waltham Woods</h3>
                <div>
                    <div className="property_card-T">
                        <div className="photo_wrapper">

                            <img
                                style={{ width: '68px', height: '60px', borderRadius: '4px' }}
                                src={Propcard1}
                                alt=""
                            />


                        </div>
                        <div className="text_wrapper">
                            <div style={{ display: "flex", gap: '12px' }}>
                                <div className="box">
                                    3 <span></span>
                                </div>
                                <p>164 S. Carson Court Newport News, VA 23601</p>
                            </div>

                            <div>
                                <h4>
                                    $1,000,000
                                </h4>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <img src={budget} alt='' />
            <p className='text'>Under-performing asset Acquisition for 9 years, 4% assumptions, -4% deviation rent, 84% renewal rate</p>
            <div className='details'>
                <div className='left'>
                    <img src={person} alt='' />
                    <div>
                    <span>Created by</span>
                    <p>Joel Pearson</p>
                    </div>
                </div>
                <div className='right'>
                    <span>Date Created</span>
                    <p>                     <img src={clock} alt='' />
                    2019/11/28</p>
                </div>
            </div>
            <div className='budget_box_lower'>
                <button className='btn_left'>Show Results</button>
                <button className='btn_right'><img src={edit} alt='' /> Edit Settings</button>
                <div>
                    <img style={{rotate:'90deg'}} src={dot} alt='' />
                </div>
            </div>
        </div>
    )
}