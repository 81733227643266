import React, {useState} from "react";
import ReactDOM from "react-dom";
import ReactApexChart from "react-apexcharts";

const TimeChart = () => {
  const [series] = useState([
    {
      data: [
        {
          x: "#Hurdle 1",
          y: [new Date("2019-03-02").getTime(), new Date("2019-03-04").getTime()],
        },
        {
          x: "#Hurdle 2",
          y: [new Date("2019-03-04").getTime(), new Date("2019-03-08").getTime()],
        },
        {
          x: "#Hurdle 3",
          y: [new Date("2019-03-08").getTime(), new Date("2019-03-12").getTime()],
        },
        {
          x: "#Hurdle 4",
          y: [new Date("2019-03-08").getTime(), new Date("2019-03-12").getTime()],
        },
        {
          x: "Final Distribution",
          y: [new Date("2019-03-12").getTime(), new Date("2019-03-18").getTime()],
        },
      ],
    },
  ]);

  const [options] = useState({
    chart: {
      height: 350,
      type: "rangeBar",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      type: "datetime",
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="rangeBar"
          height={350}
        />
      </div>
    </div>
  );
};

export default TimeChart;
