import React from 'react'
import './Disclaimer.scss'
import AssestCard from './AssetCard'
import photo from "../../assets/acqusition/svg/photo.svg";
import location from "../../assets/acqusition/svg/location.svg";
import street from "../../assets/acqusition/svg/street.svg";
import bookmark from "../../assets/acqusition/svg/bookmark.svg";
import share from "../../assets/acqusition/svg/share.svg";
import compare from "../../assets/acqusition/svg/compare.svg";
import CrowdfundingHeader from './CrowdFundingHeader';

function Disclaimer() {

   
      
  return (
    <div className='disclaimer'>
    <CrowdfundingHeader title={'Disclaimer'} />

    <div className='disclaimer_container'>
      <div className='disclaimer_center'>
        <p>The content on this detail page was produced by the Sponsor or an affiliate thereof. The Sponsor is under no obligation to update this detail page. None of the opinions expressed on this detail page are the opinions of CrowdStreet and they are not endorsed by CrowdStreet. Assumptions and projections included in this detail page are not reflective of the position of CrowdStreet or any other person or entity other than the Company or its affiliates.</p>
         <p>The preceding summary of principal terms of the offering is qualified in its entirety by reference to the more complete information about the offering contained in the offering documents, including, without limitation, the Private Placement Memorandum, Operating Agreement, Subscription Agreement and all exhibits and other documents attached thereto or referenced therein (collectively, the "Investment Documents"). This summary is not complete, and each prospective investor should carefully read all of the Investment Documents and any supplements thereto, copies of which are available by clicking the links above or upon request, before deciding whether to make an investment. In the event of an inconsistency between the preceding summary and the Investment Documents, investors should rely on the content of the Investment Documents.</p>
         <p>There can be no assurance that the methodology used for calculating targeted IRR is appropriate or adequate. Target IRR is presented solely for the purpose of providing insight into the Company’s investment objectives, detailing its anticipated risk and reward characteristics and for establishing a benchmark for future evaluation of the Company’s performance. Targeted IRR is not a predictor, projection or guarantee of future performance. There can be no assurance that the Company’s targets will be met or that the Company will be successful in identifying and investing in investment opportunities that would allow the Company to meet these return parameters. Target returns should not be used as a primary basis for an investor’s decision to invest in the Company. Please see the applicable Investment Documents for disclosure relating to forward-looking statements.</p>
         <p>All forward–looking statements attributable to the Sponsor or persons acting on its behalf apply only as of the date of the offering and are expressly qualified in their entirety by the cautionary statements included elsewhere in this summary and the Investment Documents. Any financial projections are preliminary and subject to change; the Sponsor undertakes no obligation to update or revise these forward–looking statements to reflect events or circumstances that arise after the date made or to reflect the occurrence of unanticipated events. Inevitably, some assumptions will not materialize, and unanticipated events and circumstances may affect the ultimate financial results. Projections are inherently subject to substantial and numerous uncertainties and to a wide variety of significant business, economic and competitive risks, and the assumptions underlying the projections may be inaccurate in any material respect. Therefore, the actual results achieved may vary significantly from the forecasts, and the variations may be material.</p>
         <p>The interests in the Company will not be registered under the Securities Act of 1933, as amended (the “Securities Act”) in reliance upon exemptions contained in 506(c) of Regulation D as promulgated under the Securities Act. In addition, the interests will not be registered under any state securities laws in reliance on exemptions from registration. Such interests are subject to restrictions on transferability and resale and may not be transferred or resold except as permitted under applicable state and federal securities laws pursuant to registration or an available exemption.</p>
         <p>All investing activities risk the loss of capital. There can be no assurance that investors will not suffer significant losses. No guarantee or representation is made that investment objectives of the Company will be achieved. You should not subscribe to purchase interests in the Company unless you can readily bear the consequences of such loss.</p>
        </div>
    <div className='disclaimer_right'>
        <AssestCard />
    </div>
    </div> </div>
  )
}

export default Disclaimer


  