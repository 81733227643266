const initialState = {
    userPermissions: [],
  };
  
  const userPermissionsReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_USER_PERMISSIONS':
        return {
          ...state,
          userPermissions: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default userPermissionsReducer;
  