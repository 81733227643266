
import './styles.scss';

function ImageModal({setIsOpenModal, src , alt}) {

  const closeModal = () => {
    setIsOpenModal(false)
  }
  return (
    <div id="myModal" className="modal">
      <span className="close" onClick={closeModal}>&times;</span>
      <img className="modal-content" id="img01" src={src} alt={alt}/>
    
    </div>
  );
}

export default ImageModal;
