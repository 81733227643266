import React, {useState} from "react";
import {useSelector} from "react-redux";
import stonksIcon from "../../../../assets/portfolio/svgs/stonks.svg";
import lossIcon from "../../../../assets/portfolio/svgs/loss.svg";
import UtilityGraphOne from "../../../../assets/portfolio/images/utility-graph-1.png";

const Utilities = ({selectedItem}) => {
  const userPermissions = useSelector((state) => state.portfolio.assetPermissions);
  const userInformation = useSelector((state) => state.userInfo.userInformation); 
  const roleType = userInformation?.roleTypes; 

  const hasEditPermission = (section, permission) => {
    const foundPermission = userPermissions?.find((perm) => perm.section === section);
    return foundPermission ? foundPermission.permissions[permission] === true : false;
  };

  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editPopupTitle, setEditPopupTitle] = useState("");

  const handleEditClick = (title) => {
    setEditPopupTitle(title);
    setShowEditPopup(true);
  };

  const hasUtilitiesPermission = roleType === 2 ||  hasEditPermission("Property Data Overview", "Utilities");



  return (
    <div>
      <div className="Edit_Utilities">
        {hasUtilitiesPermission && (
          <>
            <button
              className="edit-button"
              onClick={() => handleEditClick("Unit Comparison")}
            >
              Edit
            </button>
          </>
        )}
      </div>
      <div
        className="utilities-card"
        style={{height: "600px", overflowY: "auto", marginTop:"15px"}}
      >
        <h2>Unit Comparison</h2>
        <div className="line">&nbsp;</div>
        <div className="unit-details-grid">
          <div className="unit-details">
            {/* Example unit details */}
            <div className="unit-details-building-label">
              <p style={{backgroundColor: "#34AA44"}}>&nbsp;</p>
              <h4>Building 1</h4>
            </div>
          </div>
          {/* Additional unit details */}
        </div>
        <div className="line">&nbsp;</div>
        <img
          src={UtilityGraphOne}
          alt=""
          className="unit-graph"
        />
        <div className="consuming-value-grid">
          <div className="consuming-value-header">
            <h2>Highest Consuming Units</h2>
            <div className="line">&nbsp;</div>
          </div>
          <div className="consuming-value-header">
            <h2>Value Discrepancies</h2>
            <div className="line">&nbsp;</div>
          </div>
          <div className="consuming-value-graph">{/* Graph or content for value discrepancies */}</div>
          <div className="consuming-value-content">
            {/* Example content */}
            <div className="value-cost-header">
              <div className="value-content-cost">
                <p style={{backgroundColor: "#34AA44"}}>&nbsp;</p>
                <h4>$2021.34</h4>
              </div>
            </div>
            <div className="value-budget-indicator">
              <h4 style={{color: "#e6492d"}}>Over budget</h4>
              <img
                src={lossIcon}
                alt="loss-icon"
              />
              <p style={{backgroundColor: "#bb4040"}}>+40%</p>
            </div>
            {/* Next Item */}
            <div className="value-cost-header">
              <div className="value-content-cost">
                <p style={{backgroundColor: "#9B51E0"}}>&nbsp;</p>
                <h4>$2021.34</h4>
              </div>
            </div>
            <div className="value-budget-indicator">
              <h4 style={{color: "#27AE60"}}>Lower budget</h4>
              <img
                src={stonksIcon}
                alt="loss-icon"
              />
              <p style={{backgroundColor: "#55ab68"}}>-8%</p>
            </div>
            {/* Next Item */}
            <div className="value-cost-header">
              <div className="value-content-cost">
                <p style={{backgroundColor: "#ED962D"}}>&nbsp;</p>
                <h4>$2021.34</h4>
              </div>
            </div>
            <div className="value-budget-indicator">
              <h4 style={{color: "#27AE60"}}>Lower budget</h4>
              <img
                src={stonksIcon}
                alt="loss-icon"
              />
              <p style={{backgroundColor: "#55ab68"}}>-8%</p>
            </div>
          </div>
        </div>

        {/* Render the EditPopup component */}
        <EditPopup
          isOpen={showEditPopup}
          onClose={() => setShowEditPopup(false)}
          title={editPopupTitle}
        />
      </div>
    </div>
  );
};

const EditPopup = ({isOpen, onClose, title}) => {
  if (!isOpen) return null;

  return (
    <div className="edit_popup_Utilities">
      <div className="edit_popup_content_Utilities">
        <h2>{title}</h2>
        <p>Edit content for {title}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Utilities;
