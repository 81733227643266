import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {InputBox} from "../../components/reusableComponents/FormFields/FormFields";
import {PrimaryButton} from "../../components/reusableComponents/Buttons/Buttons";
import {Checkbox, FormControl, FormControlLabel} from "@mui/material";

const AdvancedFilters = ({onClick}) => {
  return (
    <div className="AssetDiscoveryFilters">
      <div className="AssetDiscoveryFilters-T">
        <Tabs selectedTabClassName="active">
          <TabList>
            <Tab>Location</Tab>
            <Tab>Asset Type</Tab>
            <Tab>Building & Lot</Tab>
            <Tab>Sales</Tab>
            <Tab>Debt</Tab>
            <Tab>Tenants</Tab>
            <Tab>Ownership</Tab>
            <Tab>Tax</Tab>
            <Tab>Comps</Tab>
            <Tab>Amenities</Tab>
            <Tab>Investors</Tab>
            <Tab>Other</Tab>
          </TabList>
          <TabPanel>
            <Location />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          {/* <TabPanel>
            <Building />
          </TabPanel>
          <TabPanel>
            <Sales />
          </TabPanel>
          <TabPanel>
            <Debt />
          </TabPanel>
          <TabPanel>
            <Tenants />
          </TabPanel>
          <TabPanel>
            <Ownership />
          </TabPanel>
          <TabPanel>
            <Tax />
          </TabPanel>
          <TabPanel>
            <Comps />
          </TabPanel>
          <TabPanel>
            <Amenities />
          </TabPanel>
          <TabPanel>
            <Investors />
          </TabPanel>
          <TabPanel>
            <Other />
          </TabPanel> */}
        </Tabs>
      </div>

      <div className="AssetDiscoveryFilters-B">
        <div className="white_buttons">
          <span>New Oxford, PA</span>{" "}
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.4142 9.00091L13.9497 5.46537L12.5355 4.05116L9 7.58669L5.46447 4.05116L4.05025 5.46537L7.58579 9.00091L4.05025 12.5364L5.46447 13.9507L9 10.4151L12.5355 13.9507L13.9497 12.5364L10.4142 9.00091Z"
              fill="white"
              fill-opacity="0.01"
            />
            <mask
              id="mask0_13635_57581"
              maskUnits="userSpaceOnUse"
              x="4"
              y="4"
              width="10"
              height="10"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.4142 9.00091L13.9497 5.46537L12.5355 4.05116L9 7.58669L5.46447 4.05116L4.05025 5.46537L7.58579 9.00091L4.05025 12.5364L5.46447 13.9507L9 10.4151L12.5355 13.9507L13.9497 12.5364L10.4142 9.00091Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_13635_57581)">
              <rect
                opacity="0.5"
                width="18"
                height="18"
                fill="#9EA0A5"
              />
            </g>
          </svg>
        </div>
        <div
          className="applyButton"
          onClick={() => onClick()}
        >
          <PrimaryButton name={"Apply Filters"} />
        </div>
      </div>
    </div>
  );
};

export default AdvancedFilters;
function Location(params) {
  return (
    <div className="location">
      <div className="left"></div>
      <div className="right">
        <div className="column">
          <div className="row">
            <InputBox
              label="Neighborhood"
              placeholder="Example"
            />
          </div>
          <div className="row">
            <InputBox
              label="County"
              placeholder="Example"
            />
          </div>
          <div className="row">
            <InputBox
              label="MSA"
              placeholder="Example"
            />
          </div>
        </div>
        <div className="column">
          <div className="row">
            <InputBox
              label="State"
              placeholder="Example"
            />
          </div>
          <div className="row">
            <InputBox
              label="Street"
              placeholder="Example"
            />
          </div>
          <div className="row">
            <InputBox
              label="City"
              placeholder="Example"
            />
          </div>
        </div>
        <div className="column">
          <div className="row">
            <InputBox
              label="ZIP"
              placeholder="Example"
            />
          </div>
          <div className="row">
            <InputBox
              label="Market"
              placeholder="Example"
            />
          </div>
          <div className="row">
            <InputBox
              label="Submarket"
              placeholder="Example"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
function Asset(params) {
  return (
    <div className="asset">
      <div className="left">
        <Tabs selectedTabClassName="active">
          <TabList>
            <Tab>Commercial</Tab>
            <Tab>Multifamily</Tab>
            <Tab>Industrial</Tab>
            <Tab>Special Purpose</Tab>
            <Tab>Vacant Land</Tab>
            <Tab>New/old construction</Tab>
            <Tab>Other</Tab>
          </TabList>

          <TabPanel>
            <CommercialOpts />
          </TabPanel>
          <TabPanel>
            <MultifamilyOpts />
          </TabPanel>
          <TabPanel>
            <IndustrialOpts />
          </TabPanel>
          <TabPanel></TabPanel>
          <TabPanel></TabPanel>
          <TabPanel></TabPanel>
        </Tabs>
      </div>
    </div>
  );
}
function CommercialOpts(params) {
  return (
    <div className="right">
      <div className="column">
        <div className="row">
          <div className="subCat">
            <div className="subCat-L">
              <SubCatIcon /> <p>All Commercial General/Misc.</p>
            </div>{" "}
            <span>14,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Commercial (General) "
              />
            </div>
            <span>146</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Commercial Condominium "
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Self-Storage"
              />
            </div>
            <span>3,346</span>
          </div>
        </div>
        <div className="row">
          <div className="subCat">
            <div className="subCat-L">
              <SubCatIcon /> <p>All Office</p>
            </div>{" "}
            <span>14,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Business Park  "
              />
            </div>
            <span>146</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Financial Building "
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Medical Building "
              />
            </div>
            <span>3,346</span>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="row">
          <div className="subCat">
            <div className="subCat-L">
              <SubCatIcon /> <p>All Hospitality</p>
            </div>{" "}
            <span>14,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Hotel "
              />
            </div>
            <span>146</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Hotel Condominium "
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Motel "
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Resort  "
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Transient Lodging  "
              />
            </div>
            <span>3,346</span>
          </div>
        </div>
        <div className="row">
          <div className="subCat">
            <div className="subCat-L">
              <SubCatIcon /> <p>All Mixed Use</p>
            </div>{" "}
            <span>14,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Commercial/Industrial "
              />
            </div>
            <span>146</span>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="row">
          <div className="subCat">
            <div className="subCat-L">
              <SubCatIcon /> <p>All Retail</p>
            </div>{" "}
            <span>14,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Automotive Repair Shop "
              />
            </div>
            <span>146</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Automotive Sales "
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Bar/Tavern/Nightclub "
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Car Wash  "
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Convenience/Service Station  "
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Department Store "
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Fast Food Restaurant  "
              />
            </div>
            <span>3,346</span>
          </div>
        </div>
      </div>
    </div>
  );
}
function MultifamilyOpts(params) {
  return (
    <div className="right">
      <div className="column">
        <div className="row">
          <div className="subCat">
            <div className="subCat-L">
              <SubCatIcon /> <p>All Multifamily</p>
            </div>{" "}
            <span>14,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Cooperative "
              />
            </div>
            <span>146</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Dormitories/Group Quarters "
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Duplex"
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Frat/Sorority House"
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Mobile Home Park"
              />
            </div>
            <span>3,346</span>
          </div>
        </div>
      </div>

      <div className="column">
        <div className="row">
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Multi Family (General) "
              />
            </div>
            <span>146</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Nursing Home "
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Quadruplex "
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Triplex  "
              />
            </div>
            <span>3,346</span>
          </div>
        </div>
      </div>
    </div>
  );
}
function IndustrialOpts(params) {
  return (
    <div className="right">
      <div className="column">
        <div className="row">
          <div className="subCat">
            <div className="subCat-L">
              <SubCatIcon /> <p>All Industrial</p>
            </div>{" "}
            <span>14,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Aircraft Facility "
              />
            </div>
            <span>146</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Automotive Wrecking "
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Bulk Plant"
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Chemical"
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Dump Site"
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Facilities "
              />
            </div>
            <span>146</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Food Packing/Packing/Canning Plant "
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Quadruplex "
              />
            </div>
            <span>3,346</span>
          </div>
          <div className="option">
            <div className="option-L">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#d8dce5 !important",
                      "&.Mui-checked": {
                        color: "#8059c7 !important",
                      },
                      "& .MuiSvgIcon-root": {fontSize: 22},
                    }}
                  />
                }
                label="Triplex  "
              />
            </div>
            <span>3,346</span>
          </div>
        </div>
      </div>

      <div className="row"></div>
    </div>
  );
}
function Building(params) {
  return "Building";
}
function Sales(params) {
  return "Sales";
}
function Debt(params) {
  return "Location";
}
function Tenants(params) {
  return "Tenants";
}
function Ownership(params) {
  return "Ownership";
}
function Tax(params) {
  return "Tax";
}
function Comps(params) {
  return "Comps";
}
function Amenities(params) {
  return "Amenities";
}
function Investors(params) {
  return "Investors";
}
function Other(params) {
  return "Other";
}
function SubCatIcon() {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_di_13635_266218)">
        <rect
          width="16"
          height="16"
          rx="4"
          fill="#7D55C7"
          fill-opacity="0.71"
        />
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="3.5"
          stroke="#7D55C7"
          stroke-opacity="0.71"
        />
      </g>
      <circle
        cx="8"
        cy="8"
        r="2"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_di_13635_266218"
          x="-1"
          y="0"
          width="18"
          height="18"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood
            flood-opacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0745098 0 0 0 0 0.121569 0 0 0 0 0.0823529 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_13635_266218"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_13635_266218"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feComposite
            in2="hardAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_13635_266218"
          />
        </filter>
      </defs>
    </svg>
  );
}
