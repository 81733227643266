import React, { useState } from "react";
import "./Search.scss";

const Search = () => {
  const [searchValue, setSearchValue] = useState("");

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className="search-container">
      <form
        role="search"
        method="get"
        className="search-form"
        action=""
      >
        <label className="search-label">
          <input
            type="search"
            className="search-field"
            placeholder="Search …"
            value={searchValue}
            name="s"
            title="Search for:"
            onChange={handleInputChange}
          />
        </label>
        <input
          type="submit"
          className="search-submit"
          value="Search"
        />
      </form>
    </div>
  );
};

export default Search;
