import React from "react";
import "./CapReport.scss";
import SearchInput from "../../../components/reusableComponents/Search/Search";

const CapReport = () => {
  return (
    <div>
      <div className="Report-Container">
        <div style={{marginTop: "10px", marginBottom: "10px"}}>
          <h1>Equity Reporting</h1>
        </div>
        <div>
          <div className="All-report-container">
            <div className="All-report-data">
              <h1>All Reports</h1>
              <SearchInput />
            </div>
            <hr />
            <div className="Report-header-data">
              <h4>FOLDERS</h4>
              <h4>CANITALIZATION</h4>
              <h4>SECURITIES</h4>
              <h4>EQUITY PLAN</h4>
              <h4>TRANSECTION</h4>
              <h4>VESTING</h4>
              <h4>MODELING</h4>
              <h4>COMPLIANCE</h4>
            </div>
            <div className="report-box-container">
              <div className="box-border">
                <div className="box-container">
                  <div className="box-container-data">
                    <h1>Report Name</h1>
                    <h1>Description</h1>
                  </div>
                  <div className="box-container-data">
                    <h1>Folder</h1>
                  </div>
                </div>
              </div>
              <div className="box-border">
                <div className="box-container">
                  <div className="box-container-data">
                    <h1>Cap table</h1>
                    <h1>Capitalizatio table grouped by organization, individual stakeholder, or security type.</h1>
                  </div>
                  <div className="box-container-data">
                    <h1>Capitalization</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CapReport;
