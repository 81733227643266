import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import SummaryImg from "../../../assets/portfolio/images/edit-asset-pic.png";
import PhotosThumbnailImg from "../../../assets/portfolio/images/asset-photos-thumbnail.png";
import PhotosThumbnailTwoImg from "../../../assets/portfolio/images/asset-photos-thumbnail-2.png";
import HomeIcon from "../../../assets/portfolio/svgs/home.svg";
import DescriptionIcon from "../../../assets/portfolio/svgs/description.svg";
import PriceIcon from "../../../assets/portfolio/svgs/price.svg";
import WebsiteIcon from "../../../assets/portfolio/svgs/link.svg";
import ContactIcon from "../../../assets/portfolio/svgs/profile.svg";
import ContactAddIcon from "../../../assets/portfolio/svgs/prrofile-plus.svg";
import EmailIcon from "../../../assets/portfolio/svgs/email.svg";
import PhoneIcon from "../../../assets/portfolio/svgs/phone.svg";
import DeleteIcon from "../../../assets/portfolio/svgs/delete.svg";
import PhotosIcon from "../../../assets/portfolio/svgs/pictures.svg";
import RotateIcon from "../../../assets/portfolio/svgs/rotate.svg";
import PdfIcon from "../../../assets/portfolio/svgs/pdf.svg";
import FolderIcon from "../../../assets/portfolio/svgs/folder.svg";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";
import {useLocation} from "react-router-dom";
import plusIcon from "../../../assets/portfolio/svgs/plus.svg";
import MessageIcon from "../../../assets/portfolio/svgs/message.svg";
import RemoveIcon from "../../../assets/portfolio/svgs/delete-red.svg";
import TagIcon from "../../../assets/portfolio/svgs/tag.svg";
import {Checkbox, FormControlLabel} from "@mui/material";
import ManagerImg from "../../../assets/portfolio/images/manager-img.png";
import MemberImg from "../../../assets/portfolio/images/member-image.png";
import {useSelector} from "react-redux";
import {InputWithIcon, InputWithAddFieldFunc, UploadFile, TextInputWithLabel, DropdownWithCheckbox} from "../Inputs";

const Sidecard = () => {
  const [asset, setAsset] = useState(null);

  useEffect(() => {
    const storedAsset = localStorage.getItem("selectedAsset");
    if (storedAsset) {
      setAsset(JSON.parse(storedAsset));
    }
  }, []);

  return (
    <div className="sidecard-container">
      <div className="sidecard-header">
        <h2>Summary</h2>
      </div>
      <div className="sidecard-content">
        <img
          src={asset?.assetsImages[0]}
          alt=""
        />
        <h4>${asset?.assetValue}</h4>
        <div className="property-details">
          <h6>Name</h6>
          <p>{asset?.assetName}</p>
          <h6>Address</h6>
          <p>{asset?.assetsAddress}</p>
          <h6>Post Code</h6>
          <p>{asset?.ZipCode}</p>
          <h6>Country</h6>
          <p>{asset?.Country}</p>
          <h6>Phone No.</h6>
          <p>
            {asset?.phone}
            {"1234567890"}
          </p>
          <h6>E-mail</h6>
          <p>{asset?.addedByEmail}</p>
          <h4>LOT</h4>
          <h4>&nbsp;</h4>
          <h6>Asset type</h6>
          <p>
            {asset?.Student}
            {"Student"}
          </p>
          <h6>Lot Area</h6>
          <p>{asset?.unitDuplex}</p>
          <h6>Zoning</h6>
          <p>
            {asset?.advanceAssets?.property_details?.features?.zoning}
            {"0"}
          </p>
        </div>
      </div>
    </div>
  );
};

const Card = ({type, selectedItem}) => {
  const [asset, setAsset] = useState(null);

  useEffect(() => {
    const storedAsset = localStorage.getItem("selectedAsset");

    if (storedAsset) {
      setAsset(JSON.parse(storedAsset));
    }
  }, []);
  const renderIcon = () => {
    switch (type) {
      case "AssetName":
        return HomeIcon;
      case "Description":
        return DescriptionIcon;
      case "Price":
        return PriceIcon;
      case "Contact":
        return ContactIcon;
      case "Website":
        return WebsiteIcon;
      case "Photos":
        return PhotosIcon;
      case "Google":
        return WebsiteIcon;
      case "Marketing":
        return PdfIcon;
      case "PropertyDetails":
        return FolderIcon;
      case "Utilities":
        return FolderIcon;
      case "Manager":
        return ContactIcon;
      case "Tags":
        return TagIcon;
      default:
        return null;
    }
  };
  const renderHeading = () => {
    switch (type) {
      case "AssetName":
        return "Asset Name";
      case "Description":
        return "Description";
      case "Price":
        return "Price";
      case "Contact":
        return "Contact";
      case "Website":
        return "Website";
      case "Photos":
        return "Photos, Videos, 360° / VR tours";
      case "Google":
        return "Google Maps Link";
      case "Marketing":
        return "Marketing Brochure";
      case "PropertyDetails":
        return "Property Details";
      case "Utilities":
        return "Utilities Arrangement & Amenities";
      case "Manager":
        return "Manager and collaborators";
      case "Tags":
        return "Tags";
      default:
        return "";
    }
  };
  const [propertyTabIndex, setPropertyTabIndex] = useState(0);
  const propertyTabs = ["Info", "Plan", "Documents"];
  const [utilityTabIndex, setUtilityTabIndex] = useState(0);
  const utilityTabs = ["Utilities Arrangement", "Amenities"];
  const [editManager, setEditManager] = useState(true);

  // const memberList = [
  //   {
  //     name: "Dalle Garrett",
  //     department: "Marketing",
  //     budgeting: false,
  //     marketIntelligence: true,
  //     dealProposal: true,
  //     maintenance: false,
  //     inventory: true,
  //     disposition: false,
  //   },
  //   {
  //     name: "Louis Patrick",
  //     department: "Marketing",
  //     budgeting: true,
  //     marketIntelligence: false,
  //     dealProposal: true,
  //     maintenance: true,
  //     inventory: true,
  //     disposition: false,
  //   },
  //   {
  //     name: "Bob Jacobsen",
  //     department: "Marketing",
  //     budgeting: true,
  //     marketIntelligence: false,
  //     dealProposal: true,
  //     maintenance: true,
  //     inventory: true,
  //     disposition: false,
  //   },
  // ];

  const RenderpropertyTabContents = () => {
    switch (propertyTabIndex) {
      case 0:
        return <PropertyInfo />;
      case 1:
        return <PropertyPlan />;
      case 2:
        return <PropertyDocuments />;
      default:
        return <PropertyInfo />;
    }
  };

  const RenderUtilityTabContents = () => {
    switch (utilityTabIndex) {
      case 0:
        return <UtilitiesArrangement />;
      case 1:
        return <Amenities />;
      default:
        return <UtilitiesArrangement />;
    }
  };

  const RenderCardContent = () => {
    // const updateAsset = async (updatedAsset) => {
    //   try {
    //     const response = await fetch(`http://localhost:6001/api/portfolio/updateAsset/${asset.id}`, {
    //       method: 'PATCH',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify(updatedAsset),
    //     });

    //     if (!response.ok) {
    //       throw new Error('Network response was not ok');
    //     }

    //     const data = await response.json();
    //     setAsset(data);
    //   } catch (error) {
    // 
    //   }
    // };
    const [showAdditionalFields, setshowAdditionalFields] = useState(true);
    const {selectedProperty} = useSelector((state) => state.portfolio);
    const filteredAssetsData = selectedProperty?.filter((asset) => {
      return asset?.assetNames?.includes(selectedItem?.assetName);
    });

    switch (type) {
      case "AssetName":
        return <h2 className="asset-name">{asset?.assetName}</h2>;
      case "Description":
        return (
          <div className="description">
            <textarea
              value={asset?.assetDescription}
              placeholder="Value"
              rows="6"
              name="description"
            ></textarea>
          </div>
        );
      case "Price":
        return (
          <div className="price-input-container">
            <span className="currency-symbol">$</span>
            <input
              value={asset?.assetValue}
              type="text"
              placeholder="Enter price"
            />
          </div>
        );
      case "Contact":
        return (
          <div className="contacts-container">
            <h2>Primary Contact</h2>
            <InputWithIcon
              label={"NAME"}
              placeholder={"Tom Waits"}
              imageSrc={ContactIcon}
              value={asset?.addedBy}
            />
            <InputWithIcon
              label={"EMAL"}
              placeholder={"sales1@hotels.biz"}
              imageSrc={EmailIcon}
              value={asset?.addedByEmail}
            />
            <InputWithAddFieldFunc
              label={"PHONE"}
              placeholder={"+1 404-795-4989"}
              imageSrc={PhoneIcon}
              value={asset?.phone}
            />
            {showAdditionalFields && (
              <div>
                <div className="additional-contact-header">
                  <h2>Additional contact</h2>
                  <img
                    src={DeleteIcon}
                    alt=""
                    onClick={() => setshowAdditionalFields(false)}
                  />
                </div>
                <InputWithIcon
                  label={"NAME"}
                  placeholder={"Tom Waits"}
                  imageSrc={ContactIcon}
                />
                <InputWithIcon
                  label={"EMAIL"}
                  placeholder={"sales1@hotels.biz"}
                  imageSrc={EmailIcon}
                />
                <InputWithAddFieldFunc
                  label={"PHONE"}
                  placeholder={"+1 404-795-4989"}
                  imageSrc={PhoneIcon}
                />
              </div>
            )}
            <Link className="contact-button">
              <img
                src={ContactAddIcon}
                alt="Contact icon"
              />
              <h4>Add contact</h4>
            </Link>
          </div>
        );
      case "Website":
        return <h2 className="website-container">www.heritageattampa.com</h2>;
      case "Photos":
        return (
          <div className="photo-container">
            <div className="photo-card-grid">
              <div className="photos-card">
                <img
                  className="img"
                  src={asset?.assetsImages[0]}
                  alt=""
                />
                <div className="controls-wrapper">
                  <img
                    src={RotateIcon}
                    alt="Rotate Icon"
                  />
                  <img
                    src={DeleteIcon}
                    alt="Delete Icon"
                  />
                </div>
                <div className="media-description">
                  <label htmlFor="mediaDescription">Description</label>
                  <textarea
                    placeholder="Value"
                    rows="2"
                    name="mediaDescription"
                    id="mediaDescription"
                  ></textarea>
                </div>
              </div>
              <div className="photos-card">
                <img
                  className="img"
                  src={asset?.assetsImages[1]}
                  alt=""
                />
                <div className="controls-wrapper">
                  <img
                    src={RotateIcon}
                    alt="Rotate Icon"
                  />
                  <img
                    src={DeleteIcon}
                    alt="Delete Icon"
                  />
                </div>
                <div className="media-description">
                  <label htmlFor="mediaDescription">Description</label>
                  <textarea
                    placeholder="Value"
                    rows="2"
                    name="mediaDescription"
                    id="mediaDescription"
                  ></textarea>
                </div>
              </div>
              <div className="photos-card">
                <img
                  className="img"
                  src={asset?.assetsImages[2]}
                  alt=""
                />
                <div className="controls-wrapper">
                  <img
                    src={RotateIcon}
                    alt="Rotate Icon"
                  />
                  <img
                    src={DeleteIcon}
                    alt="Delete Icon"
                  />
                </div>
                <div className="media-description">
                  <label htmlFor="mediaDescription">Description</label>
                  <textarea
                    placeholder="Value"
                    rows="2"
                    name="mediaDescription"
                    id="mediaDescription"
                  ></textarea>
                </div>
              </div>
              <div className="photos-card">
                <img
                  className="img"
                  src={asset?.assetsImages[3]}
                  alt=""
                />
                <div className="controls-wrapper">
                  <img
                    src={RotateIcon}
                    alt="Rotate Icon"
                  />
                  <img
                    src={DeleteIcon}
                    alt="Delete Icon"
                  />
                </div>
                <div className="media-description">
                  <label htmlFor="mediaDescription">Description</label>
                  <textarea
                    placeholder="Value"
                    rows="2"
                    name="mediaDescription"
                    id="mediaDescription"
                  ></textarea>
                </div>
              </div>
            </div>
            <h2>ADD MULTIMEDIA</h2>
            <div className="upload-media">
              <UploadFile />
            </div>
            <InputWithIcon
              label={"LINK"}
              placeholder={"Value"}
              imageSrc={WebsiteIcon}
              bottomText={"Insert a link to your VR tour."}
            />
          </div>
        );
      case "Google":
        return (
          <div>
            <InputWithIcon
              label={"GOOGLE MAPS LINK"}
              placeholder={"Value"}
              imageSrc={WebsiteIcon}
              bottomText={"Insert a google maps link."}
            />
          </div>
        );
      case "Marketing":
        return (
          <div className="marketing-container">
            <UploadFile />
            <InputWithIcon
              label={"LINK"}
              placeholder={"Value"}
              imageSrc={WebsiteIcon}
              bottomText={"Insert a link to your Marketing Brochure."}
            />
          </div>
        );
      case "PropertyDetails":
        return (
          <div className="property-details-container">
            <div className="property-tabs">
              {propertyTabs.map((tab, index) => (
                <Link
                  key={index}
                  className={propertyTabIndex === index ? "active" : ""}
                  onClick={() => setPropertyTabIndex(index)}
                >
                  {tab}
                </Link>
              ))}
            </div>
            <div className="property-tab-content">{RenderpropertyTabContents()}</div>
          </div>
        );
      case "Utilities":
        return (
          <div className="utility-details-container">
            <div className="utility-tabs">
              {utilityTabs.map((tab, index) => (
                <Link
                  key={index}
                  className={utilityTabIndex === index ? "active" : ""}
                  onClick={() => setUtilityTabIndex(index)}
                >
                  {tab}
                </Link>
              ))}
            </div>
            <div className="utility-tab-content">{RenderUtilityTabContents()}</div>
          </div>
        );
      case "Manager":
        const options = ["Budgeting", "Market Intelligence", "Deal proposal management", "Maintenance & Utilities", "Inventory Management", "Disposition"];
        return (
          <div className="manager-container">
            <h2>{editManager ? "Asset Manager" : "Assign Manager"}</h2>
            {!editManager ? (
              <div className="manager-details">
                {filteredAssetsData.map((asset, index) => (
                  <div
                    key={index}
                    className="manager-profile"
                  >
                    <img
                      src={ManagerImg}
                      alt="Manager"
                    />
                    <div className="manager-name">
                      <h4>{asset?.userName}</h4>
                      <p>{asset?.managerRole}</p>
                    </div>
                  </div>
                ))}
                <Link
                  className="manager-edit-button"
                  onClick={() => setEditManager(true)}
                >
                  <h4>Add Utility</h4>
                </Link>
              </div>
            ) : (
              <div className="edit-manager">
                <div className="edit-input-wrapper">
                  <input
                    type="text"
                    name="assignManager"
                    id="assignManager"
                    placeholder="Name or email address"
                  />
                  <div className="btn-group">
                    <Link
                      className="edit-input-button"
                      style={{
                        backgroundImage: "linear-gradient(to top, #7858c0, #8164c1)",
                      }}
                      onClick={() => setEditManager(false)}
                    >
                      <h4 style={{color: "white"}}>Save</h4>
                    </Link>
                    <Link
                      className="edit-input-button"
                      style={{
                        backgroundImage: "linear-gradient(to top, #f7f8fa, #fefefe)",
                      }}
                      onClick={() => setEditManager(false)}
                    >
                      <h4>Cancel</h4>
                    </Link>{" "}
                  </div>
                </div>
                <p>There must be at least one manager on this page.</p>
              </div>
            )}
            <div className="line">&nbsp;</div>
            {/* <h2>COLLABORATORS ({assetsData?.length})</h2> */}
            <h2>ADD PEOPLE</h2>
            <div className="add-collaborator-wrapper">
              <input
                type="text"
                name="addCollaborator"
                id="addCollaborator"
                placeholder="Name or email address"
              />
              <DropdownWithCheckbox options={options} />
              <Link
                className="edit-input-button"
                style={{backgroundImage: "linear-gradient(to top, #7858c0, #8164c1)"}}
                onClick={() => setEditManager(false)}
              >
                <h4 style={{color: "white"}}>Save</h4>
              </Link>
            </div>
            <p className="add-collaborator-info">After people will be notified by email they have to confirm invitation.</p>
            <div className="member-permission-container">
              {filteredAssetsData.map((member, index) => {
                return (
                  <div className="member-permission-card">
                    <div className="grid-1 member-detail">
                      <img
                        src={MemberImg}
                        alt=""
                      />
                      <div className="member-name">
                        <h4>{member?.userName}</h4>
                        <p>Investor</p>
                      </div>
                    </div>
                    <div className="grid-2 member-chat">
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: "#d8dce5 !important",
                              "&.Mui-checked": {
                                color: "#8059c7 !important",
                              },
                              "& .MuiSvgIcon-root": {fontSize: 22},
                            }}
                          />
                        }
                        label="Can invite others"
                      />
                      <Link className="start-chat-button">
                        <img
                          src={MessageIcon}
                          alt="Contact icon"
                        />
                        <h4>Add Utility</h4>
                      </Link>
                    </div>
                    <div className="grid-3 remove-wrapper">
                      <img
                        src={RemoveIcon}
                        alt=""
                      />
                      <h6>Remove</h6>
                    </div>
                    <div className="grid-4 permission-options">
                      <div className="permission-module-header">
                        <SubCatIcon /> <p>All Modules</p>
                      </div>
                      <div className="permission-submodules">
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#d8dce5 !important",
                                "&.Mui-checked": {
                                  color: "#8059c7 !important",
                                },
                                "& .MuiSvgIcon-root": {fontSize: 22},
                              }}
                            />
                          }
                          label="Budgeting"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#d8dce5 !important",
                                "&.Mui-checked": {
                                  color: "#8059c7 !important",
                                },
                                "& .MuiSvgIcon-root": {fontSize: 22},
                              }}
                            />
                          }
                          label="Market Intelligence"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#d8dce5 !important",
                                "&.Mui-checked": {
                                  color: "#8059c7 !important",
                                },
                                "& .MuiSvgIcon-root": {fontSize: 22},
                              }}
                            />
                          }
                          label="Deal proposal management"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#d8dce5 !important",
                                "&.Mui-checked": {
                                  color: "#8059c7 !important",
                                },
                                "& .MuiSvgIcon-root": {fontSize: 22},
                              }}
                            />
                          }
                          label="Maintenance & Utilities"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#d8dce5 !important",
                                "&.Mui-checked": {
                                  color: "#8059c7 !important",
                                },
                                "& .MuiSvgIcon-root": {fontSize: 22},
                              }}
                            />
                          }
                          label="Inventory Management"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#d8dce5 !important",
                                "&.Mui-checked": {
                                  color: "#8059c7 !important",
                                },
                                "& .MuiSvgIcon-root": {fontSize: 22},
                              }}
                            />
                          }
                          label="Disposition"
                        />
                      </div>
                    </div>
                    <div className="grid-5">
                      <div className="line">&nbsp;</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      case "Tags":
        const tags = ["Della Garrett", "river side"];
        return (
          <div className="tags-container">
            <div className="assign-input-wrapper">
              <input
                type="text"
                name="assignManager"
                id="assignManager"
                placeholder="Start Typing"
              />

              <Link
                className="edit-input-button"
                style={{backgroundImage: "linear-gradient(to top, #7858c0, #8164c1)"}}
              >
                <h4 style={{color: "white"}}>Assign</h4>
              </Link>
            </div>
            <div className="tags-list">
              {tags.map((tag, index) => {
                return (
                  <div
                    key={index}
                    className="tag"
                  >
                    <h6>{tag}</h6>
                    <button>x</button>
                  </div>
                );
              })}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="EditAssetInfo-card-container">
      <div className="card-header">
        <img
          src={renderIcon()}
          alt="card-icon"
        />
        <h3 className="card-heading">{renderHeading()}</h3>
      </div>
      <div className="card-content">{RenderCardContent()}</div>
    </div>
  );
};

const PropertyInfo = () => {
  const filterOptions = ["Multifamily", "Multifamily"];
  return (
    <div className="property-info-container">
      <div className="info-row">
        <DropDown
          label="Lot Area"
          data={filterOptions}
          selectedData={"Select"}
          classDataName="dropdown"
          width="200px"
          margin="10px 0px"
        />
        <TextInputWithLabel
          label={"LOT AREA"}
          placeholder={"Value"}
          optionText={"SF"}
        />
        <TextInputWithLabel
          label={"ZONING"}
          placeholder={"Value"}
        />
      </div>
      <div className="line">&nbsp;</div>
      <div className="info-row">
        <TextInputWithLabel
          label={"TOTAL UNITS"}
          placeholder={"Value"}
        />
        <TextInputWithLabel
          label={"BUILDING AREA"}
          placeholder={"Value"}
          optionText={"SF"}
        />
        <TextInputWithLabel
          label={"YEAR BUILT"}
          placeholder={"Value"}
        />
      </div>
    </div>
  );
};

const PropertyPlan = () => {
  return <div className="property-plan-container">PropertyPlan</div>;
};

const PropertyDocuments = () => {
  return <div className="property-documents-container">PropertyDocuments</div>;
};

// const UtilitiesArrangement = () => {
//   const filterOptionsServicer = ["Pepco", "Resident"];
//   const filterOptionsPaid = ["Resident", "Resident"];
//   const filterOptionsType = ["Individual", "Resident"];
//   return (
//     <div className="utility-arrangement-container">
//       <div className="arrangement-table-header">
//         <h4>UTILITY</h4>
//         <h4>SERVICER</h4>
//         <h4>PAID BY</h4>
//         <h4>METER TYPE</h4>
//       </div>
//       <div className="arrangement-table-content">
//         <>
//           <p>Electricity</p>
//           <DropDown
//             data={filterOptionsServicer}
//             selectedData={"Select"}
//             classDataName="dropdown"
//             width="122px"
//             margin="10px 0px"
//           />
//           <DropDown
//             data={filterOptionsPaid}
//             selectedData={"Select"}
//             classDataName="dropdown"
//             width="122px"
//             margin="10px 0px"
//           />
//           <DropDown
//             data={filterOptionsType}
//             selectedData={"Select"}
//             classDataName="dropdown"
//             width="122px"
//             margin="10px 0px"
//           />
//           <img
//             src={DeleteIcon}
//             alt="Delete Icon"
//           />
//         </>
//         <>
//           <p>Gas (stove top)</p>
//           <DropDown
//             data={filterOptionsServicer}
//             selectedData={"Select"}
//             classDataName="dropdown"
//             width="122px"
//             margin="10px 0px"
//           />
//           <DropDown
//             data={filterOptionsPaid}
//             selectedData={"Select"}
//             classDataName="dropdown"
//             width="122px"
//             margin="10px 0px"
//           />
//           <DropDown
//             data={filterOptionsType}
//             selectedData={"Select"}
//             classDataName="dropdown"
//             width="122px"
//             margin="10px 0px"
//           />
//           <img
//             src={DeleteIcon}
//             alt="Delete Icon"
//           />
//         </>
//         <>
//           <p>Water/Sewer</p>
//           <DropDown
//             data={filterOptionsServicer}
//             selectedData={"Select"}
//             classDataName="dropdown"
//             width="122px"
//             margin="10px 0px"
//           />
//           <DropDown
//             data={filterOptionsPaid}
//             selectedData={"Select"}
//             classDataName="dropdown"
//             width="122px"
//             margin="10px 0px"
//           />
//           <DropDown
//             data={filterOptionsType}
//             selectedData={"Select"}
//             classDataName="dropdown"
//             width="122px"
//             margin="10px 0px"
//           />
//           <img
//             src={DeleteIcon}
//             alt="Delete Icon"
//           />
//         </>
//       </div>
//       <Link className="utility-button">
//         <img
//           src={plusIcon}
//           alt="Contact icon"
//         />
//         <h4>Add Utility</h4>
//       </Link>
//       <div className="line">&nbsp;</div>
//       <FormControlLabel
//         control={
//           <Checkbox
//             sx={{
//               color: "#d8dce5 !important",
//               "&.Mui-checked": {
//                 color: "#8059c7 !important",
//               },
//               "& .MuiSvgIcon-root": {fontSize: 22},
//             }}
//           />
//         }
//         label="I want my Utility Informtion to be uploaded into Asset Discovery"
//       />
//     </div>
//   );
// };

// Updated
const UtilitiesArrangement = () => {
  const [utilities, setUtilities] = useState([
    {name: "Electricity", show: true},
    {name: "Gas (stove top)", show: true},
    {name: "Water/Sewer", show: true},
  ]);

  const deleteUtility = (index) => {
    const updatedUtilities = [...utilities];
    updatedUtilities.splice(index, 1);
    setUtilities(updatedUtilities);
  };

  const filterOptionsServicer = ["Pepco", "Resident"];
  const filterOptionsPaid = ["Resident", "Resident"];
  const filterOptionsType = ["Individual", "Resident"];

  return (
    <div className="utility-arrangement-container">
      <div className="arrangement-table-header">
        <h4>UTILITY</h4>
        <h4>SERVICER</h4>
        <h4>PAID BY</h4>
        <h4>METER TYPE</h4>
      </div>
      <div className="EditAssetInfo-arrangement-table-content">
        {utilities.map(
          (utility, index) =>
            utility.show && (
              <div
                key={index}
                className="flex-EditAssetInfo"
              >
                <div className="EditAssetInfo-data">
                  <p>{utility.name}</p>
                  <DropDown
                    data={filterOptionsServicer}
                    selectedData={"Select"}
                    classDataName="dropdown"
                    width="122px"
                    margin="10px 0px"
                  />
                  <DropDown
                    data={filterOptionsPaid}
                    selectedData={"Select"}
                    classDataName="dropdown"
                    width="122px"
                    margin="10px 0px"
                  />
                  <DropDown
                    data={filterOptionsType}
                    selectedData={"Select"}
                    classDataName="dropdown"
                    width="122px"
                    margin="10px 0px"
                  />
                </div>
                <div>
                  <img
                    style={{cursor: "pointer"}}
                    onClick={() => deleteUtility(index)}
                    src={DeleteIcon}
                    alt="Delete Icon"
                  />
                </div>
              </div>
            ),
        )}
      </div>
      <Link className="utility-button">
        <img
          src={plusIcon}
          alt="Contact icon"
        />
        <h4>Add Utility</h4>
      </Link>
      <div className="line">&nbsp;</div>
      <FormControlLabel
        control={
          <Checkbox
            sx={{
              color: "#d8dce5 !important",
              "&.Mui-checked": {
                color: "#8059c7 !important",
              },
              "& .MuiSvgIcon-root": {fontSize: 22},
            }}
          />
        }
        label="I want my Utility Information to be uploaded into Asset Discovery"
      />
    </div>
  );
};

const Amenities = () => {
  return <div className="amenities-container">Amenities</div>;
};

function SubCatIcon() {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_di_13635_266218)">
        <rect
          width="16"
          height="16"
          rx="4"
          fill="#8265c2"
          fill-opacity="0.71"
        />
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="3.5"
          stroke="#8265c2"
          stroke-opacity="0.71"
        />
      </g>
      <circle
        cx="8"
        cy="8"
        r="2"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_di_13635_266218"
          x="-1"
          y="0"
          width="18"
          height="18"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood
            flood-opacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0745098 0 0 0 0 0.121569 0 0 0 0 0.0823529 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_13635_266218"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_13635_266218"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feComposite
            in2="hardAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_13635_266218"
          />
        </filter>
      </defs>
    </svg>
  );
}

export {Sidecard, Card, PropertyInfo, PropertyPlan, PropertyDocuments, UtilitiesArrangement, Amenities, SubCatIcon};
