import { createSlice } from '@reduxjs/toolkit';

const disposition = createSlice({
  name: 'disposition',
  initialState: {
    Disposition: [],
  },
  reducers: {
    setAssetIdList: (state, action) => {
      state.Disposition = action.payload;
    },
    addAsset: (state, action) => {
      state.Disposition.push(action.payload);
    },
    removeAsset: (state, action) => {
      state.Disposition = state.Disposition.filter(asset => asset.id !== action.payload);
    },
  },
});

export const { setAssetIdList, addAsset, removeAsset } = disposition.actions;
export default disposition.reducer;
