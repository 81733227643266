import React from "react";
import "./style.scss";
import profile from "../../../../../../../../../assets/svg/sponsorsProfile.svg";
import updatesImg1 from "../../../../../../../../../assets/InvestmentDashboard/updatesImg1.png";
import updatesImg2 from "../../../../../../../../../assets/InvestmentDashboard/updatesImg2.png";
import updatesImg3 from "../../../../../../../../../assets/InvestmentDashboard/updatesImg3.png";
import rightArrow from "../../../../../../../../../assets/svg/RightArrowBig.svg";
import dotgreen from "../../../../../../../../../assets/svg/dot-green.svg";

// const updates = [
//   {
//     name: "Gregory Ray",
//     update:
//       "Can I invest on behalf of my friend, I'll provide my docs and Investor Certificate, but he'll be a real Investor (100% his money)? If not can I borrow his money and then invest? Also, can I invest $48K, not $45K or $50?",
//     timeAgo: "2 days ago",
//     image: profile,
//   },
//   {
//     name: "Francisco Weber",
//     update: "Can I invest on behalf of my friend? I'll provide my docs and Investor Certificate.",
//     timeAgo: "1 month ago",
//     image: profile,
//   },
// ];

const UpdateSection = () => {
  return (
    <div className="updates-section">
      <div className="update">
        <div className="update-header">
          <img
            src={dotgreen}
            alt="dotgreen"
          />
          <img
            src={profile}
            alt={profile}
            className="avatar"
          />
        </div>

        <div className="update-main">
          <div className="update-details">
            <p className="update-details_title">
              Isabelle Neal <span>from your team</span>
            </p>

            <p>Added 3 new photos.</p>

            <div className="update-details_images">
              <img
                src={updatesImg1}
                alt="updatesImg1"
              />
              <img
                src={updatesImg2}
                alt="updatesImg2"
              />
              <img
                src={updatesImg3}
                alt="updatesImg3"
              />
            </div>
            <p>4 days ago, on August 15, 12:15am</p>
          </div>
        </div>
      </div>
      <div className="update">
        <div className="update-header">
          <img
            src={dotgreen}
            alt="dotgreen"
          />
          <img
            src={profile}
            alt={profile}
            className="avatar"
          />
        </div>

        <div className="update-main">
          <div className="update-details">
            <p className="update-details_title">
              Isabelle Neal <span>from your team</span>
            </p>

            <p>Added 3 new photos.</p>

            <div className="before_now_div">
              <div className="before_now_div_one">
                <span>Before</span>
                <p>Minimum investment</p>
                <h3>$15,500</h3>
              </div>
              <div>
                <img
                  src={rightArrow}
                  alt="rightArrow"
                />
              </div>

              <div className="before_now_div_one">
                <span>Now</span>
                <p>Minimum investment</p>
                <h3 className="now_amount">$15,500</h3>
              </div>
            </div>
            <p>8 days ago, on August 11, 10:15am</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateSection;
