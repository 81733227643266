import React, {useState} from "react";
import "./style.scss";
import profilewithbage from "../../../../../../../../../assets/svg/profilewithbage.svg";

const comments = [
  {
    name: "Gregory Ray",
    comment:
      "Can I invest on behalf of my friend, I'll provide my docs and Investor Certificate, but he'll be a real Investor (100% his money)? If not can I borrow his money and then invest? Also, can I invest $48K, not $45K or $50?",
    timeAgo: "2 days ago",
    image: profilewithbage,
  },
  {
    name: "Francisco Weber",
    comment: "Can I invest on behalf of my friend? I'll provide my docs and Investor Certificate.",
    timeAgo: "1 month ago",
    image: profilewithbage,
  },
];

const CommentsSection = () => {
  const [activeCommentIndex, setActiveCommentIndex] = useState(null);

  const toggleModal = (index) => {
    setActiveCommentIndex(index === activeCommentIndex ? null : index);
  };

  const closeModal = () => {
    setActiveCommentIndex(null);
  };
  return (
    <div className="comments-section">
      {comments.map((comment, index) => (
        <div
          key={index}
          className="comment"
        >
          <div className="comment-header">
            <img
              src={comment.image}
              alt={comment.name}
              className="avatar"
            />
          </div>

          <div className="comment-main">
            <div className="comment-details">
              <h3 className="comment-name">{comment.name}</h3> commented:
            </div>
            <div className="comment-right">
              <div className="comment-body">
                <p>{comment.comment}</p>
              </div>
            </div>
            <div className="comment-footer">
              <p className="comment-time">{comment.timeAgo}</p>

              <a
                href="#reply"
                className="reply-link"
                onClick={() => toggleModal(index)}
              >
                Reply →
              </a>
            </div>
            {activeCommentIndex === index && (
              <ReplyModal
                comment={comment}
                closeModal={closeModal}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CommentsSection;

const ReplyModal = ({comment, closeModal}) => {
  const [answer, setAnswer] = useState("");

  const handleAnswerChange = (e) => {
    setAnswer(e.target.value);
  };

  const handleOverlayClick = (e) => {
    if (e.target.className === "reply-modal_overlay") {
      closeModal();
    }
  };

  return (
    <div className="reply-modal">
      <div className="replyMain">
        <div className="replyMai_top">
          <img
            src={profilewithbage}
            alt="profilewithbage"
          />
        </div>
        <div className="replyMai_bottom">
          <p>Your Answer</p>
          <textarea
            name="text"
            id="textArea"
            cols="30"
            rows="10"
          ></textarea>
          <button>Reply</button>
        </div>
      </div>
    </div>
  );
};
