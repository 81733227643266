import React, {useEffect, useState, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTable} from "react-table";
import "./TableUnitOver.scss";
import {fetchUnitTurnoverData} from "../../store/reducers/Graphs/UnitTurnover";
import {PrimaryButton} from "../../components/reusableComponents/Buttons/Buttons";
import {Pagination} from "antd";

const TableUnitOver = () => {
  const dispatch = useDispatch();
  const {data: sliceData, totalPages} = useSelector((state) => state.UnitTurnoverSlice.data || {});
  const {selectedProperty} = useSelector((state) => state.portfolio);
  const property_code = selectedProperty?.Property_Code;
  const idToken = useSelector((state) => state.auth.idToken);
  const [current, setCurrent] = useState(1);

  const onChange = (page) => {
  
    setCurrent(page);
    setCurrentPage(page);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    if (selectedProperty && idToken) {
      dispatch(
        fetchUnitTurnoverData({
          property_code,
          page: currentPage,
          limit: pageSize,
        }),
      );
    }
  }, [dispatch, selectedProperty, idToken, currentPage, property_code, pageSize]);
  const currentData = sliceData || [];
  const columns = useMemo(
    () => [
      {Header: "UNIT", accessor: "unit"},
      {Header: "UNIT TYPE", accessor: "unitType"},
      {Header: "DATE OCCUPIED", accessor: "dateOccupied"},
      {Header: "DATE VACANT", accessor: "dateVacant"},
      {Header: "DATE READY", accessor: "dateReady"},
      {Header: "VACANT DAYS", accessor: "vacantDays"},
      {Header: "DAYS TO MAKE READY", accessor: "daysToMakeReady"},
      {Header: "DAYS READY TO OCCUPIED", accessor: "daysReadyToOccupied"},
    ],
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
    columns,
    data: currentData,
  });

  return (
    <div className="unitTurnOver_table">
      <div className="header_bar">
        <div className="left">
          <h2>Recent Move Ins</h2>
        </div>
      </div>
      <div className="container">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination-TableUnitOver">
        <Pagination
          current={current}
          onChange={onChange}
          total={totalPages * pageSize}
        />
      </div>
    </div>
  );
};

export default TableUnitOver;
