import React from "react";
import "./Boardmeetings.scss";
import {PrimaryButton, PrimaryButtonSvgFirst, PrimaryButtonNameFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import pen from "../../../assets/Manage-syndicate/Svg/pen.svg";
import people from "../../../assets/Manage-syndicate/Svg/people.svg";
import fileupload from "../../../assets/Manage-syndicate/Svg/fileupload.svg";
import clock from "../../../assets/Manage-syndicate/Svg/clock.svg";
import Teammember from "../../../assets/Manage-syndicate/Svg/Teammember.svg";
import meeting from "../../../assets/Manage-syndicate/Svg/meeting.svg";
import copy from "../../../assets/Manage-syndicate/Svg/Copy.svg";
import add from "../../../assets/Manage-syndicate/Svg/circle-add.svg";
import person1 from "../../../assets/Manage-syndicate/image/Person1.png";
import person2 from "../../../assets/Manage-syndicate/image/Person2.png";
import calender from "../../../assets/Manage-syndicate/image/Frame 48097964.png";
import managepen from "../../../assets/Manage-syndicate/Svg/manage-pen.svg";
import mind from "../../../assets/Manage-syndicate/Svg/mind.svg";
import medal from "../../../assets/Manage-syndicate/Svg/medal.svg";

const Boardmeetings = () => {
  return (
    <div>
      <div className="Boardmeetings-Header">
        <div className="background-color">
          <div className="Container-Header">
            <div className="Boardmeetings-header-data">
              <div className="Container-Header-data">
                <h5>Meeting Published</h5>
                <PrimaryButtonSvgFirst
                  name="View Presentation"
                  classNames="Boardmeetings-pen"
                  padding="10px"
                  svgHeight="20px"
                  svgWidth="20px"
                />
              </div>
              <div className="Container-Header-button">
                <PrimaryButtonSvgFirst
                  name="Loerum Ipsum"
                  classNames="Boardmeetings-pen"
                  svgPath={pen}
                  padding="10px"
                  svgHeight="20px"
                  svgWidth="20px"
                />
                <PrimaryButtonSvgFirst
                  name="Loerum Ipsum"
                  classNames="Boardmeetings-pen"
                  svgPath={people}
                  padding="10px"
                  svgHeight="20px"
                  svgWidth="20px"
                />
                <PrimaryButtonSvgFirst
                  name="Loerum Ipsum"
                  classNames="Boardmeetings-pen"
                  svgPath={fileupload}
                  padding="10px"
                  svgHeight="20px"
                  svgWidth="20px"
                />
              </div>
            </div>
            {/* meeting details */}
            <div className="Container-Header-meeting">
              <div className="Boardmeetings-data">
                <div className="backcolor-data">
                  <div>
                    <div className="Boardmeetings-Meeting-data">
                      <h5>Meeting Details</h5>
                      <div className="Container-Boardmeetings-time">
                        <img
                          src={clock}
                          alt="image"
                        />
                        <h1>Time</h1>
                        <div className="meeting-time">
                          <p>(GMT-06:00) Central Time - Chicago</p>
                          <p>(GMT-10:00) Tahiti Time</p>
                        </div>
                      </div>
                    </div>
                    <div className="Boardmeetings-Team">
                      <div className="Container-Boardmeetings-Team-member">
                        <img
                          src={Teammember}
                          alt="img"
                        />
                        <p>Team Member</p>
                      </div>
                      <div className="Container-Boardmeetings-Team-member">
                        <img
                          src={person1}
                          alt="img"
                        />
                        <p>Daniel Padilla </p>
                      </div>
                      <div className="Container-Boardmeetings-Team-member">
                        <img
                          src={person2}
                          alt="img"
                        />
                        <p>Daniel Roy </p>
                      </div>
                      <div className="Container-Boardmeetings-Team-member">
                        <PrimaryButtonNameFirst
                          name="Add"
                          classNames="Boardmeetings-Add"
                          svgPath={add}
                          padding="10px"
                          svgHeight="20px"
                          svgWidth="20px"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="Boardmeetings-Meeting">
                        <img
                          src={meeting}
                          alt="image"
                        />
                        <p>Meeting Link</p>
                      </div>
                    </div>
                    <div className="Boardmeetings-flex">
                      <input
                        type="text"
                        placeholder="sfdgfgjuiookj,j,hjghfgdgfmh,k7"
                        name=""
                        id=""
                      />
                      <PrimaryButtonSvgFirst
                        name="copy link"
                        classNames="Boardmeetings-copylink"
                        svgPath={copy}
                        padding="10px"
                        svgHeight="20px"
                        svgWidth="20px"
                      />
                    </div>
                  </div>
                  {/* <div className="calander-image">
                  // <img
                  // className="calander"
                  //   src={calender}
                  //   alt="image"
                  // />
                  // </div> */}
                  <div>{/* <DateCalendar  onChange={(newValue) => setValue(newValue)} /> */}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* second */}
        <div className="Conatiner-Boardmeetings-box">
          <div className="Conatiner-Boardmeetings-Better">
            <h1>Better board meeting on Lilypads</h1>
            <hr />
            <p>Run organized meeting with agendes and minutes, and ahere document on our encrypted platform.</p>
            <div className="Conatiner-Boardmeetings-data-button">
              <PrimaryButtonSvgFirst
                name="Upgrade"
                classNames="Boardmeetings-Upgrade"
                padding="10px"
                svgHeight="20px"
                svgWidth="20px"
              />
              <PrimaryButtonSvgFirst
                name="See Plan"
                classNames="Boardmeetings-Plan"
                padding="10px"
                svgHeight="20px"
                svgWidth="20px"
              />
            </div>
          </div>
        </div>
      </div>
      {/* last */}
      <div>
        <div className="Boardmeetings-rules-container">
          <div className="Boardmeetings-rules-container-second">
            <div className="Boardmeetings-rules-container-secondbox">
              <div className="Taxrules-image-footer">
                <img
                  src={managepen}
                  alt="image"
                />
              </div>
              <h1>Generate all forms 3921 documents </h1>
              <p>Find support articles on equity withholding taxes and a more detailed explanation of tax- related features.</p>
            </div>
          </div>
          <div className="Boardmeetings-rules-container-second">
            <div className="Boardmeetings-rules-container-secondbox">
              <div className="Taxrules-image-footer">
                <img
                  src={mind}
                  alt="image"
                />
              </div>
              <h1>Generate all forms 3921 documents </h1>
              <p>Find support articles on equity withholding taxes and a more detailed explanation of tax- related features.</p>
            </div>
          </div>
          <div className="Boardmeetings-rules-container-second">
            <div className="Boardmeetings-rules-container-secondbox">
              <div className="Taxrules-image-footer">
                <img
                  src={medal}
                  alt="image"
                />
              </div>
              <h1>Generate all forms 3921 documents </h1>
              <p>Find support articles on equity withholding taxes and a more detailed explanation of tax- related features.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boardmeetings;
