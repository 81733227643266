import React from "react";
import "./Communication.scss";
import userAvtar from "../../assets/svg/avtar_men.svg";
import more from "../../assets/svg/three_dot.svg";
import gallaryIcon from '../../assets/svg/gallary_icon.svg';
import mediaIcon from '../../assets/svg/media_icon.svg';
import emojiIcon from '../../assets/svg/emoji_icon.svg';

const Communication = () => {
  return (
    <div className="communication_parent_container">
      <div className="communication_container">
        <div className="user_message">
          <div className="info">
            <img
              src={userAvtar}
              alt=""
            />
            <p>Francisco Gibbs</p>
            <span>08:52 AM</span>
          </div>
          <div className="msg_text_parent">
            <div className="msg_text">
              <p>Real Estate IQ video David Caballero is all set to give you SOME GOOD NEWS about Dallas Fort-Worth, Texas! See you on August 05, 2020 at 7:30 PM Central Time! </p>
            </div>
            <img
              src={more}
              alt=""
            />
          </div>
        </div>
        <div className="user_message">
          <div className="info">
            <img
              src={userAvtar}
              alt=""
            />
            <p>You</p>
            <span>Typing...</span>
          </div>
        </div>
      </div>
      <div className="input_box">
        
          <input type="text" placeholder="Type a message…" />
       <div className="more_actions">
        <img src={gallaryIcon} alt="" />
        <img src={mediaIcon} alt="" />
        <img src={emojiIcon} alt="" />
       </div>
      </div>
    </div>
  );
};

export default Communication;
