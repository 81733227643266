import React from "react";
import {PrimaryButton} from "../../../components/reusableComponents/Buttons/Buttons";
import "./Investorupdates.scss";
import Table from "../../../components/reusableComponents/Table/ReactTable";
import SearchInput from "../../../components/reusableComponents/Search/Search";
import files from "../../../assets/Manage-syndicate/Svg/files.svg";
import refresh from "../../../assets/Manage-syndicate/Svg/refresh.svg";
import rocket from "../../../assets/Manage-syndicate/Svg/rocket.svg";

const Investorupdates = () => {
  const headers = [];
  const tableData = [
    {Name: "409a Valuation", Age: "04/16/2022  12/ 21/2022", Location: "04/16/2022  12/ 21/2022", Occupation: "5/8 approval signed"},
    {Name: "409a Valuation", Age: "04/16/2022  12/ 21/2022", Location: "04/16/2022  12/ 21/2022", Occupation: "5/8 approval signed"},
    {Name: "409a Valuation", Age: "04/16/2022  12/ 21/2022", Location: "04/16/2022  12/ 21/2022", Occupation: "5/8 approval signed"},
    {Name: "409a Valuation", Age: "04/16/2022  12/ 21/2022", Location: "04/16/2022  12/ 21/2022", Occupation: "5/8 approval signed"},
    {Name: "409a Valuation", Age: "04/16/2022  12/ 21/2022", Location: "04/16/2022  12/ 21/2022", Occupation: "5/8 approval signed"},
    {Name: "409a Valuation", Age: "04/16/2022  12/ 21/2022", Location: "04/16/2022  12/ 21/2022", Occupation: "5/8 approval signed"},
  ];
  return (
    <div>
      <div>
        <div className="Boardconsents-Header">
          <div className="background-color">
            <div className="Boardconsents-Container-Header">
              <div className="Boardconsents-Container-Header-data">
                <PrimaryButton
                  name="GENERAL"
                  classNames="Boardconsents-GENERAL"
                  padding="10px"
                  svgHeight="20px"
                  svgWidth="20px"
                />
                <PrimaryButton
                  name="USER AND PERMISSIONS"
                  classNames="Boardconsents-USER"
                  svgPath="/path/to/svg/image.svg"
                  padding="10px"
                  svgHeight="20px"
                  svgWidth="20px"
                />
              </div>
              <div className="Boardconsents-text">
                <SearchInput />
                <PrimaryButton
                  name="Draft Shares"
                  classNames="Report-see-plan"
                  svgPath="/path/to/svg/image.svg"
                  padding="10px"
                  svgHeight="20px"
                  svgWidth="20px"
                />
              </div>
              <div className="Boardconsents-table-header">
                <p>NAME</p>
                <p>CREATION DATE</p>
                <p>APPLICATION DATE</p>
                <p>__</p>
              </div>
              <div>
                <Table
                  headers={headers}
                  tabledata={tableData}
                />
              </div>
            </div>
          </div>
          <div className="Conatiner-Boardconsents-box">
            <div className="Conatiner-Boardconsents-data">
              <h1>Keep investors up to date</h1>
              <hr />
              <p>Send update to your investors and track who’s seen them.</p>
              <div className="Conatiner-Boardconsents-data-button">
                <PrimaryButton
                  name="Upgrade"
                  classNames="Report-see-plan"
                  svgPath="/path/to/svg/image.svg"
                  padding="10px"
                  svgHeight="20px"
                  svgWidth="20px"
                />
                <PrimaryButton
                  name="Schedule a call"
                  classNames="Investorupdates-Schedule"
                  svgHeight="20px"
                  svgWidth="20px"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="Boardconsents-rules-container">
            <div className="Boardmeetingsrm-rules-container-data">
              <div className="Boardconsents-rules-container-second">
                <div className="Boardconsents-rules-container-secondbox">
                  <div className="Taxrules-image-footer">
                    <img
                      src={rocket}
                      alt="image"
                    />
                  </div>
                  <h1>Faster option grants</h1>
                  <span>Draft option grants and send them to your board in a few clicks, no email needed.</span>
                </div>
              </div>
            </div>
            <div className="Boardconsents-rules-container-second">
              <div className="Boardconsents-rules-container-secondbox">
                <div className="Taxrules-image-footer">
                  <img
                    src={rocket}
                    alt="image"
                  />
                </div>
                <h1>Faster option grants</h1>
                <span>Draft option grants and send them to your board in a few clicks, no email needed.</span>
              </div>
            </div>
            <div className="Boardconsents-rules-container-thid">
              <div className="Boardconsents-rules-container-thiddata">
                <div className="Taxrules-image-footer">
                  <img
                    src={refresh}
                    alt="image"
                  />
                </div>
                <h1>Automated cap table updates</h1>
                <span>When option grants are approved and issue cap table will automatically reflect the changes</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Investorupdates;
