import React from "react";
import ReactECharts from "echarts-for-react";
import leftIcon from "../../assets/dealsroom/svg/ic_chevron_left.svg";
import rightIcon from "../../assets/dealsroom/svg/ic_chevron_right.svg";

const BudgetingBarChart = ({data}) => {
  const barGraphdata = [
    {
      name: "Budgeting",
      type: "bar",
      barWidth: "60%",
      data: [
        {value: 300, itemStyle: {color: "#4285F4"}},
        {value: 200, itemStyle: {color: "#34A853"}},
        {value: 250, itemStyle: {color: "#FBBC05"}},
        {value: 100, itemStyle: {color: "#EA4335"}},
      ],
    },
  ];

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: ["Discovery", "Due Diligence", "Pre Closing", "Post Closing"],
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          color: "#555",
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          formatter: "${value}K",
          color: "#555",
        },
        splitLine: {
          lineStyle: {
            color: "#EDEDED",
          },
        },
      },
    ],
    series: barGraphdata,
  };

  return (
    <div className="budget_chart_container">
      <div className="barchart_nav">
        <h2>Budgeting</h2>
        <div className="cntrl_btns">
          <button>
            <img
              src={leftIcon}
              alt="leftIcon"
            />
          </button>
          <button>
            <img
              src={rightIcon}
              alt="rightIcon"
            />
          </button>
        </div>
      </div>
      <ReactECharts
        option={option}
        style={{height: "400px", width: "100%"}}
      />
    </div>
  );
};

export default BudgetingBarChart;
