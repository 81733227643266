import React from "react";
import {PrimaryButton, PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import "./Reviewcap.scss";
import cross from "../../../assets/Manage-syndicate/Svg/Cross.svg";
import light from "../../../assets/Manage-syndicate/Svg/light.svg";
import error from "../../../assets/Manage-syndicate/Svg/error.svg";

const Reviewcap = () => {
  return (
    <div>
      <div style={{marginTop: "10px", marginBottom: "10px"}}>
        <h1>Account Health Checks</h1>
      </div>
      <div className="Reviewcap-container">
        <div className="Reviewcap-conatiner">
          <div className="Reviewcap-cap">
            <div className="Reviewcap-cap-data">
              <h1>Manage Tags</h1>
              <PrimaryButton
                name="Export"
                classNames="Report-see-plan"
                padding="10px"
                svgHeight="20px"
                svgWidth="20px"
              />
            </div>
            <div>
              <hr />
            </div>
            <div className="Reviewcap-cap-data-button">
              <PrimaryButtonSvgFirst
                name="Folders"
                classNames="Cross-button"
                svgPath={cross}
                padding="10px"
                svgHeight="20px"
                svgWidth="20px"
              />
              <PrimaryButtonSvgFirst
                name="canitalization"
                classNames="Cross-button"
                svgPath={cross}
                padding="10px"
                svgHeight="20px"
                svgWidth="20px"
              />
              <PrimaryButtonSvgFirst
                name="equity plan"
                classNames="Cross-button"
                svgPath={cross}
                padding="10px"
                svgHeight="20px"
                svgWidth="20px"
              />
              <PrimaryButtonSvgFirst
                name="transection"
                classNames="Cross-button"
                svgPath={cross}
                padding="10px"
                svgHeight="20px"
                svgWidth="20px"
              />
              <PrimaryButtonSvgFirst
                name="Export"
                classNames="Cross-button"
                svgPath={cross}
                padding="10px"
                svgHeight="20px"
                svgWidth="20px"
              />
            </div>
            <div className="required-box">
              <div className="required-data">
                <h1>Required</h1>
                <h1>recommended</h1>
                <h1>Discard</h1>
                <h1>{""}</h1>
              </div>
            </div>
          </div>
          <div className="Refresh-Result">
            <div className="Refresh-Result-data">
              <p>Account Health Checks help you identity, understand, and fix data in Carta. All checks are evaluated nightly and checks are added regularly</p>
              <PrimaryButton
                name="Refresh Result"
                classNames="Refresh-Result-button"
                svgPath="/path/to/svg/image.svg"
                padding="10px"
                svgHeight="20px"
                svgWidth="20px"
              />
            </div>
          </div>
        </div>
        <div className="Reviewcap-container-data">
          <div>
            <div className="reviewCap-container">
              <div className="reviewCap-container-data">
                <p>A curated directory for you with special offers on products to help you grow your company</p>
                <div className="background-color-image">
                  <img
                    src={light}
                    alt="image"
                  />
                </div>
              </div>
              <div className="reviewCap-container-data-link">
                <a href="">See All Offers</a>
              </div>
            </div>
          </div>
          <div className="Brek-container">
            <div className="Brek-container-data">
              <h1>Brek</h1>
              <p>Get a business cash accounts, credit card, and accounting all in one place to keep you in one control of your growth.</p>
              <p>As a Lilypads customer, you get 80,000 points ($800 value) after spending $1,000.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="box-data">
        <div className="box-data-container-maindiv">
          <div className="Error-Container">
            <div className="box-data-container-image">
              <img
                src={error}
                alt="image"
              />
            </div>
            <p>No Draft Found</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviewcap;
