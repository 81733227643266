export let dataforutilities = [
    {
        "UTILITY":"Water/Sewer",
        "SERVICER":"Pepco",
        "PAID_BY":"Resident",
        "METER_TYPE":"Individual"
    },
    {
        "UTILITY":"Water/Sewer",
        "SERVICER":"Pepco",
        "PAID_BY":"Resident",
        "METER_TYPE":"Individual"
    },
    {
        "UTILITY":"Water/Sewer",
        "SERVICER":"Pepco",
        "PAID_BY":"Resident",
        "METER_TYPE":"Individual"
    },
    {
        "UTILITY":"Water/Sewer",
        "SERVICER":"Pepco",
        "PAID_BY":"Resident",
        "METER_TYPE":"Individual"
    },
    {
        "UTILITY":"Water/Sewer",
        "SERVICER":"Pepco",
        "PAID_BY":"Resident",
        "METER_TYPE":"Individual"
    },
]