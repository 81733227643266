import {PrimaryButton} from "../../../components/reusableComponents/Buttons/Buttons";
import {InputBox} from "../../../components/reusableComponents/FormFields/FormFields";
import {Link} from "react-router-dom";
import lilypandsLogo from "../../../assets/svg/lilipadsLogomain.svg";
// import singinRight from "../../assets/svg/signInRight.png";
import PRgif1 from "../../../assets/svg/PRgif1.gif";
import PRsvg2 from "../../../assets/svg/PRsvg2.svg";

export function FifthSection({setSliderState}) {
  return (
    <div className="prFifthSection">
      <div className="signupCon-L">
        <div className="signupFirstSection signupsection">
          <div className="top_header">
            <img
              src={lilypandsLogo}
              alt=""
            />

            <div className="text_wrapper">
              <h2>Two factor verification</h2>
              <p>Please check your inbox for a link with a secret code to acces the system.</p>
            </div>
          </div>
          <p>Resend a link</p>

          <InputBox
            label="E-mail address"
            type="email"
          />
          <br />
          <PrimaryButton
            classNames=" noMargin "
            name="Send a link"
            // onclick={() => {
            //   setSliderState("four");
            // }}
          />
        </div>
      </div>
      <div className="signupCon-R ">
        <div className="container flex_reverse ">
          <div className="passwordRecovery_svg_wrapper">
            <img
              src={PRsvg2}
              alt=""
            />
          </div>
          <div className="color_stripes">
            <div className="stripe1"></div>
            <div className="stripe2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
