import React from "react";
import "./Records.scss";
import search_icon from "../../assets/svg/lineBreak_searchIcon.svg";
import export_btn from "../../assets/svg/export_btn.svg";
import menSvg from "../../assets/svg/man_profile_pic.svg";
import leftArrow from '../../assets/svg/paginationLeftIcon.svg';
import rightArrow from '../../assets/svg/paginationRightIcon.svg';

const Recordsss = () => {
  const recordsData = [
    {
      file_name: "Agreement.pdf",
      shares_ACQ: "+10",
      total_paid: "300",
      running_balance: "300",
      transition_type: "300",
      share_CERT_number: "300",
      updatedBy: menSvg,
    },
    {
      file_name: "Agreement.pdf",
      shares_ACQ: "+10",
      total_paid: "300",
      running_balance: "300",
      transition_type: "300",
      share_CERT_number: "300",
      updatedBy: menSvg,
    },
    {
      file_name: "Agreement.pdf",
      shares_ACQ: "+10",
      total_paid: "300",
      running_balance: "300",
      transition_type: "300",
      share_CERT_number: "300",
      updatedBy: menSvg,
    },
    {
      file_name: "Agreement.pdf",
      shares_ACQ: "+10",
      total_paid: "300",
      running_balance: "300",
      transition_type: "300",
      share_CERT_number: "300",
      updatedBy: menSvg,
    },
    {
      file_name: "Agreement.pdf",
      shares_ACQ: "+10",
      total_paid: "300",
      running_balance: "300",
      transition_type: "300",
      share_CERT_number: "300",
      updatedBy: menSvg,
    },
  ];
  return (
<div className="record_container">
      <div className="heading">
        <h1>Shareholder Record</h1>
        <div>
          <div className="search_export">
            <div className="search">
              <input
                type="text"
                placeholder="Search"
              />
              <img
                src={search_icon}
                alt=""
              />
            </div>
            <img
              src={export_btn}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="records_details">
        <div className="records_header">
          <p style={{width: "250px"}}>#</p>
          <p>Number of shares acq/transferred</p>
          <p>Total Paid/ consideration</p>
          <p>Running balance</p>
          <p>Transaction type</p>
          <p>Share Cert. Number</p>
          <p>Updated by</p>
        </div>
        <div>
         {
           recordsData?.map((item,index)=> {
           return(
            <div className="reocord_info" key={index}>
           <div style={{display:'flex',alignItems:'center',gap:'30px'}}>
           <p>{index+1} </p>
            <p>{item?.file_name}</p>
           </div>
            <p>{item?.shares_ACQ}</p>
            <p>{item?.total_paid}</p>
            <p>{item?.running_balance}</p>
            <p>{item?.transition_type}</p>
            <p>{item?.share_CERT_number}</p>
            <img src={item?.updatedBy} alt="" />
             </div>
           )
          })
         }
        </div>
        <div className="pagination">
         <div className="paginate_buttons">
         <button className="left_button">
            <img src={leftArrow} alt="" />
          </button>
          <p className="pagination_value">
             2 of 10
          </p>
          <button className="right_button">
            <img src={rightArrow} alt="" />
          </button>
         </div>
        </div>
      </div>
    </div>
  )
}

export default Recordsss