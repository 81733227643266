import React from 'react'
import './compareassets.scss'
import bulb from '../../assets/svg/bulb-light.svg'
import save from '../../assets/svg/save.svg'
import download from '../../assets/svg/download-white.svg'
import upload from '../../assets/svg/upload-white.svg'
import file from '../../assets/svg/file-arrow.svg'
import cloud from '../../assets/svg/cloud-arrow.svg'
import chart1 from '../../assets/Image/donut-chart-blue.png'

function CompareAssets() {
    return (
        <div className='compare_assets'>
            <div className='compare_assets_header'>
                <h2>Compare Assets</h2>
                <div>
                    <img src={bulb} alt='' />
                    <button className='btn_save'>
                        <img src={save} alt='' />
                        Save Assets
                    </button>
                    <button className='btn_download'>
                        <img src={download} alt='' />
                        Download
                    </button>
                </div>
            </div>

            <div className='compare_table_box'>
                <div className='table_header'>
                    <h3>Lilypads Rent Comps Summary Table</h3>
                    <div className='table_btn'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.50033 17.5013C7.50033 17.9596 7.87533 18.3346 8.33366 18.3346H11.667C12.1253 18.3346 12.5003 17.9596 12.5003 17.5013V16.668H7.50033V17.5013ZM10.0003 1.66797C6.78366 1.66797 4.16699 4.28464 4.16699 7.5013C4.16699 9.48464 5.15866 11.2263 6.66699 12.2846V14.168C6.66699 14.6263 7.04199 15.0013 7.50033 15.0013H12.5003C12.9587 15.0013 13.3337 14.6263 13.3337 14.168V12.2846C14.842 11.2263 15.8337 9.48464 15.8337 7.5013C15.8337 4.28464 13.217 1.66797 10.0003 1.66797ZM12.3753 10.918L11.667 11.418V13.3346H8.33366V11.418L7.62533 10.918C6.50033 10.1346 5.83366 8.85964 5.83366 7.5013C5.83366 5.2013 7.70033 3.33464 10.0003 3.33464C12.3003 3.33464 14.167 5.2013 14.167 7.5013C14.167 8.85964 13.5003 10.1346 12.3753 10.918Z" fill="#9EA0A5" />
                        </svg>   Insights
                    </div>

                </div>
                <div className='infobox_wrapper'>
                    <h3>Average values</h3>
                    <div className='infobox_row'>
                        <div className='infobox'>
                            <p className='title'>Size</p>
                            <h3 className='value'>48</h3>
                            <span>Units</span>
                        </div>
                        <div className='infobox'>
                            <p className='title'>Size</p>
                            <h3 className='value'>48</h3>
                            <span>Units</span>
                        </div>

                        <div className='infobox'>
                            <p className='title'>Year Built</p>
                            <h3 className='value'>1922</h3>
                            <span>Average</span>
                        </div>


                        <div className='infobox'>
                            <p className='title'>Size</p>
                            <h3 className='value'>48</h3>
                            <span>Units</span>
                        </div>


                        <div className='infobox'>
                            <p className='title'>Size</p>
                            <h3 className='value'>48</h3>
                            <span>Units</span>
                        </div>


                        <div className='infobox'>
                            <p className='title'>Size</p>
                            <h3 className='value'>48</h3>
                            <span>Units</span>
                        </div>

                    </div>
                </div>

                <CompareTable />
            </div>



            <div className='compare_table_box'>
                <div className='table_header'>
                    <h3>Lilypads Sales Comps Summary Table</h3>
                    <div className='table_btn'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.50033 17.5013C7.50033 17.9596 7.87533 18.3346 8.33366 18.3346H11.667C12.1253 18.3346 12.5003 17.9596 12.5003 17.5013V16.668H7.50033V17.5013ZM10.0003 1.66797C6.78366 1.66797 4.16699 4.28464 4.16699 7.5013C4.16699 9.48464 5.15866 11.2263 6.66699 12.2846V14.168C6.66699 14.6263 7.04199 15.0013 7.50033 15.0013H12.5003C12.9587 15.0013 13.3337 14.6263 13.3337 14.168V12.2846C14.842 11.2263 15.8337 9.48464 15.8337 7.5013C15.8337 4.28464 13.217 1.66797 10.0003 1.66797ZM12.3753 10.918L11.667 11.418V13.3346H8.33366V11.418L7.62533 10.918C6.50033 10.1346 5.83366 8.85964 5.83366 7.5013C5.83366 5.2013 7.70033 3.33464 10.0003 3.33464C12.3003 3.33464 14.167 5.2013 14.167 7.5013C14.167 8.85964 13.5003 10.1346 12.3753 10.918Z" fill="#9EA0A5" />
                        </svg>   Insights
                    </div>

                </div>

                <CompareTable />
            </div>



            <div className='compare_table_box'>
                <div className='table_header'>
                    <h3>New Construction Comps Summary Table</h3>
                    <div className='table_btn'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.50033 17.5013C7.50033 17.9596 7.87533 18.3346 8.33366 18.3346H11.667C12.1253 18.3346 12.5003 17.9596 12.5003 17.5013V16.668H7.50033V17.5013ZM10.0003 1.66797C6.78366 1.66797 4.16699 4.28464 4.16699 7.5013C4.16699 9.48464 5.15866 11.2263 6.66699 12.2846V14.168C6.66699 14.6263 7.04199 15.0013 7.50033 15.0013H12.5003C12.9587 15.0013 13.3337 14.6263 13.3337 14.168V12.2846C14.842 11.2263 15.8337 9.48464 15.8337 7.5013C15.8337 4.28464 13.217 1.66797 10.0003 1.66797ZM12.3753 10.918L11.667 11.418V13.3346H8.33366V11.418L7.62533 10.918C6.50033 10.1346 5.83366 8.85964 5.83366 7.5013C5.83366 5.2013 7.70033 3.33464 10.0003 3.33464C12.3003 3.33464 14.167 5.2013 14.167 7.5013C14.167 8.85964 13.5003 10.1346 12.3753 10.918Z" fill="#9EA0A5" />
                        </svg>   Insights
                    </div>

                </div>


                <CompareTable />
            </div>


            <div className='upload_wrapper'>
                <div className='upload_header'>
                    <h3>Upload Own Comps</h3>
                    <button className='btn_download'>
                        <img src={upload} alt='' />
                        Upload File
                    </button>
                </div>
                <div className='upload_box'>
                    <img src={file} alt='' />
                    <p>Drag & Drop file here<br></br>
                        or <span>Select document</span> from computer.</p>
                </div>
            </div>

            <div className='charts'>
                <ChartBox
                    title="Fair Investment Market"
                    imageSrc={chart1}
                    description="Waltham Woods Apartments"
                    location="Maryland"
                />
                <ChartBox
                    title="High Opportunity"
                    imageSrc={chart1}
                    progress={true}
                    width={'542px'}
                />

                <ChartBox
                    title="Reviews Influence"
                    imageSrc={chart1}
                    description={'No influence'}
                />

            </div>


        </div>
    )
}

export default CompareAssets



const ProgressBar = ({ name, percentage }) => {
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' , width:'290px' }}>
                <p className='name'>{name}</p>
                <span>28%</span>
            </div>
            <div class="progress_bar">
                <div class="bar size"></div>
            </div>
        </div>
    );
};

const ChartBox = ({ width , title, imageSrc, description, location  , progress}) => {
    return (
        <div style={{width:width}} className='chart_box_wrapper'>
            <div className='chart_box'>
                <div className='header'>
                    <h3>{title}</h3>
                    <img src={cloud} alt='' />
                </div>
                <div className='chart_wrapper'>
                    <div style={{display:'flex' , gap:'20px' , alignItems:'center'}}>
                    <img src={imageSrc} alt='' />
                    {progress &&
                    <div className='progressbar_wrapper'>
                        <ProgressBar name={'Cash'}/>
                        <ProgressBar  name={'Cash in return'} />
                        <ProgressBar  name={'Cash'}/>
                        <ProgressBar  name={'Cash'}/>
                        <ProgressBar  name={'Cash'}/>

                    </div>
}
                    </div>
                    <p>{description}</p>
                    <span>{location}</span>
                </div>
            </div>
        </div>
    );
};


const CompareTable = () => {
    return (
        <div className="compare-table">
            <div className="table-row header">
                <div className="column" style={{ minWidth: '300px' }}>
                    Metric
                </div>
                <div className="column right">Low</div>
                <div className="column right">Mean</div>
                <div className="column right">Median</div>
                <div className="column right">Mean</div>
                <div className="column ">

                </div>

            </div>
            <TableRow
                metric="Current Asking Rent Per Unit"
                low="$1,461.00"
                mean="$1,001.00"
                median="$1,461.00"
                high="$1,461.00"
            />
            <TableRow
                metric="Current Effective Rent Per Unit	"
                low="$1,461.00"
                mean="$1,001.00"
                median="$1,461.00"
                high="$1,461.00"
            />


            <TableRow
                metric="Current Vacancy Rate"
                low="$1,461.00"
                mean="$1,001.00"
                median="$1,461.00"
                high="$1,461.00"
            />


            <TableRow
                metric="Property Size (Unit)"
                low="$1,461.00"
                mean="$1,001.00"
                median="$1,461.00"
                high="$1,461.00"
            />



        </div>
    );
};


const TableRow = ({ metric, low, mean, median, high }) => {
    return (
        <div className="row table-row">
            <div className="cell1" style={{ minWidth: '300px' }}>
                {metric}
            </div>
            <div className="cell right">{low}</div>
            <div className="cell right">{mean}</div>
            <div className="cell right">{median}</div>
            <div className="cell right">{high}</div>
            <div className="cell ">

                <svg xmlns="http://www.w3.org/2000/svg" width="160" height="42" viewBox="0 0 160 42" fill="none">
                    <path opacity="0.101741" fill-rule="evenodd" clip-rule="evenodd" d="M159 20.2141L144.636 19.7805L130.273 8.12891L115.909 8.39987L101.545 17.4773L87.1818 23.1947L72.8182 16.7999L58.4545 19.6721L44.0909 26.5547L29.7273 24.9289L15.3636 28.1805L1 28.6141V41.9999H159V20.2141Z" fill="#7D55C7" fill-opacity="0.71" />
                    <path d="M159 20.2141L144.636 19.7805L130.273 8.12891L115.909 8.39987L101.545 17.4773L87.1818 23.1947L72.8182 16.7999L58.4545 19.6721L44.0909 26.5547L29.7273 24.9289L15.3636 28.1805L0.999998 28.6141" stroke="#7D55C7" stroke-opacity="0.71" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
        </div>
    );
};