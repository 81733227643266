import westCost from "../../../assets/svg/westcoast.svg";
import middleCoast from "../../../assets/svg/middleCoast.svg";
import eastCoast from "../../../assets/svg/eastCoast.svg";
import {IOSSwitch, InputBox, SelectBox} from "../../../components/reusableComponents/FormFields/FormFields";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";

const FourthSectionSQ = ({location, setLocation, stateList, stateAddress, setStateAddress, stateAddressDA, setStateAddressDA, setRegion, setSubMarket}) => {
  const handleClick = (id) => {
    setLocation(id);
    setRegion(id === 1 ? "west-coast" : id === 2 ? "middle" : id === 3 ? "east coast" : "");
  };

  return (
    <>
      <div className="FirstSectionSQ sectionSignupQ">
        <div className="sectionSignupQ-T">
          <h1>
            Location{" "}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z"
                fill="#9EA0A5"
              />
            </svg>
          </h1>
          <h2>What region do you want to lend in? You can specify several</h2>
        </div>
        <div className="sectionSignupQ-B">
          <div className="location">
            <div className="left">
              {location === 1 ? (
                <img
                  src={westCost}
                  alt=""
                />
              ) : location === 2 ? (
                <img
                  src={middleCoast}
                  alt=""
                />
              ) : (
                <img
                  src={eastCoast}
                  alt=""
                />
              )}
            </div>
            <div className="right">
              <div className="upper">
                <div className="investment_vehicles">
                  <div
                    className="container"
                    onClick={() => handleClick(1)}
                  >
                    <SelectBox
                      title={"West Coast"}
                      id={1}
                      activeId={location}
                    />
                  </div>
                  <div
                    className="container"
                    onClick={() => handleClick(2)}
                  >
                    <SelectBox
                      title={"Middle"}
                      id={2}
                      activeId={location}
                    />
                  </div>
                  <div
                    className="container"
                    onClick={() => handleClick(3)}
                  >
                    <SelectBox
                      title={"East Coast"}
                      id={3}
                      activeId={location}
                    />
                  </div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="686"
                  height="1"
                  viewBox="0 0 686 1"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M686 1L8.7428e-08 1.00006L0 0L686 -5.9972e-05L686 1Z"
                    fill="#EAEDF3"
                  />
                </svg>
              </div>
              <div className="lower">
                <p>Submarket</p>
                <div className="input_Wrapper">
                  <DropDown
                    label="State"
                    data={stateList}
                    selectedData={stateAddress}
                    setSelectedData={setStateAddress}
                    dropdownState={stateAddressDA}
                    dropdownSetter={setStateAddressDA}
                  />
                  <InputBox
                    label="Submarket"
                    type="text"
                    placeholder="Enter a city, ZIP or any location"
                    onChange={(e) => setSubMarket(e.target.value)}
                  />
                  <div className="delete_button">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7 16H9V8H7V16ZM11 16H13V8H11V16ZM5 18H15V6H5V18ZM7 4H13V2H7V4ZM15 4V0H5V4H0V6H3V20H17V6H20V4H15Z"
                        fill="white"
                        fill-opacity="0.01"
                      />
                      <mask
                        id="mask0_8203_1393"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7 16H9V8H7V16ZM11 16H13V8H11V16ZM5 18H15V6H5V18ZM7 4H13V2H7V4ZM15 4V0H5V4H0V6H3V20H17V6H20V4H15Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_8203_1393)">
                        <rect
                          x="-1"
                          y="-1"
                          width="22"
                          height="22"
                          fill="#9EA0A5"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FourthSectionSQ;
