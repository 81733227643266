import React from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import "./BluePieChart.scss";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const BluePieChart = () => {
  const seriesData = [
    {name: "GP", y: 22575, color: "#03629c"},
    {name: "LP", y: 428925, color: "#57ccf2"},
  ];

  const options = {
    animationEnabled: true,
    backgroundColor: "transparent",
    title: {
      text: "",
    },
    subtitles: [
      {
        text: `${((seriesData[1].y / (seriesData[0].y + seriesData[1].y)) * 100).toFixed(2)}%`,
        verticalAlign: "center",
        fontSize: 24,
        dockInsidePlotArea: true,
      },
    ],
    data: [
      {
        type: "doughnut",
        startAngle: 60,
        innerRadius: "60%",
        indexLabelFontSize: 17,
        dataPoints: seriesData,
      },
    ],
    toolTip: {
      content: "<b>{name}</b>: ${y.toLocaleString()}",
    },
    legend: {
      horizontalAlign: "right", // Override legend's horizontal align
      verticalAlign: "center",
      fontSize: 14,
      itemTextFormatter: function (e) {
        return `${e.dataPoint.name}: $${e.dataPoint.y.toLocaleString()}`;
      },
    },
  };

  return (
    <div className="blue_pie_chart">
      <div className="chart-container">
        <CanvasJSChart options={options} />
      </div>
      <div className="legend-container">
        <div className="legend-item">
          <div
            className="color-box"
            style={{backgroundColor: "#03629c"}}
          ></div>
          <div className="legend-text">
            <div className="value">${seriesData[0].y.toLocaleString()}</div>
            <div className="label">GP</div>
          </div>
        </div>
        <div className="legend-item">
          <div
            className="color-box"
            style={{backgroundColor: "#57ccf2"}}
          ></div>
          <div className="legend-text">
            <div className="value">${seriesData[1].y.toLocaleString()}</div>
            <div className="label">LP</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BluePieChart;
