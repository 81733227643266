import React, {useState} from "react";
import ReactTable from "../../../reusuableComponents/ReactTable/ReactTable";
import "./InvestmentTable.scss";
import OfficePic1 from "../../../../../assets/investor-pipeline/icons/office_pic2.svg";
import {Navigate, useNavigate} from "react-router-dom";
import Checkbox from "../../../../profile/settings/Checkbox";
const InvestmentTable = () => {
  const data = React.useMemo(
    () => [
      {
        office_picture: OfficePic1,
        investmentEntity: "351 California St, LLC",
        address: "Address",
        committed: "$16,542,250",
        contributions: "$16,542,250",
        distributed: "$6,542,250",
        netAssetValue: "$16,542,250",
        equityBalance: "$16,542,250",
        yield: "3.5%",
        cashFlow: {
          progress1: "30%",
          progress2: "50%",
        },
      },
      {
        office_picture: OfficePic1,
        investmentEntity: "123 Main St, Inc.",
        address: "123 Main St, Los Angeles, CA",
        committed: "$8,750,000",
        contributions: "$7,200,000",
        distributed: "$3,500,000",
        netAssetValue: "$10,000,000",
        equityBalance: "$5,250,000",
        yield: "4.2%",
        cashFlow: {
          progress1: "45%",
          progress2: "35%",
        },
      },
      {
        office_picture: OfficePic1,
        investmentEntity: "789 Broadway, Ltd.",
        address: "789 Broadway, New York, NY",
        committed: "$12,000,000",
        contributions: "$11,500,000",
        distributed: "$4,000,000",
        netAssetValue: "$9,000,000",
        equityBalance: "$8,500,000",
        yield: "5.0%",
        cashFlow: {
          progress1: "25%",
          progress2: "40%",
        },
      },
      {
        office_picture: OfficePic1,
        investmentEntity: "456 Market St, Corp.",
        address: "456 Market St, Chicago, IL",
        committed: "$18,500,000",
        contributions: "$15,000,000",
        distributed: "$7,000,000",
        netAssetValue: "$14,000,000",
        equityBalance: "$9,500,000",
        yield: "3.8%",
        cashFlow: {
          progress1: "50%",
          progress2: "30%",
        },
      },
      {
        office_picture: OfficePic1,
        investmentEntity: "101 First Ave, LLP",
        address: "101 First Ave, Austin, TX",
        committed: "$9,000,000",
        contributions: "$8,500,000",
        distributed: "$2,500,000",
        netAssetValue: "$7,000,000",
        equityBalance: "$6,500,000",
        yield: "4.5%",
        cashFlow: {
          progress1: "35%",
          progress2: "45%",
        },
      },
    ],
    [],
  );

  const navigate = useNavigate();

  const handleApartmentClick = () => {
    navigate("/investment/apartment-details");
  };

  const columns = React.useMemo(
    () => [
      {
        id: "selection",
        Header: ({}) => (
          <div style={{width: "fit-content"}}>
            {/* <input type="checkbox" /> */}
            <Checkbox />
          </div>
        ),
        Cell: ({row}) => (
          <div style={{width: "fit-content"}}>
            {/* <input type="checkbox" /> */}
            <Checkbox />
          </div>
        ),
      },
      {
        Header: "INVESTMENT ENTITY",
        accessor: "investmentEntity",
        Cell: ({row}) => (
          <div
            className="investment-entity-cell"
            onClick={handleApartmentClick}
            style={{cursor: "pointer"}}
          >
            <img
              src={row.original.office_picture}
              alt="entity"
              className="entity-image"
            />{" "}
            <div
              className="entity-wrapper"
              style={{width: "fit-content", display: "flex", alignItems: "flex-start", flexDirection: "column"}}
            >
              <div>{row.original.investmentEntity}</div>
              <div className="address">{row.original.address}</div>
            </div>
          </div>
        ),
      },
      {
        Header: "COMMITTED",
        accessor: "committed",
      },
      {
        Header: "CONTRIBUTIONS",
        accessor: "contributions",
      },
      {
        Header: "DISTRIBUTED",
        accessor: "distributed",
      },
      {
        Header: "NET ASSET VALUE",
        accessor: "netAssetValue",
      },
      {
        Header: "EQUITY BALANCE",
        accessor: "equityBalance",
      },
      {
        Header: "YIELD",
        accessor: "yield",
      },
      {
        Header: "CASH FLOW",
        accessor: "cashFlow",
        Cell: ({row}) => {
          const progress1Value = parseFloat(row.original.cashFlow.progress1) / 100;
          const progress2Value = parseFloat(row.original.cashFlow.progress2) / 100;

          return (
            <div className="cash_flow_cell">
              <div className="progressbar_container wrapper1">
                <div
                  className="progress-bar blue"
                  style={{width: `${progress1Value * 100}%`}}
                ></div>
              </div>
              <div className="progressbar_container wrapper2">
                <div
                  className="progress-bar green"
                  style={{width: `${progress2Value * 100}%`}}
                ></div>
              </div>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <div className="investment_table_wrapper">
      <ReactTable
        columns={columns}
        data={data || []}
      />
    </div>
  );
};

export default InvestmentTable;
