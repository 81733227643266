import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./ProcessKeyWords.scss";

const ProcessKeyWords = ({text}) => {
  return (
    <div className="process-keyword-message">
      <div className="message-bubble plain-background">
        <ReactMarkdown
          children={text}
          remarkPlugins={[remarkGfm]}
          components={{
            p: ({node, children}) => {
              if (node.children[0]?.tagName === "strong") {
                return (
                  <p>
                    <strong className="other_text">{children}</strong>
                  </p>
                );
              }
              return <p>{children}</p>;
            },
          }}
        />
      </div>
    </div>
  );
};

export default ProcessKeyWords;
