import React from "react";
// import bottomFirstt from "../Images/bottompic1.png";
import bottomFirst from "../../assets/acqusition/svg/bottomFirst.svg";
import bottomSecond from "../../assets/acqusition/svg/bottomSecond.svg";
import "./offComp.scss";

function OffmarketsCompo({img, title, disc, squareFoot, units}) {


  return (
    <div className="off-compo">
      <img
        src={img}
        alt="bottom"
      />

      <div className="heading">
        <p>{title}</p>
      </div>

      <div className="disc">{disc}</div>

      <div className="bottom-icons">
        <div>
          <img
            src={bottomFirst}
            alt="icon"
          />
          <div className="">{squareFoot}</div>
        </div>
        <div>
          <img
            src={bottomSecond}
            alt="icon"
            className=""
          />
          <div className="">{units}</div>
        </div>
      </div>

      <hr id="line" />

      <div className="last-bottom">
        <div className="last-left">$756,879</div>
        <button className="last-right">Asset Info</button>
      </div>
    </div>
  );
}

export default OffmarketsCompo;
