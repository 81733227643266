import React from "react";
import "./Dashboard.scss";
import arrow from "../../../assets/Manage-syndicate/Svg/arrow-up.png";
import ChangeCurrency from "../../../assets/Manage-syndicate/Svg/Change Currency.svg";
import Privacy from "../../../assets/Manage-syndicate/Svg/Privacy.png";
import Investments from "../../../assets/Manage-syndicate/Svg/Investments.png";
import Operations from "../../../assets/Manage-syndicate/Svg/Operations.png";
import Vector from "../../../assets/Manage-syndicate/Svg/Vector.png";
import Vector1 from "../../../assets/Manage-syndicate/Svg/Vector (1).png";
import {PrimaryButton} from "../../../components/reusableComponents/Buttons/Buttons";

const Dashboard = () => {
  let headerdata = [
    {
      headerdata: "Fundraise faster with end -to-end SAFEs",
      containerdata: "Issue, Sign and Fund your SADFEs funded on Carta on average close faster than those funded offline, and your captable is automatically updated.",
      button_data: "Update Share Classes",
      image: ChangeCurrency,
    },
    {
      headerdata: "Get the most cost-effective 409a",
      containerdata: "Carta helps you obtain fast, accurate 409A valuation so you can audits with confidence.",
      button_data: "Update Share Classes",
      image: Privacy,
    },
    {
      headerdata: "Have you closed a new round?",
      containerdata: "Update your share classes and issue the new certificates.",
      button_data: "Update Share Classes",
      image: Investments,
    },
    {
      headerdata: "You may be eligible for the QSBS tax break",
      containerdata: "The Qualified Small Business tax break can save you and your employees up to 100% incapital gains- check if you qualify.",
      button_data: "Update Share Classes",
      image: Operations,
    },
    {
      headerdata: "Enable employee data updates",
      containerdata: "HR integration flag termination to return unvested shares back to your equity pool, and keeps your data current for tax calculation and reporting.",
      button_data: "Update Share Classes",
      image: Vector,
    },
    {
      headerdata: "You can table is healthy",
      containerdata: "We did not find any issue with your cap table that could affect reporting and audits.",
      button_data: "Update Share Classes",
      image: Vector1,
    },
  ];

  return (
    <div className="DashBoard-Conatiner">
      <div>
        <div className="dashboard_header">
          <h1>Welcome back {""}</h1>
        </div>
        <div className="dashboard_header_data">
          <div>
            <div className="dashboard_container">
              <h1>Your Tasks</h1>
            </div>
            <div className="dashboard_box">
              <div className="dashboard_data">
                <h1>Missing fair market value (FMV)</h1>
              </div>
              <div className="dashboard_contentdata">
                <p>
                  Request a new 409A or add a board - determines FMV to be compliant with the IRS. <span>What is FMV?</span>
                </p>
              </div>
              <div className="dashboard_button">
                <PrimaryButton
                  name="Manage FMV"
                  classNames="Dashboard-Manage"
                  padding="10px"
                  svgHeight="20px"
                  svgWidth="20px"
                />
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className="dashboard_container">
                <p>Snapshot</p>
              </div>
              <div className="second-box">
                <div className="color">
                  <div className="second-box-header">
                    <h1>Fully Diluted Shares</h1>
                  </div>
                  <div className="second-box-number">
                    <h1>9,320,725</h1>
                    <div className="images">
                      <img
                        src={arrow}
                        alt="image"
                      />
                    </div>
                  </div>
                </div>
                <div className="second-box-container">
                  <div className="color-data">
                    <h1>Stakeholders</h1>
                    <div className="second-box-container-data">
                      <h1>41</h1>
                      <div className="images">
                        <img
                          src={arrow}
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="task-header">
        <h1>Your Tasks</h1>
      </div>
      <div className="grid-container">
        {headerdata.map((item, index) => (
          <div
            className="grid-item"
            key={index}
          >
            <div>
              <div className="grid-item-data">
                <span>{item.headerdata}</span>
                <div
                  className={`grid-item grid-item-${index}`}
                  key={index}
                >
                  {item.image && (
                    <img
                      src={item.image}
                      alt="image"
                      style={{width: "600%"}}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="grid-item-data-details">
              <p>{item.containerdata}</p>
            </div>
            <div className="grid-item-button">
              <PrimaryButton
                name="
                Update Share Classes"
                classNames="Dashboard-Share"
                padding="10px"
                svgHeight="20px"
                svgWidth="20px"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
