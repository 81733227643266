import React, { useState } from 'react';
import './TaskModal.scss';
import { InputBox } from '../../components/reusableComponents/FormFields/FormFields';
import cross from '../../assets/svg/cross.svg'
import Checkbox from '../profile/settings/Checkbox';
import upload from '../../assets/svg/upload.svg'
import person from '../../assets/svg/profileAvatar.png'
import building from '../../assets/Image/assetDiscovery/general-bg.png'

const TaskModal = ({ isOpen, onClose, onSubmit , selectedColumn}) => {
    const [taskTitle, setTaskTitle] = useState('');
    const [dueDate, setDueDate] = useState('');
    
    const handleTitleChange = (e) => {
      setTaskTitle(e.target.value);
    };
  
    const handleDateChange = (e) => {
      setDueDate(e.target.value);
    };
  
    const handleSubmit = () => {
      if (taskTitle.trim() === '') {
        return;
      }

   
  
      onSubmit(taskTitle, dueDate , selectedColumn);
  
      setTaskTitle('');
      setDueDate('');
    };
  
    return (
      <>
        {isOpen && (
          <div className="task-modal">
            <div className="modal-overlay"></div>
            <div className="modal-content">
              <div className='basic_header'>
                  <p>Create Task</p>
                  <img  onClick={onClose}  src={cross} alt='' />
              </div>          
              <div className="modal-body">
              
                <InputBox type={'text'} label={'Task Name'} onChange={handleTitleChange} value={taskTitle} />
                <div className='desc'>
                <InputBox type={'text'} label={'description'} />
                </div>

                <p className='title'>Attachments</p>
                <div className='attachment'>
                    <p>
                        <img src={upload} alt='' />
                        Drag and drop your file 
                        or click to upload
                    </p>
                    <br></br>
                </div>


                <p className='title'>Assigned To</p>
                <div style={{display:'flex' , alignItems:'center' , gap:'4px' , marginTop:'5px' , paddingBottom:'20px' }}>
                    <img style={{height:'38px' , width:'38px'}} src={person} alt='' />
                    <img style={{height:'38px' , width:'38px'}} src={person} alt='' />
                    <img style={{height:'38px' , width:'38px'}} src={person} alt='' />
                    <button className='btn_add'>
                          Add People
                      </button>
                </div>

          
                <InputBox type={'date'} label={'Due Date'} onChange={handleDateChange} value={dueDate} width={'235px'} />

                <p className='title'>Labels</p>
                <div className='tags_wrapper'>
                        <span className='tag green'>office Management</span>
                        <span className='tag orange'>commercial</span>
                        <span className='tag blue'>Development</span>

                    </div>
              </div>
              <div className="modal-footer">
              <div className='btns'>
                  <div>
                      <button className='btn_cancel'>
                          Cancel
                      </button>
                  </div>
                  <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                      <Checkbox label={'Add Another'} />
                      <button onClick={handleSubmit} className='btn_create'>Create</button>
                  </div>
              </div>              </div>
            </div>
          </div>
        )}
      </>
    );
  };


export default TaskModal