import {PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import {Checkbox, FormControlLabel} from "@mui/material";
import profileIcon from "../../../assets/svg/commentProfile.png";
import updatesIcon from "../../../assets/svg/commentsProfile2.png";
import greendot from "../../../assets/dealsroom/svg/greendot.svg";
import updatesPics from "../../../assets/svg/updatesPics.png";
import UpdatesCards from "../../../assets/svg/updates_cards.png";
import cover from "../../../assets/Image/assetDiscovery/general-bg.png";
import cross from "../../../assets/svg/cross.svg";
import clock from "../../../assets/svg/clock.svg";
import "./recom.scss";
import SavedHeader from "../saveditems/SavedHeader";
import decline from "../../../assets/svg/cross-error.svg";

import React, {useState} from "react";
const Recom = () => {
  return (
    <div style={{maxWidth: "1111px"}}>
      <SavedHeader
        title={"Recommendation History"}
        dd1={false}
      />
      <div
        style={{padding: "0px", width: "maxContent"}}
        className="deals-room-cente asset-center-con lender_wrapper recom"
      >
        <LenderTable />
      </div>
    </div>
  );
};

export default Recom;

const LenderTable = () => {
  const [expandedRow, setExpandedRow] = useState(null);

  const handlePreviewClick = (rowIndex) => {
    setExpandedRow((prevRow) => (prevRow === rowIndex ? null : rowIndex));
  };

  return (
    <div
      className="lender_table"
      style={{display: "flex", justifyContent: "center", width: "maxContent"}}
    >
      <div className="documents_box">
        <div className="documents_box-B table">
          <div className="uploaded_docs">
            <table style={{border: "1px solid #eaedf3", width: "100%", borderCollapse: "collapse"}}>
              <thead>
                <tr>
                  <th style={{borderBottom: "1px solid #eaedf3"}}>RELATED ASSETS</th>
                  <th style={{borderBottom: "1px solid #eaedf3"}}>DATE</th>
                  <th style={{borderBottom: "1px solid #eaedf3"}}>TARGET</th>
                  <th style={{borderBottom: "1px solid #eaedf3"}}>DECISION</th>
                  <th style={{borderBottom: "1px solid #eaedf3"}}>SUCCESS RATE</th>
                  <th style={{borderBottom: "1px solid #eaedf3"}}>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3, 4, 5].map((item, index) => {
                  return (
                    <tr
                      key={index}
                      style={{borderBottom: "1px solid #eaedf3"}}
                    >
                      <td>
                        <div
                          className="item first"
                          style={{display: "flex", gap: "10px"}}
                        >
                          <img
                            style={{width: "74px", height: "56px", borderRadius: "3px"}}
                            src={cover}
                            alt=""
                          />
                          <div className="text_wrapper">
                            <p>Bauhaus Archive</p>
                            <span>MD 21432 Waltham Woods Rd, Parkville 8781</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="item">
                          <div className="text_wrapper">
                            <p>Nov. 18, 2019</p>
                            <span>02:45 pm</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="item">{index === 2 ? <button className="btn_blue">Inventory</button> : <button className="btn_green">Budgeting</button>}</div>
                      </td>
                      <td>
                        <div className="item">
                          <div className="text_wrapper">
                            <p style={{display: "flex", alignItems: "center", gap: "6px", color: index === 2 ? "#CB333B" : "#009639"}}>
                              {index === 2 ? (
                                <>
                                  <img
                                    src={decline}
                                    alt=""
                                  />{" "}
                                  Declined
                                </>
                              ) : (
                                <>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clipPath="url(#clip0_11773_39555)">
                                      <path
                                        d="M10 0C4.48615 0 0 4.48577 0 10C0 15.5142 4.48615 20 10 20C15.5138 20 20 15.5142 20 10C20 4.48577 15.5138 0 10 0ZM10 19.2308C4.91038 19.2308 0.769231 15.0896 0.769231 10C0.769231 4.91038 4.91038 0.769231 10 0.769231C15.0896 0.769231 19.2308 4.91038 19.2308 10C19.2308 15.0896 15.0896 19.2308 10 19.2308Z"
                                        fill="#009639"
                                      />
                                      <path
                                        d="M14.7125 5.89894L8.80131 12.5489L5.24015 9.7001C5.07477 9.56741 4.83207 9.59471 4.69977 9.7601C4.56707 9.92625 4.594 10.1682 4.75977 10.3009L8.60592 13.3778C8.67669 13.4343 8.76169 13.462 8.84631 13.462C8.95246 13.462 9.05823 13.4182 9.134 13.3328L15.2878 6.40971C15.429 6.25125 15.4148 6.00779 15.2559 5.86702C15.0967 5.72548 14.854 5.73971 14.7125 5.89894Z"
                                        fill="#009639"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_11773_39555">
                                        <rect
                                          width="20"
                                          height="20"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>{" "}
                                  Accepted
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="text_wrapper">
                          <p>95%</p>
                        </div>
                      </td>
                      <td>
                        <div
                          className="item"
                          style={{display: "flex", gap: "5px"}}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M9.99992 3.75C5.83325 3.75 2.27492 6.34167 0.833252 10C2.27492 13.6583 5.83325 16.25 9.99992 16.25C14.1666 16.25 17.7249 13.6583 19.1666 10C17.7249 6.34167 14.1666 3.75 9.99992 3.75ZM9.99992 14.1667C7.69992 14.1667 5.83325 12.3 5.83325 10C5.83325 7.7 7.69992 5.83333 9.99992 5.83333C12.2999 5.83333 14.1666 7.7 14.1666 10C14.1666 12.3 12.2999 14.1667 9.99992 14.1667ZM9.99992 7.5C8.61659 7.5 7.49992 8.61667 7.49992 10C7.49992 11.3833 8.61659 12.5 9.99992 12.5C11.3833 12.5 12.4999 11.3833 12.4999 10C12.4999 8.61667 11.3833 7.5 9.99992 7.5Z"
                              fill="#9EA0A5"
                            />
                          </svg>
                          <p>Preview</p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const Row = ({inventory, rowIndex, isExpanded, onPreviewClick}) => {
  return (
    <div className="table_row table_row2">
      <div className="left">
        <div className="item first">
          <img
            style={{width: "74px", height: "56px", borderRadius: "3px"}}
            src={cover}
            alt=""
          />

          <div className="text_wrapper">
            <p>Bauhaus Archive</p>
            <span>MD 21432 Waltham Woods Rd, Parkville 8781</span>
          </div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p>Nov. 18, 2019</p>
            <span>02:45 pm</span>
          </div>
        </div>
        <div className="item">{inventory ? <button className="btn_blue">Inventory </button> : <button className="btn_green">Budgeting</button>}</div>
        <div className="item">
          <div className="text_wrapper">
            <p style={{display: "flex", alignItems: "center", gap: "6px", color: inventory ? "#CB333B" : "#009639"}}>
              {inventory ? (
                <>
                  <img
                    src={decline}
                    alt=""
                  />{" "}
                  Declined{" "}
                </>
              ) : (
                <>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_11773_39555)">
                      <path
                        d="M10 0C4.48615 0 0 4.48577 0 10C0 15.5142 4.48615 20 10 20C15.5138 20 20 15.5142 20 10C20 4.48577 15.5138 0 10 0ZM10 19.2308C4.91038 19.2308 0.769231 15.0896 0.769231 10C0.769231 4.91038 4.91038 0.769231 10 0.769231C15.0896 0.769231 19.2308 4.91038 19.2308 10C19.2308 15.0896 15.0896 19.2308 10 19.2308Z"
                        fill="#009639"
                      />
                      <path
                        d="M14.7125 5.89894L8.80131 12.5489L5.24015 9.7001C5.07477 9.56741 4.83207 9.59471 4.69977 9.7601C4.56707 9.92625 4.594 10.1682 4.75977 10.3009L8.60592 13.3778C8.67669 13.4343 8.76169 13.462 8.84631 13.462C8.95246 13.462 9.05823 13.4182 9.134 13.3328L15.2878 6.40971C15.429 6.25125 15.4148 6.00779 15.2559 5.86702C15.0967 5.72548 14.854 5.73971 14.7125 5.89894Z"
                        fill="#009639"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_11773_39555">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  Accepted
                </>
              )}
            </p>
          </div>
        </div>
        <div
          style={{paddingLeft: "28px"}}
          className="item"
        >
          <div className="text_wrapper">
            <p>95%</p>
          </div>
        </div>

        <div
          className="item"
          onClick={onPreviewClick}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.99992 3.75C5.83325 3.75 2.27492 6.34167 0.833252 10C2.27492 13.6583 5.83325 16.25 9.99992 16.25C14.1666 16.25 17.7249 13.6583 19.1666 10C17.7249 6.34167 14.1666 3.75 9.99992 3.75ZM9.99992 14.1667C7.69992 14.1667 5.83325 12.3 5.83325 10C5.83325 7.7 7.69992 5.83333 9.99992 5.83333C12.2999 5.83333 14.1666 7.7 14.1666 10C14.1666 12.3 12.2999 14.1667 9.99992 14.1667ZM9.99992 7.5C8.61659 7.5 7.49992 8.61667 7.49992 10C7.49992 11.3833 8.61659 12.5 9.99992 12.5C11.3833 12.5 12.4999 11.3833 12.4999 10C12.4999 8.61667 11.3833 7.5 9.99992 7.5Z"
              fill="#9EA0A5"
            />
          </svg>

          <p>Preview</p>
        </div>
      </div>

      {isExpanded && <ExpandedComponent />}
    </div>
  );
};

const ExpandedComponent = ({onCloseClick}) => {
  return (
    <div className="expanded_component">
      <div className="expanded_component_header">
        <div>
          <BulbSvg />
          <div>
            <p>Market Insights</p>
            <span>You can Increase the price by $1 or even $1.5 since the market average is higher</span>
          </div>
        </div>
        <img
          src={cross}
          alt=""
          onClick={onCloseClick}
        />
      </div>
      <div className="btns_wrapper">
        <div>
          <button className="btn_one">
            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.021 2.42991L6.13296 9.31791C5.54596 9.90391 4.59596 9.90391 4.00896 9.31791L1.24196 6.55091C0.851963 6.16091 0.851963 5.52791 1.24196 5.13691C1.63296 4.74691 2.26596 4.74691 2.65596 5.13691L4.36196 6.84291C4.75296 7.23391 5.38796 7.23391 5.77996 6.84291L11.607 1.01591C11.997 0.624906 12.631 0.624906 13.021 1.01591C13.412 1.40591 13.412 2.03991 13.021 2.42991Z"
                fill="white"
              />
            </svg>
            Accepted
          </button>
          <button>
            <img
              style={{height: "20px", width: "20px"}}
              src={clock}
              alt=""
            />
            Snooze
          </button>
          <button>
            {" "}
            <img
              src={cross}
              alt=""
            />
            Reject
          </button>
        </div>
        <div>
          <div className="share">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12C9 11.76 8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C19.66 8 21 6.66 21 5C21 3.34 19.66 2 18 2C16.34 2 15 3.34 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C4.34 9 3 10.34 3 12C3 13.66 4.34 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.35C15.11 18.56 15.08 18.78 15.08 19C15.08 20.61 16.39 21.92 18 21.92C19.61 21.92 20.92 20.61 20.92 19C20.92 17.39 19.61 16.08 18 16.08Z"
                fill="#9EA0A5"
              />
            </svg>
            Share
          </div>
          <button className="btn_one">Create Task</button>
        </div>
      </div>
    </div>
  );
};

const BulbSvg = () => {
  return (
    <svg
      width="68"
      height="70"
      viewBox="0 0 68 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_11773_216664)">
        <path
          d="M34.0001 20.9258C27.0564 20.9258 21.4075 26.5747 21.4075 33.5184C21.4075 38.7865 24.6601 43.4532 29.5556 45.2962V52.0369C29.5556 52.4465 29.8867 52.7776 30.2964 52.7776H31.0371V54.2591C31.0371 54.6687 31.3682 54.9999 31.7778 54.9999H36.2223C36.6319 54.9999 36.963 54.6687 36.963 54.2591V52.7776H37.7038C38.1134 52.7776 38.4445 52.4465 38.4445 52.0369V45.2962C43.3401 43.4532 46.5927 38.7865 46.5927 33.5184C46.5927 26.5747 40.9438 20.9258 34.0001 20.9258ZM36.2223 51.2962C35.8127 51.2962 35.4815 51.6273 35.4815 52.0369V53.5184H32.5186V52.0369C32.5186 51.6273 32.1875 51.2962 31.7778 51.2962H31.0371V47.5924H36.963V51.2962H36.2223ZM37.4727 44.068C37.1682 44.168 36.963 44.4524 36.963 44.7717V46.111H31.0371V44.7717C31.0371 44.4517 30.8319 44.168 30.5275 44.068C25.9593 42.5658 22.889 38.3258 22.889 33.5184C22.889 27.3917 27.8734 22.4073 34.0001 22.4073C40.1267 22.4073 45.1112 27.3917 45.1112 33.5184C45.1112 38.3258 42.0408 42.5658 37.4727 44.068Z"
          fill="#7D55C7"
        />
        <path
          d="M34 19.4444C34.4096 19.4444 34.7408 19.1133 34.7408 18.7037V15.7407C34.7408 15.3311 34.4096 15 34 15C33.5904 15 33.2593 15.3311 33.2593 15.7407V18.7037C33.2593 19.1133 33.5904 19.4444 34 19.4444Z"
          fill="#7D55C7"
        />
        <path
          d="M51.7778 32.7773H48.8148C48.4052 32.7773 48.0741 33.1085 48.0741 33.5181C48.0741 33.9277 48.4052 34.2588 48.8148 34.2588H51.7778C52.1874 34.2588 52.5185 33.9277 52.5185 33.5181C52.5185 33.1085 52.1874 32.7773 51.7778 32.7773Z"
          fill="#7D55C7"
        />
        <path
          d="M19.1851 32.7773H16.2222C15.8126 32.7773 15.4814 33.1085 15.4814 33.5181C15.4814 33.9277 15.8126 34.2588 16.2222 34.2588H19.1851C19.5948 34.2588 19.9259 33.9277 19.9259 33.5181C19.9259 33.1085 19.5948 32.7773 19.1851 32.7773Z"
          fill="#7D55C7"
        />
        <path
          d="M46.0474 20.424L43.9526 22.5188C43.6629 22.8084 43.6629 23.2766 43.9526 23.5662C44.097 23.7106 44.2866 23.7832 44.4763 23.7832C44.6659 23.7832 44.8555 23.7106 45 23.5662L47.0948 21.4714C47.3844 21.1818 47.3844 20.7136 47.0948 20.424C46.8052 20.1343 46.3363 20.1351 46.0474 20.424Z"
          fill="#7D55C7"
        />
        <path
          d="M22.9999 43.4711L20.9051 45.5659C20.6155 45.8556 20.6155 46.3237 20.9051 46.6134C21.0495 46.7578 21.2392 46.8304 21.4288 46.8304C21.6184 46.8304 21.8081 46.7578 21.9525 46.6134L24.0473 44.5185C24.3369 44.2289 24.3369 43.7608 24.0473 43.4711C23.7577 43.1815 23.2895 43.1815 22.9999 43.4711Z"
          fill="#7D55C7"
        />
        <path
          d="M45 43.4711C44.7103 43.1815 44.2422 43.1815 43.9526 43.4711C43.6629 43.7608 43.6629 44.2289 43.9526 44.5185L46.0474 46.6134C46.1918 46.7578 46.3815 46.8304 46.5711 46.8304C46.7607 46.8304 46.9503 46.7578 47.0948 46.6134C47.3844 46.3237 47.3844 45.8556 47.0948 45.5659L45 43.4711Z"
          fill="#7D55C7"
        />
        <path
          d="M22.9999 23.5665C23.1443 23.7109 23.334 23.7835 23.5236 23.7835C23.7132 23.7835 23.9029 23.7109 24.0473 23.5665C24.3369 23.2768 24.3369 22.8087 24.0473 22.5191L21.9525 20.4243C21.6629 20.1346 21.1947 20.1346 20.9051 20.4243C20.6155 20.7139 20.6155 21.182 20.9051 21.4717L22.9999 23.5665Z"
          fill="#7D55C7"
        />
        <path
          d="M34 24.6289C29.0985 24.6289 25.1111 28.6163 25.1111 33.5178C25.1111 33.9274 25.4422 34.2585 25.8518 34.2585C26.2615 34.2585 26.5926 33.9274 26.5926 33.5178C26.5926 29.4334 29.9155 26.1104 34 26.1104C34.4096 26.1104 34.7407 25.7793 34.7407 25.3696C34.7407 24.96 34.4096 24.6289 34 24.6289Z"
          fill="#7D55C7"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_11773_216664"
          x="0.481445"
          y="0"
          width="67.0371"
          height="70"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood
            flood-opacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.490196 0 0 0 0 0.333333 0 0 0 0 0.780392 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11773_216664"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11773_216664"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
