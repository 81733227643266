import fileIcon from "../../../assets/svg/fileIcon.svg";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";
import {FormControlLabel} from "@mui/material";
import {IOSSwitch, InputBox, SelectBox} from "../../../components/reusableComponents/FormFields/FormFields";








const EighthSectionSQ = ({
    loanChecks,
    setLoanChecks,
    setAnnualIncome,
    annualIncomeList,
    setAnnualIncomeDA,
    annualIncome,
    annualIncomeDA,
    investmentHorizon,
    setInvestmentHorizon,
    investmentHorizonList,
    investmentHorizonDA,
    setInvestmentHorizonDA,
    liquiditySeverity,
    setLiquiditySeverity,
    liquiditySeverityList,
    liquiditySeverityDA,
    setLiquiditySeverityDA,
    netWorth,
    setNetWorth,
    netWorthDA,
    setNetWorthDA,
    netWorthList,
    investingExperienceList,
    investingExperience,
    setInvestingExperience,
    investingExperienceDA,
    setInvestingExperienceDA,
  }) => {
    return (
      <>
        <div className="FirstSectionSQ sectionSignupQ">
          <div className="sectionSignupQ-T">
            <h1>
              Current data{" "}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z"
                  fill="#9EA0A5"
                />
              </svg>
            </h1>
            <h2>Specify a few things for the Pricing plan so we can do a better job for you</h2>
          </div>
          <div className="sectionSignupQ-B">
            <div className="current_data">
              <div className="investment_objectives">
                <div className="top">
                  <img
                    src={fileIcon}
                    alt=""
                  />
                  <p>Loan Parameters</p>
                </div>
                <div className="bottom">
                  <div className="dropdowns">
                    <DropDown
                      label="What is your annual income?"
                      data={annualIncomeList}
                      selectedData={annualIncome}
                      setSelectedData={setAnnualIncome}
                      dropdownState={annualIncomeDA}
                      dropdownSetter={setAnnualIncomeDA}
                    />
                    <DropDown
                      label="What is your investment horizon?"
                      data={investmentHorizonList}
                      selectedData={investmentHorizon}
                      setSelectedData={setInvestmentHorizon}
                      dropdownState={investmentHorizonDA}
                      dropdownSetter={setInvestmentHorizonDA}
                    />
                    <DropDown
                      label="How important is liquidity?"
                      data={liquiditySeverityList}
                      selectedData={liquiditySeverity}
                      setSelectedData={setLiquiditySeverity}
                      dropdownState={liquiditySeverityDA}
                      dropdownSetter={setLiquiditySeverityDA}
                    />
                    <DropDown
                      label="What is your total net worth?"
                      data={netWorthList}
                      selectedData={netWorth}
                      setSelectedData={setNetWorth}
                      dropdownState={netWorthDA}
                      dropdownSetter={setNetWorthDA}
                    />
                    <DropDown
                      label="What is your investing experience?"
                      data={investingExperienceList}
                      selectedData={investingExperience}
                      setSelectedData={setInvestingExperience}
                      dropdownState={investingExperienceDA}
                      dropdownSetter={setInvestingExperienceDA}
                    />
                  </div>
                  <div className="additional_parameters">
                    <div className="title">
                      <h2>
                        Additional Parameters
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z"
                            fill="#9EA0A5"
                          />
                        </svg>
                      </h2>
                      <span>Do any of these apply?</span>
                    </div>
                    <div className="switches_wrapper">
                      <div className="row">
                        <div className="container">
                          <p>I am affiliated with or work for a broker/dealer</p>{" "}
                          <div className="switch_wrapper">
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  sx={{m: 1}}
                                  defaultChecked={loanChecks.second}
                                  onChange={() =>
                                    setLoanChecks((prev) => ({
                                      ...prev,
                                      first: !prev.first,
                                    }))
                                  }
                                />
                              }
                            />
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z"
                                fill="#9EA0A5"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="spacerLine30"></div>
                      </div>
                      <div className="row">
                        <div className="container">
                          <p>I am a 10% shareholder or director of a publicly traded company</p>{" "}
                          <div className="switch_wrapper">
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  sx={{m: 1}}
                                  defaultChecked={loanChecks.third}
                                  onChange={() =>
                                    setLoanChecks((prev) => ({
                                      ...prev,
                                      second: !prev.second,
                                    }))
                                  }
                                />
                              }
                            />
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z"
                                fill="#9EA0A5"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="spacerLine30"></div>
                      </div>
                      <div className="row">
                        <div className="container">
                          <p>I am or an immediate family member is a current or formerpublic official.</p>{" "}
                          <div className="switch_wrapper">
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  sx={{m: 1}}
                                  defau
                                  ltCh
                                  ecked={loanChecks.first}
                                  onChange={() =>
                                    setLoanChecks((prev) => ({
                                      ...prev,
                                      third: !prev.third,
                                    }))
                                  }
                                />
                              }
                            />
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z"
                                fill="#9EA0A5"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="spacerLine30"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  export default EighthSectionSQ