import React, {useState} from "react";
import "./style.scss";
import ReactTable from "../../../../../../../reusuableComponents/ReactTable/ReactTable";
import sponsorsProfile from "../../../../../../../../../assets/svg/sponsorsProfile.svg";
import eye from "../../../../../../../../../assets/investor-pipeline/icons/eye_icon_grey.svg";
import three_dot from "../../../../../../../../../assets/svg/three_dot.svg";
import cross from "../../../../../../../../../assets/svg/cross.svg";
import WeeklyPerformanceChart from "./WeeklyPerformanceChart";
import TopInvestors from "./TopInvestors";

const Sponsors = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const dummyData = [
    {
      sponsor_entity: {
        name: "Jose Perkins",
        type: "LLC",
        image: sponsorsProfile,
      },
      date_sponsored: "5th of Nov, 18",
      actions: "view",
    },
    {
      sponsor_entity: {
        name: "Mayme Colon",
        type: "Bank",
        image: sponsorsProfile,
      },
      date_sponsored: "3rd of Nov, 18",
      actions: "view",
    },
    {
      sponsor_entity: {
        name: "Lucas Barrett",
        type: "Direct Lender",
        image: sponsorsProfile,
      },
      date_sponsored: "1st of Nov, 18",
      actions: "view",
    },
    {
      sponsor_entity: {
        name: "Robert Marshall",
        type: "LLC",
        image: sponsorsProfile,
      },
      date_sponsored: "15th of Sep, 18",
      actions: "view",
    },
    {
      sponsor_entity: {
        name: "Anne Carson",
        type: "Bank",
        image: sponsorsProfile,
      },
      date_sponsored: "5th of Nov, 18",
      actions: "view",
    },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: "Sponsor Entity",
        accessor: "sponsor_entity",
        Cell: ({cell: {value}}) => (
          <div className="sponsor-entity">
            <img
              src={value.image}
              alt={value.name}
              className="sponsor-image"
            />
            <div>
              <p>{value.name}</p>
              <p className="sponsor-type">{value.type}</p>
            </div>
          </div>
        ),
      },
      {
        Header: "Date Sponsored",
        accessor: "date_sponsored",
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({cell: {value}}) => (
          <div className="action">
            <img
              src={eye}
              alt="eye"
              className="eye_icon"
              onClick={() => setIsModalVisible(true)} // Show modal on click
            />
            <img
              src={three_dot}
              alt="three_dot"
              className="three_dot"
            />
          </div>
        ),
      },
    ],
    [],
  );

  const data = React.useMemo(() => dummyData, []);

  return (
    <>
      <div className="sponsors_main">
        <div className="sponsors_left">
          <ReactTable
            columns={columns}
            data={data || []}
          />
        </div>

        <div className="sponsors_right">
          <div className="Syndicate_Statistic_main">
            <div className="Syndicate_Statistic_header">
              <h3>Syndicate Statistics</h3>
            </div>
            <div>
              <WeeklyPerformanceChart />
            </div>
            <div>
              <TopInvestors />
            </div>
          </div>
        </div>
      </div>
      {isModalVisible && (
        <InvestorDetailsModal
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
        />
      )}
    </>
  );
};

export default Sponsors;

const InvestorDetailsModal = ({visible, onClose}) => {
  const [isAnimating, setIsAnimating] = useState(true);

  const handleOverlayClick = (e) => {
    if (e.target.className.includes("modal-overlay")) {
      setIsAnimating(false);
      setTimeout(onClose, 300);
    }
  };
  const handleCloseButtonClick = () => {
    setIsAnimating(false);
    setTimeout(onClose, 300);
  };

  return (
    <div
      className={`modal-overlay ${isAnimating ? "enter" : "exit"}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-content ${isAnimating ? "slide-in" : "slide-out"}`}>
        <div className="modal-header">
          <button
            onClick={handleCloseButtonClick}
            className="close-button"
          >
            <img
              src={cross}
              alt="cross"
            />
          </button>
        </div>
        <div className="modal-body">
          <div className="DealRoomComp-conatiner">
            <div className="deals-room-center-del">
              <div className="deals-room-heading">
                <div className="deals-room-heading-left">
                  <p>
                    T/
                    <span>E</span>
                  </p>
                </div>
                <div className="deals-room-heading-right">
                  <div className="deals-room-heading-right-top">
                    <span>Top Estate</span>
                    <img alt="" />
                  </div>
                  <div className="deals-room-heading-right-detail">
                    <p>Investment Firm</p>
                  </div>
                  <div className="deals-room-heading-right-bottom">
                    <div className="deals-room-heading-right-text">
                      <div className="dls-room-head-back">
                        <span>68</span>
                      </div>
                      <span>Assets</span>
                    </div>
                    <div className="deals-room-heading-right-img">
                      <img alt="" />
                      <span>80% Match</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="deals-room-nav-detail">
            <span>basic details</span>
            <div className="deals-room-nav-details">
              <div className="deals-room-nav-contact-details">
                <img alt="" />
                <span>me@angenewton.co</span>
              </div>
              <div className="deals-room-nav-contact-details">
                <img alt="" />
                <span>+1 (200) 7887 665 </span>
              </div>
              <div className="deals-room-nav-contact-details">
                <img alt="" />
                <span>United States</span>
              </div>
            </div>
          </div>
          <div className="deals-room-nav-det-btn">
            <img alt="" />
            <span>Open Profile Page</span>
            <img alt="" />
          </div>
          <div className="deals-room-nav-bio">
            <span>Bio</span>
            <p>
              Working in automotive quality management for over 3 years. A passionate traveller, loves to spend time with her dog. Wine taster. Biggest dream is to travel the world with all the money
              I make from renting building to my dearest customers who pay well.
            </p>
            <span>Tags</span>
            <div className="deals-room-nav-bio-button">
              <div className="deals-room-nav-bio-btn bio-btn-first">
                <span>Office Management</span>
              </div>
              <div className="deals-room-nav-bio-btn bio-btn-second">
                <span>Commercial</span>
              </div>
              <div className="deals-room-nav-bio-btn bio-btn-third">
                <span>Development</span>
              </div>
            </div>
          </div>
          <div className="deals-room-nav-fact-box">
            <div className="deals-room-nav-fact">
              <p>Assets in Portfolio</p>
              <p>159</p>
              <p>12% more than average</p>
            </div>
            <div className="deals-room-nav-fact">
              <p>Average Price</p>
              <p>$2,074,705</p>
              <p>52% more than average</p>
            </div>
            <div className="deals-room-nav-fact">
              <p>Assets For sell</p>
              <p>15</p>
              <p>10% more than average</p>
            </div>
            <div className="deals-room-nav-fact">
              <p>Invested</p>
              <p>$5,080,000</p>
              <p>52% more than average</p>
            </div>
            <div className="deals-room-nav-fact">
              <p>Average Investment</p>
              <p>$74,705</p>
              <p>52% more than average</p>
            </div>
            <div className="deals-room-nav-fact">
              <p>MAX investment</p>
              <p>$1,000,000</p>
              <p>52% more than average</p>
            </div>
          </div>
          <div className="deals-room-nav-bio-cond">
            <div className="deals-room-nav-bio-cond-top">
              <p>Conditions</p>
              <div className="deals-room-nav-bio-cond-top-left">
                <span>See More</span>
                <img alt="" />
              </div>
            </div>
            <p>
              Working in automotive quality management for over 3 years. A passionate traveller, loves to spend time with her dog. Wine taster. Biggest dream is to travel the world with all the money
              I make from renting building to my dearest customers who pay well.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
