import React, {useState} from "react";
import "./GeneralPartner.scss";
import profile from "../../../../../../../assets/InvestmentDashboard/GaneralPartner/profile.svg";
import facebook from "../../../../../../../assets/InvestmentDashboard/GaneralPartner/facebook.svg";
import gmail from "../../../../../../../assets/InvestmentDashboard/GaneralPartner/gmail.svg";
import link from "../../../../../../../assets/InvestmentDashboard/GaneralPartner/link.svg";
import linkdin from "../../../../../../../assets/InvestmentDashboard/GaneralPartner/linkdin.svg";
import marketing from "../../../../../../../assets/InvestmentDashboard/GaneralPartner/marketing.svg";
import message from "../../../../../../../assets/InvestmentDashboard/GaneralPartner/message.svg";
import phone from "../../../../../../../assets/InvestmentDashboard/GaneralPartner/phone.svg";
import twitter from "../../../../../../../assets/InvestmentDashboard/GaneralPartner/twitter.svg";
import google from "../../../../../../../assets/InvestmentDashboard/GaneralPartner/google.svg";
import ActiveInvestment from "../../../../../../../components/InvestmentPipelineComponents/Portfolio_Overview/ActiveInvestment/ActiveInvestment";

function GeneralProfile({setIsClicked}) {
  const [navActive, setNavActive] = useState(1);

  return (
    <div className="gp_profile">
      <div className="gp_profile_left">
        <div className="gp_profile_top">
          <img alt="" />
          <div className="img_text">
            <p>North Group Trust</p>
            <span>45 Groups</span>
          </div>

          <div className="gp_profile_top_nav">
            <button>
              <img alt="" />
              Connected
            </button>
            <div className=" deals-room-nav gp_nav asset-nav ass-nav">
              <span
                className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
                onClick={() => {
                  setNavActive(1);
                }}
              >
                About{" "}
              </span>
              <span
                className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
                onClick={() => {
                  setNavActive(2);
                }}
              >
                Investments
              </span>
            </div>
          </div>
        </div>

        <div className="gp_profile_center">
          {navActive === 1 && <About setNavActive={setNavActive} />}
          {navActive === 2 && <Investment />}
        </div>
      </div>
    </div>
  );
}

export default GeneralProfile;

const About = ({setNavActive}) => {
  return (
    <div className="about">
      <div className="about_left">
        <h4>General Partner</h4>

        <div className="about_text_wrapper">
          <h1>North Group Trust</h1>
          <span>164 S. Carson Court Newport News, VA 23601</span>
          <p>
            North Group Trust is a vertically integrated commercial real estate development and capital investment company with a strong track record of successful, high-quality medical and strategic
            acquisitions. New Era works with healthcare systems, large medical groups and regional healthcare companies to provide the solutions for on and off campus medical office development,
            post-acute hospitals, behavioral health hospitals, cancer centers, surgical and imaging centers, hospitality and full campus development.
          </p>
        </div>

        <div className="about_data">
          <div className="data_item">
            <p>Total Portfolio Activity</p>
            <p>$376,722,967</p>
          </div>
          <div className="data_item">
            <p>Total Portfolio Activity</p>
            <p>$376,722,967</p>
          </div>
          <div className="data_item">
            <p>Total Portfolio Activity</p>
            <p>$376,722,967</p>
          </div>
        </div>

        <div className="investment_data">
          <h1>
            Investment Interests <span>based on portfolio</span>
          </h1>
          <div className="investment_data_box">
            <div className="row1">
              <div className="data_box_wrapper">
                <h3>Property Type</h3>
                <div className="data_box">
                  <div className="data_box_item">
                    <p>Built to Rent </p>
                    <p>24</p>
                  </div>
                  <div className="data_box_item">
                    <p>Industrial</p>
                    <p>45</p>
                  </div>
                  <div className="data_box_item">
                    <p>Retail</p>
                    <p>2</p>
                  </div>
                </div>
              </div>
              <div className="data_box_wrapper">
                <h3>Hold On Period</h3>
                <div className="data_box">
                  <div className="data_box_item">
                    <p>Less than 1 year </p>
                    <p>24</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row2">
              <div className="data_box_wrapper">
                <h3>Market Size</h3>
                <div className="data_box">
                  <div className="data_box_item">
                    <p>Built to Rent </p>
                    <p>24</p>
                  </div>
                </div>
              </div>
              <div className="data_box_wrapper">
                <h3>Regions</h3>
                <div className="data_box">
                  <div className="data_box_item">
                    <p>Midwest </p>
                    <p>24</p>
                  </div>
                  <div className="data_box_item">
                    <p>Southwest </p>
                    <p>24</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about_right">
        {/* <div className="about_right_header">
          <h4>Investments</h4>
          <p
            style={{cursor: "pointer"}}
            onClick={() => setNavActive(2)}
          >
            See More
          </p>
        </div> */}

        <div className="about_right_img_box">
          <div></div>

          <div className="gp_profile_right">
            <h2>Contacts</h2>
            <div className="contact_info">
              <span>
                <img
                  alt="profile"
                  src={profile}
                />
                Randall Renegade
              </span>
              <span>
                <img
                  src={phone}
                  alt="phone"
                />
                +1 7887 665 221 <br />
                +1 7887 665 221
              </span>

              <span>
                <img
                  alt="gmail"
                  src={gmail}
                />
                me@angenewton.co
              </span>
              <span>
                <img
                  alt="link"
                  src={link}
                />
                www.heritageattampa.com
              </span>
              <span>
                <img
                  src={marketing}
                  alt="marketing"
                />
                Marketing Brochure
              </span>
              <span>
                <img
                  src={facebook}
                  alt="facebook"
                />
                NorthGroupTrust
              </span>
              <span>
                <img
                  src={linkdin}
                  alt="linkdin"
                />
                Marketing Brochure
              </span>
              <span>
                <img
                  src={twitter}
                  alt="twitter"
                />
                NorthGroupTrust
              </span>
              <span>
                <img
                  src={google}
                  alt="gmail"
                />
                NGT
              </span>
              <span>
                <img
                  src={message}
                  alt="message"
                />
                Mesage
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function Investment() {
  const [navActive, setNavActive] = useState(1);

  // const [tableHeader, setTblaeHeader] = useState(false);

  return (
    <>
      <div className="general_partner_activeInvestment">
        <ActiveInvestment />
      </div>
    </>
  );
}

const Table = () => {
  return (
    <div>
      <div className="documents_box-B table">
        <div className="uploaded_docs">
          <div className="header">
            <div className="left">
              <p>Name</p>
              <p>date added</p>
              <p>Performance</p>
              <p>Potential Growth</p>
            </div>
          </div>
          <Row2 />
          <Row2 />
          <Row2 />
          <Row2 />
        </div>
      </div>
    </div>
  );
};

const Row2 = () => {
  return (
    <div className="table_row table_row2">
      <div className="left">
        <div className="item first">
          <img alt="" />

          <div className="text_wrapper">
            <p>Aurora</p>
          </div>
        </div>

        <div className="item">
          <div className="text_wrapper">
            <p>08 Nov 2018</p>
          </div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p>
              <span>
                {" "}
                <img alt="" />
                <img alt="" />
                <img alt="" />
                <img alt="" />
                <img alt="" />
              </span>
            </p>
          </div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p>$500,900.00</p>
          </div>
        </div>
      </div>
    </div>
  );
};
