import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Endpoint from "../../../utils/Endpoint";

export const fetchBudgetData = createAsyncThunk(
  'budget/fetchBudgetData', 
  async ({ property_code }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const idToken = auth?.idToken;

      const response = await axios.get(Endpoint.getBudgetData, {
        headers: {
          'auth-token': idToken,
        },
        params: {
          property_code,
         
        },
      });

      return response.data;
    } catch (error) {
      const errorMsg = error.response?.data?.message || error.message || "Failed to fetch budget data";
      return rejectWithValue(errorMsg);
    }
  }
);

export const generateBudgetData = createAsyncThunk(
  'budget/generateBudgetData',
  async ({ property_code, page, limit }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const idToken = auth?.idToken;

      const response = await axios.get(Endpoint.generate_property, {
        headers: {
          'auth-token': idToken,
        },
        params: {
          property: property_code,
          page,
          limit,
        },
      });

      return response.data;
    } catch (error) {
      const errorMsg = error.response?.data?.message || error.message || "Failed to generate budget data";
      return rejectWithValue(errorMsg);
    }
  }
);

const budgetSlice = createSlice({
  name: "budget",
  initialState: {
    data: null,
    totalItems: 0,
    totalPages: 0,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBudgetData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBudgetData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        state.totalItems = action.payload.totalItems;
        state.totalPages = action.payload.totalPages;
        state.error = null;
      })
      .addCase(fetchBudgetData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(generateBudgetData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateBudgetData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        state.totalItems = action.payload.totalItems;
        state.totalPages = action.payload.totalPages;
        state.error = null;
      })
      .addCase(generateBudgetData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default budgetSlice.reducer;
