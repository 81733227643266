import React from "react";
import "./style.scss";
import CrossIcon from "../../../assets/icons/errorCrossIcon.svg";
import {useDispatch, useSelector} from "react-redux";
import { clearErrors } from "../../../../../store/reducers/error";
import { PrimaryButton } from "../../../Buttons/Buttons";

function ErrorModal() {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    dispatch(clearErrors());
  };

  const {title, msg, status} = useSelector((state) => state.error);

  React.useEffect(() => {
    setOpen(true);
    setMessage(msg);
    if (status === 500 && !msg) {
      setMessage("Internal server error. Please try again later.");
    }
  }, [title, msg, status]);
  return msg && status ? (
    <div
      className="error_modal_overlay"
      onClick={() => handleClose(false)}
    >
      <div className="error_modal_container">
        <div
          className="error_modal_container_top"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="tick_icon">
            <img
              src={CrossIcon}
              alt="error"
            />
          </div>
        </div>

        <div
          className="error_modal_container_bottom"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h1>Error</h1>
          <p>{msg}</p>
          <button
            className="error_modal_close_btn"
            onClick={() => handleClose(false)}
          >
            {"Close"}
          </button>
        </div>
      </div>
    </div>
  ) : (
    false
  );
}

export default ErrorModal;
