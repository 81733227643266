import React from "react";
import Table from "../../../components/reusableComponents/Table/ReactTable";
import { PrimaryButton } from "../../../components/reusableComponents/Buttons/Buttons";
import "./Datarooms.scss";
import SearchInput from "../../../components/reusableComponents/Search/Search";

const Datarooms = () => {
  const headers = ["Name", "CREATION", "Documents", "access", "status"];
  const tableData = [
    { Name: "Onboarding documents", Date: "03/12/2021", Documents: "5 documents", Occupation: "0 people", status: "Archived" }
  ];
  return (
    <div className="Datarooms-container">
      <h1>Data Rooms</h1>
      <div className="Datarooms-content">
        <div className="Datarooms-controls">
          <SearchInput />
          <PrimaryButton
            name="Filter"
            classNames="Datarooms-Filter"
            padding="10px"
            svgHeight="20px"
            svgWidth="20px"
          />
        </div>
        <Table headers={headers} tabledata={tableData} />
      </div>
    </div>
  );
};

export default Datarooms;
