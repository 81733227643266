import React from "react";
import {useSelector} from "react-redux";
import "./Deals.scss";

import dot from "../../assets/svg/dot.svg";
import blubar from "../../assets/svg/bluebar.svg";
import greenbar from "../../assets/svg/greenbar.svg";
import orangebar from "../../assets/svg/orangebar.svg";
import redbar from "../../assets/svg/redbar.svg";
import info from "../../assets/svg/info.svg";
import status from "../../assets/svg/status.svg";
import quesMarkIcon from "../../assets/dealsroom/svg/QuesMarkIcon.svg";
import importerIcon from "../../assets/dealsroom/svg/importedGreenIcon.svg";
import success from "../../assets/svg/sucess.svg";
import dealcard from "../../assets/Image/deals/dealcard.png";
import cardman from "../../assets/Image/deals/cardman.png";
import {Link} from "react-router-dom";
import {Progress} from "antd";

const DispositionCards = ({data, setSelectedAssetData, roleType}) => {
  const selectedData = useSelector((state) => state.portfolio.selectedProperty);

  return (
    <div className="deal-card">
      <div className="deal-card-upper">
        <img
          src={data?.assetsImages?.[0] ? data.assetsImages[0] : dealcard}
          alt="Asset"
        />
        <div className="deal-c-u-t">
          <span>{data?.assetName}</span>
          <span>{data?.assetsAddress}</span>
        </div>
        <div className="dot">
          <img
            src={dot}
            alt=""
          />
        </div>
      </div>
      <Link to={`/${selectedData?._id}/dealsroom`}>
        <div className="deal-card-center">
          <div className="deal-card-c-inner">
            <div className="deal-card-list">
              <span>Property type</span>
              <span>{data?.property_type}</span>
            </div>
            <div className="deal-card-list">
              <span>Deal type</span>
              <span>Acquisition</span>
            </div>
            <div className="deal-card-list">
              <span>Market</span>
              <span>Baltimore</span>
            </div>
          </div>
          <div className="deal-card-c-inner">
            <div className="deal-card-list">
              <span>Gross SF</span>
              <span>100,000</span>
            </div>
            <div className="deal-card-list">
              <span>Price</span>
              <span>{data?.assetValue}</span>
            </div>
            <div className="deal-card-list">
              <span>Price GSF</span>
              <span>{data?.pricePerUnit}</span>
            </div>
          </div>

          <div className="divider"></div>

          <div className="deal-card-progress">
            <div className="card-progress">
              <div>
                <span>Checklist</span>
              </div>
              <div>
                <img
                  src={quesMarkIcon}
                  alt="quesMarkIcon "
                />
              </div>
            </div>
            <div className="bar-container">
              <div className="bar-wrapper">
                <span>21/30</span>
                <Progress
                  showInfo={false}
                  percent={50}
                  strokeColor="#7EC331"
                />
              </div>
              <div className="bar-wrapper">
                <span>21/30</span>
                <Progress
                  showInfo={false}
                  percent={50}
                  strokeColor="#ED962D"
                />
              </div>
              <div className="bar-wrapper">
                <span>21/30</span>
                <Progress
                  showInfo={false}
                  percent={50}
                  strokeColor="#CB333B"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="deal-card-bottom">
          <div>
            <div>
              <img
                src={cardman}
                alt=""
              />
            </div>
            <div className="importer_info">
              <div className="dealcard-success">
                <img
                  src={importerIcon}
                  alt=""
                />
                <span>Imported by</span>
              </div>
              <div className="dealcard-s-name">
                <span>{data?.addedBy}</span>
              </div>
            </div>
          </div>
          <div className="dealcard-date">
            <span>Nov. 19, 2019 2:23 pm</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default DispositionCards;
