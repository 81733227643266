import ProgressBar from "@ramonak/react-progress-bar";
import "./style.scss";
const ProgressBarComponent = ({completed}) => {
  return (
    <ProgressBar
      completed={completed}
      bgColor="#7d55c7"
      isLabelVisible={false}
      labelColor="#ffffff"
      maxCompleted={100}
    />
  );
};

export default ProgressBarComponent;
