import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import addGray from '../../assets/svg/add-gray.svg';
import setting from '../../assets/svg/settings.svg';
import person from '../../assets/svg/profileAvatar.png';
import attachment from '../../assets/svg/attachment.svg';
import './Project.scss';
import Board from './Board';
import edit from '../../assets/svg/edit.svg';
import Delete from '../../assets/svg/delete.svg';

function Projects({ moveCard, projects, setProjects, selectedColumn , setSelectedColumn ,  columns ,setColumns, onDragEnd, addNewProjectColumn  , openTaskModal , handleDeleteColumn , handleRenameColumn}) {
  const [viewBoard, setViewBoard] = useState('open');

  const handleTabChange = (status) => {
    setViewBoard(status);
  };


  


  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div style={{ display: 'flex', gap: '20px', maxWidth: '1091px', overflowX: 'auto' }}>
        {columns.map((column, index) => (
          <Droppable key={column.name} droppableId={column.name}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <ProjectColumn
                projectsarr={projects}
                  key={index}
                  viewBoard={viewBoard}
                  border={column.border}
                  name={column.name}
                  status={column.name}
                  projects={column.projects}
                  moveCard={moveCard}
                  addNewProjectColumn={addNewProjectColumn}
                  openTaskModal={openTaskModal}
                  onDeleteColumn={handleDeleteColumn}
                  onRenameColumn={handleRenameColumn}
                />
              </div>
            )}
          </Droppable>
        ))}
      </div>
    </DragDropContext>
  );
}

export default Projects;

const SettingsPopup = ({ onDeleteColumn, onRenameColumn }) => {
  const handleRename = () => {
    const newName = prompt('Enter a new name for the column:');
    if (newName) {
      onRenameColumn(newName);
    }
  };

  const handleDelete = () => {
    const confirmDelete = window.confirm('Are you sure you want to delete this column?');
    if (confirmDelete) {
      onDeleteColumn();
    }
  };

  return (
    <div className='settings_popup'>
      <p onClick={handleRename}>
        <img src={edit} alt='' />
        Rename List
      </p>
      <p onClick={handleDelete}>
        <img src={Delete} alt='' />
        Remove List
      </p>
    </div>
  );
};

const ProjectColumn = ({ border, name, status, projects, moveCard, addNewProjectColumn, openTaskModal, onDeleteColumn, onRenameColumn }) => {


  return (
    <div className='project_list_wrapper'>
      <ProjectTab border={border} name={name} length={projects.length} openTaskModal={openTaskModal} onDeleteColumn={onDeleteColumn} onRenameColumn={onRenameColumn} />
      <Droppable droppableId={name}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className='project_box_wrapper'
          >
            {projects?.filter((project) => project.status === name)
              .map((project, index) => (
                <Draggable key={project.id} draggableId={`${project.id}`} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ProjectBox
                        data={project}
                        key={project.id}
                        index={index}
                        project={project}
                        moveCard={moveCard}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};


  const ProjectBox = ({ data, project, index, moveCard, updateProjectStatus }) => {

  
    return (
      <div className='project_box'>
        {data.img ? (
          <img
            style={{ width: '300px', height: '85px', borderRadius: '4px', marginBottom: '20px' }}
            src={data.img}
            alt=''
          />
        ) : (
          ''
        )}
        <div className='progress'></div>
        <p>{data.title}</p>
        <div className='project_box_lower'>
          <div className='lower_left'>
            <img src={person} alt='' />
            <img src={person} alt='' />
            <img src={person} alt='' />
            <img src={person} alt='' />
            <svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28' fill='none'>
              <g clip-path='url(#clip0_562_622)'>
                <path
                  d='M15 13H18C18.5523 13 19 13.4477 19 14C19 14.5523 18.5523 15 18 15H15V18C15 18.5523 14.5523 19 14 19C13.4477 19 13 18.5523 13 18V15H10C9.44772 15 9 14.5523 9 14C9 13.4477 9.44772 13 10 13H13V10C13 9.44772 13.4477 9 14 9C14.5523 9 15 9.44772 15 10V13Z'
                  fill='#9EA0A5'
                />
              </g>
              <defs>
                <clipPath id='clip0_562_622'>
                  <rect width='28' height='28' fill='white' />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className='lower_right'>
            <img src={attachment} alt='' />
            <span>2</span>
          </div>
        </div>
      </div>
    );
  };

const ProjectTab = ({name, border, length, openTaskModal, onDeleteColumn, onRenameColumn  }) => {

  const [settings , setSettings] = useState(false)
  const toggleSettings = () => {
    setSettings(!settings);
  };
  return (
    <div>
    <div style={{ borderTop: `2px solid ${border}` }} className='project_tab'>
      <div>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='10'
          height='24'
          viewBox='0 0 10 24'
          fill='none'
        >
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5ZM10 1.5C10 2.32843 9.32843 3 8.5 3C7.67157 3 7 2.32843 7 1.5C7 0.671573 7.67157 0 8.5 0C9.32843 0 10 0.671573 10 1.5ZM1.5 10C2.32843 10 3 9.32843 3 8.5C3 7.67157 2.32843 7 1.5 7C0.671573 7 0 7.67157 0 8.5C0 9.32843 0.671573 10 1.5 10ZM10 8.5C10 9.32843 9.32843 10 8.5 10C7.67157 10 7 9.32843 7 8.5C7 7.67157 7.67157 7 8.5 7C9.32843 7 10 7.67157 10 8.5ZM1.5 17C2.32843 17 3 16.3284 3 15.5C3 14.6716 2.32843 14 1.5 14C0.671573 14 0 14.6716 0 15.5C0 16.3284 0.671573 17 1.5 17ZM10 15.5C10 16.3284 9.32843 17 8.5 17C7.67157 17 7 16.3284 7 15.5C7 14.6716 7.67157 14 8.5 14C9.32843 14 10 14.6716 10 15.5ZM1.5 24C2.32843 24 3 23.3284 3 22.5C3 21.6716 2.32843 21 1.5 21C0.671573 21 0 21.6716 0 22.5C0 23.3284 0.671573 24 1.5 24ZM10 22.5C10 23.3284 9.32843 24 8.5 24C7.67157 24 7 23.3284 7 22.5C7 21.6716 7.67157 21 8.5 21C9.32843 21 10 21.6716 10 22.5Z'
            fill='#D5DCE6'
          />
        </svg>
        <h3>{name}</h3>
        <span className="length">{length}</span>
      </div>
      <div>
      <img src={addGray} alt='' onClick={() => openTaskModal(name)} />
          <img src={setting} alt='' onClick={toggleSettings} />
        </div>
      </div>
      {settings && <SettingsPopup onDeleteColumn={() => onDeleteColumn(name)} onRenameColumn={(newName) => onRenameColumn(name, newName)} />}
    </div>

  );
};

