import React, {useState} from "react";
import axios from "axios";
import "./basic.scss";
import HeaderImg from "../../../assets/Image/real-estate.png";
import {InputBox} from "../../../components/reusableComponents/FormFields/FormFields";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";
import {useSelector} from "react-redux";
import {returnMessages} from "../../../store/reducers/message";
import {useDispatch} from "react-redux";
import {returnErrors} from "../../../store/reducers/error";
import useAuthConfig from "../../../utils/Config";

function Basic({userData, setUserData, setDetails, userDetails, setRefresh, refresh}) {
  const dispatch = useDispatch();
  const {idToken} = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [userTimeDA, setUserTimeDA] = useState(false);
  const [countryDA, setCountryDA] = useState(false);
  const config = useAuthConfig();

  const setFilteredUserData = (newData) => {
    const {email, phone_number, ...filteredData} = newData;
    setUserData(filteredData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.patch("api/auth/edit/user", userData, config);
      setDetails(userData);
      setRefresh(!refresh);
      dispatch(returnMessages("Profile modification complete. All changes saved", 200));
    } catch (error) {
      dispatch(returnErrors("Profile modification failed. Please try again.", error.response.status));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="basic">
      <div className="basic_header">
        <div>
          <p>Basic Profile</p>
          <span>Fill Information</span>
        </div>
        <img
          src={HeaderImg}
          alt=""
        />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="inputs">
          <div className="genral-input input_box">
            <InputBox
              label={"First Name"}
              placeholder={"John"}
              value={userData.name}
              onChange={(e) => setFilteredUserData({...userData, name: e.target.value})}
            />
            <InputBox
              label={"Last Name"}
              placeholder={"Doe"}
              value={userData.surname}
              onChange={(e) => setFilteredUserData({...userData, surname: e.target.value})}
            />
            <InputBox
              label={"Email Address"}
              type={"email"}
              placeholder={"john.doe@example.com"}
              disabled
              value={userData.email}
              onChange={(e) => setFilteredUserData({...userData, email: e.target.value})}
            />
            <InputBox
              label={"Phone Number"}
              type={"tel"}
              placeholder={"(123) 456-7890"}
              disabled
              value={userData.phone_number}
              onChange={(e) => setFilteredUserData({...userData, phone_number: e.target.value})}
            />
            <InputBox
              label={"Role"}
              placeholder={"Your Role"}
              value={userData.role}
              onChange={(e) => setFilteredUserData({...userData, role: e.target.value})}
            />
            <InputBox
              label={"Department"}
              placeholder={"Your Department"}
              value={userData.department}
              onChange={(e) => setFilteredUserData({...userData, department: e.target.value})}
            />
          </div>
          <span>What do you do at your company</span>

          <div
            style={{marginTop: "20px"}}
            className="genral-input b-i input box"
          >
            <InputBox
              label={"Street Address"}
              placeholder={"123 Main St"}
              value={userData.street_address}
              onChange={(e) => setFilteredUserData({...userData, street_address: e.target.value})}
            />
            <InputBox
              label={"Secondary Address Designation"}
              placeholder={"Apartment, Suite, etc."}
              value={userData.secondary_address}
              onChange={(e) => setFilteredUserData({...userData, secondary_address: e.target.value})}
            />
            <InputBox
              label={"City"}
              placeholder={"Your City"}
              value={userData.city}
              onChange={(e) => setFilteredUserData({...userData, city: e.target.value})}
            />
            <InputBox
              label={"State"}
              placeholder={"Your State"}
              value={userData.state}
              onChange={(e) => setFilteredUserData({...userData, state: e.target.value})}
            />
            <InputBox
              label={"zip_code"}
              placeholder={"Your zip_code"}
              value={userData.zip_code}
              onChange={(e) => setFilteredUserData({...userData, zip_code: e.target.value})}
            />
            <DropDown
              label="Country"
              data={["Usa", "Canada"]}
              selectedData={userData.country}
              setSelectedData={(value) => setFilteredUserData({...userData, country: value})}
              dropdownState={countryDA}
              dropdownSetter={setCountryDA}
            />
            <DropDown
              label="time_zone"
              data={["UTC -08:00 Pacific Time (US and Canada)", "IST - Indian Standard Time"]}
              selectedData={userData.time_zone}
              setSelectedData={(value) => setFilteredUserData({...userData, time_zone: value})}
              dropdownState={userTimeDA}
              dropdownSetter={setUserTimeDA}
            />
          </div>
          <div className="input_lower">
            <span>About Me</span>
            <br></br>
            <textarea
             className="TextArea_AllInput"
              value={userData.about_me}
              onChange={(e) => setFilteredUserData({...userData, about_me: e.target.value})}
            ></textarea>
            <span>Optional</span>
          </div>
        </div>
        <button
          type="submit"
          className={`start-syndicate-button-Basic ${loading ? "loading" : ""}`}
          disabled={loading}
        >
          {loading ? <div className="spinner"></div> : "Save Changes"}
        </button>
      </form>
    </div>
  );
}

export default Basic;
