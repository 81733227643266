import React from "react";
import Chart from "react-apexcharts";

const ApexBarChart = () => {
  const options = {
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["Category 1", "Category 2"],
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return `${value / 1000}K`;
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => {
          return `$${value}`;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    colors: ["#AB7DF6", "#BFBFBF"],
  };

  const series = [
    {
      name: "Series 1",
      data: [800000, 300000],
    },
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      height="300"
      // width="260"
    />
  );
};

export default ApexBarChart;
