import React, {useEffect, useRef, useState} from "react";
import "./SetupLoanReq.scss";
import cross from "../../assets/syndicate/svg/cross.svg";
import save from "../../assets/syndicate/svg/save.svg";
import eye from "../../assets/syndicate/svg/eye.svg";
import left from "../../assets/syndicate/svg/left.svg";
import right from "../../assets/syndicate/svg/right.svg";
import download from "../../assets/syndicate/svg/download.svg";
import link from "../../assets/syndicate/svg/link.svg";
import cover from "../../assets/syndicate/svg/cover.svg";
import boypic from "../../assets/syndicate/image/boypic.png";
import uploadIcon from "../../../src/assets/svg/uploadIcon.svg";

import remove from "../../assets/syndicate/svg/remove.svg";
import store from "../../assets/syndicate/svg/store.svg";
import dropdown from "../../assets/syndicate/svg/dropdown.svg";
import date from "../../assets/syndicate/svg/date.svg";
import dollar from "../../assets/loanrequest/svg/dollar.svg";
import idea from "../../assets/loanrequest/svg/idea.svg";
import dropdocument from "../../assets/loanrequest/svg/upload.svg";
import pdf from "../../assets/loanrequest/svg/pdf.svg";
import navclose from "../../assets/loanrequest/svg/navclose.svg";
import deleted from "../../assets/loanrequest/svg/delete.svg";
import plus from "../../assets/loanrequest/svg/plus.svg";
import pluspurple from "../../assets/loanrequest/svg/pluspurple.svg";
import saved from "../../assets/loanrequest/svg/save.svg";
import upload from "../../assets/loanrequest/svg/upload.svg";
import search from "../../assets/loanrequest/svg/search.svg";
import filter from "../../assets/loanrequest/svg/filter.svg";
import signal from "../../assets/loanrequest/svg/signal.svg";
import info from "../../assets/loanrequest/svg/info.svg";
import deletej from "../../assets/loanrequest/svg/delete.svg";
import profileplus from "../../assets/loanrequest/svg/profileplus.svg";
import edit from "../../assets/loanrequest/svg/edit.svg";
import addprofile from "../../assets/loanrequest/svg/addprofile.svg";
import user from "../../assets/dealsroom/svg/user.svg";
import email from "../../assets/dealsroom/svg/email.svg";
import phoneno from "../../assets/dealsroom/svg/phoneno.svg";
import website from "../../assets/dealsroom/svg/website.svg";
import socialnetwork from "../../assets/dealsroom/svg/socialnetwork.svg";
import facebook from "../../assets/dealsroom/svg/facebook.svg";
import twitter from "../../assets/dealsroom/svg/twitter.svg";
import linkedein from "../../assets/dealsroom/svg/linkedein.svg";
import google from "../../assets/dealsroom/svg/google.svg";
import addsec from "../../assets/syndicate/svg/addsec.svg";
import sp from "../../assets/svg/arrow_disposition.svg";
import man from "../../assets/loanrequest/img/man.png";
import lender_cross from "../../assets/loanrequest/svg/lender_cross.svg";
import webinar from "../../assets/loanrequest/svg/webinar.svg";
// import link from "../../assets/loanrequest/svg/link.svg";
import video from "../../assets/syndicate/svg/video.svg";
import grid from "../../assets/syndicate/svg/grid.svg";
import text from "../../assets/syndicate/svg/text.svg";
import image from "../../assets/syndicate/svg/image.svg";

import prevnav from "../../assets/dealsroom/svg/prevnav.svg";
import fwdnav from "../../assets/dealsroom/svg/fwdnav.svg";

import {IOSSwitch, InputBox, SelectBox} from "../../components/reusableComponents/FormFields/FormFields";
import {PrimaryButtonSvgFirst} from "../../components/reusableComponents/Buttons/Buttons";
import DropDown from "../../components/reusableComponents/Dropdown/Dropdown";
import {Checkbox, FormControlLabel, FormGroup, Slider} from "@mui/material";
import {useNavigate, Link} from "react-router-dom";
import {PostLoanSetupData} from "../../utils/Axios";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import errorReducer, {returnErrors} from "../../store/reducers/error";
import {returnMessages} from "../../store/reducers/message";
import { SummarySyndicate } from "./Syndicate/LenderCard";

export const Button = ({icon, value}) => {
  return (
    <div className="header-button-SetupLoanReq">
      <img
        src={icon}
        alt="icon"
      />
      <p>{value}</p>
    </div>
  );
};

const SetupLoanReq = ({navdata = 1, handleMenuClicked}) => {
  const {idToken} = useSelector((state) => state.auth);
  const selectedData = useSelector((state) => state.userInfo.userAssetData);
  const [isChecked, setIsChecked] = useState(false);
  // 1ST SECTION
  const [dealNameList, setDealNameList] = useState(["My Next Asset", "Refinancing existing details", "Construction"]);
  const [dealName, setDealName] = useState();
  const [dealNameDA, setDealNameDA] = useState(false);

  const [financialPurposeList, setFinancialPurposeList] = useState(["My Next Asset", "Refinancing existing details", "Construction"]);
  const [financialPurpose, setFinancialPurpose] = useState();
  const [financialPurposeDA, setFinancialPurposeDA] = useState(false);

  const [purchaseStage, setPurchaseStage] = useState();
  const [purchaseStageList, setPurchaseStageList] = useState(["Under Contract", "Making an Offer", "Activity Looking"]);
  const [purchaseStageDA, setPurchaseStageDA] = useState(false);

  const [statusList, setStatusList] = useState(["Active", "Pending", "Cancelled", "Closed"]);
  const [status, setStatus] = useState("Active");
  const [statusDA, SetStatusDA] = useState(false);

  const [coverImage, setCoverImage] = useState();
  const [coverImageLink, setCoverImageLink] = useState();
  const [customLink, setCustomLink] = useState();

  //2 SECTION
  const [price, setPrice] = useState({from: "", to: "", any: false});
  const [purchasePrice, setPurchasePrice] = useState("2,50,000");
  const [longTerm, setLongTerm] = useState();
  const [closingDate, setClosingDate] = useState();
  const [offering, setOffering] = useState();
  const [offeringList, setOfferingList] = useState(["Equity 1031-Exchange", "Common Equity", "Preferred Equity", "Junior Debt", "Mezzanine Debt", "Senior Debt"]);
  const [offeringDA, setOfferingDA] = useState(false);
  const [loanType, setLoanType] = useState();
  const [rePayment, setRepayment] = useState();
  const [additionalType, setAdditionalType] = useState();
  const [additionalAmount, setAdditionalAmount] = useState();
  const [finacialDocument, setFinancialDocument] = useState();

  // 3 SECTION
  const [selectedLenders, setSelectedLenders] = useState([]);
  // 4 SECTION
  const [webinarLink, setWebinarLink] = useState();
  const [webinarDesc, setWebinarDesc] = useState();
  const [document, setDocument] = useState();

  // 5 SECTION
  const [dealPoints, setDealPoints] = useState([
    {
      id: 1,
      heading: "",
      description: "",
    },
  ]);

  // 6 SECTION
  const [legalName, setLegalName] = useState();
  const [financingPurpose, setFinancingPurpose] = useState();
  const [BorrowerPurchaseStage, setBorrowerPurchaseStage] = useState();

  const [guarantor, setGuarantor] = useState([
    {
      id: 1,
      guarantor_full_name: "",
      guarantor_email: "",
      guarantor_address: "",
      estimated_credit_score: "",
      status: "",
      property_sales_in_last_3_years: "",
      rental_properties_currently_owned: "",
      rental_properties_currently_owned_amount: "",
    },
  ]);

  // 7 SECTION
  const [teams, setTeams] = useState([
    {
      id: 1,
      team_description: "",
      members: [
        {
          id: Math.floor(Math.random() * 100000),
          name: "",
          role: "",
          location: "",
          phone: "",
          email: "",
          description: "",
          image: "",
        },
      ],
    },
  ]);

  // 8 SECTION
  const [additionalContacts, setAdditionalContacts] = useState([]);
  const [primaryContact, setPrimaryContact] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [socialInfo, setSocialInfo] = useState({
    website_link: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    facebook_business: "",
  });

  //9 SECTION
  const [desclaimerDoc, setDesclaimerDoc] = useState();

  const [navActive, setNavActive] = useState(6);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    setNavActive(navdata);
  }, []);
  const buttonData = [
    {icon: `${save}`, value: "Save Draft without Publishing"},
    {icon: `${eye}`, value: "Lender’s Preview"},
  ];

  const [loadData, setLoanData] = useState({
    asset_id: selectedData?._id,
    general: {
      deal_properties: {
        deal_name: "",
        financing_purpose: "",
        purchase_stage: "",
        status: "",
      },
      custom_links: {},
      cover_images: {
        images: [],
        images_url: [],
      },
    },
    load_details: {
      finacial_details: {
        requested_purchase_financing: {
          to: "",
          from: "",
          purchase_price: "",
        },
        loan_term: "",
        desired_closing_date: "",
        offering_type: "",
        loan_type: "",
        repayment_strategy: "",
        additional_financing: {
          additional_financing_types: "",
          additional_financing_amount: "",
        },
        upload_financial_documents: {
          images: [],
        },
      },
    },
    lender: {
      lenders_list: [
        {lender_name: "", apr: "", term: "", max_loan: "", match: ""},
        {lender_name: "", apr: "", term: "", max_loan: "", match: ""},
      ],
    },
    business_plan: {
      webinar_registration: {
        link: "",
        description: "",
      },
      upload_financial_documents: {
        images: [],
      },
    },
    key_deal_points: {
      deal_points_list: [
        {
          heading: "",
          description: "",
        },
      ],
    },
    borrower: {
      legal_investor_list: {
        legal_name: "",
        financing_purpose: "",
        purchase_stage: "",
      },
      guarantor_investor_list: {
        guarantor_full_name: "",
        guarantor_email: "",
        guarantor_address: "",
        estimated_credit_score: "",
        status: "",
        property_sales_in_last_3_years: "",
        rental_properties_currently_owned: "",
      },
    },
    team: {
      description: "",
      member: {
        name: "",
        role: "",
        location: "",
        phone: "",
        email: "",
        team_desc: "",
      },
    },
    contacts: {
      primary_contact: {
        name: "",
        email: "",
        phone: "",
      },
      additional_contact: {
        name: "",
        email: "",
        phone: "",
      },
      website_links: [],
      social_networks: {
        facebook: "",
        twitter: "",
        instagram: "",
        linkedin: "",
        youtube: "",
      },
    },
    disclaimer: {
      images: [],
    },
  });

  useEffect(() => {
    setLoanData((loadData) => ({
      ...loadData,
      general: {
        ...loadData.general,
        deal_properties: {
          ...loadData.general.deal_properties,
          deal_name: dealName,
          financing_purpose: financialPurpose,
          purchase_stage: purchaseStage,
          status: status,
        },
        custom_links: customLink,
        cover_images: coverImage,
      },
      load_details: {
        ...loadData.load_details,
        finacial_details: {
          ...loadData.load_details.finacial_details,
          requested_purchase_financing: {
            ...loadData.load_details.finacial_details.requested_purchase_financing,
            to: price.to,
            from: price.from,
            purchase_price: `$${purchasePrice}`,
          },
          loan_term: longTerm,
          desired_closing_date: closingDate,
          offering_type: offering,
          loan_type: loanType,
          repayment_strategy: rePayment,
          additional_financing: {
            additional_financing_types: additionalType,
            additional_financing_amount: additionalAmount,
          },
          upload_financial_documents: {
            ...loadData.load_details.finacial_details.upload_financial_documents,
            images: finacialDocument,
          },
        },
      },
      lender: {
        ...loadData.lender,
        lenders_list: selectedLenders,
      },
      business_plan: {
        ...loadData.business_plan,
        webinar_registration: {
          link: webinarLink,
          description: webinarDesc,
        },
        upload_financial_documents: {
          ...loadData.business_plan.upload_financial_documents,
          images: document,
        },
      },
      key_deal_points: {
        ...loadData.key_deal_points,
        deal_points_list: dealPoints,
      },
      borrower: {
        ...loadData.borrower,
        legal_investor_list: {
          ...loadData.borrower.legal_investor_list,
          legal_name: legalName,
          financing_purpose: financingPurpose,
          purchase_stage: BorrowerPurchaseStage,
        },
        guarantor_investor_list: guarantor,
      },
      team: teams,
      contacts: {
        ...loadData.contacts,
        primary_contact: {
          ...loadData.contacts.primary_contact,
          name: primaryContact.name,
          email: primaryContact.email,
          phone: primaryContact.phone,
        },
        additional_contact: additionalContacts,
        website_links: socialInfo.website_link,
        social_networks: {
          ...loadData.contacts.social_networks,
          facebook: socialInfo.facebook,
          twitter: socialInfo.twitter,
          linkedin: socialInfo.linkedin,
          facebook_business: socialInfo.facebook_business,
        },
      },
      disclaimer: {
        ...loadData.disclaimer,
        images: desclaimerDoc,
      },
    }));
  }, [
    dealName,
    financialPurpose,
    purchaseStage,
    status,
    price,
    purchasePrice,
    selectedLenders,
    longTerm,
    closingDate,
    offering,
    loanType,
    rePayment,
    additionalAmount,
    additionalType,
    finacialDocument,
    webinarDesc,
    webinarLink,
    document,
    dealPoints,
    guarantor,
    teams,
    primaryContact,
    additionalContacts,
    socialInfo,
    desclaimerDoc,
    customLink,
    coverImage,
  ]);

  const checkBoxDivRef = useRef(null);
  const scrollToBottom = () => {
    checkBoxDivRef.current.scrollIntoView({behavior: "smooth"});
  };

  const handlePostLoanSetup = async () => {
    function flattenObject(obj, prefix = "") {
      return Object.keys(obj).reduce((acc, key) => {
        const propName = prefix ? `${prefix}[${key}]` : key;
        if ((typeof obj[key] === "object" && obj[key] !== null) || Array.isArray(obj[key])) {
          if (obj[key] instanceof File) {
            acc[propName] = obj[key];
          } else {
            Object.assign(acc, flattenObject(obj[key], propName));
          }
        } else {
          acc[propName] = obj[key];
        }
        return acc;
      }, {});
    }
    function objectToFormData(obj) {
      const formData = new FormData();
      for (const key in obj) {
        if (obj[key] instanceof File) {
          formData.append(key, obj[key]);
        } else {
          formData.append(key, obj[key]);
        }
      }
      return formData;
    }
    const flattenedData = flattenObject(loadData);
    const formData = objectToFormData(flattenedData);

    if (isChecked) {
      try {
        const res = await PostLoanSetupData(idToken, formData).then((res) => {
          dispatch(returnMessages(res.message, 200));
        });
      } catch (error) {
 

        let validation = error?.response?.data?.error?._message;
        let msg = error?.response?.data?.message;
        dispatch(returnErrors(validation || msg, 500));

        if (msg === "Loan already exists") {
          navigate("/asset-discovery");
        }
      }
    } else {
      dispatch(returnErrors("Please accept term and condition", 500));
      scrollToBottom();
    }
  };

  useEffect(() => {
    if (isChecked === true) {
      window.scrollTo(0, 0);
    }
  }, [isChecked]);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  return (
    <div className="deals-room-cente asset-center-con">
      <div className="SetupLoanReq-deals-room-center-header">
        <p>Loan Setup</p>
        <div className="SetupLoanReq-deals-room-header-button">
          {navActive !== 11 ? (
            <>
              <div
                className="SetupLoanReq-header-button-first"
                onClick={goBack}
              >
                <img
                  src={cross}
                  alt="icon"
                />
                <p>Cancel</p>
              </div>

              {buttonData.map(({icon, value}) => {
                return (
                  <Button
                    icon={icon}
                    value={value}
                  />
                );
              })}
              <div
                className="header-button"
                onClick={() => setNavActive(navActive === 1 ? 1 : navActive - 1)}
              >
                <img
                  src={left}
                  alt="icon"
                />
                <p>Back</p>
              </div>

              {navActive === 10 ? (
                <div
                  onClick={handlePostLoanSetup}
                  className="header-button-last"
                >
                  <p>Submit Proposal</p>
                  <img
                    src={sp}
                    alt="icon"
                  />
                </div>
              ) : (
                <div
                  className="header-button-last"
                  onClick={() => setNavActive(navActive + 1)}
                >
                  <p>Next</p>
                  <img
                    src={right}
                    alt="icon"
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div
                className="header-button-first"
                onClick={goBack}
              >
                <img
                  src={cross}
                  alt="icon"
                />
                <p>Call Off Loan Request</p>
              </div>

              <Button
                icon={edit}
                value={"Edit Request Details"}
              />
            </>
          )}
        </div>
      </div>
      <div className="deals-room-container loan-req-container">
        {navActive != 11 && (
          <div className="SetupLoanReq-deals-room-nav loan-setup-nav">
            <span
              className={navActive === 1 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(1);
              }}
            >
              General
            </span>
            <span
              className={navActive === 2 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(2);
              }}
            >
              Loan Details
            </span>
            <span
              className={navActive === 3 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(3);
              }}
            >
              Lender
            </span>
            <span
              className={navActive === 4 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(4);
              }}
            >
              Business Plan
            </span>
            <span
              className={navActive === 5 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(5);
              }}
            >
              Key Deal Points
            </span>
            <span
              className={navActive === 6 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(6);
              }}
            >
              Borrower
            </span>
            <span
              className={navActive === 7 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(7);
              }}
            >
              Team
            </span>
            <span
              className={navActive === 8 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(8);
              }}
            >
              Contacts
            </span>
            <span
              className={navActive === 9 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(9);
              }}
            >
              Disclaimer
            </span>
            <span
              className={navActive === 10 && "deals-room-nav-active"}
              onClick={() => {
                setNavActive(10);
              }}
            >
              Summary
            </span>
          </div>
        )}
        {navActive === 1 && (
          <General
            dealNameList={dealNameList}
            dealName={dealName}
            setDealName={setDealName}
            dealNameDA={dealNameDA}
            setDealNameDA={setDealNameDA}
            customLink={customLink}
            financialPurpose={financialPurpose}
            setFinancialPurposeDA={setFinancialPurposeDA}
            setFinancialPurpose={setFinancialPurpose}
            financialPurposeDA={financialPurposeDA}
            financialPurposeList={financialPurposeList}
            purchaseStage={purchaseStage}
            setPurchaseStage={setPurchaseStage}
            purchaseStageList={purchaseStageList}
            purchaseStageDA={purchaseStageDA}
            setPurchaseStageDA={setPurchaseStageDA}
            status={status}
            setStatus={setStatus}
            statusList={statusList}
            statusDA={statusDA}
            SetStatusDA={SetStatusDA}
            coverImage={coverImage}
            setCoverImage={setCoverImage}
            setCustomLink={setCustomLink}
            setCoverImageLink={setCoverImageLink}
            coverImageLink={coverImageLink}
          />
        )}

        {navActive === 2 && (
          <LoanDetails
            price={price}
            setPrice={setPrice}
            purchasePrice={purchasePrice}
            setPurchasePrice={setPurchasePrice}
            longTerm={longTerm}
            setLongTerm={setLongTerm}
            setClosingDate={setClosingDate}
            closingDate={closingDate}
            offering={offering}
            setOffering={setOffering}
            offeringList={offeringList}
            offeringDA={offeringDA}
            setOfferingDA={setOfferingDA}
            setLoanType={setLoanType}
            setAdditionalType={setAdditionalType}
            setRepayment={setRepayment}
            setAdditionalAmount={setAdditionalAmount}
            additionalAmount={additionalAmount}
            finacialDocument={finacialDocument}
            setFinancialDocument={setFinancialDocument}
          />
        )}
        {navActive === 3 && (
          <Lender
            setSelectedLenders={setSelectedLenders}
            selectedLenders={selectedLenders}
          />
        )}
        {navActive === 4 && (
          <BusinnesPlan
            setWebinarDesc={setWebinarDesc}
            setWebinarLink={setWebinarLink}
            webinarLink={webinarLink}
            document={document}
            setDocument={setDocument}
            webinarDesc={webinarDesc}
          />
        )}
        {navActive === 5 && (
          <KeyDeal
            dealPoints={dealPoints}
            setDealPoints={setDealPoints}
          />
        )}
        {navActive === 6 && (
          <Borrower
            setLegalName={setLegalName}
            financingPurpose={financingPurpose}
            setFinancingPurpose={setFinancingPurpose}
            setBorrowerPurchaseStage={setBorrowerPurchaseStage}
            BorrowerPurchaseStage={BorrowerPurchaseStage}
            guarantor={guarantor}
            setGuarantor={setGuarantor}
            legalName={legalName}
          />
        )}
        {navActive === 7 && (
          <Team
            teams={teams}
            setTeams={setTeams}
          />
        )}
        {navActive === 8 && (
          <Contacts
            additionalContacts={additionalContacts}
            setAdditionalContacts={setAdditionalContacts}
            setPrimaryContact={setPrimaryContact}
            primaryContact={primaryContact}
            setSocialInfo={setSocialInfo}
            socialInfo={socialInfo}
          />
        )}
        {navActive === 9 && <Disclaimer setDesclaimerDoc={setDesclaimerDoc} />}
        {navActive === 10 && (
          <SummarySyndicate
            path={"/newloanreq"}
            setNavActive={setNavActive}
            loadData={loadData}
            handleCheckboxChange={handleCheckboxChange}
            isChecked={isChecked}
            checkBoxDivRef={checkBoxDivRef}
          />
        )}
        {navActive === 11 && <SubmitProposal handleMenuClicked={handleMenuClicked} />}
        {/* <General /> */}
      </div>
    </div>
  );
};

export default SetupLoanReq;

const SubmitProposal = ({handleMenuClicked}) => {
  return (
    <div className="sp">
      <div className="sp_text">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <g clip-path="url(#clip0_13390_372443)">
            <path
              d="M20 0C8.97231 0 0 8.97154 0 20C0 31.0285 8.97231 40 20 40C31.0277 40 40 31.0285 40 20C40 8.97154 31.0277 0 20 0ZM20 38.4615C9.82077 38.4615 1.53846 30.1792 1.53846 20C1.53846 9.82077 9.82077 1.53846 20 1.53846C30.1792 1.53846 38.4615 9.82077 38.4615 20C38.4615 30.1792 30.1792 38.4615 20 38.4615Z"
              fill="#009639"
            />
            <path
              d="M29.4247 11.7969L17.6024 25.0969L10.4801 19.3992C10.1493 19.1338 9.66391 19.1884 9.39929 19.5192C9.13391 19.8515 9.18775 20.3354 9.51929 20.6008L17.2116 26.7546C17.3531 26.8677 17.5231 26.9231 17.6924 26.9231C17.9047 26.9231 18.1162 26.8354 18.2678 26.6646L30.5754 12.8185C30.8578 12.5015 30.8293 12.0146 30.5116 11.7331C30.1931 11.45 29.7078 11.4785 29.4247 11.7969Z"
              fill="#009639"
            />
          </g>
          <defs>
            <clipPath id="clip0_13390_372443">
              <rect
                width="40"
                height="40"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
        <p>
          The Request was successfuly created and sent to Lenders<br></br> interested in helping you.
        </p>
      </div>

      <div className="sp_btns">
        <Button
          icon={eye}
          value={"Lender's Preview"}
        />

        <div
          onClick={() => handleMenuClicked(13)}
          style={{marginTop: "0px"}}
          className="header-button-last"
        >
          <p>Track Updates</p>
        </div>
      </div>
    </div>
  );
};

export const General = ({
  dealNameList,
  dealName,
  dealNameDA,
  setDealName,
  setDealNameDA,
  financialPurpose,
  setFinancialPurpose,
  financialPurposeDA,
  setFinancialPurposeDA,
  financialPurposeList,
  purchaseStage,
  setPurchaseStage,
  purchaseStageDA,
  setPurchaseStageDA,
  purchaseStageList,
  status,
  setStatus,
  statusDA,
  SetStatusDA,
  statusList,
  coverImage,
  setCoverImage,
  setCustomLink,
  setCoverImageLink,
  coverImageLink,
  customLink,
}) => {
  const [isClicked, setIsClicked] = useState(1);

  const [previewURL, setPreviewURL] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }
    const allowedTypes = ["image/jpeg", "image/png", "image/bmp", "image/tiff"];
    if (!allowedTypes.includes(file.type)) {
      alert("Please select a JPG, PNG, BMP, or TIFF file.");
      return;
    }
    const maxSizeMB = 20;
    if (file.size > maxSizeMB * 1024 * 1024) {
      alert("File size exceeds the maximum allowed size of 20 MB.");
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewURL(reader.result);
    };
    reader.readAsDataURL(file);

    const formData = new FormData();
    formData.append("cover_image", file);
    setCoverImage(file);
  };

  return (
    <div>
      <div className="financial-container loan-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Deal properties</p>
        </div>

        <div className="setup-general">
          <DropDown
            label="Deal Name"
            data={dealNameList}
            selectedData={dealName}
            setSelectedData={setDealName}
            dropdownState={dealNameDA}
            dropdownSetter={setDealNameDA}
          />

          <div className="genral-input">
            <DropDown
              label="Financing Purpose"
              data={financialPurposeList}
              selectedData={financialPurpose}
              setSelectedData={setFinancialPurpose}
              dropdownState={financialPurposeDA}
              dropdownSetter={setFinancialPurposeDA}
            />
            <DropDown
              label="Purchase Stage"
              data={purchaseStageList}
              selectedData={purchaseStage}
              setSelectedData={setPurchaseStage}
              dropdownState={purchaseStageDA}
              dropdownSetter={setPurchaseStageDA}
            />
            <DropDown
              label="Status"
              data={statusList}
              selectedData={status}
              setSelectedData={setStatus}
              dropdownState={statusDA}
              dropdownSetter={SetStatusDA}
            />
          </div>
        </div>
      </div>

      <div className="financial-container loan-container">
        <div className="first-inner-box">
          <img
            src={link}
            alt="download"
          />
          <p>Custom link</p>
        </div>

        <div className="setup-general">
          <InputBox
            type={"text"}
            placeholder={"app.lilypads.com/crowdfunding/syndicates/21f621f61f632f4126f"}
            onChange={(e) => setCustomLink(e.target.value)}
            value={customLink}
          />
        </div>
      </div>

      <div className="financial-container upload-image loan-container">
        <div className="first-inner-box">
          <img
            src={cover}
            alt="download"
          />
          <p>Cover Image</p>
        </div>

        <div className="switch-btn switch-syndicate">
          <span
            onClick={() => {
              setIsClicked(1);
            }}
            className={isClicked === 1 && "back"}
          >
            Upload document
          </span>
          <span
            onClick={() => {
              setIsClicked(2);
            }}
            className={isClicked === 2 && "back"}
          >
            Custom Link
          </span>
        </div>
        {isClicked === 1 && (
          <div className="big-box">
            <div className="inner-big-box">
              <div className="preview-box"></div>
              <div className="upload-btn-wrapper">
                <button className="btn">
                  {previewURL ? (
                    <img
                      src={previewURL}
                      alt="Preview"
                      width="10%"
                    />
                  ) : (
                    <img
                      src={uploadIcon}
                      alt=""
                    />
                  )}
                  Drag and drop files or click
                </button>
                <input
                  type="file"
                  name="myfile"
                  onChange={handleFileChange}
                  accept="image/jpeg, image/png, image/bmp, image/tiff"
                />
              </div>
              <p className="second-para">Supported file formats: JPEG, PNG, BMP, TIFF, max 20 MB.</p>
            </div>
          </div>
        )}
        {isClicked === 2 && (
          <InputBox
            label={"IMAGE LINK"}
            svgPath={link}
            placeholder={"Custom Link"}
            conCLass={"link-inp"}
            onChange={(e) => setCoverImageLink(e.target.value)}
            value={coverImageLink}
          />
        )}
      </div>

      <div className="financial-container loan-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt=""
          />
          <p>Manager and collaborators</p>
        </div>

        <p className="admin">ADMIN</p>

        <div className="inner-manager">
          <div className="inner-manager-flex">
            <div className="user-detail">
              <img
                src={boypic}
                alt="boypic"
              />
            </div>

            <div className="name-you">
              <p className="mark">Mark Barker</p>
              <p className="you">You</p>
            </div>
          </div>

          <button>Edit</button>
        </div>

        <div className="collaborators">
          <span>Collaborators</span>
          <span>(3)</span>
        </div>

        <h3 className="add-people">Add people</h3>

        <div className="people">
          <input
            type="text"
            placeholder="Name or email address"
            value=""
          />
          <input
            className="second-input"
            type="text"
            placeholder="Permission : Select"
            value=""
          />
          <button>Add</button>
        </div>

        <h3 className="notified">After people will be notified by email they have to confirm invitation.</h3>

        <div className="repeat">
          <div className="inner-manager test-manager">
            <div className="inner-manager-flex">
              <div className="user-detail">
                <img
                  src={boypic}
                  alt="boypic"
                />
              </div>

              <div className="name-you">
                <p className="mark">Mark Barker</p>
                <p className="you">You</p>
              </div>
            </div>

            <div className="temp">
              <img
                src={remove}
                alt="remove"
              />
              <span>Remove</span>
            </div>
          </div>

          <div className="user-box">
            <div style={{marginLeft: "40px"}}>
              <div>
                {/* <input type="checkbox" />  */}
                {/* <Checkbox label={"Can invite others"} /> */}
                {/* <span></span> */}
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Can invite others"
                  />
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="View full documentation"
                  />
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Subscribe for updates"
                  />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const LoanDetails = ({
  price,
  setPrice,
  purchasePrice,
  setPurchasePrice,
  setLongTerm,
  setClosingDate,
  closingDate,
  offering,
  setOffering,
  offeringDA,
  setOfferingDA,
  offeringList,
  setLoanType,
  setRepayment,
  setAdditionalType,
  setAdditionalAmount,
  setFinancialDocument,
  finacialDocument,
  longTerm,
  additionalAmount,
}) => {
  const [countryList, setCountrylist] = useState();
  const [countryOfCitizenship, setCountryOfCitizenship] = useState("Preferred Equity");
  const [countryOfCitizenshipDA, setCountryOfCitizenshipDA] = useState(false);
  const [purchageList, setPurchageList] = useState(["Super senor debt", "Senior debt", "Second lien debt", "mezzanine debt", "Junior debt"]);
  const [selectedPurchageList, setSelectedPurchageList] = useState("Senior debt");
  const [purchageListDa, setPurchageListDa] = useState(false);
  const [statusList, setStatusList] = useState(["Yes", "No"]);
  const [selectedStatusList, setSelectedStatusList] = useState("Yes");
  const [statusListDa, setStatusListDa] = useState(false);
  const [loanTypeList, setLoanTypeList] = useState(["Sell the Property", "Refinance the loan", "Sell the Property", "Ohter (Specify in Description)"]);
  const [selectedLoanTypeList, setSelectedLoanTypeList] = useState("Sell the Property");
  const [loanTypeListDa, setLoanTypeListDa] = useState(false);

  const [selectedFileName, setSelectedFileName] = useState("");

  const preventDefault = (event) => {
    event.preventDefault();
  };

  const handleImageDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileSelection(file);
    }
  };

  const handleImageInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileSelection(file);
    }
  };

  const handleFileSelection = (file) => {
    setSelectedFileName(file.name);

    // Create a new FormData object
    const formData = new FormData();
    formData.append("pdfFile", file);

    // Set the FormData object in state using setFinancialDocument
    setFinancialDocument(file);
  };


  return (
    <div className="loan-details-container">
      <div>
        {" "}
        <div className="financial-container loan-container">
          <div className="first-inner-box">
            <img
              src={store}
              alt=""
            />
            <span>Financial details</span>
          </div>

          <div className="loan-details-inner">
            <div className="loan-req-in-first">
              <div>
                <div className="third-inner loan-req-in">
                  <div className="third-inner-left">
                    <p className="slider-para">Requested Purchase Financing</p>
                    <div className="slider_container">
                      <div className="top_div inputFlexLine">
                        <p>$</p>
                        <span>{price?.from}</span>
                      </div>
                      <Slider
                        defaultValue={price?.from}
                        aria-label="Price Range"
                        // valueLabelDisplay="auto"
                        step={5000}
                        min={0}
                        max={300000}
                        onChange={(event, value) => {
                          setPrice((prev) => ({
                            ...prev,
                            from: value,
                          }));
                        }}
                        value={price.from}
                        valueLabelDisplay="on"
                      />
                    </div>
                    <div className="slider_container">
                      <div className="top_div inputFlexLine">
                        <p>$</p>
                        <span>{price?.to}</span>
                      </div>
                      <Slider
                        defaultValue={price?.to}
                        aria-label="Price Range"
                        step={5000}
                        min={0}
                        max={300000}
                        onChange={(event, value) => {
                          setPrice((prev) => ({
                            ...prev,
                            to: value,
                          }));
                        }}
                        value={price.to}
                        valueLabelDisplay="on"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <img
                  src={dollar}
                  alt=""
                />
                <span>
                  Purchase Price: <span>${purchasePrice}</span>
                </span>
              </div>
            </div>

            <div className="loan-req-in-second">
              <div>
                <InputBox
                  label={"Loan Term"}
                  type={"text"}
                  classCon={"inp-"}
                  onChange={(e) => setLongTerm(e.target.value)}
                  value={longTerm}
                />
                <span>Minimum loan duration is 3 months</span>
              </div>
              <InputBox
                label={"Desired Closing Date"}
                type="date-range"
                onChange={(e) => setClosingDate(e.target.value)}
                value={closingDate}
              />
            </div>

            <div className="loan-req-in-third">
              <div>
                <DropDown
                  label="Offering Type"
                  data={offeringList}
                  selectedData={offering}
                  setSelectedData={setOffering}
                  dropdownState={offeringDA}
                  dropdownSetter={setOfferingDA}
                />

                <DropDown
                  label="Loan type"
                  data={purchageList}
                  selectedData={selectedPurchageList}
                  setSelectedData={setLoanType}
                  dropdownState={purchageListDa}
                  dropdownSetter={setPurchageListDa}
                />
                <DropDown
                  label=" Repayment Strategy"
                  data={loanTypeList}
                  selectedData={selectedLoanTypeList}
                  setSelectedData={setRepayment}
                  dropdownState={loanTypeListDa}
                  dropdownSetter={setLoanTypeListDa}
                />
              </div>
              <div className="lender">
                <img
                  src={idea}
                  alt=""
                />
                <span>
                  <span>45 Lenders </span>from your Network interested in Senior debt Loans.from your Network interested in Senior debt Loans.
                </span>
              </div>
            </div>

            <span className="texta-op">Optional</span>
          </div>
        </div>
      </div>
      <div>
        <div className="financial-container add-fin loan-container">
          <div className="first-inner-box">
            <img
              src={store}
              alt=""
            />
            <span>Additional Financing</span>
          </div>

          <div className="loan-details-inner">
            <div className="loan-d-i">
              <DropDown
                label="Additional Financing types"
                data={statusList}
                selectedData={selectedStatusList}
                setSelectedData={setAdditionalType}
                dropdownState={statusListDa}
                dropdownSetter={setStatusListDa}
              />
              <InputBox
                label={"Additional Financing Amount"}
                type={"text "}
                value={additionalAmount}
                onChange={(e) => setAdditionalAmount(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <span className="upload-doc">Upload Financial Documents</span>
        <div className="upload-doc-body">
          <div
            className="select-doc"
            onDrop={handleImageDrop}
            onDragOver={preventDefault}
          >
            <img
              src={uploadIcon}
              alt=""
            />
            <div className="upload-btn-wrapper">
              <button className="btn">{selectedFileName ? selectedFileName : "Drag and drop files or click"}</button>
              <input
                type="file"
                name="myfile"
                accept="application/pdf"
                onChange={handleImageInputChange}
              />
            </div>
            <p>Supported file format: PDF Max file size 250 MB</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Lender = ({selectedLenders, setSelectedLenders}) => {
  const [pageValue, setPageValue] = useState(5);
  const [startVal, setStartVal] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  const [lenderData, setLenderData] = useState([
    {
      profile: man,
      name: "Tommy Pride",
      role: "Tommy Pride",
      apr: "5.95–10.88%",
      term: "1–5 years",
      max_loan: "$150,000",
      match: "96%",
    },
    {
      profile: man,
      name: "Alice Johnson",
      role: "Loan Officer",
      apr: "6.25–11.50%",
      term: "2–7 years",
      max_loan: "$180,000",
      match: "92%",
    },
    {
      profile: man,
      name: "John Smith",
      role: "Senior Loan Officer",
      apr: "5.75–10.25%",
      term: "1–4 years",
      max_loan: "$130,000",
      match: "91%",
    },
    {
      profile: man,
      name: "Emily Brown",
      role: "Financial Advisor",
      apr: "6.0–11.75%",
      term: "2–6 years",
      max_loan: "$160,000",
      match: "94%",
    },
    {
      profile: man,
      name: "Michael Thompson",
      role: "Mortgage Specialist",
      apr: "5.5–10.0%",
      term: "1–5 years",
      max_loan: "$140,000",
      match: "93%",
    },
    {
      profile: man,
      name: "Jessica Davis",
      role: "Loan Processor",
      apr: "6.25–11.25%",
      term: "2–7 years",
      max_loan: "$170,000",
      match: "95%",
    },
    {
      profile: man,
      name: "William Rodriguez",
      role: "Underwriter",
      apr: "5.75–10.5%",
      term: "1–4 years",
      max_loan: "$120,000",
      match: "90%",
    },
    {
      profile: man,
      name: "Samantha Martinez",
      role: "Credit Analyst",
      apr: "5.95–10.88%",
      term: "2–6 years",
      max_loan: "$150,000",
      match: "96%",
    },
    {
      profile: man,
      name: "David Wilson",
      role: "Loan Originator",
      apr: "6.25–11.0%",
      term: "1–5 years",
      max_loan: "$130,000",
      match: "92%",
    },
    {
      profile: man,
      name: "Olivia Anderson",
      role: "Loan Underwriter",
      apr: "5.5–10.25%",
      term: "2–7 years",
      max_loan: "$180,000",
      match: "93%",
    },
    {
      profile: man,
      name: "James Thomas",
      role: "Financial Analyst",
      apr: "5.95–11.5%",
      term: "1–4 years",
      max_loan: "$160,000",
      match: "94%",
    },
    {
      profile: man,
      name: "Elizabeth Garcia",
      role: "Loan Consultant",
      apr: "5.75–10.75%",
      term: "2–6 years",
      max_loan: "$170,000",
      match: "95%",
    },
    {
      profile: man,
      name: "Daniel Martinez",
      role: "Senior Loan Processor",
      apr: "6.0–11.25%",
      term: "1–5 years",
      max_loan: "$140,000",
      match: "91%",
    },
    {
      profile: man,
      name: "Emma Hernandez",
      role: "Loan Coordinator",
      apr: "5.75–10.5%",
      term: "2–7 years",
      max_loan: "$190,000",
      match: "93%",
    },
    {
      profile: man,
      name: "Christopher Young",
      role: "Loan Officer Assistant",
      apr: "5.95–11.0%",
      term: "1–4 years",
      max_loan: "$110,000",
      match: "90%",
    },
    {
      profile: man,
      name: "Madison King",
      role: "Loan Closer",
      apr: "6.25–11.5%",
      term: "2–6 years",
      max_loan: "$200,000",
      match: "96%",
    },
    {
      profile: man,
      name: "Liam Lopez",
      role: "Loan Processor",
      apr: "5.95–10.88%",
      term: "1–5 years",
      max_loan: "$150,000",
      match: "96%",
    },
    {
      profile: man,
      name: "Sophia Carter",
      role: "Financial Advisor",
      apr: "6.25–11.50%",
      term: "2–7 years",
      max_loan: "$180,000",
      match: "92%",
    },
    {
      profile: man,
      name: "Benjamin Baker",
      role: "Senior Loan Officer",
      apr: "5.75–10.25%",
      term: "1–4 years",
      max_loan: "$130,000",
      match: "91%",
    },
    {
      profile: man,
      name: "Grace White",
      role: "Mortgage Specialist",
      apr: "6.0–11.75%",
      term: "2–6 years",
      max_loan: "$160,000",
      match: "94%",
    },
  ]);

  const [data, setData] = useState([
    {
      profile: man,
      name: "Tommy Pride",
      role: "Tommy Pride",
      apr: "5.95–10.88%",
      term: "1–5 years",
      max_loan: "$150,000",
      match: "96%",
    },
    {
      profile: man,
      name: "Alice Johnson",
      role: "Loan Officer",
      apr: "6.25–11.50%",
      term: "2–7 years",
      max_loan: "$180,000",
      match: "92%",
    },
    {
      profile: man,
      name: "John Smith",
      role: "Senior Loan Officer",
      apr: "5.75–10.25%",
      term: "1–4 years",
      max_loan: "$130,000",
      match: "91%",
    },
    {
      profile: man,
      name: "Emily Brown",
      role: "Financial Advisor",
      apr: "6.0–11.75%",
      term: "2–6 years",
      max_loan: "$160,000",
      match: "94%",
    },
    {
      profile: man,
      name: "Michael Thompson",
      role: "Mortgage Specialist",
      apr: "5.5–10.0%",
      term: "1–5 years",
      max_loan: "$140,000",
      match: "93%",
    },
    {
      profile: man,
      name: "Jessica Davis",
      role: "Loan Processor",
      apr: "6.25–11.25%",
      term: "2–7 years",
      max_loan: "$170,000",
      match: "95%",
    },
    {
      profile: man,
      name: "William Rodriguez",
      role: "Underwriter",
      apr: "5.75–10.5%",
      term: "1–4 years",
      max_loan: "$120,000",
      match: "90%",
    },
    {
      profile: man,
      name: "Samantha Martinez",
      role: "Credit Analyst",
      apr: "5.95–10.88%",
      term: "2–6 years",
      max_loan: "$150,000",
      match: "96%",
    },
    {
      profile: man,
      name: "David Wilson",
      role: "Loan Originator",
      apr: "6.25–11.0%",
      term: "1–5 years",
      max_loan: "$130,000",
      match: "92%",
    },
    {
      profile: man,
      name: "Olivia Anderson",
      role: "Loan Underwriter",
      apr: "5.5–10.25%",
      term: "2–7 years",
      max_loan: "$180,000",
      match: "93%",
    },
    {
      profile: man,
      name: "James Thomas",
      role: "Financial Analyst",
      apr: "5.95–11.5%",
      term: "1–4 years",
      max_loan: "$160,000",
      match: "94%",
    },
    {
      profile: man,
      name: "Elizabeth Garcia",
      role: "Loan Consultant",
      apr: "5.75–10.75%",
      term: "2–6 years",
      max_loan: "$170,000",
      match: "95%",
    },
    {
      profile: man,
      name: "Daniel Martinez",
      role: "Senior Loan Processor",
      apr: "6.0–11.25%",
      term: "1–5 years",
      max_loan: "$140,000",
      match: "91%",
    },
    {
      profile: man,
      name: "Emma Hernandez",
      role: "Loan Coordinator",
      apr: "5.75–10.5%",
      term: "2–7 years",
      max_loan: "$190,000",
      match: "93%",
    },
    {
      profile: man,
      name: "Christopher Young",
      role: "Loan Officer Assistant",
      apr: "5.95–11.0%",
      term: "1–4 years",
      max_loan: "$110,000",
      match: "90%",
    },
    {
      profile: man,
      name: "Madison King",
      role: "Loan Closer",
      apr: "6.25–11.5%",
      term: "2–6 years",
      max_loan: "$200,000",
      match: "96%",
    },
    {
      profile: man,
      name: "Liam Lopez",
      role: "Loan Processor",
      apr: "5.95–10.88%",
      term: "1–5 years",
      max_loan: "$150,000",
      match: "96%",
    },
    {
      profile: man,
      name: "Sophia Carter",
      role: "Financial Advisor",
      apr: "6.25–11.50%",
      term: "2–7 years",
      max_loan: "$180,000",
      match: "92%",
    },
    {
      profile: man,
      name: "Benjamin Baker",
      role: "Senior Loan Officer",
      apr: "5.75–10.25%",
      term: "1–4 years",
      max_loan: "$130,000",
      match: "91%",
    },
    {
      profile: man,
      name: "Grace White",
      role: "Mortgage Specialist",
      apr: "6.0–11.75%",
      term: "2–6 years",
      max_loan: "$160,000",
      match: "94%",
    },
  ]);

  useEffect(() => {
    const updatedLenderData = lenderData.slice(startVal, pageValue);
    setData(updatedLenderData);
  }, [pageValue, startVal, lenderData]);

  const handleTablePage = (type) => {
    if (type === "next") {
      if (pageValue === lenderData.length) {
        alert("last page");
      } else {
        setPageValue(pageValue + 5);
        setStartVal(startVal + 5);
        setPageNo(pageNo + 1);
      }
    }
    if (type === "prev") {
      if (pageValue === 5) {
        alert("first page");
      } else {
        setPageValue(pageValue - 5);
        setStartVal(startVal - 5);
        setPageNo(pageNo - 1);
      }
    }
  };

  const handleLenderSelection = (item) => {
    const index = selectedLenders.findIndex((lender) => lender.name === item.name);
    if (index === -1) {
      setSelectedLenders([...selectedLenders, item]);
    } else {
      const updatedLenders = [...selectedLenders];
      updatedLenders.splice(index, 1);
      setSelectedLenders(updatedLenders);
    }
  };

  return (
    <div className="loan-details-container">
      <div className="financial-container loan-container">
        <div className="lender-table-wrapper">
          <div className="lender-table-heading">
            <div className="search-btn">
              <img
                src={search}
                alt=""
              />
              <input
                type="text"
                placeholder="Search"
              />
            </div>
            <div className="filter-btn">
              <img
                src={filter}
                alt=""
              />
              <span>Filters</span>
            </div>
          </div>

          <div className="lender-table">
            <div className="lender-table-header">
              <div>
                <span>Lender</span>
              </div>
              <div>
                <span>APR</span>
                <img
                  src={info}
                  alt=""
                />
              </div>
              <div>
                <span>Term</span>
              </div>
              <div>
                <span>Max Loan</span>
              </div>
              <div>
                <span>Match</span>
              </div>
            </div>
            {data.map((item, index) => (
              <LenderCard
                key={index}
                data={item}
                handleSelection={handleLenderSelection}
                isSelected={selectedLenders.some((lender) => lender.name === item.name)}
              />
            ))}

            <div className="deals-room-doc-page-nav center">
              <div className="page-nav-cont">
                <img
                  src={prevnav}
                  alt=""
                  onClick={() => handleTablePage("prev")}
                />
                <span>
                  {pageNo} of {lenderData.length / 5}
                </span>
                <img
                  src={fwdnav}
                  alt=""
                  onClick={() => handleTablePage("next")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BusinnesPlan = ({setWebinarLink, setWebinarDesc, setDocument, document, webinarLink, webinarDesc}) => {
  const [selectedFileName, setSelectedFileName] = useState("");

  const preventDefault = (event) => {
    event.preventDefault();
  };

  const handleImageDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileSelection(file);
    }
  };

  const handleImageInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileSelection(file);
    }
  };

  const handleFileSelection = (file) => {
    setSelectedFileName(file.name);

    // Create a new FormData object
    const formData = new FormData();
    formData.append("pdfFile", file);

    // Set the FormData object in state using setDocument
    setDocument(file);
  };
  return (
    <div className="loan-details-container">
      <div>
        {" "}
        <div className="financial-container loan-container">
          <div className="first-inner-box">
            <img
              src={webinar}
              alt=""
            />
            <span>Webinar Registration</span>
          </div>
          <div className="loan-details-inner">
            <InputBox
              label={"Link"}
              type={"text"}
              placeholder={"Heading Name"}
              onChange={(e) => setWebinarLink(e.target.value)}
              value={webinarLink}
            />
            {/* <InputB
              label={"Link"}
              type={"text"}
              classCon={""}
              placeholder={"Heading Name"}
              value={""}
            /> */}
            <InputBox
              label={"Description"}
              placeholder="tell more about the details"
              type={"text"}
              classCon={"text-area-p"}
              value={webinarDesc}
              onChange={(e) => setWebinarDesc(e.target.value)}
            />
          </div>
        </div>
        <div>
          <span className="upload-doc">Upload Financial Documents</span>
          <div className="financial-container loan-container">
            <div className="first-inner-box">
              <img
                src={store}
                alt=""
              />
              <span>Business plan upload</span>
            </div>

            <div className="upload-doc-body">
              <div
                className="select-doc"
                onDrop={handleImageDrop}
                onDragOver={preventDefault}
              >
                <img
                  src={uploadIcon}
                  alt=""
                />
                <div className="upload-btn-wrapper">
                  <button className="btn">{selectedFileName ? selectedFileName : "Drag and drop files or click"}</button>
                  <input
                    type="file"
                    name="myfile"
                    accept="application/pdf"
                    onChange={handleImageInputChange}
                  />
                </div>
                <p>Supported file format: PDF Max file size 250 MB</p>
              </div>
              <div className="progress-cont">
                <div className="uploading-file">
                  <div className="file-name">
                    <div>
                      <img
                        src={pdf}
                        alt=""
                      />
                      <span>Agreement B.pdf</span>
                    </div>
                    <span>1,5MB of 3,6MB</span>
                  </div>
                  <div className="progress-bar">
                    <span className="progress"></span>
                  </div>
                </div>
                <img
                  src={navclose}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const KeyDeal = ({dealPoints, setDealPoints}) => {
  const handleAddPoint = () => {
    const newId = dealPoints.length + 1;

    dealPoints.length === 15 ? alert("you can add only 15 key points.. you can remove any other") : setDealPoints([...dealPoints, {id: newId, heading: "", description: ""}]);
  };

  const handleRemovePoint = (id) => {
    setDealPoints(dealPoints.filter((point) => point.id !== id));
  };

  const handleInputChange = (id, name, value) => {
    setDealPoints(dealPoints.map((point) => (point.id === id ? {...point, [name]: value} : point)));
  };

  return (
    <div className="borrower-container">
      <div className="financial-container loan-container">
        <div className="first-inner-box">
          <img
            src={store}
            alt=""
          />
          <p>Deal points List</p>
        </div>
        {dealPoints.map((point, index) => (
          <div
            className="deal-main-wrapper"
            key={point.id}
          >
            <div className="budget">
              <span>{point.id}</span>
            </div>
            <div className="loan-details-inner">
              <InputBox
                label={"Heading"}
                type={"text"}
                classCon={""}
                name="heading"
                value={point.heading}
                onChange={(e) => handleInputChange(point.id, e.target.name, e.target.value)}
              />
              <InputBox
                label={"Description"}
                type={"text"}
                classCon={"inp-w"}
                name="description"
                value={point.description}
                onChange={(e) => handleInputChange(point.id, e.target.name, e.target.value)}
              />
            </div>
            <div className="key-deal-btn">
              <div
                className="remove-pt-btn"
                onClick={() => handleRemovePoint(point.id)}
              >
                <img
                  src={deletej}
                  alt=""
                />
                <span>Remove Point</span>
              </div>
              <PrimaryButtonSvgFirst
                name={"save"}
                svgPath={saved}
                classNames={"save-btn"}
              />
            </div>
          </div>
        ))}
        <div className="key-d-add">
          <button onClick={handleAddPoint}>
            <img
              src={plus}
              alt=""
            />
            <span>Add a new point</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export const Borrower = ({setLegalName, financingPurpose, setFinancingPurpose, BorrowerPurchaseStage, setBorrowerPurchaseStage, setGuarantor, guarantor, legalName}) => {
  const [financingPurposeList, setFinancingPurposeList] = useState(["My Next Asset", "Refinancing existing details", "Construction"]);
  const [financingPurposeDA, setFinancingPurposeDA] = useState();
  const [purchaseStageList, setPurchaseStageList] = useState(["Under Contract", "Making an Offer", "Activity Looking"]);
  const [purchaseStageDA, setPurchaseStageDA] = useState(false);
  const [statusList, setStatusList] = useState(["Active", "Pending", "Cancelled", "Closed"]);
  const [selectedStatusList, setSelectedStatusList] = useState("Active");
  const [statusListDa, setStatusListDa] = useState(false);
  const [status, setStatus] = useState();

  const handleAddPoint = () => {
    const newId = guarantor.length + 1;

    guarantor.length === 15
      ? alert("you can add only 15 key points..")
      : setGuarantor([
          ...guarantor,
          {
            id: newId,
            guarantor_full_name: "",
            guarantor_email: "",
            guarantor_address: "",
            estimated_credit_score: "",
            status: "",
            property_sales_in_last_3_years: "",
            rental_properties_currently_owned: "",
            rental_properties_currently_owned_amount: "",
          },
        ]);
  };


  const handleRemovePoint = (id) => {
    setGuarantor(guarantor.filter((point) => point.id !== id));
  };

  const handleInputChange = (id, name, value) => {
    setGuarantor(guarantor.map((point) => (point.id === id ? {...point, [name]: value} : point)));
  };

  return (
    <div className="borrower-container">
      <div>
        <div className="financial-container loan-container">
          <div className="first-inner-box">
            <img
              src={download}
              alt="download"
            />
            <p>Investor list</p>
          </div>

          <div className="browwer">
            <InputBox
              label={"legal name"}
              placeholder={"Name"}
              onChange={(e) => setLegalName(e.target.value)}
              value={legalName}
            />
            <div className="genral-input">
              <DropDown
                label="Financing Purpose"
                data={financingPurposeList}
                selectedData={financingPurpose}
                setSelectedData={setFinancingPurpose}
                dropdownState={financingPurposeDA}
                dropdownSetter={setFinancingPurposeDA}
              />
              <DropDown
                label="Purchase Stage"
                data={purchaseStageList}
                selectedData={BorrowerPurchaseStage}
                setSelectedData={setBorrowerPurchaseStage}
                dropdownState={purchaseStageDA}
                dropdownSetter={setPurchaseStageDA}
              />
            </div>
          </div>
        </div>

        <div className="financial-container loan-container">
          <div className="first-inner-box borro">
            <div>
              <img
                src={download}
                alt="download"
              />
              <p>Investor list</p>
            </div>

            <IOSSwitch />
          </div>

          {guarantor.map((point, index) => {
            return (
              <div
                className="browwer"
                key={index}
                style={{borderBottom: "1px solid grey", paddingBottom: "30px", paddingTop: "30px"}}
              >
                <div className="genral-input">
                  <InputBox
                    label={"Guarantor Full name"}
                    placeholder={"Mark Barker"}
                    name={"guarantor_full_name"}
                    onChange={(e) => handleInputChange(point.id, e.target.name, e.target.value)}
                    value={point.guarantor_full_name}
                  />
                  <InputBox
                    type={"email"}
                    label={"Guarantor email"}
                    placeholder={"Mark.Barker@invest.com"}
                    onChange={(e) => handleInputChange(point.id, e.target.name, e.target.value)}
                    name={"guarantor_email"}
                    value={point.guarantor_email}
                  />
                </div>
                <InputBox
                  label={"Guarantor Address"}
                  placeholder={"1400 Washington Ave, Albany, NY 12222, United States"}
                  onChange={(e) => handleInputChange(point.id, e.target.name, e.target.value)}
                  name={"guarantor_address"}
                  value={point.guarantor_address}
                />
                <div className="genral-input b-i">
                  <InputBox
                    label={"Estimated Credit Score"}
                    placeholder={"700"}
                    onChange={(e) => handleInputChange(point.id, e.target.name, e.target.value)}
                    name={"estimated_credit_score"}
                    value={point.estimated_credit_score}
                  />
                  <div>
                    <DropDown
                      label="Status"
                      data={statusList}
                      selectedData={point.status}
                      setSelectedData={(value) => handleInputChange(point.id, "status", value)}
                      dropdownState={statusListDa}
                      dropdownSetter={setStatusListDa}
                    />
                    <span>Have the above occurred to you or entities associated with you in the last 7 years?</span>
                  </div>
                </div>
                <div className="genral-input">
                  <InputBox
                    label={"# Property Sales in last 3 years"}
                    placeholder={"1"}
                    onChange={(e) => handleInputChange(point.id, e.target.name, e.target.value)}
                    name={"property_sales_in_last_3_years"}
                    value={point.property_sales_in_last_3_years}
                  />
                  <InputBox
                    type={"email"}
                    label={"# Rental Properties Currently Owned"}
                    placeholder={"1"}
                    onChange={(e) => handleInputChange(point.id, e.target.name, e.target.value)}
                    name={"rental_properties_currently_owned"}
                    value={point.rental_properties_currently_owned}
                  />
                </div>

                <div className="genral-input">
                  <InputBox
                    conCLass={"t"}
                    svgPath={dollar}
                    svgHeight={"30px"}
                    type={"text"}
                    name={"rental_properties_currently_owned_amount"}
                    label={"# Rental Properties Currently Owned"}
                    placeholder={"1"}
                    nChange={(e) => handleInputChange(point.id, e.target.name, e.target.value)}
                  />
                </div>
              </div>
            );
          })}

          <div className="key-d-add">
            <button onClick={handleAddPoint}>
              <img
                src={profileplus}
                alt=""
              />
              <span>Add another Guarantor</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Team = ({teams, setTeams}) => {
  const handleAddPoint = () => {
    if (teams.length === 15) {
      alert("You can add only 15 teams. Please remove any existing teams.");
    } else {
      const newId = teams.length + 1;
      setTeams([
        ...teams,
        {
          id: newId,
          team_description: "",
          members: [
            {
              id: Math.floor(Math.random() * 100000),
              name: "",
              role: "",
              location: "",
              phone: "",
              email: "",
              description: "",
              image: "",
            },
          ],
        },
      ]);
    }
  };


  const handleAddMember = (teamIndex) => {
    const newTeams = [...teams];
    if (newTeams[teamIndex].members.length === 3) {
      alert("You can add only three members in one team.");
    } else {
      const newMember = {
        id: Math.floor(Math.random() * 100000),
        name: "",
        role: "",
        location: "",
        phone: "",
        email: "",
        description: "",
        image: "",
      };
      newTeams[teamIndex].members.push(newMember);
      setTeams(newTeams);
    }
  };

  const handleInputChange = (teamId, memberId, fieldName, value) => {
    setTeams((prevTeams) => {
      return prevTeams.map((team) => {
        if (team.id === teamId) {
          if (fieldName === "team_description") {
            return {
              ...team,
              team_description: value,
            };
          } else {
            return {
              ...team,
              members: team.members.map((member) => {
                if (member.id === memberId) {
                  if (fieldName === "image") {
                    const formData = new FormData();
                    formData.append("member_image", value);
                    // Update member with FormData
                    return {
                      ...member,
                      [fieldName]: value,
                    };
                  } else {
                    // Update member with other fields
                    return {
                      ...member,
                      [fieldName]: value,
                    };
                  }
                }
                return member;
              }),
            };
          }
        }
        return team;
      });
    });
  };

  return (
    <div className="borrower-container">
      <div>
        <div className="financial-container loan-container">
          {teams.map((team, index) => (
            <div
              key={team.id}
              className="financial-body team-body"
            >
              <div className="first-inner-box borro">
                <div>
                  <img
                    src={download}
                    alt="download"
                  />
                  <p>Team {team.id}</p>
                </div>
                <img
                  src={edit}
                  alt=""
                />
              </div>

              <InputBox
                label={"Team Description"}
                placeholder={"(Optional)"}
                value={team.team_description}
                onChange={(e) => handleInputChange(team.id, "", "team_description", e.target.value)}
              />

              <div className="border"></div>

              <div className="team-wrapper">
                <span>Members</span>
                {team.members.map((member, memberIndex) => (
                  <div
                    key={memberIndex}
                    className="team-wrapper-inner"
                  >
                    <div className="team-wrapper-inner-left">
                      <label htmlFor={member.id}>
                        <img
                          src={addprofile}
                          alt=""
                        />
                        <input
                          type="file"
                          name="myfile"
                          id={member.id}
                          accept="image/jpeg, image/png, image/bmp, image/tiff"
                          style={{display: "none"}}
                          onChange={(e) => handleInputChange(team.id, member.id, "image", e.target.files[0])}
                        />
                      </label>
                    </div>
                    <div className="team-wrapper-inner-right">
                      <InputBox
                        label={"Name"}
                        placeholder={"John Smith"}
                        value={member.name}
                        onChange={(e) => handleInputChange(team.id, member.id, "name", e.target.value)}
                      />
                      <div className="inp-we">
                        <InputBox
                          conCLass={"team-input"}
                          label={"ROLE"}
                          placeholder={"CEO"}
                          value={member.role}
                          onChange={(e) => handleInputChange(team.id, member.id, "role", e.target.value)}
                        />
                        <InputBox
                          type={"text"}
                          conCLass={"team-input"}
                          label={"LOCATION"}
                          placeholder={"CEO"}
                          value={member.location}
                          onChange={(e) => handleInputChange(team.id, member.id, "location", e.target.value)}
                        />
                      </div>
                      <div className="inp-we">
                        <InputBox
                          conCLass={"team-input"}
                          label={"PHONE"}
                          placeholder={"000-000-0000"}
                          value={member.phone}
                          onChange={(e) => handleInputChange(team.id, member.id, "phone", e.target.value)}
                        />
                        <InputBox
                          type={"text"}
                          conCLass={"team-input"}
                          label={"EMAIL"}
                          placeholder={"name@companyname.com"}
                          value={member.email}
                          onChange={(e) => handleInputChange(team.id, member.id, "email", e.target.value)}
                        />
                      </div>
                      <InputBox
                        label={"Team Description"}
                        placeholder={"(Optional)"}
                        value={member.description}
                        onChange={(e) => handleInputChange(team.id, member.id, "description", e.target.value)}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="border"></div>
              <div className="key-d-add">
                <button onClick={() => handleAddMember(index)}>
                  <img
                    src={profileplus}
                    alt=""
                  />
                  <span>Add another Guarantor</span>
                </button>
              </div>
            </div>
          ))}

          <div className="key-d-add">
            <button onClick={handleAddPoint}>
              <img
                src={pluspurple}
                alt=""
              />
              <p>Add a new Team</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Contacts = ({additionalContacts, setAdditionalContacts, setPrimaryContact, primaryContact, setSocialInfo, socialInfo}) => {
  const handleAddContact = () => {
    additionalContacts.length === 15
      ? alert("you can add only 15 contacts..")
      : setAdditionalContacts([
          ...additionalContacts,
          {
            id: Math.floor(Math.random() * 100000),
            name: "",
            email: "",
            phone: "",
          },
        ]);
  };
  const handleRemoveContact = (id) => {
    setAdditionalContacts(additionalContacts.filter((point) => point.id !== id));
  };

  const handleInputChange = (id, name, value) => {
    setAdditionalContacts(additionalContacts.map((point) => (point.id === id ? {...point, [name]: value} : point)));
  };
  const handleChangePrimaryContact = (name, value) => {
    setPrimaryContact({...primaryContact, [name]: value});
  };

  const handleChangeSocial = (name, value) => {
    setSocialInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="borrower-container">
      <div>
        <div className="financial-container loan-container">
          <p style={{textAlign: "center"}}>Primary Contact</p>
          <div className="contact-body">
            <InputBox
              label={"NAME"}
              svgPath={user}
              placeholder={"Tom Waitsh"}
              type={"text"}
              onChange={(e) => handleChangePrimaryContact("name", e.target.value)}
            />
            <InputBox
              label={"EMAIL"}
              svgPath={email}
              placeholder={"sales1@hotels.biz"}
              type={"email"}
              onChange={(e) => handleChangePrimaryContact("email", e.target.value)}
            />
            <div className="add-phone">
              <InputBox
                label={"PHONE"}
                svgPath={phoneno}
                placeholder={"+1 404-795-4989"}
                type={"phone"}
                onChange={(e) => handleChangePrimaryContact("phone", e.target.value)}
              />
              <img
                src={plus}
                alt=""
              />
            </div>
            <div className="border"></div>
          </div>
          <div className="contact-body">
            <span className="contact-header">Additional Contacts</span>
            {additionalContacts.map((contact, index) => {
              return (
                <div key={contact.id}>
                  <div
                    className="additional-contact"
                    onClick={() => handleRemoveContact(contact.id)}
                  >
                    <img
                      src={deleted}
                      alt=""
                    />
                  </div>
                  <InputBox
                    label={"NAME"}
                    svgPath={user}
                    placeholder={"Tom Waitsh"}
                    type={"text"}
                    value={contact.name}
                    onChange={(e) => handleInputChange(contact.id, "name", e.target.value)}
                  />
                  <InputBox
                    label={"EMAIL"}
                    svgPath={email}
                    placeholder={"sales1@hotels.biz"}
                    type={"email"}
                    value={contact.email}
                    onChange={(e) => handleInputChange(contact.id, "email", e.target.value)}
                  />
                  <div className="add-phone">
                    <InputBox
                      label={"PHONE"}
                      svgPath={phoneno}
                      placeholder={"+1 404-795-4989"}
                      type={"phone"}
                      value={contact.phone}
                      onChange={(e) => handleInputChange(contact.id, "phone", e.target.value)}
                    />
                    <img
                      src={plus}
                      alt=""
                    />
                  </div>
                  <div className="border"></div>
                </div>
              );
            })}
            <div className="add-btn-wrapper">
              <div
                className="add-contact"
                onClick={handleAddContact}
              >
                <img
                  src={profileplus}
                  alt=""
                />
                <span>Add contact</span>
              </div>
            </div>
          </div>
          <div className="financial-container loan-container">
            <div className="first-inner-box ">
              <img
                src={website}
                alt="download"
              />
              <p>Website</p>
            </div>

            <div className="financial-body">
              <InputBox
                label={"LINK"}
                svgPath={link}
                placeholder={"http://"}
                type={"text"}
                onChange={(e) => handleChangeSocial("website_link", e.target.value)}
                width={"100%"}
              />
            </div>
          </div>
          <div className="financial-container loan-container">
            <div className="first-inner-box ">
              <img
                src={socialnetwork}
                alt="download"
              />
              <p>Social Networks</p>
            </div>

            <div className="contact-body">
              <InputBox
                label={"Facebook Business Page"}
                svgPath={facebook}
                placeholder={"facebook.com/"}
                type={"text"}
                onChange={(e) => handleChangeSocial("facebook", e.target.value)}
                width={"100%"}
              />
              <InputBox
                label={"twitter Business Page"}
                svgPath={twitter}
                placeholder={"twitter.com/"}
                type={"text"}
                onChange={(e) => handleChangeSocial("twitter", e.target.value)}
                width={"100%"}
              />
              <InputBox
                label={"LinkedIn Business Page"}
                svgPath={linkedein}
                placeholder={"linkedein.com/"}
                type={"text"}
                onChange={(e) => handleChangeSocial("linkedin", e.target.value)}
                width={"100%"}
              />
              <InputBox
                label={"Facebook Business Page"}
                svgPath={facebook}
                placeholder={"https://g.page/"}
                type={"text"}
                onChange={(e) => handleChangeSocial("facebook_business", e.target.value)}
              />
            </div>
          </div>
        </div>
        {/* Other parts of your UI */}
      </div>
    </div>
  );
};

export const Disclaimer = ({setDesclaimerDoc}) => {
  const [selectedFileName, setSelectedFileName] = useState("");

  const preventDefault = (event) => {
    event.preventDefault();
  };

  const handleImageDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileSelection(file);
    }
  };

  const handleImageInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileSelection(file);
    }
  };

  const handleFileSelection = (file) => {
    setSelectedFileName(file.name);

    // Create a new FormData object
    const formData = new FormData();
    formData.append("pdfFile", file);

    // Set the FormData object in state using setFinancialDocument
    setDesclaimerDoc(file);
  };
  return (
    <div className="borrower-container investment-main-container">
      <div>
        <div className="financial-container loan-container">
          <div className="first-inner-box">
            <img
              src={download}
              alt="download"
            />
            <p>Your Documents</p>
          </div>
          <div className="upload-doc-body">
            <div
              className="select-doc"
              onDrop={handleImageDrop}
              onDragOver={preventDefault}
            >
              <img
                src={uploadIcon}
                alt=""
              />
              <div className="upload-btn-wrapper">
                <button className="btn">{selectedFileName ? selectedFileName : "Drag and drop files or click"}</button>
                <input
                  type="file"
                  name="myfile"
                  accept="application/pdf"
                  onChange={handleImageInputChange}
                />
              </div>
              <p>Supported file format: PDF Max file size 250 MB</p>
            </div>
            {selectedFileName && (
              <div className="progress-cont">
                <div className="uploading-file">
                  <div className="file-name">
                    <div>
                      <img
                        src={pdf}
                        alt=""
                      />
                      <span>{selectedFileName}</span>
                    </div>
                    <span>1,5MB of 3,6MB</span>
                  </div>

                  <div className="progress-bar">
                    <span className="progress"></span>
                  </div>
                </div>
                <img
                  src={navclose}
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
        <h1>Add Disclaimer manually</h1>
        <div className="financial-container">
          <div className="first-inner-box">
            <img
              src={download}
              alt="download"
            />
            <p>General Details</p>
          </div>
          <div className="general-syndicate-body">
            <div className="add-info-man custom-disc">
              <div className="add-info-img">
                <img
                  src={text}
                  alt=""
                />
              </div>
              <span>Text</span>
            </div>
            <div className="add-info-man custom-disc">
              <div className="add-info-img">
                <img
                  src={image}
                  alt=""
                />
              </div>
              <span>Image</span>
            </div>
            <div className="add-info-man custom-disc">
              <div className="add-info-img">
                <img
                  src={video}
                  alt=""
                />
              </div>
              <span>Video</span>
            </div>
            <div className="add-info-man custom-disc">
              <div className="add-info-img">
                <img
                  src={grid}
                  alt=""
                />
              </div>
              <span>Table</span>
            </div>
            <div className="add-info-man custom-disc">
              <div className="add-info-img">
                <img
                  src={addsec}
                  alt=""
                />
              </div>
              <span>Add Section</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InputB = ({label, type, classCon, value, placeholder, svgPath}) => {
  return (
    <div className={"input-box " + classCon}>
      <label htmlFor="box">{label}</label>
      <div>
        {svgPath && <img src={svgPath} />}
        <input
          id="box"
          type={type}
          placeholder={placeholder}
          // value={value}
        />
        {/* <span>Month</span> */}
      </div>
    </div>
  );
};
export const TextAreaB = ({label, type, classCon, value, placeholder}) => {
  return (
    <div className={"input-box area-box "}>
      <label htmlFor="box">{label}</label>
      <div>
        <textarea
          placeholder={placeholder}
          id="box"
          type={type}
          value={value}
        />
      </div>
    </div>
  );
};

const DropDownBox = ({label, type, classCon, value}) => {
  return (
    <div className={"input-box " + classCon}>
      <label htmlFor="box">{label}</label>
      <div>
        <input
          id="box"
          type={type}
          value={value}
        />
        <img
          src={dropdown}
          alt=""
        />
      </div>
    </div>
  );
};

export const LenderCard = ({data, handleSelection, isSelected}) => {
  return (
    <div className="lender-table-main">
      <div>
        <Checkbox
          checked={isSelected}
          onChange={() => handleSelection(data)}
          sx={{accentColor: "rgb(116,77,193)"}}
        />
      </div>
      <div className="img-wrapper">
        <div>
          <img
            src={data.profile}
            alt=""
          />
        </div>
        <div>
          <span>{data.name}</span>
          <span>{data.role}</span>
        </div>
      </div>
      <div className="apr">{data.apr}</div>
      <div className="term">
        <span>{data.term}</span>
      </div>
      <div className="max-loan">{data.max_loan}</div>
      <div className="match">
        <span>{data.match}</span>
      </div>
    </div>
  );
};
