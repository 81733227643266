import React, {useState} from "react";
import "./Notes.scss";
import {InputBox} from "../../components/reusableComponents/FormFields/FormFields";
import {PrimaryButton, PrimaryButtonSvgFirst} from "../../components/reusableComponents/Buttons/Buttons";
import searchBtn from "../../assets/notes/searchbtn.svg";
import tag from "../../assets/notes/tag.svg";
import man from "../../assets/notes/man.png";
import Pic1 from "../../assets/notes/Pic1.png";
import Pic2 from "../../assets/notes/Pic2.png";
import Pic3 from "../../assets/notes/Pic3.png";
import camera from "../../assets/notes/camera.svg";
import dot from "../../assets/notes/dot.svg";
import comment from "../../assets/notes/comment.svg";
import share from "../../assets/notes/share.svg";
import smile from "../../assets/notes/smile.svg";

const Notes = () => {
  const [navActive, setNavActive] = useState(1);

  return (
    <div className="notes-main-container">
      <div className="notes-main-left">
        <h1>Notes</h1>
        <div className="notes-main">
          <NotesNav
            navActive={navActive}
            setNavActive={setNavActive}
          />

          {navActive === 1 && <Counterparty />}
        </div>
      </div>
      <div className="notes-main-right">
        <SearchForNote />
        <TagsForNote />
      </div>
    </div>
  );
};

export default Notes;

const NotesNav = ({navActive, setNavActive}) => {
  return (
    <div className="deals-room-nav asset-nav ass-nav notes-nav">
      <span
        className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
        onClick={() => {
          setNavActive(1);
        }}
      >
        Internal
      </span>
      <span
        className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
        onClick={() => {
          setNavActive(2);
        }}
      >
        Counterparty
      </span>
    </div>
  );
};

const Counterparty = () => {
  return (
    <div className="counter-party-container">
      <CounterPartyUpper />
      <CounterPartyCenter />
      <CounterPartyDown />
    </div>
  );
};

const CounterPartyUpper = () => {
  return (
    <div className="counter-party-upper">
      <div className="c-p-u-u">
        <img
          src={man}
          alt=""
        />
        <input
          type="text"
          placeholder="Add your note here..."
        />
      </div>
      <div className="divider"></div>
      <div className="c-p-u-d">
        <div>
          <img
            src={camera}
            alt=""
          />
          <span>Photo/Video</span>
        </div>
        <InputBox
          svgPath={tag}
          placeholder={"Enter Tag"}
          conCLass={"inp"}
        />
      </div>
    </div>
  );
};

const CounterPartyCenter = () => {
  return (
    <div className="counter-party-center">
      <div className="user-card">
        <div className="left">
          <img
            src={man}
            alt=""
          />
          <div>
            <h3>Roxie Mills</h3>
            <span>December 28, 2018</span>
          </div>
        </div>
        <div>
          <img
            src={dot}
            alt=""
          />
        </div>
      </div>
      <h3>Interion ideas for renovations</h3>
      <div className="c-p-c-i">
        <div className="pic-left">
          <img
            src={Pic1}
            alt=""
          />
        </div>
        <div className="pic-right">
          <img
            src={Pic2}
            alt=""
          />
          <img
            src={Pic3}
            alt=""
          />
        </div>
      </div>
      <div className="divider"></div>
      <div className="c-p-comment">
        <div>
          <div>
            <img
              src={comment}
              alt=""
            />
            <span>Comment</span>
          </div>
          <div>
            <img
              src={share}
              alt=""
            />
            <span>Share</span>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="user-card">
        <div className="left">
          <img
            src={man}
            alt=""
          />
          <div>
            <h3>Roxie Mills</h3>
            <span>December 28, 2018</span>
          </div>
        </div>
        <div className="right">
          <img
            src={camera}
            alt=""
          />
          <img
            src={smile}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
const CounterPartyDown = () => {
  return (
    <div className="counter-party-down">
      <div className="user-card">
        <div className="left">
          <img
            src={man}
            alt=""
          />
          <div>
            <h3>Roxie Mills</h3>
            <span>December 28, 2018</span>
          </div>
        </div>
        <div>
          <img
            src={dot}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const SearchForNote = () => {
  return (
    <div className="search-for-note">
      <div className="search-f-n-header">
        <h3>Search for note</h3>
        <span>Specify details</span>
      </div>
      <div className="search-f-n-main">
        <InputBox
          label={"Keywords"}
          placeholder={"Start typing"}
        />
        <InputBox
          label={"Added by"}
          placeholder={"Enter name"}
        />
        <InputBox
          label={"Date added"}
          type={"date"}
          placeholder={"15 Apr ‘18     -     15 Apr ‘18"}
        />
        <PrimaryButtonSvgFirst
          classNames={"btn"}
          svgPath={searchBtn}
          name={"Search"}
        />
      </div>
    </div>
  );
};

const TagsForNote = () => {
  return (
    <div className="tags-notes-container">
      <div className="tags-n-header search-f-n-header">
        <h3>Tags</h3>
        <span>(5)</span>
      </div>
      <div className="tags-main-wrapper">
        <TagCard
          name={"Renovation"}
          value={"5"}
        />
        <TagCard
          name={"2-bedroom"}
          value={"12"}
        />
        <TagCard
          name={"Broker"}
          value={"4"}
        />
        <TagCard
          name={"Revenue"}
          value={"1"}
        />
        <TagCard
          name={"Tour"}
          value={"6"}
        />
      </div>
    </div>
  );
};

const TagCard = ({name, value}) => {
  return (
    <div className="notes-tag">
      <div className="notes-tag-left">
        <img
          src={tag}
          alt=""
        />{" "}
        <span>{name}</span>
      </div>
      <div>{value}</div>
    </div>
  );
};
