import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import {ThemeProvider, createTheme} from "@mui/material";
import {Provider} from "react-redux";
import {store} from "./store/store";

import ErrorModal from "./components/reusableComponents/Alerts/Alerts/ErrorModal";
import SuccessModal from "./components/reusableComponents/Alerts/Alerts/SuccessModal";

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: "white",
        },
        colorPrimary: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: "white",
          },
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.2,
          backgroundColor: "#fff",
          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: "#724bc0",
          },
        },
      },
    },
  },
});
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
      <ErrorModal />
      <SuccessModal />
    </ThemeProvider>
  </Provider>,
);
