import i18next from '../../i18n/config';

const initialState = {
  title: null,
  msg: null, 
  status: null,
  isWarning: false,
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MESSAGES":
      return {
        ...state,
        title: i18next.t(action.payload.title),
        msg: i18next.t(action.payload.msg),
        status: action.payload.status,
        isWarning: false,
      };
    case "GET_WARNING_MESSAGES":
      return {
        ...state,
        title: i18next.t(action.payload.title),
        msg: i18next.t(action.payload.msg),
        status: action.payload.status,
        isWarning: true,
      };
    case "CLEAR_MESSAGES":
      return initialState;
    default:
      return state;
  }
};


export const returnMessages = (msg, status, title = '') => {
  return {
    type: "GET_MESSAGES",
    payload: { msg, status, title },
  };
};

export const returnWarningMessages = (msg, status, title = '') => {
  return {
    type: "GET_WARNING_MESSAGES",
    payload: { msg, status, title },
  };
};

export const clearMessages = () => {
  return {
    type: "CLEAR_MESSAGES",
  };
};

export default messageReducer;
