import React, {useState} from "react";
import {PrimaryButton, PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import "./Convertibles.scss";
import SearchInput from "../../../components/reusableComponents/Search/Search";
import filter from "../../../assets/Manage-syndicate/Svg/filter.svg";
import DotMenu from "../../../components/reusableComponents/Tripledot/Tripledot";
import x from "../../../assets/Manage-syndicate/Svg/x.svg";

const Convertibles = () => {
  const [showNotification, setShowNotification] = useState(true);

  const handleHideNotification = () => {
    setShowNotification(false);
  };

  const profileMenuItems = [
    {label: "Profile", action: "profile"},
    {label: "Settings", action: "settings"},
    {label: "Log out", action: "logout"},
  ];

  const data = [
    {
      name: "CA- 58",
      authors: "Belien S. Tadesse and Belay A. Mengistu",
      status: "Converted- CA-58",
      amount: "200,000",
      price: "$1.00",
      total: "$200,000.00",
      date: "04/06/2022",
      moreInfo: "3dot",
    },
    {
      name: "CA- 58",
      authors: "Belien S. Tadesse and Belay A. Mengistu",
      status: "Converted- CA-58",
      amount: "200,000",
      price: "$1.00",
      total: "$200,000.00",
      date: "04/06/2022",
      moreInfo: "3dot",
    },
    {
      name: "CA- 58",
      authors: "Belien S. Tadesse and Belay A. Mengistu",
      status: "Converted- CA-58",
      amount: "200,000",
      price: "$1.00",
      total: "$200,000.00",
      date: "04/06/2022",
      moreInfo: "3dot",
    },
    {
      name: "CA- 58",
      authors: "Belien S. Tadesse and Belay A. Mengistu",
      status: "Converted- CA-58",
      amount: "200,000",
      price: "$1.00",
      total: "$200,000.00",
      date: "04/06/2022",
      moreInfo: "3dot",
    },
    {
      name: "CA- 58",
      authors: "Belien S. Tadesse and Belay A. Mengistu",
      status: "Converted- CA-58",
      amount: "200,000",
      price: "$1.00",
      total: "$200,000.00",
      date: "04/06/2022",
      moreInfo: "3dot",
    },
  ];

  return (
    <div>
      <div style={{marginTop: "10px", marginBottom: "10px"}}>
        <h1>Convertibles</h1>
      </div>
      {showNotification && (
        <div className="Convertibles-container-header">
          <div className="Convertibles-container">
            <div className="Convertibles-container-data">
              <div>
                <p>You can add an already issued SAFE in the SAFE financings section. Clicking on Go SAFE financing will redirect you.</p>
              </div>
              <div>
                <img
                  src={x}
                  alt=""
                  onClick={handleHideNotification}
                />
              </div>
            </div>
            <div className="Convertibles-container-data-button">
              <PrimaryButtonSvgFirst
                name="Go to SAFE financing"
                classNames="Convertibles-Goto"
                padding="10px"
                svgHeight="20px"
                svgWidth="20px"
              />
              <PrimaryButtonSvgFirst
                name="Don’t show again"
                classNames="CaptableStake-show"
                padding="10px"
                svgHeight="20px"
                svgWidth="20px"
                onClick={handleHideNotification}
              />
            </div>
          </div>
        </div>
      )}
      <div className="colordata">
        <div className="CabTable-data">
          <div>
            <SearchInput />
          </div>
          <div className="CabTable-data-flex">
            <PrimaryButtonSvgFirst
              name="Filter"
              classNames="CabTaConvertiblesble-Filter"
              svgPath={filter}
              padding="10px"
              svgHeight="20px"
              svgWidth="20px"
            />
            <div className="select-wrapper">
              <select
                name="Action"
                id="Action"
              >
                <option
                  value=""
                  disabled
                  selected
                >
                  Action
                </option>
                <option value="2nd">
                  2nd <i className="fas fa-arrow-up"></i>
                </option>
                <option value="3rd">
                  3rd <i className="fas fa-arrow-down"></i>
                </option>
              </select>
            </div>
            <span>View Share Classes</span>
            <PrimaryButton
              name="Draft Shares"
              classNames="Report-see-plan"
              svgPath="/path/to/svg/image.svg"
              padding="10px"
              svgHeight="20px"
              svgWidth="20px"
            />
          </div>
        </div>
      </div>
      <div>
        {/* Body-data */}
        <div className="dropdown-data">
          <input
            type="checkbox"
            name=""
            id=""
          />
          <select name="Saved views">
            <option
              value=""
              disabled
              selected
            >
              Security
            </option>
            <option value="view1">View 1</option>
            <option value="view2">View 2</option>
          </select>
          <select name="Saved views">
            <option
              value=""
              disabled
              selected
            >
              STAKEHOLDER
            </option>
            <option value="view1">View 1</option>
            <option value="view2">View 2</option>
          </select>
          <select name="Saved views">
            <option
              value=""
              disabled
              selected
            >
              STATUS
            </option>
            <option value="view1">View 1</option>
            <option value="view2">View 2</option>
          </select>
          <select name="Saved views">
            <option
              value=""
              disabled
              selected
            >
              PRINCIPLE
            </option>
            <option value="view1">View 1</option>
            <option value="view2">View 2</option>
          </select>
          <select name="Saved views">
            <option
              value=""
              disabled
              selected
            >
              INTEREST RATE
            </option>
            <option value="view1">View 1</option>
            <option value="view2">View 2</option>
          </select>
          <select name="Saved views">
            <option
              value=""
              disabled
              selected
            >
              INTEREST START
            </option>
            <option value="view1">View 1</option>
            <option value="view2">View 2</option>
          </select>
          <select name="Saved views">
            <option
              value=""
              disabled
              selected
            >
              INTEREST ACCURED
            </option>
            <option value="view1">View 1</option>
            <option value="view2">View 2</option>
          </select>
          <select name="Saved views">
            <option
              value=""
              disabled
              selected
            >
              TOTAL AMOUNT
            </option>
            <option value="view1">View 1</option>
            <option value="view2">View 2</option>
          </select>
        </div>
      </div>
      <div className="color">
        {data.map((item, index) => (
          <div
            key={index}
            className="user-details-container"
          >
            <div className="user-details">
              <input
                type="checkbox"
                name=""
                id=""
              />
              <h5>{item.name}</h5>
              <h5>{item.authors}</h5>
              <div className="dot"></div>
              <span>{item.status}</span>
              <h5>{item.amount}</h5>
              <h5>{item.price}</h5>
              <h5>{item.total}</h5>
              <h5>{item.date}</h5>
              <DotMenu menuItems={profileMenuItems} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Convertibles;
