import lenderHeader from "../../assets/svg/Frame 1196.png";
import lenderWrapperHeader from "../../assets/svg/Component 218.png";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import historyTable from "../../assets/svg/Frame 1119.png";
import {PrimaryButtonSvgFirst} from "../../components/reusableComponents/Buttons/Buttons";
import {Checkbox, FormControlLabel} from "@mui/material";
import eyeicon from "../../assets/svg/passwordeye.svg";
import profileIcon from "../../assets/svg/commentProfile.png";
import updatesIcon from "../../assets/svg/commentsProfile2.png";
import greendot from "../../assets/dealsroom/svg/greendot.svg";
import updatesPics from "../../assets/svg/updatesPics.png";
import UpdatesCards from "../../assets/svg/updates_cards.png";
import '../DealsRoom/DealsRoom.scss'
import './LenderResponses.scss'
const LenderResponses = ({setLenderResponseTab}) => {
  return (
    <div>
      <div className=" LenderResponses-heightfix asset-center-con lender_wrapper ">
        <div className="deals-room-center-header">
          <div className="left">
            {/* <img
                  src={todoHeader}
                  alt=""
                /> */}
            <p>Lender Responses</p>
          </div>
          <div
            className="right"
            onClick={() => {
              setLenderResponseTab(1);
            }}
          >
            {/* <span>19 Collaborators</span> */}
            <img
              src={lenderHeader}
              alt=""
            />
          </div>
        </div>
        {/* <div className="lender_header">
          <img
            src={lenderWrapperHeader} 
            alt=""
          />
        </div> */}
        <div className="lender_container">
          <Tabs selectedTabClassName="active">
            <TabList>
              <Tab>Lender Responses</Tab>
              <Tab>Loan Details</Tab>
              <Tab>Documents</Tab>
              <Tab>Comments</Tab>
              <Tab>Updates</Tab>
            </TabList>
            <TabPanel>
              <LenderTable />
            </TabPanel>
            <TabPanel>
              <LoanDetails />
            </TabPanel>
            <TabPanel>
              <Documents />
            </TabPanel>
            <TabPanel>
              <Comments />
            </TabPanel>
            <TabPanel>
              <Updates />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default LenderResponses;

const LenderTable = () => {
  return (
    <div className="lender_table">
      <div className="documents_box">
        <div className="documents_box-T">
          <div className="left">
            <h2>Response history</h2>
          </div>
          <div className="right">
            <div className="search_bar">
              <input
                type="text"
                placeholder="Search"
              />
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 18.586L18.586 20L12.929 14.343L14.343 12.929L20 18.586ZM7 12C4.243 12 2 9.757 2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12ZM7 0C3.134 0 0 3.134 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_9862_58034"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20 18.586L18.586 20L12.929 14.343L14.343 12.929L20 18.586ZM7 12C4.243 12 2 9.757 2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12ZM7 0C3.134 0 0 3.134 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_9862_58034)">
                  <rect
                    opacity="0.5"
                    x="-1"
                    y="-1"
                    width="22"
                    height="22"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
            </div>

            <div className="button2">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18 4V0H16V4H14V6H16V20H18V6H20V4H18ZM11 0H9V9H7V11H9V20H11V11H13V9H11V0ZM4 14H6V16H4V20H2V16H0V14H2V0H4V14Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_9862_58051"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18 4V0H16V4H14V6H16V20H18V6H20V4H18ZM11 0H9V9H7V11H9V20H11V11H13V9H11V0ZM4 14H6V16H4V20H2V16H0V14H2V0H4V14Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_9862_58051)">
                  <rect
                    x="-1"
                    y="-1"
                    width="22"
                    height="22"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>

              <PrimaryButtonSvgFirst
                name={"Filter"}
                classNames={"white"}
              />
            </div>
            <div className="button2">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.9952 15.4689C13.9952 15.0765 12.9726 15.0805 12.5831 15.4719L11.8521 16.2084C11.5375 16.5245 10.9992 16.3028 10.9992 15.8552V7.03153C10.9992 6.48162 10.6007 6.03708 10.0555 6.03005C9.50819 6.03708 9.00187 6.48162 9.00187 7.03153V15.8552C9.00187 16.3028 8.46359 16.5265 8.14901 16.2104L7.41799 15.2672C7.02851 14.8758 6.40634 15.0594 6.01686 15.0594H6.01087C5.62139 16.0629 5.61839 16.2947 6.00887 16.6861L8.58942 19.3855C9.36938 20.1692 10.6337 20.2214 11.4136 19.4376L13.9952 16.8687C14.3857 16.4764 13.9952 15.8542 13.9952 15.4628V15.4689ZM10.0445 6.02805C10.0485 6.02805 10.0515 6.03005 10.0555 6.03005C10.0595 6.03005 10.0625 6.02805 10.0664 6.02805H10.0445ZM13.9952 14.0559C13.4439 14.0559 12.9965 13.6064 12.9965 13.0525C12.9965 12.4985 13.4439 12.049 13.9952 12.049H14.888C19.1423 12.049 18.8337 5.89559 14.894 6.03808C13.5478 -0.665213 3.21261 1.75419 5.24389 8.47756C3.16867 6.8198 0.677995 10.0279 2.79916 11.6115C4.29816 12.7313 7.00454 11.199 7.00454 13.0525C7.00454 14.3148 5.57845 14.0559 4.20928 14.0559C0.877728 14.0559 -1.17553 10.4835 0.731923 7.75204C1.6437 6.4475 3.07379 6.12539 3.07379 6.12539C3.73291 0.994555 9.56312 -1.71486 13.8814 1.18923C15.7349 2.43657 16.4379 4.2589 16.4379 4.2589C17.5275 4.59206 18.4622 5.28246 19.0984 6.19663C21.2405 9.27333 19.5757 14.0559 13.9952 14.0559Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_13801_315163"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.9952 15.4689C13.9952 15.0765 12.9726 15.0805 12.5831 15.4719L11.8521 16.2084C11.5375 16.5245 10.9992 16.3028 10.9992 15.8552V7.03153C10.9992 6.48162 10.6007 6.03708 10.0555 6.03005C9.50819 6.03708 9.00187 6.48162 9.00187 7.03153V15.8552C9.00187 16.3028 8.46359 16.5265 8.14901 16.2104L7.41799 15.2672C7.02851 14.8758 6.40634 15.0594 6.01686 15.0594H6.01087C5.62139 16.0629 5.61839 16.2947 6.00887 16.6861L8.58942 19.3855C9.36938 20.1692 10.6337 20.2214 11.4136 19.4376L13.9952 16.8687C14.3857 16.4764 13.9952 15.8542 13.9952 15.4628V15.4689ZM10.0445 6.02805C10.0485 6.02805 10.0515 6.03005 10.0555 6.03005C10.0595 6.03005 10.0625 6.02805 10.0664 6.02805H10.0445ZM13.9952 14.0559C13.4439 14.0559 12.9965 13.6064 12.9965 13.0525C12.9965 12.4985 13.4439 12.049 13.9952 12.049H14.888C19.1423 12.049 18.8337 5.89559 14.894 6.03808C13.5478 -0.665213 3.21261 1.75419 5.24389 8.47756C3.16867 6.8198 0.677995 10.0279 2.79916 11.6115C4.29816 12.7313 7.00454 11.199 7.00454 13.0525C7.00454 14.3148 5.57845 14.0559 4.20928 14.0559C0.877728 14.0559 -1.17553 10.4835 0.731923 7.75204C1.6437 6.4475 3.07379 6.12539 3.07379 6.12539C3.73291 0.994555 9.56312 -1.71486 13.8814 1.18923C15.7349 2.43657 16.4379 4.2589 16.4379 4.2589C17.5275 4.59206 18.4622 5.28246 19.0984 6.19663C21.2405 9.27333 19.5757 14.0559 13.9952 14.0559Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_13801_315163)">
                  <rect
                    x="-1"
                    y="-1"
                    width="22"
                    height="22"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>

              <PrimaryButtonSvgFirst
                name={"Export"}
                classNames={"white"}
              />
            </div>
          </div>
        </div>
        <div className="documents_box-B table">
          <div className="uploaded_docs">
            <div className="header">
              <div className="left">
                <p>Lender</p>
                <p>start date</p>
                <p>exit date (anticipated)</p>
                <p>borrowed</p>
                <p>returned</p>
              </div>
              <div className="right">
                <p>left</p>
                <p>Loan Status</p>
                <p>Action</p>
              </div>
            </div>
            <Row2 />
            <Row2 />
            <Row2 />
            <Row2 />
            <Row2 />
            <Row2 />
          </div>
        </div>
      </div>
    </div>
  );
};
const Row2 = () => {
  return (
    <div className="table_row table_row2">
      <div className="left">
        <div className="item first">
          <img
            src={profileIcon}
            alt=""
          />

          <div className="text_wrapper">
            <p>Jose Perkins</p>
            <span>LLC</span>
          </div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p>15/11/18</p>
          </div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p>15/11/21</p>
          </div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p>$500,900.00</p>
          </div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p>$500,900.00</p>
            <span>0%</span>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="item">
          <div className="text_wrapper">
            <p>00.00</p>
            <span>0%</span>
          </div>
        </div>
        <div className="item">
          <svg
            width="78"
            height="24"
            viewBox="0 0 78 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="78"
              height="24"
              rx="4"
              fill="#ED962D"
            />
            <path
              d="M12.5625 7.46875V16H11.0918V7.46875H12.5625ZM21.1582 7.46875V16H19.6875L15.8613 9.88867V16H14.3906V7.46875H15.8613L19.6992 13.5918V7.46875H21.1582ZM25.8984 7.46875H28.916C29.5645 7.46875 30.1172 7.56641 30.5742 7.76172C31.0312 7.95703 31.3809 8.24609 31.623 8.62891C31.8691 9.00781 31.9922 9.47656 31.9922 10.0352C31.9922 10.4609 31.9141 10.8359 31.7578 11.1602C31.6016 11.4844 31.3809 11.7578 31.0957 11.9805C30.8105 12.1992 30.4707 12.3691 30.0762 12.4902L29.6309 12.707H26.918L26.9062 11.541H28.9395C29.291 11.541 29.584 11.4785 29.8184 11.3535C30.0527 11.2285 30.2285 11.0586 30.3457 10.8438C30.4668 10.625 30.5273 10.3789 30.5273 10.1055C30.5273 9.80859 30.4688 9.55078 30.3516 9.33203C30.2383 9.10938 30.0625 8.93945 29.8242 8.82227C29.5859 8.70117 29.2832 8.64062 28.916 8.64062H27.3691V16H25.8984V7.46875ZM30.7676 16L28.7637 12.168L30.3047 12.1621L32.3379 15.9238V16H30.7676ZM39.041 14.834V16H34.5117V14.834H39.041ZM34.9277 7.46875V16H33.457V7.46875H34.9277ZM38.4492 11.0312V12.1797H34.5117V11.0312H38.4492ZM39.0117 7.46875V8.64062H34.5117V7.46875H39.0117ZM43.166 14.4414L45.4102 7.46875H47.0332L43.9746 16H42.8789L43.166 14.4414ZM41.0918 7.46875L43.3184 14.4414L43.623 16H42.5215L39.4746 7.46875H41.0918ZM49.5586 7.46875V16H48.0879V7.46875H49.5586ZM56.9707 14.834V16H52.4414V14.834H56.9707ZM52.8574 7.46875V16H51.3867V7.46875H52.8574ZM56.3789 11.0312V12.1797H52.4414V11.0312H56.3789ZM56.9414 7.46875V8.64062H52.4414V7.46875H56.9414ZM60.3105 14.084L62.0098 7.46875H62.9004L62.8008 9.17383L60.9844 16H60.0586L60.3105 14.084ZM59.0391 7.46875L60.416 14.0312L60.5391 16H59.5547L57.5801 7.46875H59.0391ZM64.8281 14.0137L66.1875 7.46875H67.6523L65.6777 16H64.6934L64.8281 14.0137ZM63.2461 7.46875L64.9277 14.1016L65.1738 16H64.248L62.4609 9.17383L62.3672 7.46875H63.2461Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="action_buttons">
          <div className="button">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 10.0005C12 11.1477 11.105 12.0768 10 12.0768C8.895 12.0768 8 11.1477 8 10.0005C8 8.85336 8.895 7.92421 10 7.92421C11.105 7.92421 12 8.85336 12 10.0005ZM10 13.9237C7.011 13.9237 4.195 12.4474 2.399 10.0005C4.195 7.55359 7.011 6.0763 10 6.0763C12.989 6.0763 15.805 7.55359 17.601 10.0005C15.805 12.4474 12.989 13.9237 10 13.9237ZM10 4C5.724 4 1.999 6.41682 0 10.0005C1.999 13.5842 5.724 16 10 16C14.276 16 18.001 13.5842 20 10.0005C18.001 6.41682 14.276 4 10 4Z"
                fill="white"
                fill-opacity="0.01"
              />
              <mask
                id="mask0_13801_268584"
                maskUnits="userSpaceOnUse"
                x="0"
                y="4"
                width="20"
                height="12"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 10.0005C12 11.1477 11.105 12.0768 10 12.0768C8.895 12.0768 8 11.1477 8 10.0005C8 8.85336 8.895 7.92421 10 7.92421C11.105 7.92421 12 8.85336 12 10.0005ZM10 13.9237C7.011 13.9237 4.195 12.4474 2.399 10.0005C4.195 7.55359 7.011 6.0763 10 6.0763C12.989 6.0763 15.805 7.55359 17.601 10.0005C15.805 12.4474 12.989 13.9237 10 13.9237ZM10 4C5.724 4 1.999 6.41682 0 10.0005C1.999 13.5842 5.724 16 10 16C14.276 16 18.001 13.5842 20 10.0005C18.001 6.41682 14.276 4 10 4Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_13801_268584)">
                <rect
                  x="-1"
                  y="-1"
                  width="22"
                  height="22"
                  fill="#9EA0A5"
                />
              </g>
            </svg>
          </div>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 12C3.10457 12 4 11.1046 4 10C4 8.89543 3.10457 8 2 8C0.89543 8 0 8.89543 0 10C0 11.1046 0.89543 12 2 12ZM10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12ZM20 10C20 11.1046 19.1046 12 18 12C16.8954 12 16 11.1046 16 10C16 8.89543 16.8954 8 18 8C19.1046 8 20 8.89543 20 10Z"
              fill="white"
              fill-opacity="0.01"
            />
            <mask
              id="mask0_13820_234248"
              maskUnits="userSpaceOnUse"
              x="0"
              y="8"
              width="20"
              height="4"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 12C3.10457 12 4 11.1046 4 10C4 8.89543 3.10457 8 2 8C0.89543 8 0 8.89543 0 10C0 11.1046 0.89543 12 2 12ZM10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12ZM20 10C20 11.1046 19.1046 12 18 12C16.8954 12 16 11.1046 16 10C16 8.89543 16.8954 8 18 8C19.1046 8 20 8.89543 20 10Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_13820_234248)">
              <rect
                x="-1"
                y="-1"
                width="22"
                height="22"
                fill="#9EA0A5"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};
const LoanDetails = () => {
  return (
    <div className="loan_details">
      <div className="summary_box">
        <div className="summary_box-T">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16 15C16 15.552 15.552 16 15 16H5C4.448 16 4 15.552 4 15C4 14.448 4.448 14 5 14H15C15.552 14 16 14.448 16 15ZM5 10H15C15.552 10 16 10.448 16 11C16 11.552 15.552 12 15 12H5C4.448 12 4 11.552 4 11C4 10.448 4.448 10 5 10ZM4 7V5C4 4.448 4.448 4 5 4H9C9.552 4 10 4.448 10 5V7C10 7.552 9.552 8 9 8H5C4.448 8 4 7.552 4 7ZM18 17C18 17.552 17.552 18 17 18H3C2.448 18 2 17.552 2 17V3C2 2.448 2.448 2 3 2H12V6C12 7.104 12.895 8 14 8H18V17ZM19.707 5.707L14.293 0.293C14.105 0.105 13.851 0 13.586 0H2C0.895 0 0 0.895 0 2V18C0 19.104 0.895 20 2 20H18C19.105 20 20 19.104 20 18V6.414C20 6.149 19.895 5.895 19.707 5.707Z"
              fill="white"
              fill-opacity="0.01"
            />
            <mask
              id="mask0_9862_57231"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="20"
              height="20"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16 15C16 15.552 15.552 16 15 16H5C4.448 16 4 15.552 4 15C4 14.448 4.448 14 5 14H15C15.552 14 16 14.448 16 15ZM5 10H15C15.552 10 16 10.448 16 11C16 11.552 15.552 12 15 12H5C4.448 12 4 11.552 4 11C4 10.448 4.448 10 5 10ZM4 7V5C4 4.448 4.448 4 5 4H9C9.552 4 10 4.448 10 5V7C10 7.552 9.552 8 9 8H5C4.448 8 4 7.552 4 7ZM18 17C18 17.552 17.552 18 17 18H3C2.448 18 2 17.552 2 17V3C2 2.448 2.448 2 3 2H12V6C12 7.104 12.895 8 14 8H18V17ZM19.707 5.707L14.293 0.293C14.105 0.105 13.851 0 13.586 0H2C0.895 0 0 0.895 0 2V18C0 19.104 0.895 20 2 20H18C19.105 20 20 19.104 20 18V6.414C20 6.149 19.895 5.895 19.707 5.707Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_9862_57231)">
              <rect
                x="-1"
                y="-1"
                width="22"
                height="22"
                fill="#9EA0A5"
              />
            </g>
          </svg>
          <p>Summary</p>
        </div>
        <div className="summary_box-B">
          <div className="heading">
            <h2>Loan Details</h2>
          </div>
          <div className="stats">
            <div className="summary_box-left">
              <div className="row">
                <p>Financing Purpose...........</p>
                <span>Purchase</span>
              </div>
              <div className="row">
                <p>Requested Purchase Financing...</p>
                <span>$50,000.00</span>
              </div>
              <div className="row">
                <p>Requested Renovation Financing....</p>
                <span>$15,000.00</span>
              </div>
              <div className="row">
                <p>Total Requested Loan......</p>
                <span>$65,000.00</span>
              </div>
            </div>
            <div className="summary_box-right">
              <div className="row">
                <p>Loan Term.................</p>
                <span>12 Months</span>
              </div>
              <div className="row">
                <p>Loan Repayment Method..........</p>
                <span>Sell</span>
              </div>
              <div className="row">
                <p>Desired Closing Date......</p>
                <span>Sep. 12, 2020</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Documents = () => {
  return (
    <div className="documents">
      <div className="documents_box">
        <div className="documents_box-T">
          <div className="left">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5 14C5.552 14 6 14.448 6 15V17C6 17.552 5.552 18 5 18H3C2.448 18 2 17.552 2 17V15C2 14.448 2.448 14 3 14H5ZM8 18V14C8 12.895 7.105 12 6 12H5V8H6C7.105 8 8 7.105 8 6V2C8 0.895 7.105 0 6 0H2C0.895 0 0 0.895 0 2V6C0 7.105 0.895 8 2 8H3V12H2C0.895 12 0 12.895 0 14V18C0 19.105 0.895 20 2 20H6C7.105 20 8 19.105 8 18ZM5 2C5.552 2 6 2.448 6 3V5C6 5.552 5.552 6 5 6H3C2.448 6 2 5.552 2 5V3C2 2.448 2.448 2 3 2H5ZM17 14C17.552 14 18 14.448 18 15V17C18 17.552 17.552 18 17 18H15C14.448 18 14 17.552 14 17V15C14 14.448 14.448 14 15 14H17ZM20 18V14C20 12.895 19.105 12 18 12H17V5.005C17 3.9 16.105 2.995 15 2.995H12C12 1.99996 11.5564 1.99998 11.0082 2L11 2C10.448 2 10 2.452 10 3.005V5.005C10 5.557 10.448 6 11 6C11.552 6 12 5.995 12 4.995H14C14.552 4.995 15 5.452 15 6.005V12H14C12.895 12 12 12.895 12 14V18C12 19.105 12.895 20 14 20H18C19.105 20 20 19.105 20 18ZM15 12V12.005H17V12H15Z"
                fill="white"
                fill-opacity="0.01"
              />
              <mask
                id="mask0_9862_58217"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5 14C5.552 14 6 14.448 6 15V17C6 17.552 5.552 18 5 18H3C2.448 18 2 17.552 2 17V15C2 14.448 2.448 14 3 14H5ZM8 18V14C8 12.895 7.105 12 6 12H5V8H6C7.105 8 8 7.105 8 6V2C8 0.895 7.105 0 6 0H2C0.895 0 0 0.895 0 2V6C0 7.105 0.895 8 2 8H3V12H2C0.895 12 0 12.895 0 14V18C0 19.105 0.895 20 2 20H6C7.105 20 8 19.105 8 18ZM5 2C5.552 2 6 2.448 6 3V5C6 5.552 5.552 6 5 6H3C2.448 6 2 5.552 2 5V3C2 2.448 2.448 2 3 2H5ZM17 14C17.552 14 18 14.448 18 15V17C18 17.552 17.552 18 17 18H15C14.448 18 14 17.552 14 17V15C14 14.448 14.448 14 15 14H17ZM20 18V14C20 12.895 19.105 12 18 12H17V5.005C17 3.9 16.105 2.995 15 2.995H12C12 1.99996 11.5564 1.99998 11.0082 2L11 2C10.448 2 10 2.452 10 3.005V5.005C10 5.557 10.448 6 11 6C11.552 6 12 5.995 12 4.995H14C14.552 4.995 15 5.452 15 6.005V12H14C12.895 12 12 12.895 12 14V18C12 19.105 12.895 20 14 20H18C19.105 20 20 19.105 20 18ZM15 12V12.005H17V12H15Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_9862_58217)">
                <rect
                  x="-1"
                  y="-1"
                  width="22"
                  height="22"
                  fill="#9EA0A5"
                />
              </g>
            </svg>

            <p>documents</p>
          </div>
          <div className="right">
            <div className="button">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.9952 15.4689C13.9952 15.0765 12.9726 15.0805 12.5831 15.4719L11.8521 16.2084C11.5375 16.5245 10.9992 16.3028 10.9992 15.8552V7.03153C10.9992 6.48162 10.6007 6.03708 10.0555 6.03005C9.50819 6.03708 9.00187 6.48162 9.00187 7.03153V15.8552C9.00187 16.3028 8.46359 16.5265 8.14901 16.2104L7.41799 15.2672C7.02851 14.8758 6.40634 15.0594 6.01686 15.0594H6.01087C5.62139 16.0629 5.61839 16.2947 6.00887 16.6861L8.58942 19.3855C9.36938 20.1692 10.6337 20.2214 11.4136 19.4376L13.9952 16.8687C14.3857 16.4764 13.9952 15.8542 13.9952 15.4628V15.4689ZM10.0445 6.02805C10.0485 6.02805 10.0515 6.03005 10.0555 6.03005C10.0595 6.03005 10.0625 6.02805 10.0664 6.02805H10.0445ZM13.9952 14.0559C13.4439 14.0559 12.9965 13.6064 12.9965 13.0525C12.9965 12.4985 13.4439 12.049 13.9952 12.049H14.888C19.1423 12.049 18.8337 5.89559 14.894 6.03808C13.5478 -0.665213 3.21261 1.75419 5.24389 8.47756C3.16867 6.8198 0.677995 10.0279 2.79916 11.6115C4.29816 12.7313 7.00454 11.199 7.00454 13.0525C7.00454 14.3148 5.57845 14.0559 4.20928 14.0559C0.877728 14.0559 -1.17553 10.4835 0.731923 7.75204C1.6437 6.4475 3.07379 6.12539 3.07379 6.12539C3.73291 0.994555 9.56312 -1.71486 13.8814 1.18923C15.7349 2.43657 16.4379 4.2589 16.4379 4.2589C17.5275 4.59206 18.4622 5.28246 19.0984 6.19663C21.2405 9.27333 19.5757 14.0559 13.9952 14.0559Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_13801_291855"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.9952 15.4689C13.9952 15.0765 12.9726 15.0805 12.5831 15.4719L11.8521 16.2084C11.5375 16.5245 10.9992 16.3028 10.9992 15.8552V7.03153C10.9992 6.48162 10.6007 6.03708 10.0555 6.03005C9.50819 6.03708 9.00187 6.48162 9.00187 7.03153V15.8552C9.00187 16.3028 8.46359 16.5265 8.14901 16.2104L7.41799 15.2672C7.02851 14.8758 6.40634 15.0594 6.01686 15.0594H6.01087C5.62139 16.0629 5.61839 16.2947 6.00887 16.6861L8.58942 19.3855C9.36938 20.1692 10.6337 20.2214 11.4136 19.4376L13.9952 16.8687C14.3857 16.4764 13.9952 15.8542 13.9952 15.4628V15.4689ZM10.0445 6.02805C10.0485 6.02805 10.0515 6.03005 10.0555 6.03005C10.0595 6.03005 10.0625 6.02805 10.0664 6.02805H10.0445ZM13.9952 14.0559C13.4439 14.0559 12.9965 13.6064 12.9965 13.0525C12.9965 12.4985 13.4439 12.049 13.9952 12.049H14.888C19.1423 12.049 18.8337 5.89559 14.894 6.03808C13.5478 -0.665213 3.21261 1.75419 5.24389 8.47756C3.16867 6.8198 0.677995 10.0279 2.79916 11.6115C4.29816 12.7313 7.00454 11.199 7.00454 13.0525C7.00454 14.3148 5.57845 14.0559 4.20928 14.0559C0.877728 14.0559 -1.17553 10.4835 0.731923 7.75204C1.6437 6.4475 3.07379 6.12539 3.07379 6.12539C3.73291 0.994555 9.56312 -1.71486 13.8814 1.18923C15.7349 2.43657 16.4379 4.2589 16.4379 4.2589C17.5275 4.59206 18.4622 5.28246 19.0984 6.19663C21.2405 9.27333 19.5757 14.0559 13.9952 14.0559Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_13801_291855)">
                  <rect
                    x="-1"
                    y="-1"
                    width="22"
                    height="22"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>

              <p>Save</p>
            </div>

            <div className="button">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18 14.7619C18 15.0248 17.776 15.2381 17.5 15.2381H17V11.4286H3V15.2381H2.5C2.224 15.2381 2 15.0248 2 14.7619V8.57143C2 8.04571 2.448 7.61905 3 7.61905H17C17.552 7.61905 18 8.04571 18 8.57143V14.7619ZM15 16.1905C15 16.7162 14.552 17.1429 14 17.1429H6C5.448 17.1429 5 16.7162 5 16.1905V13.3333H15V16.1905ZM4 2.85714C4 2.33143 4.448 1.90476 5 1.90476H15C15.552 1.90476 16 2.33143 16 2.85714V5.71429H4V2.85714ZM18 5.71429V1.90476C18 0.852381 17.105 0 16 0H4C2.895 0 2 0.852381 2 1.90476V5.71429C0.895 5.71429 0 6.56667 0 7.61905V15.2381C0 16.2895 0.895 17.1429 2 17.1429H3C3 18.1943 3.895 19.0476 5 19.0476H15C16.105 19.0476 17 18.1943 17 17.1429H18C19.105 17.1429 20 16.2895 20 15.2381V7.61905C20 6.56667 19.105 5.71429 18 5.71429Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_9862_58244"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18 14.7619C18 15.0248 17.776 15.2381 17.5 15.2381H17V11.4286H3V15.2381H2.5C2.224 15.2381 2 15.0248 2 14.7619V8.57143C2 8.04571 2.448 7.61905 3 7.61905H17C17.552 7.61905 18 8.04571 18 8.57143V14.7619ZM15 16.1905C15 16.7162 14.552 17.1429 14 17.1429H6C5.448 17.1429 5 16.7162 5 16.1905V13.3333H15V16.1905ZM4 2.85714C4 2.33143 4.448 1.90476 5 1.90476H15C15.552 1.90476 16 2.33143 16 2.85714V5.71429H4V2.85714ZM18 5.71429V1.90476C18 0.852381 17.105 0 16 0H4C2.895 0 2 0.852381 2 1.90476V5.71429C0.895 5.71429 0 6.56667 0 7.61905V15.2381C0 16.2895 0.895 17.1429 2 17.1429H3C3 18.1943 3.895 19.0476 5 19.0476H15C16.105 19.0476 17 18.1943 17 17.1429H18C19.105 17.1429 20 16.2895 20 15.2381V7.61905C20 6.56667 19.105 5.71429 18 5.71429Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_9862_58244)">
                  <rect
                    x="-1"
                    y="-0.952148"
                    width="22"
                    height="20.9524"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
              <p>Print</p>
            </div>
            <div className="button">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16 18.073V18.0977C16 18.6221 15.552 18.9982 15 18.9982H2V19.0476C0.895 19.0476 0 18.2468 0 17.1981V4.8493C0 4.324 0.448 3.89939 1 3.89939C1.552 3.89939 2 4.324 2 4.8493V16.2482C2 16.7726 2.448 17.0984 3 17.0984H15C15.552 17.0984 16 17.5487 16 18.073V18.073ZM18 12.4486C18 12.9729 17.552 13.2988 17 13.2988H7C6.448 13.2988 6 12.9729 6 12.4486V2.94947C6 2.42417 6.448 1.89982 7 1.89982H12V5.79921C12 6.84791 12.895 7.59929 14 7.59929H18V12.4486ZM19.707 5.49619L14.293 0.327719C14.105 0.150086 13.851 0 13.586 0H6V0.0493954C4.895 0.0493954 4 0.925213 4 1.97392V1.99956V13.3985C4 14.4472 4.895 15.248 6 15.248V15.1986H18V15.248C19.105 15.248 20 14.4225 20 13.3729V6.19247C20 5.94074 19.895 5.67382 19.707 5.49619V5.49619Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_13801_363087"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16 18.073V18.0977C16 18.6221 15.552 18.9982 15 18.9982H2V19.0476C0.895 19.0476 0 18.2468 0 17.1981V4.8493C0 4.324 0.448 3.89939 1 3.89939C1.552 3.89939 2 4.324 2 4.8493V16.2482C2 16.7726 2.448 17.0984 3 17.0984H15C15.552 17.0984 16 17.5487 16 18.073V18.073ZM18 12.4486C18 12.9729 17.552 13.2988 17 13.2988H7C6.448 13.2988 6 12.9729 6 12.4486V2.94947C6 2.42417 6.448 1.89982 7 1.89982H12V5.79921C12 6.84791 12.895 7.59929 14 7.59929H18V12.4486ZM19.707 5.49619L14.293 0.327719C14.105 0.150086 13.851 0 13.586 0H6V0.0493954C4.895 0.0493954 4 0.925213 4 1.97392V1.99956V13.3985C4 14.4472 4.895 15.248 6 15.248V15.1986H18V15.248C19.105 15.248 20 14.4225 20 13.3729V6.19247C20 5.94074 19.895 5.67382 19.707 5.49619V5.49619Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_13801_363087)">
                  <rect
                    x="-1"
                    y="-0.952148"
                    width="22"
                    height="20.9524"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
              <p>Copy to Clipboard</p>
            </div>
          </div>
        </div>
        <div className="documents_box-B">
          <p> {"4EH#@887oseno8vnh89h352h9616816e1bf6df0#$5gh7&H*%^&*()(*guiqncnw"} </p>
        </div>
      </div>
      <div className="documents_box">
        <div className="documents_box-T">
          <div className="left">
            <h2>Uploaded Documents</h2>
          </div>
          <div className="right">
            <div className="search_bar">
              <input
                type="text"
                placeholder="Search"
              />
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 18.586L18.586 20L12.929 14.343L14.343 12.929L20 18.586ZM7 12C4.243 12 2 9.757 2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12ZM7 0C3.134 0 0 3.134 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_9862_58034"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20 18.586L18.586 20L12.929 14.343L14.343 12.929L20 18.586ZM7 12C4.243 12 2 9.757 2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12ZM7 0C3.134 0 0 3.134 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_9862_58034)">
                  <rect
                    opacity="0.5"
                    x="-1"
                    y="-1"
                    width="22"
                    height="22"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
            </div>

            <div className="button2">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18 4V0H16V4H14V6H16V20H18V6H20V4H18ZM11 0H9V9H7V11H9V20H11V11H13V9H11V0ZM4 14H6V16H4V20H2V16H0V14H2V0H4V14Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_9862_58051"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18 4V0H16V4H14V6H16V20H18V6H20V4H18ZM11 0H9V9H7V11H9V20H11V11H13V9H11V0ZM4 14H6V16H4V20H2V16H0V14H2V0H4V14Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_9862_58051)">
                  <rect
                    x="-1"
                    y="-1"
                    width="22"
                    height="22"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>

              <PrimaryButtonSvgFirst
                name={"Filter"}
                classNames={"white"}
              />
            </div>
            <div className="button2">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.9952 15.4689C13.9952 15.0765 12.9726 15.0805 12.5831 15.4719L11.8521 16.2084C11.5375 16.5245 10.9992 16.3028 10.9992 15.8552V7.03153C10.9992 6.48162 10.6007 6.03708 10.0555 6.03005C9.50819 6.03708 9.00187 6.48162 9.00187 7.03153V15.8552C9.00187 16.3028 8.46359 16.5265 8.14901 16.2104L7.41799 15.2672C7.02851 14.8758 6.40634 15.0594 6.01686 15.0594H6.01087C5.62139 16.0629 5.61839 16.2947 6.00887 16.6861L8.58942 19.3855C9.36938 20.1692 10.6337 20.2214 11.4136 19.4376L13.9952 16.8687C14.3857 16.4764 13.9952 15.8542 13.9952 15.4628V15.4689ZM10.0445 6.02805C10.0485 6.02805 10.0515 6.03005 10.0555 6.03005C10.0595 6.03005 10.0625 6.02805 10.0664 6.02805H10.0445ZM13.9952 14.0559C13.4439 14.0559 12.9965 13.6064 12.9965 13.0525C12.9965 12.4985 13.4439 12.049 13.9952 12.049H14.888C19.1423 12.049 18.8337 5.89559 14.894 6.03808C13.5478 -0.665213 3.21261 1.75419 5.24389 8.47756C3.16867 6.8198 0.677995 10.0279 2.79916 11.6115C4.29816 12.7313 7.00454 11.199 7.00454 13.0525C7.00454 14.3148 5.57845 14.0559 4.20928 14.0559C0.877728 14.0559 -1.17553 10.4835 0.731923 7.75204C1.6437 6.4475 3.07379 6.12539 3.07379 6.12539C3.73291 0.994555 9.56312 -1.71486 13.8814 1.18923C15.7349 2.43657 16.4379 4.2589 16.4379 4.2589C17.5275 4.59206 18.4622 5.28246 19.0984 6.19663C21.2405 9.27333 19.5757 14.0559 13.9952 14.0559Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_13801_315163"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.9952 15.4689C13.9952 15.0765 12.9726 15.0805 12.5831 15.4719L11.8521 16.2084C11.5375 16.5245 10.9992 16.3028 10.9992 15.8552V7.03153C10.9992 6.48162 10.6007 6.03708 10.0555 6.03005C9.50819 6.03708 9.00187 6.48162 9.00187 7.03153V15.8552C9.00187 16.3028 8.46359 16.5265 8.14901 16.2104L7.41799 15.2672C7.02851 14.8758 6.40634 15.0594 6.01686 15.0594H6.01087C5.62139 16.0629 5.61839 16.2947 6.00887 16.6861L8.58942 19.3855C9.36938 20.1692 10.6337 20.2214 11.4136 19.4376L13.9952 16.8687C14.3857 16.4764 13.9952 15.8542 13.9952 15.4628V15.4689ZM10.0445 6.02805C10.0485 6.02805 10.0515 6.03005 10.0555 6.03005C10.0595 6.03005 10.0625 6.02805 10.0664 6.02805H10.0445ZM13.9952 14.0559C13.4439 14.0559 12.9965 13.6064 12.9965 13.0525C12.9965 12.4985 13.4439 12.049 13.9952 12.049H14.888C19.1423 12.049 18.8337 5.89559 14.894 6.03808C13.5478 -0.665213 3.21261 1.75419 5.24389 8.47756C3.16867 6.8198 0.677995 10.0279 2.79916 11.6115C4.29816 12.7313 7.00454 11.199 7.00454 13.0525C7.00454 14.3148 5.57845 14.0559 4.20928 14.0559C0.877728 14.0559 -1.17553 10.4835 0.731923 7.75204C1.6437 6.4475 3.07379 6.12539 3.07379 6.12539C3.73291 0.994555 9.56312 -1.71486 13.8814 1.18923C15.7349 2.43657 16.4379 4.2589 16.4379 4.2589C17.5275 4.59206 18.4622 5.28246 19.0984 6.19663C21.2405 9.27333 19.5757 14.0559 13.9952 14.0559Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_13801_315163)">
                  <rect
                    x="-1"
                    y="-1"
                    width="22"
                    height="22"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>

              <PrimaryButtonSvgFirst
                name={"Export"}
                classNames={"white"}
              />
            </div>
          </div>
        </div>
        <div className="documents_box-B table">
          <div className="uploaded_docs">
            <div className="header">
              <div className="left">
                <p>Document Name</p>
                <p>Uploaded</p>
                <p>Owner</p>
              </div>
              <div className="right">
                <p>Action</p>
              </div>
            </div>
            <Row />
            <Row />
            <Row />
            <Row />
          </div>
        </div>
      </div>
    </div>
  );
};
const Row = () => {
  return (
    <div className="table_row">
      <div className="left">
        <div className="item first">
          {" "}
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: "#d8dce5 !important",
                  padding: "0px",
                  "&.Mui-checked": {
                    color: "#8059c7 !important",
                  },
                  "& .MuiSvgIcon-root": {fontSize: 22},
                }}
              />
            }
          />
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16 15C16 15.552 15.552 16 15 16H5C4.448 16 4 15.552 4 15C4 14.448 4.448 14 5 14H15C15.552 14 16 14.448 16 15ZM5 10H15C15.552 10 16 10.448 16 11C16 11.552 15.552 12 15 12H5C4.448 12 4 11.552 4 11C4 10.448 4.448 10 5 10ZM4 7V5C4 4.448 4.448 4 5 4H9C9.552 4 10 4.448 10 5V7C10 7.552 9.552 8 9 8H5C4.448 8 4 7.552 4 7ZM18 17C18 17.552 17.552 18 17 18H3C2.448 18 2 17.552 2 17V3C2 2.448 2.448 2 3 2H12V6C12 7.104 12.895 8 14 8H18V17ZM19.707 5.707L14.293 0.293C14.105 0.105 13.851 0 13.586 0H2C0.895 0 0 0.895 0 2V18C0 19.104 0.895 20 2 20H18C19.105 20 20 19.104 20 18V6.414C20 6.149 19.895 5.895 19.707 5.707Z"
              fill="white"
              fill-opacity="0.01"
            />
            <mask
              id="mask0_9862_58150"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="20"
              height="20"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16 15C16 15.552 15.552 16 15 16H5C4.448 16 4 15.552 4 15C4 14.448 4.448 14 5 14H15C15.552 14 16 14.448 16 15ZM5 10H15C15.552 10 16 10.448 16 11C16 11.552 15.552 12 15 12H5C4.448 12 4 11.552 4 11C4 10.448 4.448 10 5 10ZM4 7V5C4 4.448 4.448 4 5 4H9C9.552 4 10 4.448 10 5V7C10 7.552 9.552 8 9 8H5C4.448 8 4 7.552 4 7ZM18 17C18 17.552 17.552 18 17 18H3C2.448 18 2 17.552 2 17V3C2 2.448 2.448 2 3 2H12V6C12 7.104 12.895 8 14 8H18V17ZM19.707 5.707L14.293 0.293C14.105 0.105 13.851 0 13.586 0H2C0.895 0 0 0.895 0 2V18C0 19.104 0.895 20 2 20H18C19.105 20 20 19.104 20 18V6.414C20 6.149 19.895 5.895 19.707 5.707Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_9862_58150)">
              <rect
                x="-1"
                y="-1"
                width="22"
                height="22"
                fill="#9EA0A5"
              />
            </g>
          </svg>
          <div className="text_wrapper">
            <p>Contract.pdf</p>
            <span>8.6 MB</span>
          </div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p>Aug. 15, 2020</p>
            <span>10:23am</span>
          </div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p>Mike Butcher</p>
            <span>Lender</span>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="action_buttons">
          <div className="button">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 10.0005C12 11.1477 11.105 12.0768 10 12.0768C8.895 12.0768 8 11.1477 8 10.0005C8 8.85336 8.895 7.92421 10 7.92421C11.105 7.92421 12 8.85336 12 10.0005ZM10 13.9237C7.011 13.9237 4.195 12.4474 2.399 10.0005C4.195 7.55359 7.011 6.0763 10 6.0763C12.989 6.0763 15.805 7.55359 17.601 10.0005C15.805 12.4474 12.989 13.9237 10 13.9237ZM10 4C5.724 4 1.999 6.41682 0 10.0005C1.999 13.5842 5.724 16 10 16C14.276 16 18.001 13.5842 20 10.0005C18.001 6.41682 14.276 4 10 4Z"
                fill="white"
                fill-opacity="0.01"
              />
              <mask
                id="mask0_13801_268584"
                maskUnits="userSpaceOnUse"
                x="0"
                y="4"
                width="20"
                height="12"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 10.0005C12 11.1477 11.105 12.0768 10 12.0768C8.895 12.0768 8 11.1477 8 10.0005C8 8.85336 8.895 7.92421 10 7.92421C11.105 7.92421 12 8.85336 12 10.0005ZM10 13.9237C7.011 13.9237 4.195 12.4474 2.399 10.0005C4.195 7.55359 7.011 6.0763 10 6.0763C12.989 6.0763 15.805 7.55359 17.601 10.0005C15.805 12.4474 12.989 13.9237 10 13.9237ZM10 4C5.724 4 1.999 6.41682 0 10.0005C1.999 13.5842 5.724 16 10 16C14.276 16 18.001 13.5842 20 10.0005C18.001 6.41682 14.276 4 10 4Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_13801_268584)">
                <rect
                  x="-1"
                  y="-1"
                  width="22"
                  height="22"
                  fill="#9EA0A5"
                />
              </g>
            </svg>
          </div>
          <div className="button">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.9952 15.4689C13.9952 15.0765 12.9726 15.0805 12.5831 15.4719L11.8521 16.2084C11.5375 16.5245 10.9992 16.3028 10.9992 15.8552V7.03153C10.9992 6.48162 10.6007 6.03708 10.0555 6.03005C9.50819 6.03708 9.00187 6.48162 9.00187 7.03153V15.8552C9.00187 16.3028 8.46359 16.5265 8.14901 16.2104L7.41799 15.2672C7.02851 14.8758 6.40634 15.0594 6.01686 15.0594H6.01087C5.62139 16.0629 5.61839 16.2947 6.00887 16.6861L8.58942 19.3855C9.36938 20.1692 10.6337 20.2214 11.4136 19.4376L13.9952 16.8687C14.3857 16.4764 13.9952 15.8542 13.9952 15.4628V15.4689ZM10.0445 6.02805C10.0485 6.02805 10.0515 6.03005 10.0555 6.03005C10.0595 6.03005 10.0625 6.02805 10.0664 6.02805H10.0445ZM13.9952 14.0559C13.4439 14.0559 12.9965 13.6064 12.9965 13.0525C12.9965 12.4985 13.4439 12.049 13.9952 12.049H14.888C19.1423 12.049 18.8337 5.89559 14.894 6.03808C13.5478 -0.665213 3.21261 1.75419 5.24389 8.47756C3.16867 6.8198 0.677995 10.0279 2.79916 11.6115C4.29816 12.7313 7.00454 11.199 7.00454 13.0525C7.00454 14.3148 5.57845 14.0559 4.20928 14.0559C0.877728 14.0559 -1.17553 10.4835 0.731923 7.75204C1.6437 6.4475 3.07379 6.12539 3.07379 6.12539C3.73291 0.994555 9.56312 -1.71486 13.8814 1.18923C15.7349 2.43657 16.4379 4.2589 16.4379 4.2589C17.5275 4.59206 18.4622 5.28246 19.0984 6.19663C21.2405 9.27333 19.5757 14.0559 13.9952 14.0559Z"
                fill="white"
                fill-opacity="0.01"
              />
              <mask
                id="mask0_13801_315163"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.9952 15.4689C13.9952 15.0765 12.9726 15.0805 12.5831 15.4719L11.8521 16.2084C11.5375 16.5245 10.9992 16.3028 10.9992 15.8552V7.03153C10.9992 6.48162 10.6007 6.03708 10.0555 6.03005C9.50819 6.03708 9.00187 6.48162 9.00187 7.03153V15.8552C9.00187 16.3028 8.46359 16.5265 8.14901 16.2104L7.41799 15.2672C7.02851 14.8758 6.40634 15.0594 6.01686 15.0594H6.01087C5.62139 16.0629 5.61839 16.2947 6.00887 16.6861L8.58942 19.3855C9.36938 20.1692 10.6337 20.2214 11.4136 19.4376L13.9952 16.8687C14.3857 16.4764 13.9952 15.8542 13.9952 15.4628V15.4689ZM10.0445 6.02805C10.0485 6.02805 10.0515 6.03005 10.0555 6.03005C10.0595 6.03005 10.0625 6.02805 10.0664 6.02805H10.0445ZM13.9952 14.0559C13.4439 14.0559 12.9965 13.6064 12.9965 13.0525C12.9965 12.4985 13.4439 12.049 13.9952 12.049H14.888C19.1423 12.049 18.8337 5.89559 14.894 6.03808C13.5478 -0.665213 3.21261 1.75419 5.24389 8.47756C3.16867 6.8198 0.677995 10.0279 2.79916 11.6115C4.29816 12.7313 7.00454 11.199 7.00454 13.0525C7.00454 14.3148 5.57845 14.0559 4.20928 14.0559C0.877728 14.0559 -1.17553 10.4835 0.731923 7.75204C1.6437 6.4475 3.07379 6.12539 3.07379 6.12539C3.73291 0.994555 9.56312 -1.71486 13.8814 1.18923C15.7349 2.43657 16.4379 4.2589 16.4379 4.2589C17.5275 4.59206 18.4622 5.28246 19.0984 6.19663C21.2405 9.27333 19.5757 14.0559 13.9952 14.0559Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_13801_315163)">
                <rect
                  x="-1"
                  y="-1"
                  width="22"
                  height="22"
                  fill="#9EA0A5"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

const Comments = () => {
  return (
    <div className="comments_panel">
      <CommentBox />
      <CommentBox />
    </div>
  );
};
const CommentBox = () => {
  return (
    <div className="comment_box">
      <div className="left">
        <img
          src={profileIcon}
          alt=""
        />
      </div>
      <div className="right">
        <div className="right-T">
          <p>
            <span>Gregory Ray</span> commented:
          </p>
          <span>1 month ago</span>
        </div>
        <div className="right-M">
          <p>
            {
              "Can I Invest on behalf on my friend, I’ll provide my docs and Investor Certificate, but he’ll be a real Investor (100% his money)? If not can I borrow his money and then invest? Also, can I invest $48K, not $45K or $50?"
            }
          </p>
        </div>
        <div className="right-B">
          <p>2 days ago</p>
          <span>
            Reply{" "}
            <svg
              width="11"
              height="5"
              viewBox="0 0 11 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.40157 0.839024L9.11373 1.581C9.26605 1.74071 9.15206 1.99905 8.93065 1.99905H1.41711C1.1387 1.99905 1 2.22385 1 2.50022V2.49621C1 2.77258 1.1387 3.00038 1.41711 3.00038H8.92308C9.14551 3.00038 9.25899 3.27023 9.10465 3.42894L8.39652 4.15891C8.20486 4.35817 8.21344 4.67609 8.4167 4.86434L8.41771 4.86634C8.61844 5.05259 8.93317 5.04307 9.12231 4.84481L10.7247 3.1736C11.0934 2.78609 11.0913 2.18029 10.7211 1.79479L9.1329 0.153619C8.94426 -0.0426396 8.63206 -0.0521522 8.43183 0.132592L8.42679 0.137598C8.22403 0.324345 8.21293 0.63876 8.40157 0.839024Z"
                fill="white"
                fill-opacity="0.01"
              />
              <mask
                id="mask0_9862_57779"
                maskUnits="userSpaceOnUse"
                x="1"
                y="0"
                width="10"
                height="5"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.40157 0.839024L9.11373 1.581C9.26605 1.74071 9.15206 1.99905 8.93065 1.99905H1.41711C1.1387 1.99905 1 2.22385 1 2.50022V2.49621C1 2.77258 1.1387 3.00038 1.41711 3.00038H8.92308C9.14551 3.00038 9.25899 3.27023 9.10465 3.42894L8.39652 4.15891C8.20486 4.35817 8.21344 4.67609 8.4167 4.86434L8.41771 4.86634C8.61844 5.05259 8.93317 5.04307 9.12231 4.84481L10.7247 3.1736C11.0934 2.78609 11.0913 2.18029 10.7211 1.79479L9.1329 0.153619C8.94426 -0.0426396 8.63206 -0.0521522 8.43183 0.132592L8.42679 0.137598C8.22403 0.324345 8.21293 0.63876 8.40157 0.839024Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_9862_57779)">
                <rect
                  width="12.1"
                  height="5"
                  fill="#1665D8"
                />
              </g>
            </svg>
          </span>
        </div>
      </div>
    </div>
  );
};

const Updates = () => {
  return (
    <div className="updates_panel">
      <Updates_photos />
      <Updates_values />
    </div>
  );
};

const Updates_photos = () => {
  return (
    <div className="updates_photos">
      <div className="left">
        <img
          src={greendot}
          className="greendot"
          alt=""
        />
        <img
          src={updatesIcon}
          className="updatesIcon"
          alt=""
        />
      </div>
      <div className="right">
        <div className="right-T">
          <h3>
            <span>Isabelle Neal</span> from your team
          </h3>
          <p>Added 3 new photos.</p>
        </div>
        <div className="right-M">
          <div className="photos_wrapper">
            <img
              src={updatesPics}
              alt=""
            />
          </div>
        </div>
        <div className="right-B">
          <span>4 days ago, on August 15, 12:15am</span>
        </div>
      </div>
    </div>
  );
};
const Updates_values = () => {
  return (
    <div className="updates_photos">
      <div className="left">
        <img
          src={greendot}
          className="greendot"
          alt=""
        />
        <img
          src={updatesIcon}
          className="updatesIcon"
          alt=""
        />
      </div>
      <div className="right">
        <div className="right-T">
          <h3>You</h3>
          <p>Changed value</p>
        </div>
        <div className="right-M">
          <p>{"Design (Folder), app-design-final.sketch and landing-design.sketch."}</p>

          <div className="photos_wrapper">
            <img
              src={UpdatesCards}
              alt=""
            />
          </div>
        </div>
        <div className="right-B">
          <span>8 days ago, on August 11, 10:15am</span>
        </div>
      </div>
    </div>
  );
};
const FilterSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 4V0H16V4H14V6H16V20H18V6H20V4H18ZM11 0H9V9H7V11H9V20H11V11H13V9H11V0ZM4 14H6V16H4V20H2V16H0V14H2V0H4V14Z"
        fill="white"
        fill-opacity="0.01"
      />
      <mask
        id="mask0_9862_58051"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18 4V0H16V4H14V6H16V20H18V6H20V4H18ZM11 0H9V9H7V11H9V20H11V11H13V9H11V0ZM4 14H6V16H4V20H2V16H0V14H2V0H4V14Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_9862_58051)">
        <rect
          x="-1"
          y="-1"
          width="22"
          height="22"
          fill="#9EA0A5"
        />
      </g>
    </svg>
  );
};
