import lilypandsLogo from "../../../assets/svg/lilipadsLogomain.svg";
import PRsvg2 from "../../../assets/svg/PRsvg2.svg";
import {PrimaryButton} from "../../../components/reusableComponents/Buttons/Buttons";

export function ThirdSection({setSliderState}) {
  return (
    <div className="prThirdSection">
      <div className="signupCon-L">
        <div className="signupFirstSection signupsection">
          <div className="top_header">
            <img
              src={lilypandsLogo}
              alt=""
            />
            <div className="text_wrapper">
              <h2>Password recovery</h2>
              <p>Please check your inbox for a link to reset your password. Usualy it may appear in a couple minutes.</p>
            </div>
          </div>
          <p>Didn’t get the email? It could land in your Spam / Junk folder. Check them as well.</p>
          <div className="spacerLine30"></div>
          <h3>
            I’ve remembered password. <span>Sign In.</span>
          </h3>
          <PrimaryButton
            classNames=" noMargin "
            name="Next"
            onclick={() => {
              setSliderState("four");
            }}
          />
        </div>
      </div>
      <div className="signupCon-R ">
        <div className="container flex_reverse ">
          <div className="passwordRecovery_svg_wrapper">
            <img
              src={PRsvg2}
              alt=""
            />
          </div>
          <div className="color_stripes">
            <div className="stripe1"></div>
            <div className="stripe2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
