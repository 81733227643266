import React, {useState} from "react";
import "./CreateNewSAFE.scss";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";
import group from "../../../assets/Manage-syndicate/Svg/Group.svg";
import {PrimaryButtonNameFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import circle from "../../../assets/Manage-syndicate/Svg/circle-add.svg";

const CreateNewSAFE = () => {
  const [dropdownState, setDropdownState] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const variableData = ["Option A", "Option B", "Option C"];
  return (
    <div>
      <div style={{marginTop: "10px", marginBottom: "10px"}}>
        <h1>Create New SAFE</h1>
      </div>
      <div>
        <div className="CreateNewSAFE-Container">
          <div className="Container-header">
            <div className="Container-header-data">
              <h1>Financing document template</h1>
              <hr className="hr-tag-background" />
              <h3>Duration Type</h3>
              <div className="CreateNewSAFE-checkbox">
                <input
                  type="checkbox"
                  id="myCheckbox"
                  name="myCheckbox"
                />
                <label for="myCheckbox">Pre-money</label>
              </div>
              <hr />
              <div className="CreateNewSAFE-checkbox">
                <input
                  type="checkbox"
                  id="myCheckbox"
                  name="myCheckbox"
                />
                <label for="myCheckbox">Post-money</label>
              </div>
              <DropDown
                label="SAFE dociment template"
                data={variableData}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                dropdownState={dropdownState}
                dropdownSetter={setDropdownState}
                classDataName="Drop-down"
                margin="10px"
              />
              <DropDown
                label="Governing Law"
                data={variableData}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                dropdownState={dropdownState}
                dropdownSetter={setDropdownState}
                classDataName="yourCustomClassName"
                margin="10px"
              />
              <DropDown
                label="Side Letter"
                data={variableData}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                dropdownState={dropdownState}
                dropdownSetter={setDropdownState}
                classDataName="yourCustomClassName"
                margin="10px"
              />
              <p>separate terms that allows SAFE to be unchanged but adds extra benefits.</p>
              <span>Valuation cap</span>
              <input
                type="text"
                name=""
                className="input-text"
                id=""
                placeholder="$"
              />
              <p>Safe Type: Most favored nation cause automatically applies if neither</p>
            </div>
          </div>
          <div className="flex-container">
            <div className="flex-container-data">
              <div className="flex-container-data-flex">
                <img
                  src={group}
                  alt="image"
                />
                <h1>SAFE Documents Preview</h1>
              </div>
              <hr />
              <p>Terms entered in the form automatically updates in the documents below.</p>
              <div className="Create-data">
                <h1>SAFE Documents </h1>
                <p>Most Favored Nation, no Valuation Cap, no Discount</p>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi
                  nesciunt. Neque porro quisquam est, qui
                </p>
                <PrimaryButtonNameFirst
                  name="Copier"
                  classNames="CreateNewSAFE-Filter"
                  svgPath={circle}
                  padding="10px"
                  svgHeight="20px"
                  svgWidth="20px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewSAFE;
