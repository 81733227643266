import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import FullPageSlider from "../FullPageSlider.jsx";
import featurePropertyIcon from "../../../assets/portfolio/svgs/feature-property.png";
import featureAreaIcon from "../../../assets/portfolio/svgs/feature-area.png";
import featurePeopleIcon from "../../../assets/portfolio/svgs/feature-people.png";
import BtnEyeIcon from "../../../assets/portfolio/svgs/btn-eye.png";
import floorPlanIcon from "../../../assets/portfolio/svgs/floor-plan-icon.png";
import photo from "../../../assets/acqusition/svg/photo.svg";
import location from "../../../assets/acqusition/svg/location.svg";
import street from "../../../assets/acqusition/svg/street.svg";
import {PrimaryButtonSvg, PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import {useSelector} from "react-redux";
import ShimmerEffect from "../../../Layout/ShimmerEffect.js";
import CustomSlider from "./CustomSlider";
import axios from "axios";
import {useDispatch} from "react-redux";
import PropertyDetailsContent from "./PropertyDetailsContent.jsx";
import NewsSummaries from "./Market Survey/NewsSummaries.jsx";
import FinancialKPI from "./Quick Overview/FinancialKPI.jsx";
import OperatingIncomeAndExpenses from "./Quick Overview/OperatingIncomeAndExpenses.jsx";
import MarketAnalysis from "./Quick Overview/MarketAnalysis.jsx";
import Utilities from "./Quick Overview/Utilities.jsx";
import Maintenance from "./Quick Overview/Maintenance.jsx";
import AssetReviews from "./Market Survey/AssetReviews.jsx";
import ProsAndCons from "./Market Survey/ProsAndCons.jsx";
import {MarketSurvey, SocialNetworks, TwitterPost, ReviewBox, leftArrow} from "../portfolioAddtionalData/Social.js";
import defaultImage from "../../../assets/house-thailand-with-car-parked-front-it.jpg";
import {useNavigate} from "react-router-dom";
import {setAllowToStartGroup, setIsChatOpen, setReadToInvest} from "../../../store/reducers/userDetailsSlice.js";
import {selectProperty} from "../../../store/reducers/portfolioSlice";
import {clearDealsroomData, setShowDealsroomData} from "../../../store/reducers/DealsRoom/dealsroomSlice.js";

const PropertyDetails = ({selectedItem}) => {
  const [slider, setSlider] = useState(false);
  const [activeTab, setActiveTab] = useState("Photos");
  const [propertyDetailsIndex, setPropertyDetailsIndex] = useState(0);
  const [overviewTabIndex, setOverviewTabIndex] = useState(0);
  const [marketTabIndex, setMarketTabIndex] = useState(0);
  const [activeElement, setActiveElement] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataForMaintenance, setDataForMaintenance] = useState([]);
  const {idToken} = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  const [totalPhotos, setTotalPhotos] = useState(0);
  const [totalVideos, setTotalVideos] = useState(0);
  const [totalVRViews, setTotalVRViews] = useState(0);
  let selectedData = selectedItem;

  const PropertyDetailsTabs = ["Property Details", "Property Data Overview"];
  const overviewTabs = ["Financial KPI", "Operating Income & Expenses", "Market Analysis", "Utilities", "Maintenance"];
  // const marketTabs = ["News Summaries", "Social Network", "Asset Reviews", "Potential Pros & Cons"];

  useEffect(() => {
    if (propertyDetailsIndex === 4 && selectedItem && selectedItem._id) {
      handleMaintenanceTabClick(selectedItem._id);
    }
    setTimeout(() => {
      setLoading(false);
    }, 10);
  }, [propertyDetailsIndex, selectedItem, refresh]);

  const handleSlider = () => {
    setSlider(!slider);
  };

  const smoothScrollTo = (id) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({behavior: "smooth", block: "start"});
      if (!targetElement.classList.contains("targeted-element")) {
        targetElement.classList.add("targeted-element");
      }
    }
  };

  const handleTabClick = (index) => {
    if (index === 1) {
      smoothScrollTo("overview-container");
    } else if (index === 2) {
      smoothScrollTo("market-tab-container");
    }
    setPropertyDetailsIndex(index);
  };

  const renderTabContent = () => {
    switch (propertyDetailsIndex) {
      case 0:
      case 1:
      case 2:
        return (
          <PropertyDetailsContent
            selectedItem={selectedItem}
            triggerRefresh={() => setRefresh(!refresh)}
          />
        );
      default:
        return (
          <PropertyDetailsContent
            selectedItem={selectedItem}
            triggerRefresh={() => setRefresh(!refresh)}
          />
        );
    }
  };

  const renderOverviewTabContent = () => {
    switch (overviewTabIndex) {
      case 0:
        return <FinancialKPI selectedItem={selectedItem} />;
      case 1:
        return <OperatingIncomeAndExpenses selectedItem={selectedItem} />;
      case 2:
        return <MarketAnalysis selectedItem={selectedItem} />;
      case 3:
        return <Utilities selectedItem={selectedItem} />;
      case 4:
        return (
          <Maintenance
            data={dataForMaintenance}
            selectedItem={selectedItem}
          />
        );
      default:
        return <FinancialKPI selectedItem={selectedItem} />;
    }
  };

  const renderMarketTabContent = () => {
    switch (marketTabIndex) {
      case 0:
        return <NewsSummaries selectedItem={selectedItem} />;
      case 1:
        return <SocialNetworks selectedItem={selectedItem} />;
      case 2:
        return <AssetReviews selectedItem={selectedItem} />;
      case 3:
        return <ProsAndCons selectedItem={selectedItem} />;
      default:
        return <NewsSummaries selectedItem={selectedItem} />;
    }
  };

  // const handleShowMoreDetails = () => {
  //   dispatch({type: "SET_SHOW_MORE_DETAILS", payload: !showMoreDetails});
  // };

  const handleEditAssetInfoClick = () => {
    localStorage.setItem("selectedAsset", JSON.stringify(selectedItem));
  };

  const handleMaintenanceTabClick = async (assetId) => {
    if (!selectedItem || !selectedItem._id) {
      return;
    }

    try {
      setLoading(true);
      const config = {
        headers: {
          "auth-token": idToken,
        },
        params: {
          assetId: assetId,
        },
      };
      const response = await axios.get("/api/Portfolio/getallownerdata", config);
      setDataForMaintenance(response.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  // send id
  const dealsroom = () => {
    if (selectedData) {
      dispatch(selectProperty(selectedData));
      dispatch(setShowDealsroomData(false));
      navigate(`/${selectedData?._id}/dealsroom`);
    } else {
      alert("Please select a property.");
    }
  };

  const handleStartGroup = async () => {
    dispatch(setAllowToStartGroup(true));
    dispatch(setIsChatOpen());
  };

  let financedData = useSelector((state) => state.financialSlice);
  // google Map
  const openGoogleMap = () => {
    const latAndLong = selectedData?.lat_and_long;
    if (latAndLong && latAndLong?.length === 1 && latAndLong[0]?.length === 2) {
      const latitude = latAndLong[0][0];
      const longitude = latAndLong[0][1];
      const googleMapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
      window.open(googleMapUrl, "_blank");
    }
  };

  // For Street View
  const openStreetView = () => {
    const latAndLong = selectedData?.lat_and_long;
    if (latAndLong && latAndLong.length === 1 && latAndLong[0].length === 2) {
      const latitude = latAndLong[0][0];
      const longitude = latAndLong[0][1];
      const streetViewUrl = `https://www.google.com/maps?q=&layer=c&cbll=${latitude},${longitude}`;
      window.open(streetViewUrl, "_blank");
    }
  };

  return (
    <div className="property-details-container">
      {slider && <FullPageSlider handleSlider={handleSlider} />}
      <div className="details-header-container">
        <div className="details-header-left">
          <Link
            to="#"
            onClick={() => setActiveTab("Photos")}
            className={activeTab === "Photos" ? "active" : ""}
          >
            <img
              src={photo}
              alt="image"
            />
            <span>Photos</span>
          </Link>
          <Link
            to="#"
            onClick={() => setActiveTab("Location")}
            className={activeTab === "Location" ? "active" : ""}
          >
            <img
              src={location}
              alt="image"
            />
            <span onClick={openGoogleMap}>Location</span>
          </Link>
          <Link
            to="#"
            onClick={() => setActiveTab("Street View")}
            className={activeTab === "Street View" ? "active" : ""}
          >
            <img
              src={street}
              alt="image"
            />
            <span onClick={openStreetView}>Street View</span>
          </Link>
        </div>
        <div className="details-header-right">
          <h2>STATUS:</h2>
          <div className="status-input-wrapper">
            <span class="Greendot"></span>
            <h1>{"Active"}</h1>
          </div>
        </div>
      </div>
      <div className="property-details">
        <div className="property-main-container">
          {loading ? (
            <ShimmerEffect />
          ) : (
            <CustomSlider
              key={selectedItem ? selectedItem._id : undefined}
              images={selectedItem?.assetsImages || []}
              totalPhotos={selectedItem?.assetsImages?.length}
              totalVideos={totalVideos}
              totalVRViews={totalVRViews}
              defaultImage={defaultImage}
              // onImageUpload={handleImageUpload}
              selectedItem={selectedItem}
            />
          )}
          <div className="property-tabs">
            {PropertyDetailsTabs?.map((tab, index) => (
              <Link
                key={index}
                className={propertyDetailsIndex === index ? "active" : ""}
                onClick={() => handleTabClick(index)}
              >
                {tab}
              </Link>
            ))}
          </div>
          <div className="tab-content">{renderTabContent()}</div>
        </div>
        <div className="property-features-container">
          {loading ? (
            <ShimmerEffect />
          ) : (
            <div className="property-features">
              <h2>Property Feature</h2>
              <div className="details-wrapper">
                <div className="detail-item">
                  <div className="item-type">
                    <img
                      src={featurePropertyIcon}
                      alt="property-icon"
                    />
                    <h4>Property type</h4>
                  </div>
                  <p>{selectedItem?.assetType}</p>
                </div>
                <div className="detail-item">
                  <div className="item-type">
                    <img
                      src={featureAreaIcon}
                      alt="property-icon"
                    />
                    <h4>Total area</h4>
                  </div>
                  <p>{selectedItem?.assetSquareFeet}</p>
                </div>
                <div className="detail-item">
                  <div className="item-type">
                    <img
                      src={featurePeopleIcon}
                      alt="property-icon"
                    />
                    <h4>Suitable for</h4>
                  </div>
                  <p>{selectedItem?.suitableFor}</p>
                </div>
              </div>
              <PrimaryButtonSvgFirst
                classNames="white-bg noMargin secondary"
                name="Show More"
                svgPath={BtnEyeIcon}
                padding={"2px 0px"}
                svgHeight={"24px"}
                svgWidth={"24px"}
                style={{background: "red"}}
                onclick={dealsroom}
              />

              {/* <Link
                to="/portfolio/edit-asset-info"
                className="edit_asset_btn_portfolio"
                onClick={handleEditAssetInfoClick}
              > */}
              <PrimaryButtonSvgFirst
                classNames="white-bg noMargin secondary"
                name="View Chat"
                padding={"2px 0px"}
                svgHeight={"24px"}
                svgWidth={"24px"}
                onclick={handleStartGroup}
              />

              <div className="line">&nbsp;</div>
              <Link
                to=""
                onClick={handleSlider}
                className="view_floor_btn_portfolio"
              >
                <PrimaryButtonSvgFirst
                  classNames="white-bg noMargin secondary"
                  name="View Floor Plan"
                  svgPath={floorPlanIcon}
                  padding={"2px 0px"}
                  svgHeight={"24px"}
                  svgWidth={"24px"}
                />
              </Link>
            </div>
          )}
        </div>
      </div>
      <div
        className={`overview-container ${activeElement === "overview-container" ? "targeted-element" : ""}`}
        id="overview-container"
      >
        <h1 className="overview-header">Quick Overview</h1>
        <div className="overview-tabs">
          {overviewTabs?.map((tab, index) => (
            <Link
              key={index}
              className={overviewTabIndex === index ? "active" : ""}
              onClick={() => setOverviewTabIndex(index)}
              financedData={financedData}
            >
              {tab}
            </Link>
          ))}
        </div>
        <div className="overview-tab-content">{renderOverviewTabContent()}</div>
      </div>

      {/* Market Survey component has been commented out for now. Uncomment and integrate when ready. */}
      {/* <div
        className={`market-tab-container ${activeElement === "market-tab-container" ? "targeted-element" : ""}`}
        id="market-tab-container"
      >
        <h1 className="market-header">Market Survey</h1>
        <div className="market-tabs">
          {marketTabs.map((tab, index) => (
            <Link
              key={index}
              className={marketTabIndex === index ? "active" : ""}
              onClick={() => setMarketTabIndex(index)}
            >
              {tab}
            </Link>
          ))}
        </div>
        <div className="market-tab-content">{renderMarketTabContent()}</div>
      </div> */}
    </div>
  );
};

export {MarketSurvey, PropertyDetailsContent, SocialNetworks, ReviewBox, leftArrow, PropertyDetails};
