import React, {useEffect, useState} from "react";
import "./DealsRoom.scss";
import SvgColorChanger from "../DealsRoom/SvgColorChanger";

import back from "../../assets/dealsroom/svg/back.svg";

import rect from "../../assets/dealsroom/images/Rectangle.jpeg";

import {ReactComponent as Assetinformation} from "../../assets/dealsroom/svg/assetinformation.svg";

import {ReactComponent as Dealroom} from "../../assets/dealsroom/svg/dealroom.svg";

import {ReactComponent as KeyDeals} from "../../assets/svg/key-deal.svg";

import {ReactComponent as Dealeconomics} from "../../assets/svg/investment.svg";

import {ReactComponent as Loanrequest} from "../../assets/svg/group.svg";

import {ReactComponent as Syndicate} from "../../assets/dealsroom/svg/syndicate.svg";

import {ReactComponent as Checklist} from "../../assets/dealsroom/svg/checklist.svg";

import {ReactComponent as Valuatio} from "../../assets/svg/business-plan.svg";

import {ReactComponent as History} from "../../assets/svg/general-partner.svg";

import dropdown from "../../assets/dealsroom/svg/dropdown.svg";

import {Link, useLocation, useNavigate} from "react-router-dom";

import AssetInformation from "./AssetInformation";
import Investment from "./Investment";
import BusinessPlan from "./BusinessPlan";
import Team from "./Team";
import Disclaimer from "./Disclaimer";
import GeneralPartner from "./GeneralPartner";
import KeyDeal from "./KeyDeal";

const NewOfferingsSubPages = (props) => {
  const [isClicked, setIsClicked] = useState(1);
  const [syndicateState, setSyndicate] = useState(1);
  const [loanReqState, setLoanReq] = useState(1);
  const [lenderResponseTab, setLenderResponseTab] = useState(0);
  const handleMenuClicked = (data) => {
    setIsClicked(data);
    if (data === 4) {
      setLoanReq(1);
    } else if (data == 5) {
      setSyndicate(1);
    }
    // if (data === 12) {
    //   setLoanReq(2);
    // }
  };
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {state} = useLocation();
  useEffect(() => {
    if (state) {
      setIsClicked(state);
    } else {
      setIsClicked(isClicked);
    }
  }, [state]);

  return (
    <div className="new-offerings-deals deals-room">
      <div className="deals-room-left">
        <Link to={"/investment-pipeline"}>
          <div className="acqu">
            <img
              src={back}
              alt=""
            />
            <span>New Offerings</span>
          </div>
        </Link>
        <div className="deal-title">
          <div className="deal-left-img">
            <img
              src={rect}
              alt="Rectangle deal img"
            />
          </div>
          <div className="deal-left-img-text">
            <span>Aurora Offices</span>
            <span>8781 Waltham Woods Rd Parkville, MD 21234, USA</span>
          </div>
        </div>
        <div
          style={{margin: "20px 0"}}
          className="deal-room-left-menu"
        >
          <span>main menu</span>
        </div>

        <LeftMenu
          icon={<Assetinformation />}
          name={"Asset Information"}
          id={1}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenu
          icon={<KeyDeals />}
          name={"Key Deal Points"}
          id={2}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenu
          icon={<Dealeconomics />}
          name={"Investments"}
          id={3}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenu
          icon={<Valuatio />}
          name={"Business Plan"}
          id={1110}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenu
          icon={<History />}
          name={"General Partner"}
          id={19}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenu
          icon={<Loanrequest />}
          name={"Team"}
          id={1111}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />

        <LeftMenu
          icon={<Dealroom />}
          name={"Disclaimer"}
          id={23}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
      </div>
      <div className="new-offerings-wrapper center-wrapper">
        {isClicked === 1 && <AssetInformation />}
        {isClicked === 2 && <KeyDeal />}
        {isClicked === 3 && <Investment />}
        {isClicked === 1110 && <BusinessPlan />}
        {isClicked === 1111 && <Team />}
        {isClicked === 23 && <Disclaimer />}
        {isClicked === 19 && <GeneralPartner />}
      </div>
    </div>
  );
};

export default NewOfferingsSubPages;

const LeftMenu = ({icon, name, id, state, handleMenuClicked}) => {
  return (
    <div
      className={`deals-left-menu ${id === state && "deals-menu-active"}`}
      onClick={() => {
        handleMenuClicked(id);
      }}
    >
      <SvgColorChanger svgContent={icon} />

      <span className="left-menu-title">{name} </span>
    </div>
  );
};
const LeftMenuDrop = ({icon, name, id, state, handleMenuClicked}) => {
  return (
    <div
      className={`deals-left-menu drop ${id === state && "deals-menu-active"}`}
      onClick={() => {
        handleMenuClicked(id);
      }}
    >
      <div>
        <SvgColorChanger svgContent={icon} />

        <span className="left-menu-title">{name} </span>
      </div>

      <img
        src={dropdown}
        alt=""
      />
    </div>
  );
};

const LeftMenuSort = ({icon, name, id, state, handleMenuClicked}) => {
  return (
    <div
      className={`deals-left-menu left-menu-sort ${id === state && "deals-menu-active-sort"}`}
      onClick={() => {
        handleMenuClicked(id);
      }}
    >
      <SvgColorChanger svgContent={icon} />

      <span className="left-menu-title">{name} </span>
    </div>
  );
};
