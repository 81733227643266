import {IOSSwitch, InputBox} from "../../../components/reusableComponents/FormFields/FormFields";

import cross from "../../../assets/svg/cross.svg";

const BulkBox = ({handleBulk}) => {
  return (
    <div className="bulkbox">
      <div className="invite_modal">
        <div className="invitebox">
          <div className="basic_header">
            <p>Bulk addition of tasks</p>
            <img
              onClick={handleBulk}
              src={cross}
              alt=""
            />
          </div>

          <div className="inputs">
            <div>
              <InputBox
                label={"Task Name"}
                type={"text"}
              />
            </div>
            <span>Separate Tasks with comma , or Enter</span>
          </div>
          <div className="btns">
            <div>
              <button className="btn_cancel">Cancel</button>
            </div>
            <div style={{display: "flex", gap: "12px", alignItems: "center"}}>
              <button className="btn_right">Add Tasks</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default BulkBox