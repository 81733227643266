import React, {useState} from "react";
import Convertibles from "../../../assets/Manage-syndicate/image/Convertibles.png";
import Checkmark from "../../../assets/Manage-syndicate/Svg/Checkmark.svg";
import TotalCompensationimage from "../../../assets/Manage-syndicate/image/Total Compensation.png";
import relationship from "../../../assets/Manage-syndicate/Svg/relationship.svg";
import "./DealTeam.scss";
import {PrimaryButton, PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";

const DealTeam = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What can employee discuss in a one-on -one session?",
      answer:
        "Compare that have raised at last $10M in funding OR have least 500 stakeholders on their cap table are required to disclose their stock based compensation expenses on their income statement",
    },
    {
      question: "What qualification do Equity Advisor have",
      answer:
        "Compare that have raised at last $10M in funding OR have least 500 stakeholders on their cap table are required to disclose their stock based compensation expenses on their income statement",
    },
    {
      question: "What are the benefits of educating about their equity compensation ",
      answer:
        "Compare that have raised at last $10M in funding OR have least 500 stakeholders on their cap table are required to disclose their stock based compensation expenses on their income statement",
    },
    {
      question: "What does Equity Advisory include?",
      answer: "Equity Advisory include: Unlimited one-on -ine sessions with a dedicated Equity Tax Advisor Company-specific webinars Access to our of educational on- demand content.",
    },
  ];
  return (
    <div>
      <div>
        <div className="DealTeam-container-image">
          <div className="DealTeam-container-image-data">
            <div className="DealTeam-Data">
              <div className="DealTeam-divconatiner">
                <div className="DealTeam-image">
                  <img
                    src={relationship}
                    alt="img"
                  />
                </div>
              </div>
              <PrimaryButton
                name="Carta Deal Team"
                classNames="DealTeam-Deal"
             
                svgHeight="20px"
                svgWidth="20px"
              />
              <div>
                <PrimaryButton
                  name="Add-On"
                  classNames="DealTeam-Add"
    
                  svgHeight="20px"
                  svgWidth="20px"
                />
              </div>
              <h1>Save money and close your financing with Carta</h1>
              <hr className="linetag" />
              <div className="DealTeam-flex">
                <img
                  src={Checkmark}
                  alt="image"
                />
                <p>Reduce admin costs</p>
              </div>
              <div className="DealTeam-flex">
                <img
                  src={Checkmark}
                  alt="image"
                />
                <p>Get an expert them in your corner</p>
              </div>
              <div className="DealTeam-flex">
                <img
                  src={Checkmark}
                  alt="image"
                />
                <p>Data to inform the best deal terms</p>
              </div>
              <PrimaryButtonSvgFirst
                name="Schedule a call"
                classNames="CaptableStake-Filter"
                svgHeight="20px"
                svgWidth="20px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealTeam;
