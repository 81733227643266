import React from "react";
import Chart from "react-apexcharts";
import "./Style.scss";

const EarningsDistributionsChart = () => {
  const options = {
    chart: {
      type: "bar",
      height: "100%",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val + "K";
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "K";
        },
      },
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    colors: ["#EAECEE", "#7D55C7"],
    title: {
      text: "Earnings Distributions",
      align: "left",
      style: {
        fontSize: "18px",
        fontWeight: "bold",
        color: "#000",
      },
    },
  };

  const series = [
    {
      name: "Reach",
      data: [200, 300, 350, 400, 320, 280, 330, 310],
    },
    {
      name: "Potential Reach / Goals",
      data: [150, 250, 300, 350, 280, 260, 300, 300],
    },
  ];

  return (
    <div id="chart">
      <div className="chart-container">
        <Chart
          options={options}
          series={series}
          type="bar"
          height={400}
          width={"100%"}
        />
      </div>
    </div>
  );
};

export default EarningsDistributionsChart;
