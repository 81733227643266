import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faDownload, faPlus, faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import {PrimaryButton, PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import "./Warrants.scss";
import SearchInput from "../../../components/reusableComponents/Search/Search";
import filter from "../../../assets/Manage-syndicate/Svg/filter.svg";
import error from "../../../assets/Manage-syndicate/Svg/error.svg";

const Warrants = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const options = [
    {value: "", label: "Action"},
    {value: "2nd", label: "2nd"},
    {value: "3rd", label: "3rd"},
  ];

  return (
    <div>
      <div style={{marginTop: "10px", marginBottom: "10px"}}>
        <h1>Warrants</h1>
      </div>
      <div className="Warrants-conatiner">
        <div className="Share-Draft">
        <SearchInput />
          <div className="Warrants-button">
            <PrimaryButtonSvgFirst
              name="Filter"
              classNames="Warrants-Filter"
              svgPath={filter}
              padding="10px"
              svgHeight="20px"
              svgWidth="20px"
            />
            <div className="select-wrapper">
              <select
                name="Action"
                id="Action"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option
                  value=""
                  disabled
                  selected
                >
                  Action
                </option>
                <option value="2nd">
                  2nd <i className="fas fa-arrow-up"></i>
                </option>
                <option value="3rd">
                  3rd <i className="fas fa-arrow-down"></i>
                </option>
              </select>
            </div>
            <span className="View-Share-cLasses">View Share Classes</span>
            <PrimaryButton
              name="Draft Shares"
              classNames="Warrants-Shares-button"
            />
          </div>
        </div>
      </div>
      <div>
          <div className="Equityplans-data">
            <div className="Equityplans-data-container-maindiv">
              <div className="Equityplans-Error-Container">
                <div className="Equityplans-data-container-image">
                  <img
                    src={error}
                    alt="image"
                  />
                </div>
                <p>No equity plan have been created</p>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Warrants;
