import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {ReactComponent as InfoIcon} from "../../../../assets/portfolio/svgs/info-icon.svg";
import ProgressBar from "@ramonak/react-progress-bar";

import AvgMarketGraph from "../../../../assets/portfolio/images/market-graph.png";
import AvgMarketBarGraph from "../../../../assets/portfolio/images/market-avg-graph.png";
import BaseMarketGraph from "../../../../assets/portfolio/images/market-base-graph.png";
import BaseMarketBarGraph from "../../../../assets/portfolio/images/market-base-bargraph.png";

const MarketAnalysis = ({selectedItem}) => {
  const userPermissions = useSelector((state) => state.portfolio.assetPermissions);
  const userInformation = useSelector((state) => state.userInfo.userInformation);
  const roleType = userInformation?.roleTypes;

  const hasEditPermission = (section, permission) => {
    const foundPermission = userPermissions?.find((perm) => perm.section === section);
    return foundPermission ? foundPermission.permissions[permission] === true : false;
  };

  const hasMarketAnalysisPermission = roleType === 2 || hasEditPermission("Property Data Overview", "Market Analysis");

  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editPopupTitle, setEditPopupTitle] = useState("");

  const handleEditClick = (title) => {
    setEditPopupTitle(title);
    setShowEditPopup(true);
  };

  // useEffect(() => {

  // }, [hasMarketAnalysisPermission]);

  return (
    <div>
      <div className="Edit_MarketAnalysis">
        {hasMarketAnalysisPermission && (
          <button
            className="edit-button"
            onClick={() => handleEditClick("Average Market vs Base Rent")}
          >
            Edit
          </button>
        )}
      </div>
      <div className="market-analysis-card">
        <h1>Average Market vs NER</h1>
        <div className="market-analysis-grids">
          <img
            className="market-analysis-grid-1"
            src={AvgMarketGraph}
            alt="Average Market Graph"
          />
          <div className="market-analysis-grid-2">
            <img
              src={AvgMarketBarGraph}
              alt="Average Market Bar Graph"
            />
            <div className="graph-labels">
              <div className="label">
                <p style={{backgroundColor: "#7d55c7"}}>&nbsp;</p>
                <h4>You</h4>
              </div>
              <div className="label">
                <p style={{backgroundColor: "#dcecfd"}}>&nbsp;</p>
                <h4>Benchmark</h4>
              </div>
            </div>
          </div>
          <div className="market-analysis-grid-3">
            <p>+3%</p>
            <h1 style={{fontSize: "38px", fontWeight: "300", lineHeight: "60px"}}>$66.20</h1>
            <h6>/SF/YR</h6>
            <h2>$67.20</h2>
            <h2 style={{color: "#9EA0A5"}}>$67.20</h2>
          </div>
        </div>

        <h1>Average Market vs Base Rent</h1>
        <div className="market-analysis-grids">
          <img
            className="market-analysis-grid-1"
            src={BaseMarketGraph}
            alt="Base Market Graph"
          />
          <div className="market-analysis-grid-2">
            <img
              src={BaseMarketBarGraph}
              alt="Base Market Bar Graph"
            />
            <div className="graph-labels">
              <div className="label">
                <p style={{backgroundColor: "#7d55c7"}}>&nbsp;</p>
                <h4>You</h4>
              </div>
              <div className="label">
                <p style={{backgroundColor: "#dcecfd"}}>&nbsp;</p>
                <h4>Benchmark</h4>
              </div>
            </div>
          </div>
          <div className="market-analysis-grid-3">
            <p>+3%</p>
            <h1 style={{fontSize: "38px", fontWeight: "300", lineHeight: "60px"}}>$66.20</h1>
            <h6>/SF/YR</h6>
            <h2>$67.20</h2>
            <h2 style={{color: "#9EA0A5"}}>$67.20</h2>
          </div>
        </div>

        {showEditPopup && (
          <EditPopup
            isOpen={showEditPopup}
            onClose={() => setShowEditPopup(false)}
            title={editPopupTitle}
          />
        )}
      </div>
    </div>
  );
};

const EditPopup = ({isOpen, onClose, title}) => {
  if (!isOpen) return null;

  return (
    <div className="edit_popup_MarketAnalysis">
      <div className="popup_content_MarketAnalysis">
        <h2>{title}</h2>
        <p>Content to edit {title}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default MarketAnalysis;
