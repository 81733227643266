import React, {useState} from "react";
import "./TotalCompensation.scss";
import Convertibles from "../../../assets/Manage-syndicate/image/Convertibles.png";
import Checkmark from "../../../assets/Manage-syndicate/Svg/Checkmark.svg";
import EquityAdvisoryimage from "../../../assets/Manage-syndicate/image/EquityAdvisory.png";
import "./EquityAdvisory.scss";
import {PrimaryButton} from "../../../components/reusableComponents/Buttons/Buttons";

const EquityAdvisory = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What can employee discuss in a one-on -one session?",
      answer:
        "Compare that have raised at last $10M in funding OR have least 500 stakeholders on their cap table are required to disclose their stock based compensation expenses on their income statement",
    },
    {
      question: "What qualification do Equity Advisor have",
      answer:
        "Compare that have raised at last $10M in funding OR have least 500 stakeholders on their cap table are required to disclose their stock based compensation expenses on their income statement",
    },
    {
      question: "What are the benefits of educating about their equity compensation ",
      answer:
        "Compare that have raised at last $10M in funding OR have least 500 stakeholders on their cap table are required to disclose their stock based compensation expenses on their income statement",
    },
    {
      question: "What does Equity Advisory include?",
      answer: "Equity Advisory include: Unlimited one-on -ine sessions with a dedicated Equity Tax Advisor Company-specific webinars Access to our of educational on- demand content.",
    },
  ];

  return (
    <div>
      <div>
        <div className="EquityAdvisory-container-image">
          <div className="EquityAdvisory-container-image-data">
            <div className="EquityAdvisory-Data">
              <img
                src={EquityAdvisoryimage}
                alt="image"
              />
              <h1>The QSBS tax break could save you up to 100% in capital gains</h1>
              <hr className="linetag" />
              <div className="EquityAdvisory-flex">
                <img
                  src={Checkmark}
                  alt="image"
                />
                <p>Review of tax break gligibility</p>
              </div>
              <div className="EquityAdvisory-flex">
                <img
                  src={Checkmark}
                  alt="image"
                />
                <p>Attenstation letters for eligible shareholders</p>
              </div>
              <div className="EquityAdvisory-flex">
                <img
                  src={Checkmark}
                  alt="image"
                />
                <p>Guidance for tax saving strategies</p>
              </div>
              <div className="EquityAdvisory-button">
                <PrimaryButton
                  name="Schedule a call"
                  classNames="EquityAdvisory-Schedule"
                  padding="10px"
                  svgHeight="20px"
                  svgWidth="20px"
                />
                <PrimaryButton
                  name="Preview Letter"
                  classNames="EquityAdvisory-Preview"
                  padding="10px"
                  svgHeight="20px"
                  svgWidth="20px"
                />
              </div>
            </div>
            <div className="EquityAdvisory-wrapper">
              <h1>Frequently Asked Questions</h1>
              {faqData.map((faq, index) => (
                <div
                  className="EquityAdvisory"
                  key={index}
                >
                  <button
                    className={`EquityAdvisory-accordion ${activeIndex === index ? "active" : ""}`}
                    onClick={() => toggleAccordion(index)}
                  >
                    {faq.question}
                    <i className="fa-solid fa-chevron-down"></i>
                  </button>
                  <div className={`EquityAdvisory-pannel ${activeIndex === index ? "active" : ""}`}>
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EquityAdvisory;
