import React, {useEffect, useState} from "react";
import "./ProfileSettings.scss";
import {useSelector, useDispatch} from "react-redux";
import axios from "axios";
import Button from "@mui/material/Button";
import {styled} from "@mui/material/styles";
import {fetchUserDetails} from "../../store/reducers/userDetailsSlice";
import Basic from "./settings/Basic";
import Password from "./settings/Password";
import Notifications from "./settings/Notifications";
import Integration from "./settings/Integration";
import info from "../../assets/svg/info.svg";
import Assetinformation from "../../pages/crowdFunding/AssetInformation";
import {getUserDetails} from "../../utils/Axios";
import profile_logo from "../../assets/profile.png";
import {logout} from "../../store/reducers/authSlice";
import {clearPersistedData} from "../../store/store";
import {Link, useNavigate} from "react-router-dom";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ProfileSettings = () => {
  const [isClicked, setIsClicked] = useState(1);
  const [selectedFile, setSelectedFile] = useState(null);
  const {idToken} = useSelector((state) => state.auth);
  const [userImage, setUserImage] = useState();
  const [refresh, setRefresh] = useState(false);
  const [userDetails, setDetails] = useState();
  const dispatch = useDispatch();
  const {userInformation} = useSelector((state) => state.auth);
  const {_id} = useSelector((state) => state.auth.userInformation);
  let [loading, setLoading] = useState(false);
  const navigate = useNavigate();



  const [userData, setUserData] = useState({
    name: "",
    surname: "",
    email: "",
    phone_number: "",
    role: "",
    department: "",
    street_address: "",
    secondary_address: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    time_zone: "",
    about_me: "",
  });

  useEffect(() => {
    if (userDetails) {
      setUserData({
        name: userDetails.name || "",
        surname: userDetails.surname || "",
        email: userDetails.email || "",
        phone_number: userDetails.phone_number || "",
        role: userDetails.role || "",
        department: userDetails.department || "",
        street_address: userDetails.street_address || "",
        secondary_address: userDetails.secondary_address || "",
        city: userDetails.city || "",
        state: userDetails.state || "",
        zip_code: userDetails.zip_code || "",
        country: userDetails.country || "",
        time_zone: userDetails.time_zone || "",
        about_me: userDetails.about_me || "",
      });
    }
  }, [userDetails]);

  const handleMenuClicked = (data) => {
    setIsClicked(data);
  };

  useEffect(() => {
    (async () => {
      const response = await getUserDetails(idToken);
      if (response?.success) {
        setUserImage(response?.data?.profile_image);
      }
    })();
  }, [dispatch, refresh]);

  useEffect(() => {
    (async () => {
      const response = await getUserDetails(idToken);
      if (response?.success) {
        setDetails(response.data);
      }
    })();
  }, [idToken]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setSelectedFile(file);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("imagedata", selectedFile);
      formData.append("userId", _id);
      try {
        setLoading(true);
        const response = await axios.post("/api/auth/uploads/profile", formData, {
          headers: {
            "auth-token": idToken,
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data.success) {
          setRefresh(!refresh);
          setSelectedFile(null);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  // Auto Logout

  useEffect(() => {
    if (!userInformation?.name || !userInformation?.surname) {
      handleLogout();
    }
  }, [userInformation]);

  const handleLogout = () => {
    dispatch(logout());
    clearPersistedData();
    navigate("/");
  };

  useEffect(() => {
    dispatch(fetchUserDetails(idToken));
  }, [dispatch, refresh]);

  const [localTime, setLocalTime] = useState("");

  useEffect(() => {
    const updateLocalTime = () => {
      const now = new Date();
      const timeZoneOffset = (now.getTimezoneOffset() / 60) * -1;
      const localTimeDate = new Date(now.getTime() + timeZoneOffset * 60 * 60 * 1000);
      const formattedLocalTime = localTimeDate.toLocaleTimeString([], {hour: "2-digit", minute: "2-digit"});
      setLocalTime(formattedLocalTime);
    };

    const interval = setInterval(updateLocalTime, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="profile_settings">
      <div className="settings_left">
        <div className="settings_left_upper">
          <div className="img_div">
            <img
              src={userInformation?.profile_image || profile_logo}
              alt="User"
              className="setting_user_img"
            />
          </div>

          <div className="file_btn">
            <input
              type="file"
              accept="image/jpeg, image/png"
              onChange={handleFileChange}
            />
          </div>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            className="file_upload_btn"
            size="small"
          >
            Choose
            <VisuallyHiddenInput
              type="file"
              onChange={handleFileChange}
            />
          </Button>
          {selectedFile && (
            <div
              onClick={handleUpload}
              className={`upload_btnnew ${loading ? "loading" : ""}`}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: loading ? "#e0e0e0" : "#1976d2",
                color: "#fff",
                width: "95%",
                margin: "0 auto",
                padding: "10px 20px",
                borderRadius: "4px",
                cursor: loading ? "not-allowed" : "pointer",
                transition: "background-color 0.3s",
                marginTop: "10px",
                opacity: loading ? 0.7 : 1,
              }}
              disabled={loading}
            >
              {loading ? <div className="spinner-ProfileSettings"></div> : "Upload"}
            </div>
          )}

          <div>
            <p>{`${userInformation?.name} ${userInformation?.surname}`}</p>
            {/* <span>
              {userInformation?.street_address}, {userInformation?.secondary_address}, {userInformation?.city}
              <br></br>
              {userInformation?.state} ,Your Time {localTime}
            </span> */}
          </div>
        </div>
        <div className="settings_left_lower">
          <span>Your Details</span> <br />
          <span
            style={{fontSize: "11px", color: "#9ea0a5"}}
            className="user_short_details"
          >
            Baltimore, MD, United States<br></br>
            Your time: {localTime}
          </span>
          <div className="profile_setting_tabination">
            <div>
              <LeftMenu
                icon={<Assetinformation />}
                name={"Profile Settings"}
                id={1}
                state={isClicked}
                handleMenuClicked={handleMenuClicked}
              />
            </div>
            <div>
              <LeftMenu
                icon={<Assetinformation />}
                name={"Password"}
                id={2}
                state={isClicked}
                handleMenuClicked={handleMenuClicked}
              />
            </div>

            <div>
              <LeftMenu
                icon={<Assetinformation />}
                name={"Notifications"}
                id={3}
                state={isClicked}
                handleMenuClicked={handleMenuClicked}
              />
            </div>

            <div>
              <LeftMenu
                icon={<Assetinformation />}
                name={"Integrations"}
                id={4}
                state={isClicked}
                handleMenuClicked={handleMenuClicked}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="settings_right">
        {isClicked === 1 && (
          <Basic
            userData={userData}
            setUserData={setUserData}
            userDetails={userDetails}
            setDetails={setDetails}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        )}
        {isClicked === 2 && <Password />}
        {isClicked === 3 && <Notifications />}
        {isClicked === 4 && <Integration />}
      </div>
    </div>
  );
};

export default ProfileSettings;

const LeftMenu = ({icon, name, id, state, handleMenuClicked}) => {
  return (
    <div
      className={`deals-left-menu  ${id === state && name === "Integrations" && "int"} ${id === state && name != "Integrations" && "profile_menu_active"}`}
      onClick={() => {
        handleMenuClicked(id);
      }}
    >
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline", width: "90%"}}>
        <span
          style={{display: "flex", gap: "10px"}}
          className={`title_menu ${id === state && "title_menu_active"}`}
        >
          {name}
          {name === "Integrations" && id === state ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.1665 14.166H10.8332V9.16602H9.1665V14.166ZM9.99984 1.66602C5.39984 1.66602 1.6665 5.39935 1.6665 9.99935C1.6665 14.5993 5.39984 18.3327 9.99984 18.3327C14.5998 18.3327 18.3332 14.5993 18.3332 9.99935C18.3332 5.39935 14.5998 1.66602 9.99984 1.66602ZM9.99984 16.666C6.32484 16.666 3.33317 13.6743 3.33317 9.99935C3.33317 6.32435 6.32484 3.33268 9.99984 3.33268C13.6748 3.33268 16.6665 6.32435 16.6665 9.99935C16.6665 13.6743 13.6748 16.666 9.99984 16.666ZM9.1665 7.49935H10.8332V5.83268H9.1665V7.49935Z"
                fill="white"
              />
            </svg>
          ) : (
            name === "Integrations" && (
              <img
                src={info}
                alt=""
              />
            )
          )}
        </span>
        {name === "Integrations" && <span className={`soon ${id === state && "title_menu_active soon_active"}`}>Soon</span>}
      </div>
    </div>
  );
};
