import { useEffect, useState } from 'react'

import './PageLayout.scss'
import HeaderOne from '../Headers/HeaderOne'
import NewHeader from '../Headers/NewHeader'

const PageLayout = (props) => {
  return (
    <div className="PageL-M">
      <NewHeader crossActive={props.crossActive} />

      <div className="PageL-RB">{props.children}</div>
    </div>
  )
}

export default PageLayout
