import React from "react";
import "./CabTable.scss";
import {PrimaryButton, PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import SearchInput from "../../../components/reusableComponents/Search/Search";
import filter from "../../../assets/Manage-syndicate/Svg/filter.svg";

const CabTable = () => {
  const data = [
    {
      name: "CA- 58",
      authors: "Belien S. Tadesse and Belay A. Mengistu",
      status: "Available",
      amount: "200,000",
      price: "$1.00",
      total: "$200,000.00",
      date: "04/06/2022",
      moreInfo: "3dot",
    },
    {
      name: "CA- 58",
      authors: "Belien S. Tadesse and Belay A. Mengistu",
      status: "Available",
      amount: "200,000",
      price: "$1.00",
      total: "$200,000.00",
      date: "04/06/2022",
      moreInfo: "3dot",
    },
    {
      name: "CA- 58",
      authors: "Belien S. Tadesse and Belay A. Mengistu",
      status: "Available",
      amount: "200,000",
      price: "$1.00",
      total: "$200,000.00",
      date: "04/06/2022",
      moreInfo: "3dot",
    },
    {
      name: "CA- 58",
      authors: "Belien S. Tadesse and Belay A. Mengistu",
      status: "Available",
      amount: "200,000",
      price: "$1.00",
      total: "$200,000.00",
      date: "04/06/2022",
      moreInfo: "3dot",
    },
    {
      name: "CA- 58",
      authors: "Belien S. Tadesse and Belay A. Mengistu",
      status: "Available",
      amount: "200,000",
      price: "$1.00",
      total: "$200,000.00",
      date: "04/06/2022",
      moreInfo: "3dot",
    },
    {
      name: "CA- 58",
      authors: "Belien S. Tadesse and Belay A. Mengistu",
      status: "Available",
      amount: "200,000",
      price: "$1.00",
      total: "$200,000.00",
      date: "04/06/2022",
      moreInfo: "3dot",
    },
  ];
  return (
    <div>
      <div className="colordata">
        <div className="CabTable-data">
          <div>
            <SearchInput />
          </div>
          <div className="CabTable-data-flex">
            <PrimaryButtonSvgFirst
              name="Filter"
              classNames="CabTable-Filter"
              svgPath={filter}
              padding="10px"
              svgHeight="20px"
              svgWidth="20px"
            />
            <div className="select-wrapper">
              <select
                name="Action"
                id="Action"
              >
                <option
                  value=""
                  disabled
                  selected
                >
                  Action
                </option>
                <option value="2nd">
                  2nd <i class="fas fa-arrow-up"></i>
                </option>
                <option value="3rd">
                  3rd <i class="fas fa-arrow-down"></i>
                </option>
              </select>
            </div>
            <span>View Share cLasses</span>
            <PrimaryButton
              name="Draft Shares"
              classNames="Report-see-plan"
              svgPath="/path/to/svg/image.svg"
              padding="10px"
              svgHeight="20px"
              svgWidth="20px"
            />
          </div>
        </div>
      </div>
      <div>
        {/* Body-data */}
        <div className="CabTable-container-data">
          <div className="CabTable-container">
            <div className="CabTable-container-first-data">
              <div className="CabTable-dropdown">
                <input
                  type="checkbox"
                  name=""
                  id=""
                />
                <div class="dropdown">
                  <button class="dropbtn">SECURITY &#9660;</button>
                  <div
                    class="dropdown-content"
                    id="myDropdown"
                  >
                    <a href="#">opt-1</a>
                    <a href="#">opt-2</a>
                    <a href="#">opt-3</a>
                  </div>
                </div>
                <div class="dropdown">
                  <button class="dropbtn">STAKEHOLDER &#9660;</button>
                  <div
                    class="dropdown-content"
                    id="myDropdown"
                  >
                    <a href="#">opt-1</a>
                    <a href="#">opt-2</a>
                    <a href="#">opt-3</a>
                  </div>
                </div>
              </div>
              <div className="CabTable-dropdown">
                <div class="dropdown">
                  <button class="dropbtn">STATUS &#9660;</button>
                  <div
                    class="dropdown-content"
                    id="myDropdown"
                  >
                    <a href="#">opt-1</a>
                    <a href="#">opt-2</a>
                    <a href="#">opt-3</a>
                  </div>
                </div>
                <div class="dropdown">
                  <button class="dropbtn">SHARES &#9660;</button>
                  <div
                    class="dropdown-content"
                    id="myDropdown"
                  >
                    <a href="#">opt-1</a>
                    <a href="#">opt-2</a>
                    <a href="#">opt-3</a>
                  </div>
                </div>
                <div class="dropdown">
                  <button class="dropbtn">PRICE/SHARE &#9660;</button>
                  <div
                    class="dropdown-content"
                    id="myDropdown"
                  >
                    <a href="#">opt-1</a>
                    <a href="#">opt-2</a>
                    <a href="#">opt-3</a>
                  </div>
                </div>
                <div class="dropdown">
                  <button class="dropbtn">TRANSECTION VALUE &#9660;</button>
                  <div
                    class="dropdown-content"
                    id="myDropdown"
                  >
                    <a href="#">opt-1</a>
                    <a href="#">opt-2</a>
                    <a href="#">opt-3</a>
                  </div>
                </div>
                <div class="dropdown">
                  <button class="dropbtn">ISSUE DATE &#9660;</button>
                  <div
                    class="dropdown-content"
                    id="myDropdown"
                  >
                    <a href="#">opt-1</a>
                    <a href="#">opt-2</a>
                    <a href="#">opt-3</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="color">
        {data.map((item, index) => (
          <div
            key={index}
            className="user-details-container"
          >
            <div className="user-details">
              <input
                type="checkbox"
                name=""
                id=""
              />
              <h5>{item.name}</h5>
              <h5>{item.authors}</h5>
              <div className="dot"></div>
              <span>{item.status}</span>
              <h5>{item.amount}</h5>
              <h5>{item.price}</h5>
              <h5>{item.total}</h5>
              <h5>{item.date}</h5>
              <h5>{item.moreInfo}</h5>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CabTable;
