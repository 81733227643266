import { createSlice } from "@reduxjs/toolkit";

const recommendationsSlice = createSlice({
  name: "recommendations",
  initialState: {
    data: [],
  },
  reducers: {
    setRecommendations(state, action) {
      state.data = action.payload;
    },
    clearRecommendations(state) {
      state.data = [];
    },
  },
});

export const { setRecommendations, clearRecommendations } = recommendationsSlice.actions;
export default recommendationsSlice.reducer;