// investmentSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Endpoint from '../../../utils/Endpoint';


export const fetchInvestmentDetails = createAsyncThunk(
  'investment/fetchInvestmentDetails',
  async (ReadyToInvest_id, { getState, rejectWithValue }) => {
    const { idToken } = getState().auth;

    try {
      const response = await axios.get(Endpoint.PaymentsDetails, {
        params: { ReadyToInvest_id },
        headers: { 'auth-token': idToken },
      });
      if (response.data.success) {
        return response.data.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(error.response?.status || 500);
    }
  }
);

const investmentSlice = createSlice({
  name: 'investment',
  initialState: {
    payment: null,
    status: 'idle',
    error: null,
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvestmentDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchInvestmentDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.payment = action.payload;
      })
      .addCase(fetchInvestmentDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default investmentSlice.reducer;
