import React, {useState, useEffect} from "react";
import arrowup from "../../../assets/Image/assetDiscovery/arrow_down.svg";
import Checkbox from "../../profile/settings/Checkbox";
import "./TimeTableBar.scss";
import {format, addDays, subDays} from "date-fns";

export const TimeTableBar = ({getTaskdata}) => {
  const [checkedState, setCheckedState] = useState(getTaskdata ? getTaskdata.map(() => false) : []);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [linePosition, setLinePosition] = useState(0);

  const handleCheckboxChange = (position) => {
    const updatedCheckedState = checkedState?.map((item, index) => (index === position ? !item : item));
    setCheckedState(updatedCheckedState);
  };

  const handleFixCheckboxes = () => {
    setCheckedState(getTaskdata?.map(() => true));
  };

  const getTasksForSelectedDate = () => {
    const formattedSelectedDate = format(selectedDate, "yyyy-MM-dd");
    return getTaskdata?.filter((task) => format(new Date(task.date), "yyyy-MM-dd") === formattedSelectedDate);
  };

  const tasksForDate = getTasksForSelectedDate();

  const handleDateChange = (direction) => {
    if (direction === "next") {
      setSelectedDate((prevDate) => addDays(prevDate, 1));
    } else if (direction === "previous") {
      setSelectedDate((prevDate) => subDays(prevDate, 1));
    }
  };

  useEffect(() => {
    const updateTimelinePosition = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const totalMinutes = hours * 60 + minutes;

      const position = ((totalMinutes - 360) / 60) * 60;
      setLinePosition(position);
    };

    updateTimelinePosition();

    const interval = setInterval(updateTimelinePosition, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="timetable_bar">
      <div className="timetable_bar_header">
        <button
          className="button_task_left"
          onClick={() => handleDateChange("previous")}
        >
          <img
            src={arrowup}
            alt="previous"
            style={{rotate: "90deg"}}
          />
        </button>
        <span className="calender_date_data">{format(selectedDate, "MMMM dd, yyyy")}</span>
        <button
          className="button_task_right"
          onClick={() => handleDateChange("next")}
        >
          <img
            src={arrowup}
            alt="next"
            style={{rotate: "270deg"}}
          />
        </button>
      </div>

      <div className="timetable_bar_agenda">
        <span className="title">Agenda</span>
        <div className="agenda_list">
          {tasksForDate && tasksForDate.length > 0 ? (
            tasksForDate.map((task, index) => (
              <div
                key={task._id}
                className="agenda_item"
              >
                <div className="TimeTableBar_agenda_item">
                  <div
                    className="agenda_indicator"
                    style={{backgroundColor: task?.color || "grey"}}
                  />
                  <Checkbox
                    label={checkedState[index] ? <s>{task?.Name}</s> : task?.Name}
                    checked={checkedState[index]}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </div>
              </div>
            ))
          ) : (
            <p>No tasks {format(selectedDate, "MMMM dd, yyyy")}</p>
          )}
        </div>
      </div>
      {/* <div className="timetable_bar_details">
        <div className="timetable-container">
          <h3 className="timetable-title">TIMETABLE</h3>
          <div className="time-slot">
            {tasksForDate && tasksForDate.length > 0 ? (
              tasksForDate.map((task) => (
                <div key={task._id} className="time-row">
                  <span className="task-name">{task?.Name}</span>
                </div>
              ))
            ) : (
              <p>No tasks scheduled</p>
            )}
          </div>
        </div>
      </div> */}
      <div className="timetable_bar_details">
        <div className="timetable-container">
          <h3 className="timetable-title">TIMETABLE</h3>
          <div className="time-slot">
            <div className="time-row">
              <span className="time">6 AM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">7 AM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">8 AM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">9 AM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">10 AM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">11 AM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">12 PM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">1 PM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">2 PM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">3 PM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">4 PM</span>
              <div className="timetable_bar_divider" />
            </div>

            <div className="time-row">
              <span className="time">5 PM</span>
              <div className="timetable_bar_divider" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
