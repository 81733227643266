import React from "react";
import map_svg from "../../assets/svg/map_svg.svg";
import mobile_icon from "../../assets/svg/Mobile_icon.svg";
import email_svg from "../../assets/svg/email_svg.svg";
import fb_icon from "../../assets/Image/facebook_icon.png";
import twitter_icon from "../../assets/svg/twitter_svg.svg";
import link_svg from "../../assets/svg/link_svgggg.svg";
import goLink from "../../assets/svg/go_link.svg";
import linkdin_icon from "../../assets/svg/lindin_icon.svg";
import location_svg from "../../assets/svg/location.svg";
import google_icon from "../../assets/svg/google.svg";
import career_grp from "../../assets/svg/career_group.svg";
import education_icon from "../../assets/svg/educaton_icon.svg";
import award_icon from "../../assets/svg/award_svg.svg";
import signal_svg from "../../assets/svg/signal_svg.svg";
import technical_svg from "../../assets/svg/technical_svg.svg";
import life360 from "../../assets/svg/360Life.svg";
import mozart_icon from "../../assets/svg/mozart_data_icon.svg";
import Samasource from "../../assets/svg/samasourse_icon.svg";
import technical_2 from "../../assets/svg/technical_2.svg";
import life_2 from "../../assets/svg/life_2.svg";
import mozart_2 from "../../assets/svg/mozart-2.svg";
import asset_2 from "../../assets/svg/asset_2.svg";
import estate from "../../assets/svg/estate_icon.svg";
import Syndicates from "./OpportunitiesSearch/Syndicates";

const Investor = () => {
  return (
    <div className="details_container">
      <div className="overview_container">
        <div className="overview_without_contact">
          <div className="overview_heading">
            <h1> Investor Overview</h1>
          </div>
          <div className="bio_container">
            <div className="bio_heading">
              <h1>Bio</h1>
            </div>
            <div className="bio_info">
              <p>
                Working in automotive quality management for over 3 years. A passionate traveller, loves to spend time with her dog. Wine taster. Biggest dream is to travel the world with all the
                money I make from renting building to my dearest customers who pay well.{" "}
              </p>
            </div>
            <div className="tags">
              <h1>Tags</h1>
              <div
                className="tags_names"
                style={{display: "flex", alignItems: "center", gap: "10px"}}
              >
                <p style={{background: "#7EC331"}}>Long Term</p>
                <p style={{background: "linear-gradient(0deg, #1665D8 2.22%, #1F6FE5 98.44%)"}}>Offices</p>
                <p style={{background: "#ED962D"}}>Development</p>
              </div>
            </div>
          </div>
          <div className="hr_line"></div>
          <div className="total_profit">
            <div>
              <h1>$376,722,967</h1>
              <p>Total Portfolio Activity</p>
            </div>
            <div>
              <h1>240</h1>
              <p>Assets Under Management</p>
            </div>
            <div>
              <h1>45</h1>
              <p>Offerings on Lilypads</p>
            </div>
          </div>
        </div>

        <div className="contact_info">
          <div className="contact_info_heading">
            <h1>Contact Info</h1>
          </div>
          <div className="contact_info_details">
            <div className="contact_type">
              <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                <img
                  src={mobile_icon}
                  alt=""
                />
                <p>+1 7887 665 221</p>
              </div>
              <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                <img
                  src={fb_icon}
                  alt=""
                />
                <p>facebook.com/ NorthGroupTrust</p>
                <img
                  src={goLink}
                  alt=""
                />
              </div>
            </div>

            <div className="contact_type">
              <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                <img
                  src={email_svg}
                  alt=""
                />
                <p>me@angenewton.co</p>
              </div>
              <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                <img
                  src={twitter_icon}
                  alt=""
                />
                <p>twitter.com/ NorthGroupTrust</p>
                <img
                  src={goLink}
                  alt=""
                />
              </div>
            </div>

            <div className="contact_type">
              <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                <img
                  src={link_svg}
                  alt=""
                />
                <p>www.heritageattampa.com</p>
                <img
                  src={goLink}
                  alt=""
                  height="20px"
                  width="20px"
                />
              </div>
              <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                <img
                  src={linkdin_icon}
                  alt=""
                />
                <p>linkedin.com/ NorthGroupTrust</p>
                <img
                  src={goLink}
                  alt=""
                />
              </div>
            </div>
            <div className="contact_type">
              <div style={{display: "flex", alignItems: "center", gap: "20px", border: "none"}}>
                <img
                  src={location_svg}
                  alt=""
                />
                <div style={{display: "flex", flexDirection: "column", gap: "0px", border: "none"}}>
                  <p>
                    Works in <b>Baltimore, MD</b>
                  </p>
                  <span>Originally from Austin, TX</span>
                </div>
              </div>
              <div style={{display: "flex", alignItems: "center", gap: "20px", border: "none"}}>
                <img
                  src={google_icon}
                  alt=""
                />
                <p>facebook.com/ NorthGroupTrust</p>
              </div>
            </div>
          </div>
        </div>

        <div className="career">
          <div className="career_heading">
            <h1>Career</h1>
          </div>
          <div className="career_info">
            <div className="career_item_1">
              <img
                src={career_grp}
                alt=""
              />
              <div className="invest_info">
                <p>NorthGroup Invest</p>
                <b>Investment Consultant</b>
                <span>2017 – Present</span>
              </div>
              <div></div>
            </div>

            <div className="career_item_2">
              <img
                src={career_grp}
                alt=""
              />
              <div className="invest_info">
                <p>Independent Investor</p>
                <b>2005 – 2017</b>
                <span>I have worked with fortune 500 clients, mid level and start up companies. Specialties: Sales, Account Management, Sales Management </span>
              </div>
              <div></div>
            </div>
          </div>
        </div>

        <div className="education">
          <div className="education_heading">
            <h1>Education</h1>
          </div>
          <div
            className="education_info"
            style={{display: "flex", flexDirection: "column", gap: "20px"}}
          >
            <div
              className="education_item_1"
              style={{paddingTop: "15px"}}
            >
              <img
                src={education_icon}
                alt=""
              />
              <div className="education_info">
                <p>University of Iowa</p>
                <b>Bachelor's Degree Sport & Recreation Management</b>
                <span>2013 – 2015</span>
              </div>
              <div></div>
            </div>
            <div className="education_item_1">
              <img
                src={education_icon}
                alt=""
              />
              <div className="education_info">
                <p>Hawkeye Community College</p>
                <b>Bachelor's Degree Sport & Recreation Management</b>
                <span>1999 – 2004</span>
              </div>
              <div></div>
            </div>
          </div>
          <div className="licenses">
            <div className="licenses_heading">
              <h1>Licenses & Certifications</h1>
            </div>
            <div className="licenses_item">
              <img
                src={award_icon}
                alt=""
              />
              <div className="licenses_desc">
                <p>Accredited Investor</p>
                <span>Since 2009</span>
              </div>
            </div>
          </div>
        </div>

        <div className="Investment">
          <div className="investment_heading">
            <h1>Investment interests</h1>
            <span>Based on Portfolio</span>
          </div>
          <div className="deal_match">
            <div className="deal_item_1">
              <div className="total_deals">68</div>
              <p>Deals</p>
            </div>
            <div className="deal_item_2">
              <img
                src={signal_svg}
                alt=""
              />
              <p>80% Match for your activities</p>
            </div>
          </div>

          <div
            className="property_holdPeriod"
            style={{display: "flex", gap: "30px"}}
          >
            <div className="property_type">
              <h2>Property types</h2>
              <div style={{display: "flex", justifyContent: "space-between", paddingTop: "14px"}}>
                <p>Built-to-Rent</p>
                <p>24</p>
              </div>
              <div style={{display: "flex", justifyContent: "space-between", paddingTop: "14px"}}>
                <p>Industrial</p>
                <p>45</p>
              </div>
              <div style={{display: "flex", justifyContent: "space-between", paddingTop: "14px"}}>
                <p>Retail</p>
                <p>2</p>
              </div>
            </div>
            <div className="hold_period">
              <h2>Hold period</h2>
              <div style={{display: "flex", justifyContent: "space-between", paddingTop: "14px"}}>
                <p>Less than 1 Year</p>
                <p>24</p>
              </div>
            </div>
          </div>
          <div
            className="size_regions"
            style={{display: "flex", gap: "30px"}}
          >
            <div className="size__">
              <h2>Property types</h2>
              <div style={{display: "flex", justifyContent: "space-between", paddingTop: "14px"}}>
                <p>Built-to-Rent</p>
                <p>24</p>
              </div>
            </div>
            <div className="regions">
              <h2>Regions</h2>
              <div style={{display: "flex", justifyContent: "space-between", paddingTop: "14px"}}>
                <p>Midwest</p>
                <p>1</p>
              </div>
              <div style={{display: "flex", justifyContent: "space-between", paddingTop: "14px"}}>
                <p>Southwest</p>
                <p>13</p>
              </div>
            </div>
          </div>

          <div className="investment_experience">
            <div className="investment_experience_heading">
              <h1>Investment Experience</h1>
            </div>
            <div className="investment_experience_details">
              <div className="investment_experience_item">
                <img
                  src={technical_svg}
                  alt=""
                />
                <p>Invenia Technical Computing</p>
                <span>2020 Series A</span>
              </div>
              <div className="investment_experience_item">
                <img
                  src={life360}
                  alt=""
                />
                <p>Life360</p>
                <span>Life360</span>
              </div>
              <div className="investment_experience_item">
                <img
                  src={mozart_icon}
                  alt=""
                />
                <p>Mozart Data YC S20</p>
                <span>2020 Seed</span>
              </div>
              <div className="investment_experience_item">
                <img
                  src={Samasource}
                  alt=""
                />
                <p>Samasource</p>
              </div>
              <div className="investment_experience_item">
                <img
                  src={technical_2}
                  alt=""
                />
                <p>Invenia Technical Computing</p>
                <span>2020 Series A</span>
              </div>
              <div className="investment_experience_item">
                <img
                  src={life_2}
                  alt=""
                />
                <p>Invenia Technical Computing</p>
                <span>2020 Series A</span>
              </div>
              <div className="investment_experience_item">
                <img
                  src={mozart_2}
                  alt=""
                />
                <p>Invenia Technical Computing</p>
                <span>2020 Series A</span>
              </div>
              <div className="investment_experience_item">
                <img
                  src={asset_2}
                  alt=""
                />
                <p>Asset Market</p>
              </div>
            </div>
          </div>

          <div className="recommended_syndicates"  id="recommended">
            <div className="heading">
              <img
                src={estate}
                alt=""
              />
              <div>
                <h1>2 Recommended Syndicates to Apply</h1>
                <span>Based on your interests, investments, and search history</span>
              </div>
            </div>
            <div className="syndicats_list">
              <Syndicates />
              <Syndicates />
              <Syndicates />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Investor;
