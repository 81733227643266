import React from 'react'
import './Reviews.scss'
import Woman from '../../../assets/Image/assetDiscovery/woman.png'
import Star from '../../../assets/Image/assetDiscovery/star.svg'
import { Checkbox } from '@mui/material';
import { InputBox } from '../../../components/reusableComponents/FormFields/FormFields';
import CalenderIcon from "../../../assets/svg/calender.svg";
import DropDown from '../../../components/reusableComponents/Dropdown/Dropdown';

function Reviews() {


    const reviewdata = [
        {
            img: Woman,
            name: 'Susie Chapman',
            text1: 'May 2017',
            text2: 'You are empowered to make decisions and choose your own goals/tasks. Every day you meet so many amazing entrepreneurs as well as continuously build these relationships. Inspiring and fun meetings, all-hands & trips (Summer Camp & Summit). Support system! Everyone cares for your well-being and become family.'
        },
        {
            img: Woman,
            name: 'Susie Chapman',
            text1: 'May 2017',
            text2: 'You are empowered to make decisions and choose your own goals/tasks. Every day you meet so many amazing entrepreneurs as well as continuously build these relationships. Inspiring and fun meetings, all-hands & trips (Summer Camp & Summit). Support system! Everyone cares for your well-being and become family.'
        },


    ];


    return (
        <div className='reviews'>
            <div className='left'>
                <div className='left_header'>
                    <h2>Reviews</h2>
                    <div className="search_bar">
                        <input
                            type="text"
                            placeholder="Search Ratings"
                        />
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M20 18.586L18.586 20L12.929 14.343L14.343 12.929L20 18.586ZM7 12C4.243 12 2 9.757 2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12ZM7 0C3.134 0 0 3.134 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0Z"
                                fill="white"
                                fill-opacity="0.01"
                            />
                            <mask
                                id="mask0_9862_58034"
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="20"
                                height="20"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M20 18.586L18.586 20L12.929 14.343L14.343 12.929L20 18.586ZM7 12C4.243 12 2 9.757 2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12ZM7 0C3.134 0 0 3.134 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0Z"
                                    fill="white"
                                />
                            </mask>
                            <g mask="url(#mask0_9862_58034)">
                                <rect
                                    opacity="0.5"
                                    x="-1"
                                    y="-1"
                                    width="22"
                                    height="22"
                                    fill="#9EA0A5"
                                />
                            </g>
                        </svg>
                    </div>
                </div>
                <hr></hr>
                <div className='ratings'>
                    <div className='ratings_row'>
                        <div>
                            <p>Trust</p>
                            <span>
                                <img src={Star} alt='' />
                                <img src={Star} alt='' />
                                <img src={Star} alt='' />
                                <img src={Star} alt='' />
                                <img src={Star} alt='' />
                            </span>
                        </div>
                        <div>
                            <p>Value</p>
                            <span>     <img src={Star} alt='' />
                                <img src={Star} alt='' />
                                <img src={Star} alt='' />
                                <img src={Star} alt='' />
                                <img src={Star} alt='' /></span>
                        </div>
                    </div>
                    <div className='ratings_row'>
                        <div>
                            <p>Activity</p>
                            <span>     <img src={Star} alt='' />
                                <img src={Star} alt='' />
                                <img src={Star} alt='' />
                                <img src={Star} alt='' />
                                <img src={Star} alt='' /></span>
                        </div>
                        <div>
                            <p>Location</p>
                            <span>     <img src={Star} alt='' />
                                <img src={Star} alt='' />
                                <img src={Star} alt='' />
                                <img src={Star} alt='' />
                                <img src={Star} alt='' /></span>
                        </div>
                    </div>
                </div>
                <hr></hr>

                <div className='review_wrapper'>
                    {reviewdata.map((deal) => (
                        <ReviewBox key={deal.id} {...deal} />
                    ))}
                </div>
            </div>
            <div className='right'>
                <div className='right_upper'>
                <div>
                 <Checkbox />
                 <p>All Sources</p>
                </div>
                <div>
                 <Checkbox />
                 <p>Google Reviews</p>
                </div>
                <div>
                 <Checkbox />
                 <p>Facebook Reviews</p>
                </div>
                <div>
                 <Checkbox />
                 <p>All Sources</p>
                </div>
                <div>
                 <Checkbox />
                 <p>All Sources</p>
                </div>
                </div>
                <div className='right_lower'>
                  <DropDown Img2={CalenderIcon} label={'Date'} selectedData={'This Year'} />
                </div>
            </div>
        </div>
    )
}

export default Reviews

function ReviewBox({ img, name, text1, text2 }) {
    return (
        <div className='review_box'>
            <div className='review_box_upper'>
                <div className='review_box_left'>
                    <img src={img} alt='' />
                    <div>
                        <p className='name'>{name}</p>
                        <p className='text1'>{text1}</p>
                    </div>
                </div>
                <div className='review_box_right'>
                    <img src={Star} alt='' />
                    <img src={Star} alt='' />
                    <img src={Star} alt='' />
                    <img src={Star} alt='' />
                    <img src={Star} alt='' />
                </div>
            </div>
            <div className='review_text'>
                <p>{text2}</p>
            </div>
        </div>
    );
}