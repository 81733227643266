import React from 'react'
import './investment.scss'
import AssestCard from './AssetCard'
import photo from "../../assets/acqusition/svg/photo.svg";
import location from "../../assets/acqusition/svg/location.svg";
import street from "../../assets/acqusition/svg/street.svg";
import bookmark from "../../assets/acqusition/svg/bookmark.svg";
import share from "../../assets/acqusition/svg/share.svg";
import compare from "../../assets/acqusition/svg/compare.svg";
import { useState } from 'react';
import CrowdfundingHeader from './CrowdFundingHeader';

function Investment() {

    const [navActive, setNavActive] = useState(1);

    return (
        <div className='investment'>
             <CrowdfundingHeader title={'Investment'} />

            <div className='investment_container'>
                <div className='investment_center'>
                    <div className='investment_info'>
                        <p>
                            NEMI Fund III, LLC (dba, Aurora Offices FUND III) is a Texas limited liability company, (the “Company”). The Company will make investments in existing or to-be developed medical office buildings (MOB’s), behavioral health facilities, in-patient physical rehabilitation hospitals (IRF’s), medical specialty facilities, ambulatory surgery centers and other healthcare related investments. The Company will generally make its investments in the form of preferred equity (meaning common equity with preferred return hurdles) and mezzanine debt into a single purpose entity for each Property (collectively, “Investments”). These Investments will generally be subordinate to first-lien construction and mortgage debt in the 50-75% leverage range. The business investment strategy will focus on projects in the general geographic areas of Texas and other southern and midwestern states. The Company intends to make investments in ground-up developments, strategic value-add, and bolt-on acquisitions and repositions. The Company anticipates a target holding period of 3-5 years per Property.
                        </p>
                    </div>
                    <div className='investment_nav'>
                    <div className=" deals-room-nav asset-nav ass-nav">
                        <span
                            className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
                            onClick={() => {
                                setNavActive(1);
                            }}
                        >
                            Distribution Strategy            </span>
                        <span
                            className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
                            onClick={() => {
                                setNavActive(2);
                            }}
                        >
                            Capital Calls
                        </span>
                        <span
                            className={navActive === 3 && "deals-room-nav-active ut-nav-active"}
                            onClick={() => {
                                setNavActive(3);
                            }}
                        >
                            Organizational Chart
                        </span>
                        <span
                            className={navActive === 4 && "deals-room-nav-active ut-nav-active"}
                            onClick={() => {
                                setNavActive(4);
                            }}
                        >
                           Fees
                        </span>

                        <span
                            className={navActive === 5 && "deals-room-nav-active ut-nav-active"}
                            onClick={() => {
                                setNavActive(5);
                            }}
                        >
                           Sources & Uses
                        </span>

                    </div> </div>
                    <div className='investment_table'>
                    <h2>Distributions from Net Cash from Operations</h2>
                    
                      <Table /> </div>
                      <div style={{marginTop:'20px'}} className='investment_table'>
                    <h2>Distributions from Net Cash from Operations</h2>
                    
                      <Table /> </div>

                      <div className='investment_bottom'>
                        <h2>
                        Distributions are made at the sole discretion of the Sponsor.Please see offering documents for additional detail.
                        </h2>
                        <p>Distributions are made at the sole discretion of the Sponsor. Please see offering documents for additional detail.</p>
                      </div>
                      
      </div>
                <div className='investment_right'>
                    <AssestCard />
                </div>
            </div> </div>
    )
}

export default Investment



const Table = () => {
    return (
        <table className="table">
  <thead>
    <tr className="table_header">
      <th style={{paddingLeft:'30px'}}>Tier</th>
      <th>Hurdle Rate (up to)</th>
      <th>Type</th>
      <th>Investors</th>
      <th>Promote</th>
    </tr>
  </thead>
  <tbody>
    <tr className="table_row">
      <td className="table_data">1</td>
      <td className="table_data">8%</td>
      <td className="table_data">Preferred Return</td>
      <td className="table_data">100%</td>
      <td className="table_data">0.0%</td>
    </tr>
    <tr className="table_row">
      <td className="table_data">2</td>
      <td className="table_data">10%</td>
      <td className="table_data">Preferred Return</td>
      <td className="table_data">90%</td>
      <td className="table_data">1.5%</td>
    </tr>
    <tr className="table_row">
      <td className="table_data">3</td>
      <td className="table_data">12%</td>
      <td className="table_data">Waterfall</td>
      <td className="table_data">80%</td>
      <td className="table_data">3.0%</td>
    </tr>
    <tr className="table_row">
      <td className="table_data">4</td>
      <td className="table_data">15%</td>
      <td className="table_data">Waterfall</td>
      <td className="table_data">70%</td>
      <td className="table_data">5.0%</td>
    </tr>
  </tbody>
</table>
    )
}