import React, {useEffect} from "react";
import eye from "../../../assets/acqusition/svg/eye.svg";
import download from "../../../assets/acqusition/svg/download.svg";

const PropertyDetails = ({svgPath, name, details, name_, details_}) => {
  return (
    <div className="property-list">
      <div className="p-l-l">
        <img
          src={svgPath}
          alt=""
        />
        <div>
          <span className="p-l-l-s-f">{name}</span>
          {name_ && <span className="p-l-l-s-l">{name_}</span>}
        </div>
      </div>
      <div className="p-l-r">
        <span className="p-l-r-s-f">{details}</span>
        {details_ && <span className="p-l-r-s-l">{details_}</span>}
      </div>
    </div>
  );
};

const PropertyFeatures = ({svgPath, name, details}) => {
  return (
    <div className="f-w-c">
      <div>
        {" "}
        <img
          src={svgPath}
          alt=""
        />
        <div>
          <span>{name}</span>
        </div>
      </div>

      <div>
        <span>{details}</span>
      </div>
    </div>
  );
};

const LandDoc = ({selectedData}) => {
  return (
    <div className="land-doc-wrapper">
      <div className="land-doc-card">
        <img
          src={selectedData?.advanceAssets?.asset_info?.property_details?.docs?.land_docs[0]}
          alt=""
        />

        <div>
          <img
            src={eye}
            alt=""
          />
          <img
            src={download}
            alt=""
          />
        </div>
      </div>
      <div className="land-doc-card">
        <img
          src={selectedData?.advanceAssets?.asset_info?.property_details?.docs?.land_docs[1]}
          alt=""
        />
        <div>
          <img
            src={eye}
            alt=""
          />
          <img
            src={download}
            alt=""
          />
        </div>
      </div>
      <div className="land-doc-card">
        <img
          src={selectedData?.advanceAssets?.asset_info?.property_details?.docs?.land_docs[0]}
          alt=""
        />
        <div>
          <img
            src={eye}
            alt=""
          />
          <img
            src={download}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const AdvancedDoc = ({selectedData}) => {
  return (
    <div className="land-doc-wrapper">
      <div className="land-doc-card">
        <img
          src={selectedData?.advanceAssets?.asset_info?.property_details?.docs?.advanced_techniques[0]}
          alt=""
        />
        {/* <span>Council Decision No. 135/1969. On the registra...</span> */}
        <div>
          <img
            src={eye}
            alt=""
          />
          <img
            src={download}
            alt=""
          />
        </div>
      </div>
      <div className="land-doc-card">
        <img
          src={selectedData?.advanceAssets?.asset_info?.property_details?.docs?.advanced_techniques[1]}
          alt=""
        />
        {/* <span>Council Decision No. 135/1969. On the registra...</span> */}
        <div>
          <img
            src={eye}
            alt=""
          />
          <img
            src={download}
            alt=""
          />
        </div>
      </div>
      <div className="land-doc-card">
        <img
          src={selectedData?.advanceAssets?.asset_info?.property_details?.docs?.advanced_techniques[0]}
          alt=""
        />
        {/* <span>Council Decision No. 135/1969. On the registra...</span> */}
        <div>
          <img
            src={eye}
            alt=""
          />
          <img
            src={download}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const FinancingDoc = ({selectedData}) => {
  return (
    <div className="land-doc-wrapper">
      <div className="land-doc-card">
        <img
          src={selectedData?.advanceAssets?.asset_info?.property_details?.docs?.financing_schemes[0]}
          alt=""
        />
        {/* <span>Council Decision No. 135/1969. On the registra...</span> */}
        <div>
          <img
            src={eye}
            alt=""
          />
          <img
            src={download}
            alt=""
          />
        </div>
      </div>
      <div className="land-doc-card">
        <img
          src={selectedData?.advanceAssets?.asset_info?.property_details?.docs?.financing_schemes[1]}
          alt=""
        />
        {/* <span>Council Decision No. 135/1969. On the registra...</span> */}
        <div>
          <img
            src={eye}
            alt=""
          />
          <img
            src={download}
            alt=""
          />
        </div>
      </div>
      <div className="land-doc-card">
        <img
          src={selectedData?.advanceAssets?.asset_info?.property_details?.docs?.financing_schemes[0]}
          alt=""
        />
        {/* <span>Council Decision No. 135/1969. On the registra...</span> */}
        <div>
          <img
            src={eye}
            alt=""
          />
          <img
            src={download}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export {PropertyDetails, PropertyFeatures, LandDoc, AdvancedDoc, FinancingDoc};
