import {useState} from "react";
import signal from "../../assets/dealsroom/svg/signal.svg";
import messages from "../../assets/dealsroom/svg/messages.svg";
import phone from "../../assets/dealsroom/svg/phone.svg";
import location from "../../assets/dealsroom/svg/location.svg";
import profileicon from "../../assets/dealsroom/svg/profileicon.svg";
import profilearrow from "../../assets/dealsroom/svg/profilearrow.svg";
import greendot from "../../assets/dealsroom/svg/greendot.svg";
import loanresponse from "../../assets/dealsroom/svg/loanresponse.svg";
import seemore from "../../assets/dealsroom/svg/seemore.svg";
import filter from "../../assets/dealsroom/svg/filter.svg";
import plusbtn from "../../assets/dealsroom/svg/plusbtn.svg";
import pdf from "../../assets/dealsroom/svg/pdf.svg";
import sign from "../../assets/dealsroom/svg/sign.svg";
import notsign from "../../assets/dealsroom/svg/notsign.svg";
import importnat from "../../assets/dealsroom/svg/important.svg";
import clock from "../../assets/dealsroom/svg/clock.svg";
import action from "../../assets/dealsroom/svg/action.svg";
import eye from "../../assets/dealsroom/svg/eye.svg";
import linktoken from "../../assets/dealsroom/svg/linktoken.svg";
import save from "../../assets/dealsroom/svg/save.svg";
import print from "../../assets/dealsroom/svg/print.svg";
import copy from "../../assets/dealsroom/svg/copy.svg";
import prevnav from "../../assets/dealsroom/svg/prevnav.svg";
import fwdnav from "../../assets/dealsroom/svg/fwdnav.svg";
import tokenimg from "../../assets/dealsroom/svg/tokenimg.svg";
import navarrow from "../../assets/dealsroom/svg/navarrow.svg";
import medal from "../../assets/dealsroom/svg/medal.svg";
import arrowright from "../../assets/dealsroom/svg/arrowright.svg";
import imgupload from "../../assets/dealsroom/svg/imgupload.svg";
import attachment from "../../assets/dealsroom/svg/attachment.svg";
import emoji from "../../assets/dealsroom/svg/emoji.svg";
import women from "../../assets/dealsroom/images/women.png";
import women1 from "../../assets/dealsroom/images/women1.png";
import chatimg from "../../assets/dealsroom/images/chatimg.png";
import {Link} from "react-router-dom";
import './DealRoomComp.scss'

const DealRoomComp = () => {
  const [navActive, setNavActive] = useState(1);

  return (
    <div className="DealRoomComp-conatiner">
      <div className="deals-room-center-header">
        <p>Deal Room</p>
      </div>
      <div className="deals-room-center-del">
        <div className="deals-room-heading">
          <div className="deals-room-heading-left">
            <p>
              T/
              <span>E</span>
            </p>
          </div>
          <div className="deals-room-heading-right">
            <div className="deals-room-heading-right-top">
              <span>Top Estate</span>
              <img
                src={greendot}
                alt=""
              />
            </div>
            <div className="deals-room-heading-right-detail">
              <p>Investment Firm</p>
            </div>
            <div className="deals-room-heading-right-bottom">
              <div className="deals-room-heading-right-text">
                <div className="dls-room-head-back">
                  <span>68</span>
                </div>
                <span>Assets</span>
              </div>
              <div className="deals-room-heading-right-img">
                <img
                  src={signal}
                  alt=""
                />
                <span>80% Match</span>
              </div>
            </div>
          </div>
        </div>
        <div className="deals-room-nav">
          <span
            className={navActive === 1 && "deals-room-nav-active"}
            onClick={() => {
              setNavActive(1);
            }}
          >
            Seller Profile
          </span>
          <span
            className={navActive === 2 && "deals-room-nav-active"}
            onClick={() => {
              setNavActive(2);
            }}
          >
            Documents
          </span>
          <span
            className={navActive === 3 && "deals-room-nav-active"}
            onClick={() => {
              setNavActive(3);
            }}
          >
            Tokens
          </span>
          <span
            className={navActive === 4 && "deals-room-nav-active"}
            onClick={() => {
              setNavActive(4);
            }}
          >
            Seller Activity
          </span>
          <span
            className={navActive === 5 && "deals-room-nav-active"}
            onClick={() => {
              setNavActive(5);
            }}
          >
            Communication
          </span>
        </div>

        {navActive === 1 && <SellerProfile />}
        {navActive === 2 && <Documents />}
        {navActive === 3 && <Token />}
        {navActive === 4 && <SellerActivity />}
        {navActive === 5 && <Communication />}
      </div>
    </div>
  );
};

export default DealRoomComp;

const SellerProfile = () => {
  return (
    <div>
      <div className="deals-room-nav-detail">
        <span>basic details</span>
        <div className="deals-room-nav-details">
          <div className="deals-room-nav-contact-details">
            <img
              src={messages}
              alt=""
            />
            <span>me@angenewton.co</span>
          </div>
          <div className="deals-room-nav-contact-details">
            <img
              src={phone}
              alt=""
            />
            <span>+1 (200) 7887 665 </span>
          </div>
          <div className="deals-room-nav-contact-details">
            <img
              src={location}
              alt=""
            />
            <span>United States</span>
          </div>
        </div>
      </div>
      <div className="deals-room-nav-det-btn">
        <img
          src={profileicon}
          alt=""
        />
        <span>Open Profile Page</span>
        <img
          src={profilearrow}
          alt=""
        />
      </div>
      <div className="deals-room-nav-bio">
        <span>Bio</span>
        <p>
          Working in automotive quality management for over 3 years. A passionate traveller, loves to spend time with her dog. Wine taster. Biggest dream is to travel the world with all the money I
          make from renting building to my dearest customers who pay well.
        </p>
        <span>Tags</span>
        <div className="deals-room-nav-bio-button">
          <div className="deals-room-nav-bio-btn bio-btn-first">
            <span>Office Management</span>
          </div>
          <div className="deals-room-nav-bio-btn bio-btn-second">
            <span>Commercial</span>
          </div>
          <div className="deals-room-nav-bio-btn bio-btn-third">
            <span>Development</span>
          </div>
        </div>
      </div>
      <div className="deals-room-nav-fact-box">
        <div className="deals-room-nav-fact">
          <p>Assets in Portfolio</p>
          <p>159</p>
          <p>12% more than average</p>
        </div>
        <div className="deals-room-nav-fact">
          <p>Average Price</p>
          <p>$2,074,705</p>
          <p>52% more than average</p>
        </div>
        <div className="deals-room-nav-fact">
          <p>Assets For sell</p>
          <p>15</p>
          <p>10% more than average</p>
        </div>
        <div className="deals-room-nav-fact">
          <p>Invested</p>
          <p>$5,080,000</p>
          <p>52% more than average</p>
        </div>
        <div className="deals-room-nav-fact">
          <p>Average Investment</p>
          <p>$74,705</p>
          <p>52% more than average</p>
        </div>
        <div className="deals-room-nav-fact">
          <p>MAX investment</p>
          <p>$1,000,000</p>
          <p>52% more than average</p>
        </div>
      </div>
      <div className="deals-room-nav-bio-cond">
        <div className="deals-room-nav-bio-cond-top">
          <p>Conditions</p>
          <div className="deals-room-nav-bio-cond-top-left">
            <span>See More</span>
            <img
              src={seemore}
              alt=""
            />
          </div>
        </div>
        <p>
          Working in automotive quality management for over 3 years. A passionate traveller, loves to spend time with her dog. Wine taster. Biggest dream is to travel the world with all the money I
          make from renting building to my dearest customers who pay well.{" "}
        </p>
      </div>
    </div>
  );
};

const Documents = () => {
  return (
    <div>
      <div className="deals-room-doc">
        <div className="deals-room-doc-nav">
          <div className="deals-room-doc-nav-left">
            <div className="deals-room-doc-nav-left-all doc-nav">
              <p>All</p>
              <span>4</span>
            </div>
            <div className="delas-room-doc-nav-inbox doc-nav">
              <div className="deals-room-doc-nav-count">
                <span>1</span>
              </div>
              <p>Inbox</p>
              <span>32</span>
            </div>
            <div className="delas-room-doc-nav-sent doc-nav">
              <p>Sent</p>
              <span>4</span>
            </div>
            <div className="delas-room-doc-nav-draft doc-nav">
              <p>Drafts</p>
              <span>36</span>
            </div>
          </div>
          <div className="deals-room-doc-nav-right">
            <div className="deals-room-doc-nav-filt">
              <img
                src={filter}
                alt=""
              />
              <span>Filter</span>
            </div>
            <Link to={"/dealsroom/adddoc"}>
              <div className="deals-room-doc-nav-addmore">
                <img
                  src={plusbtn}
                  alt=""
                />
                <span>Add Document</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="deals-room-doc-nav-det">
          <div className="deals-room-doc-nav-head">
            <div className="deals-room-doc-nav-head-first">
              <input type="checkbox" />
              <span>Document</span>
            </div>
            <span>Date Added/Updated</span>
            <span>my sign</span>
            <span>Seller's Sign</span>
            <span>Originated by</span>
            <span>tags</span>
            <span>Action</span>
          </div>
        </div>
        {/* DOC UPLOADING COMPONENT LOADING */}
        <DocUpload />
        <DocUpload />
        <DocUpload />
        <DocUpload />
        <DocUpload />
        <DocUpload />
        <div className="deals-room-doc-page-nav">
          <div className="page-nav-cont">
            <img
              src={prevnav}
              alt=""
            />
            <span>2 of 10</span>
            <img
              src={fwdnav}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const DocUpload = () => {
  return (
    <div>
      <div className="deals-room-doc-upload">
        <div className="deals-room-doc-pdf">
          <input type="checkbox" />
          <img
            src={pdf}
            alt=""
          />
          <span>Agreement.pdf</span>
        </div>
        <div className="deals-room-doc-date">
          <span>5th of Nov, 18</span>
          <img
            src={clock}
            alt=""
          />
          <span>Nov. 24, 2018</span>
        </div>
        <div className="deals-room-doc-sign">
          <img
            src={sign}
            alt=""
          />
          <span>Signed</span>
        </div>
        <div className="deals-room-doc-import">
          <img
            src={importnat}
            alt=""
          />
          <span>Edited</span>
        </div>
        <div className="deals-room-doc-org">
          <div className="delas-room-doc-org-img">
            <img
              id="womenimg"
              src={women}
              alt=""
            />
          </div>
          <div className="deals-room-doc-org-name">
            <p>Paityn Press</p>
            <span>InvestGroup</span>
          </div>
        </div>
        <div className="deals-room-doc-tag">
          <span>Tax</span>
          <span>Rent Roll</span>
        </div>
        <div className="deals-room-doc-action">
          <img
            src={eye}
            alt=""
          />
          <img
            src={action}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const Token = () => {
  return (
    <div>
      <div className="token-container">
        <div className="token-head">
          <div className="token-head-title">
            <h2>Token</h2>
          </div>
          <div className="token-head-btn">
            <img
              src={linktoken}
              alt=""
            />
            <span>Transfer another Token</span>
          </div>
        </div>
        <div className="token-link">
          <span>0x34EH887oseno8vnh89h98h9h9616816e1bf6df0#$5gh7&H*%^&*()(*guiqncnw</span>
        </div>
        <div className="token-option">
          <div className="token-save token-opt token-line">
            <img
              src={save}
              alt=""
            />
            <span>Save</span>
          </div>
          <div className="token-print token-opt token-line">
            <img
              src={print}
              alt=""
            />
            <span>Print</span>
          </div>
          <div className="token-copy token-opt">
            <img
              src={copy}
              alt=""
            />
            <span>Copy to Clipboard</span>
          </div>
        </div>
        <div className="token-frame">
          <img
            src={tokenimg}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
const SellerActivity = () => {
  return (
    <div>
      <div className="seller-activity-container">
        <div className="seller-card-container">
          <div className="seller-activity">
            <img
              src={greendot}
              alt=""
            />
          </div>
          <div className="seller-img">
            <img
              id="womenimg"
              src={women}
              alt=""
            />
          </div>
          <div className="seller-details">
            <p>
              Isabelle Neal <span>from Investor’s team</span>
            </p>
            <p>Uploaded 3 documents</p>
            <div className="seller-det-doc">
              <img
                id="pdf"
                src={pdf}
                alt=""
              />
              <img
                id="pdf"
                src={pdf}
                alt=""
              />
              <img
                id="pdf"
                src={pdf}
                alt=""
              />
            </div>
            <p id="date">4 days ago, on August 15, 12:15am</p>
          </div>
        </div>
        <div className="seller-card-container">
          <div className="seller-activity">
            <img
              src={greendot}
              alt=""
            />
          </div>
          <div className="seller-img">
            <img
              id="womenimg"
              src={women1}
              alt=""
            />
          </div>
          <div className="seller-details">
            <p>Jose Perkins</p>
            <p>at “Agreement C.pdf” document</p>
            <p>Suggested 1 edit</p>
            <div className="seller-det-invest">
              <div className="seller-invst invst-card">
                <span id="time">Before</span>
                <span id="min">Minimum Investment</span>
                <span>$15,500</span>
              </div>
              <img
                src={navarrow}
                alt=""
              />
              <div className="seller-invst-now invst-card">
                <span id="time"> Now</span>
                <span id="min">Minimum Investment</span>
                <span>$25,000</span>
              </div>
            </div>
            <p id="date">8 days ago, on August 11, 10:15am</p>
          </div>
        </div>
        <div className="seller-card-container">
          <div className="seller-activity">
            <img
              id="imgmedal"
              src={medal}
              alt=""
            />
          </div>
          <div className="seller-img">
            <img
              id="womenimg"
              src={women}
              alt=""
            />
          </div>
          <div className="seller-details">
            <p>
              Gregory Ray <span> from Investor’s team</span>
            </p>
            <p>commented at “Agreement C.pdf” document</p>

            <div className="seller-comment">
              <p>
                Can I Invest on behalf on my friend, I’ll provide my docs and Investor Certificate, but he’ll be a real Investor (100% his money)? If not can I borrow his money and then invest? Also,
                can I invest $48K, not $45K or $50?
              </p>
            </div>
            <div className="seller-cmt-date">
              <p id="date">4 days ago, on August 15, 12:15am</p>
              <div className="seller-cmt-reply">
                <span>Reply</span>
                <img
                  src={arrowright}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="seller-card-container">
          <div className="seller-activity">
            <img
              id="imgmedal"
              src={medal}
              alt=""
            />
          </div>
          <div className="seller-img">
            <img
              id="womenimg"
              src={women}
              alt=""
            />
          </div>
          <div className="seller-details">
            <div className="seller-detail-top">
              <div className="seller-det-name">
                <p>
                  Gregory Ray <span> from Investor’s team</span>
                </p>
                <p>commented at “Agreement C.pdf” document</p>
              </div>
              <div className="seller-detail-time">
                <span>1 month ago</span>
              </div>
            </div>
            <div className="seller-comment">
              <p>
                Can I Invest on behalf on my friend, I’ll provide my docs and Investor Certificate, but he’ll be a real Investor (100% his money)? If not can I borrow his money and then invest? Also,
                can I invest $48K, not $45K or $50?
              </p>
            </div>
            <div className="seller-cmt-date">
              <p id="date">4 days ago, on August 15, 12:15am</p>
              <div className="seller-cmt-reply">
                <span>Reply</span>
                <img
                  src={arrowright}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Communication = () => {
  return (
    <div>
      <div className="seller-chat-container">
        <div className="seller-chat-activity">
          <div className="seller-chat-activity-right">
            <div className="chat-user">
              <img
                id="chatimg"
                src={women}
                alt=""
              />
              <span>Norman Hernandez</span>
              <span>05:10 AM</span>
            </div>
            <div className="chat-box-right">
              <p>
                I always think teenagers must hate my place as there is nothing to do but relax and enjoy the sea view and sunsets and read books which is not a very teenagery thing to do (my idea of
                paradise when I was a teen though). No shops or even a park to hang out in. I had one lady stay with her young daughter who wanted to come back at Xmas for a month with husband and
                teenage daughter from over 1500km away but the teen nixed it as the oldest girls who live here are only about 10.
              </p>
            </div>
          </div>
          <div className="seller-chat-activity-left">
            <div className="chat-user">
              <img
                id="chatimg"
                src={women}
                alt=""
              />
              <span>Norman Hernandez</span>
              <span>05:10 AM</span>
            </div>
            <div className="chat-box-left chat-color-unread">
              <p>Hey y’all! We own Hidden Lake Forest which is in a private lake community. We wanted to see how others handle a waiver of liability to use of Kayaks, boats, swimming. 🛶🏖</p>
              <img
                src={action}
                alt=""
              />
            </div>
          </div>
          <div className="seller-chat-activity-right">
            <div className="chat-user">
              <img
                id="chatimg"
                src={women}
                alt=""
              />
              <span>Norman Hernandez</span>
              <span>05:10 AM</span>
            </div>
            <div className="chat-box-right">
              <p>
                I always think teenagers must hate my place as there is nothing to do but relax and enjoy the sea view and sunsets and read books which is not a very teenagery thing to do (my idea of
                paradise when I was a teen though). No shops or even a park to hang out in. I had one lady stay with her young daughter who wanted to come back at Xmas for a month with husband and
                teenage daughter from over 1500km away but the teen nixed it as the oldest girls who live here are only about 10.
              </p>
            </div>
            <div className="chat-box-img-upload">
              <img
                id="uploadimg"
                src={chatimg}
                alt=""
              />
              <img
                id="uploadimg"
                src={chatimg}
                alt=""
              />
              <img
                id="uploadimg"
                src={chatimg}
                alt=""
              />
            </div>
          </div>
          <div className="seller-chat-activity-left">
            <div className="chat-user">
              <img
                id="chatimg"
                src={women}
                alt=""
              />
              <span>Norman Hernandez</span>
              <span>05:10 AM</span>
            </div>
            <div className="chat-box-left chat-color-read">
              <p>Hey y’all! We own Hidden Lake Forest which is in a private lake community. We wanted to see how others handle a waiver of liability to use of Kayaks, boats, swimming. 🛶🏖</p>
              <img
                src={action}
                alt=""
              />
            </div>
          </div>
          <div className="seller-chat-activity-left">
            <div className="chat-user user-active">
              <img
                id="chatimg"
                src={women}
                alt=""
              />
              <span>Norman Hernandez</span>
              <span>Typing...</span>
            </div>
          </div>
        </div>
        <div className="seller-chat-box">
          <input
            id="input-box"
            type="text"
            placeholder="Type a message..."
          />
          <div className="chat-attacment">
            <img
              src={imgupload}
              alt=""
            />
            <img
              src={attachment}
              alt=""
            />
            <img
              src={emoji}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};
