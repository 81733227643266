import lilypandsLogo from "../../../assets/svg/lilipadsLogomain.svg";
import PRsvg1 from "../../../assets/svg/PRsvg1.svg";
import {PrimaryButton} from "../../../components/reusableComponents/Buttons/Buttons";
import {InputBox} from "../../../components/reusableComponents/FormFields/FormFields";
import {Link} from "react-router-dom";

export function SecondSection({setSliderState}) {
  return (
    <div className="prFirstSection">
      <div className="signupCon-L">
        <div className="signupFirstSection signupsection">
          <div className="top_header">
            <img
              src={lilypandsLogo}
              alt=""
            />

            <div className="text_wrapper">
              <h2>Password recovery</h2>
              <p style={{color: "#ED962D"}}>Entered email doesn;t exist in our database. Try enter email that you used for registration.</p>
            </div>
          </div>

          <InputBox
            label="E-mail address"
            type="email"
          />
          <br />
          <PrimaryButton
            classNames=" noMargin "
            name="Reset Password!"
            onclick={() => {
              setSliderState("three");
            }}
          />
          <h3>
            I’ve remembered password. <span>Sign In.</span>
          </h3>

          <div className="spacerLine30"></div>
          <Link to="/signup">
            <PrimaryButton
              classNames="grey noMargin"
              name="Create Account!"
              onclick={() => {}}
            />
          </Link>
        </div>
      </div>
      <div className="signupCon-R ">
        <div className="container ">
          <div className="passwordRecovery_svg_wrapper">
            <img
              src={PRsvg1}
              alt=""
            />
          </div>
          <div className="color_stripes">
            <div className="stripe1"></div>
            <div className="stripe2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
