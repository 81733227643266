import multifamily from "../../../assets/svg/multifamily.svg";
import industrial from "../../../assets/svg/industrial.svg";
import office from "../../../assets/svg/office.svg";
import retail from "../../../assets/svg/retail.svg";
import hotels from "../../../assets/svg/hotels.svg";
import somethingelse from "../../../assets/svg/somethingelse.svg";
import fileIcon from "../../../assets/svg/fileIcon.svg";
import {Slider} from "@mui/material";
import {FormControlLabel} from "@mui/material";
import {IOSSwitch, InputBox, SelectBox} from "../../../components/reusableComponents/FormFields/FormFields";


const SixthSectionSQ = ({
    propertyTypeBox,
    setPropertyTypeBox,
    Checkmark,
    CheckmarkTICK,
    greenAssetCheck,
    setGreenAssetCheck,
    propertyClassBox,
    setPropertyClassBox,
    tenancyBox,
    setTenancyBox,
    price,
    setPrice,
    size,
    setSize,
    units,
    setUnits,
    setPropertyType,
    setPropertyClass,
    setTenancy,
  }) => {
    const handleClick = (id) => {
      setPropertyTypeBox(id);
      setPropertyType(id === 1 ? "multifamily" : id === 2 ? "industrial" : id === 3 ? "office" : id === 4 ? "retail" : id === 5 ? "hotels" : id === 6 ? "something else" : "multifamily");
    };
  
    const handleClassClick = (id) => {
      setPropertyClassBox(id);
      setPropertyClass(id === 1 ? "A" : id === 2 ? "B" : id === 3 ? "C" : "A");
    };
    const handleTenancyClick = (id) => {
      setTenancyBox(id);
      setTenancy(id === 1 ? "multiple" : id === 2 ? "single" : "multiple");
    };
    return (
      <>
        <div className="FirstSectionSQ sectionSignupQ">
          <div className="sectionSignupQ-T">
            <h1>
              Property Details{" "}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z"
                  fill="#9EA0A5"
                />
              </svg>
            </h1>
            <h2>What types of assets do you consider the most suitable for lending?</h2>
          </div>
          <div className="sectionSignupQ-B">
            <div>
              <div className="property_row">
                <div className="title">
                  <h2>
                    Property Type
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z"
                        fill="#9EA0A5"
                      />
                    </svg>
                  </h2>
                </div>
                <div className="boxes_container">
                  <div className="investment_vehicles">
                    <div
                      className="container"
                      onClick={() => handleClick(1)}
                    >
                      <SelectBox
                        icon={multifamily}
                        title={"Multifamily"}
                        id={1}
                        activeId={propertyTypeBox}
                      />
                    </div>
                    <div
                      className="container"
                      onClick={() => handleClick(2)}
                    >
                      <SelectBox
                        icon={industrial}
                        title={"Industrial"}
                        id={2}
                        activeId={propertyTypeBox}
                      />
                    </div>
                    <div
                      className="container"
                      onClick={() => handleClick(3)}
                    >
                      <SelectBox
                        icon={office}
                        title={"Office"}
                        id={3}
                        activeId={propertyTypeBox}
                      />
                    </div>
                    <div
                      className="container"
                      onClick={() => handleClick(4)}
                    >
                      <SelectBox
                        icon={retail}
                        title={"Retail"}
                        id={4}
                        activeId={propertyTypeBox}
                      />
                    </div>
                    <div
                      className="container"
                      onClick={() => handleClick(5)}
                    >
                      <SelectBox
                        icon={hotels}
                        title={"Hotels"}
                        id={5}
                        activeId={propertyTypeBox}
                      />
                    </div>
                    <div
                      className="container"
                      onClick={() => handleClick(6)}
                    >
                      <SelectBox
                        icon={somethingelse}
                        title={"Something else?"}
                        id={6}
                        activeId={propertyTypeBox}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="checkBoxCon"
                  onClick={() => setGreenAssetCheck(!greenAssetCheck)}
                >
                  {greenAssetCheck ? <CheckmarkTICK /> : <Checkmark />}
                  <p>Focus on Green Asset</p>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_8226_1929)">
                      <path
                        d="M23.6357 10.7275L12.6338 1.72618C12.2657 1.42461 11.7354 1.42461 11.3672 1.72618L0.365484 10.7275C0.0366264 10.9966 -0.0867869 11.4575 0.0631767 11.8547C0.208379 12.2397 0.587042 12.5018 0.998664 12.5018H3.99922V21.5032C3.99922 22.0555 4.44691 22.5034 4.99934 22.5034H19.0016C19.5539 22.5034 20.0017 22.0555 20.0017 21.5032V12.5018H23.0023C23.411 12.5018 23.7935 12.237 23.9378 11.8547C24.0866 11.4604 23.9618 10.9944 23.6357 10.7275ZM15.9529 10.7271C15.909 11.5399 15.6202 15.6651 14.2469 17.038C13.4914 17.7935 12.4867 18.2097 11.4183 18.2097C10.8148 18.2097 10.2318 18.077 9.70259 17.8254C9.37007 18.7297 9.0826 19.8278 8.90041 21.1614L7.78548 21.009C7.99623 19.4677 8.33937 18.2079 8.73909 17.1801C8.68819 17.1342 8.6382 17.0869 8.58949 17.038C7.83382 16.2824 7.4178 15.2777 7.4178 14.209C7.4178 13.1406 7.83382 12.1359 8.58949 11.3802C9.96242 10.0073 14.0874 9.71838 14.9004 9.67443C15.1829 9.65814 15.4607 9.76507 15.6618 9.96594C15.8625 10.1668 15.9683 10.4435 15.9529 10.7271Z"
                        fill="#7EC331"
                      />
                      <path
                        d="M12.3808 14.0004L12.1246 13.4995L11.873 12.9961C11.7738 13.0459 9.98047 13.9834 8.73828 17.1777C9.02997 17.4414 9.35443 17.6578 9.70178 17.8228C10.7872 14.8713 12.3601 14.011 12.3808 14.0004Z"
                        fill="#7EC331"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_8226_1929">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z"
                      fill="#9EA0A5"
                    />
                  </svg>
                </div>
              </div>
              <div className="property_row">
                <div className="title">
                  <h2>
                    Property Class
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z"
                        fill="#9EA0A5"
                      />
                    </svg>
                  </h2>
                </div>
                <div className="boxes_container">
                  <div className="investment_vehicles">
                    <div
                      className="container"
                      onClick={() => handleClassClick(1)}
                    >
                      <SelectBox
                        title={"Class A"}
                        id={1}
                        activeId={propertyClassBox}
                      />
                    </div>
                    <div
                      className="container"
                      onClick={() => handleClassClick(2)}
                    >
                      <SelectBox
                        title={"Class B"}
                        id={2}
                        activeId={propertyClassBox}
                      />
                    </div>
                    <div
                      className="container"
                      onClick={() => handleClassClick(3)}
                    >
                      <SelectBox
                        title={"Class C"}
                        id={3}
                        activeId={propertyClassBox}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="property_row">
                <div className="title">
                  <h2>
                    Tenancy
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z"
                        fill="#9EA0A5"
                      />
                    </svg>
                  </h2>
                </div>
                <div className="boxes_container ">
                  <div className="investment_vehicles twoBoxes">
                    <div
                      className="container "
                      onClick={() => handleTenancyClick(1)}
                    >
                      <SelectBox
                        title={"Multiple"}
                        id={1}
                        activeId={tenancyBox}
                      />
                    </div>
                    <div
                      className="container"
                      onClick={() => handleTenancyClick(2)}
                    >
                      <SelectBox
                        title={"Single"}
                        id={2}
                        activeId={tenancyBox}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="property_row">
                <div className="title">
                  <h2>
                    Property Details
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z"
                        fill="#9EA0A5"
                      />
                    </svg>
                  </h2>
                </div>
                <div className="investment_objectives">
                  <div className="top">
                    <img
                      src={fileIcon}
                      alt=""
                    />
                    <p>Investment Objectives</p>
                  </div>
                  <div className="sliders_wrapper">
                    <div className="upper">
                      <div className="dropDownOuterCon ">
                        <p style={{marginBottom: "0px"}}> Price </p>
                        <div className="slider_container">
                          <div className="top_div inputFlexLine">
                            <p>$</p>
                            <span>{price.from}</span>
                          </div>
                          <Slider
                            defaultValue={price.from}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            step={5000}
                            min={0}
                            max={300000}
                            onChange={(event) => {
                              setPrice((prev) => ({
                                ...prev,
                                from: event.target.value.toLocaleString(),
                              }));
                            }}
                          />
                        </div>
                        <p style={{marginBottom: ""}}> from </p>
                      </div>
                      <div className="dropDownOuterCon ">
                        <div className="slider_container">
                          <div className="top_div inputFlexLine">
                            <p>$</p>
                            <span>{price.to}</span>
                          </div>
                          <Slider
                            defaultValue={price.to}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            step={5000}
                            min={0}
                            max={300000}
                            onChange={(event) => {
                              setPrice((prev) => ({
                                ...prev,
                                to: event.target.value.toLocaleString(),
                              }));
                            }}
                          />
                        </div>
                        <p style={{marginBottom: ""}}> To </p>
                      </div>
                      <div className="switch_wrapper">
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{m: 1}}
                              defaultChecked={price.any}
                              onChange={() =>
                                setPrice((prev) => ({
                                  ...prev,
                                  any: !prev.any,
                                }))
                              }
                            />
                          }
                          label="Any"
                        />
                      </div>
                    </div>
                    <div className="middle">
                      <div className="dropDownOuterCon ">
                        <p style={{marginBottom: "0px"}}> size </p>
                        <div className="slider_container">
                          <div className="top_div inputFlexLine">
                            <p>SF</p>
                            <span>{size.from}</span>
                          </div>
                          <Slider
                            defaultValue={size.from.toLocaleString()}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            step={100}
                            min={0}
                            max={25000}
                            onChange={(event) => {
                              setSize((prev) => ({
                                ...prev,
                                from: event.target.value.toLocaleString(),
                              }));
                            }}
                          />
                        </div>
                        <p style={{marginBottom: ""}}> from </p>
                      </div>
                      <div className="dropDownOuterCon ">
                        <div className="slider_container">
                          <div className="top_div inputFlexLine">
                            <p>SF</p>
                            <span>{size.to}</span>
                          </div>
                          <Slider
                            defaultValue={size.to}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            step={100}
                            min={0}
                            max={25000}
                            onChange={(event) => {
                              setSize((prev) => ({
                                ...prev,
                                to: event.target.value.toLocaleString(),
                              }));
                            }}
                          />
                        </div>
                        <p style={{marginBottom: ""}}> To </p>
                      </div>
                      <div className="switch_wrapper">
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{m: 1}}
                              defaultChecked={size.any}
                              onChange={() =>
                                setSize((prev) => ({
                                  ...prev,
                                  any: !prev.any,
                                }))
                              }
                            />
                          }
                          label="Any"
                        />
                      </div>
                    </div>
                    <div className="lower">
                      <div className="dropDownOuterCon ">
                        <p style={{marginBottom: "0px"}}> Units </p>
                        <div className="slider_container">
                          <div className="top_div inputFlexLine">
                            <p>Units</p>
                            <span>{units.from}</span>
                          </div>
                          <Slider
                            defaultValue={units.from}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            step={1}
                            min={0}
                            max={100}
                            onChange={(event) => {
                              setUnits((prev) => ({
                                ...prev,
                                from: event.target.value,
                              }));
                            }}
                          />
                        </div>
                        <p style={{marginBottom: ""}}> from </p>
                      </div>
                      <div className="dropDownOuterCon ">
                        <div className="slider_container">
                          <div className="top_div inputFlexLine">
                            <p>Units</p>
                            <span>{units.to}</span>
                          </div>
                          <Slider
                            defaultValue={units.to}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            step={1}
                            min={0}
                            max={100}
                            onChange={(event) => {
                              setUnits((prev) => ({
                                ...prev,
                                to: event.target.value,
                              }));
                            }}
                          />
                        </div>
                        <p style={{marginBottom: ""}}> To </p>
                      </div>
                      <div className="switch_wrapper">
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{m: 1}}
                              defaultChecked={units.any}
                              onChange={() =>
                                setUnits((prev) => ({
                                  ...prev,
                                  any: !prev.any,
                                }))
                              }
                            />
                          }
                          label="Any"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  export default SixthSectionSQ