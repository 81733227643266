import React from "react";
import {PrimaryButton} from "../../../components/reusableComponents/Buttons/Buttons";
import "./Companyfinancials.scss";
import Link from "../../../assets/Manage-syndicate/Svg/Link.svg";

const Companyfinancials = () => {
  return (
    <div>
      <div style={{marginTop: "10px", marginBottom: "10px"}}>
        <h1>Company Financials</h1>
      </div>
      <div className="Companyfinancials-Container">
        <PrimaryButton
          name="Income statement"
          classNames="Companyfinancials-Income"
          svgPath="/path/to/svg/image.svg"
          padding="10px"
          svgHeight="20px"
          svgWidth="20px"
        />
        <PrimaryButton
          name="balance sheet"
          classNames="Companyfinancials-balance"
          svgPath="/path/to/svg/image.svg"
          padding="10px"
          svgHeight="20px"
          svgWidth="20px"
        />
      </div>
      <div>
        <div className="Companyfinancials-data">
          <div className="Companyfinancials-data-container-maindiv">
            <div className="Companyfinancials-Error-Container">
              <div className="Companyfinancials-data-container-image">
                <img
                  src={Link}
                  alt="image"
                />
              </div>
              <p>No message have been sent yet.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Companyfinancials;
