import React,{useState} from "react";
import { PrimaryButton } from "../../../components/reusableComponents/Buttons/Buttons";
import "./RunReport.scss";
import SearchInput from "../../../components/reusableComponents/Search/Search";
import DotMenu from "../../../components/reusableComponents/Tripledot/Tripledot";

const RunReport = () => {

  const [showNotification, setShowNotification] = useState(true);

  const handleHideNotification = () => {
    setShowNotification(false);
  };

  const profileMenuItems = [
    {label: "Data-1", action: "profile"},
    {label: "Data-2", action: "settings"},
    {label: "Data-3", action: "logout"},
  ];
  return (
    <div className="Report-Container">
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <h1>Equity Reporting</h1>
      </div>
      <div className="Report-header">
        <h4>Upgrade your Carta Account to access advanced reporting</h4>
        <PrimaryButton
          name="See Plans"
          classNames="Report-see-plan"
          svgPath="/path/to/svg/image.svg"
          padding="10px"
          svgHeight="20px"
          svgWidth="20px"
        />
      </div>
      <div className="All-report-container">
        <div className="All-report-data">
          <h1>All Reports</h1>
          <SearchInput />
        </div>
        <hr />
        <div className="Report-header-data">
          <h4>FOLDERS</h4>
          <h4>CAPITALIZATION</h4>
          <h4>SECURITIES</h4>
          <h4>EQUITY PLAN</h4>
          <h4>TRANSACTION</h4>
          <h4>VESTING</h4>
          <h4>MODELING</h4>
          <h4>COMPLIANCE</h4>
        </div>
        <div className="report-box-container">
          <div className="box-border">
            <div className="box-container">
              <div className="box-container-data">
                <h1>Report Name</h1>
                <h1>Description</h1>
              </div>
              <div>
                <h1>Folder</h1>
              </div>
            </div>
          </div>
          <div className="box-border">
            <div className="box-container">
              <div className="box-container-data">
                <h1>Cap table</h1>
                <h1>Capitalization table grouped by organization, individual stakeholder, or security type.</h1>
              </div>
              <div className="box-container-data-flex">
                <h1>Capitalization</h1>
                <DotMenu menuItems={profileMenuItems} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RunReport;
