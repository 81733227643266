export const keywords = [
    "Actionable Recommendations:",
    "Insights:",
    "Summary:",
    "Analysis:",
    "Key Area Analysis:",
    "#### Key Areas of Analysis:",
    "Recommendations",
    "#### Recommendations:",
    "#### Key Area Analysis:",
  ];
  