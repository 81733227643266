import React from "react";
import "./ShareClasses.scss";
import { PrimaryButton } from "../../../components/reusableComponents/Buttons/Buttons";

const ShareClasses = () => {
  const shareData = [
    { header: "NAME", data: ["Class A Common", "Class B Common", "Class C Common"] },
    { header: "PREFIX", data: ["CA", "CB", "CC"] },
    { header: "AUTHORIZED SHARE", data: ["9,000,000,00", "8,000,000,00", "7,000,000,00"] },
    { header: "CLASS TYPE", data: ["Common", "Common", "Common"] },
    { header: "CERTIFICATED", data: ["Yes", "Yes", "Yes"] },
    { header: "PER VALUE", data: ["0.000 1", "0.000 2", "0.000 3"] },
    { header: "SENIORITY", data: ["1", "2", "3"] },
    { header: "PARI-PASSU", data: ["Yes", "Yes", "Yes"] },
  ];

  return (
    
    <div className="share-classes-container">
       <h1>Share Classes</h1>
      <div className="share-classes-actions">
        <a href="">View incorporation documents</a>
        <div className="select-wrapper">
          <select name="Cover share class" id="Cover share class" defaultValue="">
            <option value="" disabled>
              Action
            </option>
            <option value="2nd">2nd <i className="fas fa-arrow-up"></i></option>
            <option value="3rd">3rd <i className="fas fa-arrow-down"></i></option>
          </select>
        </div>
        <PrimaryButton name="Update share classes" classNames="ShareClasses-Filter" />
      </div>
      <div className="share-classes-table">
        <div className="share-classes-headers">
          {shareData.map(({ header }, index) => (
            <div className="header-cell" key={index}>
              <h2>{header}</h2>
            </div>
          ))}
        </div>
        <div className="share-classes-rows">
          {shareData[0].data.map((_, rowIndex) => (
            <div className="share-classes-row" key={rowIndex}>
              {shareData.map(({ data }, colIndex) => (
                <div className="row-cell" key={colIndex}>
                  <span>{data[rowIndex]}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShareClasses;
