import React, {useEffect, useRef, useState} from "react";
import "./style.scss";
import PageStatisticsChart from "../Charts/PastInvestmentsChart";
import ActiveInvestmentsChart from "../Charts/ActiveInvestmentsChart";
import calander from "../../../assets/svg/calender.svg";
import upIcon from "../../../assets/svg/upIcon.svg";
import downIcon from "../../../assets/svg/downIcon.svg";
import search from "../../../assets/svg/search.svg";
import northGroup from "../../../assets/Image/Investment_Pipeline/northGroup.png";
import buildingOne from "../../../assets/svg/buildingOne.svg";
import buildingTwo from "../../../assets/svg/buildingTwo.svg";
import filter from "../../../assets/svg/filter_icon.svg";
import info from "../../../assets/svg/info.svg";
import AuroraAssests from "../../../assets/Image/Investment_Pipeline/AuroraAssets.png";
import DollarSilver from "../../../assets/svg/dollarSilver.svg";
import ArrowUp from "../../../assets/svg/ArrowUp.svg";
import ArrowDown from "../../../assets/svg/ArrowDown.svg";
import PastInvestmentsChart from "../Charts/PastInvestmentsChart";
import ActiveInvestment from "./ActiveInvestment/ActiveInvestment";
import axios from "axios";
import {useSelector} from "react-redux";
import useAuthConfig from "../../../utils/Config";

const PortfolioOverview = ({setIsClickedOne}) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const filterRef = useRef(null);

  const [investments, setInvestments] = useState([]);
  const [TotalInvest, setTotalInvest] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const config = useAuthConfig();
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (filterRef.current && !filterRef.current.contains(event.target)) {
  //       setIsFilterModalOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [filterRef]);

  // get Syndice data
  useEffect(() => {
    const fetchInvestments = async () => {
      try {
        const response = await axios.get("api/GetPaymentDetailsWithAssetInfo", config);
        setInvestments(response.data.data);
        setTotalInvest(response.data);
        setLoading(false);
      } catch (err) {
   
        setError(err);
        setLoading(false);
      }
    };

    fetchInvestments();
  }, []);

  

  return (
    <div className="porfolio_overview_main">
      <div className="porfolio_overview_heading">
        <h2>Portfolio Overview</h2>
      </div>
      <div className="porfolio_overview_one">
        <div className="investment_summary">
          <div className="investment_summary_one">
            <p>Total Invested</p>
            <h3>
              {(TotalInvest?.totalValue ?? 0).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </h3>
          </div>
          <div className="investment_summary_two">
            <p>Total Distributed</p>
            <h3>$0</h3>
          </div>
        </div>
        {/* <div style={{width: "38%"}}>
          <PastInvestmentsChart />
        </div>
        <div style={{width: "38%"}}>
          <ActiveInvestmentsChart />
        </div> */}
      </div>
      <div className="syndicates_highilites">
        {/* <div className="syndicates_highilites_header">
          <div>
            <h1>Syndicates Highilites</h1>
          </div>
          <div className="syndicates_highilites_calander_and_button">
            <div className="syndicates_highilites_calander">
              <div>
                <img
                  src={calander}
                  alt=""
                />
                <p>8 Apr 20</p>
              </div>
              {"-"}
              <div>
                <img
                  src={calander}
                  alt=""
                />
                <p>8 Apr 20</p>
              </div>
            </div>
            <div className="all_syndicates">All Syndicates</div>
          </div>
        </div> */}

        {/* <div className="syndicates_highilites_data">
          <div className="syndicates_highilites_data_one">
            <p>Unique Users</p>
            <h4>18,325</h4>
            <div className="syndicates_highilites_data_one_percentage">
              <img
                src={upIcon}
                alt="upIcon"
              />
              <p>9.8%</p>
            </div>
            <p>Compare to average</p>
          </div>
          <div className="syndicates_highilites_data_one">
            <p>Unique Users</p>
            <h4>18,325</h4>
            <div className="syndicates_highilites_data_one_percentage">
              <img
                src={downIcon}
                alt="downIcon"
              />
              <p className="negative">9.8%</p>
            </div>
            <p>Compare to average</p>
          </div>
          <div className="syndicates_highilites_data_one">
            <p>Unique Users</p>
            <h4>18,325</h4>
            <div className="syndicates_highilites_data_one_percentage">
              <img
                src={upIcon}
                alt="upIcon"
              />
              <p>9.8%</p>
            </div>
            <p>Compare to average</p>
          </div>
          <div className="syndicates_highilites_data_one">
            <p>Unique Users</p>
            <h4>18,325</h4>
            <div className="syndicates_highilites_data_one_percentage">
              <img
                src={upIcon}
                alt="upIcon"
              />
              <p>9.8%</p>
            </div>
            <p>Compare to average</p>
          </div>
          <div className="syndicates_highilites_data_one">
            <p>Unique Users</p>
            <h4>18,325</h4>
            <div className="syndicates_highilites_data_one_percentage">
              <img
                src={upIcon}
                alt="upIcon"
              />
              <p>9.8%</p>
            </div>
            <p>Compare to average</p>
          </div>
        </div> */}
      </div>
      <div className="GP_and_Investment">
        <div className="GP_list_Main">
          <div className="GP_list_header">
            <h3>GP List</h3>
            <img
              src={search}
              alt=""
            />
          </div>
          <div className="GP_list_one">
            <p>Name</p>
            <p>Total/Mutual Investments</p>
          </div>
          <div className="GP_list_two">
            <div onClick={() => setIsClickedOne(1)}>
              <img
                src={northGroup}
                alt=""
              />
            </div>
            <div className="GP_list_two_inner">
              <h3>North Group Trust</h3>
              <p>Address</p>
            </div>
            <div className="GP_list_two_investment">
              <div>
                <img
                  src={buildingOne}
                  alt=""
                />
                <h3>45</h3>
              </div>
              <p>Total Investments</p>
            </div>
            <div className="GP_list_two_investment">
              <div>
                <img
                  src={buildingTwo}
                  alt=""
                />
                <h3>45</h3>
              </div>
              <p>Total Investments</p>
            </div>
          </div>
        </div>

        <ActiveInvestment investments={investments} />
      </div>
    </div>
  );
};

export default PortfolioOverview;
