import { createSlice } from '@reduxjs/toolkit';

const crowdfundingSlice = createSlice({
  name: 'crowdfunding',
  initialState: {
    crowdfundingList: [],
    selectedCrowdfunding: null, 
  },
  reducers: {
    setCrowdfundingList: (state, action) => {
      state.crowdfundingList = action.payload;
    },
    selectCrowdfunding: (state, action) => {
      state.selectedCrowdfunding = action.payload;
    },
  },
});

export const { setCrowdfundingList, selectCrowdfunding } = crowdfundingSlice.actions;

export default crowdfundingSlice.reducer;
