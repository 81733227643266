import React from "react";
import "./Document.scss";
import pdfIcon from "../../../../../../../assets/investment/pdf.svg";
import search from "../../../../../../../assets/svg/search.svg";

import Checkbox from "../../../../../../profile/settings/Checkbox";

import downloadIcon from "../../../../../../../assets/investment/download.svg";
import {InputBox} from "../../../../../../../components/reusableComponents/FormFields/FormFields";
import DropDown from "../../../../../../../components/reusableComponents/Dropdown/Dropdown";

import prevnav from "../../../../../../../assets/dealsroom/svg/prevnav.svg";
import fwdnav from "../../../../../../../assets/dealsroom/svg/fwdnav.svg";
import image from "../../../../../../../assets/InvestmentDashboard/image.png";
import document from "../../../../../../../assets/svg/document.svg";
import CpitalCallNotice from "../../../../../../../assets/svg/CpitalCallNotice.svg";
import Distribution from "../../../../../../../assets/svg/Distribution.svg";
import Reports from "../../../../../../../assets/svg/Reports.svg";
import taxForm from "../../../../../../../assets/svg/taxForm.svg";
import calendar from "../../../../../../../assets/svg/calendar 1.svg";

function Document() {
  return (
    <div className="document_wrapper">
      <div className="document_left">
        <div className="document_table general_investments">
          <div className="upper">
            <span className="upper_heading">
              <h2>All Documents</h2>
            </span>
            <div className="upper_right">
              <button>
                <img
                  src={downloadIcon}
                  alt=""
                />
                Download as a Zip
              </button>
            </div>
          </div>

          <div className="lower">
            <Table />
          </div>
        </div>
      </div>

      <div className="search-filters">
        <h3>Search & Filters</h3>
        <div className="filter-options">
          <div className="filter-option">
            {/* <input
                type="checkbox"
                id="all-documents"
              /> */}
            <label htmlFor="all-documents">
              <img
                className="icon-document"
                src={document}
                alt="document"
              />{" "}
              <p>All Documents</p>
            </label>
          </div>
          <div className="filter-option">
            {/* <input
              type="checkbox"
              id="capital-call-notices"
            /> */}
            <Checkbox />
            <label htmlFor="capital-call-notices">
              <img
                className="icon-capital-call"
                src={CpitalCallNotice}
                alt="CpitalCallNotice"
              />{" "}
              <p>Capital Call Notices</p>
            </label>
          </div>
          <div className="filter-option">
            {/* <input
              type="checkbox"
              id="distribution-notices"
            /> */}
            <Checkbox />
            <label htmlFor="distribution-notices">
              <img
                className="icon-distribution"
                src={Distribution}
                alt="Distribution"
              />{" "}
              <p>Distribution Notices</p>
            </label>
          </div>
          <div className="filter-option">
            {/* <input
              type="checkbox"
              id="reports"
              className="custom-checkbox"
            /> */}
            <Checkbox />
            <label htmlFor="reports">
              <img
                className="icon-reports"
                src={Reports}
                alt="Reports"
              />{" "}
              <p>Reports</p>
            </label>
          </div>
          <div className="filter-option">
            {/* <input
              type="checkbox"
              id="tax-forms"
            /> */}
            <Checkbox />
            <label htmlFor="tax-forms">
              <img
                className="icon-tax"
                src={taxForm}
                alt="taxForm"
              />{" "}
              <p>Tax Forms</p>
            </label>
          </div>
        </div>
        <div className="search-box">
          <label htmlFor="search">Search</label>
          <input
            type="text"
            id="search"
            placeholder="Document name or any info"
          />
          <img
            className="icon-search"
            src=""
            alt=""
          />
        </div>
        <div className="date-filter">
          <label htmlFor="date">Date</label>
          {/* <img
              src={calendar}
              alt="calendar"
            /> */}
          <select id="date">
            <option>This Year</option>
            <option>Last Year</option>
            <option>Last 5 Years</option>
          </select>
          <img
            className="icon-calendar"
            src=""
            alt=""
          />
        </div>
      </div>

      {/* <div className="document_right">
        <div className="right_header">
          <h2>Search and Filters</h2>
        </div>

        <div className="search_wrapper">
          <InputBox
            label={"Search"}
            placeholder={"Document name or any info"}
            svgPath={search}
          />
        </div>

        <div className="heading_wrapper">
          <p>Category</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.59 8.58997L12 13.17L7.41 8.58997L6 9.99997L12 16L18 9.99997L16.59 8.58997Z"
              fill="#959595"
            />
          </svg>
        </div>

        <div className="category_wrapper">
          <Checkbox
            variant={2}
            img={pdfIcon}
            label1={"Dilligence"}
          />
          <Checkbox
            variant={2}
            img={pdfIcon}
            label1={"Distribution Notice"}
          />
          <Checkbox
            variant={2}
            img={pdfIcon}
            label1={"Capital Call Notices"}
          />
          <Checkbox
            variant={2}
            img={pdfIcon}
            label1={"Capital Call Notices"}
          />
          <Checkbox
            variant={2}
            img={pdfIcon}
            label1={"Capital Call Notices"}
          />
          <Checkbox
            variant={2}
            img={pdfIcon}
            label1={"Capital Call Notices"}
          />
          <Checkbox
            variant={2}
            img={pdfIcon}
            label1={"Capital Call Notices"}
          />
        </div>

        <div className="heading_wrapper">
          <p>Investment</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.59 8.58997L12 13.17L7.41 8.58997L6 9.99997L12 16L18 9.99997L16.59 8.58997Z"
              fill="#959595"
            />
          </svg>
        </div>

        <div className="date_wrapper">
          <DropDown
            label={"Date"}
            placeholder={"Date"}
            type={"date"}
          />
        </div>
      </div> */}
    </div>
  );
}

export default Document;

const Table = () => {
  return (
    <div>
      <div className="documents_box-B table">
        <div className="uploaded_docs">
          <div className="header">
            <div className="left">
              <p className="col_first">
                <Checkbox label={"name"} />{" "}
              </p>
              <p>Type</p>
              <p>Date</p>
            </div>
          </div>
          <Row2 />
          <Row2 />
          <Row2 />
          <Row2 />
        </div>
      </div>
      <div className="table_lower">
        <div className="deals-room-doc-page-nav">
          <div className="page-nav-cont">
            <img
              src={prevnav}
              alt=""
            />
            <span>2 of 10</span>
            <img
              src={fwdnav}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Row2 = () => {
  return (
    <div className="table_row table_row2">
      <div className="left">
        <div className="item first">
          <div className="text_wrapper">
            <Checkbox
              variant={2}
              img={pdfIcon}
              label1={"Capital Call #2 notice - Hobart Family Capital, LP"}
            />
          </div>
        </div>

        <div className="item">
          <div className="text_wrapper">
            <p style={{color: "#9EA0A5"}}>Commercial</p>
          </div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p style={{color: "#9EA0A5"}}>
              Sep 25, 2018,<br></br> 10:23am
            </p>
          </div>
        </div>

        <div className="item">
          <div className="text_wrapper">
            <img
              src={downloadIcon}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};
