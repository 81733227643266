import React, {useEffect} from "react";
import "./Syndicate.scss";
import meeting from "../../assets/syndicate/svg/synmeeting.svg";

const SyndicateComp = ({setSyndicate}) => {
  // useEffect(() => {
  //   return () => {
  //     // setSyndicate(2);
  //   };
  // });

  return (
    <div className="deals-room-cente asset-center-con">
      <div className="deals-room-center-header">
        <p>Syndicate Settings</p>
      </div>
      <div className="loan-req-main">
        <div className="loan-req-left">
          <div className="loan-req-left-title">
            <span>Want the community to join a great opportunity?</span>
          </div>
          <div className="loan-req-left-para">
            <span>Add and setup this asset to your Syndicate.</span>
          </div>
          <div
            className="loan-req-btn"
            onClick={() => {
              setSyndicate(2);
            }}
          >
            <span>Setup a Syndicate</span>
          </div>
          <div className="syndicate-start">
            <span>Don’t have a Syndicate?</span>
            <span>Start one.</span>
          </div>
        </div>
        <div className="loan-req-right">
          <img
            src={meeting}
            alt="img"
          />
        </div>
      </div>
      <div className="loan-req-scalton">
        <div className="scalton-div"></div>
        <div className="scalton-div"></div>
        <div className="scalton-div"></div>
      </div>
    </div>
  );
};

export default SyndicateComp;
