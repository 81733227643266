import {IOSSwitch, InputBox, SelectBox} from "../../../components/reusableComponents/FormFields/FormFields";









const SeventhSectionSQ = ({holdPeriod, setHoldPeriod, setHoldPeriodValue}) => {
    const handleClick = (id) => {
      setHoldPeriod(id);
      setHoldPeriodValue(id === 1 ? "less than 1 year" : id === 2 ? "1-2 years" : id === 3 ? "3-5 years" : id === 4 ? "6-9 years" : id === 5 ? "10 plus year" : "less than 1 year");
    };
    return (
      <>
        <div className="FirstSectionSQ sectionSignupQ">
          <div className="sectionSignupQ-T">
            <h1>
              Hold period{" "}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z"
                  fill="#9EA0A5"
                />
              </svg>
            </h1>
            <h2>How long are you going to hold on to the object?</h2>
          </div>
          <div className="sectionSignupQ-B">
            <div className="hold_period">
              <div className="investment_vehicles">
                <div
                  className="container"
                  onClick={() => handleClick(1)}
                >
                  <SelectBox
                    title={"Less than 1 Year"}
                    id={1}
                    activeId={holdPeriod}
                  />
                </div>
                <div
                  className="container"
                  onClick={() => handleClick(2)}
                >
                  <SelectBox
                    title={"1-2 Years"}
                    id={2}
                    activeId={holdPeriod}
                  />
                </div>
                <div
                  className="container"
                  onClick={() => handleClick(3)}
                >
                  <SelectBox
                    title={"3-5 Years"}
                    id={3}
                    activeId={holdPeriod}
                  />
                </div>
                <div
                  className="container"
                  onClick={() => handleClick(4)}
                >
                  <SelectBox
                    title={"6-9 Years"}
                    id={4}
                    activeId={holdPeriod}
                  />
                </div>
                <div
                  className="container"
                  onClick={() => handleClick(5)}
                >
                  <SelectBox
                    title={"10+ Years"}
                    id={5}
                    activeId={holdPeriod}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  export default SeventhSectionSQ