import React, {useState} from "react";
import "./TenderOffers.scss";
import Convertibles from "../../../assets/Manage-syndicate/image/Convertibles.png";
import Checkmark from "../../../assets/Manage-syndicate/Svg/Checkmark.svg";
import TotalCompensationimage from "../../../assets/Manage-syndicate/image/Total Compensation.png";
import Network from "../../../assets/Manage-syndicate/Svg/network.svg";
import {PrimaryButton, PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";

const TenderOffers = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What Is a Tender Offer ?",
      answer:
        "A tender offer occurs when a company allows their stakeholders to sell their stock to: Pre- selected buyer(s) at a set price with specific condition (e.g,. only employees are eligible to sell). All if which are negotiated by the company on be halp of its stakeholders. Company -sponsored liquity structured as a tender offer needs to, among other things, have an offering window open for 20 business day and company needs to prepare specific documentation and disclosures. ",
    },
    {
      question: "What does a company need to run a tender offer?",
      answer:
        "Compare that have raised at last $10M in funding OR have least 500 stakeholders on their cap table are required to disclose their stock based compensation expenses on their income statement",
    },
    {
      question: "My stackeholder and employees are asking for liquidify, but I dont have the run a tender offer. Can Carta help?",
      answer:
        "Compare that have raised at last $10M in funding OR have least 500 stakeholders on their cap table are required to disclose their stock based compensation expenses on their income statement",
    },
  ];
  return (
    <div>
      <div>
        <div className="TenderOffers-container-image">
          <div className="TenderOffers-container-image-data">
            <div className="TenderOffers-Data">
              <div className="TenderOffers-divconatiner">
                <div className="TenderOffers-image">
                  <img
                    src={Network}
                    alt="img"
                  />
                </div>
              </div>
              <PrimaryButton
                name="Tender Offers"
                classNames="TenderOffers-Tender"
                padding="10px"
                svgHeight="20px"
                svgWidth="20px"
              />
              <div>
                <PrimaryButton
                  name="Add-On"
                  classNames="TenderOffers-Add"
                  padding="10px"
                  svgHeight="20px"
                  svgWidth="20px"
                />
              </div>
              <h1>Let your stakeholders sell their equity</h1>
              <hr className="linetag" />
              <div className="TenderOffers-flex">
                <img
                  src={Checkmark}
                  alt="image"
                />
                <p>Run Secondary Sales</p>
              </div>
              <div className="TenderOffers-flex">
                <img
                  src={Checkmark}
                  alt="image"
                />
                <p>Move money on Carta</p>
              </div>
              <div className="TenderOffers-flex">
                <img
                  src={Checkmark}
                  alt="image"
                />
                <p>Enjoy white -glove support</p>
              </div>
              <PrimaryButtonSvgFirst
                name="Schedule a call"
                classNames="CaptableStake-Filter"
                svgHeight="20px"
                svgWidth="20px"
              />
            </div>
            <div className="TenderOffers-wrapper">
              <h1>Frequently asked questions</h1>
              {faqData.map((faq, index) => (
                <div
                  className="TenderOffers"
                  key={index}
                >
                  <button
                    className={`TenderOffers-accordion ${activeIndex === index ? "active" : ""}`}
                    onClick={() => toggleAccordion(index)}
                  >
                    {faq.question}
                    <i className="fa-solid fa-chevron-down"></i>
                  </button>
                  <div className={`TenderOffers-pannel ${activeIndex === index ? "active" : ""}`}>
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenderOffers;
