import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {handleLogout} from "../../utils/Signout";
import Endpoint from "../../utils/Endpoint";

const Timeout = (idToken) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      if (!idToken) return;

      const config = {
        headers: {
          "auth-token": idToken,
        },
      };

      try {
        const res = await axios.post(Endpoint.checkTokken, {}, config);


        if (res.data.success === false && res.data.message === "Token expired or invalid") {
          handleLogout(dispatch, navigate);
        }

        return res.data;
      } catch (error) {

        return error.response?.data;
      }
    };

    validateToken();
  }, [idToken, dispatch, navigate]);
};

export default Timeout;