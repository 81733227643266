import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import Endpoint from "../../../utils/Endpoint";

const initialState = {
  userDetails: null,
  loading: false,
  error: null,
};

export const fetchUserDetails = createAsyncThunk("user/fetchUserDetails", async (idToken) => {
  const response = await axios.get(Endpoint.getUserDetails, {
    headers: {
      "auth-token": idToken,
    },
  });
  return response.data.data;
});

const userSlice = createSlice({
  name: "Only_User_data",
  initialState,
  reducers: {
    resetUserDetails: (state) => {
      state.userDetails = null;
      state.loading = false;
      state.error = null;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetails = action.payload;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {resetUserDetails, setUserDetails} = userSlice.actions;
export default userSlice.reducer;