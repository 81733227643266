import React, { useEffect, useMemo, useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useTable } from "react-table";
import newdoc from "../../assets/dealsroom/svg/newdoc.svg";
import warning from "../../assets/dealsroom/svg/warning.svg";
import uploaded from "../../assets/syndicate/svg/uploaded.svg";
import action from "../../assets/dealsroom/svg/action.svg";
import pen2 from "../../assets/dealsroom/svg/pen2.svg";
import { PrimaryButtonSvgFirst } from "../../components/reusableComponents/Buttons/Buttons";

import "./DocUploadTable.scss";

const DocUploadTable = ({ setSetUpSyndicateData, setUpSyndicateData }) => {
  const [checkedRows, setCheckedRows] = useState([]);

 
  // 

  const data = useMemo(
    () => [
      {
        checkbox: "",
        filename: "Agreement.pdf",
        size: "8.6 MB",
        date: "5th of Nov, 18",
        time: "12:23 am",
        orgName: "Mark Barker",
        isYou: "You",
        hasWarning: {
          status: true,
          img: warning,
        },
        buttonWithImage: false,
        buttonImgPath: null,
        buttonText: "Setup",
      },
      {
        checkbox: "",
        filename: "Agreement.pdf",
        size: "8.6 MB",
        date: "5th of Nov, 18",
        time: "12:23 am",
        orgName: "Mark Barker",
        isYou: "You",
        hasWarning: {
          status: true,
          img: uploaded,
        },
        buttonWithImage: true,
        buttonImgPath: pen2,
        buttonText: "Edit",
      },
      {
        checkbox: "",
        filename: "Agreement.pdf",
        size: "8.6 MB",
        date: "5th of Nov, 18",
        time: "12:23 am",
        orgName: "Mark Barker",
        isYou: "You",
        hasWarning: {
          status: true,
          img: warning,
        },
        buttonWithImage: false,
        buttonImgPath: null,
        buttonText: "Setup",
      },
      {
        checkbox: "",
        filename: "Agreement.pdf",
        size: "8.6 MB",
        date: "5th of Nov, 18",
        time: "12:23 am",
        orgName: "Mark Barker",
        isYou: "You",
        hasWarning: {
          status: true,
          img: warning,
        },
        buttonWithImage: false,
        buttonImgPath: null,
        buttonText: "Setup",
      },
      // Add more data entries as needed
    ],
    [],
  );

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "checkbox",
        Cell: () => (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: "#d8dce5 !important",
                    "&.Mui-checked": {
                      color: "#8059c7 !important",
                    },
                    "& .MuiSvgIcon-root": { fontSize: 22 },
                  }}
                />
              }
            />
          </div>
        ),
      },
      {
        Header: "Document Name",
        accessor: "filename",
        Cell: ({ value, row }) => (
          <div className="deals-room-doc-pdf">
            <img
              src={newdoc}
              alt=""
            />
            <div>
              <span>{value}</span>
              <span>{row.original.size}</span>
            </div>
          </div>
        ),
      },
      {
        Header: "Uploaded",
        accessor: "date",
        Cell: ({ value, row }) => (
          <div className="deals-room-doc-date">
            <div>
              <span>{value}</span>
              <span>{row.original.time}</span>
            </div>
          </div>
        ),
      },
      {
        Header: "Owner",
        accessor: "orgName",
        Cell: ({ value, row }) => (
          <div className="deals-room-doc-org">
            <div className="deals-room-doc-org-name">
              <p>{value}</p>
              {row.original.isYou && <span> (You)</span>}
            </div>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "hasWarning",
        Cell: ({ value }) => (
          <div className="deals-room-doc-tag">
            {value.status && (
              <img
                src={value.img}
                alt=""
              />
            )}
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <div className="deals-room-doc-action action-btn">
            {row.original.buttonWithImage ? (
              <PrimaryButtonSvgFirst
                classNames="table-btn"
                name={row.original.buttonText}
                svgPath={row.original.buttonImgPath}
              />
            ) : (
              <PrimaryButtonSvgFirst
                classNames="table-btn"
                name={row.original.buttonText}
              />
            )}
          </div>
        ),
      },
      {
        Header: "",
        accessor: "threedots",
        Cell: () => (
          <div className="deals-room-doc-action">
            <img
              src={action}
              alt=""
            />
          </div>
        ),
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  const handleRowClick = (row) => {
    const isChecked = checkedRows.includes(row.original);
    const {filename , date ,orgName } = row.values
    const {id} = row;
    setCheckedRows(isChecked ? checkedRows.filter(item => item.id !== row.id) : [...checkedRows, row.original]);
    setSetUpSyndicateData(prevState => ({
      ...prevState,
      smart_contract: {
        ...prevState.smart_contract,
        upload_documents: isChecked
          ? prevState.smart_contract.upload_documents.filter(item => item.id !== row.id)
          : [...prevState.smart_contract.upload_documents, {filename,date , orgName , id}]
      }
    }));
    
  };

  return (
    <div>
      <table {...getTableProps()} className="deals-room-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="table-header">
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div className="table-header-text">{column.render("Header")}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={`${index % 2 === 0 ? "even-row" : "odd-row"} ${checkedRows.includes(row.original) ? "selected-row" : ""}`}
                onClick={() => {
                  // 
                  handleRowClick(row)
                } }
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

    </div>
  );
};

export default DocUploadTable;
