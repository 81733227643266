import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUp, faArrowDown} from "@fortawesome/free-solid-svg-icons";
import "./Select.scss";
import arrow from "../../../assets/Manage-syndicate/Svg/Down-arrow.svg";

const DropdownSelect = ({options, onSelectChange}) => {
  return (
    <div className="dropdown-select-wrapper">
      <select
        className="dropdown-select"
        onChange={onSelectChange}
      >
        {options.map((option, index) => (
          <option
            key={index}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
      <div className="dropdown-arrow">
        <img
          src={arrow}
          alt="image"
        />
      </div>
    </div>
  );
};

export default DropdownSelect;
