import React, {useState} from "react";
import "./Taxrules.scss";
import {PrimaryButton, PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import Table from "../../../components/reusableComponents/Table/ReactTable";
import SearchInput from "../../../components/reusableComponents/Search/Search";
import filter from "../../../assets/Manage-syndicate/Svg/filter.svg";
import customersuppoer from "../../../assets/Manage-syndicate/Svg/customer-support 1.svg";
import grouppeople from "../../../assets/Manage-syndicate/Svg/Group people.svg";

const Taxrules = () => {
  const [activeTab, setActiveTab] = useState("WIRHHOLDING OVERVIEW");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const data = [
    {
      STAKEHOLDER: "Belien S.",
      OUTSTANDING: "3,755,000",
      OUTSTANDINGOWNERSHIP: "40.287%",
      FULLYDILUTED: "200,000",
      FULYDILUTEDOWNERSHIP: "40.287%",
      Totalcashraised: "US$0.00",
    },
    {
      STAKEHOLDER: "Belien S.",
      OUTSTANDING: "3,755,000",
      OUTSTANDINGOWNERSHIP: "40.287%",
      FULLYDILUTED: "200,000",
      FULYDILUTEDOWNERSHIP: "40.287%",
      Totalcashraised: "US$0.00",
    },
    {
      STAKEHOLDER: "Belien S.",
      OUTSTANDING: "3,755,000",
      OUTSTANDINGOWNERSHIP: "40.287%",
      FULLYDILUTED: "200,000",
      FULYDILUTEDOWNERSHIP: "40.287%",
      Totalcashraised: "US$0.00",
    },
    {
      STAKEHOLDER: "Belien S.",
      OUTSTANDING: "3,755,000",
      OUTSTANDINGOWNERSHIP: "40.287%",
      FULLYDILUTED: "200,000",
      FULYDILUTEDOWNERSHIP: "40.287%",
      Totalcashraised: "US$0.00",
    },
    {
      STAKEHOLDER: "Belien S.",
      OUTSTANDING: "3,755,000",
      OUTSTANDINGOWNERSHIP: "40.287%",
      FULLYDILUTED: "200,000",
      FULYDILUTEDOWNERSHIP: "40.287%",
      Totalcashraised: "US$0.00",
    },
    {
      STAKEHOLDER: "Belien S.",
      OUTSTANDING: "3,755,000",
      OUTSTANDINGOWNERSHIP: "40.287%",
      FULLYDILUTED: "200,000",
      FULYDILUTEDOWNERSHIP: "40.287%",
      Totalcashraised: "US$0.00",
    },
  ];
  return (
    <div>
      {/* First */}

      <div style={{marginTop: "10px", marginBottom: "10px"}}>
        <h1>Tax Rules</h1>
      </div>
      {/* first */}
      <div className="Taxrules-header-conatiner">
        <div className="Taxrules-header-data">
          <div className="Taxrules-Container-new">
            <div className="Taxrules-Container-data">
              <div className="Taxrules-Container-WIRHHOLDING">
                <span
                  className={activeTab === "WIRHHOLDING OVERVIEW" ? "active" : ""}
                  onClick={() => handleTabClick("WIRHHOLDING OVERVIEW")}
                >
                  WIRHHOLDING OVERVIEW
                </span>
                <span
                  className={activeTab === "TAX JURISDICTIONS AND RATES" ? "active" : ""}
                  onClick={() => handleTabClick("TAX JURISDICTIONS AND RATES")}
                >
                  TAX JURISDICTIONS AND RATES
                </span>
              </div>
            </div>
            <span className="span-data">Keep stakeholder tax profiles up-to-date to help Lilypads give more accurate estimates of tax withholding amount How is withholding estimated?</span>
            <div className="Taxrules-TYD">
              <div className="Taxrules-box">
                <h1>YTD Payroll</h1>
                <h1>stakeholder address</h1>
                <h1>stakeholder relationship</h1>
              </div>
              <div className="Taxrules-box">
                <p>Last updated</p>
                <span className="span-tag">41 missing</span>
                <PrimaryButtonSvgFirst
                  name="Complete"
                  classNames="Taxrules-Completed"
                  padding="10px"
                  svgHeight="20px"
                  svgWidth="20px"
                />
              </div>
              <div className="Upload-Conatiner">
                <div>
                  <h1>Upload YTD Payroll</h1>
                </div>
                <div className="YTD-Container">
                  <div className="YTD-Container-data">
                    <SearchInput />
                    <PrimaryButtonSvgFirst
                      name="Filter"
                      classNames="Taxrules-Filter"
                      svgPath={filter}
                      padding="10px"
                      svgHeight="20px"
                      svgWidth="20px"
                    />
                  </div>
                  <p>Tax Mobility is disabled</p>
                  <PrimaryButtonSvgFirst
                    name="Confirm"
                    classNames="Taxrules-Filter"
                    svgHeight="20px"
                    svgWidth="20px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Second */}
      <div>
        <div className="Taxrules-container-data">
          <div className="Taxrules-container">
            <div className="Taxrules-container-first-data">
              <p>STAKEHOLDER</p>
              <p>OUTSTANDING</p>
              <p>OUTSTANDING OWNERSHIP</p>
              <p>FULLY DILUTED</p>
              <p>FULY DILUTED OWNERSHIP</p>
              <p>TOTAL CASH RAISED</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="Taxrules">
          {data.map((item, index) => (
            <div
              key={index}
              className="user-details-container"
            >
              <div className="user-details">
                <h5>{item.STAKEHOLDER}</h5>
                <h5>{item.OUTSTANDING}</h5>
                <h5>{item.OUTSTANDINGOWNERSHIP}</h5>
                <h5>{item.FULLYDILUTED}</h5>
                <h5>{item.FULYDILUTEDOWNERSHIP}</h5>
                <h5>{item.Totalcashraised}</h5>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* third */}
      <div>
        <div className="Tax-rules-container">
          <div className="Tax-rules-container-data">
            <div className="Tax-rules-container-datafirst">
              <h1>Quick action</h1>
              <span>YTD payroll & tax withholding reports</span>
              <p>Export a report of tax withholding events or a detailed history of uploaded YTD payroll records used for withholding taxes/</p>
              <span className="text-area">Adjust taxes on option exercises</span>
              <p>Adjustment are used for reporting only and will not impact withholding amount for approved exercises.</p>
            </div>
          </div>
          <div className="Tax-rules-container-second">
            <div className="Tax-rules-container-secondbox">
              <div className="Taxrules-image-footer">
                <img
                  src={customersuppoer}
                  alt="image"
                />
              </div>
              <h1>Tax help center</h1>
              <p>Find support articles on equity withholding taxes and a more detailed explanation of tax- related features.</p>
            </div>
          </div>
          <div className="Tax-rules-container-thid">
            <div className="Tax-rules-container-thiddata">
              <div className="Taxrules-image-footer">
                <img
                  src={grouppeople}
                  alt="image"
                />
              </div>
              <h1>Get export tax advice and equity education for your team</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Taxrules;
