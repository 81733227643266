import React from 'react'
import './searches.scss'
import see from '../../../assets/svg/see-arrow-white.svg'
import searched from '../../../assets/svg/searched.svg'
import fav from '../../../assets/svg/fav.svg'
import budget from '../../../assets/svg/budget.svg'
import DropDown from '../../../components/reusableComponents/Dropdown/Dropdown'
import { useState } from 'react'
import Delete from '../../../assets/svg/delete.svg'
import building from '../../../assets/svg/searched-1.svg'
import SavedHeader from './SavedHeader'

function Searches() {
    return (
        <>
                <SavedHeader title={'Saved Searches'} />

        <div className='searches'>
            <Searchbox />
            <Searchbox />


        </div>
        </>
    )
}

export default Searches



const Searchbox = ({ imageUrl, title, description, seeMoreLink }) => {
    const [timeList, setTimeList] = useState(["Hourly", "Daily"]);
    const [selectedTimeList, setSelectedTimeList] = useState("Hourly");
    const [timeListDa, setTimeListDa] = useState(false);

    return (
        <>
        <div className='searchbox'>
            <div className='searchbox_left'>
                <div>
                    <img src={building} alt='' />
                    <div>
                        <h2>Commercial  •  New York</h2>
                        <p>Built later than 1975, Last sold 5+ years ago</p>
                    </div>
                </div>
            </div>
            <div className='searchbox_right'>
                <DropDown
                    label=""
                    label2={'Notifications:'}
                    data={timeList}
                    selectedData={selectedTimeList}
                    setSelectedData={setTimeList}
                    dropdownState={timeListDa}
                    dropdownSetter={setTimeListDa}
                />

                <button>Search Result <img src={see} alt='' /></button>
                <img src={Delete} alt='' />
            </div>
        </div>
        </>
    );
};