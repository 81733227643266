import {PrimaryButton} from "../../components/reusableComponents/Buttons/Buttons";
import overviewRight from "../../assets/svg/overviewRight.svg";
import "./overview.scss";
import {Link} from "react-router-dom";
import { useSelector } from "react-redux";
const Overview = () => {
  const {isAuthenticated} = useSelector((state) => state.auth);

  return (
    <div className="overview">
      <div className="overview_content">
        <div className="left">
          <h1>
            Welcome to <br /> Lilypads Investor Portal
          </h1>
          <p>
            Lilypads is an AI-powered platform that empowers owners, investors, asset managers, and operators to maximize revenue by leveraging existing data to increase transactional efficiency and
            optimize property performance.
          </p>
          <Link to={isAuthenticated ? '/portfolio' : 'signup'}>
            <PrimaryButton name={"Get Started"} />
          </Link>
        </div>
        <div className="right">
          <img
            src={overviewRight}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
