import { logout } from "../store/reducers/authSlice";

export const handleLogout = (dispatch, navigate) => {
  dispatch(logout());
  localStorage.removeItem("authToken"); 
  navigate("/signin"); 
};


// import Timeout from '../../components/Autologout/Timeout'
// Timeout(idToken)