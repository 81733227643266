import React, {useState} from "react";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";
import {InputBox, InfoBox, IOSSwitch} from "../../../components/reusableComponents/FormFields/FormFields";
import {Checkbox, FormControlLabel, FormGroup, Slider} from "@mui/material";
import DocUploadTable from "../../addDoc/DocUploadTable";
import edit from "../../../assets/loanrequest/svg/edit.svg";
import eye from "../../../assets/syndicate/svg/eye.svg";
import cornor from "../../../assets/syndicate/svg/cornor.svg";
import building from "../../../assets/syndicate/svg/building.svg";
import buildingone from "../../../assets/syndicate/svg/buildingone.svg";
import remove from "../../../assets/syndicate/svg/remove.svg";
import star from "../../../assets/syndicate/svg/star.svg";
import download from "../../../assets/syndicate/svg/download.svg";
import rightPic from "../../../assets/syndicate/image/rightPic.png";
import hero from "../../../assets/syndicate/image/hero.png";
import boypic from "../../../assets/syndicate/image/boypic.png";
import location from "../../../assets/syndicate/svg/location.svg";
import lock from "../../../assets/syndicate/svg/lock.svg";
import cover from "../../../assets/syndicate/svg/cover.svg";
import link from "../../../assets/syndicate/svg/link.svg";
import video from "../../../assets/syndicate/svg/video.svg";
import grid from "../../../assets/syndicate/svg/grid.svg";
import text from "../../../assets/syndicate/svg/text.svg";
import image from "../../../assets/syndicate/svg/image.svg";
import network from "../../../assets/syndicate/svg/network.svg";
import upload from "../../../assets/syndicate/svg/upload.svg";
import addsec from "../../../assets/syndicate/svg/addsec.svg";
import camera from "../../../assets/syndicate/svg/camera.svg";
import filter from "../../../assets/syndicate/svg/filter.svg";
import navclose from "../../../assets/loanrequest/svg/navclose.svg";
import pdf from "../../../assets/dealsroom/svg/pdf.svg";
import videoplayer from "../../../assets/syndicate/svg/videoplayer.svg";
import removenew from "../../../assets/syndicate/svg/removenew.svg";
import savetick from "../../../assets/syndicate/svg/savetick.svg";
import deletered from "../../../assets/syndicate/svg/deletered.svg";
import pen2 from "../../../assets/dealsroom/svg/pen2.svg";
import profileplus from "../../../assets/loanrequest/svg/profileplus.svg";
import addprofile from "../../../assets/loanrequest/svg/addprofile.svg";
// import uploadIcon from "../../../src/assets/svg/uploadIcon.svg";
import fullscreen from "../../../assets/syndicate/svg/fullscreen.svg";
import user from "../../../assets/syndicate/svg/user.svg";
import pluspurple from "../../../assets/loanrequest/svg/pluspurple.svg";
import plus from "../../../assets/loanrequest/svg/plus.svg";
import {useDispatch} from "react-redux";
import {returnErrors} from "../../../store/reducers/error";
import {useSelector} from "react-redux";

export const LenderCard = ({data}) => {

  return (
    <div className="lender-table-main-summary">
      <div></div>
      <div className="img-wrapper-summary">
        <div>
          <img
            src={data.profile}
            alt=""
          />
        </div>
        <div>
          <span>{data.name}</span>
          <span>{data.role}</span>
        </div>
      </div>
      <div className="apr">{data.apr}</div>
      <div className="term">
        <span>{data.term}</span>
      </div>
      <div className="max-loan">{data.max_loan}</div>
      <div className="match">
        <span>{data.match}</span>
      </div>
    </div>
  );
};

export const SmartContractSyndicate = ({setUpSyndicateData, setSetUpSyndicateData}) => {
  const [navActive, setNavActive] = useState(1);

  const [selectedFileName, setSelectedFileName] = useState("");

  const preventDefault = (event) => {
    event.preventDefault();
  };

  const handleImageDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileSelection(file);
    }
  };

  const handleImageInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileSelection(file);
    }
  };

  const handleFileSelection = (file) => {
    setSelectedFileName(file.name);

    const formData = new FormData();
    formData.append("pdfFile", file);

    setSetUpSyndicateData((prevState) => ({
      ...prevState,
      smart_contract: {
        ...prevState.smart_contract,
        user_document: file,
      },
    }));
    // setDocument(file);
  };
  return (
    <div>
      <div className="deals-room-nav">
        <div
          className={navActive === 1 && "deals-room-nav-active"}
          onClick={() => {
            setNavActive(1);
          }}
        >
          <div className="switch_wrapper">
            <span id="mt-7px">Documents</span>
          </div>
        </div>
        <div
          className={navActive === 2 && "deals-room-nav-active"}
          onClick={() => {
            setNavActive(2);
          }}
        >
          <div className="switch_wrapper">
            <span>Smart Contract Parameters</span>
            <FormControlLabel
              className="testing"
              control={
                <IOSSwitch
                  sx={{m: 1}}
                  defaultChecked={true}
                />
              }
            />
          </div>
        </div>
        <div
          className={navActive === 3 && "deals-room-nav-active"}
          onClick={() => {
            setNavActive(3);
          }}
        >
          <div className="switch_wrapper">
            <span>Digital Securities</span>
            <FormControlLabel
              className="testing"
              control={
                <IOSSwitch
                  sx={{m: 1}}
                  defaultChecked={true}
                  // onChange={() =>
                  //   setPrice((prev) => ({
                  //     ...prev,
                  //     any: !prev.any,
                  //   }))
                  // }
                />
              }
            />
          </div>
        </div>
      </div>
      <div className="financial-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Your Documents</p>
        </div>
        <div className="upload-doc-body">
          <div
            className="select-doc"
            onDrop={handleImageDrop}
            onDragOver={preventDefault}
          >
            <img
            //   src={uploadIcon}
              alt=""
            />
            <div className="upload-btn-wrapper">
              <button className="btn">{selectedFileName ? selectedFileName : "Drag and drop files or click"}</button>
              <input
                type="file"
                name="myfile"
                accept="application/pdf"
                onChange={handleImageInputChange}
              />
            </div>
            <p>Supported file format: PDF Max file size 250 MB</p>
          </div>
          <div className="progress-cont">
            <div className="uploading-file">
              <div className="file-name">
                <div>
                  <img
                    src={pdf}
                    alt=""
                  />
                  <span>{selectedFileName}</span>
                </div>
                <span>1,5MB of 3,6MB</span>
              </div>
              <div className="progress-bar">
                <span className="progress"></span>
              </div>
            </div>
            <img
              src={navclose}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="financial-container">
        <div className="contract-table-head">
          <div className="contract-head-left">
            <span>Upload Documents</span>
            {/* <span>{setUpSyndicateData.smart_contract.upload_documents.length} items selected</span> */}
          </div>
          <div className="contract-head-right">
            <img
              src={deletered}
              alt=""
            />
            <span>Delete Selected</span>
          </div>
        </div>
        <DocUploadTable
          setUpSyndicateData={setUpSyndicateData}
          setSetUpSyndicateData={setSetUpSyndicateData}
        />
      </div>
    </div>
  );
};

export const TeamSyndicate = ({setUpSyndicateData, setSetUpSyndicateData}) => {
  const handleAddPoint = () => {
    if (setUpSyndicateData.team.length === 15) {
      alert("You can add only 15 teams. Please remove any existing teams.");
    } else {
      const newId = setUpSyndicateData.team.length + 1;
      const newTeam = {
        id: newId,
        team_description: "",
        members: [
          {
            name: "",
            role: "",
            location: "",
            phone: "",
            email: "",
            description: "",
            profile_pic: "",
          },
        ],
      };

      setSetUpSyndicateData((prevState) => ({
        ...prevState,
        team: [...prevState.team, newTeam],
      }));
    }
  };

  const handleInputChange = (teamId, memberId, fieldName, value) => {
    setSetUpSyndicateData((prevState) => {
      const updatedTeam = prevState.team.map((team) => {
        if (team.id === teamId) {
          if (fieldName === "team_description") {
            return {
              ...team,
              team_description: value,
            };
          } else {
            const updatedMembers = team.members.map((member) => {
              if (member.id === memberId) {
                if (fieldName === "image") {
                  const formData = new FormData();
                  formData.append("member_image", value);
                  // Update member with FormData
                  return {
                    ...member,
                    [fieldName]: value,
                  };
                } else {
                  // Update member with other fields
                  return {
                    ...member,
                    [fieldName]: value,
                  };
                }
              }
              return member;
            });
            return {
              ...team,
              members: updatedMembers,
            };
          }
        }
        return team;
      });

      return {
        ...prevState,
        team: updatedTeam,
      };
    });
  };

  const handleAddMember = (teamIndex) => {
    setSetUpSyndicateData((prevState) => {
      const updatedTeam = [...prevState.team];
      const team = updatedTeam[teamIndex];

      if (team.members.length === 3) {
        alert("You can add only three members in one team.");
        return prevState; // Return the previous state without any modifications
      } else {
        const newMember = {
          id: Math.floor(Math.random() * 100000),
          name: "",
          role: "",
          location: "",
          phone: "",
          email: "",
          description: "",
          image: "",
        };

        team.members.push(newMember); // Add the new member to the team
        return {
          ...prevState,
          team: updatedTeam,
        };
      }
    });
  };

  return (
    <div className="borrower-container">
      <div>
        <div className="financial-container loan-container">
          {setUpSyndicateData.team.map((team, index) => (
            <div
              key={team.id}
              className="financial-body team-body"
            >
              <div className="first-inner-box borro">
                <div>
                  <img
                    src={download}
                    alt="download"
                  />
                  <p>Team {team.id}</p>
                </div>
                <img
                  src={edit}
                  alt=""
                />
              </div>

              <InputBox
                label={"Team Description"}
                placeholder={"(Optional)"}
                value={team.team_description}
                onChange={(e) => handleInputChange(team.id, "", "team_description", e.target.value)}
              />

              <div className="border"></div>

              <div className="team-wrapper">
                <span>Members</span>
                {team.members.map((member, memberIndex) => (
                  <div
                    key={memberIndex}
                    className="team-wrapper-inner"
                  >
                    <div className="team-wrapper-inner-left">
                      <label htmlFor={member.id}>
                        <img
                          src={addprofile}
                          alt=""
                        />
                        <input
                          type="file"
                          name="myfile"
                          id={member.id}
                          accept="image/jpeg, image/png, image/bmp, image/tiff"
                          style={{display: "none"}}
                          onChange={(e) => handleInputChange(team.id, member.id, "image", e.target.files[0])}
                        />
                      </label>
                    </div>
                    <div className="team-wrapper-inner-right">
                      <InputBox
                        label={"Name"}
                        placeholder={"John Smith"}
                        value={member.name}
                        onChange={(e) => handleInputChange(team.id, member.id, "name", e.target.value)}
                      />
                      <div className="inp-we">
                        <InputBox
                          conCLass={"team-input"}
                          label={"ROLE"}
                          placeholder={"CEO"}
                          value={member.role}
                          onChange={(e) => handleInputChange(team.id, member.id, "role", e.target.value)}
                        />
                        <InputBox
                          type={"text"}
                          conCLass={"team-input"}
                          label={"LOCATION"}
                          placeholder={"CEO"}
                          value={member.location}
                          onChange={(e) => handleInputChange(team.id, member.id, "location", e.target.value)}
                        />
                      </div>
                      <div className="inp-we">
                        <InputBox
                          conCLass={"team-input"}
                          label={"PHONE"}
                          placeholder={"000-000-0000"}
                          value={member.phone}
                          onChange={(e) => handleInputChange(team.id, member.id, "phone", e.target.value)}
                        />
                        <InputBox
                          type={"text"}
                          conCLass={"team-input"}
                          label={"EMAIL"}
                          placeholder={"name@companyname.com"}
                          value={member.email}
                          onChange={(e) => handleInputChange(team.id, member.id, "email", e.target.value)}
                        />
                      </div>
                      <InputBox
                        label={"Team Description"}
                        placeholder={"(Optional)"}
                        value={member.description}
                        onChange={(e) => handleInputChange(team.id, member.id, "description", e.target.value)}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="border"></div>
              <div className="key-d-add">
                <button onClick={() => handleAddMember(index)}>
                  <img
                    src={profileplus}
                    alt=""
                  />
                  <span>Add another Guarantor</span>
                </button>
              </div>
            </div>
          ))}

          <div className="key-d-add">
            <button onClick={handleAddPoint}>
              <img
                src={pluspurple}
                alt=""
              />
              <p>Add a new Team</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DisclaimerSyndicate = ({setSetUpSyndicateData}) => {
  return (
    <div>
      <div className="financial-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Discription</p>
        </div>
        <div className="financial-body">
          <div className={"input-box area-box "}>
            {/* <label htmlFor="box">{}</label> */}
            <div>
              <textarea
                placeholder="Type here"
                id="box"
                onChange={(e) => {
                  setSetUpSyndicateData((prevState) => ({
                    ...prevState,
                    disclaimer: e.target.value,
                  }));
              
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <h1 id="disc-heading">Add Info manually</h1>
      <div className="general-syndicate-body disclaimer-card">
        <div className="add-info-man">
          <div className="add-info-img">
            <img
              src={text}
              alt=""
            />
          </div>
          <span>Text</span>
        </div>
        <div className="add-info-man">
          <div className="add-info-img">
            <img
              src={image}
              alt=""
            />
          </div>
          <span>Image</span>
        </div>
        <div className="add-info-man">
          <div className="add-info-img">
            <img
              src={video}
              alt=""
            />
          </div>
          <span>Video</span>
        </div>
        <div className="add-info-man">
          <div className="add-info-img">
            <img
              src={grid}
              alt=""
            />
          </div>
          <span>Table</span>
        </div>
      </div>
    </div>
  );
};

export const SummarySyndicate = ({path, setNavActive, loadData, isChecked, handleCheckboxChange, checkBoxDivRef}) => {
  // General
  const {general} = loadData;
  const {deal_properties} = loadData.general;

  // Loan details
  const {finacial_details} = loadData.load_details;
  const {additional_financing, upload_financial_documents, requested_purchase_financing} = loadData.load_details.finacial_details;

  // Lender
  const {lenders_list} = loadData.lender;

  // Business Plan
  const financialDoc = loadData.business_plan.upload_financial_documents;
  const {webinar_registration} = loadData.business_plan;

  //Key deal point
  const {deal_points_list} = loadData.key_deal_points;

  // Borrower
  const {legal_investor_list, guarantor_investor_list} = loadData.borrower;

  //Team
  const {team} = loadData;

  //Contacts
  const {primary_contact, social_networks, website_links, additional_contact} = loadData.contacts;

  const handleFullScreenView = () => {};

  return (
    <div>
      <div
        className="summary_parent_container"
        style={{width: "10% !important"}}
      >
        <div className="message-container finish-head">
          <div className="form-heading">
            <img
              src={download}
              alt=""
            />
            <span>Summary</span>
          </div>

          <div>
            <img
              src={pen2}
              alt=""
              onClick={() => setNavActive(1)}
            />
          </div>
        </div>
        <div className="summary-container">
          <div className="summary_overview">
            <div className="summaryBox">
              <h1>General </h1>

              <SummaryDetailsCard
                heading={"Deal Name"}
                text={deal_properties?.deal_name}
              />
              <SummaryDetailsCard
                heading={"Financing Purpose"}
                text={deal_properties?.financing_purpose}
              />
              <SummaryDetailsCard
                heading={"Status"}
                text={deal_properties?.status}
              />
              <SummaryDetailsCard
                heading={"Custom Link"}
                link={general?.custom_links}
              />
              <SummaryDetailsCard
                heading={"Cover Image"}
                text={general.cover_images ? "Yes" : "No"}
              />
              {/* <SummaryDetailsCard heading={'Cover Link'} text={general.cover_images ? 'Yes' : 'No'} /> */}
            </div>

            <div className="summaryBox">
              <h1>Loan Details </h1>
              <SummaryDetailsCard
                heading={"Requested Purchase"}
                text={requested_purchase_financing.from && requested_purchase_financing.to && `${requested_purchase_financing.from} to ${requested_purchase_financing.to}`}
              />
              <SummaryDetailsCard
                heading={"Loan Term"}
                text={finacial_details?.loan_term}
              />
              <SummaryDetailsCard
                heading={"Closing Date"}
                text={finacial_details?.desired_closing_date}
              />
              <SummaryDetailsCard
                heading={"Loan Type"}
                text={finacial_details?.loan_type}
              />
              <SummaryDetailsCard
                heading={"Offering Type"}
                text={finacial_details?.offering_type}
              />
              <SummaryDetailsCard
                heading={"Repayment"}
                text={finacial_details?.repayment_strategy}
              />
              <SummaryDetailsCard
                heading={"Additionla Financing Amount"}
                text={additional_financing?.additional_financing_amount}
              />
              <SummaryDetailsCard
                heading={"Additionla Financing Type"}
                text={additional_financing?.additional_financing_types}
              />
              <SummaryDetailsCard
                heading={"Document"}
                text={upload_financial_documents?.images ? "Yes" : "No"}
              />
            </div>

            <div className="summaryBox">
              <h1>Lenders {lenders_list?.length === 0 ? "" : lenders_list?.length}</h1>
              {lenders_list?.length === 0 ? (
                <SummaryDetailsCard
                  heading={"Lenders"}
                  text={"No Lenders"}
                />
              ) : (
                lenders_list.map((item, index) => <LenderCard data={item} />)
              )}
            </div>
            <div className="summaryBox">
              <h1>Business Plan</h1>
              <SummaryDetailsCard
                heading={"Webinar Description"}
                text={webinar_registration.description ? "Yes" : "No"}
              />
              <SummaryDetailsCard
                heading={"Webinar Link"}
                text={webinar_registration.link ? "Yes" : "No"}
              />
              <SummaryDetailsCard
                heading={"Business Plan Document"}
                text={financialDoc.images ? "Yes" : "No"}
              />
            </div>

            <div className="summaryBox">
              <h1>Key Deal Points</h1>
              <SummaryDetailsCard
                heading={"Deal Points"}
                text={deal_points_list.length}
              />
            </div>
            <div className="summaryBox">
              <h1>Guarantor Investor</h1>
              {guarantor_investor_list?.map((item, index) => (
                <SummaryDetailsCard
                  heading={`Investor ${index + 1}`}
                  text={item.guarantor_full_name}
                  key={index}
                />
              ))}
            </div>
            <div className="summaryBox">
              <h1>Legal Investor</h1>
              <SummaryDetailsCard
                heading={`Investor`}
                text={legal_investor_list?.legal_name}
              />
            </div>
            <div className="summaryBox">
              <h1>Teams</h1>
              {team?.map((item, index) => {
                return (
                  <>
                    <SummaryDetailsCard
                      heading={`Team ${index + 1}`}
                      text={"-"}
                    />

                    <SummaryDetailsCard
                      heading={`Members`}
                      text={item.members.length}
                    />
                  </>
                );
              })}
            </div>

            <div className="summaryBox">
              <h1>Primary Contact</h1>
              {primary_contact.phone === "" ? (
                <SummaryDetailsCard
                  heading={`Primary Contact`}
                  text={"No Contact"}
                />
              ) : (
                <>
                  <SummaryDetailsCard
                    heading={`Name`}
                    text={primary_contact.name}
                  />
                  <SummaryDetailsCard
                    heading={`Email`}
                    text={primary_contact.email}
                  />
                  <SummaryDetailsCard
                    heading={`Phone`}
                    text={primary_contact.email}
                  />
                </>
              )}
            </div>
            <div className="summaryBox">
              <h1>Additional Contact</h1>
              {additional_contact?.length === 0 ? (
                <SummaryDetailsCard
                  heading={`Additional Contacts`}
                  text={"No Contacts"}
                />
              ) : (
                additional_contact?.map((item, index) => (
                  <SummaryDetailsCard
                    heading={item.phone}
                    text={"-"}
                  />
                ))
              )}
            </div>

            <div
              className="summaryBox"
              ref={checkBoxDivRef}
            >
              <h1>Disclaimer</h1>
              <SummaryDetailsCard
                heading={"Disclaimer"}
                text={loadData?.disclaimer?.images ? "Yes" : "No"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="financial-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Terms & Conditions Agreement</p>
        </div>
        <div className="term-box">
          <div className="terms-box-top">
            <h1>Terms & Conditions</h1>
            <h2>Using the Services</h2>
            <p>
              Authority. You agree that you are permitted to use the Services under applicable law. If you are using the Services on behalf of a company, business or other entity, you represent that
              you have the legal authority to accept these Terms on behalf of that entity, in which case that entity accepts these Terms, and "you" means that entity. If you are accessing an
              account(s) on behalf of the account owner (e.g., as an administrator, consultant, analyst, etc.), the Terms apply to your activities on behalf of the account owner.
            </p>
          </div>
          <div className="term-box-bottom">
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="I agree with Lilypads Terms & Conditions"
                />
              </FormGroup>
            </div>
            <img
              src={fullscreen}
              alt=""
              onClick={handleFullScreenView}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const SetupSyndicateSummary = ({setNavActive}) => {
  return (
    <div>
      <div
        className="summary_parent_container"
        style={{width: "10% !important"}}
      >
        <div className="message-container finish-head">
          <div className="form-heading">
            <img
              src={download}
              alt=""
            />
            <span>Summary</span>
          </div>

          <div>
            <img
              src={pen2}
              alt=""
              onClick={() => setNavActive(1)}
            />
          </div>
        </div>
        <div className="summary-container">
          <div className="summary_overview">
            <div className="summaryBox">
              <h1>General </h1>

              <SummaryDetailsCard
                heading={"Deal Name"}
                text={""}
              />
              <SummaryDetailsCard
                heading={"Financing Purpose"}
                text={""}
              />
              <SummaryDetailsCard
                heading={"Status"}
                text={""}
              />
              <SummaryDetailsCard
                heading={"Custom Link"}
                text={""}
              />
              <SummaryDetailsCard
                heading={"Cover Image"}
                text={""}
              />
              <SummaryDetailsCard
                heading={"Cover Link"}
                text={""}
              />
            </div>

            <div className="summaryBox">
              <h1>Loan Details </h1>
              <SummaryDetailsCard
                heading={"Requested Purchase"}
                text={""}
              />
              <SummaryDetailsCard
                heading={"Loan Term"}
                text={""}
              />
              <SummaryDetailsCard
                heading={"Closing Date"}
                text={""}
              />
              <SummaryDetailsCard
                heading={"Loan Type"}
                text={""}
              />
              <SummaryDetailsCard
                heading={"Offering Type"}
                text={""}
              />
              <SummaryDetailsCard
                heading={"Repayment"}
                text={""}
              />
              <SummaryDetailsCard
                heading={"Additionla Financing Amount"}
                text={""}
              />
              <SummaryDetailsCard
                heading={"Additionla Financing Type"}
                text={""}
              />
              <SummaryDetailsCard
                heading={"Document"}
                text={""}
              />
            </div>

            <div className="summaryBox">
              <h1>Business Plan</h1>

              <SummaryDetailsCard
                heading={"Webinar Description"}
                text={""}
              />
              <SummaryDetailsCard
                heading={"Webinar Link"}
                text={""}
              />
              <SummaryDetailsCard
                heading={"Business Plan Document"}
                text={""}
              />
            </div>

            <div className="summaryBox">
              <h1>Key Deal Points</h1>
              <SummaryDetailsCard
                heading={"Deal Points"}
                text={""}
              />
            </div>

            <div className="summaryBox">
              <h1>Legal Investor</h1>
              <SummaryDetailsCard
                heading={`Investor`}
                text={""}
              />
            </div>

            <div className="summaryBox">
              <h1>Additional Contact</h1>
              <SummaryDetailsCard
                heading={`Additional Contacts`}
                text={"No Contacts"}
              />
            </div>

            <div className="summaryBox">
              <h1>Disclaimer</h1>
              <SummaryDetailsCard
                heading={"Disclaimer"}
                text={""}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="financial-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Terms & Conditions Agreement</p>
        </div>
        <div className="term-box">
          <div className="terms-box-top">
            <h1>Terms & Conditions</h1>
            <h2>Using the Services</h2>
            <p>
              Authority. You agree that you are permitted to use the Services under applicable law. If you are using the Services on behalf of a company, business or other entity, you represent that
              you have the legal authority to accept these Terms on behalf of that entity, in which case that entity accepts these Terms, and "you" means that entity. If you are accessing an
              account(s) on behalf of the account owner (e.g., as an administrator, consultant, analyst, etc.), the Terms apply to your activities on behalf of the account owner.
            </p>
          </div>
          <div className="term-box-bottom">
            <div>
              <Checkbox />
              <span> I agree with Lilypads Terms & Conditions</span>
            </div>
            <img
              src={fullscreen}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const NetworkContact = ({imgSrc, name, role, statusImgSrc, percentage, whatImgSrc, position, tooltipText}) => (
  <div className={`network-${position}-body`}>
    <div className="network-profile">
      <div>
        <img
          id="womenimg"
          src={imgSrc}
          alt=""
        />
      </div>
      <div>
        <p>{name}</p>
        <span>{role}</span>
      </div>
    </div>
    <div className="network-status">
      <img
        src={statusImgSrc}
        alt=""
      />
      <span>{percentage}</span>
      <div className="custom-tooltip">
        <img
          src={whatImgSrc}
          alt=""
        />
        <div
          className="tooltip-content"
          dangerouslySetInnerHTML={{__html: tooltipText ? tooltipText.replace(/\n/g, "<br>") : ""}}
        >
      
        </div>
      </div>
    </div>
  </div>
);

export const SummaryDetailsCard = ({heading, text, link}) => {
  return (
    <div className="summaryCard">
      <p className="card_heading">{heading}</p>
      {link ? (
        <a
          href={link}
          target="_blank"
        >
          {link.length > 30 ? link.slice(0, 30) + "..." : link}
        </a>
      ) : (
        <p>{text || "N/A"}</p>
      )}
    </div>
  );
};

export const Button = ({icon, value, setNavActive, navActive}) => {
  return (
    <div
      className="header-button"
      onClick={() => (value === "Back" ? setNavActive(navActive === 1 ? 1 : navActive - 1) : null)}
    >
      <img
        src={icon}
        alt="icon"
      />
      <p>{value}</p>
    </div>
  );
};

export const Yield = ({name, value}) => {
  return (
    <div className="yield">
      <p className="first-child">{name}</p>
      <p className="last-child">{value}</p>
    </div>
  );
};

export const Yieldd = ({name, value}) => {
  return (
    <div className="yieldd">
      <p className="firstt-child">{name}</p>
      <p className="lastt-child">{value}</p>
    </div>
  );
};

export const Yielddd = ({name, value, valueSecond}) => {
  return (
    <div className="yielddd">
      <p className="firsttt-child">{name}</p>
      <p className="lasttt-child">{value}</p>
      <p className="lasttt-child-child">{valueSecond}</p>
    </div>
  );
};

export const List = ({value}) => {
  return <li className="list">{value}</li>;
};

export const DealsRoomLeft = ({handleMenuClicked, navActive, setNav, setNavActive, setSetUpSyndicateData, setUpSyndicateData}) => {
  const {selectedProperty} = useSelector((state) => state.portfolio);



  return (
    <div className="StartSyndicate-conatiner">
      <div className="StartSyndicate-deals-room-left-container">
        {navActive != 11 && (
          <>
            <div className="hero-section-image">
              <img
                src={hero}
                alt=""
              />
              <div className="hero-section-img-title">
                <span>North Group Trust</span>
                <div>
                  <img
                    src={camera}
                    alt=""
                  />
                  <span>Edit Cover</span>
                </div>
              </div>
            </div>
            <div className="scroll-handler">
              <div className="deals-room-nav StartSyndicate-nav">
                <span
                  className={navActive === 1 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(1);
                  }}
                >
                  General
                </span>
                <span
                  className={navActive === 2 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(2);
                  }}
                >
                  investment
                </span>
                {/* <span
                    className={navActive === 3 && "deals-room-nav-active"}
                    onClick={() => {
                      setNavActive(3);
                    }}
                  >
                    Sponsor
                  </span> */}
                <span
                  className={navActive === 4 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(4);
                  }}
                >
                  The Business Plan
                </span>
                <span
                  className={navActive === 5 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(5);
                  }}
                >
                  Key Deal Points
                </span>
                {/* <span
                    className={navActive === 6 && "deals-room-nav-active"}
                    onClick={() => {
                      setNavActive(6);
                    }}
                  > */}
                {/* Smart Contract
                  </span> */}
                <span
                  className={navActive === 7 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(7);
                  }}
                >
                  Team
                </span>
                <span
                  className={navActive === 8 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(8);
                  }}
                >
                  Contacts
                </span>
                <span
                  className={navActive === 9 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(9);
                  }}
                >
                  Disclaimer
                </span>
                <span
                  style={{paddingRight: "20px"}}
                  className={navActive === 10 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(10);
                  }}
                >
                  Summary
                </span>
              </div>
            </div>
          </>
        )}

        {navActive === 1 && (
          <GeneralSyndicate
            setUpSyndicateData={setUpSyndicateData}
            setSetUpSyndicateData={setSetUpSyndicateData}
          />
        )}
        {navActive === 2 && (
          <InvestmentSyndicate
            setSetUpSyndicateData={setSetUpSyndicateData}
            setUpSyndicateData={setUpSyndicateData}
          />
        )}
        {/* {navActive === 3 && <SponsorSyndicate />} */}
        {navActive === 4 && (
          <BussinessSyndicate
            setSetUpSyndicateData={setSetUpSyndicateData}
            setUpSyndicateData={setUpSyndicateData}
          />
        )}
        {navActive === 5 && (
          <KeyDealSyndicate
            setSetUpSyndicateData={setSetUpSyndicateData}
            setUpSyndicateData={setUpSyndicateData}
          />
        )}
        {navActive === 6 && (
          <SmartContractSyndicate
            setUpSyndicateData={setUpSyndicateData}
            setSetUpSyndicateData={setSetUpSyndicateData}
          />
        )}
        {navActive === 7 && (
          <TeamSyndicate
            setSetUpSyndicateData={setSetUpSyndicateData}
            setUpSyndicateData={setUpSyndicateData}
          />
        )}
        {navActive === 8 && (
          <TeamSyndicate
            setSetUpSyndicateData={setSetUpSyndicateData}
            setUpSyndicateData={setUpSyndicateData}
          />
        )}
        {navActive === 9 && (
          <DisclaimerSyndicate
            setSetUpSyndicateData={setSetUpSyndicateData}
            setUpSyndicateData={setUpSyndicateData}
          />
        )}
        {/* {navActive === 10 && <SummarySyndicate path={"/newsyndicate"} navActive={navActive} />} */}
        {navActive === 10 && (
          <SetupSyndicateSummary
            path={"/newsyndicate"}
            setNavActive={setNavActive}
          />
        )}
        {navActive === 11 && <SubmitProposal handleMenuClicked={handleMenuClicked} />}
      </div>
    </div>
  );
};

export const SubmitProposal = ({handleMenuClicked}) => {
  return (
    <div className="sp">
      <div className="sp_text">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <g clip-path="url(#clip0_13390_372443)">
            <path
              d="M20 0C8.97231 0 0 8.97154 0 20C0 31.0285 8.97231 40 20 40C31.0277 40 40 31.0285 40 20C40 8.97154 31.0277 0 20 0ZM20 38.4615C9.82077 38.4615 1.53846 30.1792 1.53846 20C1.53846 9.82077 9.82077 1.53846 20 1.53846C30.1792 1.53846 38.4615 9.82077 38.4615 20C38.4615 30.1792 30.1792 38.4615 20 38.4615Z"
              fill="#009639"
            />
            <path
              d="M29.4247 11.7969L17.6024 25.0969L10.4801 19.3992C10.1493 19.1338 9.66391 19.1884 9.39929 19.5192C9.13391 19.8515 9.18775 20.3354 9.51929 20.6008L17.2116 26.7546C17.3531 26.8677 17.5231 26.9231 17.6924 26.9231C17.9047 26.9231 18.1162 26.8354 18.2678 26.6646L30.5754 12.8185C30.8578 12.5015 30.8293 12.0146 30.5116 11.7331C30.1931 11.45 29.7078 11.4785 29.4247 11.7969Z"
              fill="#009639"
            />
          </g>
          <defs>
            <clipPath id="clip0_13390_372443">
              <rect
                width="40"
                height="40"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
        <p>
          The Syndicate was successfuly created and invitation has been sent<br></br> to Private Investors inerested in such opportunities
        </p>
      </div>

      <div className="sp_btns">
        <Button
          icon={eye}
          value={"Investor's Preview"}
        />

        <div
          onClick={() => handleMenuClicked(15)}
          style={{marginTop: "0px"}}
          className="header-button-last"
        >
          <p>Track Updates</p>
        </div>
      </div>
    </div>
  );
};

export const GeneralSyndicate = ({setUpSyndicateData, setSetUpSyndicateData}) => {
  const [dealNameList, setDealNameList] = useState(["Purchasing Asset", "Refinancing Existing Asset", "Construction"]);
  const [countryOfCitizenship, setCountryOfCitizenship] = useState("Purchasing Asset");
  const [countryOfCitizenshipDA, setCountryOfCitizenshipDA] = useState(false);
  const [isClicked, setIsClicked] = useState(1);
  const [coverImage, setCoverImage] = useState();
  const [previewURL, setPreviewURL] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    // Check file type
    const allowedTypes = ["image/jpeg", "image/png", "image/bmp", "image/tiff"];
    if (!allowedTypes.includes(file.type)) {
      alert("Please select a JPG, PNG, BMP, or TIFF file.");
      return;
    }

    // Check file size
    const maxSizeMB = 20;
    if (file.size > maxSizeMB * 1024 * 1024) {
      alert("File size exceeds the maximum allowed size of 20 MB.");
      return;
    }

    // Set the preview URL
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewURL(reader.result);
    };
    reader.readAsDataURL(file);

    // Create a new FormData object
    const formData = new FormData();
    formData.append("cover_image", file);

    // Set the FormData object in state using setCoverImage
    setCoverImage(file);

    handleChangeGeneral("cover_doc", file);
  };

  // Function to update general state
  const handleChangeGeneral = (key, value) => {
    setSetUpSyndicateData((prevState) => ({
      ...prevState,
      general: {
        ...prevState.general,
        [key]: value,
      },
    }));
  };

  return (
    <>
      <div className="first-box">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Deal Name</p>
        </div>
        <div className="purchasing_asset">
          <DropDown
            classDataName={"dropdown-height syndicate-dropdown"}
            data={dealNameList}
            selectedData={setUpSyndicateData.general.deal_name}
            setSelectedData={(value) => handleChangeGeneral("deal_name", value)}
            dropdownState={countryOfCitizenshipDA}
            dropdownSetter={setCountryOfCitizenshipDA}
          />
        </div>
      </div>

      <div className="first-box box-new ">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Syndicate high-concept pitch</p>
        </div>
        <div className="financial-body">
          <InputBox
            placeholder={"value"}
            onChange={(e) => handleChangeGeneral("high_concept_pitch", e.target.value)}
            value={setUpSyndicateData.general.high_concept_pitch}
            width="100%"
          />
        </div>
      </div>

      <div className="first-box  ">
        <div className="first-inner-box">
          <img
            src={link}
            alt="download"
          />
          <p>Custom link</p>
        </div>

        <div className="setup-general">
          <InputBox
            type={"text"}
            placeholder={"app.lilypads.com/crowdfunding/syndicates/21f621f61f632f4126f"}
            value={setUpSyndicateData.general.custom_link}
            onChange={(e) => handleChangeGeneral("custom_link", e.target.value)}
          />
        </div>
      </div>

      <div className="financial-container upload-image loan-container">
        <div className="first-inner-box">
          <img
            src={cover}
            alt="download"
          />
          <p>Cover Image</p>
        </div>

        <div className="switch-btn switch-syndicate">
          <span
            onClick={() => {
              setIsClicked(1);
            }}
            className={isClicked === 1 && "back"}
          >
            Upload document
          </span>
          <span
            onClick={() => {
              setIsClicked(2);
            }}
            className={isClicked === 2 && "back"}
          >
            Custom Link
          </span>
        </div>
        {isClicked === 1 && (
          <div className="big-box">
            <div className="inner-big-box">
              <div className="preview-box"></div>
              <div className="upload-btn-wrapper">
                <button className="btn">
                  {previewURL ? (
                    <img
                      src={previewURL}
                      alt="Preview"
                      width="10%"
                    />
                  ) : (
                    <img
                    //   src={uploadIcon}
                      alt=""
                    />
                  )}
                  Drag and drop files or click
                </button>
                <input
                  type="file"
                  name="myfile"
                  onChange={handleFileChange}
                  accept="image/jpeg, image/png, image/bmp, image/tiff"
                />
              </div>
              <p className="second-para">Supported file formats: JPEG, PNG, BMP, TIFF, max 20 MB.</p>
            </div>
          </div>
        )}
        {isClicked === 2 && (
          <InputBox
            label={"IMAGE LINK"}
            svgPath={link}
            placeholder={"Custom Link"}
            conCLass={"link-inp"}
            onChange={(e) => handleChangeGeneral("cover_link", e.target.value)}
            value={setUpSyndicateData.general.cover_link}
          />
        )}
      </div>

      <div className="first-box">
        <div className="manager">
          <div className="first-manager">
            <img
              src={user}
              alt=""
            />
            <p>Manager and collaborators</p>
          </div>

          <p className="admin">ADMIN</p>

          <div className="inner-manager">
            <div className="inner-manager-flex">
              <div className="user-detail">
                <img
                  src={boypic}
                  alt="boypic"
                />
              </div>

              <div className="name-you">
                <p className="mark">Mark Barker</p>
                <p className="you">You</p>
              </div>
            </div>

            <button>Edit</button>
          </div>

          <div className="collaborators">
            <span>Collaborators</span>
            <span>(3)</span>
          </div>

          <h3 className="add-people">Add people</h3>

          <div className="people">
            <input
              type="text"
              placeholder="Name or email address"
              value=""
            />
            <input
              className="second-input"
              type="text"
              placeholder="Permission : Select"
              value=""
            />
            <button>Add</button>
          </div>

          <h3 className="notified">After people will be notified by email they have to confirm invitation.</h3>
          <Collaborators />
          <Collaborators />
        </div>
      </div>

      <div className="last-section">
        <div className="inner-section">
          <img
            src={lock}
            alt="lock"
          />
          <span>Privacy</span>
        </div>

        <div className="private">
          <div className="">
            <div className="StartSyndicate-Checkbox">
              <input
                type="radio"
                name="fav_language"
                value={setUpSyndicateData.general.is_private_syndicate}
                onChange={(e) => handleChangeGeneral("is_private_syndicate", false)}
              />
              <label for="html">Promoted</label>
            </div>
            <br />
            <p>
              Your syndicate may appear In the <span>syndicates directory</span>, and your profile will be accessible via URL. This helps you grow your LP network.
            </p>
          </div>

          <div>
            <div className="StartSyndicate-Checkbox">
              <input
                type="radio"
                name="fav_language"
                value={setUpSyndicateData.general.is_private_syndicate}
                onChange={(e) => handleChangeGeneral("is_private_syndicate", true)}
              />
              <label for="css">Privated</label>
            </div>
            <p>
              Your syndicate will be hidden from the <span>syndicates directory. </span>
              Your syndicate profile will only be accessible via a custom invite link.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export const Collaborators = () => {
  return (
    <div>
      <div className="repeat">
        <div className="inner-manager test-manager">
          <div className="inner-manager-flex">
            <div className="user-detail">
              <img
                src={boypic}
                alt="boypic"
              />
            </div>

            <div className="name-you">
              <p className="mark">Della Garrett</p>
              <p className="you">Marketing</p>
            </div>
          </div>

          <div className="temp">
            <img
              src={remove}
              alt="remove"
            />
            <span>Remove</span>
          </div>
        </div>

        <div className="user-box">
          <div style={{marginLeft: "40px"}}>
            <div>
              <Checkbox />
              <span>Admin</span>
            </div>
            <div>
              <Checkbox />
              <span>Submit and run deals</span>
            </div>
            <div>
              <Checkbox />
              <span>Contact Users</span>
            </div>
          </div>
          <div style={{marginLeft: "40px"}}>
            <div>
              <Checkbox />
              <span>Can invite others</span>
            </div>
            <div>
              <Checkbox />
              <span>View full documentation</span>
            </div>
            <div>
              <Checkbox />
              <span>Message LP’s</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const InvestmentSyndicate = ({setSetUpSyndicateData, setUpSyndicateData}) => {
  const [countryList, setCountrylist] = useState(["Senior Debt", "Equity 1031-Exchange", "Common Equity", "Preferred Equity", "Junior Debt", "Mezzanine Debt", "Senior Debt"]);
  const [countryOfCitizenship, setCountryOfCitizenship] = useState("Senior Debt");
  const [countryOfCitizenshipDA, setCountryOfCitizenshipDA] = useState(false);

  const [offeringTypeList, setOfferingTypeList] = useState(["Monthly", "Quarterly", "Half Yearly", "Yearly"]);
  const [dealLocationList, setDealLocationList] = useState(["United States", "India", "UK"]);
  const [financingPurposeList, setFinancingPurposeList] = useState(["Purchasing Asset", "Refinancing existing Asset", "Construction"]);
  const [assetTypeList, setAssetTypeList] = useState(["Multifamily", "Commercial", "Offices"]);
  const [engagementList, setEngagementList] = useState(["Individual", "Entity (e.g. LLC)"]);
  const [purchaseStageList, setPurchaseStageList] = useState(["Under Contract", "Making an Offer", "Actively Looking"]);
  const [renovationList, setRenovationList] = useState(["Yes", "No"]);
  const [statusList, setStatusList] = useState(["Active", "Pending", "Cancelled"]);

  // Default values
  const defaultOfferingType = "Monthly";
  const defaultDealLocation = "United States";
  const defaultFinancingPurpose = "Purchasing Asset";
  const defaultAssetType = "Multifamily";
  const defaultEngagement = "Individual";
  const defaultPurchaseStage = "Under Contract";
  const defaultRenovation = "Yes";
  const defaultStatus = "Active";

  // State variables
  const [offeringType, setOfferingType] = useState(defaultOfferingType);
  const [dealLocation, setDealLocation] = useState(defaultDealLocation);
  const [financingPurpose, setFinancingPurpose] = useState(defaultFinancingPurpose);
  const [assetType, setAssetType] = useState(defaultAssetType);
  const [engagement, setEngagement] = useState(defaultEngagement);
  const [purchaseStage, setPurchaseStage] = useState(defaultPurchaseStage);
  const [renovation, setRenovation] = useState(defaultRenovation);
  const [status, setStatus] = useState(defaultStatus);

  // State variables for dropdown visibility
  const [offeringTypeDA, setOfferingTypeDA] = useState(false);
  const [dealLocationDA, setDealLocationDA] = useState(false);
  const [financingPurposeDA, setFinancingPurposeDA] = useState(false);
  const [assetTypeDA, setAssetTypeDA] = useState(false);
  const [engagementDA, setEngagementDA] = useState(false);
  const [purchaseStageDA, setPurchaseStageDA] = useState(false);
  const [renovationDA, setRenovationDA] = useState(false);
  const [statusDA, setStatusDA] = useState(false);

  const [price, setPrice] = useState({from: 20000, to: 8000000, any: false});
  const [priceone, setPriceone] = useState({from: 20000, to: 8000000, any: false});
  const [pricetwo, setPricetwo] = useState({from: 20000, to: 8000000, any: false});
  const [pricethree, setPricethree] = useState({from: 20, to: 100, any: false});
  const [pricefour, setPricefour] = useState({from: 2000, to: 100000, any: false});

  const handleChangeInvestment = (key, value) => {
    setSetUpSyndicateData((prevState) => ({
      ...prevState,
      investment_finacial_details: {
        ...prevState.investment_finacial_details,
        [key]: value,
      },
    }));
  };

  const handleChangeTerms = (key, value) => {
    setSetUpSyndicateData((prevState) => ({
      ...prevState,
      investment_syndicate_terms: {
        ...prevState.investment_syndicate_terms,
        [key]: value,
      },
    }));
  };

  return (
    <div className="investment-main-container">
      <div className="financial-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Financial details</p>
        </div>
        <div className="financial-body">
          <div className="financial-body-left">
            <DropDown
              label={"Offering Type"}
              classDataName={"dropdown-height "}
              data={countryList}
              selectedData={setUpSyndicateData.investment_finacial_details.off}
              setSelectedData={(val) => handleChangeInvestment("offering_type", val)}
              dropdownState={countryOfCitizenshipDA}
              dropdownSetter={setCountryOfCitizenshipDA}
            />
            <div className="slider-label">
              <span>Funding target</span>
            </div>
            <div className="slider_container">
              <div className="top_div inputFlexLine">
                <p>${price.from}</p>
              </div>
              <Slider
                defaultValue={setUpSyndicateData.investment_finacial_details.funding_target}
                aria-label="Default"
                valueLabelDisplay="auto"
                step={5000}
                min={0}
                max={8000000}
                className="Slider-range"
                onChange={(e) => handleChangeInvestment("funding_target", e.target.value)}
              />
            </div>
            <div className="slider-label">
              <span>Funding threshold</span>
            </div>
            <div className="slider_container">
              <div className="top_div inputFlexLine">
                <p>${priceone.from}</p>
                <span>%</span>
              </div>
              <Slider
                defaultValue={setUpSyndicateData.investment_finacial_details.funding_threshold}
                aria-label="Default"
                valueLabelDisplay="auto"
                step={5000}
                min={0}
                max={4000000}
                className="Slider-range"
                onChange={(e) => handleChangeInvestment("funding_threshold", e.target.value)}
              />
            </div>
            <InputBox
              conCLass={"slider-label"}
              label={"Targeted Investment Period"}
              type="text"
              onChange={(e) => handleChangeInvestment("target_investment_period", e.target.value)}
            />
            <div className="slider-label">
              <span>Minimum Investment commitment</span>
            </div>
            <div className="slider_container">
              <div className="top_div inputFlexLine">
                <p>${pricetwo.from}</p>
              </div>
              <Slider
                defaultValue={setUpSyndicateData.investment_finacial_details.minimum_investment}
                aria-label="Default"
                valueLabelDisplay="auto"
                step={5000}
                min={0}
                max={300000}
                className="Slider-range"
                onChange={(e) => handleChangeInvestment("minimum_investment", e.target.value)}
              />
            </div>
            <div className="slider-label">
              <span>Amount of carry investors pay on the deal</span>
            </div>
            <div className="slider_container">
              <div className="top_div inputFlexLine">
                <p></p>
                <span>{pricethree.from}&nbsp;%</span>
              </div>
              <Slider
                defaultValue={setUpSyndicateData.investment_finacial_details.amount_of_carry_investment}
                aria-label="Default"
                valueLabelDisplay="auto"
                step={10}
                min={0}
                max={100}
                className="Slider-range"
                onChange={(e) => handleChangeInvestment("amount_of_carry_investment", e.target.value)}
              />
            </div>
          </div>

          <div className="financial-body-right">
            <div className="fin-input-time">
              <InputBox
                label={"Offer Closing date"}
                type="date"
                value={setUpSyndicateData.investment_finacial_details.offer_closing_date}
                onChange={(e) => handleChangeInvestment("offer_closing_date", e.target.value)}
              />
              <InputBox type="time" />
            </div>
            <InputBox
              conCLass={"last-input"}
              label={"Amount of Fund Circled"}
              type="text"
              placeholder={"$100,000"}
              value={setUpSyndicateData.investment_finacial_details.amount_of_fund}
              onChange={(e) => handleChangeInvestment("amount_of_fund", e.target.value)}
            />
            <DropDown
              label={"Distribution Frequency"}
              classDataName={"dropdown-height "}
              data={offeringTypeList}
              selectedData={setUpSyndicateData.investment_finacial_details.distribution_frequency}
              setSelectedData={(val) => handleChangeInvestment("distribution_frequency", val)}
              dropdownState={offeringTypeDA}
              dropdownSetter={setOfferingTypeDA}
            />
            <div className="slider-label">
              <span>investment increment</span>
            </div>
            <div className="slider_container">
              <div className="top_div inputFlexLine">
                <p>${pricefour.from}</p>
                <span>%</span>
              </div>
              <Slider
                defaultValue={setUpSyndicateData.investment_finacial_details.investment_increment}
                aria-label="Default"
                valueLabelDisplay="auto"
                step={2000}
                min={0}
                max={100000}
                className="Slider-range"
                onChange={(e) => handleChangeInvestment("investment_increment", e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="financial-container">
        <div className="first-inner-box">
          <img
            src={buildingone}
            alt="download"
          />
          <p>Syndicate Terms</p>
        </div>
        <div className="financial-body">
          <div className="financial-body-left">
            <DropDown
              label={"Most of the deals will be based in"}
              classDataName={"dropdown-height "}
              data={dealLocationList}
              selectedData={setUpSyndicateData.investment_syndicate_terms.most_of_the_deals}
              setSelectedData={(val) => handleChangeTerms("most_of_the_deals", val)}
              dropdownState={dealLocationDA}
              dropdownSetter={setDealLocationDA}
            />
            <DropDown
              label={"Financing Purpose"}
              classDataName={"dropdown-height "}
              data={financingPurposeList}
              selectedData={setUpSyndicateData.investment_syndicate_terms.financing_purpose}
              setSelectedData={(val) => handleChangeTerms("financing_purpose", val)}
              dropdownState={financingPurposeDA}
              dropdownSetter={setFinancingPurposeDA}
            />
            <DropDown
              label={"Asset type"}
              classDataName={"dropdown-height "}
              data={assetTypeList}
              selectedData={setUpSyndicateData.investment_syndicate_terms.asset_type}
              setSelectedData={(val) => handleChangeTerms("asset_type", val)}
              dropdownState={assetTypeDA}
              dropdownSetter={setAssetTypeDA}
            />
            <DropDown
              label={"Financing engagement"}
              classDataName={"dropdown-height "}
              data={engagementList}
              selectedData={setUpSyndicateData.investment_syndicate_terms.financing_engagement}
              setSelectedData={(val) => handleChangeTerms("financing_engagement", val)}
              dropdownState={engagementDA}
              dropdownSetter={setEngagementDA}
            />
          </div>
          <div className="financial-body-right">
            <InputBox
              conCLass={"slider-label"}
              label={"Amount of deals per year"}
              type="text"
              value={setUpSyndicateData.investment_syndicate_terms.deal_amount_per_year}
              placeholder={"700"}
              onChange={(e) => handleChangeTerms("deal_amount_per_year", e.target.value)}
            />
            <DropDown
              label={"Purchase Stage"}
              classDataName={"dropdown-height "}
              data={purchaseStageList}
              selectedData={setUpSyndicateData.investment_syndicate_terms.purchase_state}
              setSelectedData={(val) => handleChangeTerms("purchase_state", val)}
              dropdownState={purchaseStageDA}
              dropdownSetter={setPurchaseStageDA}
            />
            <DropDown
              label={"Planning to Renovate?"}
              classDataName={"dropdown-height "}
              data={renovationList}
              selectedData={setUpSyndicateData.investment_syndicate_terms.planning_to_renovate}
              setSelectedData={(val) => handleChangeTerms("planning_to_renovate", val)}
              dropdownState={renovationDA}
              dropdownSetter={setRenovationDA}
            />
            <DropDown
              label={"Status"}
              classDataName={"dropdown-height "}
              data={statusList}
              selectedData={setUpSyndicateData.investment_syndicate_terms.status}
              setSelectedData={(val) => handleChangeTerms("status", val)}
              dropdownState={statusDA}
              dropdownSetter={setStatusDA}
            />
          </div>
        </div>
      </div>
      <h1>Add Info manually</h1>
      <div className="financial-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>General Details</p>
        </div>
        <div className="general-syndicate-body">
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={text}
                alt=""
              />
            </div>
            <span>Text</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={image}
                alt=""
              />
            </div>
            <span>Image</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={video}
                alt=""
              />
            </div>
            <span>Video</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={grid}
                alt=""
              />
            </div>
            <span>Table</span>
          </div>
        </div>
      </div>

      <div className="financial-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Distribution Strategy</p>
        </div>
        <div className="general-syndicate-body">
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={text}
                alt=""
              />
            </div>
            <span>Text</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={image}
                alt=""
              />
            </div>
            <span>Image</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={video}
                alt=""
              />
            </div>
            <span>Video</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={grid}
                alt=""
              />
            </div>
            <span>Table</span>
          </div>
        </div>
      </div>

      <div className="financial-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Project vs. Investor Returns</p>
        </div>
        <div className="general-syndicate-body">
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={text}
                alt=""
              />
            </div>
            <span>Text</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={image}
                alt=""
              />
            </div>
            <span>Image</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={video}
                alt=""
              />
            </div>
            <span>Video</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={grid}
                alt=""
              />
            </div>
            <span>Table</span>
          </div>
        </div>
      </div>

      <div className="financial-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Organizational Chart</p>
        </div>
        <div className="general-syndicate-body">
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={text}
                alt=""
              />
            </div>
            <span>Text</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={image}
                alt=""
              />
            </div>
            <span>Image</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={video}
                alt=""
              />
            </div>
            <span>Video</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={grid}
                alt=""
              />
            </div>
            <span>Table</span>
          </div>
        </div>
      </div>

      <div className="financial-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Sources & Uses</p>
        </div>
        <div className="general-syndicate-body">
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={text}
                alt=""
              />
            </div>
            <span>Text</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={image}
                alt=""
              />
            </div>
            <span>Image</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={video}
                alt=""
              />
            </div>
            <span>Video</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={grid}
                alt=""
              />
            </div>
            <span>Table</span>
          </div>
        </div>
      </div>

      <div className="financial-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Capital Calls</p>
        </div>
        <div className="general-syndicate-body">
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={text}
                alt=""
              />
            </div>
            <span>Text</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={image}
                alt=""
              />
            </div>
            <span>Image</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={video}
                alt=""
              />
            </div>
            <span>Video</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={grid}
                alt=""
              />
            </div>
            <span>Table</span>
          </div>
        </div>
      </div>

      <div className="financial-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Fees</p>
        </div>
        <div className="general-syndicate-body">
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={text}
                alt=""
              />
            </div>
            <span>Text</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={image}
                alt=""
              />
            </div>
            <span>Image</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={video}
                alt=""
              />
            </div>
            <span>Video</span>
          </div>
          <div className="add-info-man">
            <div className="add-info-img">
              <img
                src={grid}
                alt=""
              />
            </div>
            <span>Table</span>
          </div>
        </div>
      </div>

      <h1>Start adding custom details</h1>
      <div className="custom-detail-container">
        <div className="add-info-man custom-div">
          <div className="add-info-img">
            <img
              src={text}
              alt=""
            />
          </div>
          <span>Text</span>
        </div>
        <div className="add-info-man custom-div">
          <div className="add-info-img">
            <img
              src={image}
              alt=""
            />
          </div>
          <span>Image</span>
        </div>
        <div className="add-info-man custom-div">
          <div className="add-info-img">
            <img
              src={video}
              alt=""
            />
          </div>
          <span>Video</span>
        </div>
        <div className="add-info-man custom-div">
          <div className="add-info-img">
            <img
              src={grid}
              alt=""
            />
          </div>
          <span>Table</span>
        </div>
        <div className="add-info-man custom-div">
          <div className="add-info-img">
            <img
              src={addsec}
              alt=""
            />
          </div>
          <span>Add Section</span>
        </div>
      </div>
    </div>
  );
};

export const SponsorSyndicate = () => {
  const [isClicked, setIsClicked] = useState(1);
  return (
    <div>
      <div className="financial-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Investor list</p>
        </div>
        <div className="financial-body sponsor-body">
          <div className="internal-inves">
            <div className="internal-left">
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked />} />
              </FormGroup>
              <div>
                <h1>Internal Investors</h1>
                <p>5 of 24 potential Investors found in Connections</p>
              </div>
            </div>
            <div className="internal-right">
              <div className="img-div">
                <img
                  src={eye}
                  alt=""
                />
                <span>View List</span>
              </div>
            </div>
          </div>
          <div className="publish-deal">
            <IOSSwitch />
            <span>Publish deal to Marketplace</span>
          </div>
          <div className="internal-inves">
            <div className="internal-left">
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked />} />
              </FormGroup>
              <div>
                <h1>Public Investors</h1>
                <p>420 potential Investors found in Network</p>
              </div>
            </div>
            <div className="internal-right">
              <div>
                <img
                  src={network}
                  alt=""
                />
                <span>85%</span>
              </div>
              <div className="img-div">
                {" "}
                <img
                  src={filter}
                  alt=""
                />
                <span>Filter</span>
              </div>
            </div>
          </div>
          <div className="investor-meet">
            <p>When do you want Public investors to see the offer?</p>
            <div className="meet-time-body">
              <div className="meet-time">
                <span
                  onClick={() => {
                    setIsClicked(1);
                  }}
                  className={isClicked === 1 && "back"}
                >
                  Pic a date
                </span>
                <div></div>
                <span
                  onClick={() => {
                    setIsClicked(2);
                  }}
                  className={isClicked === 2 && "back"}
                >
                  Set a timer
                </span>
                <div></div>
                <span
                  onClick={() => {
                    setIsClicked(3);
                  }}
                  className={isClicked === 3 && "back"}
                >
                  {" "}
                  Immediately{" "}
                </span>
              </div>
              <div className="publish-date">
                <InputBox
                  label={"Publishing date"}
                  type="date"
                />
                <InputBox
                  label={"Publishing Time"}
                  type="time"
                />
              </div>
            </div>
          </div>
          <div className="invitation">
            <h1>Invite own contacts</h1>
            <div className="invitation-detail">
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked />} />
              </FormGroup>
              <div>
                <InputBox
                  conCLass={"invitation-input"}
                  label={"Add Lenedrs’ emails"}
                  placeholder={"John.M@lendland.co..."}
                  type="text"
                />
                <span>Separate emails with comas or new lines</span>
              </div>
            </div>
            <div className="last-detail">
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked />} />
              </FormGroup>
              <span>Invite to my Network as well</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BussinessSyndicate = ({setNavActive, setUpSyndicateData, setSetUpSyndicateData}) => {
  const handleNewSyndicate = () => {
    if (setNavActive) {
      setNavActive(13);
    }
  };
  const [isClicked, setIsClicked] = useState(1);

  const handleChangeBussiness = (key, value) => {
    setSetUpSyndicateData((prevState) => ({
      ...prevState,
      business_plan_webinar: {
        ...prevState.business_plan_webinar,
        [key]: value,
      },
    }));
  };

  return (
    <div className="investment-main-container">
      <div className="business">
        <div className="business-first-head">
          <div className="business-first-hr">
            <img
              src={videoplayer}
              alt=""
            />
            <span>Webinar Registration</span>
          </div>
          <IOSSwitch />
        </div>
        <div className="business-first-body">
          <InputBox
            label={"Link"}
            type="text"
            placeholder={"https://"}
            value={setUpSyndicateData.business_plan_webinar.link}
            onChange={(e) => handleChangeBussiness("link", e.target.value)}
          />
          <InputBox
            label={"Description"}
            type="text"
            placeholder={"Tell me about the detail."}
            value={setUpSyndicateData.business_plan_webinar.description}
            onChange={(e) => handleChangeBussiness("description", e.target.value)}
          />
          <div
            className="video-btn"
            onClick={handleNewSyndicate}
          >
            <span>Place Video</span>
          </div>
        </div>
      </div>
      <div className="switch-btn switch-syndicate">
        <span
          onClick={() => {
            setIsClicked(1);
          }}
          className={isClicked === 1 && "back"}
        >
          Create document
        </span>
        <span
          onClick={() => {
            setIsClicked(2);
          }}
          className={isClicked === 2 && "back"}
        >
          Upload document
        </span>
      </div>
      <div className="financial-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Investor list</p>
        </div>
        <div className="financial-body">
          <div className="big-box">
            <div className="inner-big-box">
              <img
                src={upload}
                alt=""
              />
              <p className="first-para">
                <div class="upload-btn-wrapper">
                  <button class="btn">
                    <img
                    //   src={uploadIcon}
                      alt=""
                    />
                    Drag and drop files or click
                  </button>
                  <input
                    type="file"
                    name="myfile"
                    onChange={(e) => handleChangeBussiness("document", e.target.files[0])}
                  />
                </div>
              </p>
              <p className="second-para">Supported file formats: PDF. Max file size 250 MB</p>
            </div>
          </div>
        </div>
      </div>
      <h1 className="custom_info_heading">Custom Information</h1>
      <div className="financial-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Investor list</p>
        </div>
        <div className="financial-body">
          <div className="custom-detail-container">
            <div className="add-info-man custom-divtwo ">
              <div className="add-info-img">
                <img
                  src={text}
                  alt=""
                />
              </div>
              <span>Text</span>
            </div>
            <div className="add-info-man custom-divtwo">
              <div className="add-info-img">
                <img
                  src={image}
                  alt=""
                />
              </div>
              <span>Image</span>
            </div>
            <div className="add-info-man custom-divtwo">
              <div className="add-info-img">
                <img
                  src={video}
                  alt=""
                />
              </div>
              <span>Video</span>
            </div>
            <div className="add-info-man custom-divtwo">
              <div className="add-info-img">
                <img
                  src={grid}
                  alt=""
                />
              </div>
              <span>Table</span>
            </div>
            <div className="add-info-man custom-divtwo">
              <div className="add-info-img">
                <img
                  src={addsec}
                  alt=""
                />
              </div>
              <span>Add Section</span>
            </div>
          </div>
        </div>
      </div>
      <h1 className="additional_doc_heading">Additional Documents</h1>
      <div className="financial-container">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Document</p>
        </div>
        <div className="upload-doc-body">
          <div className="select-doc">
            <img
              src={upload}
              alt=""
            />
            <div class="upload-btn-wrapper">
              <button class="btn">
                <img
                //   src={uploadIcon}
                  alt=""
                />
                Drag and drop files or click
              </button>
              <input
                type="file"
                name="myfile"
                onChange={(e) => handleChangeBussiness("additional_document", e.target.files[0])}
              />
            </div>

            <p>Supported file formats: PDF. Max file size 250 MB</p>
          </div>
          <div className="progress-cont">
            <div className="uploading-file">
              <div className="file-name">
                <div>
                  <img
                    src={pdf}
                    alt=""
                  />
                  <span>Agreement B.pdf</span>
                </div>
                <span>1,5MB of 3,6MB</span>
              </div>
              <div className="progress-bar">
                <span className="progress"></span>
              </div>
            </div>
            <img
              src={navclose}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const KeyDealSyndicate = ({setUpSyndicateData, setSetUpSyndicateData}) => {
  const dispatch = useDispatch();

  const handleAddPoint = () => {
    const newId = setUpSyndicateData.key_deal_points.length + 1;

    setUpSyndicateData.key_deal_points.length === 15
      ? dispatch(returnErrors("You can add only 15 key points.."))
      : setSetUpSyndicateData((prevState) => ({
          ...prevState,
          key_deal_points: [...prevState.key_deal_points, {id: newId, heading: "", description: ""}],
        }));
  };

  const handleRemovePoint = (id) => {
    setSetUpSyndicateData(setUpSyndicateData.key_deal_points.filter((point) => point.id !== id));
  };

  const handleInputChange = (id, name, value) => {
    setSetUpSyndicateData((prevState) => ({
      ...prevState,
      key_deal_points: prevState.key_deal_points.map((point) => (point.id === id ? {...point, [name]: value} : point)),
    }));
  };

  return (
    <div>
      {setUpSyndicateData.key_deal_points.map((item, index) => {
        return (
          <div className="keydeal-main-container">
            <div className="keydeal-body-left">
              <span>{index + 1}</span>
            </div>
            <div className="keydeal-body-right">
              <InputBox
                conCLass={"keydeal-input"}
                label={"Heading"}
                type="text"
                placeholder={"Heading Name"}
                value={setUpSyndicateData.key_deal_points.heading}
                onChange={(e) => handleInputChange(item.id, "heading", e.target.value)}
              />
              <InputBox
                conCLass={"keydeal-input"}
                label={"Description"}
                type="text"
                placeholder={"What does this point mean? Tell more about the details."}
                onChange={(e) => handleInputChange(item.id, "description", e.target.value)}
                value={setUpSyndicateData.key_deal_points.description}
              />
              <div className="keydeal-btn">
                <div className="save-btn">
                  <img
                    src={savetick}
                    alt=""
                  />
                  <span>Save</span>
                </div>
                <div
                  className="delete-btn"
                  onClick={() => handleRemovePoint(item.id)}
                >
                  <img
                    src={removenew}
                    alt=""
                  />
                  <span>Remove Point</span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div
        className="keydeal-bottom-B"
        onClick={handleAddPoint}
      >
        <img
          src={plus}
          alt=""
        />
        <span>Add a new point</span>
      </div>
    </div>
  );
};

export const DealsRoomRight = () => {
  const yieldData = [
    {name: "Yield", value: "10%"},
    {name: "LTV", value: "75%"},
    {name: "Hold Period", value: "18 mo"},
    {name: "Amount", value: "$95,625"},
  ];

  const yieldDataSecond = [
    {name: "Minimum Investment", value: "$25,000"},
    {name: "redemption period", value: "12 Month"},
    {name: "Strategy", value: "Core-Plus"},
  ];

  const yieldDataThird = [
    {name: "Area, SF", value: "15,966", valueSecond: "Duplex"},
    {
      name: "Appraisal Value",
      value: "$700,000",
      valueSecond: "from 12/26/19",
    },
    {name: "Strategy", value: "Core-Plus", valueSecond: ""},
  ];

  return (
    <div className="deals-room-right-container">
      <img
        src={rightPic}
        alt="pic"
      />
      <div className="deals-left-cornor">
        <img
          src={cornor}
          alt="cornor"
        />
        <div className="cornor-text">MultiFamily</div>
      </div>
      <div className="deals-room-small-title">
        <div className="inner-deals-room-small-title">
          <div className="deals-room-round">
            <img
              src={building}
              alt="building"
            />
          </div>
          <div className="detailss">NorthGroup Invest</div>
          <img
            src={star}
            alt="building"
          />
        </div>
        <div className="debt">
          <span>Debt</span>
        </div>
      </div>
      <div className="waltham">
        <p>Waltham Woods Apartments</p>
      </div>
      <div className="deals-room-icon-location">
        <img
          src={location}
          alt="location"
        />
        <p>164 S. Carson Court Newport News, VA 23601</p>
      </div>
      <div className="deals-room-offer">
        <p className="offer-header">Offer Available for:</p>
        <div className="deals-room-offer-inner">
          <div className="deals-room-offer-inner-div">
            <p className="numbers">02</p>
            <p className="words">Days</p>
          </div>
          <div className="deals-room-offer-inner-div">
            <p className="numbers">04</p>
            <p className="words">Hours</p>
          </div>
          <div className="deals-room-offer-inner-div">
            <p className="numbers">57</p>
            <p className="words">Minutes</p>
          </div>
          <div className="deals-room-offer-inner-div">
            <p className="numbers">04</p>
            <p className="words">Seconds</p>
          </div>
        </div>
      </div>

      <div className="yield-flex">
        {yieldData.map(({name, value}) => {
          return (
            <Yield
              name={name}
              value={value}
            />
          );
        })}
      </div>
      <div className="yield-flexx">
        {yieldDataSecond.map(({name, value}) => {
          return (
            <Yieldd
              name={name}
              value={value}
            />
          );
        })}
      </div>
      <div className="yield-flexxx">
        {yieldDataThird.map(({name, value, valueSecond}) => {
          return (
            <Yielddd
              name={name}
              value={value}
              valueSecond={valueSecond}
            />
          );
        })}
      </div>
    </div>
  );
};
