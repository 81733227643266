import React, {useState} from "react";
import "./BlockChain.scss";
import activeIcon from "../../assets/svg/activeIcon.svg";
import pendingIcon from "../../assets/svg/pendingicon.svg";
import BlockChainSideBar from "./BlockChainSideBar";
import matchIcon from "../../assets/svg/matchIcon.svg";
import BorrowerProfile from "./dist/BorrowerProfile";
import Documents from "./dist/Documents";
import Token from "./Token";
import Communication from "./Communication";
import PaymentHistory from "./PaymentHistory";
import users_invite from "../../assets/svg/users_invite.svg";
import BorrowerActivity from "./BorrowerActivity";
import Recordsss from "./Recordsss";
import addDisputeIcon from "../../assets/svg/Add Dispute_icon.svg";

const BlockChain = () => {
  const [selectedUser, setSelectedUser] = useState(); // STATE FOR SELECTED USER
  const tabination = ["Investor Profile", "Documents", "Records", "Token", "Investor Activity", "Communication", "Payment history"];   // USER DETAILS TABINATION ARRAY
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div className="blockchain_section">
      <div className="pain_page_header">
        <h1>Block Chain</h1>
        <div className="users_invite">
          <img
            src={users_invite}
            alt=""
          />
        </div>
      </div>
      <div className="blockChain_container">
        <div className="sideBar">
          <BlockChainSideBar
            setSelectedUser={setSelectedUser}  // GETTING SELECTED USER FROM SIDEBAR
            setActiveTab={setActiveTab}  
          />
        </div>
        <div className="blockChainContent">
          <div className="userProfile">                
            <ProfileCard selectedUser={selectedUser} />    
          </div>
          <div className="tabination_header">
            {tabination?.map((tabName, index) => {
              return (
                <p
                  onClick={() => {
                    setActiveTab(index);
                  }}
                  className={activeTab === index ? "tab_name activeTab" : "tab_name"}
                >
                  {tabName}
                </p>
              );
            })}
          </div>
          <div className="user_overview">
    {/* RENDERING USER DETAILS COMPONENT AS PER SELECTED TAB */}
            {activeTab === 0 && <BorrowerProfile />}
            {activeTab === 1 && <Documents />}
            {activeTab === 2 && <Recordsss />}
            {activeTab === 3 && <Token />}
            {activeTab === 4 && <BorrowerActivity />}
            {activeTab === 5 && <Communication />}
            {activeTab === 6 && <PaymentHistory />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockChain;

// SEPERATE COMPONENT FOR USER PROFILE 
const ProfileCard = ({selectedUser}) => {
  return (
    <div className="card_container">
      <div
        className="card"
        style={{position: "relative"}}
      >
        <div style={{display: "flex", gap: "20px"}}>
          <div className="prifileImg">
            <img
              src={selectedUser?.profileImg}
              alt=""
            />
          </div>
          <div className="user_details">
            <div className="name_and_type">
              <h1>
                {selectedUser?.name}{" "}
                <img
                  src={selectedUser?.status === "Active" ? activeIcon : pendingIcon}
                  alt=""
                />
              </h1>
              <p>{selectedUser?.userType}</p>
            </div>
            <div className="deal_match">
              <div className="deal">
                <p>{selectedUser?.deals}</p>
                <span>Deals</span>
              </div>
              <div className="match">
                <img
                  src={matchIcon}
                  alt=""
                />
                <p>{selectedUser?.match} Match</p>
              </div>
            </div>
          </div>
        </div>
        <div className="userInfoMore">
          <div className="borrowed">
            <h1>{selectedUser?.borrowed}</h1>
            <p>BORROWED</p>
          </div>
          <div className="paid">
            <h1>
              {selectedUser?.paid} <span>{selectedUser?.paidPercentage}</span>{" "}
            </h1>
            <p>PAID</p>
          </div>
          <div className="earned">
            <h1>
              {selectedUser?.earned} <span>{selectedUser?.earnedPercentage}</span>
            </h1>
            <p>EARNED</p>
          </div>
          <div className="loan_term">
            <h1>
              {selectedUser?.loanTerm} <span>Months</span>
            </h1>
            <p>LOAN TERM</p>
          </div>
        </div>
        <button
          className="addDisputeBtn"
          style={{position: "absolute", top: "10px", right: "10px"}}
        >
          {" "}
          <img
            src={addDisputeIcon}
            alt=""
          />{" "}
          Add Dispute
        </button>
      </div>
    </div>
  );
};
