import React, {useRef, useEffect} from "react";
import {Carousel as NativeCarousel} from "@fancyapps/ui";
import {Thumbs} from "@fancyapps/ui/dist/carousel/carousel.thumbs.esm.js";
import "@fancyapps/ui/dist/carousel/carousel.css";
import "@fancyapps/ui/dist/carousel/carousel.thumbs.css";
import "./FancyCarousal.scss";

export function FancyCarousal(props) {
  const containerRef = useRef(null);

  const defaults = {
    Dots: false,
    Thumbs: {
      type: "classic",
    },
  };

  useEffect(() => {
    const container = containerRef.current;
    const options = {
      ...defaults,
      ...(props.options || {}),
    };

    const instance = new NativeCarousel(container, options, {Thumbs});

    return () => {
      instance.destroy();
    };
  });

  return (
    <div
      className="f-carousel"
      ref={containerRef}
    >
      {props.children}
    </div>
  );
}
