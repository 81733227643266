import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

export const postQuestioneriesData = createAsyncThunk("data/postData", async ({data, idToken}, thunkAPI) => {

  try {
    const response = await axios.post("users/createQuestioneries", data, {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// Define your slice
const QustioneriesSlice = createSlice({
  name: "questioneries",
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  reducers: {},
  extraReducers: {
    [postQuestioneriesData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [postQuestioneriesData.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [postQuestioneriesData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default QustioneriesSlice.reducer;
