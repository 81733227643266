import {FancyCarousal} from "../FancyCarousal/FancyCarousal";
import {Fancybox} from "../Fancybox/Fancybox";
import "./ThumbsSlider.scss";
import {useState} from "react";
import {useSelector} from "react-redux";

export function ThumbsSlider({className, customImages, selectedData}) {
  // Accept customImages prop

  const defaultImages = ["https://wowslider.com/sliders/demo-18/data1/images/hongkong1081704.jpg", "https://wowslider.com/sliders/demo-18/data1/images/hongkong1081704.jpg"]; // Use customImages or default to empty array
  // const selectedData = useSelector((state) => state.portfolio.selectedProperty);
  const images = selectedData?.assetsImages || customImages || defaultImages;

  const videos = selectedData?.advanceAssets?.asset_info?.preview_media?.videos;

  const [activeMedia, setActiveMedia] = useState({
    isImage: true,
    isVideo: false,
    isVR: false,
  });

  const handleSetActiveMedia = (mediaType) => {
    setActiveMedia({
      isImage: mediaType === "images",
      isVideo: mediaType === "videos",
      isVR: mediaType === "vr",
    });
  };

  return (
    <div className={"property-slider-wrap" + " " + className}>
      <div className="thumb-nav">
        <button
          className={activeMedia.isImage ? "active" : ""}
          onClick={() => handleSetActiveMedia("images")}
        >
          {images.length} Photos
        </button>
        <button
          className={activeMedia.isVideo ? "active" : ""}
          onClick={() => handleSetActiveMedia("videos")}
        >
          {videos ? videos.length : 0} Videos
        </button>
        <button
          className={activeMedia.isVR ? "active" : ""}
          onClick={() => handleSetActiveMedia("vr")}
        >
          1 VR View
        </button>
      </div>
      <Fancybox
        options={{
          Carousel: {
            infinite: false,
          },
          Thumbs: {
            type: "classic",
          },
          onThumbsShow: (instance) => {
            // 
          },
        }}
      >
        <div className="property-slider">
          <FancyCarousal
            options={{
              slidesPerPage: 7.5,
              infinite: false,
              Navigation: {
                prevTpl: (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z"
                      fill="currentColor"
                    />
                  </svg>
                ),
                nextTpl: (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.0001 6L8.59009 7.41L13.1701 12L8.59009 16.59L10.0001 18L16.0001 12L10.0001 6Z"
                      fill="currentColor"
                    />
                  </svg>
                ),
              },
            }}
          >
            {images.map((media, index) => (
              <div
                key={index}
                className="f-carousel__slide"
                data-fancybox="gallery"
                data-src={media}
                data-thumb-src={media}
              >
                {activeMedia.isImage && (
                  <img
                    alt=""
                    src={media}
                    width="658"
                    height="430"
                    className="thumb-content"
                  />
                )}
                {activeMedia.isVideo && (
                  <video
                    width="658"
                    height="430"
                    controls
                  >
                    <source
                      src="https://youtu.be/FjvdTAZm7io?si=oLEbUb4GB9MuazZY"
                      type="video/mp4"
                    />
                  </video>
                )}
                <button className="slide-zoom">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {/* Your SVG code */}
                  </svg>
                </button>
                <h4 className="thumb-caption">View from Parkville Street</h4>
              </div>
            ))}
          </FancyCarousal>
        </div>
      </Fancybox>
    </div>
  );
}
