import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import arrowDropdownIcon from "../../../assets/checklistIcons/dropdownArrow.svg";
import addTaskIcon from "../../../assets/checklistIcons/addTaskIcon.svg";
import bulkImportIcon from "../../../assets/checklistIcons/bulkImportIcon.svg";
import messsageIcon from "../../../assets/checklistIcons/messageIcon.svg";
import settingIcon from "../../../assets/checklistIcons/settingsIcon.svg";
import pinIcon from "../../../assets/checklistIcons/pinIcon.svg";
import editIcon from "../../../assets/checklistIcons/editIcon.svg";
import addIcon from "../../../assets/checklistIcons/addAssignIcon.svg";
import {HolderOutlined} from "@ant-design/icons";
import {Button, Checkbox, Collapse, theme} from "antd";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Grid, FormControl, InputLabel} from "@mui/material";
import DragTable from "../DragTable/DragTable";
import "./ToDoListComponent.scss";
import {useSelector} from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const RowContext = React.createContext({});

const DragHandle = () => {
  const {setActivatorNodeRef, listeners} = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{
        cursor: "move",
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const onChange = (e) => {
  console.log(`checked = ${e.target.checked}`);
};

const columns = [
  {
    key: "sort",
    align: "center",
    width: 80,
    render: () => <DragHandle />,
  },
  {
    title: "Task Name",
    dataIndex: "taskName",
    render: (text, record) => (
      <div className="task_container">
        <Checkbox onChange={onChange}></Checkbox>
        <p>{text}</p>
        <div className="task_info">
          {record.totalComments > 0 && (
            <div className="message_icon">
              <img
                src={messsageIcon}
                alt="messageIcon"
              />
              <p>{record.totalComments}</p>
            </div>
          )}
          {record.isPinned && (
            <div className="pin_icon">
              <img
                src={pinIcon}
                alt="pinIcon"
              />
              <p>1</p>
            </div>
          )}
        </div>
      </div>
    ),
  },
  {
    title: "Task Details",
    dataIndex: "taskDetails",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Comments",
    dataIndex: "comments",
    render: (comments) => (
      comments?.map((comment, index) => (
        <div key={index}>
          <p>{comment.text}</p>
        </div>
      ))
    ),
  },
  {
    title: "Tags",
    dataIndex: "tags",
    render: (tags) => tags?.join(', '),
  },
  {
    title: "Updated Date",
    dataIndex: "updatedDate",
    render: (text) => <div className="updated_container">{new Date(text).toLocaleDateString()}</div>,
  },
  {
    title: "Due Date",
    dataIndex: "dueDate",
    render: (text) => <div className="dueDate_container">{new Date(text).toLocaleDateString()}</div>,
  },
  {
    title: "Assigned To",
    dataIndex: "assignedTo",
    render: (value) => (
      <div className="assignedTo_container">
        {value?.length > 0 ? (
          value?.map((email, index) => (
            <div
              key={index}
              className="person_icon"
            >
              <p>{email}</p>
            </div>
          ))
        ) : (
          <p>No one assigned</p>
        )}
        <div
          className="assign_container"
          style={{marginLeft: "5px"}}
        >
          <button>
            <img
              src={addIcon}
              alt="addIcon"
            />
          </button>
        </div>
      </div>
    ),
  },
  {
    title: "",
    dataIndex: "edit",
    render: () => (
      <div className="edit_container">
        <button>
          <img
            src={editIcon}
            alt="editIcon"
          />
        </button>
      </div>
    ),
  },
];


const getItems = (data, panelStyle, checklist, handleOpen, fetchData) => {
  return checklist?.map((item) => ({
    key: item.id,
    label: (
      <div
        className="toDo_table_header"
        onClick={() => fetchData(item.id)}
      >
        <div className="header_box">
          <h2>{item?.name}</h2>
          <p>({item?.tasks?.length})</p>
        </div>
        <div className="right_grp">
          {item?.tasks?.length > 0 ? (
            <div className="btn_box">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpen(item?.id);
                }}
              >
                <img
                  src={addTaskIcon}
                  alt="addIcon"
                />
                Add task
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <img
                  src={bulkImportIcon}
                  alt="importIcon"
                />
                Bulk Import
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="setting_btn"
              >
                <img
                  src={settingIcon}
                  alt="settingIcon"
                />
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    ),
    children: (
      <>
        {item?.tasks?.length > 0 ? (
          <DragTable
            columns={columns}
            initialData={item?.tasks}
          />
        ) : (
          <div className="btn_box">
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleOpen(item?.id);
              }}
            >
              <img
                src={addTaskIcon}
                alt="addIcon"
              />
              Add task
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <img
                src={bulkImportIcon}
                alt="importIcon"
              />
              Bulk Import
            </button>
          </div>
        )}
      </>
    ),
    style: panelStyle,
  }));
};

const ToDoListComponent = ({assetId, checklistId, checklist}) => {
  const {idToken} = useSelector((state) => state.auth);
  const {token} = theme.useToken();
  const [taskData, setTaskData] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [newTask, setNewTask] = useState({
    taskName: "",
    taskDetails: "",
    comments: [{text: ""}],
    tags: "",
    dueDate: "",
    assignedTo: "",
    updated: new Date().toISOString().split("T")[0],
  });

  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  const fetchData = async (id) => {
    try {
      const config = {
        headers: {
          "auth-token": idToken,
        },
      };
      const response = await axios.get(`/api/assets/${assetId}/checklist/${id}/gettask`, config);
      setTaskData(response.data);
    } catch (error) {
     
    }
  };


  const handleOpen = (e) => {
    setOpen(e);
  };

  const handleClose = () => {
    setOpen(false);
    setNewTask({
      taskName: "",
      taskDetails: "",
      comments: [{text: ""}],
      tags: "",
      dueDate: "",
      assignedTo: "",
      updated: new Date().toISOString().split("T")[0],
    });
  };

  const handleAddTask = async (checklistId) => {
    setLoading(true);
    try {
      const payload = {
        tasks: [
          {
            taskName: newTask.taskName,
            taskDetails: newTask.taskDetails,
            comments: newTask.comments.map((comment) => ({text: comment.text})),
            tags: newTask.tags.split(",").map((tag) => tag.trim()),
            dueDate: newTask.dueDate,
            assignedTo: [newTask.assignedTo],
          },
        ],
      };

      const config = {
        headers: {
          "auth-token": idToken,
        },
      };

      const response = await axios.patch(`/api/assets/${assetId}/checklists/${checklistId}/addTask`, payload, config);
      if (response.status === 201 || response.status === 200) {
        await fetchData(checklistId);
        handleClose();
      }
    } catch (error) {
 
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setNewTask((prevTask) => ({
      ...prevTask,
      [name]: value,
    }));
  };

  const handleCommentChange = (e, index) => {
    const {value} = e.target;
    const updatedComments = [...newTask.comments];
    updatedComments[index].text = value;
    setNewTask((prevTask) => ({
      ...prevTask,
      comments: updatedComments,
    }));
  };

  const assets = useSelector((state) => state.AdminGivenAccess.assets) || [];
  const emails = Array.from(new Set(assets.map((asset) => asset?.userId?.email)));

  return (
    <>
      <div className="collapseHeader">
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({isActive}) => (
            <img
              src={arrowDropdownIcon}
              style={{transform: `rotate(${isActive ? "0deg" : "-90deg"})`}}
              alt="dropdownIcon"
            />
          )}
          style={{
            background: token.colorBgContainer,
          }}
          items={getItems(taskData, panelStyle, checklist, handleOpen, fetchData)}
        />
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Add Task</DialogTitle>
        <DialogContent>
          <DialogContentText>To add a new task, please enter the task details here.</DialogContentText>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                autoFocus
                margin="dense"
                id="taskName"
                name="taskName"
                label="Task Name"
                type="text"
                fullWidth
                variant="standard"
                value={newTask.taskName}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                margin="dense"
                id="taskDetails"
                name="taskDetails"
                label="Task Details"
                type="text"
                fullWidth
                variant="standard"
                value={newTask.taskDetails}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                margin="dense"
                id="dueDate"
                name="dueDate"
                label="Due Date"
                type="date"
                fullWidth
                variant="standard"
                value={newTask.dueDate}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <FormControl
                fullWidth
                margin="dense"
              >
                <InputLabel id="assignedTo-label">Assign To</InputLabel>
                <Select
                  labelId="assignedTo-label"
                  id="assignedTo"
                  name="assignedTo"
                  value={newTask.assignedTo}
                  onChange={handleChange}
                  label="Assign To"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                >
                  {emails.map((email) => (
                    <MenuItem
                      key={email}
                      value={email}
                    >
                      {email}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                margin="dense"
                id="tags"
                name="tags"
                label="Tags (comma separated)"
                type="text"
                fullWidth
                variant="standard"
                value={newTask.tags}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                margin="dense"
                id="comment"
                name="comment"
                label="Comment"
                type="text"
                fullWidth
                variant="standard"
                value={newTask.comments[0].text}
                onChange={(e) => handleCommentChange(e, 0)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => handleAddTask(open)}
            disabled={loading}
          >
            {loading ? "Adding..." : "Add Task"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ToDoListComponent;
