import React, { useState } from 'react'
import './integration.scss'
import Checkbox from './Checkbox';
import service from '../../../assets/svg/integration-service.svg'
import { InputBox } from '../../../components/reusableComponents/FormFields/FormFields';

function Integration() {

    const [navActive, setNavActive] = useState(1);

    return (
        <div className='integration'>
            <div className='integration_upper'>
                <div className='basic_header'>
                    <div>
                        <p>Integration</p>
                        <span>Work smoothly with the external services you are used to.</span>
                    </div>

                </div>
                <div className='header_lower'>
                    <p>This is demo page. Help us understand which services will be useful for you to have here.</p>
                </div>
            </div>

            <div className='integration_middle'>
                <div className='integration_middle_header'>
                    <div className='integration_nav'>
                        <div className=" deals-room-nav asset-nav ass-nav">
                            <span
                                className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
                                onClick={() => {
                                    setNavActive(1);
                                }}
                            >
                                All <p>28</p>        </span>
                            <span
                                className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
                                onClick={() => {
                                    setNavActive(2);
                                }}
                            >
                                Calendars & Tasks
                            </span>
                            <span
                                className={navActive === 3 && "deals-room-nav-active ut-nav-active"}
                                onClick={() => {
                                    setNavActive(3);
                                }}
                            >
                                Storage
                            </span>
                            <span
                                className={navActive === 4 && "deals-room-nav-active ut-nav-active"}
                                onClick={() => {
                                    setNavActive(4);
                                }}
                            >
                                Communication
                            </span>


                        </div> </div>

                    <div className="search_bar">
                        <input
                            type="text"
                            placeholder="Search"
                        />
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M20 18.586L18.586 20L12.929 14.343L14.343 12.929L20 18.586ZM7 12C4.243 12 2 9.757 2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12ZM7 0C3.134 0 0 3.134 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0Z"
                                fill="white"
                                fill-opacity="0.01"
                            />
                            <mask
                                id="mask0_9862_58034"
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="20"
                                height="20"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M20 18.586L18.586 20L12.929 14.343L14.343 12.929L20 18.586ZM7 12C4.243 12 2 9.757 2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12ZM7 0C3.134 0 0 3.134 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0Z"
                                    fill="white"
                                />
                            </mask>
                            <g mask="url(#mask0_9862_58034)">
                                <rect
                                    opacity="0.5"
                                    x="-1"
                                    y="-1"
                                    width="22"
                                    height="22"
                                    fill="#9EA0A5"
                                />
                            </g>
                        </svg>
                    </div>
                </div>


                <div className='integration_middle_lower'>
                    <div>
                        <Checkbox img={service} variant={2} label1={'Service'} label2={'Category'} />
                        <Checkbox img={service} variant={2} label1={'Service'} label2={'Category'} />

                    </div>

                    <div>
                        <Checkbox img={service} variant={2} label1={'Service'} label2={'Category'} />
                        <Checkbox img={service} variant={2} label1={'Service'} label2={'Category'} />

                    </div>

                    <div>
                        <Checkbox img={service} variant={2} label1={'Service'} label2={'Category'} />
                        <Checkbox img={service} variant={2} label1={'Service'} label2={'Category'} />

                    </div>

                    <div>
                        <Checkbox img={service} variant={2} label1={'Service'} label2={'Category'} />
                        <Checkbox img={service} variant={2} label1={'Service'} label2={'Category'} />

                    </div>

                    <div>
                        <Checkbox img={service} variant={2} label1={'Service'} label2={'Category'} />
                        <Checkbox img={service} variant={2} label1={'Service'} label2={'Category'} />

                    </div>
                </div>

            </div>


            <div className='integration_lower'>
                <div className='basic_header'>
                    <div>
                        <p>Your suggestion</p>
                        <span>Work smoothly with the external services you are used to.</span>
                    </div>
                </div>

                
                <div className='inputs'>
                    <InputBox label={'Name or link to the service'} placeholder={'John@aaa.com'} />
                    <span>comment</span><br></br>
                    <textarea></textarea>

                    <button className='submit_btn'>Submit</button>

                    </div>

            </div>


        </div>
    )
}

export default Integration