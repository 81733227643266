import React, {useState} from "react";
import ReactECharts from "echarts-for-react";
import AnalyticsDetails from "./AnalyticsDetails";

const LeftMenuChart = ({assetsArray}) => {


  const option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      show: false,
      top: "5%",
      left: "center",
    },
    series: [
      {
        name: "Analytics",
        type: "pie",
        radius: ["80%", "60%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 40,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          {value: 1048, name: "Active"},
          {value: 735, name: "Pending"},
          {value: 580, name: "Closed"},
          {value: 484, name: "Others"},
        ],
      },
    ],
  };

  return (
    <div className="LeftMenuchart_container">
      <h4 className="leftMenuHeader">Analytics</h4>
      <div className="topSection">
        <ReactECharts
          option={option}
          style={{height: "95px", width: "95px"}}
        />
        <div className="chart_details">
          <p className="deal_count">{assetsArray?.length}</p>
          <p className="dealCount_caption">
            Deal count <br />
            total
          </p>
        </div>
      </div>
    </div>
  );
};

export default LeftMenuChart;
