import React from 'react'
import './businessPlan.scss'
import AssestCard from './AssetCard'
import photo from "../../assets/acqusition/svg/photo.svg";
import location from "../../assets/acqusition/svg/location.svg";
import street from "../../assets/acqusition/svg/street.svg";
import bookmark from "../../assets/acqusition/svg/bookmark.svg";
import share from "../../assets/acqusition/svg/share.svg";
import compare from "../../assets/acqusition/svg/compare.svg";
import VideoIcon from '../../assets/Image/assetDiscovery/video.svg'
import Edit from '../../assets/Image/assetDiscovery/edit.svg'
import Building from '../../assets/Image/assetDiscovery/building.svg'
import Thumbnail from '../../assets/Image/assetDiscovery/thumbnail.png'

import { useState } from 'react';
import CrowdfundingHeader from './CrowdFundingHeader';

function BusinessPlan() {

    const [navActive, setNavActive] = useState(1);

    return (
        <div className='businessplan'>
           <CrowdfundingHeader title={'Business Plan'} />

            <div className='businessplan_container'>
                <div className='businessplan_center'>
                    <div className='upper'>
                        <div className='upper_header'>
                            <p> <img src={VideoIcon} alt='' />
                                Webinar Registration</p>
                            <img src={Edit} alt='' />
                        </div>
                        <div className='upper_video'>
                            <video src='' alt='' poster={Thumbnail} />
                        </div>
                        <div className='upper_info'>
                            <div className='info_top'>
                                <div className='info_top_left'>
                                    <img src={Building} alt='' />
                                    <div>
                                    <h2>NorthGroup Invest Webinar</h2>
                                    <p><span>by</span>Mark Barker  •  Recorded October 7, 2020</p> </div>
                                </div>
                                <div>
                                    <p style={{fontSize:'20px'}}>26:59</p>
                                </div>
                            </div>
                            <div>
                                <p>With approximately 1.8 million SF of competitive industrial projects currently under construction, the Callis Road industrial development sets itself apart as one of the only developments in Eastern Nashville catering to mid-size users.</p>
                            </div>
                        </div>
                    </div>
                    <div className='lower'>
                        <p>Aurora Offices, LLC and Aurora Offices Capital Partners, LLC (collectively referred to herein as “Aurora Offices”) is pleased to present New Era’s fifth fund and twentieth sponsored investment partnership. NEMI Fund III, LLC (the “Fund”) will make investments in existing or to-be developed medical office buildings (MOB’s), behavioral health facilities, in-patient physical rehabilitation hospitals (IRF’s), medical specialty facilities, ambulatory surgery centers and other healthcare related investments. The Fund may also make limited joint-venture investments into operating and management companies in conjunction with a particular real estate investment. The Fund will generally make its investments in the form of preferred equity into each Project. Additionally, the Fund may periodically make mezzanine loans to Projects, if the NEMI Fund III Manager, LLC (the Fund “Manager”) determines that such a loan structure is in the best interest of the Fund. The Fund’s real estate Investments will generally be subordinate to first-lien construction and mortgage debt in the 50-75% leverage range. The Fund may be the sole investor into a Project, a co-investor with tenants in a Project, or a co-investor with other investors in any given Project all on a pari-passu basis with other investors.</p>
                        <div className='businessplan_nav'>
                            <div className=" deals-room-nav asset-nav ass-nav">
                                <span
                                    className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
                                    onClick={() => {
                                        setNavActive(1);
                                    }}
                                >
                                    Property and Asset Management          </span>
                                <span
                                    className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
                                    onClick={() => {
                                        setNavActive(2);
                                    }}
                                >
                                    Targeted Investment Criteria
                                </span>
                                <span
                                    className={navActive === 3 && "deals-room-nav-active ut-nav-active"}
                                    onClick={() => {
                                        setNavActive(3);
                                    }}
                                >
                                    Seeded Assets
                                </span>
                            </div>
                        </div>

                        <h2>Additional Capital Calls:</h2>
                        <p>Each property will retain property management companies who will be responsible for tenant relationships, daily maintenance of each property, property operating budgets, tenant charges reconciliation, net income statements as well as construction management. The Sponsor will regularly communicate with each property manager as necessary. The Sponsor will review monthly financials for each Property. The Sponsor will provide asset management services to the Company.

                            Third-party leasing brokers who will lease vacant spaces at the Properties will be engaged as required. The Sponsor will review all new leases and lease renewal in connection with the Company’s Investments.

                            The Manager currently utilizes its internal finance department for bookkeeping and accounting for quarterly reporting, tax reporting and end of year tax returns for each Property and the Company. Tax preparation work will continue to be completed by the Company’s third-party CPA firm.</p>
                        <div className='businessplan_nav'>
                            <div className=" deals-room-nav asset-nav ass-nav">
                                <span
                                    className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
                                    onClick={() => {
                                        setNavActive(1);
                                    }}
                                >
                                    Track Record         </span>
                                <span
                                    className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
                                    onClick={() => {
                                        setNavActive(2);
                                    }}
                                >
                                    In the News
                                </span>
                                <span
                                    className={navActive === 3 && "deals-room-nav-active ut-nav-active"}
                                    onClick={() => {
                                        setNavActive(3);
                                    }}
                                >
                                    Case Studies                                </span>
                                <span
                                    className={navActive === 4 && "deals-room-nav-active ut-nav-active"}
                                    onClick={() => {
                                        setNavActive(4);
                                    }}
                                >
                                    Prior CrowdStreet Offering(s)</span>
                                <span
                                    className={navActive === 5 && "deals-room-nav-active ut-nav-active"}
                                    onClick={() => {
                                        setNavActive(5);
                                    }}
                                >
                                    Sources & Uses                              </span>
                            </div>
                        </div>
                        <div>
                            <span className='link'>New $25 million Medical Complex Set for Garland</span>
                            <p>
                                A North Texas-based developer with a history of constructing medical-related buildings.</p>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <span className='link'>New $25 million Medical Complex Set for Garland</span>
                            <p>
                                A North Texas-based developer with a history of constructing medical-related buildings is readying its latest project in the region, a $25 million medical center in Garland.</p>
                        </div>
                    </div>

                </div>
                <div className='businessplan_right'>
                    <AssestCard />
                </div>
            </div> </div>
    )
}

export default BusinessPlan



