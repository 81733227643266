import React from "react";
import "./Documents.scss";
import pdf_icon from "../../../assets/svg/pdf_icon.svg";
import edited_icon from "../../../assets/svg/edited_icon.svg";
import plus_icon from "../../../assets/svg/plus.svg";
import singed_icon from "../../../assets/svg/signed_icon.svg";
import three_dot from "../../../assets/svg/three_dot.svg";
import filter_icon from "../../../assets/svg/filter_icon.svg";
import not_signed from "../../../assets/svg/not_signed_icon.svg";
import repeat_icon from "../../../assets/svg/repeat_icon.svg";
import see_more from "../../../assets/svg/see_more_icon.svg";
import search_icon from "../../../assets/svg/search_icon.svg";
import filter_btn from "../../../assets/svg/filter_btn.svg";
import add_doc from '../../../assets/svg/add_doc_btn.svg';
import pagination_icon from '../../../assets/svg/paginationIcon.svg';
import leftArrow from '../../../assets/svg/paginationLeftIcon.svg';
import rightArrow from '../../../assets/svg/paginationRightIcon.svg';

const Documents = () => {
  const documents = [
    {
      file_name: "Agreement.pdf",
      date_1: "5th of Nov, 18",
      date_2: "Nov. 24, 2018",
      user_status: "signed",
      investor_status: "signed",
      more_icon: three_dot,
      repeat_icon: repeat_icon,
      col_heading: "Document",
    },
    {
      file_name: "Agreement.pdf",
      date_1: "5th of Nov, 18",
      date_2: "Nov. 24, 2018",
      user_status: "signed",
      investor_status: "edited",
      more_icon: three_dot,
      repeat_icon: repeat_icon,
      col_heading: "Document",
    },
    {
      file_name: "Agreement.pdf",
      date_1: "5th of Nov, 18",
      date_2: "Nov. 24, 2018",
      user_status: "edited",
      investor_status: "not_signed",
      more_icon: three_dot,
      repeat_icon: repeat_icon,
      col_heading: "Document",
    },
    {
      file_name: "Agreement.pdf",
      date_1: "5th of Nov, 18",
      date_2: "Nov. 24, 2018",
      user_status: "signed",
      investor_status: "edited",
      more_icon: three_dot,
      repeat_icon: repeat_icon,
      col_heading: "Document",
    },
    {
      file_name: "Agreement.pdf",
      date_1: "5th of Nov, 18",
      date_2: "Nov. 24, 2018",
      user_status: "not_signed",
      investor_status: "signed",
      more_icon: three_dot,
      repeat_icon: repeat_icon,
      col_heading: "Document",
    },
  ];
  return (
    <div className="documents_profile_container">
      <div className="header">
        <div className="left_header">
          <div className="all">
            <p>All</p>
            <span>4</span>
          </div>
          <div className="inbox">
            <div>1</div>
            <p>Inbox</p>
            <span>32</span>
            <img
              src={see_more}
              alt=""
            />
          </div>
          <div className="sent">
            <p>Sent</p>
            <span>4</span>
          </div>
          <div className="drafts">
            <p>Drafts</p>
            <span>36</span>
          </div>
        </div>
        <div className="right_header">
          <div className="search">
            <img
              src={search_icon}
              alt=""
            />
          </div>
          <div className="filter">
            <img
              src={filter_btn}
              alt=""
            />
          </div>
          <div className="add_docs">
            <img src={add_doc} alt="" />
          </div>
        </div>
      </div>
      <div className="sub_header">
        <div className="doc">
          <input type="checkbox" />
          <p>Documents</p>
        </div>
        <div className="add_update_date">
          <p>Date added/Updated</p>
        </div>
        <div className="sign">
          <p>My sign</p>
        </div>
        <div className="investor_sign">
          <p>Investors sign</p>
        </div>

      </div>
      <div className="documents_details">
        {documents?.map((doc, index) => {
          return <div className="document">
            <div className="pdf_file">
              <input type="checkbox" />
              <img src={pdf_icon} alt="" />
              <p>{doc.file_name}</p>
            </div>
            <div className="date">
              <span>{doc.date_1}</span>
              <p> <img src={repeat_icon} alt="" />{doc.date_2}</p>
            </div>
            <div className="my_sign">
           <img src={doc.user_status  === 'signed' ? singed_icon : doc.user_status === "edited" ? edited_icon : not_signed} alt="" />
            </div>
            <div className="investor_sign">
            <img src={doc.investor_status  === 'signed' ? singed_icon : doc.investor_status === "edited" ? edited_icon : not_signed} alt="" />
            </div>
            <div className="more">
              <img src={three_dot} alt="" />
            </div>
          </div>;
        })}
        <div className="pagination">
         <div className="paginate_buttons">
         <button className="left_button">
            <img src={leftArrow} alt="" />
          </button>
          <p className="pagination_value">
             2 of 10
          </p>
          <button className="right_button">
            <img src={rightArrow} alt="" />
          </button>
         </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
