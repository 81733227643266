import React, { useState } from 'react'
import './adminSettings.scss'
import asset from '../../../assets/svg/building.svg'
import assetActive from '../../../assets/svg/building-active.svg'
import team from '../../../assets/svg/profile-gray.svg'
import teamActive from '../../../assets/svg/profile-active.svg'
import Company from './Company'
import Team from './Team'

function AdminSettings() {

    const [isClicked, setIsClicked] = useState(1);

    const handleMenuClicked = (data) => {
        setIsClicked(data);
 
    };

    return (
        <>
            <AdminHeader isClicked={isClicked} handleMenuClicked={handleMenuClicked} />
            <div className='adminsettings'>
                {isClicked === 1 && <Company />}
                {isClicked === 2 && <Team />}

            </div>
        </>
    )
}

export default AdminSettings


const AdminHeader = ({ isClicked, handleMenuClicked }) => {
    return (
        <div className='savedHeader'>
            <h2>Administrator Settings</h2>
            <div style={{ display: 'flex', gap: '10px' }}>
                <div className={`btns ${isClicked === 1 ? 'active' : ''}`}
                   onClick={() => handleMenuClicked(1)}>

                    <img src={isClicked === 1 ? assetActive : asset} alt='' />
                    Company Settings
                </div>

                <div
                    className={`btns ${isClicked === 2 ? 'active' : ''}`}
                    onClick={() => handleMenuClicked(2)}
                >
                    <img src={isClicked === 2 ? teamActive : team} alt='' />
                    Team Members
                </div>
            </div>
        </div>
    )
}