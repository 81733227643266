import React, {useState} from "react";
import "./Team.scss";
import filter from "../../../assets/svg/filtter.svg";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";
import add from "../../../assets/svg/add.svg";
import person from "../../../assets/svg/profileAvatar.png";
import Checkbox from "../settings/Checkbox";
import cross from "../../../assets/svg/cross.svg";
import {InputBox} from "../../../components/reusableComponents/FormFields/FormFields";
import Person from "../../../assets/Image/profile-pic.png";
import ContactDetails from "./ContactDetails";
import {useTable} from "react-table";

function Team() {
  const [invite, setInvite] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const handleInvite = () => {
    setInvite(!invite);
  };

  const handlePersonClick = (person) => {
    setSelectedPerson(!selectedPerson);
  };

  const handleDelete = () => {
    setIsDelete(!isDelete);
  };
  return (
    <div className="teams">
      <div className="team_header">
        <h2>Team Members</h2>

        <div style={{display: "flex", alignItems: "center", gap: "12px"}}>
          <div className="search_bar">
            <input
              type="text"
              placeholder="Type To Search"
            />
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20 18.586L18.586 20L12.929 14.343L14.343 12.929L20 18.586ZM7 12C4.243 12 2 9.757 2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12ZM7 0C3.134 0 0 3.134 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0Z"
                fill="white"
                fill-opacity="0.01"
              />
              <mask
                id="mask0_9862_58034"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 18.586L18.586 20L12.929 14.343L14.343 12.929L20 18.586ZM7 12C4.243 12 2 9.757 2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12ZM7 0C3.134 0 0 3.134 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_9862_58034)">
                <rect
                  opacity="0.5"
                  x="-1"
                  y="-1"
                  width="22"
                  height="22"
                  fill="#9EA0A5"
                />
              </g>
            </svg>
          </div>

          <div className="filter_btn">
            <img
              src={filter}
              alt=""
            />
            Filter
          </div>
          <div
            className="dropdown"
            style={{marginTop: "-22px"}}
          >
            <DropDown
              label=""
              selectedData={"Actions"}
              width={"110px"}
            />
          </div>
          <button
            onClick={handleInvite}
            className="btn_add"
          >
            <img
              src={add}
              alt=""
            />
            Add a Colleague
          </button>
        </div>
      </div>

      <TeamTable handlePersonClick={handlePersonClick} />

      {invite && <InviteBox handleInvite={handleInvite} />}
      {selectedPerson && (
        <ContactDetails
          handlePersonClick={handlePersonClick}
          className={selectedPerson ? "visible" : ""}
        />
      )}
      {isDelete && <DeleteBox handleDelete={handleDelete} />}
    </div>
  );
}

export default Team;

const TeamTable = ({handlePersonClick}) => {
  return (
    <div className="lender_table">
      <div className="documents_box">
        <div className="documents_box-B table">
          <div className="uploaded_docs">
            <div className="header">
              <div className="left">
                <p style={{width: "250px"}}>Person</p>
                <p className="second">Department</p>
                <p>Assets</p>
                <p>Phone</p>
                <p>Status</p>
                <p>
                  Active<br></br> Deals
                </p>
                <p>
                  Closed <br></br> Deals
                </p>
                <p>
                  Active <br></br> Checklist
                </p>
                {/* <p><img src={invoice} alt='' /></p> */}
              </div>
            </div>
            <Row2 handlePersonClick={handlePersonClick} />
            <Row2 pending={true} />
            <Row2 />
            <Row2 handlePersonClick={handlePersonClick} />
            <Row2 handlePersonClick={handlePersonClick} />
            <Row2 handlePersonClick={handlePersonClick} />
          </div>
        </div>
      </div>
    </div>
  );
};
const Row2 = ({pending, handlePersonClick}) => {
  return (
    <div className="table_row table_row2">
      <div className="left">
        <div
          className="item first"
          onClick={handlePersonClick}
        >
          <div style={{marginTop: "10px"}}>
            <Checkbox />
          </div>
          <div className="user_img_name">
            <img
              className="person"
              src={person}
              alt=""
            />
            <div className="text_wrapper">
              <p>Daniel Padilla</p>
              <span>evan.mcc@yahoo.com</span>
            </div>
          </div>
        </div>
        <div className="item second">
          <div className="text_wrapper">
            <p>2 Assets</p>
          </div>
        </div>
        <div className="item">
          <div className="assets_box">3</div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p>127979698</p>
          </div>{" "}
        </div>
        <div className="item">
          <div className="text_wrapper">
            <button className="btn_status">
              <span
                style={{background: pending ? "#ED962D" : "green"}}
                className="active"
              ></span>
              {pending ? "Pending" : "Active"}
            </button>
          </div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p>12</p>
          </div>{" "}
        </div>

        <div className="item">
          <div className="text_wrapper">
            <p>12</p>
          </div>{" "}
        </div>

        <div className="item">
          <div className="text_wrapper">
            <p>12</p>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

const InviteBox = ({handleInvite}) => {
  return (
    <div className="invite_modal">
      <div className="invitebox">
        <div className="basic_header">
          <p>Invite a New Colleague</p>
          <img
            onClick={handleInvite}
            src={cross}
            alt=""
          />
        </div>

        <div className="inputs">
          <div>
            <InputBox
              label={"New Colleague"}
              type={"email"}
              placeholder={"john.doe@example.com"}
            />
            <div style={{display: "flex", gap: "12px"}}>
              <DropDown
                label={"Department"}
                selectedData={"Value"}
              />
              <DropDown
                label={"Snior Manager"}
                selectedData={"Value"}
              />
            </div>
          </div>
          <span>After person will be notified by email they have to confirm invitation.</span>
        </div>
        <div className="btns">
          <div>
            <button className="btn_cancel">Cancel</button>
          </div>
          <div style={{display: "flex", gap: "12px", alignItems: "center"}}>
            <Checkbox label={"Add Another"} />
            <button className="btn_right">Send Invitation</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const DeleteBox = () => {
  return (
    <div className="deletebox">
      <div className="basic_header">
        <p>Delete Caution!</p>
      </div>

      <div className="deletebox_middle">
        <p>This action cannot be undone. If you are not sure whether to delete or not, you can deactivate the account and then delete it later.</p>
      </div>

      <div className="btns">
        <div>
          <button className="btn_cancel">Cancel</button>
        </div>
        <button className="btn_delete">Delete</button>
      </div>
    </div>
  );
};
