import React from "react";
import Chart from "react-apexcharts";

const SyndicatesPerformanceChart = () => {
  const series = [
    {
      name: "Conversions",
      data: [200, 500, 750, 600, 1250, 1500, 1250, 600, 750, 500, 600, 200, 100, 500, 100, 150, 200],
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    colors: ["#6758F3"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    markers: {
      size: 5,
      colors: ["#6758F3"],
      strokeColors: "#fff",
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    // title: {
    //   text: "Syndicates Performance",
    //   align: "left",
    // },
    grid: {
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: ["Apr 8", "Apr 9", "Apr 10", "Apr 11", "Apr 12", "Apr 13", "Apr 14", "Apr 15", "Apr 16", "Apr 17", "Apr 18", "Apr 19", "Apr 20", "Apr 21", "Apr 22"],
    },
  };

  return (
    <div id="chart">
      <Chart
        options={options}
        series={series}
        type="line"
        height={350}
        width={"100%"}
      />
    </div>
  );
};

export default SyndicatesPerformanceChart;
