import React, {useState} from "react";
import "./style.scss";
import uparrow from "../../../../../../../../../assets/svg/upArrorwsmall.svg";
import rightArrow from "../../../../../../../../../assets/svg/rightArrowBlue.svg";
import cross from "../../../../../../../../../assets/svg/cross.svg";
import radioButton from "../../../../../../../../../assets/svg/radioButton.svg";
import edit from "../../../../../../../../../assets/svg/edit.svg";
import plus from "../../../../../../../../../assets/svg/plus_icn.svg";
import Checkbox from "../../../../../../../../profile/settings/Checkbox";

const Performance_Overview = () => {
  const dummyLatestActivity = [
    {
      month: "July",
      date: "28",
      newHeader: "New distribution for 2810 Whiptail Loop, LLC",
      amount: "12,423,412",
      subHeader: "was distributed to Hobart Family Capital, LP",
    },
    {
      month: "July",
      date: "28",
      newHeader: "New distribution for 2810 Whiptail Loop, LLC",
      amount: "12,423,412",
      subHeader: "was distributed to Hobart Family Capital, LP",
    },
    {
      month: "July",
      date: "28",
      newHeader: "New distribution for 2810 Whiptail Loop, LLC",
      amount: "12,423,412",
      subHeader: "was distributed to Hobart Family Capital, LP",
    },
    {
      month: "July",
      date: "28",
      newHeader: "New distribution for 2810 Whiptail Loop, LLC",
      amount: "12,423,412",
      subHeader: "was distributed to Hobart Family Capital, LP",
    },
  ];

  const [activeModal, setActiveModal] = useState(null);

  const toggleModal = (index) => {
    setActiveModal(index === activeModal ? null : index);
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  return (
    <>
      <div className="syndicatePerformence">
        <div className="syndicate_main_lower">
          <div className="syndicate_main_lower_left">
            <div className="investment_summary_main">
              <div className="investment_summary_header">
                <h3>Investment Summary Information</h3>
              </div>
              <div className="investment_summary_middle">
                <div className="investment_summary_middle_one">
                  <div className="investment_summary_middle_one_left">
                    <p>Commitment</p>
                    <p>Ownership</p>
                    <p>Contributions</p>
                  </div>
                  <div className="investment_summary_middle_one_right">
                    <h3>$1,600,000.00</h3>
                    <h3>100.0000000%</h3>
                    <h3>$1,300,000.00</h3>
                  </div>
                </div>
                <div className="investment_summary_middle_one">
                  <div className="investment_summary_middle_one_left">
                    <p>Outstanding capital calls</p>
                    <p>Unfunded commitment</p>
                  </div>
                  <div className="investment_summary_middle_one_right">
                    <h3>$200,000.00</h3>
                    <h3>$100,000.00</h3>
                  </div>
                </div>
                <div
                  className="investment_summary_middle_one"
                  style={{border: "none"}}
                >
                  <div className="investment_summary_middle_one_left">
                    <p>Distributions</p>
                    <p></p>
                    <p>Net asset value</p>
                    <p>Equity balance</p>
                    <p>IRR</p>
                    <p>Yield</p>
                  </div>
                  <div className="investment_summary_middle_one_right">
                    <div>
                      <h3>$788,720.00</h3>
                      <p style={{color: "#009639"}}>
                        $204,719.00 <span>Return of capital</span>
                      </p>
                    </div>

                    <h3>$1,600,000.00</h3>
                    <h3>$1,600,000.00</h3>
                    <h3>
                      11.00% <span>Projected IRR</span>
                    </h3>
                    <h3>4.06%</h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="investment_summary_main">
              <div className="investment_summary_header">
                <h3>Investment Summary Information</h3>
              </div>
              <div className="investment_summary_middle">
                <div className="investment_summary_middle_one">
                  <div className="investment_summary_middle_one_left">
                    <p>Commitment</p>
                    <p>Ownership</p>
                    <p>Contributions</p>
                  </div>
                  <div className="investment_summary_middle_one_right">
                    <h3>$1,600,000.00</h3>
                    <h3>100.0000000%</h3>
                    <h3>$1,300,000.00</h3>
                  </div>
                </div>
                <div className="investment_summary_middle_one">
                  <div className="investment_summary_middle_one_left">
                    <p>Outstanding capital calls</p>
                    <p>Unfunded commitment</p>
                  </div>
                  <div className="investment_summary_middle_one_right">
                    <h3>$200,000.00</h3>
                    <h3>$100,000.00</h3>
                  </div>
                </div>
                <div
                  className="investment_summary_middle_one"
                  style={{border: "none"}}
                >
                  <div className="investment_summary_middle_one_left">
                    <p>Distributions</p>
                    <p></p>
                    <p>Net asset value</p>
                    <p>Equity balance</p>
                    <p>IRR</p>
                    <p>Yield</p>
                  </div>
                  <div className="investment_summary_middle_one_right">
                    <div>
                      <h3>$788,720.00</h3>
                      <p style={{color: "#009639"}}>
                        $204,719.00 <span>Return of capital</span>
                      </p>
                    </div>

                    <h3>$1,600,000.00</h3>
                    <h3>$1,600,000.00</h3>
                    <h3>
                      11.00% <span>Projected IRR</span>
                    </h3>
                    <h3>4.06%</h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="acquisition_details">
              <div className="acquisition_details_header">
                <h3>Investment Summary Information</h3>
              </div>
              <div
                className="acquisition_details_inner"
                style={{border: "none"}}
              >
                <div className="investment_summary_middle_one">
                  <div className="investment_summary_middle_one_left">
                    <p>Commitment</p>
                    <p>Ownership</p>
                    <p>Contributions</p>
                  </div>
                  <div className="investment_summary_middle_one_right">
                    <h3>$1,600,000.00</h3>
                    <h3>100.0000000%</h3>
                    <h3>$1,300,000.00</h3>
                  </div>
                </div>
                <div className="investment_summary_middle_one">
                  <div className="investment_summary_middle_one_left">
                    <p>Commitment</p>
                    <p>Ownership</p>
                    <p>Contributions</p>
                  </div>
                  <div className="investment_summary_middle_one_right">
                    <h3>$1,600,000.00</h3>
                    <h3>100.0000000%</h3>
                    <h3>$1,300,000.00</h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="latest_activity">
              <div className="latest_activity_header">
                <h3>Investment Summary Information</h3>
              </div>

              {dummyLatestActivity.map((activity, index) => (
                <div
                  key={index}
                  className="latest_activity_one"
                >
                  <div className="latest_activity_inner">
                    <div className="latest_activity_inner_one">
                      <p>{activity.month}</p>
                      <p>{activity.date}</p>
                    </div>

                    <div className="latest_activity_inner_two">
                      <h3>{activity.newHeader}</h3>
                      <div className="latest_activity_inner_two_lower">
                        <img
                          src={uparrow}
                          alt="uparrow"
                        />
                        <p>
                          ${activity.amount} <span>{activity.subHeader}</span>
                        </p>
                      </div>
                    </div>

                    <div>
                      <button className="latest_activity_inner_three">View all distribution notices</button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="settings_main">
            <div className="settings_header">
              <h3>Settings</h3>
            </div>
            <div className="setting_one">
              <h3>Investor</h3>
              <p>Hobart Family Capital, LP</p>
            </div>
            <div className="setting_one">
              <h3>Contacts</h3>
              <p>Franklin Smith </p>
              <p>Wealth Advisors </p>
              <p>Alan Frank Jim Hobart </p>
              <p>Stacey Lancer </p>
            </div>
            <div className="setting_one">
              <h3>Address</h3>
              <p>Hobart Family Capital, LP 19502 Sierra Irvine, CA 92603 United States</p>
              <p>Memo: FBO Hobart Family Capital Holdings LLC </p>
            </div>
            <div className="setting_lower">
              <div onClick={() => toggleModal(1)}>
                <p>Configure check payments</p>
                <img
                  src={rightArrow}
                  alt="rightArrow"
                />
              </div>
              <div onClick={() => toggleModal(2)}>
                <p>Configure wire transfer payment instructions</p>
                <img
                  src={rightArrow}
                  alt="rightArrow"
                />
              </div>
              {activeModal && <ReplyModal closeModal={closeModal} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Performance_Overview;

const ReplyModal = ({closeModal}) => {
  const [answer, setAnswer] = useState("");

  const handleAnswerChange = (e) => {
    setAnswer(e.target.value);
  };

  const handleOverlayClick = (e) => {
    if (e.target.className === "reply-modal_overlay") {
      closeModal();
    }
  };
  const [navActive, setNavActive] = useState(1);
  return (
    <div
      className="reply-modal_overlay"
      onClick={handleOverlayClick}
    >
      <div className="configure_settings_modal">
        <div className="header_box">
          <h2>Configure settings</h2>
          <img
            src={cross}
            alt="cross_icon"
            onClick={closeModal}
            style={{cursor: "pointer"}}
          />
        </div>
        <div className="nav_box">
          <div className="deals-room-nav gp_nav asset-nav ass-nav">
            <span
              className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(1);
              }}
            >
              check payments
            </span>
            <span
              className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(2);
              }}
            >
              wire transfer payment instructions
            </span>
          </div>
        </div>

        {navActive === 1 && (
          <div className="content_box">
            <div className="check_payment_wrapper">
              <div className="topSection">
                <div className="groupA">
                  <h3>Where do you want to send checks for this position?</h3>

                  <div className="option_box1">
                    <div className="topBox">
                      <div className="checkbox">
                        <img
                          src={radioButton}
                          alt="checkbox_icon"
                        />
                        <p>Real Invest Trust</p>
                      </div>
                      <img
                        src={edit}
                        alt="edit_icon"
                      />
                    </div>
                    <div className="bottomBox">
                      <p>6751 Columbia Gateway Dr #300, Columbia, MD 21046, USA</p>
                    </div>
                  </div>
                  <div className="option_box2">
                    <div className="checkbox">
                      <img
                        src={radioButton}
                        alt="checkbox_icon"
                      />
                      <p>Use another address for check payments only</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottomSection">
                <div className="groupB">
                  <h3>This account has other positions. Please select which positions you would like this change to apply to.</h3>

                  <div className="option_box option_box1">
                    <div className="topBox">
                      <div className="checkbox">
                        <img
                          src={radioButton}
                          alt="checkbox_icon"
                        />
                        <p>All other positions with these payment instructions</p>
                      </div>
                      <p>Used for 351 California St, LLC</p>
                    </div>
                  </div>
                  <div className="option_box option_box2">
                    <img
                      src={radioButton}
                      alt="checkbox_icon"
                    />
                    <p>This position only</p>
                  </div>
                  <div className="option_box option_box3">
                    <img
                      src={radioButton}
                      alt="checkbox_icon"
                    />
                    <p>All positions for this account</p>
                  </div>
                  <p className="viewAll_btn">View all positions</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {navActive === 2 && (
          <div className="wire-transfer-payment-instructions">
            <div className="form">
              <div className="form-section">
                <div className="form-header">
                  <h3>Beneficiary</h3>
                </div>
                <div className="form-group">
                  <label>Beneficiary Name</label>
                  <input
                    type="text"
                    placeholder="James"
                  />
                </div>
                <div className="form-group">
                  <label>Beneficiary Address</label>
                  <input
                    type="text"
                    placeholder="Street Line 1"
                  />
                  <input
                    type="text"
                    placeholder="Street Line 2"
                  />
                  <input
                    type="text"
                    placeholder="City"
                  />
                  <input
                    type="text"
                    placeholder="State"
                  />
                  <input
                    type="text"
                    placeholder="ZIP/Postal Code"
                  />
                  <input
                    type="text"
                    placeholder="Country"
                  />
                </div>
                <div className="form-group">
                  <label>Account Number or IBAN</label>
                  <input
                    type="text"
                    placeholder="XXXXXXXXXXXX"
                  />
                </div>
                <div className="form-group custom">
                  <div>
                    <img
                      src={plus}
                      alt="plus"
                    />
                  </div>

                  <label>Add referace to beneficiary OBI and BBI</label>
                </div>
              </div>
              <div className="form-section">
                <div className="form-header">
                  <h3>Beneficiary bank</h3>
                </div>
                <div className="form-group">
                  <label>Bank Name</label>
                  <input
                    type="text"
                    placeholder="James"
                  />
                </div>
                <div className="form-group">
                  <label>Bank Address</label>
                  <input
                    type="text"
                    placeholder="Street Line 1"
                  />
                  <input
                    type="text"
                    placeholder="Street Line 2"
                  />
                  <input
                    type="text"
                    placeholder="City"
                  />
                  <input
                    type="text"
                    placeholder="State"
                  />
                  <input
                    type="text"
                    placeholder="ZIP/Postal Code"
                  />
                  <input
                    type="text"
                    placeholder="Country"
                  />
                </div>
                <div className="form-group">
                  <label>Routing Information</label>
                  <span>Please enter the bank address (including country) first</span>
                  <div className="checkbox-group">
                    {/* <input
                      type="checkbox"
                      id="intermediary-bank"
                    /> */}
                    <Checkbox />
                    <label htmlFor="intermediary-bank">This bank uses an intermediary bank for wire transfers</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="positions-section">
              <h3>This account has other positions. Please select which positions you would like this change to apply to.</h3>
              <div className="radio-group">
                <input
                  type="radio"
                  id="all-positions"
                  name="positions"
                />
                <div className="radio-group_div">
                  <label htmlFor="all-positions">All other positions with these payment instructions</label>
                  <label htmlFor="all-positions">Used for 351 California St, LLC</label>
                </div>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  id="this-position"
                  name="positions"
                />
                <label htmlFor="this-position">This position only</label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  id="this-position"
                  name="positions"
                />
                <div className="radio-group_div">
                  <label htmlFor="this-position">All Position For this account</label>
                  <p className="view_position_link">View all position</p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="groupC">
          <button
            className="cancel"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button className="save_changes">Save Changes</button>
        </div>
      </div>
    </div>
  );
};
