import React, {useEffect, useRef, useState} from "react";
import "./Investment.scss";
import searchIcon from "../../../../assets/investor-pipeline/icons/searchIcon_grey.svg";
import actionBtnIcon from "../../../../assets/investor-pipeline/icons/action_icon.svg";
import filterBtnIcon from "../../../../assets/svg/filter_icon.svg";
import delBtnIcon from "../../../../assets/investor-pipeline/icons/delete_icon_red.svg";
import cloudBtnIcon from "../../../../assets/investor-pipeline/icons/cloud_download_btn.svg";
import menuBtnIcon from "../../../../assets/investor-pipeline/icons/menu_btn_3dots.svg";
import layoutBtnIcon from "../../../../assets/investor-pipeline/icons/layout_btn.svg";
import CashFlowHistoryChart from "./CashFlowHistoryChart/CashFlowHistoryChart";
import InvestmentTable from "./InvestmentTable/InvestmentTable";
import AllocationChart from "./AllocationChart/AllocationChart";
import InvestmentCard from "./InvestmentCard/InvestmentCard";
const Investment = () => {
  const [navActive, setNavActive] = useState(1);

  const [isInputVisible, setInputVisible] = useState(false);
  const searchBoxRef = useRef(null);

  const handleSearchIconClick = () => {
    setInputVisible(true);
  };

  const handleClickOutside = (e) => {
    if (searchBoxRef.current && !searchBoxRef.current.contains(e.target)) {
      setInputVisible(false);
    }
    if (deleteBoxRef.current && !deleteBoxRef.current.contains(e.target)) {
      setIsDeleteVisible(false);
    }
    if (filterBoxRef.current && !filterBoxRef.current.contains(e.target)) {
      setIsFilterOptionsVisible(false);
    }
  };

  // useEffect(() => {
  //   document?.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const sections = [
    {
      header: "ASSET TYPE",
      options: ["Commercial", "Multifamily"],
    },
    {
      header: "DATE ADDED",
      options: ["This year", "Previous years"],
    },
    {
      header: "LOCATION",
      options: ["San Francisco", "Los Angeles", "Florida"],
    },
  ];

  const handleOptionChange = (e) => {
    const value = e.target.value;
    setSelectedOptions((prevSelected) => (prevSelected.includes(value) ? prevSelected.filter((option) => option !== value) : [...prevSelected, value]));
  };

  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const deleteBoxRef = useRef(null);

  const handleActionBtnClick = (e) => {
    e.stopPropagation();
    setIsDeleteVisible((prev) => !prev);
  };

  const [isFilterOptionsVisible, setIsFilterOptionsVisible] = useState(false);
  const filterBoxRef = useRef(null);

  const handleFilterBtnClick = (e) => {
    e.stopPropagation();
    setIsFilterOptionsVisible((prev) => !prev);
  };

  const [isInvestmentTableVisible, setIsInvestmentTableVisible] = useState(true);
  const [isInvestmentCardVisible, setIsInvestmentCardVisble] = useState(false);

  return (
    <div className="investment_internal_container">
      <div className="investment_internal_nav_panel">
        <div className=" deals-room-nav gp_nav asset-nav ass-nav">
          <span
            className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
            onClick={() => {
              setNavActive(1);
            }}
          >
            All <span className="no_of_notifications">36</span>
          </span>
          <span
            className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
            onClick={() => {
              setNavActive(2);
            }}
          >
            Active <span className="no_of_notifications">32</span>
          </span>
          <span
            className={navActive === 3 && "deals-room-nav-active ut-nav-active"}
            onClick={() => {
              setNavActive(3);
            }}
          >
            Past <span className="no_of_notifications">4</span>
          </span>
          <span
            className={navActive === 4 && "deals-room-nav-active ut-nav-active"}
            onClick={() => {
              setNavActive(4);
            }}
          >
            Archived
          </span>
        </div>
        <div className="rightSideMenu">
          <div className="searchBar_nav">
            <div
              ref={searchBoxRef}
              className={`search_bar_box ${isInputVisible ? "active" : ""}`}
            >
              {isInputVisible && <input type="text" />}
              <img
                src={searchIcon}
                alt="search_icon"
                onClick={handleSearchIconClick}
                style={{cursor: "pointer"}}
              />
            </div>
          </div>
          <div className="option_btn_container">
            <div
              onClick={handleActionBtnClick}
              className="action_btn_wrapper option_btn_wrapper"
            >
              <button>
                Action
                <img
                  src={actionBtnIcon}
                  alt="action_icon"
                />
              </button>
              {isDeleteVisible && (
                <div
                  className="del_btn_wrapper option_btn_wrapper"
                  ref={deleteBoxRef}
                >
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <img
                      src={delBtnIcon}
                      alt="action_icon"
                    />
                    Delete
                  </button>
                </div>
              )}
            </div>
            <div
              onClick={handleFilterBtnClick}
              className="filter_btn_wrapper option_btn_wrapper"
            >
              <button>
                <img
                  src={filterBtnIcon}
                  alt="filter_icon"
                />
                Filter
              </button>
              {isFilterOptionsVisible && (
                <div
                  className="filter_options_wrapper"
                  ref={filterBoxRef}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {sections.map((section, sectionIndex) => (
                    <div
                      className="filter_options_dropdown_section"
                      key={sectionIndex}
                    >
                      <div className="filter_options_dropdown_header">{section.header}</div>
                      {section.options.map((option, optionIndex) => (
                        <label key={optionIndex}>
                          <input
                            type="checkbox"
                            name={section.header}
                            value={option}
                            checked={selectedOptions.includes(option)}
                            onChange={handleOptionChange}
                          />
                          {option}
                        </label>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="cloud_download_btn">
            <button>
              <img
                src={cloudBtnIcon}
                alt="cloud_btn"
              />
            </button>
          </div>
          <div className="layout_btns">
            <button
              onClick={() => {
                setIsInvestmentTableVisible(true);
                setIsInvestmentCardVisble(false);
              }}
            >
              <img
                src={menuBtnIcon}
                alt="3dot_btn"
                className={isInvestmentTableVisible ? "active_layout" : ""}
              />
            </button>
            <button
              onClick={() => {
                setIsInvestmentTableVisible(false);
                setIsInvestmentCardVisble(true);
              }}
            >
              <img
                src={layoutBtnIcon}
                alt="layout_btn"
                className={isInvestmentCardVisible ? "active_layout" : ""}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="investment_internal_content_container">
        <div className="investment_internal_content_wrapper">
          <div className="topSection">
            <div className="chart_container">
              <CashFlowHistoryChart />
            </div>
            <div className="bar_chart_container">
              <div className="topSection">
                <h2>Allocation</h2>
                <button>
                  by Geography
                  <img
                    src={actionBtnIcon}
                    alt="action_icon"
                  />
                </button>
              </div>
              <AllocationChart />
            </div>
          </div>
          <div className="bottomSection">
            {isInvestmentTableVisible ? (
              <div className="data_table_wrapper">
                <InvestmentTable />
              </div>
            ) : (
              <div className="data_card_wrapper">
                <InvestmentCard />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Investment;
