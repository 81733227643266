import pen from "../../../assets/acqusition/svg/pen.svg";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {PrimaryButton} from "../../../components/reusableComponents/Buttons/Buttons";
import {DateCalendar, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";

import {BookmarkSvg, ShareSvg, CompareSvg} from "../Svgimages/svgimages";
import {ContactPanel} from "./ContactPanel";
import rpbtn from "../../../assets/acqusition/svg/rpbox.svg";

const AssetCard2 = ({selectedData}) => {
  const [requesttour, setRequestTour] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div className="asset-card2">
      <div className="asset-card-inner">
        <div className="asset-heading-AssetCard2">
          <span>{selectedData?.assetName}</span>
          <p>{selectedData?.assetsAddress}</p>
          <div className="ruppes-section">
            <img
              src={rpbtn}
              alt="image"
            />

            <div className="rp-section-heading">
            <span>${selectedData?.assetValue?.toLocaleString()}</span>
            </div>
            <div>
              <span>${selectedData?.assetSquareFeet} sqft</span>
            </div>
          </div>
        </div>

        <Tabs
          selectedTabClassName="active"
          selectedIndex={selectedIndex}
        >
          <TabList>
            <Tab>Contact</Tab>
            <Tab>Schedule a Tour</Tab>
          </TabList>
          <TabPanel>
            <ContactPanel
              setSelectedIndex={setSelectedIndex}
              requesttour={requesttour}
              setRequestTour={setRequestTour}
              selectedData={selectedData}
            />
          </TabPanel>
          <TabPanel>
            <div className="calender_wrapper">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar />
              </LocalizationProvider>
              <div className="button_wrapper">
                <div className="time_wrapper">
                  <div className="left">
                    <span>08 Apr 2019,</span> 4:00 pm - 5:30 pm
                  </div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                      fill="#9EA0A5"
                    />
                  </svg>
                </div>
                <Link to="/dealsroom">
                  <PrimaryButton name={"Request a Tour & Start Deal"} />
                </Link>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
      <div className="addnote">
        <div className="left">
          <BookmarkSvg />
          <ShareSvg />
          <CompareSvg />
        </div>
        <div className="right">
          <img
            src={pen}
            alt=""
          />
          <span>Add Note</span>
        </div>
      </div>
    </div>
  );
};

export default AssetCard2;
