import React, {useState} from "react";
import {PrimaryButton} from "../../../components/reusableComponents/Buttons/Buttons";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";
import "./Captableaccess.scss";
import level from "../../../assets/Manage-syndicate/Svg/level.svg";

const Captableaccess = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const options = ["Select access level", "Select access level", "Select access level"];
  return (
    <div>
      <div>
        <div style={{marginTop: "10px", marginBottom: "10px"}}>
          <h1>Cap Table Access</h1>
        </div>
      </div>
      <div className="Conatiner-head">
        <div className="Conatiner">
          <div className="Captable-Conatiner">
            <div className="Captable-Conatiner-data">
              <span>GROUP LEVELS</span>
              <span>INDIVIDUAL LEVELS</span>
            </div>
            <div className="Conatiner-Group-access">
              <div className="Conatiner-Group-access-data">
                <span>Group access levels will not apply until they saved.</span>
                <PrimaryButton
                  name="View"
                  classNames="Report-see-plan"
                  svgPath="/path/to/svg/image.svg"
                  padding="10px"
                  svgHeight="20px"
                  svgWidth="20px"
                />
              </div>
            </div>
          </div>
          <div>
            <div className="Conatiner-Founder">
              <div className="Conatiner-Founder-data">
                <h1>Founder</h1>
                <h5>Stakeholders with a founder relationship</h5>
                <DropDown
                  data={options}
                  selectedData={selectedOption}
                  setSelectedData={setSelectedOption}
                  dropdownState={false}
                  classDataName="custom-dropdown"
                />
              </div>
            </div>
            <div className="Conatiner-Founder">
              <div className="Conatiner-Founder-data">
                <h1>Investors</h1>
                <h5>Stakeholders with an investors relationship</h5>
                <DropDown
                  data={options}
                  selectedData={selectedOption}
                  setSelectedData={setSelectedOption}
                  dropdownState={false}
                  classDataName="custom-dropdown"
                />
              </div>
            </div>
            <div className="Conatiner-Founder">
              <div className="Conatiner-Founder-data">
                <h1>Employee</h1>
                <h5>Employee, executive, advisor, and officer relationship</h5>
                <DropDown
                  data={options}
                  selectedData={selectedOption}
                  setSelectedData={setSelectedOption}
                  dropdownState={false}
                  classDataName="custom-dropdown"
                />
              </div>
            </div>
            <div className="Conatiner-Founder">
              <div className="Conatiner-Founder-data">
                <h1>Former Employees</h1>
                <h5>Former employees & advisors</h5>
                <DropDown
                  data={options}
                  selectedData={selectedOption}
                  setSelectedData={setSelectedOption}
                  dropdownState={false}
                  classDataName="custom-dropdown"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="Conatiner-flex-header">
          <div className="Conatiner-flex">
            <div className="Conatiner-flex-data">
              <div className="Conatiner-data">
                <h1>Understand access levels</h1>
                <p>See what your shareholders, experience is when you set new access levels.</p>
              </div>
              <div className="image-level">
                <img
                  src={level}
                  alt="image"
                />
              </div>
            </div>
            <div className="header-access">
              <a href="#">View access levels guide</a>
            </div>
          </div>
          <div className="Conatiner-flex">
            <div className="Conatiner-flex-data">
              <div className="Conatiner-notifications-data">
                <h1>Automatic investors notifications</h1>
                <p>When you raise a anew round, your investors on Carta will get notified of your new price per share- making it easire to keep your investors informed</p>
                <hr />
                <p>Stakeholder lose access when the no longer have outstanding holdings</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="Captable-Header">
          <div className="Captable-Header-data">
            <p>Set access level by group or for individual stakholders to enable the right experience for your shareholders and employees.</p>
            <a href="">Learn more</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Captableaccess;
