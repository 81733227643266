import React, {useEffect, useRef} from "react";
import ReactECharts from "echarts-for-react";

const DevlopmentBarChart = () => {
  const xAxisData = [];
  const data1 = [1223, 414, 234, 252, 54, 412, 421, 645, 645, 234];
  const data2 = [345, 123, 35, 123, 345, 23, 56, 252, 534, 34, 345];
  const data3 = [242, 345, 456, 345, 53, 233, 456, 234, 103, 345, 34];

  for (let i = 0; i < 10; i++) {
    xAxisData.push(1 + i);
    // data1.push(+(Math.random() * 2).toFixed(2));
    // data2.push(+(Math.random() * 5).toFixed(2));
    // data3.push(+(Math.random() + 0.3).toFixed(2));
  }

  const emphasisStyle = {
    itemStyle: {
      shadowBlur: 10,
      shadowColor: "rgba(0,0,0,0.3)",
    },
  };

  const option = {
    color: ["#1566d8", "#7fc330", "#ffc403"],
    legend: {
      show: false,
      data: ["bar", "bar2", "bar3"],
      left: "10%",
    },
    brush: {
      toolbox: ["rect", "polygon", "lineX", "lineY", "keep", "clear"],
      xAxisIndex: 0,
    },
    toolbox: {
      show: false,
      feature: {
        magicType: {
          type: ["stack"],
        },
        // dataView: {},
      },
    },
    tooltip: {
      show: false,
    },
    xAxis: {
      data: xAxisData,
      axisLine: {onZero: true},
      splitLine: {show: false},
      splitArea: {show: false},
    },
    yAxis: {
      position: "left",
      axisLabel: {
        formatter: "{value}k", // Add dollar sign to y-axis labels
      },
    },
    grid: {
      bottom: 100,
    },
    series: [
      {
        name: "bar",
        type: "bar",
        stack: "one",
        emphasis: emphasisStyle,
        data: data1,
      },
      {
        name: "bar2",
        type: "bar",
        stack: "one",
        emphasis: emphasisStyle,
        data: data2,
      },
      {
        name: "bar3",
        type: "bar",
        stack: "one",
        emphasis: emphasisStyle,
        data: data3,
      },
    ],
  };

  return <ReactECharts option={option} />;
};

export default DevlopmentBarChart;
