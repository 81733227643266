import React, {useEffect, useState} from "react";
import ReactECharts from "echarts-for-react";

const RedChart = ({data , period}) => {

  const option = {
    xAxis: {
      type: 'category',
      data: data?.dates,
    },
    yAxis: [
      {
        type: 'value',
        // name: 'Amount',
      },
      {
        type: 'value',
        // name: 'NOI',
      },
    ],
    legend: {
      data: ['Levered Net Cash Flow','Unlevered Net Cash Flow', 'Principal Payment', 'Interest Payment', 'Management Fee', 'Ending Loan Balance'],
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    series: [
      {
        name: 'Levered Net Cash Flow',
        type: 'line',
        yAxisIndex: 1,
        data: data?.levered_net_cash_flow,
        lineStyle: {
          color: '#2F80ED', 
        },
        tooltip: {
          formatter: '{b} <br/> NOI: {c}', 
        },
      },
      {
        name: 'Other Income',
        type: 'bar',
        stack: 'total',
        data: data?.other_income,
        itemStyle: {
          color: '#6FCF97', 
        },
        tooltip: {
          formatter: '{b} <br/> Other Income: {c}', 
        },
      },
      {
        name: 'Ending Loan Balance',
        type: 'bar',
        stack: 'total',
        data: data?.ending_loan_balance,
        itemStyle: {
          color: '#F2994A', 
        },
        tooltip: {
          formatter: '{b} <br/> Other Expenses: {c}', 
        },
      },
      {
        name: 'Interest Payment',
        type: 'bar',
        stack: 'total',
        data: data?.interest_payment,
        itemStyle: {
          color: '#9EA0A5', 
        },
        tooltip: {
          formatter: '{b} <br/> Management Fee: {c}', 
        },
        barWidth:'30%'
      },
      {
        name: 'Principal Payment',
        type: 'bar',
        stack: 'total',
        data: data?.principal_payment,
        itemStyle: {
          color: '#F2994A', 
        },
        tooltip: {
          formatter: '{b} <br/> Interest Payment: {c}'
        },
      },
      {
        name: 'Unlevered Net Cash Flow',
        type: 'bar',
        stack: 'total',
        data: data?.unlevered_net_cash_flow,
        itemStyle: {
          color: '#6FCF97', 
        },
        tooltip: {
          formatter: '{b} <br/> GPR: {c}', 
        },
      },
    ],
  };


  //   const handleBrushSelected = (params, echartsInstance) => {
  //     const brushed = [];
  //     const brushComponent = params.batch[0];
  //     for (let sIdx = 0; sIdx < brushComponent.selected.length; sIdx++) {
  //       const rawIndices = brushComponent.selected[sIdx].dataIndex;
  //       brushed.push("[Series " + sIdx + "] " + rawIndices.join(", "));
  //     }
  //     echartsInstance.setOption({
  //       title: {
  //         backgroundColor: "#333",
  //         text: "SELECTED DATA INDICES: \n" + brushed.join("\n"),
  //         bottom: 0,
  //         right: "10%",
  //         width: 100,
  //         textStyle: {
  //           fontSize: 12,
  //           color: "#fff",
  //         },
  //       },
  //     });
  //   };

  return (
    <ReactECharts
      option={option}
      //   onEvents={{brushSelected: handleBrushSelected}}
    />
  );
};

export default RedChart;
