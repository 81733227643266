import React, {useEffect, useState} from "react";
import {InputBox} from "../../../../components/reusableComponents/FormFields/FormFields";
import {ReactComponent as InfoIcon} from "../../../../assets/portfolio/svgs/info-icon.svg";
import ProgressBar from "@ramonak/react-progress-bar";
import {useSelector,useDispatch} from "react-redux";
import {fetchAnnualFinancialData} from "../../../../store/reducers/Graphs/OperatingIncomeExpences";

const OperatingIncomeAndExpenses = ({ selectedData }) => {
  const dispatch = useDispatch();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const Property_Code = selectedData?.Property_Code;
  const { data, loading, error } = useSelector((state) => state.OperatingIncomeExpencesSlice);

  useEffect(() => {
    if (Property_Code && selectedYear) {
      dispatch(fetchAnnualFinancialData({ property_code: Property_Code, year: selectedYear }));
    }
  }, [dispatch, Property_Code, selectedYear]);

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const years = [];
  for (let i = 2020; i <= new Date().getFullYear(); i++) {
    years.push(i);
  }

  return (
    <div className="operating-income-cards">
      <div className="income-expense-left-grid">
        <select
          className="basicSelect"
          value={selectedYear}
          onChange={handleYearChange}
        >
          {years.map((year) => (
            <option
              key={year}
              value={year}
              className="basicOption"
            >
              {year}
            </option>
          ))}
        </select>

        <div className="asset-benchmark-set-cards">
          <div className="asset-benchmark-income-card">
            <h1>Asset</h1>
            <div className="line">&nbsp;</div>
            <div className="asset-card-table">
              <p>$/YR</p>
              <p>$/UNIT/YR</p>
              <p>%</p>
            </div>
            <div className="line">&nbsp;</div>
            <div className="asset-card-table content">
              <>
                <p>{data?.AssetTotal}</p>
                <p>{data?.AssetPerUnit}</p>
                <p>{data?.AssetPerUnitpercentage}</p>
              </>
            </div>
          </div>
          <div className="asset-benchmark-income-card">
            <h1>Benchmark Set</h1>
            <div className="line">&nbsp;</div>
            <div className="asset-card-table">
              <p>$/YR</p>
              <p>$/UNIT/YR</p>
              <p>%</p>
            </div>
            <div className="line">&nbsp;</div>
            <div className="asset-card-table content">
              <p>{data?.Benchmark?.AssetTotal}</p>
              <p>{data?.Benchmark?.AssetPerUnit}</p>
              <p>{data?.Benchmark?.AssetPerUnitpercentage}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="income-expense-right-grid">
        <div className="difference-wrapper">
          <h2>DIFFERENCE</h2>
          <InfoIcon />
        </div>
        <div className="asset-benchmark">
          <h2>Asset vs. Benchmark Set</h2>
          <h1 style={{ fontSize: "26px" }}>
            {data?.Difference?.AssetTotalDifference} <span style={{ fontSize: "14px", color: "#34AA44" }}>{data?.Difference?.ProfitPercentage}</span>
          </h1>
        </div>
        <div className="progress-status">
          <p>Asset</p>
          <ProgressBar
            completed={80}
            bgColor="#1665D8"
            isLabelVisible={false}
            maxCompleted={100}
            width="180px"
            borderRadius="0px"
            baseBgColor="none"
          />
          <p>$32</p>
        </div>
        <div className="progress-status">
          <p style={{ color: "#9EA0A5" }}>Benchmark</p>
          <ProgressBar
            completed={80}
            bgColor="#EAECEE"
            isLabelVisible={false}
            maxCompleted={70}
            width="180px"
            borderRadius="0px"
            baseBgColor="none"
          />
          <p style={{ color: "#9EA0A5" }}>$28</p>
        </div>
      </div>
    </div>
  );
};

const EditPopup = ({isOpen, onClose, title, data, onSave}) => {
  const [editedData, setEditedData] = useState(data);

  const handleChange = (key, value) => {
    setEditedData((prevData) => ({...prevData, [key]: value}));
  };

  const handleSave = () => {
    onSave(editedData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="edit_popup_OperatingIncomeAndExpenses">
      <div className="popup_content_OperatingIncomeAndExpenses">
        <h2>{title}</h2>
        {Object.keys(editedData).map((key) => (
          <div
            key={key}
            className="input-group"
          >
            <label>{key}</label>
            <input
              type="text"
              value={editedData[key]}
              onChange={(e) => handleChange(key, e.target.value)}
            />
          </div>
        ))}
        <div className="button-group">
          <button
            className="save-button"
            onClick={handleSave}
          >
            Save
          </button>
          <button
            className="close-button"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default OperatingIncomeAndExpenses;
