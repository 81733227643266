import React from 'react'
import './activityhistory.scss'
import Delete from '../../../assets/svg/delete.svg'
import profile from '../../../assets/svg/profileAvatar.png'
import see from '../../../assets/svg/see-arrow.svg'

function ActivityHistory() {
  return (
    <div className='activity_history'>
        <div className='box_wrapper'>
            {/* <img src={line} alt='' /> */}
            <p>Today</p>
            <ActivityHistoryBox />
            <ActivityHistoryBox />
            <ActivityHistoryBox comment={true} />
            <ActivityHistoryBox />
            <ActivityHistoryBox />
            <ActivityHistoryBox />


        </div>
    </div>
  )
}

export default ActivityHistory

const ActivityHistoryBox = ({comment}) => {
    return (
        <div className='activity_history_box'>
            <div className='box_left'>
            <img src={profile} alt='' />
            <div>
                <p>Minnie Porter started progress on article Postcard from Paris – CSS3 Keyframes Animations Tutorial</p>
                {comment && 
                <>
                <div className='box_comment'>
                    I can’t figure out the how to do the mask on the circle and the speaker holes for the life of me. I select them all and then select the circle layer and choose layer mask. I’ve tried all the…
                </div>

                <span className='comment'>
                Go to comment <img src={see} alt='' />
                </span>
                </>
                }
                 <span>2 Hours ago</span>
            </div>
            </div>
            <img src={Delete} alt='' />
        </div>
    )
}