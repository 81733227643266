import React from "react";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

import "./AddDoc.scss";

import cancle from "../../assets/dealsroom/svg/cancle.svg";
import draft from "../../assets/dealsroom/svg/draft.svg";
import next from "../../assets/dealsroom/svg/next.svg";
import back from "../../assets/dealsroom/svg/back.svg";
import pen from "../../assets/dealsroom/svg/pen.svg";
import clipboard from "../../assets/dealsroom/svg/clipboard.svg";
import close from "../../assets/dealsroom/svg/close.svg";
import navclose from "../../assets/dealsroom/svg/navclose.svg";
import upload from "../../assets/dealsroom/svg/upload.svg";
import document from "../../assets/dealsroom/svg/document.svg";
import adddoc from "../../assets/dealsroom/svg/adddoc.svg";
import newdoc from "../../assets/dealsroom/svg/newdoc.svg";
import pdf from "../../assets/dealsroom/svg/pdf.svg";
import del from "../../assets/dealsroom/svg/delete.svg";
import eye from "../../assets/dealsroom/svg/eye.svg";
import action from "../../assets/dealsroom/svg/action.svg";
import clock from "../../assets/dealsroom/svg/clock.svg";
import warning from "../../assets/dealsroom/svg/warning.svg";
import dropdocument from "../../assets/dealsroom/svg/dropdocument.svg";
import bold from "../../assets/dealsroom/svg/bold.svg";
import italic from "../../assets/dealsroom/svg/italic.svg";
import underline from "../../assets/dealsroom/svg/underline.svg";
import strikethrough from "../../assets/dealsroom/svg/strikethrough.svg";
import link from "../../assets/dealsroom/svg/link.svg";
import quote from "../../assets/dealsroom/svg/quote.svg";
import image from "../../assets/dealsroom/svg/image.svg";
import combined from "../../assets/dealsroom/svg/combined.svg";
import listing from "../../assets/dealsroom/svg/listing.svg";
import rightalign from "../../assets/dealsroom/svg/rightalign.svg";
import leftalign from "../../assets/dealsroom/svg/leftalign.svg";
import strike from "../../assets/dealsroom/svg/strike.svg";
import stretch from "../../assets/dealsroom/svg/stretch.svg";
import numberlist from "../../assets/dealsroom/svg/numberlist.svg";
import mail from "../../assets/dealsroom/svg/mail2.svg";
import pen2 from "../../assets/dealsroom/svg/pen2.svg";
import plus from "../../assets/dealsroom/svg/plus.svg";
import minus from "../../assets/dealsroom/svg/minus.svg";
import arrowright2 from "../../assets/dealsroom/svg/arrowright2.svg";
import filedetail from "../../assets/dealsroom/svg/filedetail.svg";
import building from "../../assets/dealsroom/svg/building.svg";
import backbtn from "../../assets/dealsroom/svg/backbtn.svg";
import pages from "../../assets/dealsroom/svg/pages.svg";
import content from "../../assets/dealsroom/svg/contents.svg";
import search from "../../assets/dealsroom/svg/search.svg";
import bigclock from "../../assets/dealsroom/svg/bigclock.svg";
import issue from "../../assets/dealsroom/svg/issue.svg";
import blankcircle from "../../assets/dealsroom/svg/blankcircle.svg";
import building2 from "../../assets/dealsroom/images/finish.png";
import women from "../../assets/dealsroom/images/women.png";
import manualdoc from "../../assets/dealsroom/images/manualdoc.png";

import DropDown from "../../components/reusableComponents/Dropdown/Dropdown";
import {InputBox, InfoBox} from "../../components/reusableComponents/FormFields/FormFields";
import {Link} from "react-router-dom";
const AddDoc = () => {
  const [navActive, setNavActive] = useState(1);

  return (
    <div className="add-doc-main-wrapper">
      <DocHeader />
      <div className="second-container">
        <div className="upload-doc-section-left">
          {navActive === 1 && <DocName />}
          {navActive === 2 && <UploadDoc />}
          {navActive === 3 && <Message />}
          {navActive === 4 && <FinishBox setNavActive={setNavActive} />}
        </div>
        <div className="upload-doc-section-right">
          <DocNavigator
            navActive={navActive}
            setNavActive={setNavActive}
          />
        </div>
      </div>
    </div>
  );
};

const DocHeader = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="add-doc-header">
      <div className="doc-header-left">
        <span>New Document</span>
      </div>
      <div className="doc-header-right">
        <div
          className="doc-cancle-btn"
          onClick={goBack}
        >
          <img
            src={cancle}
            alt=""
          />
          <span>Cancel</span>
        </div>
        <div>
          <img
            src={draft}
            alt=""
          />
          <span>Save Draft</span>
        </div>
        <div>
          <img
            src={back}
            alt=""
          />
          <span>Back</span>
        </div>
        <div className="doc-next-btn">
          <span>Next</span>
          <img
            src={next}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const DocNavigator = ({navActive, setNavActive}) => {
  return (
    <div className="doc-navigator">
      <div className="steps">
        {renderStep(1, "File Name", navActive, setNavActive)}
        {renderStep(2, "Add Files", navActive, setNavActive)}
        {renderStep(3, "Message", navActive, setNavActive)}
        {renderStep(4, "Finish", navActive, setNavActive)}
        <div className="progress-bar">
          <span className="indicator"></span>
        </div>
      </div>
    </div>
  );
};

const renderStep = (stepNumber, stepText, navActive, setNavActive) => {
  const isActive = navActive === stepNumber;

  return (
    <div
      key={stepNumber}
      className={`step-cnt ${isActive ? "active" : ""}`}
      onClick={() => setNavActive(stepNumber)}
    >
      <span>{stepText}</span>
      <span className="circle"></span>
    </div>
  );
};

const DocName = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container">
        <div className="legendimg">
          <img
            src={pen}
            alt=""
          />
        </div>
        <h2>Document Name</h2>
        <div className="doc-form-container">
          <div className="form-heading">
            <img
              src={clipboard}
              alt=""
            />
            <span>General</span>
          </div>
          <div className="form-content-container">
            <h1>Name</h1>
            <div>
              <input
                id="input"
                type="text"
                placeholder="Offer"
              />
            </div>
            <p>Enter a unique name to make it easier to find in the document list.</p>
            <h1>Tag</h1>
            <div>
              <input
                id="input"
                type="text"
                placeholder="Value"
              />
            </div>
            <div className="form-btn-cont">
              <div className="form-btn">
                <span>Report</span>
                <img
                  src={close}
                  alt=""
                />
              </div>
              <div className="form-btn">
                <span>Rent Roll</span>
                <img
                  src={close}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const UploadDoc = () => {
  const [isClicked, setIsClicked] = useState(1);
  return (
    <div className="mainContainer">
      <div className="docname-container">
        <div className="legendimg">
          <img
            src={upload}
            alt=""
          />
        </div>
        <h2>Upload Document</h2>
        <div className="switch-btn">
          <span
            onClick={() => {
              setIsClicked(1);
            }}
            className={isClicked === 1 && "back"}
          >
            Create document
          </span>
          <span
            onClick={() => {
              setIsClicked(2);
            }}
            className={isClicked === 2 && "back"}
          >
            Upload document
          </span>
        </div>
        {isClicked === 1 && <CreateDocument />}
        {isClicked === 2 && <UploadDocument />}
      </div>
    </div>
  );
};

const CreateDocument = () => {
  return (
    <div className="create-doc-cont">
      <img
        src={document}
        alt=""
      />
      <Link to={"/dealsroom/adddoc/manualdoc"}>
        <div className="create-doc-btn">
          <img
            src={adddoc}
            alt=""
          />
          <span>Create Document</span>
        </div>
      </Link>
    </div>
  );
};

const UploadDocument = () => {
  return (
    <div>
      <div className="upload-doc-cont">
        <div className="upload-doc-head">
          <img
            src={newdoc}
            alt=""
          />
          <span>Your Documents</span>
        </div>
        <div className="upload-doc-body">
          <div className="select-doc">
            <img
              src={dropdocument}
              alt=""
            />
            <p>
              <span>Select documents</span> or Drop a file here.
            </p>
            <p>Supported file formats: PDF. Max file size 250 MB</p>
          </div>
          <div className="progress-cont">
            <div className="uploading-file">
              <div className="file-name">
                <div>
                  <img
                    src={pdf}
                    alt=""
                  />
                  <span>Agreement B.pdf</span>
                </div>
                <span>1,5MB of 3,6MB</span>
              </div>
              <div className="progress-bar">
                <span className="progress"></span>
              </div>
            </div>
            <img
              src={navclose}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="upload-doc-cont">
        <div className="uploaded-doc-head">
          <div>
            <span>Uploaded Documents</span>
            <span>2 items selected</span>
          </div>
          <div id="delete">
            <img
              src={del}
              alt=""
            />
            <span>Delete Selected</span>
          </div>
        </div>
        <div className="deals-room-doc-nav-head doc-nav-header">
          <span>Document Name</span>
          <span>Uploaded</span>
          <span>Owner</span>
          <span>Status</span>
          <span>Action</span>
        </div>
        <DocUpload />
        <DocUpload />
        <DocUpload />
        <DocUpload />
      </div>
    </div>
  );
};

const DocUpload = () => {
  return (
    <div>
      <div className="deals-room-doc-upload">
        <div className="deals-room-doc-pdf">
          <input type="checkbox" />
          <img
            src={newdoc}
            alt=""
          />
          <span>Agreement.pdf</span>
        </div>
        <div className="deals-room-doc-date">
          <span>5th of Nov, 18</span>
          <img
            src={clock}
            alt=""
          />
          <span>12:23 am</span>
        </div>
        <div className="deals-room-doc-org">
          <div className="deals-room-doc-org-name">
            <p>Mark Barker</p>
            <span>(You)</span>
          </div>
        </div>
        <div className="deals-room-doc-tag">
          <img
            src={warning}
            alt=""
          />
        </div>
        <div className="deals-room-doc-action">
          <img
            src={eye}
            alt=""
          />
          <img
            src={action}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const Message = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container">
        <div className="legendimg">
          <img
            src={upload}
            alt=""
          />
        </div>
        <h2>Message</h2>
        <div className="message-body-cnt">
          <div className="message-container">
            <div className="form-heading">
              <img
                src={newdoc}
                alt=""
              />
              <span>Accompanying Letter</span>
            </div>
          </div>
          <div className="sender-address">
            <div>
              <span>To:</span>
            </div>
            <div className="email first-mail">
              <span>seller.email@company.com</span>
            </div>
            <div className="email second-mail">
              <span>my.colleague@invest.group</span>
              <img
                src={close}
                alt=""
              />
            </div>
          </div>
          <div className="subject">
            <span>Subject:</span>
            <input
              id="input"
              type="text"
              placeholder="Share Document"
            />
          </div>
          <div className="formatting">
            <div>
              <img
                src={bold}
                alt=""
              />
              <img
                src={italic}
                alt=""
              />
              <img
                src={underline}
                alt=""
              />
              <img
                src={strikethrough}
                alt=""
              />
            </div>
            <div>
              <img
                src={link}
                alt=""
              />
              <img
                src={quote}
                alt=""
              />
              <img
                src={image}
                alt=""
              />
              <img
                src={combined}
                alt=""
              />
            </div>
            <div>
              <img
                src={listing}
                alt=""
              />
              <img
                src={numberlist}
                alt=""
              />
              <img
                src={stretch}
                alt=""
              />
              <img
                src={strike}
                alt=""
              />
              <img
                src={leftalign}
                alt=""
              />
              <img
                src={rightalign}
                alt=""
              />
            </div>
          </div>
          <TextBox />
        </div>
      </div>
    </div>
  );
};

const TextBox = () => {
  const initialText = `
    Let’s face it, even though English is a popular international language, there are over 6,900 other languages around the world.
    According to Wikipedia, Chinese is the most spoken language in the world, followed by Spanish, then English, Hindi, Arabic, etc. While English is popular, there are loads of other popular languages being spoken and written on a daily basis.
  `;

  const [textBoxValue, setTextBoxValue] = useState(initialText.trim());

  const handleInputChange = (event) => {
    setTextBoxValue(event.target.value);
  };

  return (
    <div className="message-body">
      <input
        id="input-box"
        type="text"
        value="Going Multilingual Means:"
      />
      <textarea
        id="textarea"
        value={textBoxValue}
        onChange={handleInputChange}
      />
      <div className="signature">
        <span>Email Signature:</span>
        <input
          id="input-box"
          type="text"
          value="Regards,Mike"
        />
      </div>
    </div>
  );
};

const FinishBox = ({setNavActive}) => {
  return (
    <div className="mainContainer">
      <div className="docname-container">
        <div className="legendimg">
          <img
            src={mail}
            alt=""
          />
        </div>
        <h2>Finish</h2>
        <div className="finish-box-container">
          <div className="message-container finish-head">
            <div className="form-heading">
              <img
                src={newdoc}
                alt=""
              />
              <span>Message for Submit</span>
            </div>
            <div
              onClick={() => {
                setNavActive(1);
              }}
            >
              <img
                src={pen2}
                alt=""
              />
            </div>
          </div>
          <div className="box-content">
            <div className="box-content-left">
              <div className="building-img">
                <img
                  src={building2}
                  alt=""
                />
                <img
                  id="building"
                  src={building}
                  alt=""
                />
                <div className="zoom-level">
                  <img
                    src={minus}
                    alt=""
                  />
                  <span>36%</span>
                  <img
                    src={plus}
                    alt=""
                  />
                </div>
              </div>
              <div className="building-bottom">
                <span>Waltham Wood Apartments</span>
                <span>8951 Waltham Woods Road, Parkville, MD 21234.</span>
                <div id="copyright">
                  <div>
                    <p>Broker Package</p>
                    <p>by Real Estate, LLC</p>
                  </div>
                  <div>
                    <span>&copy; 2022</span>
                  </div>
                </div>
                <div id="navigator">
                  <img
                    src={next}
                    alt=""
                  />
                  <span>1 of 120</span>
                  <img
                    src={next}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="box-content-right">
              <span>File Name</span>
              <p>Waltham Wood Apartments - RE-2020.pdf</p>
              <span>File Information</span>
              <img
                src={filedetail}
                alt=""
              />
              <div className="infodown">
                <span>Counterparties (5)</span>
                <div>
                  <span>See More</span>
                  <img
                    src={arrowright2}
                    alt=""
                  />
                </div>
              </div>
              <div className="user-detail">
                <div className="user-img">
                  <img
                    id="img"
                    src={women}
                    alt=""
                  />
                </div>
                <div>
                  <span>Anna Oliver</span>
                  <span>Director</span>
                </div>
              </div>
              <div className="user-detail">
                <div className="user-img">
                  <img
                    id="img"
                    src={women}
                    alt=""
                  />
                </div>
                <div>
                  <span>Anna Oliver</span>
                  <span>Director</span>
                </div>
              </div>
              <div className="user-detail">
                <div className="user-img">
                  <img
                    id="img"
                    src={women}
                    alt=""
                  />
                </div>
                <div>
                  <span>Anna Oliver</span>
                  <span>Director</span>
                </div>
              </div>
            </div>
          </div>
          <div className="finish-bottom">
            <span>Accompanying Letter</span>
            <p>
              Great talking to you today and learning more about Kensington's plans to expand into the market. Here's the pricing information you asked for. Let me know if you have any questions
              before our call tomorrow.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ManualdocCreation = () => {
  const [navActive, setNavActive] = useState(1);
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="manual-document-container">
        <div className="MDleft">
          <div className="left-head">
            <img
              src={backbtn}
              alt=""
            />
            <span>Back To Files</span>
          </div>
          <div className="left-navigation">
            <div>
              <img
                src={pages}
                alt=""
              />
              <span>Pages</span>
            </div>
            <div>
              <img
                src={content}
                alt=""
              />
              <span>Contents</span>
            </div>
            <div>
              <img
                src={search}
                alt=""
              />
              <span>Search</span>
            </div>
          </div>
        </div>
        <div className="MDright">
          <div className="MDright-head">
            <div className="head-right">
              <img
                src={newdoc}
                alt=""
              />
              <span>Agreement A.pdf</span>
            </div>
            <div className="head-left">
              <div className="zoom-level">
                <img
                  src={minus}
                  alt=""
                />
                <span>36%</span>
                <img
                  src={plus}
                  alt=""
                />
              </div>
              <div
                className="doc-cancle-btn"
                onClick={goBack}
              >
                <img
                  src={cancle}
                  alt=""
                />
                <span>Cancle</span>
              </div>
              <div className="restore">
                <img
                  src={bigclock}
                  alt=""
                />
                <span>Restore defaults</span>
              </div>
              <div
                className="save"
                onClick={goBack}
              >
                <span>Save & Close</span>
              </div>
            </div>
          </div>
          <div className="MDright-body">
            <div className="MDright-body-left">
              <div className="formatting">
                <div>
                  <img
                    src={bold}
                    alt=""
                  />
                  <img
                    src={italic}
                    alt=""
                  />
                  <img
                    src={underline}
                    alt=""
                  />
                  <img
                    src={strikethrough}
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src={link}
                    alt=""
                  />
                  <img
                    src={quote}
                    alt=""
                  />
                  <img
                    src={image}
                    alt=""
                  />
                  <img
                    src={combined}
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src={listing}
                    alt=""
                  />
                  <img
                    src={numberlist}
                    alt=""
                  />
                  <img
                    src={stretch}
                    alt=""
                  />
                  <img
                    src={strike}
                    alt=""
                  />
                  <img
                    src={leftalign}
                    alt=""
                  />
                  <img
                    src={rightalign}
                    alt=""
                  />
                </div>
              </div>
              <div>
                <img
                  src={manualdoc}
                  alt=""
                />
              </div>
            </div>
            <div className="MDright-body-right">
              <div className="right-head">
                <span
                  className={navActive === 1 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(1);
                  }}
                >
                  Input Fields
                </span>
                <span
                  className={navActive === 2 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(2);
                  }}
                >
                  E-sing details
                </span>
                <div
                  className={navActive === 3 && "deals-room-nav-active"}
                  onClick={() => {
                    setNavActive(3);
                  }}
                >
                  <img
                    src={issue}
                    alt=""
                  />
                  <span>Issues</span>
                </div>
              </div>
              {navActive === 1 && <MDrightBody />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MDrightBody = () => {
  const [countryList, setCountrylist] = useState(["Name", "Price", "Date", "Time", "Address", "Phone", "Email", "Link", "Text", "Item"]);
  const [countryOfCitizenship, setCountryOfCitizenship] = useState("Name");
  const [countryOfCitizenshipDA, setCountryOfCitizenshipDA] = useState(false);
  const [activeAction, setActiveAction] = useState("Must Fill");
  const handleActionClick = (action) => {
    setActiveAction(action);
  };
  return (
    <div>
      <div className="MD-right-body">
        <h1>1. PURCHASER</h1>
        <div id="text-filter">
          <span>Show in text</span>
          <img
            src={blankcircle}
            alt=""
          />
        </div>
        <DropDown
          label="Field Type"
          classDataName={"dropdown-height"}
          data={countryList}
          selectedData={countryOfCitizenship}
          setSelectedData={setCountryOfCitizenship}
          dropdownState={countryOfCitizenshipDA}
          dropdownSetter={setCountryOfCitizenshipDA}
        />
      </div>

      <InputBox
        label="FIELD NAME"
        type="text"
        placeholder="PURCHASER:"
      />
      <InputBox
        label="FIELD VALUE"
        type="text"
        placeholder="“PURCHASER NAME”"
      />
      <div className="action-container">
        <span>Partner’s Action</span>
        <div className="actions">
          <span
            className={`action ${activeAction === "Must Fill" ? "action-active" : ""}`}
            onClick={() => handleActionClick("Must Fill")}
          >
            Must Fill
          </span>
          <div></div>
          <span
            className={`action ${activeAction === "Can Edit" ? "action-active" : ""}`}
            onClick={() => handleActionClick("Can Edit")}
          >
            Can Edit
          </span>
          <div></div>
          <span
            className={`action ${activeAction === "Locked" ? "action-active" : ""}`}
            onClick={() => handleActionClick("Locked")}
          >
            Locked
          </span>
        </div>
      </div>
      <div className="span-dot">
        <span>....</span>
      </div>
      <div className="MD-right-body">
        <h1>6. INITIAL DEPOSIT:</h1>
        <div id="text-filter">
          <span>Show in text</span>
          <img
            src={blankcircle}
            alt=""
          />
        </div>
        <DropDown
          label="Field Type"
          classDataName={"dropdown-height"}
          data={countryList}
          selectedData={countryOfCitizenship}
          setSelectedData={setCountryOfCitizenship}
          dropdownState={countryOfCitizenshipDA}
          dropdownSetter={setCountryOfCitizenshipDA}
        />
      </div>

      <InputBox
        label="FIELD NAME"
        type="text"
        placeholder="INITIAL DEPOSIT"
      />
      <InputBox
        label="FIELD VALUE"
        type="text"
        placeholder="$900,000.00"
      />
      <div className="action-container">
        <span>Partner’s Action</span>
        <div className="actions">
          <span
            className={`action ${activeAction === "Must Fill" ? "action-active" : ""}`}
            onClick={() => handleActionClick("Must Fill")}
          >
            Must Fill
          </span>
          <div></div>
          <span
            className={`action ${activeAction === "Can Edit" ? "action-active" : ""}`}
            onClick={() => handleActionClick("Can Edit")}
          >
            Can Edit
          </span>
          <div></div>
          <span
            className={`action ${activeAction === "Locked" ? "action-active" : ""}`}
            onClick={() => handleActionClick("Locked")}
          >
            Locked
          </span>
        </div>
      </div>
    </div>
  );
};
export default AddDoc;
