import moment from "moment";

export const formatUSD = (data) => {
    return data?.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
    });
};

export const  daysAgo = (dateString) => {
    var today = moment();
    var date = moment(dateString);
    var diffDays = today.diff(date, 'days');

    if (diffDays === 0) {
        return "today";
    } else if (diffDays === 1) {
        return "yesterday";
    } else {
        return diffDays + " days ago";
    }
}


export function formatTimestamp(seconds, nanoseconds) {
    if (typeof seconds !== 'number' || typeof nanoseconds !== 'number' || isNaN(seconds) || isNaN(nanoseconds)) {
        return '';
    }
  
    const date = new Date(seconds * 1000 + nanoseconds / 1e6);
    if (isNaN(date.getTime())) {
        return '';
    }
  
    const now = new Date();
  
    const isToday = (someDate) => {
        return someDate.toDateString() === now.toDateString();
    };
  
    const isYesterday = (someDate) => {
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);
        return someDate.toDateString() === yesterday.toDateString();
    };
  
    const formatTime = (date) => {
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; 
        return `${hours}:${minutes} ${ampm}`;
    };
  
    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };
  
    if (isToday(date)) {
        return `${formatTime(date)}`;
    } else if (isYesterday(date)) {
        return `${formatTime(date)}`;
    } else {
        return `${formatDate(date)} at ${formatTime(date)}`;
    }
  }

  export const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    // Check if the date is valid
    if (isNaN(date) || date.toString() === 'Invalid Date') {
      return '';
    }
  
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
  
    if (date.toDateString() === today.toDateString()) {
      return 'Today';
    } else if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    } else {
      return date.toLocaleDateString();
    }
  };