import React, {useEffect, useState} from "react";
import "./SignupQuestion.scss";
import {SmallSvgButton, BeforeSmallSvgButton} from "../../components/reusableComponents/Buttons/Buttons";
import {Link, useNavigate} from "react-router-dom";
import {Checkmark, CheckmarkTICK} from "../../assets/svg/checkmark";

import {useDispatch} from "react-redux";
import {postQuestioneriesData} from "../../store/reducers/Questioneries";
import {useSelector} from "react-redux";
import {PostQuestionsData} from "../../utils/Axios";
import FirstSectionSQ from "./SiginupQuestionSection/FirstSectionSQ.jsx";
import SecondSectionSQ from "./SiginupQuestionSection/SecondSectionSQ.jsx";
import ThirdSectionSQ from "./SiginupQuestionSection/ThirdSectionSQ.jsx";
import FourthSectionSQ from "./SiginupQuestionSection/FourthSectionSQ.jsx";
import FifthSectionSQ from "./SiginupQuestionSection/FifthSectionSQ.jsx";
import SixthSectionSQ from "./SiginupQuestionSection/SixthSectionSQ.jsx";
import SeventhSectionSQ from "./SiginupQuestionSection/SeventhSectionSQ.jsx";
import EighthSectionSQ from "./SiginupQuestionSection/EighthSectionSQ.jsx";
import NinethSectionSQ from "./SiginupQuestionSection/NinethSectionSQ.jsx";
import TenthSectionSQ from "./SiginupQuestionSection/TenthSectionSQ.jsx";

export const SignupQuestionsNew = () => {
  const navigate = useNavigate();
  const [activeSlider, setActiveSlider] = useState(1);
  const [investmentVehicleBox, setInvestmentVehicleBox] = useState(0);
  const [stateList, setStateList] = useState(["Texas", "Georgia", "California"]);
  const [strategy, setStrategy] = useState();
  const [totalInvestmentDA, setTotalInvestmentDA] = useState(false);
  const [totalInvestment, setTotalInvestment] = useState();
  const [InvestAmountList, setInvestAmountList] = useState(["10000", "20000", "30000", "40000"]);

  const [expectedPerProjectDA, setExpectedPerProjectDA] = useState(false);
  const [expectedPerProject, setExpectedPerProject] = useState();
  const [expectedPerProjectList, setExpectedPerProjectList] = useState(["10000", "20000", "30000", "40000"]);

  const [riskToleranceDA, setRiskToleranceDA] = useState(false);
  const [riskTolerance, setRiskTolerance] = useState();
  const [riskToleranceList, setRiskToleranceList] = useState(["10000", "20000", "30000", "40000"]);
  const dispatch = useDispatch();

  const [investmentVehicles, setInvestmentVehicles] = useState();
  const [marketSize, setMarketSize] = useState();
  const [region, setRegion] = useState();
  const {idToken} = useSelector((state) => state.auth);
  //fourth

  // const locationList = ["West Coast", "Middle", "East Coast"];
  const [location, setLocation] = useState(1);
  const [stateAddress, setStateAddress] = useState(stateList[0]);
  const [stateAddressDA, setStateAddressDA] = useState(false);
  const [subMarket, setSubMarket] = useState();

  //Fifth
  const [marketSizeBox, setMarketSizeBox] = useState(1);

  //sixth
  const [propertyTypeBox, setPropertyTypeBox] = useState(1);
  const [greenAssetCheck, setGreenAssetCheck] = useState(false);
  const [propertyClassBox, setPropertyClassBox] = useState(1);
  const [tenancyBox, setTenancyBox] = useState(1);
  const [price, setPrice] = useState({from: 20000, to: 100000, any: false});
  const [size, setSize] = useState({from: 1500, to: 50000, any: false});
  const [units, setUnits] = useState({from: 10, to: 20, any: false});
  const [propertyType, setPropertyType] = useState();
  const [propertyClass, setPropertyClass] = useState();
  const [tenancy, setTenancy] = useState();
  //seventh
  const [holdPeriod, setHoldPeriod] = useState(1);
  const [holdPeriodValue, setHoldPeriodValue] = useState();

  // Eight
  const [loanForm, setLoanForm] = useState({});
  const [loanChecks, setLoanChecks] = useState({
    first: false,
    second: false,
    third: false,
  });


  const [annualIncome, setAnnualIncome] = useState();
  const [annualIncomeList, setAnnualIncomeList] = useState(["0 to 25000", "25001 to 50000", "50001 to 100000"]);
  const [annualIncomeDA, setAnnualIncomeDA] = useState();

  const [investmentHorizon, setInvestmentHorizon] = useState();
  const [investmentHorizonList, setInvestmentHorizonList] = useState(["Short(Less than 3 year)", "Average(4 to 7 years)", "Long(8 year or more"]);
  const [investmentHorizonDA, setInvestmentHorizonDA] = useState();

  const [liquiditySeverity, setLiquiditySeverity] = useState();
  const [liquiditySeverityList, setLiquiditySeverityList] = useState(["Very Important)", "Somewhat Important)", "Not important"]);
  const [liquiditySeverityDA, setLiquiditySeverityDA] = useState();

  const [netWorth, setNetWorth] = useState();
  const [netWorthList, setNetWorthList] = useState(["0 to 50000", "50001 to 100000", "100001 to 200000"]);
  const [netWorthDA, setNetWorthDA] = useState();

  const [investingExperience, setInvestingExperience] = useState();
  const [investingExperienceList, setInvestingExperienceList] = useState(["Not specified", "Risk Averse", "Moderate", "Rick Tolerant"]);
  const [investingExperienceDA, setInvestingExperienceDA] = useState();

  //Ninth
  const [investmentBox, setInvestmentBox] = useState(1);
  const [lpBox, setLpBox] = useState(1);
  const [investmentFrequency, setInvestmentFrequency] = useState();
  const [lps, setLps] = useState();
  const [strategyDescription, setStrategyDescription] = useState({
    buying: "",
    managing: "",
    projected: "",
  });

  // Ten
  const [selectedOption, setSelectedOption] = useState("");
  const [error, setError] = useState("");

  const [preferredProperties, setPreferredProperties] = useState([]);


  // signup questions data
  const [signupQuestionsData, setSignupQuestionsData] = useState({
    strategy: "string",
    investment_vehicles: "string",
    market_size: "string",
    hold_period: "string",
    investment_objectives: {
      expected_in_12_months: "string",
      expected_per_project: "string",
      risk_tolerance: "string",
    },
    location: {
      region: "string",
      state: "string",
      sub_market: "string",
      lat_long: ["long", "lat"],
    },
    property_details: {
      property_type: "string",
      focus_on_green: false,
      property_class: "string",
      tenancy: "string",
      investment_objectives: {
        price: {
          from: "string",
          to: "string",
          any: false,
        },
        size: {
          from: "string",
          to: "string",
          any: false,
        },
        units: {
          from: "string",
          to: "string",
          any: false,
        },
      },
    },
    loan_parameters: {
      annual_income: "string",
      investment_horizon: "string",
      liquidity_severity: "string",
      net_worth: "string",
      investing_experience: "string",
      additional_parameters: {
        broker_or_dealer_connection: false,
        shareholder_or_director: false,
        public_official_connection: false,
      },
    },
    prospects: {
      investment_frequency: "string",
      lps: "string",
      strategy_description: "string",
    },
    preferred_properties: [],
  });

  useEffect(() => {
    setSignupQuestionsData((prev) => ({
      ...prev,
      strategy: strategy,
      investment_vehicles: investmentVehicles,
      market_size: marketSize,
      hold_period: holdPeriodValue,
      investment_objectives: {
        ...prev.investment_objectives,
        expected_in_12_months: totalInvestment,
        expected_per_project: expectedPerProject,
        risk_tolerance: riskTolerance,
      },
      location: {
        ...prev.location,
        region: region,
        state: stateAddress,
        sub_market: subMarket,
        lat_long: null,
      },
      property_details: {
        ...prev.property_details,
        property_type: propertyType,
        focus_on_green: greenAssetCheck,
        property_class: propertyClass,
        tenancy: tenancy,
        investment_objectives: {
          ...prev.property_details.investment_objectives,
          price: {
            from: price.from,
            to: price.to,
            any: price.any,
          },
          size: {
            from: size.from,
            to: size.to,
            any: size.any,
          },
          units: {
            from: units.from,
            to: units.to,
            any: units.any,
          },
        },
      },
      loan_parameters: {
        ...prev.loan_parameters,
        annual_income: annualIncome,
        investment_horizon: investmentHorizon,
        liquidity_severity: liquiditySeverity,
        net_worth: netWorth,
        investing_experience: investingExperience,
        additional_parameters: {
          ...prev.loan_parameters.additional_parameters,
          broker_or_dealer_connection: loanChecks.first,
          shareholder_or_director: loanChecks.second,
          public_official_connection: loanChecks.third,
        },
      },
      prospects: {
        ...prev.prospects,
        investment_frequency: investmentFrequency,
        lps: lps,
        strategy_description: strategyDescription,
      },
      preferred_properties: preferredProperties,
    }));
  }, [
    strategy,
    expectedPerProject,
    totalInvestment,
    riskTolerance,
    investmentVehicles,
    region,
    subMarket,
    stateAddress,
    marketSize,
    price,
    size,
    units,
    propertyType,
    greenAssetCheck,
    propertyClass,
    tenancy,
    holdPeriodValue,
    annualIncome,
    investmentHorizon,
    liquiditySeverity,
    netWorth,
    investingExperience,
    investmentFrequency,
    lps,
    strategyDescription,
    loanChecks,
    preferredProperties,
  ]);

  const postUserQustions = async () => {
    try {
      await PostQuestionsData(idToken, signupQuestionsData);
      navigate("/asset-discovery");
    } catch (error) {
      
    }
  };

  const handleSubmit = () => {
    if (!selectedOption) {
      setError("Please select an option");
    } else {
      setError("");
    }
  };

  return (
    <>
      {" "}
      <div className="SignupPricing questions">
        <div className="pricingContainer">
          <div className="pricingContainerT">
            {" "}
            <h1>Preferable Investing Criteria</h1>
            <div className="activeSlider">
              <div className={"sliderItem one " + (activeSlider === 1 && " active ")}>
                <div className="sliderCircle"></div>
                strategy
              </div>
              <div className={"sliderItem two " + (activeSlider === 2 && " active ")}>
                <div className="sliderCircle"></div>
                Objectives
              </div>
              <div className={"sliderItem three " + (activeSlider === 3 && " active ")}>
                <div className="sliderCircle"></div>
                Vehicles
              </div>
              <div className={"sliderItem four " + (activeSlider === 4 && " active ")}>
                <div className="sliderCircle"></div>
                Location
              </div>
              <div className={"sliderItem five " + (activeSlider === 5 && " active ")}>
                <div className="sliderCircle"></div>
                Market size
              </div>
              <div className={"sliderItem six " + (activeSlider === 6 && " active ")}>
                <div className="sliderCircle"></div>
                Property Details
              </div>
              <div className={"sliderItem seven " + (activeSlider === 7 && " active ")}>
                <div className="sliderCircle"></div>
                Hold period
              </div>
              <div className={"sliderItem eight " + (activeSlider === 8 && " active ")}>
                <div className="sliderCircle"></div>
                Current data
              </div>
              <div className={"sliderItem nine " + (activeSlider === 9 && " active ")}>
                {/* <div className="sliderELine"></div> */}
                <div className="sliderCircle"></div>
                Prospects
              </div>
              <div className={"sliderItem ten " + (activeSlider === 10 && " active ")}>
                <div className="sliderELine"></div>
                <div className="sliderCircle"></div>
                preferred
              </div>
            </div>
          </div>
          <div className="pricingContainerB">
            <div
              className={
                "sectionSignupQ-Slide-Con " +
                (activeSlider === 1 && " one ") +
                (activeSlider === 2 && " two ") +
                (activeSlider === 3 && " three ") +
                (activeSlider === 4 && " four ") +
                (activeSlider === 5 && " five ") +
                (activeSlider === 6 && " six ") +
                (activeSlider === 7 && " seven ") +
                (activeSlider === 8 && " eight ") +
                (activeSlider === 9 && " nine ") +
                (activeSlider === 10 && " ten ")
              }
            >
              <FirstSectionSQ
                activeSlider={activeSlider}
                setActiveSlider={setActiveSlider}
                setStrategy={setStrategy}
              />
              <SecondSectionSQ
                setTotalInvestment={setTotalInvestment}
                totalInvestment={totalInvestment}
                setTotalInvestmentDA={setTotalInvestmentDA}
                totalInvestmentDA={totalInvestmentDA}
                InvestAmountList={InvestAmountList}
                setExpectedPerProject={setExpectedPerProject}
                expectedPerProject={expectedPerProject}
                setExpectedPerProjectDA={setExpectedPerProjectDA}
                expectedPerProjectDA={expectedPerProjectDA}
                expectedPerProjectList={expectedPerProjectList}
                setRiskTolerance={setRiskTolerance}
                riskTolerance={riskTolerance}
                setRiskToleranceDA={setRiskToleranceDA}
                riskToleranceDA={riskToleranceDA}
                riskToleranceList={riskToleranceList}
              />

              <ThirdSectionSQ
                investmentVehicleBox={investmentVehicleBox}
                setInvestmentVehicleBox={setInvestmentVehicleBox}
                setInvestmentVehicles={setInvestmentVehicles}
              />
              <FourthSectionSQ
                location={location}
                setLocation={setLocation}
                stateList={stateList}
                stateAddress={stateAddress}
                setStateAddress={setStateAddress}
                stateAddressDA={stateAddressDA}
                setStateAddressDA={setStateAddressDA}
                setRegion={setRegion}
                setSubMarket={setSubMarket}
              />
              <FifthSectionSQ
                marketSizeBox={marketSizeBox}
                setMarketSizeBox={setMarketSizeBox}
                setMarketSize={setMarketSize}
              />
              <SixthSectionSQ
                propertyTypeBox={propertyTypeBox}
                setPropertyTypeBox={setPropertyTypeBox}
                Checkmark={Checkmark}
                CheckmarkTICK={CheckmarkTICK}
                greenAssetCheck={greenAssetCheck}
                setGreenAssetCheck={setGreenAssetCheck}
                propertyClassBox={propertyClassBox}
                setPropertyClassBox={setPropertyClassBox}
                tenancyBox={tenancyBox}
                setTenancyBox={setTenancyBox}
                price={price}
                setPrice={setPrice}
                size={size}
                setSize={setSize}
                units={units}
                setUnits={setUnits}
                setPropertyType={setPropertyType}
                setPropertyClass={setPropertyClass}
                setTenancy={setTenancy}
              />
              <SeventhSectionSQ
                holdPeriod={holdPeriod}
                setHoldPeriod={setHoldPeriod}
                setHoldPeriodValue={setHoldPeriodValue}
              />
              <EighthSectionSQ
                loanChecks={loanChecks}
                setLoanChecks={setLoanChecks}
                setAnnualIncome={setAnnualIncome}
                annualIncomeList={annualIncomeList}
                setAnnualIncomeDA={setAnnualIncomeDA}
                annualIncome={annualIncome}
                annualIncomeDA={annualIncomeDA}
                investmentHorizon={investmentHorizon}
                setInvestmentHorizon={setInvestmentHorizon}
                investmentHorizonList={investmentHorizonList}
                investmentHorizonDA={investmentHorizonDA}
                setInvestmentHorizonDA={setInvestmentHorizonDA}
                liquiditySeverity={liquiditySeverity}
                setLiquiditySeverity={setLiquiditySeverity}
                liquiditySeverityList={liquiditySeverityList}
                liquiditySeverityDA={liquiditySeverityDA}
                setLiquiditySeverityDA={setLiquiditySeverityDA}
                netWorth={netWorth}
                setNetWorth={setNetWorth}
                netWorthList={netWorthList}
                netWorthDA={netWorthDA}
                setNetWorthDA={setNetWorthDA}
                investingExperience={investingExperience}
                setInvestingExperience={setInvestingExperience}
                investingExperienceList={investingExperienceList}
                investingExperienceDA={investingExperienceDA}
                setInvestingExperienceDA={setInvestingExperienceDA}
              />
              <NinethSectionSQ
                investmentBox={investmentBox}
                setInvestmentBox={setInvestmentBox}
                lpBox={lpBox}
                setLpBox={setLpBox}
                setInvestmentFrequency={setInvestmentFrequency}
                setStrategyDescription={setStrategyDescription}
                setLps={setLps}
                strategyDescription={strategyDescription}
              />
              <TenthSectionSQ
                setPreferredProperties={setPreferredProperties}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                error={error}
              />
            </div>
          </div>
        </div>

        <div className="bottomFooter">
          <p>All Rights Reserved by CTG- 2020</p>
          <div className="SignupQuestionsNew-flexlineC">
            <BeforeSmallSvgButton
              onclick={() => {
                if (activeSlider != 1) setActiveSlider(activeSlider - 1);
              }}
              secondary={true}
              name="Back"
            />

            <SmallSvgButton
              onclick={() => {
                if (activeSlider === 10) {
                  // Validate the TenthSectionSQ before submitting
                  if (!selectedOption) {
                    setError("Please select an Properties Type");
                  } else {
                    setError(""); // Clear error if option is selected
                    postUserQustions();
                  }
                } else {
                  setActiveSlider(activeSlider + 1);
                }
              }}
              name={activeSlider === 10 ? "Submit & Continue" : "Next"}
              className={"submit_button"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
