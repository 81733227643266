import React, {useEffect, useState} from "react";
import KanbanCalendar from "../KanbanCalendar";
import addWhite from "../../../assets/svg/add.svg";

export const Calendar = ({openTaskModal, tasksList, setAddTaskPopupOpen, handleDateClick, getTaskdata}) => {

  return (
    <div className="calendar">
      <div className="calendar_header">
        <h3>Calendar</h3>
        <div className="btns">
          <div></div>
          <button
            className="add_btn"
            onClick={() => setAddTaskPopupOpen(true)}
          >
            <img
              src={addWhite}
              alt="Add Task"
            />
            Add Task
          </button>
        </div>
      </div>
      <KanbanCalendar
        tasksList={tasksList}
        onDateClick={handleDateClick}
        getTaskdata={getTaskdata}
      />
    </div>
  );
};
